import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "Curah Hujan",
        attr: {
          "hellman_5m" : "Intensitas Curah Hujan 5 menit",
          "hellman_10m" : "Intensitas Curah Hujan 10 menit",
          "hellman_15m" : "Intensitas Curah Hujan 15 menit",
          "hellman_30m" : "Intensitas Curah Hujan 30 menit",
          "hellman_45m" : "Intensitas Curah Hujan 45 menit",
          "hellman_60m" : "Intensitas Curah Hujan 60 menit",
          "hellman_120m" : "Intensitas Curah Hujan 120 menit",
          "hellman_3h" : "Intensitas Curah Hujan 3 jam",
          "hellman_6h" : "Intensitas Curah Hujan 6 jam",
          "hellman_12h" : "Intensitas Curah Hujan 12 jam",
          "hellman_07_08" : "Intensitas Curah Hujan jam 07-08",
          "hellman_08_09" : "Intensitas Curah Hujan jam 08-09",
          "hellman_09_10" : "Intensitas Curah Hujan jam 09-10",
          "hellman_10_11" : "Intensitas Curah Hujan jam 10-11",
          "hellman_11_12" : "Intensitas Curah Hujan jam 11-12",
          "hellman_12_13" : "Intensitas Curah Hujan jam 12-13",
          "hellman_13_14" : "Intensitas Curah Hujan jam 13-14",
          "hellman_14_15" : "Intensitas Curah Hujan jam 14-15",
          "hellman_15_16" : "Intensitas Curah Hujan jam 15-16",
          "hellman_16_17" : "Intensitas Curah Hujan jam 16-17",
          "hellman_17_18" : "Intensitas Curah Hujan jam 17-18",
          "hellman_18_19" : "Intensitas Curah Hujan jam 18-19",
          "hellman_19_20" : "Intensitas Curah Hujan jam 19-20",
          "hellman_20_21" : "Intensitas Curah Hujan jam 20-21",
          "hellman_21_22" : "Intensitas Curah Hujan jam 21-22",
          "hellman_22_23" : "Intensitas Curah Hujan jam 22-23",
          "hellman_23_24" : "Intensitas Curah Hujan jam 23-24",
          "hellman_24_01" : "Intensitas Curah Hujan jam 24-01",
          "hellman_01_02" : "Intensitas Curah Hujan jam 01-02",
          "hellman_02_03" : "Intensitas Curah Hujan jam 02-03",
          "hellman_03_04" : "Intensitas Curah Hujan jam 03-04",
          "hellman_04_05" : "Intensitas Curah Hujan jam 04-05",
          "hellman_05_06" : "Intensitas Curah Hujan jam 05-06",
          "hellman_06_07" : "Intensitas Curah Hujan jam 06-07",
          "hellman_sum_24" : "jumlah  curah hujan harian per jam selama 24 jam",

        },
      },
    ];

    return listAttr;
  },

  getAttrMonthly() {
    let listAttr = [
      {
        group: "Hujan",
        form: "Intensitas Hujan",
        label: "Curah Hujan",
        attr: {
          // "hellman_5m_avg_monthly" : "Intensitas Curah Hujan 5 menit AVG MONTHLY",
          // "hellman_5m_mod_monthly" : "Intensitas Curah Hujan 5 menit MOD MONTHLY",
          // "hellman_5m_med_monthly" : "Intensitas Curah Hujan 5 menit MED MONTHLY",
          "hellman_5m_max_monthly" : "Intensitas Curah Hujan 5 menit MAX MONTHLY",
          // "hellman_5m_min_monthly" : "Intensitas Curah Hujan 5 menit MIN MONTHLY",
          // "hellman_5m_sum_monthly" : "Intensitas Curah Hujan 5 menit SUM MONTHLY",
          "hellman_5m_count_monthly" : "Intensitas Curah Hujan 5 menit COUNT MONTHLY",
          // "hellman_10m_avg_monthly" : "Intensitas Curah Hujan 10 menit AVG MONTHLY",
          // "hellman_10m_mod_monthly" : "Intensitas Curah Hujan 10 menit MOD MONTHLY",
          // "hellman_10m_med_monthly" : "Intensitas Curah Hujan 10 menit MED MONTHLY",
          "hellman_10m_max_monthly" : "Intensitas Curah Hujan 10 menit MAX MONTHLY",
          // "hellman_10m_min_monthly" : "Intensitas Curah Hujan 10 menit MIN MONTHLY",
          // "hellman_10m_sum_monthly" : "Intensitas Curah Hujan 10 menit SUM MONTHLY",
          "hellman_10m_count_monthly" : "Intensitas Curah Hujan 10 menit COUNT MONTHLY",
          // "hellman_15m_avg_monthly" : "Intensitas Curah Hujan 15 menit AVG MONTHLY",
          // "hellman_15m_mod_monthly" : "Intensitas Curah Hujan 15 menit MOD MONTHLY",
          // "hellman_15m_med_monthly" : "Intensitas Curah Hujan 15 menit MED MONTHLY",
          "hellman_15m_max_monthly" : "Intensitas Curah Hujan 15 menit MAX MONTHLY",
          // "hellman_15m_min_monthly" : "Intensitas Curah Hujan 15 menit MIN MONTHLY",
          // "hellman_15m_sum_monthly" : "Intensitas Curah Hujan 15 menit SUM MONTHLY",
          "hellman_15m_count_monthly" : "Intensitas Curah Hujan 15 menit COUNT MONTHLY",
          // "hellman_30m_avg_monthly" : "Intensitas Curah Hujan 30 menit AVG MONTHLY",
          // "hellman_30m_mod_monthly" : "Intensitas Curah Hujan 30 menit MOD MONTHLY",
          // "hellman_30m_med_monthly" : "Intensitas Curah Hujan 30 menit MED MONTHLY",
          "hellman_30m_max_monthly" : "Intensitas Curah Hujan 30 menit MAX MONTHLY",
          // "hellman_30m_min_monthly" : "Intensitas Curah Hujan 30 menit MIN MONTHLY",
          // "hellman_30m_sum_monthly" : "Intensitas Curah Hujan 30 menit SUM MONTHLY",
          "hellman_30m_count_monthly" : "Intensitas Curah Hujan 30 menit COUNT MONTHLY",
          // "hellman_45m_avg_monthly" : "Intensitas Curah Hujan 45 menit AVG MONTHLY",
          // "hellman_45m_mod_monthly" : "Intensitas Curah Hujan 45 menit MOD MONTHLY",
          // "hellman_45m_med_monthly" : "Intensitas Curah Hujan 45 menit MED MONTHLY",
          "hellman_45m_max_monthly" : "Intensitas Curah Hujan 45 menit MAX MONTHLY",
          // "hellman_45m_min_monthly" : "Intensitas Curah Hujan 45 menit MIN MONTHLY",
          // "hellman_45m_sum_monthly" : "Intensitas Curah Hujan 45 menit SUM MONTHLY",
          "hellman_45m_count_monthly" : "Intensitas Curah Hujan 45 menit COUNT MONTHLY",
          // "hellman_60m_avg_monthly" : "Intensitas Curah Hujan 60 menit AVG MONTHLY",
          // "hellman_60m_mod_monthly" : "Intensitas Curah Hujan 60 menit MOD MONTHLY",
          // "hellman_60m_med_monthly" : "Intensitas Curah Hujan 60 menit MED MONTHLY",
          "hellman_60m_max_monthly" : "Intensitas Curah Hujan 60 menit MAX MONTHLY",
          // "hellman_60m_min_monthly" : "Intensitas Curah Hujan 60 menit MIN MONTHLY",
          // "hellman_60m_sum_monthly" : "Intensitas Curah Hujan 60 menit SUM MONTHLY",
          "hellman_60m_count_monthly" : "Intensitas Curah Hujan 60 menit COUNT MONTHLY",
          // "hellman_120m_avg_monthly" : "Intensitas Curah Hujan 120 menit AVG MONTHLY",
          // "hellman_120m_mod_monthly" : "Intensitas Curah Hujan 120 menit MOD MONTHLY",
          // "hellman_120m_med_monthly" : "Intensitas Curah Hujan 120 menit MED MONTHLY",
          "hellman_120m_max_monthly" : "Intensitas Curah Hujan 120 menit MAX MONTHLY",
          // "hellman_120m_min_monthly" : "Intensitas Curah Hujan 120 menit MIN MONTHLY",
          // "hellman_120m_sum_monthly" : "Intensitas Curah Hujan 120 menit SUM MONTHLY",
          "hellman_120m_count_monthly" : "Intensitas Curah Hujan 120 menit COUNT MONTHLY",
          // "hellman_3h_avg_monthly" : "Intensitas Curah Hujan 3 jam AVG MONTHLY",
          // "hellman_3h_mod_monthly" : "Intensitas Curah Hujan 3 jam MOD MONTHLY",
          // "hellman_3h_med_monthly" : "Intensitas Curah Hujan 3 jam MED MONTHLY",
          "hellman_3h_max_monthly" : "Intensitas Curah Hujan 3 jam MAX MONTHLY",
          // "hellman_3h_min_monthly" : "Intensitas Curah Hujan 3 jam MIN MONTHLY",
          // "hellman_3h_sum_monthly" : "Intensitas Curah Hujan 3 jam SUM MONTHLY",
          "hellman_3h_count_monthly" : "Intensitas Curah Hujan 3 jam COUNT MONTHLY",
          // "hellman_6h_avg_monthly" : "Intensitas Curah Hujan 6 jam AVG MONTHLY",
          // "hellman_6h_mod_monthly" : "Intensitas Curah Hujan 6 jam MOD MONTHLY",
          // "hellman_6h_med_monthly" : "Intensitas Curah Hujan 6 jam MED MONTHLY",
          "hellman_6h_max_monthly" : "Intensitas Curah Hujan 6 jam MAX MONTHLY",
          // "hellman_6h_min_monthly" : "Intensitas Curah Hujan 6 jam MIN MONTHLY",
          // "hellman_6h_sum_monthly" : "Intensitas Curah Hujan 6 jam SUM MONTHLY",
          "hellman_6h_count_monthly" : "Intensitas Curah Hujan 6 jam COUNT MONTHLY",
          // "hellman_12h_avg_monthly" : "Intensitas Curah Hujan 12 jam AVG MONTHLY",
          // "hellman_12h_mod_monthly" : "Intensitas Curah Hujan 12 jam MOD MONTHLY",
          // "hellman_12h_med_monthly" : "Intensitas Curah Hujan 12 jam MED MONTHLY",
          "hellman_12h_max_monthly" : "Intensitas Curah Hujan 12 jam MAX MONTHLY",
          // "hellman_12h_min_monthly" : "Intensitas Curah Hujan 12 jam MIN MONTHLY",
          // "hellman_12h_sum_monthly" : "Intensitas Curah Hujan 12 jam SUM MONTHLY",
          "hellman_12h_count_monthly" : "Intensitas Curah Hujan 12 jam COUNT MONTHLY",
          // "hellman_07_08_avg_monthly" : "Intensitas Curah Hujan jam 07-08 AVG MONTHLY",
          // "hellman_07_08_mod_monthly" : "Intensitas Curah Hujan jam 07-08 AVG MONTHLY",
          // "hellman_07_08_med_monthly" : "Intensitas Curah Hujan jam 07-08 MED MONTHLY",
          // "hellman_07_08_max_monthly" : "Intensitas Curah Hujan jam 07-08 MAX MONTHLY",
          // "hellman_07_08_min_monthly" : "Intensitas Curah Hujan jam 07-08 MIN MONTHLY",
          // "hellman_07_08_sum_monthly" : "Intensitas Curah Hujan jam 07-08 SUM MONTHLY",
          // "hellman_07_08_count_monthly" : "Intensitas Curah Hujan jam 07-08 COUNT MONTHLY",
          // "hellman_08_09" : "Intensitas Curah Hujan jam 08-09",
          // "hellman_09_10" : "Intensitas Curah Hujan jam 09-10",
          // "hellman_10_11" : "Intensitas Curah Hujan jam 10-11",
          // "hellman_11_12" : "Intensitas Curah Hujan jam 11-12",
          // "hellman_12_13" : "Intensitas Curah Hujan jam 12-13",
          // "hellman_13_14" : "Intensitas Curah Hujan jam 13-14",
          // "hellman_14_15" : "Intensitas Curah Hujan jam 14-15",
          // "hellman_15_16" : "Intensitas Curah Hujan jam 15-16",
          // "hellman_16_17" : "Intensitas Curah Hujan jam 16-17",
          // "hellman_17_18" : "Intensitas Curah Hujan jam 17-18",
          // "hellman_18_19" : "Intensitas Curah Hujan jam 18-19",
          // "hellman_19_20" : "Intensitas Curah Hujan jam 19-20",
          // "hellman_20_21" : "Intensitas Curah Hujan jam 20-21",
          // "hellman_21_22" : "Intensitas Curah Hujan jam 21-22",
          // "hellman_22_23" : "Intensitas Curah Hujan jam 22-23",
          // "hellman_23_24" : "Intensitas Curah Hujan jam 23-24",
          // "hellman_24_01" : "Intensitas Curah Hujan jam 24-01",
          // "hellman_01_02" : "Intensitas Curah Hujan jam 01-02",
          // "hellman_02_03" : "Intensitas Curah Hujan jam 02-03",
          // "hellman_03_04" : "Intensitas Curah Hujan jam 03-04",
          // "hellman_04_05" : "Intensitas Curah Hujan jam 04-05",
          // "hellman_05_06" : "Intensitas Curah Hujan jam 05-06",
          // "hellman_06_07" : "Intensitas Curah Hujan jam 06-07",
          "hellman_total_24_avg_monthly" : "jumlah curah hujan harian per jam selama 24 jam AVG MONTHLY",
          // "hellman_total_24_mod_monthly" : "jumlah curah hujan harian per jam selama 24 jam MOD MONTHLY",
          // "hellman_total_24_med_monthly" : "jumlah curah hujan harian per jam selama 24 jam MED MONTHLY",
          "hellman_total_24_max_monthly" : "jumlah curah hujan harian per jam selama 24 jam MAX MONTHLY",
          // "hellman_total_24_min_monthly" : "jumlah curah hujan harian per jam selama 24 jam MIN MONTHLY",
          "hellman_total_24_sum_monthly" : "jumlah curah hujan harian per jam selama 24 jam SUM MONTHLY",
          "hellman_total_24_count_monthly" : "jumlah curah hujan harian per jam selama 24 jam COUNT MONTHLY"

        },
      },
    ];

    for (var i = 1; i <= 24; i++) {
      let num1 = this.padZero(i, 2)
      let num2 = this.padZero(((i+1) > 24 ? 1 : (i+1)), 2)
      listAttr[0].attr["hellman_"+num1+"_"+num2+"_avg_monthly"] = "Intensitas Curah Hujan jam "+num1+"-"+num2+" AVG MONTHLY"
      // listAttr[0].attr["hellman_"+num1+"_"+num2+"_mod_monthly"] = "Intensitas Curah Hujan jam "+num1+"-"+num2+" MOD MONTHLY"
      // listAttr[0].attr["hellman_"+num1+"_"+num2+"_med_monthly"] = "Intensitas Curah Hujan jam "+num1+"-"+num2+" MED MONTHLY"
      listAttr[0].attr["hellman_"+num1+"_"+num2+"_max_monthly"] = "Intensitas Curah Hujan jam "+num1+"-"+num2+" MAX MONTHLY"
      // listAttr[0].attr["hellman_"+num1+"_"+num2+"_min_monthly"] = "Intensitas Curah Hujan jam "+num1+"-"+num2+" MIN MONTHLY"
      listAttr[0].attr["hellman_"+num1+"_"+num2+"_sum_monthly"] = "Intensitas Curah Hujan jam "+num1+"-"+num2+" SUM MONTHLY"
      listAttr[0].attr["hellman_"+num1+"_"+num2+"_count_monthly"] = "Intensitas Curah Hujan jam "+num1+"-"+num2+" COUNT MONTHLY"
    }
    return listAttr;
  },

  getAttrMonthlyCsv() {
    let listAttr = [
      {
        label: "Curah Hujan",
        attr: {
          "hellman_5m_avg_monthly" : "hellman_5m_monthly_avg_mm_mm",
          "hellman_5m_mod_monthly" : "hellman_5m_monthly_mod_mm",
          "hellman_5m_med_monthly" : "hellman_5m_monthly_med_mm",
          "hellman_5m_max_monthly" : "hellman_5m_monthly_max_mm",
          "hellman_5m_min_monthly" : "hellman_5m_monthly_in_mm",
          "hellman_5m_sum_monthly" : "hellman_5m_monthly_sum_mm",
          "hellman_5m_count_monthly" : "count_hellman_5m_monthly",
          "hellman_10m_avg_monthly" : "hellman_10m_monthly_avg_mm",
          "hellman_10m_mod_monthly" : "hellman_10m_monthly_mod_mm",
          "hellman_10m_med_monthly" : "hellman_10m_monthly_med_mm",
          "hellman_10m_max_monthly" : "hellman_10m_monthly_max_mm",
          "hellman_10m_min_monthly" : "hellman_10m_monthly_min_mm",
          "hellman_10m_sum_monthly" : "hellman_10m_monthly_sum_mm",
          "hellman_10m_count_monthly" : "count_hellman_10m_monthly",
          "hellman_15m_avg_monthly" : "hellman_15m_monthly_avg_mm",
          "hellman_15m_mod_monthly" : "hellman_15m_monthly_mod_mm",
          "hellman_15m_med_monthly" : "hellman_15m_monthly_med_mm",
          "hellman_15m_max_monthly" : "hellman_15m_monthly_max_mm",
          "hellman_15m_min_monthly" : "hellman_15m_monthly_min_mm",
          "hellman_15m_sum_monthly" : "hellman_15m_monthly_sum_mm",
          "hellman_15m_count_monthly" : "count_hellman_15m_monthly",
          "hellman_30m_avg_monthly" : "hellman_30m_monthly_avg_mm",
          "hellman_30m_mod_monthly" : "hellman_30m_monthly_mod_mm",
          "hellman_30m_med_monthly" : "hellman_30m_monthly_med_mm",
          "hellman_30m_max_monthly" : "hellman_30m_monthly_max_mm",
          "hellman_30m_min_monthly" : "hellman_30m_monthly_min_mm",
          "hellman_30m_sum_monthly" : "hellman_30m_monthly_sum_mm",
          "hellman_30m_count_monthly" : "count_hellman_30m_monthly",
          "hellman_45m_avg_monthly" : "hellman_45m_monthly_avg_mm",
          "hellman_45m_mod_monthly" : "hellman_45m_monthly_mod_mm",
          "hellman_45m_med_monthly" : "hellman_45m_monthly_med_mm",
          "hellman_45m_max_monthly" : "hellman_45m_monthly_max_mm",
          "hellman_45m_min_monthly" : "hellman_45m_monthly_min_mm",
          "hellman_45m_sum_monthly" : "hellman_45m_monthly_sum_mm",
          "hellman_45m_count_monthly" : "count_hellman_45m_monthly",
          "hellman_60m_avg_monthly" : "hellman_60m_monthly_avg_mm",
          "hellman_60m_mod_monthly" : "hellman_60m_monthly_mod_mm",
          "hellman_60m_med_monthly" : "hellman_60m_monthly_med_mm",
          "hellman_60m_max_monthly" : "hellman_60m_monthly_max_mm",
          "hellman_60m_min_monthly" : "hellman_60m_monthly_min_mm",
          "hellman_60m_sum_monthly" : "hellman_60m_monthly_sum_mm",
          "hellman_60m_count_monthly" : "count_hellman_60m_monthly",
          "hellman_120m_avg_monthly" : "hellman_120m_monthly_avg_mm",
          "hellman_120m_mod_monthly" : "hellman_120m_monthly_mod_mm",
          "hellman_120m_med_monthly" : "hellman_120m_monthly_med_mm",
          "hellman_120m_max_monthly" : "hellman_120m_monthly_max_mm",
          "hellman_120m_min_monthly" : "hellman_120m_monthly_min_mm",
          "hellman_120m_sum_monthly" : "hellman_120m_monthly_sum_mm",
          "hellman_120m_count_monthly" : "count_hellman_120m_monthly",
          "hellman_3h_avg_monthly" : "hellman_3h_monthly_avg_mm",
          "hellman_3h_mod_monthly" : "hellman_3h_monthly_mod_mm",
          "hellman_3h_med_monthly" : "hellman_3h_monthly_med_mm",
          "hellman_3h_max_monthly" : "hellman_3h_monthly_max_mm",
          "hellman_3h_min_monthly" : "hellman_3h_monthly_min_mm",
          "hellman_3h_sum_monthly" : "hellman_3h_monthly_sum_mm",
          "hellman_3h_count_monthly" : "count_hellman_3h_monthly",
          "hellman_6h_avg_monthly" : "hellman_6h_monthly_avg_mm",
          "hellman_6h_mod_monthly" : "hellman_6h_monthly_mod_mm",
          "hellman_6h_med_monthly" : "hellman_6h_monthly_med_mm",
          "hellman_6h_max_monthly" : "hellman_6h_monthly_max_mm",
          "hellman_6h_min_monthly" : "hellman_6h_monthly_min_mm",
          "hellman_6h_sum_monthly" : "hellman_6h_monthly_sum_mm",
          "hellman_6h_count_monthly" : "count_hellman_6h_monthly",
          "hellman_12h_avg_monthly" : "hellman_12h_monthly_avg_mm",
          "hellman_12h_mod_monthly" : "hellman_12h_monthly_mod_mm",
          "hellman_12h_med_monthly" : "hellman_12h_monthly_med_mm",
          "hellman_12h_max_monthly" : "hellman_12h_monthly_max_mm",
          "hellman_12h_min_monthly" : "hellman_12h_monthly_min_mm",
          "hellman_12h_sum_monthly" : "hellman_12h_monthly_sum_mm",
          "hellman_12h_count_monthly" : "count_hellman_12h_monthly",
          // "hellman_07_08_avg_monthly" : "Intensitas Curah Hujan jam 07-08 AVG MONTHLY",
          // "hellman_07_08_mod_monthly" : "Intensitas Curah Hujan jam 07-08 AVG MONTHLY",
          // "hellman_07_08_med_monthly" : "Intensitas Curah Hujan jam 07-08 MED MONTHLY",
          // "hellman_07_08_max_monthly" : "Intensitas Curah Hujan jam 07-08 MAX MONTHLY",
          // "hellman_07_08_min_monthly" : "Intensitas Curah Hujan jam 07-08 MIN MONTHLY",
          // "hellman_07_08_sum_monthly" : "Intensitas Curah Hujan jam 07-08 SUM MONTHLY",
          // "hellman_07_08_count_monthly" : "Intensitas Curah Hujan jam 07-08 COUNT MONTHLY",
          // "hellman_08_09" : "Intensitas Curah Hujan jam 08-09",
          // "hellman_09_10" : "Intensitas Curah Hujan jam 09-10",
          // "hellman_10_11" : "Intensitas Curah Hujan jam 10-11",
          // "hellman_11_12" : "Intensitas Curah Hujan jam 11-12",
          // "hellman_12_13" : "Intensitas Curah Hujan jam 12-13",
          // "hellman_13_14" : "Intensitas Curah Hujan jam 13-14",
          // "hellman_14_15" : "Intensitas Curah Hujan jam 14-15",
          // "hellman_15_16" : "Intensitas Curah Hujan jam 15-16",
          // "hellman_16_17" : "Intensitas Curah Hujan jam 16-17",
          // "hellman_17_18" : "Intensitas Curah Hujan jam 17-18",
          // "hellman_18_19" : "Intensitas Curah Hujan jam 18-19",
          // "hellman_19_20" : "Intensitas Curah Hujan jam 19-20",
          // "hellman_20_21" : "Intensitas Curah Hujan jam 20-21",
          // "hellman_21_22" : "Intensitas Curah Hujan jam 21-22",
          // "hellman_22_23" : "Intensitas Curah Hujan jam 22-23",
          // "hellman_23_24" : "Intensitas Curah Hujan jam 23-24",
          // "hellman_24_01" : "Intensitas Curah Hujan jam 24-01",
          // "hellman_01_02" : "Intensitas Curah Hujan jam 01-02",
          // "hellman_02_03" : "Intensitas Curah Hujan jam 02-03",
          // "hellman_03_04" : "Intensitas Curah Hujan jam 03-04",
          // "hellman_04_05" : "Intensitas Curah Hujan jam 04-05",
          // "hellman_05_06" : "Intensitas Curah Hujan jam 05-06",
          // "hellman_06_07" : "Intensitas Curah Hujan jam 06-07",
          "hellman_total_24_avg_monthly" : "hellman_total_24_monthly_avg_mm",
          "hellman_total_24_mod_monthly" : "hellman_total_24_monthly_mod_mm",
          "hellman_total_24_med_monthly" : "hellman_total_24_monthly_med_mm",
          "hellman_total_24_max_monthly" : "hellman_total_24_monthly_max_mm",
          "hellman_total_24_min_monthly" : "hellman_total_24_monthly_min_mm",
          "hellman_total_24_sum_monthly" : "hellman_total_24_monthly_sum_mm",
          "hellman_total_24_count_monthly" : "count_hellman_total_24_monthly_mm"

        },
      },
    ];

    for (var i = 1; i <= 24; i++) {
      let num1 = this.padZero(i, 2)
      let num2 = this.padZero(((i+1) > 24 ? 1 : (i+1)), 2)
      listAttr[0].attr["hellman_"+num1+"_"+num2+"_avg_monthly"] = "hellman_"+num1+"_"+num2+"_monthly_avg_mm"
      listAttr[0].attr["hellman_"+num1+"_"+num2+"_mod_monthly"] = "hellman_"+num1+"_"+num2+"_monthly_mod_mm"
      listAttr[0].attr["hellman_"+num1+"_"+num2+"_med_monthly"] = "hellman_"+num1+"_"+num2+"_monthly_med_mm"
      listAttr[0].attr["hellman_"+num1+"_"+num2+"_max_monthly"] = "hellman_"+num1+"_"+num2+"_monthly_max_mm"
      listAttr[0].attr["hellman_"+num1+"_"+num2+"_min_monthly"] = "hellman_"+num1+"_"+num2+"_monthly_min_mm"
      listAttr[0].attr["hellman_"+num1+"_"+num2+"_sum_monthly"] = "hellman_"+num1+"_"+num2+"_monthly_sum_mm"
      listAttr[0].attr["hellman_"+num1+"_"+num2+"_count_monthly"] = "count_hellman_"+num1+"_"+num2+"_monthly_mm"
    }
    return listAttr;
  },

  padZero(val, n) {
    return `${"0".repeat(n)}${val}`.substr(-n, n);
  },

  getAttrDaily() {
    const listAttr = [
      {
        label: "Curah Hujan",
        attr: {
          hellman_total_24: "Total Intensitas Hujan Hellman Harian"
        }
      }
    ];
    return listAttr;
  },

  getAttrDailyCsv() {
    const listAttr = [
      {
        label: "Curah Hujan",
        attr: {
          hellman_total_24: "hellman_daily_total_mm"
        },
      }
    ];
    return listAttr;
  },

  getAttrDailyDecimal() {
    const listAttr = [
      {
        label: "Curah Hujan",
        attr: {
          hellman_total_24: 1
        },
      },
    ];
    return listAttr;
  },
};
