<template>
  <div>
    <b-card>
      <b-row>
        <b-col sm="12">
          <b-row>
            <!-- <b-col sm="2 ">
              <label>*Kategori</label>
              <v-select v-model="t_mkg" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="t_mkg_opt" />
            </b-col> -->
            <!-- <b-col sm="2 ">
              <label>Form</label>
              <v-select v-model="t_form" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="t_form_opt"  />
            </b-col> -->
            <b-col sm="3">
              <label>*Temporal Data<Datal></Datal></label>
              <v-select v-model="t_temporal_data" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="t_temp_data_opt"  >
                <template #option="{ value, text, disabled }" class="{disabled: true}">
                  <span class="{ disabled: true }"> {{ text }}</span>
                </template>
              </v-select>
            </b-col>
            <b-col sm="4">
              <label>*Group Parameter</label>
              <v-select v-model="group" class="select-size-md" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" multiple label="text" :close-on-select="false" :options="group_opt" />
            </b-col>
            <!-- <b-col sm="2">
              <label>*Temporal Data</label>
              <v-select v-model="temporal" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="temporal_opt" class="select-size-sm" />
            </b-col> -->
            <b-col sm="2">
              <label>*Balai</label>
              <v-select v-model="regions" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="regions_opt"  />
              <!-- <b-form-select v-model="regions" :options="regions_opt" size="sm" /> -->
            </b-col>
            <b-col sm="2">
              <label>Provinsi</label>
              <v-select v-model="propinsi" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="propinsi_opt"  />
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <!-- <b-col sm="2 ">
              <label>Provinsi</label>
              <v-select v-model="propinsi" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="propinsi_opt"  />
            </b-col> -->
            <b-col sm="3">
              <label>Kabupaten</label>
              <v-select v-model="kabupaten" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="kabupaten_opt"  />

              <!-- <b-form-select v-model="kabupaten" :options="kabupaten" size="sm" /> -->
            </b-col>
            <b-col sm="4 ">
              <label>Stasiun</label>
              <!-- <b-form-select v-model="stasiun" :options="stasiun_opt" size="sm" /> -->
              <v-select v-model="stasiun" class="select-size-md" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" multiple label="text" :close-on-select="false" :options="stasiun_opt" />
            </b-col>
            <!-- <b-col sm="2 ">
              <label>*Bulan Awal</label>
              <flat-pickr  v-model="start_date" style="height: 39px" class="form-control" :config="dpconfig" />
            </b-col> -->

            <b-col cols="2">
              <label>*Bulan Awal</label>
              <flat-pickr  v-model="start_date" style="height: 39px" class="form-control" :config="dpconfig" />
            </b-col>
            <b-col cols="2">
              <label>*Bulan Akhir</label>
              <flat-pickr  v-model="end_date" style="height: 39px" class="form-control" :config="dpconfig" />
            </b-col>
            <b-col cols="1" style="margin-top:3px">
              <b-button  variant="relief-primary" v-on:click="previewGroup" class="mt-2">
                <feather-icon icon="EyeIcon" class=" align-middle" v-b-tooltip.hover.v-dark title="Preview"/>
              </b-button>
            </b-col>

            <!-- <b-col sm="5">
              <b-row>
                <b-col cols="5">
                  <label>*Bulan Awal</label>
                  <flat-pickr  v-model="start_date" style="height: 39px" class="form-control" :config="dpconfig" />
                </b-col>
                <b-col cols="5">
                  <label>*Bulan Akhir</label>
                  <flat-pickr  v-model="end_date" style="height: 39px" class="form-control" :config="dpconfig" />
                </b-col>
                <b-col cols="2" style="margin-top:3px">
                  <b-button  variant="relief-primary" v-on:click="previewGroup" class="mt-2">
                    <feather-icon icon="EyeIcon" class=" align-middle" v-b-tooltip.hover.v-dark title="Preview"/>
                  </b-button>
                </b-col>
              </b-row>              
            </b-col> -->
            <!-- <b-col sm="1" class=" mt-2 w-100">
              <b-button variant="gradient-primary" v-on:click="previewGroup" style="margin-top:4px">
                <feather-icon
                  icon="EyeIcon"
                  class=""
                  v-b-tooltip.hover.v-dark
                  title="Preview"
                />
              </b-button>
            </b-col> -->
          </b-row>
          <!-- == TABLE== -->
          <b-card no-body class="border mt-1">
            <!-- <b-card-header class="p-1 bg-light-primary">
              <b-card-title class="font-medium-1">
                <feather-icon icon="FileIcon" size="16" />
                <span class="align-middle ml-50">Report View</span>
              </b-card-title>
            </b-card-header> -->

            <b-row class="p-1">
              <b-col :cols="col_card" v-for="obj in cardCheckbox" :key="obj.label">
                <b-card style="border: 2px solid #333333" id="showcard">
                  <span class="align-middle ml-10 text-dark font-weight-bolder mb-1">{{ obj.label }}</span>
                  <b-row class="mt-1">
                    <b-col>
                      <b-form-checkbox :value="obj" v-model="checkall"> Checked </b-form-checkbox>
                    </b-col>
                    <b-col>
                      <b-form-checkbox :value="obj" v-model="uncheckall"> Unchecked </b-form-checkbox>
                    </b-col>
                  </b-row>

                  <b-form-group label=" " class="font-weight-bolder text-dark">
                    <b-form-checkbox v-for="item in obj.attr" :key="item.value" v-model="selectedCheck" :value="item.value" name="flavour-3a">
                      {{ item.text }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-card>
              </b-col>

              <b-col cols="12" class="float-right">
                <b-button v-if="sumbitShow" variant="relief-primary" class="float-right" v-on:click="showTable"> Submit </b-button>
              </b-col>
             
              
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-card>

    <b-card v-if="cardTable">
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Sort Date</label>
            <b-form-select id="sortDate" v-model="sortDate" size="sm" :options="sortDateOpt" class="w-50" />
          </b-form-group>
        </b-col>

        <!-- <b-col md="2" class="my-1">
          <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
            <b-input-group size="sm">
              <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col> -->

        
        <b-col md="3" class="my-1" v-if="ready_export">
          <download-excel class="btn btn-sm btn-success ml-1" :data="itemsCsv" type="csv" :name="csvfilename" :escapeCsv="escapeCsv" :header="form_name_exp_xls" :title="form_name_exp_xls"> Download CSV </download-excel>
        </b-col>

        <b-col cols="12">
          <b-table
            class="table b-table my-table table-striped table-hover table-sm table-responsive text-nowrap text-center"
            striped
            hover
            responsive
            :bordered="true"
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm" class="my-0" />
        </b-col>
      </b-row>
    </b-card>
    <div id="divExport" class="divExport">
      <table>
        <tr>
          <td v-for="item in items" :key="item"></td>
        </tr>
      </table>
    </div>
    
    <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
  </div>
</template>

<script>
//import LiquorTree from "liquor-tree";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import 'flatpickr/dist/plugins/monthSelect/style.css'

import {
  BOverlay,
  BTable,
  BAvatar,
  BBadge,
  BPagination,
  BInputGroupAppend,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormInput,
  BInputGroup,
  BFormSelect,
  BButton,
  BButtonGroup,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormGroup,
  BFormTextarea,
  BPopover,
  BModal,
  VBModal,
  BForm,
  AlertPlugin,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import metadata from "@/api/metadata";
import VueHtml2pdf from "vue-html2pdf";

import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import helper from "@/api/helper";

import outputexport from "@/api/outputdataderived";
import lamapenyinaran from "@/attr/derivedata/pias/lamapenyinaran";
import aktinograph from "@/attr/derivedata/pias/aktinograph";
import barograph from "@/attr/derivedata/pias/barograph";
import fenologi from "@/attr/derivedata/pias/fenologi";
import intentitashujan from "@/attr/derivedata/pias/intentitashujan";
import kelembabantanah from "@/attr/derivedata/pias/kelembabantanah";
import perawanan from "@/attr/derivedata/pias/perawanan";
import thermohigrograph from "@/attr/derivedata/pias/thermohigrograph";
import formhujan from "@/attr/derivedata/pias/formhujan";

import fklim from "@/attr/derivedata/fdih/fklim";
import agm1a from "@/attr/derivedata/fdih/agm1a";
import agm1b from "@/attr/derivedata/fdih/agm1b";
import gunbellani from "@/attr/derivedata/fdih/gunbellani";
import iklimmikro from "@/attr/derivedata/fdih/iklimmikro";
import lysimeter from "@/attr/derivedata/fdih/lysimeter";
import pichepenguapan from "@/attr/derivedata/fdih/pichepenguapan";
import psycrometerassman from "@/attr/derivedata/fdih/psycrometerassman";
import suhutanah from "@/attr/derivedata/fdih/suhutanah";
import oppenguapan from "@/attr/derivedata/fdih/oppenguapan";

import kah from "@/attr/derivedata/ku/kah";
import spm from "@/attr/derivedata/ku/spm";
import so2no2 from "@/attr/derivedata/ku/so2no2";
import aerosol from "@/attr/derivedata/ku/aerosol";


import me45 from "@/attr/meteo/me45";
import me48 from "@/attr/meteo/me48";
import sinoptik from "@/attr/derivedata/meteo/sinop";

import metar from "@/attr/derivedata/meteo/metar";
import speci from "@/attr/derivedata/meteo/speci";
import metarspecy from "@/attr/derivedata/meteo/metarspecy";

import pibal from "@/attr/derivedata/meteo/pibal";
import JsPDFAutotable from "jspdf-autotable";
import jsPDF from "jspdf";
import moment from "moment";

export default {
  components: {
    AlertPlugin,
    BOverlay,
    VueHtml2pdf,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    vSelect,
    jsPDF,
    JsPDFAutotable,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormSelect,
    BButton,
    BButtonGroup,
    flatPickr,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormDatepicker,
    BTable,
    BAvatar,
    BBadge,
    BPagination,
    BInputGroupAppend,
    BPopover,
    BFormTextarea,
    BModal,
    BForm,
    VBTooltip
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      
      dpconfig: {
      
        plugins: [
            new monthSelectPlugin({
              shorthand: true,
              dateFormat: "m/Y",
            })
          ]
      },

      escapeCsv: false,
      form_name_exp_pdf: "",
      form_name_exp_xls: "",

      start_date : "",
      end_date : "",
      perPage: 20,
      pageOptions: [5, 10, 20, 50],
      sortDate: "Asc",
      sortDateOpt: ["Asc", "Desc"],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      fields: [],
      items: [],
      itemsCsv: [],

      t_form: "",
      t_form_opt: [],
      t_mkg: "",
      t_mkg_opt: [
        { value: "meteo", text: "Meteorologi" },
        { value: "klimat", text: "Klimatologi" },
        { value: "geofis", text: "Geofisika" }
      ],
      t_temporal_data:"",
      t_temp_data_opt: [
        { value: "jam", text: "Jam" },
        { value: "harian", text: "Harian" },
        { value: "dasarian", text: "Dasarian" },
        { value: "bulanan", text: "Bulanan" },
      ],

      t_from_m: [
        { value: "MetarSpeci", text: "MetarSpeci" },
        { value: "Pibal", text: "Pibal" },
        { value: "sinoptik", text: "Sinop" },
      ],
      t_from_g: [
        { value: "Magnet", text: "Magnet" },
      ],
      t_from_k: [
        // { value: "Agm1a", text: "Agm1a" },
        // { value: "Agm1b", text: "Agm1b" },
        { value: "Aerosol", text: "Aerosol" },
        { value: "Aktinograph", text: "Aktinograph" },
        { value: "Barograph", text: "Barograph" },
        // { value: "Fenologi", text: "Fenologi" },
        { value: "Fklim", text: "Fklim 71" },
        { value: "FormHujan", text: "Form Hujan" },
        { value: "FormPerawanan", text: "Form Perawanan" },
        { value: "Gunbellani", text: "Gunbellani" },
        { value: "IklimMikro", text: "Iklim Mikro" },
        { value: "HujanHellman", text: "Intensitas Hujan(Hellman)" },
        { value: "Kah", text: "Kimia Air Hujan" },
        { value: "KelembabanTanah", text: "Kelembaban Tanah" },
        { value: "LamaPenyinaran", text: "Lama Penyinaran" },
        { value: "Lysimeter", text: "Lysimeter" },
        { value: "OpPenguapan", text: "OP Penguapan" },
        { value: "PichePenguapan", text: "Piche Penguapan" },
        { value: "PsycrometerAssman", text: "Psychrometer Assman" },
        { value: "So2no2", text: "SO2&NO2" },
        { value: "SuhuTanah", text: "Suhu Tanah" },
        { value: "Spm", text: "Suspended Particulate Matter" },
        { value: "Thermohigograph", text: "Thermohigrograph" },
      ],
      group:null,
      group_opt:[],
      cardTable: true,
      checkall: [],
      uncheckall: [],
      cardCheckbox: [],
      arr_group: [],
      selectedCheck: [],
      sumbitShow: false,

      temporal:null,
      temporal_opt:[
        { value: "jam", text: "Jam" },
        { value: "harian", text: "Harian" },
        { value: "dasarian", text: "Dasarian" },
        { value: "bulanan", text: "Bulanan" },
      ],
      regions: "",
      regions_opt: [],
      propinsi: "",
      propinsi_opt: [],
      kabupaten: "",
      kabupaten_opt: [],
      stasiun: "",
      stasiun_opt: [],

      is_bmkg_entry:null,
      is_balai_selected:null,
      is_prov_selected:null,
      is_kab_selected:null,
      g_all: {},
      g_all_daily: {},
      rangeDate: null,
      dir: "ltr",
      showLoading:false,
      ready_export:false,


    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
   this.listBalai(); 
   this.callGroupForm();
   this.totalRows = this.items.length;
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async showTable(){
      // this.showLoading=true;
      // setTimeout((arr)=>{
      //   this.showLoading=false;
      //   this.$swal({
      //       title: "ERROR",
      //       text: "Api Belum Terintegrasi ",
      //       icon: "error",
      //       customClass: {
      //         confirmButton: "btn btn-danger",
      //       },
      //       buttonsStyling: false,
      //     });
      // }, 2000);
      this.sortDate = "Asc";
      // console.log("stasiun : ",this.stasiun_opt)
      // return
      var stasiun_opt_temp = this.stasiun_opt;
      var stasiun_tmp = [];
      stasiun_opt_temp.forEach((o) => {
        if (o.value !== "") stasiun_tmp.push(o.value);
      });
      if (this.regions == null || this.propinsi == null || this.kabupaten == null) {
        this.$swal({
          title: "ERROR",
          text: "Silahkan Lengkapi Filter",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        return;
      }
      this.pibaldataform = false;
      this.stasiun_arr = [];
      this.STORE_EXPORT_PIAS = [];
      // console.log("array : ",this.selectedCheck)
      // console.log("region : ",this.regions.value);
      // console.log("propinsi : ",this.propinsi);
      // console.log("kabupaten : ",this.kabupaten);
      let reg_selected = this.regions.value;
      let prov_selected = this.propinsi.value;
      if (prov_selected == "") {
        prov_selected = null;
      }
      let kab_selected = this.kabupaten.value;
      if (kab_selected == "") {
        kab_selected = null;
      }
      // if (this.rangeDate == null || reg_selected == "") {
      if (this.start_date == null || this.end_date == null || reg_selected == "") {
        this.$swal({
          title: "ERROR",
          text: "Silahkan Lengkapi Filter",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        return;
      }
      if (this.selectedCheck == "" || this.selectedCheck == null) {
        this.$swal({
          title: "ERROR",
          text: "Silahkan Pilih(ceklis) Parameter yang diinginkan",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        return;
      }

      console.log('start_date', this.start_date, this.end_date)
      var s_date = this.start_date.split('/')
      var e_date = this.end_date.split('/')
      // let s_tgl = new Date(s_date[1], s_date[0], 0)
      let e_tgl = new Date(e_date[1], e_date[0], 0)
      var date_gte = s_date[1]+"-"+s_date[0]+"-01T00:00"
      var date_lte = e_date[1]+"-"+e_date[0]+"-"+e_tgl.getDate()+"T23:59"
      console.log('date_gte', date_gte, date_lte, e_tgl )

      // var rangeDate_arr = this.rangeDate.split("to");
      // // console.log("date", rangeDate_arr[1]);
      // var date_gte = "";
      // var date_lte = "";
      // var twoDate = false;
      // if (rangeDate_arr[1] == undefined) {
      //   // this.$swal({
      //   //   title: "ERROR",
      //   //   text: "Silahkan Isi Range Date dengan benar",
      //   //   icon: "error",
      //   //   customClass: {
      //   //     confirmButton: "btn btn-danger",
      //   //   },
      //   //   buttonsStyling: false,
      //   // });
      //   // return;

      //   date_gte = rangeDate_arr[0].trim() + "T00:00";
      //   date_lte = rangeDate_arr[0].trim() + "T23:59";
      // } else {
      //   twoDate = true;
      //   date_gte = rangeDate_arr[0].trim() + "T00:00";
      //   date_lte = rangeDate_arr[1].trim();
      // }
      this.showLoading = true;
      // this.csvfilename = this.type_object + " " + this.rangeDate + ".csv";
      let rdate = s_date[1]+"-"+s_date[0]+"-01 to "+e_date[1]+"-"+e_date[0]+"-"+e_tgl.getDate()
      this.csvfilename = this.type_object + " " + rdate + ".csv";
      this.rawpibalname = "Raw Pibal" + this.rangeDate + ".csv";
      this.datapibalname = "Raw Pibal" + this.rangeDate + ".csv";

      for (var i in this.stasiun) {
        if (this.stasiun[i].value !== "") {
          this.stasiun_arr.push(this.stasiun[i].value);
        }
      }
      var _statiun = this.stasiun_arr.toString();
      // console.log(_statiun);
      if (_statiun == "") {
        var temp_s = stasiun_tmp.toString();
        if (temp_s == "") {
          _statiun = null;
        } else {
          _statiun = temp_s;
        }
      }

      var _metadata_arr = [];
      _metadata_arr = this.selectedCheck;
      //  console.log("met arr : ",_metadata_arr);
      //   console.log("selectedCheck : ",this.selectedCheck);
      var _metadata = "";
      _metadata = "station_id,station_name,data_timestamp,alias_station_id,source_data," + _metadata_arr.toString();
      // _metadata = "station_id,station_name,data_timestamp,current_latitude,current_longitude," + _metadata_arr.toString();

      var _headTable = _metadata.split(",");
      // console.log(_metadata);
      // console.log(_headTable);
      // this.fields = _headTable
      this.ready_export = true;
      this.items = [];
      var currentFrom = this.type_object;
      if (this.type_object == "me45" || this.type_object == "me48" || this.type_object == "sinoptik") {
        currentFrom = "Sinoptik";
      } else if (this.type_object == "metar" || this.type_object == "speci" || this.type_object == "MetarSpeci") {
        currentFrom = "MetarSpeci";
      }

      if (currentFrom == "Kah" || currentFrom == "Aerosol" || currentFrom == "Spm" || currentFrom == "So2no2") {
        var _metadata_KU = "";
        // _metadata_KU = "station_id,station_name,tgl_pasang,tgl_angkat,alias_station_id,source_data,tahun_form,periode_form," + _metadata_arr.toString() + ",qc_flag,qc_histories";
        _metadata_KU = "station_id,station_name,data_timestamp," + _metadata_arr.toString();
        var FORM_KU = currentFrom;
        if (currentFrom == "Aerosol") {
          FORM_KU = "DSM_Aerosol";
        } else if (currentFrom == "Kah") {
          FORM_KU = "DSM_KAH";
        } else if (currentFrom == "So2no2") {
          FORM_KU = "DSM_SO2NO2";
        } else if (currentFrom == "Spm") {
          FORM_KU = "DSM_SPM";
        }
        // if(currentFrom == "Kah" || currentFrom == "Spm" || currentFrom == "So2no2"){
        //   this.$swal({
        //     title: "ERROR",
        //     text: "Api Belum Terintegrasi ",
        //     icon: "error",
        //     customClass: {
        //       confirmButton: "btn btn-danger",
        //     },
        //     buttonsStyling: false,
        //   });
        //   this.ready_export = false;
        //   this.showLoading = false;
        //   return;
        // }
        try {
          console.log('getRawDataKU', FORM_KU, date_gte, date_lte, _statiun, _metadata_KU, reg_selected, prov_selected, kab_selected)
          const { data } = await outputexport.getRawDataKU(FORM_KU, date_gte, date_lte, _statiun, _metadata_KU, reg_selected, prov_selected, kab_selected);
          var temp_data_ku = [],
              temp_data_csv = [];
          let params = this.callGroupFormCsv(this.type_object)[0]['attr']
          console.log('params', params, this.callGroupFormCsv(this.type_object))
          for (var i in data) {
            var row = data[i];
            var row_ku = {};
            var row_csv = {}
            var tgl = row.data_timestamp.split('-')
            row_ku["station_id"] = row.station_id//row.alias_station_id;
            row_csv["station_id"] = row.station_id
            row_ku["station_name"] = row.station_name;
            row_csv["station_name"] = row.station_name; 
            row_ku["latitude"] = row.current_latitude;
            row_csv["latitude"] = row.current_latitude; 
            row_ku["longitude"] = row.current_longitude;
            row_csv["longitude"] = row.current_longitude;
            // row_ku["periode"] = row.periode_form;
            // row_ku["tahun"] = row.tahun_form.substr(0, 4);
            row_ku["bulan"] = (tgl.length > 2) ? (tgl[0]+"-"+tgl[1]) : row.data_timestamp;
            row_csv["bulan"] = (tgl.length > 2) ? (tgl[0]+"-"+tgl[1]) : row.data_timestamp;
            // row_ku["tgl_angkat"] = row.tgl_angkat.replace("T", " ");;
            for (var k in row) {
              // console.log("k : ",k)
              if (k == "alias_station_id" || k == "station_id" || k == "station_name" || k == "data_timestamp" || k == "qc_flag" ||
                k == "tgl_angkat" || k == "source_data" || k == "tahun_form" || k == "periode_form" || k == "qc_histories" ||
                k == "current_latitude" || k == "current_longitude") {
                // console.log("continue : ",k)
                continue;
              } else {
                // row_ku[k] = row[k];
                let value = this.kuValue(currentFrom, k, row[k]);
                row_ku[k] = value
                // ONLY FOR CSV LABKU
                row_csv[params[k]] = value//row[k]
              }

            }

            // row_ku["qc_flag"] = row.qc_flag;
            // if (row_ku["qc_flag"] == 1 || row_ku["qc_flag"] == 2) {
            //   let JsonQc = JSON.parse(row.qc_flag);
            //   let Qc_His = JsonQc.after;
            //   var Log_QC_His = "";
            //   for (var i_qc in Qc_His) {
            //     Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
            //   }
            //   let len = Log_QC_His.length;
            //   let log = Log_QC_His;
            //   if (len > 0) {
            //     log = Log_QC_His.substring(0, len - 1);
            //   }

            //   row_ku["qc_parameter"] = log;
            // } else {
            //   row_ku["qc_parameter"] = "";
            // }
            temp_data_ku.push(row_ku);
            temp_data_csv.push(row_csv);
          }

          // console.log('temp_data_csv', temp_data_csv)
          this.STORE_EXPORT_PIAS = temp_data_ku;
          this.items = temp_data_ku;
          this.itemsCsv = temp_data_csv;
          this.totalRows = this.items.length;
          this.showLoading = false;
        } catch (e) {
          console.log('e', e)
          this.showLoading = false;
        }

      }

    },
    
    kuValue(form, param, value) {
      let attr = null
      if (form == "Aerosol") {
        attr = aerosol.getAttrMonthlyDecimal()[0].attr
      }
      else if (form == "Kah") {
        attr = kah.getAttrMonthlyDecimal()[0].attr
      }
      else if (form == "So2no2") {
        attr = so2no2.getAttrMonthlyDecimal()[0].attr
      }
      else if (form == "Spm") {
        attr = spm.getAttrMonthlyDecimal()[0].attr
      }
      // console.log('attr', attr, form, param, value)
      let newValue = value
      let decimal = (attr && attr.hasOwnProperty(param)) ? attr[param] : -1
      
      if (value && decimal != -1) {
        newValue = value.toFixed(decimal)
      }

      return newValue
    },

    previewGroup() {
      this.cardCheckbox = [];
      this.selectedCheck = [];
      this.checkall = [];
      // this.col_card = this.temp_cols;
      // console.log("Selected ", this.selectedCheck);
      console.log("leng grup : ", this.group.length);
      let leng_cols_current = this.group.length;
      if (leng_cols_current < 3) {
        this.col_card = 6;
      } else {
        this.col_card = 4;
      }
      for (var obj in this.group) {
        var attrib = [];
        var groupData = parseInt(this.group[obj].value);
        // console.log("objenya : ", groupData);
        for (var atrb in this.arr_group[groupData].attr) {
          var row = {
            text: this.arr_group[groupData].attr[atrb],
            value: atrb,
          };
          // console.log(row)
          attrib.push(row);
        }
        // console.log("attrib : ",attrib)
        var data = {
          label: this.arr_group[groupData].label,
          attr: attrib,
        };
        this.cardCheckbox.push(data);
        this.sumbitShow = true;
        // console.log("data : ",data)
      }
    },


    callGroupForm(){

      this.g_all = {
        sinoptik: sinoptik.getAttrMonthly()
      };

      this.g_all_daily = {
        sinoptik: sinoptik.getAttrDaily()
      }
    }, 
    
    callGroupFormCsv(type){
      console.log('callGroupFormCsv', type)
      let group_all = {
        LamaPenyinaran: lamapenyinaran.getAttr(),
        Aktinograph: aktinograph.getAttr(),
        Thermohigograph: thermohigrograph.getAttr(),
        HujanHellman: intentitashujan.getAttr(),
        Barograph: barograph.getAttr(),
        KelembabanTanah: kelembabantanah.getAttr(),
        FormPerawanan: perawanan.getAttr(),
        Fenologi: fenologi.getAttr(),
        FormHujan: formhujan.getAttr(),
        Aerosol:aerosol.getAttrMonthlyCsv(),
        Kah:kah.getAttrMonthlyCsv(),
        So2no2:so2no2.getAttrMonthlyCsv(),
        Spm:spm.getAttrMonthlyCsv(),
        Fklim: fklim.getAttr(),
        Agm1a: agm1a.getAttr(),
        Agm1b: agm1b.getAttr(),
        Gunbellani: gunbellani.getAttr(),
        IklimMikro: iklimmikro.getAttr(),
        Lysimeter: lysimeter.getAttr(),
        PichePenguapan: pichepenguapan.getAttr(),
        PsycrometerAssman: psycrometerassman.getAttr(),
        SuhuTanah: suhutanah.getAttr(),
        OpPenguapan: oppenguapan.getAttr(),

        metar: metar.getAttr(),
        speci: speci.getAttr(),

        sinoptik: sinoptik.getAttrMonthlyCsv(),
        MetarSpeci: metarspecy.getAttr(),

        Pibal: pibal.getAttr(),
      };

      return group_all[type]
    }, 

    async listBalai() {
      try {
        const { data } = await metadata.getRegionList();
        for (var i = 0; i < data.length; i++) {
          const dataRegion = {
            value: data[i].region_id,
            text: data[i].region_description,
          };
          this.regions_opt.push(dataRegion);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async listPropinsi(region_id) {
      this.propinsi_opt = [{ value: "", text: "Semua Propinsi" }];
      try {
        const { data } = await metadata.getPropinsiListByRegion(region_id);
        // console.log(data);
        for (var i = 0; i < data.length; i++) {
          const rowsPropinsi = {
            value: data[i].propinsi_id,
            text: data[i].propinsi_name,
          };
          this.propinsi_opt.push(rowsPropinsi);

          // this.items.push(dataPropinsi);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async listKabupaten(propinsi_id) {
      if (propinsi_id != "") {
        this.stasiun_opt = [];
        this.kabupaten_opt = [{ value: "", text: "Semua Kota/Kabupaten" }];
        try {
          const { data } = await metadata.getKabupatenListByPropinsi(propinsi_id);
          // console.log(data);
          for (var i = 0; i < data.length; i++) {
            const rowsKabupaten = {
              value: data[i].kabupaten_id,
              text: data[i].kabupaten_name,
            };
            this.kabupaten_opt.push(rowsKabupaten);
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    async listStasiunReg(reg_id) {
      this.showLoading = true;
      this.stasiun_opt = [];
      this.stasiun_opt = [{ value: "", text: " - Semua Stasiun" }];
      try {
        // const { data } = await metadata.getStasiunByRegion(reg_id);
        const { data } = await metadata.getExStasiunByBalai(reg_id, this.is_bmkg_entry);
        // console.log(data);
        for (var i = 0; i < data.length; i++) {
          const rowsStasiun = {
            value: data[i].station_id,
            text: data[i].station_name,
          };
          this.stasiun_opt.push(rowsStasiun);
        }
        var sort_data = this.stasiun_opt;
        // console.log("sort : ", sort_data)
        sort_data.sort((a, b) => {
          let fa = a.text;
          let fb = b.text;

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        this.stasiun_opt = sort_data;
        this.showLoading = false;
      } catch (e) {
        console.log(e);
        this.showLoading = false;
      }
    },
    async listStasiunProv(prov_id) {
      // console.log("prov_id : ",prov_id)
      if (prov_id == "") {
        console.log("prov_id kosong");
      } else {
        this.showLoading = true;
        this.stasiun_opt = [{ value: "", text: " - Semua Stasiun" }];
        try {
          const { data } = await metadata.getExStasiunByProv(prov_id, this.is_bmkg_entry);
          // const { data } = await metadata.getStasiunByPropinsi(prov_id);
          // console.log(data);
          for (var i = 0; i < data.length; i++) {
            const rowsStasiun = {
              value: data[i].station_id,
              text: data[i].station_name,
            };
            this.stasiun_opt.push(rowsStasiun);
          }
          var sort_data = this.stasiun_opt;
          sort_data.sort((a, b) => {
            let fa = a.text;
            let fb = b.text;

            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          this.stasiun_opt = sort_data;
          this.showLoading = false;
        } catch (e) {
          console.log(e);
          this.showLoading = false;
        }
      }
    },
    async listStasiun(kab_id) {
      if (kab_id == "") {
        console.log("kab kosong");
      } else {
        this.showLoading = true;
        this.stasiun_opt = [];
        this.stasiun_opt = [{ value: "", text: " - Semua Stasiun" }];
        try {
          const { data } = await metadata.getExStasiunByKab(kab_id, this.is_bmkg_entry);
          // const { data } = await metadata.getStasiunByKabupaten(kab_id);
          // console.log(data);
          for (var i = 0; i < data.length; i++) {
            const rowsStasiun = {
              value: data[i].station_id,
              text: data[i].station_name,
            };
            this.stasiun_opt.push(rowsStasiun);
          }
          var sort_data = this.stasiun_opt;
          sort_data.sort((a, b) => {
            let fa = a.text;
            let fb = b.text;

            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          this.stasiun_opt = sort_data;
          this.showLoading = false;
        } catch (e) {
          console.log(e);
          this.showLoading = false;
        }
      }
    },
    checkStarEndDate(bln){
      // console.log(this.start_date)
      // console.log(this.end_date)
      var ds = moment(this.start_date, "MM/YYYY")
      var de = moment(this.end_date, "MM/YYYY");
      var a = ds.isBefore(de) // false
      var b = ds.isAfter(de)
      // console.log("a = "+a+" b = "+b)
      if(!a && b){
        if(bln == "start"){
          this.start_date = "";
        }else if(bln == "end"){
          this.end_date = ""
        }
        // alert("Bulan Akhir harus lebih besar/sama dari bulan "+ this.start_date)
        this.$swal({
            title: "ERROR",
            text: "Bulan Akhri harus sama/lebih besar dari bulan awal ",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
      }
    }
  },
  watch: {
    start_date(val){
      this.checkStarEndDate("start")
    },
    end_date(val){
      this.checkStarEndDate("end")
    },
    t_mkg(val) {
      // console.log("val :", val);
      if (val.value == "meteo") {
        this.t_form_opt = this.t_from_m;
      } else if (val.value == "klimat") {
        this.t_form_opt = this.t_from_k;
      } else if (val.value == "geofis") {
        this.t_form_opt = this.t_from_g;
      } else {
        this.t_form_opt = [];
      }
      this.t_form = "";
      this.group = "";
    },

    t_form(val) {
      this.selectedCheck = [];
      this.checkall = [];
      this.cardCheckbox = [];

      console.log("form select : ",val)
      // console.log("form op  : ",this.g_all[val.value])
      // console.log("selected cek cek : ",this.selectedCheck)
      if (val.value == "Aerosol" || val.value == "Spm" || val.value == "Kah" || val.value == "So2no2" ) {
        this.t_temp_data_opt = [
          { value: "jam", text: "Jam", disabled: true },
          { value: "harian", text: "Harian", disabled: true },
          { value: "dasarian", text: "Dasarian", disabled: true },
          { value: "bulanan", text: "Bulanan", disabled: false },
        ]
      }
      else {
        this.t_temp_data_opt = [
          { value: "jam", text: "Jam", disabled: false },
          { value: "harian", text: "Harian", disabled: false },
          { value: "dasarian", text: "Dasarian", disabled: false },
          { value: "bulanan", text: "Bulanan", disabled: false },
        ]
      }

      if (val != null) {
        this.is_bmkg_entry = val.value;
        if (this.is_kab_selected != "") {
          this.listStasiun(this.is_kab_selected);
        } else if (this.is_prov_selected != "") {
          this.listStasiunProv(this.is_prov_selected);
        } else if (this.is_balai_selected != "") {
          this.listStasiunReg(this.is_balai_selected);
        }

        var data = [];
        for (var obj in this.g_all[val.value]) {
          var rowData = {
            value: obj,
            text: this.g_all[val.value][obj].label,
          };
          data.push(rowData);
        }
        var temp_cols_card = 3;
        var leng_cols = this.g_all[val.value].length;
        if (leng_cols < 2) {
          temp_cols_card = 12;
        } else if (temp_cols_card < 3) {
          temp_cols_card = 6;
        } else {
          temp_cols_card = 4;
        }
        this.temp_cols = temp_cols_card;

        this.arr_group = this.g_all[val.value];
        this.group_opt = data;
        this.type_object = val.value;

        
      }
      

      this.group = "";
    },

    t_temporal_data(val) {
      
      this.callGroupForm()
      this.callGroupFormCsv('sinoptik')
      console.log('t_temporal_data', val, this.g_all)
      var data = [];
      if (val.value == "bulanan") {
        this.group = null
        this.group_opt = []
        for (var obj in this.g_all['sinoptik']) {
          var rowData = {
            value: obj,
            text: this.g_all['sinoptik'][obj].label,
          };
          data.push(rowData);
        }
        var temp_cols_card = 3;
        var leng_cols = this.g_all['sinoptik'].length;
        if (leng_cols < 2) {
          temp_cols_card = 12;
        } else if (temp_cols_card < 3) {
          temp_cols_card = 6;
        } else {
          temp_cols_card = 4;
        }
        this.temp_cols = temp_cols_card;

        this.arr_group = this.g_all['sinoptik'];
        this.group_opt = data;
      }
      else if (val.value == "harian") {
        this.group = null
        this.group_opt = []
        for (var obj in this.g_all_daily['sinoptik']) {
          var rowData = {
            value: obj,
            text: this.g_all_daily['sinoptik'][obj].label,
          };
          data.push(rowData);
        }
        var temp_cols_card = 3;
        var leng_cols = this.g_all_daily['sinoptik'].length;
        if (leng_cols < 2) {
          temp_cols_card = 12;
        } else if (temp_cols_card < 3) {
          temp_cols_card = 6;
        } else {
          temp_cols_card = 4;
        }
        this.temp_cols = temp_cols_card;

        this.arr_group = this.g_all_daily['sinoptik'];
        this.group_opt = data;
      }
      else {

        this.group = null
        this.group_opt = []
        this.regions = ""
        // this.regions_opt = []
        this.propinsi_opt = []
        this.propinsi = ""
        this.kabupaten_opt = []
        this.kabupaten = ""
        this.stasiun_opt = []
        this.stasiun = ""
      }
    },

    regions(val) {
      console.log('regions', val)
      if (val != null || val == "") {
        console.log('this.group', this.group)
        if (!this.group) {
          // this.$swal({
          //   title: "ERROR",
          //   text: "Silahkan Isi Form ",
          //   icon: "error",
          //   customClass: {
          //     confirmButton: "btn btn-danger",
          //   },
          //   buttonsStyling: false,
          // });
          // this.regions = null;
          return;
        }
        this.is_balai_selected = val.value;
        this.listPropinsi(val.value);
        this.listStasiunReg(val.value);
        this.propinsi = { value: "", text: "Semua Propinsi" };
      } else {
        this.propinsi_opt = [];
        this.propinsi = "";
        this.kabupaten_opt = [];
        this.kabupaten = "";
        this.stasiun_opt = [];
        this.stasiun = "";
      }
    },
    propinsi(val) {
      if (val != null || val == "") {
        this.is_prov_selected = val.value;
        this.listKabupaten(val.value);
        this.listStasiunProv(val.value);
        this.kabupaten = { value: "", text: "Semua Kota/Kabupaten" };
      } else {
        this.kabupaten_opt = [];
        this.kabupaten = "";
        this.stasiun_opt = [];
        this.stasiun = "";
      }
    },
    kabupaten(val) {
      console.log("kab val : ", val);
      if (val != null || val == "") {
        this.is_kab_selected = val.value;
        this.listStasiun(val.value);
        this.stasiun = "";
      } else {
        this.stasiun_opt = [];
        this.stasiun = "";
      }
    },

    checkall() {
      // console.log("cek : ",this.selectedCheck)
      // Helper.DELETE_ARRAY(this.selectedCheck,)
      this.selectedCheck = [];

      for (var obj in this.checkall) {
        var cek = this.checkall[obj];
        var is_data = this.uncheckall.indexOf(cek);
        if (is_data != "-1") {
          this.uncheckall.splice(is_data, 1);
        }
        var row = this.checkall[obj].attr;
        for (var i in row) {
          if (this.selectedCheck.indexOf(row[i].value) == "-1") {
            // console.log("daaaa : ",row[i])
            this.selectedCheck.push(row[i].value);
          }
        }
      }
    },
    uncheckall() {
      // console.log("uncek : ",this.uncheckall)
      for (var obj in this.uncheckall) {
        var uncek = this.uncheckall[obj];
        var is_data = this.checkall.indexOf(uncek);
        if (is_data != "-1") {
          this.checkall.splice(is_data, 1);
        }
        // console.log("uncek : ",this.checkall.indexOf(uncek))
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style scoped>
.pad1 {
  padding-right: 0px;
}

.pad2 {
  padding-right: 0px;
  padding-left: 0px;
}
</style>

<style>
    .disabled {
      pointer-events:none;
      color: #bfcbd9;
      cursor: not-allowed;
      background-image: none;
      background-color: #eef1f6;
      border-color: #d1dbe5;   
    }
  </style>
