import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
        {
            label: "Sinoptik",
            attr: {
                "cloud_cover_oktas_m":"Bagian Langit Tertutup Awan (oktas)",
                "cloud_low_type_cl":"Awan Rendah",
                "evaporation_24hours_mm_eee":"Penguapan (mm)",
                "rainfall_last_mm":"Hujan 24 jam",
                "solar_rad_24h_jcm2_f24":"Radiasi Matahari (Joule/cm2)",
                "sunshine_h_sss":"Lama Penyinaran Matahari (jam)",
                "temp_dewpoint_c_tdtdtd":"Suhu Titik Embun",
                "temp_drybulb_c_tttttt":"Suhu Bola Kering (℃)",
                "temp_max_c_txtxtx":"Suhu Maksimum (℃)",
                "temp_min_c_tntntn":"Suhu Minimum (℃)",
                "wind_dir_deg_dd":"Arah Angin (derajat)",
                "wind_speed_ff":"Kecepatan Angin (knot)",
                "relative_humidity_pc":"Kelembaban Udara",
                "pressure_qff_mb_derived":"Tekanan QFF",
                "pressure_qfe_mb_derived":"Tekanan QFE"                
                },  
        },
        
        ]
        return listAttr;
    },

    getAttrMonthly() {
        const listAttr = [
            {
                label: "Angin",
                attr: {
                    "wind_dir_cardinal_monthly_count":"Arah Angin (derajat) COUNT MONTHLY",
                    "c_wind_dir_cardinal_monthly": "Jumlah Data Arah Angin (derajat) MONTHLY",
                    "wind_speed_ff_monthly_max":"Kecepatan Angin (knot) MAX MONTHLY",     
                    "c_wind_speed_ff_monthly": "Jumlah Data Kecepatan Angin (knot) MONTHLY"       
                },  
            },
            {
                label: "Tekanan",
                attr: {
                    "pressure_qff_mb_derived_monthly_max":"Tekanan QFF MAX MONTHLY",
                    "pressure_qff_mb_derived_monthly_min":"Tekanan QFF MIN MONTHLY",
                    "pressure_qff_mb_derived_monthly_avg":"Tekanan QFF AVG MONTHLY",
                    "c_pressure_qff_mb_derived_monthly": "Jumlah Data Tekanan QFF AVG MONTHLY",
                    "pressure_qfe_mb_derived_monthly_max":"Tekanan QFE MAX MONTHLY",
                    "pressure_qfe_mb_derived_monthly_min":"Tekanan QFE MIN MONTHLY",
                    "pressure_qfe_mb_derived_monthly_avg":"Tekanan QFE AVG MONTHLY",
                    "c_pressure_qfe_mb_derived_monthly": "Jumlah Data Tekanan QFE MONTHLY"          
                },  
            },
            {
                label: "Suhu",
                attr: {
                    "temp_dewpoint_monthly_avg_C":"Suhu Titik Embun AVG MONTHLY",
                    "temp_dewpoint_monthly_min_C":"Suhu Titik Embun MIN MONTHLY",
                    "temp_dewpoint_monthly_max_C":"Suhu Titik Embun MAX MONTHLY",
                    "c_temp_dewpoint_monthly_C": "Jumlah Data Suhu Titik Embun MONTHLY",
                    "temp_drybulb_monthly_avg_C":"Suhu Bola Kering (℃) AVG MONTHLY",
                    "temp_drybulb_monthly_min_C":"Suhu Bola Kering (℃) MIN MONTHLY",
                    "temp_drybulb_monthly_max_C":"Suhu Bola Kering (℃) MAX MONTHLY",
                    "c_temp_drybulb_monthly_C": "Jumlah data Suhu Bola Kering (℃) MONTHLY",
                    "temp_max_monthly_avg_C":"Suhu Maksimum (℃) AVG MONTHLY",
                    "temp_max_monthly_min_C":"Suhu Maksimum (℃) MIN MONTHLY",
                    "temp_max_monthly_max_C":"Suhu Maksimum (℃) MAX MONTHLY",
                    "c_temp_max_monthly_C": "Jumlah Data Suhu Maksimum (℃) MONTHLY",
                    "temp_min_monthly_avg_C":"Suhu Minimum (℃) AVG MONTHLY",
                    "temp_min_monthly_min_C":"Suhu Minimum (℃) MIN MONTHLY",
                    "temp_min_monthly_max_C":"Suhu Minimum (℃) MAX MONTHLY",
                    "c_temp_min_monthly_C": "Jumlah Data Suhu Minimum (℃) MONTHLY",
                    "relative_humidity_pc_monthly_max":"Kelembaban Udara MAX MONTHLY",
                    "relative_humidity_pc_monthly_min":"Kelembaban Udara MIN MONTHLY",  
                    "relative_humidity_pc_monthly_avg":"Kelembaban Udara AVG MONTHLY",  
                    "c_relative_humidity_pc_monthly": "Jumlah Data Kelembaban Udara MONTHLY"          
                },  
            },
            {
                label: "Sandi & Lain-Lain",
                attr: {
                    "evaporation_24hours_mm_eee_monthly_total":"Penguapan (mm) TOTAL MONTHLY",
                    "evaporation_24hours_mm_eee_monthly_max":"Penguapan (mm) MAKSIMUM MONTHLY",
                    "evaporation_24hours_mm_eee_monthly_min":"Penguapan (mm) MINIMUM MONTHLY",
                    "c_evaporation_24hours_mm_eee_monthly": "Jumlah Data Penguapan (mm) MONTHLY",
                    "rainfall_24h_rrr_monthly_total":"Hujan 24 jam TOTAL MONTHLY",
                    "rainfall_24h_rrr_monthly_max":"Hujan 24 jam MAX MONTHLY",
                    "rainfall_24h_rrr_monthly_count":"Hujan 24 jam COUNT MONTHLY",
                    "c_rainfall_24h_rrr_monthly": "Jumlah Data Hujan 24 jam MONTHLY",
                    "solar_rad_24h_jcm2_f24_monthly_avg":"Radiasi Matahari (Joule/cm2) AVG MONTHLY",
                    "solar_rad_24h_jcm2_f24_monthly_min":"Radiasi Matahari (Joule/cm2) MIN MONTHLY",
                    "solar_rad_24h_jcm2_f24_monthly_max":"Radiasi Matahari (Joule/cm2) MAX MONTHLY",
                    "c_solar_rad_24h_jcm2_f24_monthly": "Jumlah Data Radiasi Matahari (Joule/cm2) MONTHLY",
                    "sunshine_h_sss_monthly_avg":"Lama Penyinaran Matahari (jam) AVG MONTHLY",
                    "sunshine_h_sss_monthly_min":"Lama Penyinaran Matahari (jam) MIN MONTHLY",
                    "sunshine_h_sss_monthly_max":"Lama Penyinaran Matahari (jam) MAX MONTHLY",
                    "sunshine_h_sss_monthly_total":"Lama Penyinaran Matahari (jam) TOTAL MONTHLY",
                    "c_sunshine_h_sss_monthly": "Jumlah Data Lama Penyinaran Matahari (jam) MONTHLY"         
                },  
            },
        ]
        return listAttr;
    },

    getAttrMonthlyCsv() {
        const listAttr = [
            {
                label: "Angin",
                attr: {
                    "wind_dir_deg_dd":"Arah Angin (derajat)",
                    "wind_speed_ff":"Kecepatan Angin (knot)",            
                },  
            },
            // {
            //     label: "Cuaca",
            //     attr: {           
            //     },  
            // },
            {
                label: "Tekanan",
                attr: {
                    "pressure_qff_mb_derived":"Tekanan QFF",
                    "pressure_qfe_mb_derived":"Tekanan QFE"              
                },  
            },
            {
                label: "Suhu",
                attr: {
                    "temp_dewpoint_c_tdtdtd":"Suhu Titik Embun",
                    "temp_drybulb_c_tttttt":"Suhu Bola Kering (℃)",
                    "temp_max_c_txtxtx":"Suhu Maksimum (℃)",
                    "temp_min_c_tntntn":"Suhu Minimum (℃)",
                    "relative_humidity_pc":"Kelembaban Udara",            
                },  
            },
            {
                label: "Awan Rendah",
                attr: {
                    "cloud_low_type_cl":"Awan Rendah",            
                },  
            },
            // {
            //     label: "Awan Mengengah",
            //     attr: {
            //         "cloud_low_type_cl":"Awan Rendah",           
            //     },  
            // },
            // {
            //     label: "Awan Tinggi",
            //     attr: {
            //         "cloud_low_type_cl":"Awan Rendah",             
            //     },  
            // },
            {
                label: "Sandi & Lain-Lain",
                attr: {
                    "cloud_cover_oktas_m":"Bagian Langit Tertutup Awan (oktas)",
                    "evaporation_24hours_mm_eee":"Penguapan (mm)",
                    "rainfall_last_mm":"Hujan 24 jam",
                    "solar_rad_24h_jcm2_f24":"Radiasi Matahari (Joule/cm2)",
                    "sunshine_h_sss":"Lama Penyinaran Matahari (jam)"            
                },  
            },
        ]
        return listAttr;
    },

    getAttrDaily() {
        const listAttr = [
            {
                label: "Angin",
                attr: {
                    "wind_dir_cardinal_daily":"Arah Angin (derajat) DAILY",
                    "c_wind_dir_cardinal_daily": "Jumlah Data Arah Angin (derajat) DAILY",
                    "wind_speed_ff_daily_max":"Kecepatan Angin (knot) MAX DAILY",
                    "c_wind_speed_ff_daily": "Jumlah Data Kecepatan Angin (knot) DAILY",
                    "visibility_vv_daily_min":"Jarak penglihatan mendatar (km) MIN DAILY",
                    "visibility_vv_daily_max":"Jarak penglihatan mendatar (km) MAX DAILY",
                    "c_visibility_vv_daily": "Jumlah Data Jarak penglihatan mendatar (km) DAILY",      
                },  
            },
            // {
            //     label: "Cuaca",
            //     attr: {           
            //     },  
            // },
            {
                label: "Tekanan",
                attr: {
                    "pressure_qff_mb_derived_daily_max_mb":"Tekanan QFF MAX DAILY",
                    "pressure_qff_mb_derived_daily_min_mb":"Tekanan QFF MIN DAILY",
                    "pressure_qff_mb_derived_daily_avg_mb":"Tekanan QFF AVG DAILY",
                    "pressure_qff_mb_derived_daily_avg_climat_mb":"Tekanan QFF AVG CLIMAT DAILY",
                    "c_pressure_qff_mb_derived_daily": "Jumlah Data Tekanan QFF DAILY",
                    "pressure_qfe_mb_derived_daily_max_mb":"Tekanan QFE MAX DAILY",
                    "pressure_qfe_mb_derived_daily_min_mb":"Tekanan QFE MIN DAILY",
                    "pressure_qfe_mb_derived_daily_avg_mb":"Tekanan QFE AVG DAILY",
                    "pressure_qfe_mb_derived_daily_avg_climat_mb":"Tekanan QFE AVG CLIMAT DAILY",
                    "c_pressure_qfe_mb_derived_daily":"Jumlah Data Tekanan QFE DAILY",          
                },  
            },
            {
                label: "Suhu",
                attr: {
                    "temp_dewpoint_daily_avg_C":"Suhu Titik Embun AVG DAILY",
                    "temp_dewpoint_daily_avg_climat_C":"Suhu Titik Embun AVG CLIMAT DAILY",
                    "temp_dewpoint_daily_min_C":"Suhu Titik Embun MIN DAILY",
                    "temp_dewpoint_daily_max_C":"Suhu Titik Embun MAX DAILY",
                    "c_temp_dewpoint_daily_C": "Jumlah Data Suhu Titik Embun DAILY",
                    "temp_drybulb_daily_avg_C":"Suhu Bola Kering (℃) AVG DAILY",
                    "temp_drybulb_daily_avg_climat_C":"Suhu Bola Kering (℃) AVG CLIMAT DAILY",
                    "temp_drybulb_daily_min_C":"Suhu Bola Kering (℃) MIN DAILY",
                    "temp_drybulb_daily_max_C":"Suhu Bola Kering (℃) MAX DAILY",
                    "c_temp_drybulb_daily_C": "Jumlah Data Suhu Bola Kering (℃) DAILY",
                    "temp_max_daily_C":"Suhu Maksimum (℃) DAILY",
                    "c_temp_max_daily_C": "Jumlah Data Suhu Maksimum (℃) DAILY",
                    "temp_min_daily_C":"Suhu Minimum (℃) DAILY",
                    "c_temp_min_daily_C": "Jumlah Data Suhu Minimum (℃) DAILY",
                    "relative_humidity_pc_daily_max_pc":"Kelembaban Udara MAX DAILY",
                    "relative_humidity_pc_daily_min_pc":"Kelembaban Udara MIN DAILY",        
                    "relative_humidity_pc_daily_avg_pc":"Kelembaban Udara AVG DAILY",
                    "relative_humidity_pc_daily_avg_climat_pc":"Kelembaban Udara AVG CLIMAT DAILY",
                    "c_relative_humidity_pc_daily": "Jumlah Data Kelembaban Udara DAILY",
                },  
            },
            {
                label: "Awan Rendah",
                attr: {
                    "cloud_low_type_cl_daily_modus":"Awan Rendah MODUS DAILY",            
                },  
            },
            // {
            //     label: "Awan Mengengah",
            //     attr: {
            //         "cloud_low_type_cl":"Awan Rendah",           
            //     },  
            // },
            // {
            //     label: "Awan Tinggi",
            //     attr: {
            //         "cloud_low_type_cl":"Awan Rendah",             
            //     },  
            // },
            {
                label: "Sandi & Lain-Lain",
                attr: {
                    "cloud_cover_oktas_m_daily_min":"Bagian Langit Tertutup Awan (oktas) MIN DAILY",
                    "cloud_cover_oktas_m_daily_max":"Bagian Langit Tertutup Awan (oktas) MAX DAILY",
                    "cloud_cover_oktas_m_daily_modus":"Bagian Langit Tertutup Awan (oktas) MODUS DAILY",
                    "c_cloud_cover_oktas_m_daily": "Jumlah Data Bagian Langit Tertutup Awan (oktas) DAILY ",
                    "evaporation_24hours_mm_eee_daily":"Penguapan (mm) DAILY",
                    "c_evaporation_24hours_mm_eee_daily": "Jumlah Data Penguapan (mm) DAILY",
                    "rainfall_24h_rrr_daily":"Hujan 24 jam DAILY",
                    "solar_rad_24h_jcm2_f24_daily":"Radiasi Matahari (Joule/cm2) DAILY",
                    "sunshine_h_sss_daily":"Lama Penyinaran Matahari (jam) DAILY",
                    "c_sunshine_h_sss_daily": "Jumlah Data Lama Penyinaran Matahari (jam) DAILY"            
                },  
            },
        ]
        return listAttr;
    },

    getAttrDailyCsv() {
        const listAttr = [
            {
                label: "Angin",
                attr: {
                    "wind_dir_deg_dd":"Arah Angin (derajat)",
                    "wind_speed_ff":"Kecepatan Angin (knot)",            
                },  
            },
            // {
            //     label: "Cuaca",
            //     attr: {           
            //     },  
            // },
            {
                label: "Tekanan",
                attr: {
                    "pressure_qff_mb_derived":"Tekanan QFF",
                    "pressure_qfe_mb_derived":"Tekanan QFE"              
                },  
            },
            {
                label: "Suhu",
                attr: {
                    "temp_dewpoint_c_tdtdtd":"Suhu Titik Embun",
                    "temp_drybulb_c_tttttt":"Suhu Bola Kering (℃)",
                    "temp_max_c_txtxtx":"Suhu Maksimum (℃)",
                    "temp_min_c_tntntn":"Suhu Minimum (℃)",
                    "relative_humidity_pc":"Kelembaban Udara",            
                },  
            },
            {
                label: "Awan Rendah",
                attr: {
                    "cloud_low_type_cl":"Awan Rendah",            
                },  
            },
            // {
            //     label: "Awan Mengengah",
            //     attr: {
            //         "cloud_low_type_cl":"Awan Rendah",           
            //     },  
            // },
            // {
            //     label: "Awan Tinggi",
            //     attr: {
            //         "cloud_low_type_cl":"Awan Rendah",             
            //     },  
            // },
            {
                label: "Sandi & Lain-Lain",
                attr: {
                    "cloud_cover_oktas_m":"Bagian Langit Tertutup Awan (oktas)",
                    "evaporation_24hours_mm_eee":"Penguapan (mm)",
                    "rainfall_last_mm":"Hujan 24 jam",
                    "solar_rad_24h_jcm2_f24":"Radiasi Matahari (Joule/cm2)",
                    "sunshine_h_sss":"Lama Penyinaran Matahari (jam)"            
                },  
            },
        ]
        return listAttr;
    },




    
}


    








