import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "Awan",
        attr: {
          ww_cerah: "Cerah",
          ww_berawan_sebagian: "Berawan Sebagian",
          ww_berawan: "Berawan",
          ww_berawan_banyak: "Berawan Banyak",
          obsawan_total_24: "Jumlah Pengamatan",
          // jumlah_observasi : "Jumlah Pengamatan",
          ket_perawanan: "Keterangan",
        },
      },
    ];

    return listAttr;
  },

  getAttrMonthly() {
    const listAttr = [
      {
        group: "Awan",
        form: "Perawanan",
        label: "Awan",
        attr: {
          // "obsawan_total_24_avg_monthly": "Jumlah Pengamatan AVG MONTHLY",
          // "obsawan_total_24_mod_monthly": "Jumlah Pengamatan MOD MONTHLY",
          // "obsawan_total_24_med_monthly": "Jumlah Pengamatan MED MONTHLY",
          // "obsawan_total_24_max_monthly": "Jumlah Pengamatan MAX MONTHLY",
          // "obsawan_total_24_min_monthly": "Jumlah Pengamatan MIN MONTHLY",
          // "obsawan_total_24_sum_monthly": "Jumlah Pengamatan SUM MONTHLY",
          // "obsawan_total_24_count_monthly": "Jumlah Pengamatan COUNT MONTHLY",
          // "ww_total_cerah_31_avg_monthly": "Cerah AVG MONTHLY",
          // "ww_total_cerah_31_mod_monthly": "Cerah MOD MONTHLY",
          // "ww_total_cerah_31_med_monthly": "Cerah MED MONTHLY",
          // "ww_total_cerah_31_max_monthly": "Cerah MAX MONTHLY",
          // "ww_total_cerah_31_min_monthly": "Cerah MIN MONTHLY",
          "ww_total_cerah_31_sum_monthly": "Jumlah cuaca cerah selama 1 bulan",
          // "ww_total_cerah_31_count_monthly": "Cerah COUNT MONTHLY",
          // "ww_total_berawan_sebagian_avg_monthly": "Berawan Sebagian AVG MONTHLY",
          // "ww_total_berawan_sebagian_mod_monthly": "Berawan Sebagian MOD MONTHLY",
          // "ww_total_berawan_sebagian_med_monthly": "Berawan Sebagian MED MONTHLY",
          // "ww_total_berawan_sebagian_max_monthly": "Berawan Sebagian MAX MONTHLY",
          // "ww_total_berawan_sebagian_min_monthly": "Berawan Sebagian MIN MONTHLY",
          "ww_total_berawan_sebagian_sum_monthly": "Jumlah cuaca berawansebagian selama 1 bulan",
          // "ww_total_berawan_sebagian_count_monthly": "Berawan Sebagian COUNT MONTHLY",
          // "ww_total_berawan_avg_monthly": "Berawan AVG MONTHLY",
          // "ww_total_berawan_mod_monthly": "Berawan MOD MONTHLY",
          // "ww_total_berawan_med_monthly": "Berawan MED MONTHLY",
          // "ww_total_berawan_max_monthly": "Berawan MAX MONTHLY",
          // "ww_total_berawan_min_monthly": "Berawan MIN MONTHLY",
          "ww_total_berawan_sum_monthly": "Jumlah cuaca berawan selama 1 bulan",
          // "ww_total_berawan_count_monthly": "Berawan COUNT MONTHLY",
          // "ww_total_berawan_banyak_avg_monthly": "Berawan Banyak AVG MONTHLY",
          // "ww_total_berawan_banyak_mod_monthly": "Berawan Banyak MOD MONTHLY",
          // "ww_total_berawan_banyak_med_monthly": "Berawan Banyak MED MONTHLY",
          // "ww_total_berawan_banyak_max_monthly": "Berawan Banyak MAX MONTHLY",
          // "ww_total_berawan_banyak_min_monthly": "Berawan Banyak MIN MONTHLY",
          "ww_total_berawan_banyak_sum_monthly": "Jumlah cuaca berawanbanyak selama 1 bulan",
          // "ww_total_berawan_banyak_count_monthly": "Berawan Banyak COUNT MONTHLY",
          // "obsawan_total_31_avg_monthly": "Jumlah Pengamatan 31 AVG MONTHLY",
          // "obsawan_total_31_mod_monthly": "Jumlah Pengamatan 31 MOD MONTHLY",
          // "obsawan_total_31_med_monthly": "Jumlah Pengamatan 31 MED MONTHLY",
          // "obsawan_total_31_max_monthly": "Jumlah Pengamatan 31 MAX MONTHLY",
          // "obsawan_total_31_min_monthly": "Jumlah Pengamatan 31 MIN MONTHLY",
          "obsawan_total_31_sum_monthly": "Jumlah pengamatan awan selama 1 bulan",
          // "obsawan_total_31_count_monthly": "Jumlah Pengamatan 31 COUNT MONTHLY"
        },
      },
    ];

    return listAttr;
  },

  getAttrMonthlyCsv() {
    const listAttr = [
      {
        label: "Awan",
        attr: {
          "obsawan_total_24_avg_monthly": "obsawan_total_24_monthly_avg",
          "obsawan_total_24_mod_monthly": "obsawan_total_24_monthly_mod",
          "obsawan_total_24_med_monthly": "obsawan_total_24_monthly_med",
          "obsawan_total_24_max_monthly": "obsawan_total_24_monthly_max",
          "obsawan_total_24_min_monthly": "obsawan_total_24_monthly_min",
          "obsawan_total_24_sum_monthly": "obsawan_total_24_monthly_sum",
          "obsawan_total_24_count_monthly": "count_obsawan_total_24_monthly",
          "ww_total_cerah_31_avg_monthly": "ww_total_cerah_31_monthly_avg",
          "ww_total_cerah_31_mod_monthly": "ww_total_cerah_31_monthly_mod",
          "ww_total_cerah_31_med_monthly": "ww_total_cerah_31_monthly_med",
          "ww_total_cerah_31_max_monthly": "ww_total_cerah_31_monthly_max",
          "ww_total_cerah_31_min_monthly": "ww_total_cerah_31_monthly_min",
          "ww_total_cerah_31_sum_monthly": "ww_total_cerah_monthly_total",
          "ww_total_cerah_31_count_monthly": "count_ww_total_cerah_31_monthly",
          "ww_total_berawan_sebagian_avg_monthly": "ww_total_berawan_sebagian_monthly_avg",
          "ww_total_berawan_sebagian_mod_monthly": "ww_total_berawan_sebagian_monthly_mod",
          "ww_total_berawan_sebagian_med_monthly": "ww_total_berawan_sebagian_monthly_med",
          "ww_total_berawan_sebagian_max_monthly": "ww_total_berawan_sebagian_monthly_max",
          "ww_total_berawan_sebagian_min_monthly": "ww_total_berawan_sebagian_monthly_min",
          "ww_total_berawan_sebagian_sum_monthly": "ww_total_berawan_sebagian_monthly_total",
          "ww_total_berawan_sebagian_count_monthly": "count_ww_total_berawan_sebagian_monthly",
          "ww_total_berawan_avg_monthly": "ww_total_berawan_monthly_avg",
          "ww_total_berawan_mod_monthly": "ww_total_berawan_monthly_mod",
          "ww_total_berawan_med_monthly": "ww_total_berawan_monthly_med",
          "ww_total_berawan_max_monthly": "ww_total_berawan_monthly_max",
          "ww_total_berawan_min_monthly": "ww_total_berawan_monthly_min",
          "ww_total_berawan_sum_monthly": "ww_total_berawan_monthly_total",
          "ww_total_berawan_count_monthly": "count_ww_total_berawan_monthly",
          "ww_total_berawan_banyak_avg_monthly": "ww_total_berawan_banyak_monthly_avg",
          "ww_total_berawan_banyak_mod_monthly": "ww_total_berawan_banyak_monthly_mod",
          "ww_total_berawan_banyak_med_monthly": "ww_total_berawan_banyak_monthly_med",
          "ww_total_berawan_banyak_max_monthly": "ww_total_berawan_banyak_monthly_max",
          "ww_total_berawan_banyak_min_monthly": "ww_total_berawan_banyak_monthly_min",
          "ww_total_berawan_banyak_sum_monthly": "ww_total_berawan_banyak_monthly_total",
          "ww_total_berawan_banyak_count_monthly": "count_ww_total_berawan_banyak_monthly",
          "obsawan_total_31_avg_monthly": "obsawan_total_31_monthly_avg",
          "obsawan_total_31_mod_monthly": "obsawan_total_31_monthly_mod",
          "obsawan_total_31_med_monthly": "obsawan_total_31_monthly_med",
          "obsawan_total_31_max_monthly": "obsawan_total_31_monthly_max",
          "obsawan_total_31_min_monthly": "obsawan_total_31_monthly_min",
          "obsawan_total_31_sum_monthly": "obsawan_total_monthly_total",
          "obsawan_total_31_count_monthly": "count_obsawan_total_31_monthly_avg"
        },
      },
    ];

    return listAttr;
  },

  getAttrMonthlyDecimal() {
    const listAttr = [
      {
        label: "Awan",
        attr: {
          "obsawan_total_24_avg_monthly": 1,
          "obsawan_total_24_mod_monthly": 1,
          "obsawan_total_24_med_monthly": 1,
          "obsawan_total_24_max_monthly": 1,
          "obsawan_total_24_min_monthly": 1,
          "obsawan_total_24_sum_monthly": 1,
          "obsawan_total_24_count_monthly": 1,
          "ww_total_cerah_31_avg_monthly": 1,
          "ww_total_cerah_31_mod_monthly": 1,
          "ww_total_cerah_31_med_monthly": 1,
          "ww_total_cerah_31_max_monthly": 1,
          "ww_total_cerah_31_min_monthly": 1,
          "ww_total_cerah_31_sum_monthly": 1,
          "ww_total_cerah_31_count_monthly": 1,
          "ww_total_berawan_sebagian_avg_monthly": 1,
          "ww_total_berawan_sebagian_mod_monthly": 1,
          "ww_total_berawan_sebagian_med_monthly": 1,
          "ww_total_berawan_sebagian_max_monthly": 1,
          "ww_total_berawan_sebagian_min_monthly": 1,
          "ww_total_berawan_sebagian_sum_monthly": 1,
          "ww_total_berawan_sebagian_count_monthly": 1,
          "ww_total_berawan_avg_monthly": 1,
          "ww_total_berawan_mod_monthly": 1,
          "ww_total_berawan_med_monthly": 1,
          "ww_total_berawan_max_monthly": 1,
          "ww_total_berawan_min_monthly": 1,
          "ww_total_berawan_sum_monthly": 1,
          "ww_total_berawan_count_monthly": 1,
          "ww_total_berawan_banyak_avg_monthly": 1,
          "ww_total_berawan_banyak_mod_monthly": 1,
          "ww_total_berawan_banyak_med_monthly": 1,
          "ww_total_berawan_banyak_max_monthly": 1,
          "ww_total_berawan_banyak_min_monthly": 1,
          "ww_total_berawan_banyak_sum_monthly": 1,
          "ww_total_berawan_banyak_count_monthly": 1,
          "obsawan_total_31_avg_monthly": 1,
          "obsawan_total_31_mod_monthly": 1,
          "obsawan_total_31_med_monthly": 1,
          "obsawan_total_31_max_monthly": 1,
          "obsawan_total_31_min_monthly": 1,
          "obsawan_total_31_sum_monthly": 1,
          "obsawan_total_31_count_monthly": 1
        },
      },
    ];

    return listAttr;
  },

  getAttrDaily() {
    const listAttr = [
      {
        label: "Awan",
        attr: {
          ww_cerah: "Cerah",
          ww_berawan_sebagian: "Berawan Sebagian",
          ww_berawan: "Berawan",
          ww_berawan_banyak: "Berawan Banyak",
          // obsawan_total_24: "Jumlah Pengamatan",
          jumlah_observasi : "Jumlah Pengamatan"
        }
      }
    ];
    return listAttr;
  },

  getAttrDailyCsv() {
    const listAttr = [
      {
        label: "Awan",
        attr: {
          ww_cerah: "ww_cerah_daily",
          ww_berawan_sebagian: "ww_berawan_sebagian_daily",
          ww_berawan: "ww_berawan_daily",
          ww_berawan_banyak: "ww_berawan_banyak_daily",
          // obsawan_total_24: "Jumlah Pengamatan",
          jumlah_observasi : "obsawan_total_24_daily"
        },
      }
    ];
    return listAttr;
  },

  getAttrDailyDecimal() {
    const listAttr = [
      {
        label: "Awan",
        attr: {
          ww_cerah: 1,
          ww_berawan_sebagian: 1,
          ww_berawan: 1,
          ww_berawan_banyak: 1,
          // obsawan_total_24: "Jumlah Pengamatan",
          jumlah_observasi : 1
        },
      },
    ];
    return listAttr;
  },
};
