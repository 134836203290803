import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "Tekanan Udara",
        attr: {
          po_baro_07: "Tekanan Udara Jam 7",
          po_baro_08: "Tekanan Udara Jam 8",
          po_baro_09: "Tekanan Udara Jam 9",
          po_baro_10: "Tekanan Udara Jam 10",
          po_baro_11: "Tekanan Udara Jam 11",
          po_baro_12: "Tekanan Udara Jam 12",
          po_baro_13: "Tekanan Udara Jam 13",
          po_baro_14: "Tekanan Udara Jam 14",
          po_baro_15: "Tekanan Udara Jam 15",
          po_baro_16: "Tekanan Udara Jam 16",
          po_baro_17: "Tekanan Udara Jam 17",
          po_baro_18: "Tekanan Udara Jam 18",
          po_baro_19: "Tekanan Udara Jam 19",
          po_baro_20: "Tekanan Udara Jam 20",
          po_baro_21: "Tekanan Udara Jam 21",
          po_baro_22: "Tekanan Udara Jam 22",
          po_baro_23: "Tekanan Udara Jam 23",
          po_baro_24: "Tekanan Udara Jam 24",
          po_baro_01: "Tekanan Udara Jam 1",
          po_baro_02: "Tekanan Udara Jam 2",
          po_baro_03: "Tekanan Udara Jam 3",
          po_baro_04: "Tekanan Udara Jam 4",
          po_baro_05: "Tekanan Udara Jam 5",
          po_baro_06: "Tekanan Udara Jam 6",
          po_baro_avg_24: "Rata-rata Tekanan udara dalam 24 jam",
        },
      },
    //   {
    //     label: "Observer",
    //     attr: {
    //         "observer_id" : "ID observer",
    //         "observer_name" : "Nama observer "
    //         },
    // },
    ];

    return listAttr;
  },

  getAttrMonthly() {
    const listAttrMonthly = [
      {
        group: "Tekanan Udara",
        form: "Barograph",
        label: "Tekanan Udara",
        attr: {
          "po_baro_07_avg_monthly": "Tekanan Udara Jam 7 AVG MONTHLY",
          // "po_baro_07_mod_monthly": "Tekanan Udara Jam 7 MOD MONTHLY",
          // "po_baro_07_med_monthly": "Tekanan Udara Jam 7 MED MONTHLY",
          "po_baro_07_max_monthly": "Tekanan Udara Jam 7 MAX MONTHLY",
          "po_baro_07_min_monthly": "Tekanan Udara Jam 7 MIN MONTHLY",
          // "po_baro_07_sum_monthly": "Tekanan Udara Jam 7 SUM MONTHLY",
          "po_baro_07_count_monthly": "Tekanan Udara Jam 7 COUNT MONTHLY",
          "po_baro_08_avg_monthly": "Tekanan Udara Jam 8 AVG MONTHLY",
          // "po_baro_08_mod_monthly": "Tekanan Udara Jam 8 MOD MONTHLY",
          // "po_baro_08_med_monthly": "Tekanan Udara Jam 8 MED MONTHLY",
          "po_baro_08_max_monthly": "Tekanan Udara Jam 8 MAX MONTHLY",
          "po_baro_08_min_monthly": "Tekanan Udara Jam 8 MIN MONTHLY",
          // "po_baro_08_sum_monthly": "Tekanan Udara Jam 8 SUM MONTHLY",
          "po_baro_08_count_monthly": "Tekanan Udara Jam 8 COUNT MONTHLY",
          "po_baro_09_avg_monthly": "Tekanan Udara Jam 9 AVG MONTHLY",
          // "po_baro_09_mod_monthly": "Tekanan Udara Jam 9 MOD MONTHLY",
          // "po_baro_09_med_monthly": "Tekanan Udara Jam 9 MED MONTHLY",
          "po_baro_09_max_monthly": "Tekanan Udara Jam 9 MAX MONTHLY",
          "po_baro_09_min_monthly": "Tekanan Udara Jam 9 MIN MONTHLY",
          // "po_baro_09_sum_monthly": "Tekanan Udara Jam 9 SUM MONTHLY",
          "po_baro_09_count_monthly": "Tekanan Udara Jam 9 COUNT MONTHLY",
          "po_baro_10_avg_monthly": "Tekanan Udara Jam 10 AVG MONTHLY",
          // "po_baro_10_mod_monthly": "Tekanan Udara Jam 10 MOD MONTHLY",
          // "po_baro_10_med_monthly": "Tekanan Udara Jam 10 MED MONTHLY",
          "po_baro_10_max_monthly": "Tekanan Udara Jam 10 MAX MONTHLY",
          "po_baro_10_min_monthly": "Tekanan Udara Jam 10 MIN MONTHLY",
          // "po_baro_10_sum_monthly": "Tekanan Udara Jam 10 SUM MONTHLY",
          "po_baro_10_count_monthly": "Tekanan Udara Jam 10 COUNT MONTHLY",
          "po_baro_11_avg_monthly": "Tekanan Udara Jam 11 AVG MONTHLY",
          // "po_baro_11_mod_monthly": "Tekanan Udara Jam 11 MOD MONTHLY",
          // "po_baro_11_med_monthly": "Tekanan Udara Jam 11 MED MONTHLY",
          "po_baro_11_max_monthly": "Tekanan Udara Jam 11 MAX MONTHLY",
          "po_baro_11_min_monthly": "Tekanan Udara Jam 11 MIN MONTHLY",
          // "po_baro_11_sum_monthly": "Tekanan Udara Jam 11 SUM MONTHLY",
          "po_baro_11_count_monthly": "Tekanan Udara Jam 11 COUNT MONTHLY",
          "po_baro_12_avg_monthly": "Tekanan Udara Jam 12 AVG MONTHLY",
          // "po_baro_12_mod_monthly": "Tekanan Udara Jam 12 MOD MONTHLY",
          // "po_baro_12_med_monthly": "Tekanan Udara Jam 12 MED MONTHLY",
          "po_baro_12_max_monthly": "Tekanan Udara Jam 12 MAX MONTHLY",
          "po_baro_12_min_monthly": "Tekanan Udara Jam 12 MIN MONTHLY",
          // "po_baro_12_sum_monthly": "Tekanan Udara Jam 12 SUM MONTHLY",
          "po_baro_12_count_monthly": "Tekanan Udara Jam 12 COUNT MONTHLY",
          "po_baro_13_avg_monthly": "Tekanan Udara Jam 13 AVG MONTHLY",
          // "po_baro_13_mod_monthly": "Tekanan Udara Jam 13 MOD MONTHLY",
          // "po_baro_13_med_monthly": "Tekanan Udara Jam 13 MED MONTHLY",
          "po_baro_13_max_monthly": "Tekanan Udara Jam 13 MAX MONTHLY",
          "po_baro_13_min_monthly": "Tekanan Udara Jam 13 MIN MONTHLY",
          // "po_baro_13_sum_monthly": "Tekanan Udara Jam 13 SUM MONTHLY",
          "po_baro_13_count_monthly": "Tekanan Udara Jam 13 COUNT MONTHLY",
          "po_baro_14_avg_monthly": "Tekanan Udara Jam 14 AVG MONTHLY",
          // "po_baro_14_mod_monthly": "Tekanan Udara Jam 14 MOD MONTHLY",
          // "po_baro_14_med_monthly": "Tekanan Udara Jam 14 MED MONTHLY",
          "po_baro_14_max_monthly": "Tekanan Udara Jam 14 MAX MONTHLY",
          "po_baro_14_min_monthly": "Tekanan Udara Jam 14 MIN MONTHLY",
          // "po_baro_14_sum_monthly": "Tekanan Udara Jam 14 SUM MONTHLY",
          "po_baro_14_count_monthly": "Tekanan Udara Jam 14 COUNT MONTHLY",
          "po_baro_15_avg_monthly": "Tekanan Udara Jam 15 AVG MONTHLY",
          // "po_baro_15_mod_monthly": "Tekanan Udara Jam 15 MOD MONTHLY",
          // "po_baro_15_med_monthly": "Tekanan Udara Jam 15 MED MONTHLY",
          "po_baro_15_max_monthly": "Tekanan Udara Jam 15 MAX MONTHLY",
          "po_baro_15_min_monthly": "Tekanan Udara Jam 15 MIN MONTHLY",
          // "po_baro_15_sum_monthly": "Tekanan Udara Jam 15 SUM MONTHLY",
          "po_baro_15_count_monthly": "Tekanan Udara Jam 15 COUNT MONTHLY",
          "po_baro_16_avg_monthly": "Tekanan Udara Jam 16 AVG MONTHLY",
          // "po_baro_16_mod_monthly": "Tekanan Udara Jam 16 MOD MONTHLY",
          // "po_baro_16_med_monthly": "Tekanan Udara Jam 16 MED MONTHLY",
          "po_baro_16_max_monthly": "Tekanan Udara Jam 16 MAX MONTHLY",
          "po_baro_16_min_monthly": "Tekanan Udara Jam 16 MIN MONTHLY",
          // "po_baro_16_sum_monthly": "Tekanan Udara Jam 16 SUM MONTHLY",
          "po_baro_16_count_monthly": "Tekanan Udara Jam 16 COUNT MONTHLY",
          "po_baro_17_avg_monthly": "Tekanan Udara Jam 17 AVG MONTHLY",
          // "po_baro_17_mod_monthly": "Tekanan Udara Jam 17 MOD MONTHLY",
          // "po_baro_17_med_monthly": "Tekanan Udara Jam 17 MED MONTHLY",
          "po_baro_17_max_monthly": "Tekanan Udara Jam 17 MAX MONTHLY",
          "po_baro_17_min_monthly": "Tekanan Udara Jam 17 MIN MONTHLY",
          // "po_baro_17_sum_monthly": "Tekanan Udara Jam 17 SUM MONTHLY",
          "po_baro_17_count_monthly": "Tekanan Udara Jam 17 COUNT MONTHLY",
          "po_baro_18_avg_monthly": "Tekanan Udara Jam 18 AVG MONTHLY",
          // "po_baro_18_mod_monthly": "Tekanan Udara Jam 18 MOD MONTHLY",
          // "po_baro_18_med_monthly": "Tekanan Udara Jam 18 MED MONTHLY",
          "po_baro_18_max_monthly": "Tekanan Udara Jam 18 MAX MONTHLY",
          "po_baro_18_min_monthly": "Tekanan Udara Jam 18 MIN MONTHLY",
          // "po_baro_18_sum_monthly": "Tekanan Udara Jam 18 SUM MONTHLY",
          "po_baro_18_count_monthly": "Tekanan Udara Jam 18 COUNT MONTHLY",
          "po_baro_19_avg_monthly": "Tekanan Udara Jam 19 AVG MONTHLY",
          // "po_baro_19_mod_monthly": "Tekanan Udara Jam 19 MOD MONTHLY",
          // "po_baro_19_med_monthly": "Tekanan Udara Jam 19 MED MONTHLY",
          "po_baro_19_max_monthly": "Tekanan Udara Jam 19 MAX MONTHLY",
          "po_baro_19_min_monthly": "Tekanan Udara Jam 19 MIN MONTHLY",
          // "po_baro_19_sum_monthly": "Tekanan Udara Jam 19 SUM MONTHLY",
          "po_baro_19_count_monthly": "Tekanan Udara Jam 19 COUNT MONTHLY",
          "po_baro_20_avg_monthly": "Tekanan Udara Jam 20 AVG MONTHLY",
          // "po_baro_20_mod_monthly": "Tekanan Udara Jam 20 MOD MONTHLY",
          // "po_baro_20_med_monthly": "Tekanan Udara Jam 20 MED MONTHLY",
          "po_baro_20_max_monthly": "Tekanan Udara Jam 20 MAX MONTHLY",
          "po_baro_20_min_monthly": "Tekanan Udara Jam 20 MIN MONTHLY",
          // "po_baro_20_sum_monthly": "Tekanan Udara Jam 20 SUM MONTHLY",
          "po_baro_20_count_monthly": "Tekanan Udara Jam 20 COUNT MONTHLY",
          "po_baro_21_avg_monthly": "Tekanan Udara Jam 21 AVG MONTHLY",
          // "po_baro_21_mod_monthly": "Tekanan Udara Jam 21 MOD MONTHLY",
          // "po_baro_21_med_monthly": "Tekanan Udara Jam 21 MED MONTHLY",
          "po_baro_21_max_monthly": "Tekanan Udara Jam 21 MAX MONTHLY",
          "po_baro_21_min_monthly": "Tekanan Udara Jam 21 MIN MONTHLY",
          // "po_baro_21_sum_monthly": "Tekanan Udara Jam 21 SUM MONTHLY",
          "po_baro_21_count_monthly": "Tekanan Udara Jam 21 COUNT MONTHLY",
          "po_baro_22_avg_monthly": "Tekanan Udara Jam 22 AVG MONTHLY",
          // "po_baro_22_mod_monthly": "Tekanan Udara Jam 22 MOD MONTHLY",
          // "po_baro_22_med_monthly": "Tekanan Udara Jam 22 MED MONTHLY",
          "po_baro_22_max_monthly": "Tekanan Udara Jam 22 MAX MONTHLY",
          "po_baro_22_min_monthly": "Tekanan Udara Jam 22 MIN MONTHLY",
          // "po_baro_22_sum_monthly": "Tekanan Udara Jam 22 SUM MONTHLY",
          "po_baro_22_count_monthly": "Tekanan Udara Jam 22 COUNT MONTHLY",
          "po_baro_23_avg_monthly": "Tekanan Udara Jam 23 AVG MONTHLY",
          // "po_baro_23_mod_monthly": "Tekanan Udara Jam 23 MOD MONTHLY",
          // "po_baro_23_med_monthly": "Tekanan Udara Jam 23 MED MONTHLY",
          "po_baro_23_max_monthly": "Tekanan Udara Jam 23 MAX MONTHLY",
          "po_baro_23_min_monthly": "Tekanan Udara Jam 23 MIN MONTHLY",
          // "po_baro_23_sum_monthly": "Tekanan Udara Jam 23 SUM MONTHLY",
          "po_baro_23_count_monthly": "Tekanan Udara Jam 23 COUNT MONTHLY",
          "po_baro_24_avg_monthly": "Tekanan Udara Jam 24 AVG MONTHLY",
          // "po_baro_24_mod_monthly": "Tekanan Udara Jam 24 MOD MONTHLY",
          // "po_baro_24_med_monthly": "Tekanan Udara Jam 24 MED MONTHLY",
          "po_baro_24_max_monthly": "Tekanan Udara Jam 24 MAX MONTHLY",
          "po_baro_24_min_monthly": "Tekanan Udara Jam 24 MIN MONTHLY",
          // "po_baro_24_sum_monthly": "Tekanan Udara Jam 24 SUM MONTHLY",
          "po_baro_24_count_monthly": "Tekanan Udara Jam 24 COUNT MONTHLY",
          "po_baro_01_avg_monthly": "Tekanan Udara Jam 1 AVG MONTHLY",
          // "po_baro_01_mod_monthly": "Tekanan Udara Jam 1 MOD MONTHLY",
          // "po_baro_01_med_monthly": "Tekanan Udara Jam 1 MED MONTHLY",
          "po_baro_01_max_monthly": "Tekanan Udara Jam 1 MAX MONTHLY",
          "po_baro_01_min_monthly": "Tekanan Udara Jam 1 MIN MONTHLY",
          // "po_baro_01_sum_monthly": "Tekanan Udara Jam 1 SUM MONTHLY",
          "po_baro_01_count_monthly": "Tekanan Udara Jam 1 COUNT MONTHLY",
          "po_baro_02_avg_monthly": "Tekanan Udara Jam 2 AVG MONTHLY",
          // "po_baro_02_mod_monthly": "Tekanan Udara Jam 2 MOD MONTHLY",
          // "po_baro_02_med_monthly": "Tekanan Udara Jam 2 MED MONTHLY",
          "po_baro_02_max_monthly": "Tekanan Udara Jam 2 MAX MONTHLY",
          "po_baro_02_min_monthly": "Tekanan Udara Jam 2 MIN MONTHLY",
          // "po_baro_02_sum_monthly": "Tekanan Udara Jam 2 SUM MONTHLY",
          "po_baro_02_count_monthly": "Tekanan Udara Jam 2 COUNT MONTHLY",
          "po_baro_03_avg_monthly": "Tekanan Udara Jam 3 AVG MONTHLY",
          // "po_baro_03_mod_monthly": "Tekanan Udara Jam 3 MOD MONTHLY",
          // "po_baro_03_med_monthly": "Tekanan Udara Jam 3 MED MONTHLY",
          "po_baro_03_max_monthly": "Tekanan Udara Jam 3 MAX MONTHLY",
          "po_baro_03_min_monthly": "Tekanan Udara Jam 3 MIN MONTHLY",
          // "po_baro_03_sum_monthly": "Tekanan Udara Jam 3 SUM MONTHLY",
          "po_baro_03_count_monthly": "Tekanan Udara Jam 3 COUNT MONTHLY",
          "po_baro_04_avg_monthly": "Tekanan Udara Jam 4 AVG MONTHLY",
          // "po_baro_04_mod_monthly": "Tekanan Udara Jam 4 MOD MONTHLY",
          // "po_baro_04_med_monthly": "Tekanan Udara Jam 4 MED MONTHLY",
          "po_baro_04_max_monthly": "Tekanan Udara Jam 4 MAX MONTHLY",
          "po_baro_04_min_monthly": "Tekanan Udara Jam 4 MIN MONTHLY",
          // "po_baro_04_sum_monthly": "Tekanan Udara Jam 4 SUM MONTHLY",
          "po_baro_04_count_monthly": "Tekanan Udara Jam 4 COUNT MONTHLY",
          "po_baro_05_avg_monthly": "Tekanan Udara Jam 5 AVG MONTHLY",
          // "po_baro_05_mod_monthly": "Tekanan Udara Jam 5 MOD MONTHLY",
          // "po_baro_05_med_monthly": "Tekanan Udara Jam 5 MED MONTHLY",
          "po_baro_05_max_monthly": "Tekanan Udara Jam 5 MAX MONTHLY",
          "po_baro_05_min_monthly": "Tekanan Udara Jam 5 MIN MONTHLY",
          // "po_baro_05_sum_monthly": "Tekanan Udara Jam 5 SUM MONTHLY",
          "po_baro_05_count_monthly": "Tekanan Udara Jam 5 COUNT MONTHLY",
          "po_baro_06_avg_monthly": "Tekanan Udara Jam 6 AVG MONTHLY",
          // "po_baro_06_mod_monthly": "Tekanan Udara Jam 6 MOD MONTHLY",
          // "po_baro_06_med_monthly": "Tekanan Udara Jam 6 MED MONTHLY",
          "po_baro_06_max_monthly": "Tekanan Udara Jam 6 MAX MONTHLY",
          "po_baro_06_min_monthly": "Tekanan Udara Jam 6 MIN MONTHLY",
          // "po_baro_06_sum_monthly": "Tekanan Udara Jam 6 SUM MONTHLY",
          "po_baro_06_count_monthly": "Tekanan Udara Jam 6 COUNT MONTHLY",
          "po_baro_avg_24_avg_monthly": "Rata-rata Tekanan udara dalam 24 jam AVG MONTHLY",
          // "po_baro_avg_24_mod_monthly": "Rata-rata Tekanan udara dalam 24 jam MOD MONTHLY",
          // "po_baro_avg_24_med_monthly": "Rata-rata Tekanan udara dalam 24 jam MED MONTHLY",
          "po_baro_avg_24_max_monthly": "Rata-rata Tekanan udara dalam 24 jam MAX MONTHLY",
          "po_baro_avg_24_min_monthly": "Rata-rata Tekanan udara dalam 24 jam MIN MONTHLY",
          // "po_baro_avg_24_sum_monthly": "Rata-rata Tekanan udara dalam 24 jam SUM MONTHLY",
          "po_baro_avg_24_count_monthly": "Rata-rata Tekanan udara dalam 24 jam COUNT MONTHLY"
        },
      },
    ];

    return listAttrMonthly;
  },

  getAttrMonthlyCsv() {
    const listAttrMonthly = [
      {
        label: "Tekanan Udara",
        attr: {
          "po_baro_07_avg_monthly": "po_baro_07_monthly_avg_mb",
          "po_baro_07_mod_monthly": "po_baro_07_monthly_mod_mb",
          "po_baro_07_med_monthly": "po_baro_07_monthly_med_mb",
          "po_baro_07_max_monthly": "po_baro_07_monthly_max_mb",
          "po_baro_07_min_monthly": "po_baro_07_monthly_min_mb",
          "po_baro_07_sum_monthly": "po_baro_07_monthly_sum_mb",
          "po_baro_07_count_monthly": "count_po_baro_07_monthly_mb",
          "po_baro_08_avg_monthly": "po_baro_08_monthly_avg_mb",
          "po_baro_08_mod_monthly": "po_baro_08_monthly_mod_mb",
          "po_baro_08_med_monthly": "po_baro_08_monthly_med_mb",
          "po_baro_08_max_monthly": "po_baro_08_monthly_max_mb",
          "po_baro_08_min_monthly": "po_baro_08_monthly_min_mb",
          "po_baro_08_sum_monthly": "po_baro_08_monthly_sum_mb",
          "po_baro_08_count_monthly": "count_po_baro_08_monthly_mb",
          "po_baro_09_avg_monthly": "po_baro_09_monthly_avg_mb",
          "po_baro_09_mod_monthly": "po_baro_09_monthly_mod_mb",
          "po_baro_09_med_monthly": "po_baro_09_monthly_med_mb",
          "po_baro_09_max_monthly": "po_baro_09_monthly_max_mb",
          "po_baro_09_min_monthly": "po_baro_09_monthly_min_mb",
          "po_baro_09_sum_monthly": "po_baro_09_monthly_sum_mb",
          "po_baro_09_count_monthly": "count_po_baro_09_monthly_mb",
          "po_baro_10_avg_monthly": "po_baro_10_monthly_avg_mb",
          "po_baro_10_mod_monthly": "po_baro_10_monthly_mod_mb",
          "po_baro_10_med_monthly": "po_baro_10_monthly_med_mb",
          "po_baro_10_max_monthly": "po_baro_10_monthly_max_mb",
          "po_baro_10_min_monthly": "po_baro_10_monthly_min_mb",
          "po_baro_10_sum_monthly": "po_baro_10_monthly_sum_mb",
          "po_baro_10_count_monthly": "count_po_baro_10_monthly_mb",
          "po_baro_11_avg_monthly": "po_baro_11_monthly_avg_mb",
          "po_baro_11_mod_monthly": "po_baro_11_monthly_mod_mb",
          "po_baro_11_med_monthly": "po_baro_11_monthly_med_mb",
          "po_baro_11_max_monthly": "po_baro_11_monthly_max_mb",
          "po_baro_11_min_monthly": "po_baro_11_monthly_min_mb",
          "po_baro_11_sum_monthly": "po_baro_11_monthly_sum_mb",
          "po_baro_11_count_monthly": "count_po_baro_11_monthly_mb",
          "po_baro_12_avg_monthly": "po_baro_12_monthly_avg_mb",
          "po_baro_12_mod_monthly": "po_baro_12_monthly_mod_mb",
          "po_baro_12_med_monthly": "po_baro_12_monthly_med_mb",
          "po_baro_12_max_monthly": "po_baro_12_monthly_max_mb",
          "po_baro_12_min_monthly": "po_baro_12_monthly_min_mb",
          "po_baro_12_sum_monthly": "po_baro_12_monthly_sum_mb",
          "po_baro_12_count_monthly": "count_po_baro_12_monthly_mb",
          "po_baro_13_avg_monthly": "po_baro_13_monthly_avg_mb",
          "po_baro_13_mod_monthly": "po_baro_13_monthly_mod_mb",
          "po_baro_13_med_monthly": "po_baro_13_monthly_med_mb",
          "po_baro_13_max_monthly": "po_baro_13_monthly_max_mb",
          "po_baro_13_min_monthly": "po_baro_13_monthly_min_mb",
          "po_baro_13_sum_monthly": "po_baro_13_monthly_sum_mb",
          "po_baro_13_count_monthly": "count_po_baro_13_monthly_mb",
          "po_baro_14_avg_monthly": "po_baro_14_monthly_avg_mb",
          "po_baro_14_mod_monthly": "po_baro_14_monthly_mod_mb",
          "po_baro_14_med_monthly": "po_baro_14_monthly_med_mb",
          "po_baro_14_max_monthly": "po_baro_14_monthly_max_mb",
          "po_baro_14_min_monthly": "po_baro_14_monthly_min_mb",
          "po_baro_14_sum_monthly": "po_baro_14_monthly_sum_mb",
          "po_baro_14_count_monthly": "count_po_baro_14_monthly_mb",
          "po_baro_15_avg_monthly": "po_baro_15_monthly_avg_mb",
          "po_baro_15_mod_monthly": "po_baro_15_monthly_mod_mb",
          "po_baro_15_med_monthly": "po_baro_15_monthly_med_mb",
          "po_baro_15_max_monthly": "po_baro_15_monthly_max_mb",
          "po_baro_15_min_monthly": "po_baro_15_monthly_min_mb",
          "po_baro_15_sum_monthly": "po_baro_15_monthly_sum_mb",
          "po_baro_15_count_monthly": "count_po_baro_15_monthly_mb",
          "po_baro_16_avg_monthly": "po_baro_16_monthly_avg_mb",
          "po_baro_16_mod_monthly": "po_baro_16_monthly_mod_mb",
          "po_baro_16_med_monthly": "po_baro_16_monthly_med_mb",
          "po_baro_16_max_monthly": "po_baro_16_monthly_max_mb",
          "po_baro_16_min_monthly": "po_baro_16_monthly_min_mb",
          "po_baro_16_sum_monthly": "po_baro_16_monthly_sum_mb",
          "po_baro_16_count_monthly": "count_po_baro_16_monthly_mb",
          "po_baro_17_avg_monthly": "po_baro_17_monthly_avg_mb",
          "po_baro_17_mod_monthly": "po_baro_17_monthly_mod_mb",
          "po_baro_17_med_monthly": "po_baro_17_monthly_med_mb",
          "po_baro_17_max_monthly": "po_baro_17_monthly_max_mb",
          "po_baro_17_min_monthly": "po_baro_17_monthly_min_mb",
          "po_baro_17_sum_monthly": "po_baro_17_monthly_sum_mb",
          "po_baro_17_count_monthly": "count_po_baro_17_monthly_mb",
          "po_baro_18_avg_monthly": "po_baro_18_monthly_avg_mb",
          "po_baro_18_mod_monthly": "po_baro_18_monthly_mod_mb",
          "po_baro_18_med_monthly": "po_baro_18_monthly_med_mb",
          "po_baro_18_max_monthly": "po_baro_18_monthly_max_mb",
          "po_baro_18_min_monthly": "po_baro_18_monthly_min_mb",
          "po_baro_18_sum_monthly": "po_baro_18_monthly_sum_mb",
          "po_baro_18_count_monthly": "count_po_baro_18_monthly_mb",
          "po_baro_19_avg_monthly": "po_baro_19_monthly_avg_mb",
          "po_baro_19_mod_monthly": "po_baro_19_monthly_mod_mb",
          "po_baro_19_med_monthly": "po_baro_19_monthly_med_mb",
          "po_baro_19_max_monthly": "po_baro_19_monthly_max_mb",
          "po_baro_19_min_monthly": "po_baro_19_monthly_min_mb",
          "po_baro_19_sum_monthly": "po_baro_19_monthly_sum_mb",
          "po_baro_19_count_monthly": "count_po_baro_19_monthly_mb",
          "po_baro_20_avg_monthly": "po_baro_20_monthly_avg_mb",
          "po_baro_20_mod_monthly": "po_baro_20_monthly_mod_mb",
          "po_baro_20_med_monthly": "po_baro_20_monthly_med_mb",
          "po_baro_20_max_monthly": "po_baro_20_monthly_max_mb",
          "po_baro_20_min_monthly": "po_baro_20_monthly_min_mb",
          "po_baro_20_sum_monthly": "po_baro_20_monthly_sum_mb",
          "po_baro_20_count_monthly": "count_po_baro_20_monthly_mb",
          "po_baro_21_avg_monthly": "po_baro_21_monthly_avg_mb",
          "po_baro_21_mod_monthly": "po_baro_21_monthly_mod_mb",
          "po_baro_21_med_monthly": "po_baro_21_monthly_med_mb",
          "po_baro_21_max_monthly": "po_baro_21_monthly_max_mb",
          "po_baro_21_min_monthly": "po_baro_21_monthly_min_mb",
          "po_baro_21_sum_monthly": "po_baro_21_monthly_sum_mb",
          "po_baro_21_count_monthly": "count_po_baro_21_monthly_mb",
          "po_baro_22_avg_monthly": "po_baro_22_monthly_avg_mb",
          "po_baro_22_mod_monthly": "po_baro_22_monthly_mod_mb",
          "po_baro_22_med_monthly": "po_baro_22_monthly_med_mb",
          "po_baro_22_max_monthly": "po_baro_22_monthly_max_mb",
          "po_baro_22_min_monthly": "po_baro_22_monthly_min_mb",
          "po_baro_22_sum_monthly": "po_baro_22_monthly_sum_mb",
          "po_baro_22_count_monthly": "count_po_baro_22_monthly_mb",
          "po_baro_23_avg_monthly": "po_baro_23_monthly_avg_mb",
          "po_baro_23_mod_monthly": "po_baro_23_monthly_mod_mb",
          "po_baro_23_med_monthly": "po_baro_23_monthly_med_mb",
          "po_baro_23_max_monthly": "po_baro_23_monthly_max_mb",
          "po_baro_23_min_monthly": "po_baro_23_monthly_min_mb",
          "po_baro_23_sum_monthly": "po_baro_23_monthly_sum_mb",
          "po_baro_23_count_monthly": "count_po_baro_23_monthly_mb",
          "po_baro_24_avg_monthly": "po_baro_20_monthly_avg_mb",
          "po_baro_24_mod_monthly": "po_baro_20_monthly_mod_mb",
          "po_baro_24_med_monthly": "po_baro_20_monthly_med_mb",
          "po_baro_24_max_monthly": "po_baro_20_monthly_max_mb",
          "po_baro_24_min_monthly": "po_baro_20_monthly_min_mb",
          "po_baro_24_sum_monthly": "po_baro_20_monthly_sum_mb",
          "po_baro_24_count_monthly": "count_po_baro_24_monthly_mb",
          "po_baro_01_avg_monthly": "po_baro_1_monthly_avg_mb",
          "po_baro_01_mod_monthly": "po_baro_1_monthly_mod_mb",
          "po_baro_01_med_monthly": "po_baro_1_monthly_med_mb",
          "po_baro_01_max_monthly": "po_baro_1_monthly_max_mb",
          "po_baro_01_min_monthly": "po_baro_1_monthly_min_mb",
          "po_baro_01_sum_monthly": "po_baro_1_monthly_sum_mb",
          "po_baro_01_count_monthly": "count_po_baro_01_monthly_mb",
          "po_baro_02_avg_monthly": "po_baro_1_monthly_avg_mb",
          "po_baro_02_mod_monthly": "po_baro_1_monthly_mod_mb",
          "po_baro_02_med_monthly": "po_baro_1_monthly_med_mb",
          "po_baro_02_max_monthly": "po_baro_1_monthly_max_mb",
          "po_baro_02_min_monthly": "po_baro_1_monthly_min_mb",
          "po_baro_02_sum_monthly": "po_baro_1_monthly_sum_mb",
          "po_baro_02_count_monthly": "count_po_baro_02_monthly_mb",
          "po_baro_03_avg_monthly": "po_baro_1_monthly_avg_mb",
          "po_baro_03_mod_monthly": "po_baro_1_monthly_mod_mb",
          "po_baro_03_med_monthly": "po_baro_1_monthly_med_mb",
          "po_baro_03_max_monthly": "po_baro_1_monthly_max_mb",
          "po_baro_03_min_monthly": "po_baro_1_monthly_min_mb",
          "po_baro_03_sum_monthly": "po_baro_1_monthly_sum_mb",
          "po_baro_03_count_monthly": "count_po_baro_03_monthly_mb",
          "po_baro_04_avg_monthly": "po_baro_1_monthly_avg_mb",
          "po_baro_04_mod_monthly": "po_baro_1_monthly_mod_mb",
          "po_baro_04_med_monthly": "po_baro_1_monthly_med_mb",
          "po_baro_04_max_monthly": "po_baro_1_monthly_max_mb",
          "po_baro_04_min_monthly": "po_baro_1_monthly_min_mb",
          "po_baro_04_sum_monthly": "po_baro_1_monthly_sum_mb",
          "po_baro_04_count_monthly": "count_po_baro_04_monthly_mb",
          "po_baro_05_avg_monthly": "po_baro_1_monthly_avg_mb",
          "po_baro_05_mod_monthly": "po_baro_1_monthly_mod_mb",
          "po_baro_05_med_monthly": "po_baro_1_monthly_med_mb",
          "po_baro_05_max_monthly": "po_baro_1_monthly_max_mb",
          "po_baro_05_min_monthly": "po_baro_1_monthly_min_mb",
          "po_baro_05_sum_monthly": "po_baro_1_monthly_sum_mb",
          "po_baro_05_count_monthly": "count_po_baro_05_monthly_mb",
          "po_baro_06_avg_monthly": "po_baro_1_monthly_avg_mb",
          "po_baro_06_mod_monthly": "po_baro_1_monthly_mod_mb",
          "po_baro_06_med_monthly": "po_baro_1_monthly_med_mb",
          "po_baro_06_max_monthly": "po_baro_1_monthly_max_mb",
          "po_baro_06_min_monthly": "po_baro_1_monthly_min_mb",
          "po_baro_06_sum_monthly": "po_baro_1_monthly_sum_mb",
          "po_baro_06_count_monthly": "count_po_baro_06_monthly_mb",
          "po_baro_avg_24_avg_monthly": "po_baro_avg_24_monthly_avg_mb",
          "po_baro_avg_24_mod_monthly": "po_baro_avg_24_monthly_mod_mb",
          "po_baro_avg_24_med_monthly": "po_baro_avg_24_monthly_med_mb",
          "po_baro_avg_24_max_monthly": "po_baro_avg_24_monthly_max_mb",
          "po_baro_avg_24_min_monthly": "po_baro_avg_24_monthly_min_mb",
          "po_baro_avg_24_sum_monthly": "po_baro_avg_24_monthly_sum_mb",
          "po_baro_avg_24_count_monthly": "count_po_baro_avg_24_monthly_mb"
        },
      },
    ];

    return listAttrMonthly;
  },

  getAttrMonthlyDecimal() {
    const listAttr = [
      {
        label: "Tekanan Udara",
        attr: {
          "po_baro_avg_24_avg_monthly": 1,
          "po_baro_avg_24_mod_monthly": 1,
          "po_baro_avg_24_med_monthly": 1,
          "po_baro_avg_24_max_monthly": 1,
          "po_baro_avg_24_min_monthly": 1,
          "po_baro_avg_24_sum_monthly": 1,
          "po_baro_avg_24_count_monthly": 1
        },
      },
    ];

    for (var i = 1; i <= 24; i++) {
      let num1 = this.padZero(i, 2)
      listAttr[0].attr["po_baro_"+num1+"_avg_monthly"] = 1
      listAttr[0].attr["po_baro_"+num1+"_mod_monthly"] = 1
      listAttr[0].attr["po_baro_"+num1+"_med_monthly"] = 1
      listAttr[0].attr["po_baro_"+num1+"_max_monthly"] = 1
      listAttr[0].attr["po_baro_"+num1+"_min_monthly"] = 1
      listAttr[0].attr["po_baro_"+num1+"_sum_monthly"] = 1
      listAttr[0].attr["po_baro_"+num1+"_count_monthly"] = 1
    }

    return listAttr;
  },

  padZero(val, n) {
    return `${"0".repeat(n)}${val}`.substr(-n, n);
  },

  getAttrDaily() {
    const listAttr = [
      {
        label: "Tekanan Udara",
        attr: {
          po_baro_avg_24: "Tekanan Udara Rata-rata dari jam 7 sampai 6 (selama 24 jam)"
        }
      }
    ];
    return listAttr;
  },

  getAttrDailyCsv() {
    const listAttr = [
      {
        label: "Barograph Harian Csv",
        attr: {
          po_baro_avg_24: "po_baro_avg_24_daily_mb"
        },
      }
    ];
    return listAttr;
  },

  getAttrDailyDecimal() {
    const listAttr = [
      {
        label: "Barograph Harian Decimal",
        attr: {
          po_baro_avg_24: 1
        },
      },
    ];
    return listAttr;
  },
};
