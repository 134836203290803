import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "Intentitas Radiasi Matahari",
        attr: {
          f_5_6: "Jumlah radiasi jam 5-6",
          f_6_7: "Jumlah radiasi jam 6-7",
          f_7_8: "Jumlah radiasi jam 7-8",
          f_8_9: "Jumlah radiasi jam 8-9",
          f_9_10: "Jumlah radiasi jam 9-10",
          f_10_11: "Jumlah radiasi jam 10-11",
          f_11_12: "Jumlah radiasi jam 11-12",
          f_12_13: "Jumlah radiasi jam 12-13",
          f_13_14: "Jumlah radiasi jam 13-14",
          f_14_15: "Jumlah radiasi jam 14-15",
          f_15_16: "Jumlah radiasi jam 15-16",
          f_16_17: "Jumlah radiasi jam 16-17",
          f_17_18: "Jumlah radiasi jam 17_18",
          f_18_19: "Jumlah radiasi jam 18-19",
          f_19_20: "Jumlah radiasi jam 19-20",
          f_20_21: "Jumlah radiasi jam 20-21",
          f_21_22: "Jumlah radiasi jam 21-22",
          f_22_23: "Jumlah radiasi jam 22-23",
          f_23_24: "Jumlah radiasi jam 23-24",
          f_total19: "Total radiasi harian",
        },
      },
    //   {
    //     label: "Observer",
    //     attr: {
    //         "observer_id" : "ID observer",
    //         "observer_name" : "Nama observer "
    //         },
    // },
    ];

    return listAttr;
  },

  getAttrMonthly() {
    const listAttr = [
      {
        group: "Radiasi",
        form: "Aktinograph",
        label: "Intentitas Radiasi Matahari",
        attr: {
          "f_5_6_avg_monthly": "Jumlah radiasi jam 5-6 AVG MONTHLY",
          // "f_5_6_mod_monthly": "Jumlah radiasi jam 5-6 MOD MONTHLY",
          // "f_5_6_med_monthly": "Jumlah radiasi jam 5-6 MED MONTHLY",
          "f_5_6_max_monthly": "Jumlah radiasi jam 5-6 MAX MONTHLY",
          "f_5_6_min_monthly": "Jumlah radiasi jam 5-6 MIN MONTHLY",
          "f_5_6_sum_monthly": "Jumlah radiasi jam 5-6 SUM MONTHLY",
          "f_5_6_count_monthly": "Jumlah radiasi jam 5-6 COUNT MONTHLY",
          "f_6_7_avg_monthly": "Jumlah radiasi jam 6-7 AVG MONTHLY",
          // "f_6_7_mod_monthly": "Jumlah radiasi jam 6-7 MOD MONTHLY",
          // "f_6_7_med_monthly": "Jumlah radiasi jam 6-7 MED MONTHLY",
          "f_6_7_max_monthly": "Jumlah radiasi jam 6-7 MAX MONTHLY",
          "f_6_7_min_monthly": "Jumlah radiasi jam 6-7 MIN MONTHLY",
          "f_6_7_sum_monthly": "Jumlah radiasi jam 6-7 SUM MONTHLY",
          "f_6_7_count_monthly": "Jumlah radiasi jam 6-7 COUNT MONTHLY",
          "f_7_8_avg_monthly": "Jumlah radiasi jam 7-8 AVG MONTHLY",
          // "f_7_8_mod_monthly": "Jumlah radiasi jam 7-8 MOD MONTHLY",
          // "f_7_8_med_monthly": "Jumlah radiasi jam 7-8 MED MONTHLY",
          "f_7_8_max_monthly": "Jumlah radiasi jam 7-8 MAX MONTHLY",
          "f_7_8_min_monthly": "Jumlah radiasi jam 7-8 MIN MONTHLY",
          "f_7_8_sum_monthly": "Jumlah radiasi jam 7-8 SUM MONTHLY",
          "f_7_8_count_monthly": "Jumlah radiasi jam 7-8 COUNT MONTHLY",
          "f_8_9_avg_monthly": "Jumlah radiasi jam 8-9 AVG MONTHLY",
          // "f_8_9_mod_monthly": "Jumlah radiasi jam 8-9 MOD MONTHLY",
          // "f_8_9_med_monthly": "Jumlah radiasi jam 8-9 MED MONTHLY",
          "f_8_9_max_monthly": "Jumlah radiasi jam 8-9 MAX MONTHLY",
          "f_8_9_min_monthly": "Jumlah radiasi jam 8-9 MIN MONTHLY",
          "f_8_9_sum_monthly": "Jumlah radiasi jam 8-9 SUM MONTHLY",
          "f_8_9_count_monthly": "Jumlah radiasi jam 8-9 COUNT MONTHLY",
          "f_9_10_avg_monthly": "Jumlah radiasi jam 9-10 AVG MONTHLY",
          // "f_9_10_mod_monthly": "Jumlah radiasi jam 9-10 MOD MONTHLY",
          // "f_9_10_med_monthly": "Jumlah radiasi jam 9-10 MED MONTHLY",
          "f_9_10_max_monthly": "Jumlah radiasi jam 9-10 MAX MONTHLY",
          "f_9_10_min_monthly": "Jumlah radiasi jam 9-10 MIN MONTHLY",
          "f_9_10_sum_monthly": "Jumlah radiasi jam 9-10 SUM MONTHLY",
          "f_9_10_count_monthly": "Jumlah radiasi jam 9-10 COUNT MONTHLY",
          "f_10_11_avg_monthly": "Jumlah radiasi jam 10-11 AVG MONTHLY",
          // "f_10_11_mod_monthly": "Jumlah radiasi jam 10-11 MOD MONTHLY",
          // "f_10_11_med_monthly": "Jumlah radiasi jam 10-11 MED MONTHLY",
          "f_10_11_max_monthly": "Jumlah radiasi jam 10-11 MAX MONTHLY",
          "f_10_11_min_monthly": "Jumlah radiasi jam 10-11 MIN MONTHLY",
          "f_10_11_sum_monthly": "Jumlah radiasi jam 10-11 SUM MONTHLY",
          "f_10_11_count_monthly": "Jumlah radiasi jam 10-11 COUNT MONTHLY",
          "f_11_12_avg_monthly": "Jumlah radiasi jam 11-12 AVG MONTHLY",
          // "f_11_12_mod_monthly": "Jumlah radiasi jam 11-12 MOD MONTHLY",
          // "f_11_12_med_monthly": "Jumlah radiasi jam 11-12 MED MONTHLY",
          "f_11_12_max_monthly": "Jumlah radiasi jam 11-12 MAX MONTHLY",
          "f_11_12_min_monthly": "Jumlah radiasi jam 11-12 MIN MONTHLY",
          "f_11_12_sum_monthly": "Jumlah radiasi jam 11-12 SUM MONTHLY",
          "f_11_12_count_monthly": "Jumlah radiasi jam 11-12 COUNT MONTHLY",
          "f_12_13_avg_monthly": "Jumlah radiasi jam 12-13 AVG MONTHLY",
          // "f_12_13_mod_monthly": "Jumlah radiasi jam 12-13 MOD MONTHLY",
          // "f_12_13_med_monthly": "Jumlah radiasi jam 12-13 MED MONTHLY",
          "f_12_13_max_monthly": "Jumlah radiasi jam 12-13 MAX MONTHLY",
          "f_12_13_min_monthly": "Jumlah radiasi jam 12-13 MIN MONTHLY",
          "f_12_13_sum_monthly": "Jumlah radiasi jam 12-13 SUM MONTHLY",
          "f_12_13_count_monthly": "Jumlah radiasi jam 12-13 COUNT MONTHLY",
          "f_13_14_avg_monthly": "Jumlah radiasi jam 13-14 AVG MONTHLY",
          // "f_13_14_mod_monthly": "Jumlah radiasi jam 13-14 MOD MONTHLY",
          // "f_13_14_med_monthly": "Jumlah radiasi jam 13-14 MED MONTHLY",
          "f_13_14_max_monthly": "Jumlah radiasi jam 13-14 MAX MONTHLY",
          "f_13_14_min_monthly": "Jumlah radiasi jam 13-14 MIN MONTHLY",
          "f_13_14_sum_monthly": "Jumlah radiasi jam 13-14 SUM MONTHLY",
          "f_13_14_count_monthly": "Jumlah radiasi jam 13-14 COUNT MONTHLY",
          "f_14_15_avg_monthly": "Jumlah radiasi jam 14-15 AVG MONTHLY",
          // "f_14_15_mod_monthly": "Jumlah radiasi jam 14-15 MOD MONTHLY",
          // "f_14_15_med_monthly": "Jumlah radiasi jam 14-15 MED MONTHLY",
          "f_14_15_max_monthly": "Jumlah radiasi jam 14-15 MAX MONTHLY",
          "f_14_15_min_monthly": "Jumlah radiasi jam 14-15 MIN MONTHLY",
          "f_14_15_sum_monthly": "Jumlah radiasi jam 14-15 SUM MONTHLY",
          "f_14_15_count_monthly": "Jumlah radiasi jam 14-15 COUNT MONTHLY",
          "f_15_16_avg_monthly": "Jumlah radiasi jam 15-16 AVG MONTHLY",
          // "f_15_16_mod_monthly": "Jumlah radiasi jam 15-16 MOD MONTHLY",
          // "f_15_16_med_monthly": "Jumlah radiasi jam 15-16 MED MONTHLY",
          "f_15_16_max_monthly": "Jumlah radiasi jam 15-16 MAX MONTHLY",
          "f_15_16_min_monthly": "Jumlah radiasi jam 15-16 MIN MONTHLY",
          "f_15_16_sum_monthly": "Jumlah radiasi jam 15-16 SUM MONTHLY",
          "f_15_16_count_monthly": "Jumlah radiasi jam 15-16 COUNT MONTHLY",
          "f_16_17_avg_monthly": "Jumlah radiasi jam 16-17 AVG MONTHLY",
          // "f_16_17_mod_monthly": "Jumlah radiasi jam 16-17 MOD MONTHLY",
          // "f_16_17_med_monthly": "Jumlah radiasi jam 16-17 MED MONTHLY",
          "f_16_17_max_monthly": "Jumlah radiasi jam 16-17 MAX MONTHLY",
          "f_16_17_min_monthly": "Jumlah radiasi jam 16-17 MIN MONTHLY",
          "f_16_17_sum_monthly": "Jumlah radiasi jam 16-17 SUM MONTHLY",
          "f_16_17_count_monthly": "Jumlah radiasi jam 16-17 COUNT MONTHLY",
          "f_17_18_avg_monthly": "Jumlah radiasi jam 17-18 AVG MONTHLY",
          // "f_17_18_mod_monthly": "Jumlah radiasi jam 17-18 MOD MONTHLY",
          // "f_17_18_med_monthly": "Jumlah radiasi jam 17-18 MED MONTHLY",
          "f_17_18_max_monthly": "Jumlah radiasi jam 17-18 MAX MONTHLY",
          "f_17_18_min_monthly": "Jumlah radiasi jam 17-18 MIN MONTHLY",
          "f_17_18_sum_monthly": "Jumlah radiasi jam 17-18 SUM MONTHLY",
          "f_17_18_count_monthly": "Jumlah radiasi jam 17-18 COUNT MONTHLY",
          "f_18_19_avg_monthly": "Jumlah radiasi jam 18-19 AVG MONTHLY",
          // "f_18_19_mod_monthly": "Jumlah radiasi jam 18-19 MOD MONTHLY",
          // "f_18_19_med_monthly": "Jumlah radiasi jam 18-19 MED MONTHLY",
          "f_18_19_max_monthly": "Jumlah radiasi jam 18-19 MAX MONTHLY",
          "f_18_19_min_monthly": "Jumlah radiasi jam 18-19 MIN MONTHLY",
          "f_18_19_sum_monthly": "Jumlah radiasi jam 18-19 SUM MONTHLY",
          "f_18_19_count_monthly": "Jumlah radiasi jam 18-19 COUNT MONTHLY",
          "f_19_20_avg_monthly": "Jumlah radiasi jam 19-20 AVG MONTHLY",
          // "f_19_20_mod_monthly": "Jumlah radiasi jam 19-20 MOD MONTHLY",
          // "f_19_20_med_monthly": "Jumlah radiasi jam 19-20 MED MONTHLY",
          "f_19_20_max_monthly": "Jumlah radiasi jam 19-20 MAX MONTHLY",
          "f_19_20_min_monthly": "Jumlah radiasi jam 19-20 MIN MONTHLY",
          "f_19_20_sum_monthly": "Jumlah radiasi jam 19-20 SUM MONTHLY",
          "f_19_20_count_monthly": "Jumlah radiasi jam 19-20 COUNT MONTHLY",
          "f_20_21_avg_monthly": "Jumlah radiasi jam 20-21 AVG MONTHLY",
          // "f_20_21_mod_monthly": "Jumlah radiasi jam 20-21 MOD MONTHLY",
          // "f_20_21_med_monthly": "Jumlah radiasi jam 20-21 MED MONTHLY",
          "f_20_21_max_monthly": "Jumlah radiasi jam 20-21 MAX MONTHLY",
          "f_20_21_min_monthly": "Jumlah radiasi jam 20-21 MIN MONTHLY",
          "f_20_21_sum_monthly": "Jumlah radiasi jam 20-21 SUM MONTHLY",
          "f_20_21_count_monthly": "Jumlah radiasi jam 20-21 COUNT MONTHLY",
          "f_21_22_avg_monthly": "Jumlah radiasi jam 21-22 AVG MONTHLY",
          // "f_21_22_mod_monthly": "Jumlah radiasi jam 21-22 MOD MONTHLY",
          // "f_21_22_med_monthly": "Jumlah radiasi jam 21-22 MED MONTHLY",
          "f_21_22_max_monthly": "Jumlah radiasi jam 21-22 MAX MONTHLY",
          "f_21_22_min_monthly": "Jumlah radiasi jam 21-22 MIN MONTHLY",
          "f_21_22_sum_monthly": "Jumlah radiasi jam 21-22 SUM MONTHLY",
          "f_21_22_count_monthly": "Jumlah radiasi jam 21-22 COUNT MONTHLY",
          "f_22_23_avg_monthly": "Jumlah radiasi jam 22-23 AVG MONTHLY",
          // "f_22_23_mod_monthly": "Jumlah radiasi jam 22-23 MOD MONTHLY",
          // "f_22_23_med_monthly": "Jumlah radiasi jam 22-23 MED MONTHLY",
          "f_22_23_max_monthly": "Jumlah radiasi jam 22-23 MAX MONTHLY",
          "f_22_23_min_monthly": "Jumlah radiasi jam 22-23 MIN MONTHLY",
          "f_22_23_sum_monthly": "Jumlah radiasi jam 22-23 SUM MONTHLY",
          "f_22_23_count_monthly": "Jumlah radiasi jam 22-23 COUNT MONTHLY",
          "f_23_24_avg_monthly": "Jumlah radiasi jam 23-24 AVG MONTHLY",
          // "f_23_24_mod_monthly": "Jumlah radiasi jam 23-24 MOD MONTHLY",
          // "f_23_24_med_monthly": "Jumlah radiasi jam 23-24 MED MONTHLY",
          "f_23_24_max_monthly": "Jumlah radiasi jam 23-24 MAX MONTHLY",
          "f_23_24_min_monthly": "Jumlah radiasi jam 23-24 MIN MONTHLY",
          "f_23_24_sum_monthly": "Jumlah radiasi jam 23-24 SUM MONTHLY",
          "f_23_24_count_monthly": "Jumlah radiasi jam 23-24 COUNT MONTHLY",
          "f_total19_avg_monthly": "Total radiasi harian AVG MONTHLY",
          // "f_total19_mod_monthly": "Total radiasi harian MOD MONTHLY",
          // "f_total19_med_monthly": "Total radiasi harian MED MONTHLY",
          "f_total19_max_monthly": "Total radiasi harian MAX MONTHLY",
          "f_total19_min_monthly": "Total radiasi harian MIN MONTHLY",
          "f_total19_sum_monthly": "Total radiasi harian SUM MONTHLY",
          "f_total19_count_monthly": "Total radiasi harian COUNT MONTHLY"
        },
      },
    ];

    return listAttr;
  },

  getAttrMonthlyCsv() {
    const listAttr = [
      {
        label: "Intentitas Radiasi Matahari",
        attr: {
          "f_5_6_avg_monthly": "rad_actino_5_6_monthly_avg_cal_cm-2",
          "f_5_6_mod_monthly": "rad_actino_5_6_monthly_mod_cal_cm-2",
          "f_5_6_med_monthly": "rad_actino_5_6_monthly_med_cal_cm-2",
          "f_5_6_max_monthly": "rad_actino_5_6_monthly_max_cal_cm-2",
          "f_5_6_min_monthly": "rad_actino_5_6_monthly_min_cal_cm-2",
          "f_5_6_sum_monthly": "rad_actino_5_6_monthly_total_cal_cm-2",
          "f_5_6_count_monthly": "c_rad_actino_5_6_monthly",
          "f_6_7_avg_monthly": "rad_actino_6_7_monthly_avg_cal_cm-2",
          "f_6_7_mod_monthly": "rad_actino_6_7_monthly_mod_cal_cm-2",
          "f_6_7_med_monthly": "rad_actino_6_7_monthly_med_cal_cm-2",
          "f_6_7_max_monthly": "rad_actino_6_7_monthly_max_cal_cm-2",
          "f_6_7_min_monthly": "rad_actino_6_7_monthly_min_cal_cm-2",
          "f_6_7_sum_monthly": "rad_actino_6_7_monthly_avg_cal_cm-2",
          "f_6_7_count_monthly": "c_rad_actino_5_6_monthly",
          "f_7_8_avg_monthly": "rad_actino_7_8_monthly_avg_cal_cm-2",
          "f_7_8_mod_monthly": "rad_actino_7_8_monthly_mod_cal_cm-2",
          "f_7_8_med_monthly": "rad_actino_7_8_monthly_med_cal_cm-2",
          "f_7_8_max_monthly": "rad_actino_7_8_monthly_max_cal_cm-2",
          "f_7_8_min_monthly": "rad_actino_7_8_monthly_min_cal_cm-2",
          "f_7_8_sum_monthly": "rad_actino_7_8_monthly_sum_cal_cm-2",
          "f_7_8_count_monthly": "c_rad_actino_5_6_monthly",
          "f_8_9_avg_monthly": "rad_actino_8_9_monthly_avg_cal_cm-2",
          "f_8_9_mod_monthly": "rad_actino_8_9_monthly_mod_cal_cm-2-2monthly",
          "f_8_9_med_monthly": "rad_actino_8_9_monthly_med_cal_cm-2",
          "f_8_9_max_monthly": "rad_actino_8_9_monthly_max_cal_cm-2",
          "f_8_9_min_monthly": "rad_actino_8_9_monthly_min_cal_cm-2",
          "f_8_9_sum_monthly": "rad_actino_8_9_monthly_sum_cal_cm-2",
          "f_8_9_count_monthly": "c_rad_actino_5_6_monthly",
          "f_9_10_avg_monthly": "rad_actino_9_10_monthly_avg_cal_cm-2",
          "f_9_10_mod_monthly": "rad_actino_9_10_monthly_mod_cal_cm-2",
          "f_9_10_med_monthly": "rad_actino_9_10_monthly_med_cal_cm-2",
          "f_9_10_max_monthly": "rad_actino_9_10_monthly_max_cal_cm-2",
          "f_9_10_min_monthly": "rad_actino_9_10_monthly_min_cal_cm-2",
          "f_9_10_sum_monthly": "rad_actino_9_10_monthly_sum_cal_cm-2",
          "f_9_10_count_monthly": "c_rad_actino_5_6_monthly",
          "f_10_11_avg_monthly": "rad_actino_10_11_monthly_avg_cal_cm-2",
          "f_10_11_mod_monthly": "rad_actino_10_11_monthly_mod_cal_cm-2",
          "f_10_11_med_monthly": "rad_actino_10_11_monthly_med_cal_cm-2",
          "f_10_11_max_monthly": "rad_actino_10_11_monthly_max_cal_cm-2",
          "f_10_11_min_monthly": "rad_actino_10_11_monthly_min_cal_cm-2",
          "f_10_11_sum_monthly": "rad_actino_10_11_monthly_sum_cal_cm-2",
          "f_10_11_count_monthly": "c_rad_actino_10-11_monthly",
          "f_11_12_avg_monthly": "rad_actino_11_12_monthly_avg_cal_cm-2",
          "f_11_12_mod_monthly": "rad_actino_11_12_monthly_mod_cal_cm-2",
          "f_11_12_med_monthly": "rad_actino_11_12_monthly_med_cal_cm-2",
          "f_11_12_max_monthly": "rad_actino_11_12_monthly_max_cal_cm-2",
          "f_11_12_min_monthly": "rad_actino_11_12_monthly_min_cal_cm-2",
          "f_11_12_sum_monthly": "rad_actino_11_12_monthly_sum_cal_cm-2",
          "f_11_12_count_monthly": "c_rad_actino_11_12_monthly",
          "f_12_13_avg_monthly": "rad_actino_12_13_monthly_avg_cal_cm-2",
          "f_12_13_mod_monthly": "rad_actino_12_13_monthly_mod_cal_cm-2",
          "f_12_13_med_monthly": "rad_actino_12_13_monthly_med_cal_cm-2",
          "f_12_13_max_monthly": "rad_actino_12_13_monthly_max_cal_cm-2",
          "f_12_13_min_monthly": "rad_actino_12_13_monthly_min_cal_cm-2",
          "f_12_13_sum_monthly": "rad_actino_12_13_monthly_sum_cal_cm-2",
          "f_12_13_count_monthly": "c_rad_actino_12_13_monthly",
          "f_13_14_avg_monthly": "rad_actino_13_14_monthly_avg_cal_cm-2",
          "f_13_14_mod_monthly": "rad_actino_13_14_monthly_mod_cal_cm-2",
          "f_13_14_med_monthly": "rad_actino_13_14_monthly_med_cal_cm-2",
          "f_13_14_max_monthly": "rad_actino_13_14_monthly_max_cal_cm-2",
          "f_13_14_min_monthly": "rad_actino_13_14_monthly_min_cal_cm-2",
          "f_13_14_sum_monthly": "rad_actino_13_14_monthly_sum_cal_cm-2",
          "f_13_14_count_monthly": "c_rad_actino_13_14_monthly",
          "f_14_15_avg_monthly": "rad_actino_14_15_monthly_avg_cal_cm-2",
          "f_14_15_mod_monthly": "rad_actino_14_15_monthly_mod_cal_cm-2",
          "f_14_15_med_monthly": "rad_actino_14_15_monthly_med_cal_cm-2",
          "f_14_15_max_monthly": "rad_actino_14_15_monthly_max_cal_cm-2",
          "f_14_15_min_monthly": "rad_actino_14_15_monthly_min_cal_cm-2",
          "f_14_15_sum_monthly": "rad_actino_14_15_monthly_sum_cal_cm-2",
          "f_14_15_count_monthly": "c_rad_actino_14_15_monthly",
          "f_15_16_avg_monthly": "rad_actino_15_16_monthly_avg_cal_cm-2",
          "f_15_16_mod_monthly": "rad_actino_15_16_monthly_mod_cal_cm-2",
          "f_15_16_med_monthly": "rad_actino_15_16_monthly_med_cal_cm-2",
          "f_15_16_max_monthly": "rad_actino_15_16_monthly_max_cal_cm-2",
          "f_15_16_min_monthly": "rad_actino_15_16_monthly_min_cal_cm-2",
          "f_15_16_sum_monthly": "rad_actino_15_16_monthly_sum_cal_cm-2",
          "f_15_16_count_monthly": "c_rad_actino_15_16_monthly",
          "f_16_17_avg_monthly": "rad_actino_16_17_monthly_avg_cal_cm-2",
          "f_16_17_mod_monthly": "rad_actino_16_17_monthly_mod_cal_cm-2",
          "f_16_17_med_monthly": "rad_actino_16_17_monthly_med_cal_cm-2",
          "f_16_17_max_monthly": "rad_actino_16_17_monthly_max_cal_cm-2",
          "f_16_17_min_monthly": "rad_actino_16_17_monthly_min_cal_cm-2",
          "f_16_17_sum_monthly": "rad_actino_16_17_monthly_sum_cal_cm-2",
          "f_16_17_count_monthly": "c_rad_actino_16_17_monthly",
          "f_17_18_avg_monthly": "rad_actino_17_18_monthly_avg_cal_cm-2",
          "f_17_18_mod_monthly": "rad_actino_17_18_monthly_mod_cal_cm-2",
          "f_17_18_med_monthly": "rad_actino_17_18_monthly_med_cal_cm-2",
          "f_17_18_max_monthly": "rad_actino_17_18_monthly_max_cal_cm-2",
          "f_17_18_min_monthly": "rad_actino_17_18_monthly_min_cal_cm-2",
          "f_17_18_sum_monthly": "rad_actino_17_18_monthly_sum_cal_cm-2",
          "f_17_18_count_monthly": "c_rad_actino_17_18_monthly",
          "f_18_19_avg_monthly": "rad_actino_18_19_monthly_avg_cal_cm-2",
          "f_18_19_mod_monthly": "rad_actino_18_19_monthly_mod_cal_cm-2",
          "f_18_19_med_monthly": "rad_actino_18_19_monthly_med_cal_cm-2",
          "f_18_19_max_monthly": "rad_actino_18_19_monthly_max_cal_cm-2",
          "f_18_19_min_monthly": "rad_actino_18_19_monthly_min_cal_cm-2",
          "f_18_19_sum_monthly": "rad_actino_18_19_monthly_sum_cal_cm-2",
          "f_18_19_count_monthly": "c_rad_actino_18_19_monthly",
          "f_19_20_avg_monthly": "rad_actino_19_20_monthly_avg_cal_cm-2",
          "f_19_20_mod_monthly": "rad_actino_19_20_monthly_mod_cal_cm-2",
          "f_19_20_med_monthly": "rad_actino_19_20_monthly_med_cal_cm-2",
          "f_19_20_max_monthly": "rad_actino_19_20_monthly_max_cal_cm-2",
          "f_19_20_min_monthly": "rad_actino_19_20_monthly_min_cal_cm-2",
          "f_19_20_sum_monthly": "rad_actino_19_20_monthly_sum_cal_cm-2",
          "f_19_20_count_monthly": "c_rad_actino_19_20_monthly",
          "f_20_21_avg_monthly": "rad_actino_20_21_monthly_avg_cal_cm-2",
          "f_20_21_mod_monthly": "rad_actino_20_21_monthly_mod_cal_cm-2",
          "f_20_21_med_monthly": "rad_actino_20_21_monthly_med_cal_cm-2",
          "f_20_21_max_monthly": "rad_actino_20_21_monthly_max_cal_cm-2",
          "f_20_21_min_monthly": "rad_actino_20_21_monthly_min_cal_cm-2",
          "f_20_21_sum_monthly": "rad_actino_20_21_monthly_sum_cal_cm-2",
          "f_20_21_count_monthly": "c_rad_actino_20_21_monthly",
          "f_21_22_avg_monthly": "rad_actino_21_22_monthly_avg_cal_cm-2",
          "f_21_22_mod_monthly": "rad_actino_21_22_monthly_mod_cal_cm-2",
          "f_21_22_med_monthly": "rad_actino_21_22_monthly_med_cal_cm-2",
          "f_21_22_max_monthly": "rad_actino_21_22_monthly_max_cal_cm-2",
          "f_21_22_min_monthly": "rad_actino_21_22_monthly_min_cal_cm-2",
          "f_21_22_sum_monthly": "rad_actino_21_22_monthly_sum_cal_cm-2",
          "f_21_22_count_monthly": "c_rad_actino_21_22_monthly",
          "f_22_23_avg_monthly": "rad_actino_22_23_monthly_avg_cal_cm-2",
          "f_22_23_mod_monthly": "rad_actino_22_23_monthly_mod_cal_cm-2",
          "f_22_23_med_monthly": "rad_actino_22_23_monthly_med_cal_cm-2",
          "f_22_23_max_monthly": "rad_actino_22_23_monthly_max_cal_cm-2",
          "f_22_23_min_monthly": "rad_actino_22_23_monthly_min_cal_cm-2",
          "f_22_23_sum_monthly": "rad_actino_22_23_monthly_sum_cal_cm-2",
          "f_22_23_count_monthly": "c_rad_actino_22_23_monthly",
          "f_23_24_avg_monthly": "rad_actino_23_24_monthly_avg_cal_cm-2",
          "f_23_24_mod_monthly": "rad_actino_23_24_monthly_mod_cal_cm-2",
          "f_23_24_med_monthly": "rad_actino_23_24_monthly_med_cal_cm-2",
          "f_23_24_max_monthly": "rad_actino_23_24_monthly_max_cal_cm-2",
          "f_23_24_min_monthly": "rad_actino_23_24_monthly_min_cal_cm-2",
          "f_23_24_sum_monthly": "rad_actino_23_24_monthly_sum_cal_cm-2",
          "f_23_24_count_monthly": "c_rad_actino_23_24_monthly",
          "f_total19_avg_monthly": "rad_actino_total_monthly_avg_cal_cm-2",
          "f_total19_mod_monthly": "rad_actino_total_monthly_mod_cal_cm-2",
          "f_total19_med_monthly": "rad_actino_total_monthly_med_cal_cm-2",
          "f_total19_max_monthly": "rad_actino_total_monthly_max_cal_cm-2",
          "f_total19_min_monthly": "rad_actino_total_monthly_min_cal_cm-2",
          "f_total19_sum_monthly": "rad_actino_total_monthly_sum_cal_cm-2",
          "f_total19_count_monthly": "c_rad_actino_total_monthly"
        },
      },
    ];

    return listAttr;
  },

  getAttrMonthlyDecimal() {
    const listAttr = [
      {
        label: "Intentitas Radiasi Matahari",
        attr: {
          "f_5_6_avg_monthly": 1,
          "f_5_6_mod_monthly": 1,
          "f_5_6_med_monthly": 1,
          "f_5_6_max_monthly": 1,
          "f_5_6_min_monthly": 1,
          "f_5_6_sum_monthly": 1,
          "f_5_6_count_monthly": 1,
          "f_6_7_avg_monthly": 1,
          "f_6_7_mod_monthly": 1,
          "f_6_7_med_monthly": 1,
          "f_6_7_max_monthly": 1,
          "f_6_7_min_monthly": 1,
          "f_6_7_sum_monthly": 1,
          "f_6_7_count_monthly": 1,
          "f_7_8_avg_monthly": 1,
          "f_7_8_mod_monthly": 1,
          "f_7_8_med_monthly": 1,
          "f_7_8_max_monthly": 1,
          "f_7_8_min_monthly": 1,
          "f_7_8_sum_monthly": 1,
          "f_7_8_count_monthly": 1,
          "f_8_9_avg_monthly": 1,
          "f_8_9_mod_monthly": 1,
          "f_8_9_med_monthly": 1,
          "f_8_9_max_monthly": 1,
          "f_8_9_min_monthly": 1,
          "f_8_9_sum_monthly": 1,
          "f_8_9_count_monthly": 1,
          "f_9_10_avg_monthly": 1,
          "f_9_10_mod_monthly": 1,
          "f_9_10_med_monthly": 1,
          "f_9_10_max_monthly": 1,
          "f_9_10_min_monthly": 1,
          "f_9_10_sum_monthly": 1,
          "f_9_10_count_monthly": 1,
          "f_10_11_avg_monthly": 1,
          "f_10_11_mod_monthly": 1,
          "f_10_11_med_monthly": 1,
          "f_10_11_max_monthly": 1,
          "f_10_11_min_monthly": 1,
          "f_10_11_sum_monthly": 1,
          "f_10_11_count_monthly": 1,
          "f_11_12_avg_monthly": 1,
          "f_11_12_mod_monthly": 1,
          "f_11_12_med_monthly": 1,
          "f_11_12_max_monthly": 1,
          "f_11_12_min_monthly": 1,
          "f_11_12_sum_monthly": 1,
          "f_11_12_count_monthly": 1,
          "f_12_13_avg_monthly": 1,
          "f_12_13_mod_monthly": 1,
          "f_12_13_med_monthly": 1,
          "f_12_13_max_monthly": 1,
          "f_12_13_min_monthly": 1,
          "f_12_13_sum_monthly": 1,
          "f_12_13_count_monthly": 1,
          "f_13_14_avg_monthly": 1,
          "f_13_14_mod_monthly": 1,
          "f_13_14_med_monthly": 1,
          "f_13_14_max_monthly": 1,
          "f_13_14_min_monthly": 1,
          "f_13_14_sum_monthly": 1,
          "f_13_14_count_monthly": 1,
          "f_14_15_avg_monthly": 1,
          "f_14_15_mod_monthly": 1,
          "f_14_15_med_monthly": 1,
          "f_14_15_max_monthly": 1,
          "f_14_15_min_monthly": 1,
          "f_14_15_sum_monthly": 1,
          "f_14_15_count_monthly": 1,
          "f_15_16_avg_monthly": 1,
          "f_15_16_mod_monthly": 1,
          "f_15_16_med_monthly": 1,
          "f_15_16_max_monthly": 1,
          "f_15_16_min_monthly": 1,
          "f_15_16_sum_monthly": 1,
          "f_15_16_count_monthly": 1,
          "f_16_17_avg_monthly": 1,
          "f_16_17_mod_monthly": 1,
          "f_16_17_med_monthly": 1,
          "f_16_17_max_monthly": 1,
          "f_16_17_min_monthly": 1,
          "f_16_17_sum_monthly": 1,
          "f_16_17_count_monthly": 1,
          "f_17_18_avg_monthly": 1,
          "f_17_18_mod_monthly": 1,
          "f_17_18_med_monthly": 1,
          "f_17_18_max_monthly": 1,
          "f_17_18_min_monthly": 1,
          "f_17_18_sum_monthly": 1,
          "f_17_18_count_monthly": 1,
          "f_18_19_avg_monthly": 1,
          "f_18_19_mod_monthly": 1,
          "f_18_19_med_monthly": 1,
          "f_18_19_max_monthly": 1,
          "f_18_19_min_monthly": 1,
          "f_18_19_sum_monthly": 1,
          "f_18_19_count_monthly": 1,
          "f_19_20_avg_monthly": 1,
          "f_19_20_mod_monthly": 1,
          "f_19_20_med_monthly": 1,
          "f_19_20_max_monthly": 1,
          "f_19_20_min_monthly": 1,
          "f_19_20_sum_monthly": 1,
          "f_19_20_count_monthly": 1,
          "f_20_21_avg_monthly": 1,
          "f_20_21_mod_monthly": 1,
          "f_20_21_med_monthly": 1,
          "f_20_21_max_monthly": 1,
          "f_20_21_min_monthly": 1,
          "f_20_21_sum_monthly": 1,
          "f_20_21_count_monthly": 1,
          "f_21_22_avg_monthly": 1,
          "f_21_22_mod_monthly": 1,
          "f_21_22_med_monthly": 1,
          "f_21_22_max_monthly": 1,
          "f_21_22_min_monthly": 1,
          "f_21_22_sum_monthly": 1,
          "f_21_22_count_monthly": 1,
          "f_22_23_avg_monthly": 1,
          "f_22_23_mod_monthly": 1,
          "f_22_23_med_monthly": 1,
          "f_22_23_max_monthly": 1,
          "f_22_23_min_monthly": 1,
          "f_22_23_sum_monthly": 1,
          "f_22_23_count_monthly": 1,
          "f_23_24_avg_monthly": 1,
          "f_23_24_mod_monthly": 1,
          "f_23_24_med_monthly": 1,
          "f_23_24_max_monthly": 1,
          "f_23_24_min_monthly": 1,
          "f_23_24_sum_monthly": 1,
          "f_23_24_count_monthly": 1,
          "f_total19_avg_monthly": 1,
          "f_total19_mod_monthly": 1,
          "f_total19_med_monthly": 1,
          "f_total19_max_monthly": 1,
          "f_total19_min_monthly": 1,
          "f_total19_sum_monthly": 1,
          "f_total19_count_monthly": 1
        },
      },
    ];

    return listAttr;
  },

  getAttrDaily() {
    const listAttr = [
      {
        label: "Intentitas Radiasi Matahari",
        attr: {
          f_total19: "Jumlah Radiasi Total dalam satu hari"
        }
      }
    ];
    return listAttr;
  },

  getAttrDailyCsv() {
    const listAttr = [
      {
        label: "Aktinograph Harian Csv",
        attr: {
          f_total19: "rad_actino_total_daily_cal_cm-2"
        },
      }
    ];
    return listAttr;
  },

  getAttrDailyDecimal() {
    const listAttr = [
      {
        label: "Aktinograph Harian Decimal",
        attr: {
          f_total19: 1
        },
      },
    ];
    return listAttr;
  },
};
