import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "KAH",
        attr: {
          "kah_ph":"pH",
          "kah_conductivity":"Electro-Conductivity",
          "kah_cl":"Cl- (klorida)",
          "kah_so42":"SO42- (Sulfat)",
          "kah_no3":"NO3- (Nitrat)",
          "kah_na":"Na+ (Natrium)",
          "kah_nh4":"NH4+ (ammonium)",
          "kah_k":"K+ (Kalium)",
          "kah_mg2":"Mg2+ (Magnesium)",
          "kah_ca2":"Ca2+ (Kalsium)",
          "kah_acidity":"Acidity",
          "kah_alkalinity":"Alkalinity",
          "kah_curah_hujan_sampling":"Curah Hujan Sampling",
          "kah_massa_sampling":"massa sampling"          
        },
      },
      
      
    //   {
    //     label: "Observer",
    //     attr: {
    //         "observer_id" : "ID observer",
    //         "observer_name" : "Nama observer "
    //         },
    // },
    ];

    return listAttr;
  },

  getAttrMonthly() {
    const listAttrMonthly = [
      {
        label: "KAH",
        attr: {
          "kah_ph_avg_monthly": "pH AVG MONTHLY",
          // "kah_ph_mod_monthly": "pH MOD MONTHLY",
          // "kah_ph_med_monthly": "pH MED MONTHLY",
          "kah_ph_max_monthly": "pH MAX MONTHLY",
          "kah_ph_min_monthly": "pH MIN MONTHLY",
          // "kah_ph_sum_monthly": "pH SUM MONTHLY",
          "kah_ph_count_monthly": "pH COUNT MONTHLY",
          "kah_conductivity_avg_monthly": "Electro-Conductivity AVG MONTHLY",
          // "kah_conductivity_mod_monthly": "Electro-Conductivity MOD MONTHLY",
          // "kah_conductivity_med_monthly": "Electro-Conductivity MED MONTHLY",
          "kah_conductivity_max_monthly": "Electro-Conductivity MAX MONTHLY",
          "kah_conductivity_min_monthly": "Electro-Conductivity MIN MONTHLY",
          // "kah_conductivity_sum_monthly": "Electro-Conductivity SUM MONTHLY",
          "kah_conductivity_count_monthly": "Electro-Conductivity COUNT MONTHLY",
          "kah_cl_avg_monthly": "Cl- (Klorida) AVG MONTHLY",
          // "kah_cl_mod_monthly": "Cl- (Klorida) MOD MONTHLY",
          // "kah_cl_med_monthly": "Cl- (Klorida) MED MONTHLY",
          "kah_cl_max_monthly": "Cl- (Klorida) MAX MONTHLY",
          "kah_cl_min_monthly": "Cl- (Klorida) MIN MONTHLY",
          // "kah_cl_sum_monthly": "Cl- (Klorida) SUM MONTHLY",
          "kah_cl_count_monthly": "Cl- (Klorida) COUNT MONTHLY",
          "kah_so42_avg_monthly": "SO42- (Sulfat) AVG MONTHLY",
          // "kah_so42_mod_monthly": "SO42- (Sulfat) MOD MONTHLY",
          // "kah_so42_med_monthly": "SO42- (Sulfat) MED MONTHLY",
          "kah_so42_max_monthly": "SO42- (Sulfat) MAX MONTHLY",
          "kah_so42_min_monthly": "SO42- (Sulfat) MIN MONTHLY",
          // "kah_so42_sum_monthly": "SO42- (Sulfat) SUM MONTHLY",
          "kah_so42_count_monthly": "SO42- (Sulfat) COUNT MONTHLY",
          "kah_no3_avg_monthly": "NO3- (Nitrat) AVG MONTHLY",
          // "kah_no3_mod_monthly": "NO3- (Nitrat) MOD MONTHLY",
          // "kah_no3_med_monthly": "NO3- (Nitrat) MED MONTHLY",
          "kah_no3_max_monthly": "NO3- (Nitrat) MAX MONTHLY",
          "kah_no3_min_monthly": "NO3- (Nitrat) MIN MONTHLY",
          // "kah_no3_sum_monthly": "NO3- (Nitrat) SUM MONTHLY",
          "kah_no3_count_monthly": "NO3- (Nitrat) COUNT MONTHLY",
          "kah_na_avg_monthly": "Na+ (Natrium) AVG MONTHLY",
          // "kah_na_mod_monthly": "Na+ (Natrium) MOD MONTHLY",
          // "kah_na_med_monthly": "Na+ (Natrium) MED MONTHLY",
          "kah_na_max_monthly": "Na+ (Natrium) MAX MONTHLY",
          "kah_na_min_monthly": "Na+ (Natrium) MIN MONTHLY",
          // "kah_na_sum_monthly": "Na+ (Natrium) SUM MONTHLY",
          "kah_na_count_monthly": "Na+ (Natrium) COUNT MONTHLY",
          "kah_nh4_avg_monthly": "NH4+ (Ammonium) AVG MONTHLY",
          // "kah_nh4_mod_monthly": "NH4+ (Ammonium) MOD MONTHLY",
          // "kah_nh4_med_monthly": "NH4+ (Ammonium) MED MONTHLY",
          "kah_nh4_max_monthly": "NH4+ (Ammonium) MAX MONTHLY",
          "kah_nh4_min_monthly": "NH4+ (Ammonium) MIN MONTHLY",
          // "kah_nh4_sum_monthly": "NH4+ (Ammonium) SUM MONTHLY",
          "kah_nh4_count_monthly": "NH4+ (Ammonium) COUNT MONTHLY",
          "kah_k_avg_monthly": "K+ (Kalium) AVG MONTHLY",
          // "kah_k_mod_monthly": "K+ (Kalium) MOD MONTHLY",
          // "kah_k_med_monthly": "K+ (Kalium) MED MONTHLY",
          "kah_k_max_monthly": "K+ (Kalium) MAX MONTHLY",
          "kah_k_min_monthly": "K+ (Kalium) MIN MONTHLY",
          // "kah_k_sum_monthly": "K+ (Kalium) SUM MONTHLY",
          "kah_k_count_monthly": "K+ (Kalium) COUNT MONTHLY",
          "kah_mg2_avg_monthly": "Mg2+ (Magnesium) AVG MONTHLY",
          // "kah_mg2_mod_monthly": "Mg2+ (Magnesium) MOD MONTHLY",
          // "kah_mg2_med_monthly": "Mg2+ (Magnesium) MED MONTHLY",
          "kah_mg2_max_monthly": "Mg2+ (Magnesium) MAX MONTHLY",
          "kah_mg2_min_monthly": "Mg2+ (Magnesium) MIN MONTHLY",
          // "kah_mg2_sum_monthly": "Mg2+ (Magnesium) SUM MONTHLY",
          "kah_mg2_count_monthly": "Mg2+ (Magnesium) COUNT MONTHLY",
          "kah_ca2_avg_monthly": "Ca2+ (Calsium) AVG MONTHLY",
          // "kah_ca2_mod_monthly": "Ca2+ (Calsium) MOD MONTHLY",
          // "kah_ca2_med_monthly": "Ca2+ (Calsium) MED MONTHLY",
          "kah_ca2_max_monthly": "Ca2+ (Calsium) MAX MONTHLY",
          "kah_ca2_min_monthly": "Ca2+ (Calsium) MIN MONTHLY",
          // "kah_ca2_sum_monthly": "Ca2+ (Calsium) SUM MONTHLY",
          "kah_ca2_count_monthly": "Ca2+ (Calsium) COUNT MONTHLY",
          "kah_acidity_avg_monthly": "Acidity AVG MONTHLY",
          // "kah_acidity_mod_monthly": "Acidity MOD MONTHLY",
          // "kah_acidity_med_monthly": "Acidity MED MONTHLY",
          "kah_acidity_max_monthly": "Acidity MAX MONTHLY",
          "kah_acidity_min_monthly": "Acidity MIN MONTHLY",
          // "kah_acidity_sum_monthly": "Acidity SUM MONTHLY",
          "kah_acidity_count_monthly": "Acidity COUNT MONTHLY",
          "kah_alkalinity_avg_monthly": "Alkalinity AVG MONTHLY",
          // "kah_alkalinity_mod_monthly": "Alkalinity MOD MONTHLY",
          // "kah_alkalinity_med_monthly": "Alkalinity MED MONTHLY",
          "kah_alkalinity_max_monthly": "Alkalinity MAX MONTHLY",
          "kah_alkalinity_min_monthly": "Alkalinity MIN MONTHLY",
          // "kah_alkalinity_sum_monthly": "Alkalinity SUM MONTHLY",
          "kah_alkalinity_count_monthly": "Alkalinity COUNT MONTHLY",
          // "kah_curah_hujan_sampling_avg_monthly": "Curah Hujan Sampling AVG MONTHLY",
          // "kah_curah_hujan_sampling_mod_monthly": "Curah Hujan Sampling MOD MONTHLY",
          // "kah_curah_hujan_sampling_med_monthly": "Curah Hujan Sampling MED MONTHLY",
          // "kah_curah_hujan_sampling_max_monthly": "Curah Hujan Sampling MAX MONTHLY",
          // "kah_curah_hujan_sampling_min_monthly": "Curah Hujan Sampling MIN MONTHLY",
          "kah_curah_hujan_sampling_sum_monthly": "Curah Hujan Sampling SUM MONTHLY",
          // "kah_curah_hujan_sampling_count_monthly": "Curah Hujan Sampling COUNT MONTHLY",
          "kah_massa_sampling_avg_monthly": "Massa Sampling AVG MONTHLY",
          // "kah_massa_sampling_mod_monthly": "Massa Sampling MOD MONTHLY",
          // "kah_massa_sampling_med_monthly": "Massa Sampling MED MONTHLY",
          // "kah_massa_sampling_max_monthly": "Massa Sampling MAX MONTHLY",
          // "kah_massa_sampling_min_monthly": "Massa Sampling MIN MONTHLY",
          // "kah_massa_sampling_sum_monthly": "Massa Sampling SUM MONTHLY",
          // "kah_massa_sampling_count_monthly": "Massa Sampling COUNT MONTHLY"
          "kah_kesadahan_total_avg_monthly": "Kesadahan Total AVG MONTHLY"
        },
      }
    ];
    return listAttrMonthly;
  },

  getAttrMonthlyDecimal() {
    const listAttrMonthly = [
      {
        label: "KAH",
        attr: {
          "kah_ph_avg_monthly": 2,
          // "kah_ph_mod_monthly": "pH MOD MONTHLY",
          // "kah_ph_med_monthly": "pH MED MONTHLY",
          "kah_ph_max_monthly": 2,
          "kah_ph_min_monthly": 2,
          // "kah_ph_sum_monthly": "pH SUM MONTHLY",
          "kah_ph_count_monthly": 2,
          "kah_conductivity_avg_monthly": 1,
          // "kah_conductivity_mod_monthly": "Electro-Conductivity MOD MONTHLY",
          // "kah_conductivity_med_monthly": "Electro-Conductivity MED MONTHLY",
          "kah_conductivity_max_monthly": 1,
          "kah_conductivity_min_monthly": 1,
          // "kah_conductivity_sum_monthly": "Electro-Conductivity SUM MONTHLY",
          "kah_conductivity_count_monthly": 1,
          "kah_cl_avg_monthly": 3,
          // "kah_cl_mod_monthly": "Cl- (Klorida) MOD MONTHLY",
          // "kah_cl_med_monthly": "Cl- (Klorida) MED MONTHLY",
          "kah_cl_max_monthly": 3,
          "kah_cl_min_monthly": 3,
          // "kah_cl_sum_monthly": "Cl- (Klorida) SUM MONTHLY",
          "kah_cl_count_monthly": 3,
          "kah_so42_avg_monthly": 3,
          // "kah_so42_mod_monthly": "SO42- (Sulfat) MOD MONTHLY",
          // "kah_so42_med_monthly": "SO42- (Sulfat) MED MONTHLY",
          "kah_so42_max_monthly": 3,
          "kah_so42_min_monthly": 3,
          // "kah_so42_sum_monthly": "SO42- (Sulfat) SUM MONTHLY",
          "kah_so42_count_monthly": 3,
          "kah_no3_avg_monthly": 3,
          // "kah_no3_mod_monthly": "NO3- (Nitrat) MOD MONTHLY",
          // "kah_no3_med_monthly": "NO3- (Nitrat) MED MONTHLY",
          "kah_no3_max_monthly": 3,
          "kah_no3_min_monthly": 3,
          // "kah_no3_sum_monthly": "NO3- (Nitrat) SUM MONTHLY",
          "kah_no3_count_monthly": 3,
          "kah_na_avg_monthly": 3,
          // "kah_na_mod_monthly": "Na+ (Natrium) MOD MONTHLY",
          // "kah_na_med_monthly": "Na+ (Natrium) MED MONTHLY",
          "kah_na_max_monthly": 3,
          "kah_na_min_monthly": 3,
          // "kah_na_sum_monthly": "Na+ (Natrium) SUM MONTHLY",
          "kah_na_count_monthly": 3,
          "kah_nh4_avg_monthly": 3,
          // "kah_nh4_mod_monthly": "NH4+ (Ammonium) MOD MONTHLY",
          // "kah_nh4_med_monthly": "NH4+ (Ammonium) MED MONTHLY",
          "kah_nh4_max_monthly": 3,
          "kah_nh4_min_monthly": 3,
          // "kah_nh4_sum_monthly": "NH4+ (Ammonium) SUM MONTHLY",
          "kah_nh4_count_monthly": 3,
          "kah_k_avg_monthly": 3,
          // "kah_k_mod_monthly": "K+ (Kalium) MOD MONTHLY",
          // "kah_k_med_monthly": "K+ (Kalium) MED MONTHLY",
          "kah_k_max_monthly": 3,
          "kah_k_min_monthly": 3,
          // "kah_k_sum_monthly": "K+ (Kalium) SUM MONTHLY",
          "kah_k_count_monthly": 3,
          "kah_mg2_avg_monthly": 3,
          // "kah_mg2_mod_monthly": "Mg2+ (Magnesium) MOD MONTHLY",
          // "kah_mg2_med_monthly": "Mg2+ (Magnesium) MED MONTHLY",
          "kah_mg2_max_monthly": 3,
          "kah_mg2_min_monthly": 3,
          // "kah_mg2_sum_monthly": "Mg2+ (Magnesium) SUM MONTHLY",
          "kah_mg2_count_monthly": 3,
          "kah_ca2_avg_monthly": 3,
          // "kah_ca2_mod_monthly": "Ca2+ (Calsium) MOD MONTHLY",
          // "kah_ca2_med_monthly": "Ca2+ (Calsium) MED MONTHLY",
          "kah_ca2_max_monthly": 3,
          "kah_ca2_min_monthly": 3,
          // "kah_ca2_sum_monthly": "Ca2+ (Calsium) SUM MONTHLY",
          "kah_ca2_count_monthly": 3,
          "kah_acidity_avg_monthly": 1,
          // "kah_acidity_mod_monthly": "Acidity MOD MONTHLY",
          // "kah_acidity_med_monthly": "Acidity MED MONTHLY",
          "kah_acidity_max_monthly": 1,
          "kah_acidity_min_monthly": 1,
          // "kah_acidity_sum_monthly": "Acidity SUM MONTHLY",
          "kah_acidity_count_monthly": 1,
          "kah_alkalinity_avg_monthly": 1,
          // "kah_alkalinity_mod_monthly": "Alkalinity MOD MONTHLY",
          // "kah_alkalinity_med_monthly": "Alkalinity MED MONTHLY",
          "kah_alkalinity_max_monthly": 1,
          "kah_alkalinity_min_monthly": 1,
          // "kah_alkalinity_sum_monthly": "Alkalinity SUM MONTHLY",
          "kah_alkalinity_count_monthly": 1,
          // "kah_curah_hujan_sampling_avg_monthly": "Curah Hujan Sampling AVG MONTHLY",
          // "kah_curah_hujan_sampling_mod_monthly": "Curah Hujan Sampling MOD MONTHLY",
          // "kah_curah_hujan_sampling_med_monthly": "Curah Hujan Sampling MED MONTHLY",
          // "kah_curah_hujan_sampling_max_monthly": "Curah Hujan Sampling MAX MONTHLY",
          // "kah_curah_hujan_sampling_min_monthly": "Curah Hujan Sampling MIN MONTHLY",
          "kah_curah_hujan_sampling_sum_monthly": 1,
          // "kah_curah_hujan_sampling_count_monthly": "Curah Hujan Sampling COUNT MONTHLY",
          "kah_massa_sampling_avg_monthly": 1,
          // "kah_massa_sampling_mod_monthly": "Massa Sampling MOD MONTHLY",
          // "kah_massa_sampling_med_monthly": "Massa Sampling MED MONTHLY",
          // "kah_massa_sampling_max_monthly": "Massa Sampling MAX MONTHLY",
          // "kah_massa_sampling_min_monthly": "Massa Sampling MIN MONTHLY",
          // "kah_massa_sampling_sum_monthly": "Massa Sampling SUM MONTHLY",
          // "kah_massa_sampling_count_monthly": "Massa Sampling COUNT MONTHLY"
          "kah_kesadahan_total_avg_monthly": 3
        },
      }
    ];
    return listAttrMonthly;
  },

  getAttrMonthlyCsv() {
    const listAttrMonthly = [
      {
        label: "KAH",
        attr: {
          "kah_ph_avg_monthly": "kah_pH_monthly_ave",
          "kah_ph_mod_monthly": "kah_pH_monthly_mod",
          "kah_ph_med_monthly": "kah_pH_monthly_med",
          "kah_ph_max_monthly": "kah_pH_monthly_max",
          "kah_ph_min_monthly": "kah_pH_monthly_min",
          "kah_ph_sum_monthly": "kah_pH_monthly_sum",
          "kah_ph_count_monthly": "count_kah_pH_monthly",
          "kah_conductivity_avg_monthly": "kah_Electro_Conductivity_monthly_ave",
          "kah_conductivity_mod_monthly": "kah_Electro_Conductivity_monthly_mod",
          "kah_conductivity_med_monthly": "kah_Electro_Conductivity_monthly_med",
          "kah_conductivity_max_monthly": "kah_Electro_Conductivity_monthly_max",
          "kah_conductivity_min_monthly": "kah_Electro_Conductivity_monthly_min",
          "kah_conductivity_sum_monthly": "kah_Electro_Conductivity_monthly_sum",
          "kah_conductivity_count_monthly": "count_kah_Electro_Conductivity_monthly",
          "kah_cl_avg_monthly": "kah_Cl_monthly_ave",
          "kah_cl_mod_monthly": "kah_Cl_monthly_mod",
          "kah_cl_med_monthly": "kah_Cl_monthly_med",
          "kah_cl_max_monthly": "kah_Cl_monthly_max",
          "kah_cl_min_monthly": "kah_Cl_monthly_min",
          "kah_cl_sum_monthly": "kah_Cl_monthly_sum",
          "kah_cl_count_monthly": "count_kah_Cl_monthly",
          "kah_so42_avg_monthly": "kah_SO4_monthly_ave",
          "kah_so42_mod_monthly": "kah_SO4_monthly_mod",
          "kah_so42_med_monthly": "kah_SO4_monthly_med",
          "kah_so42_max_monthly": "kah_SO4_monthly_max",
          "kah_so42_min_monthly": "kah_SO4_monthly_min",
          "kah_so42_sum_monthly": "kah_SO4_monthly_sum",
          "kah_so42_count_monthly": "count_kah_SO4_monthly",
          "kah_no3_avg_monthly": "kah_NO3_monthly_ave",
          "kah_no3_mod_monthly": "kah_NO3_monthly_mod",
          "kah_no3_med_monthly": "kah_NO3_monthly_med",
          "kah_no3_max_monthly": "kah_NO3_monthly_max",
          "kah_no3_min_monthly": "kah_NO3_monthly_min",
          "kah_no3_sum_monthly": "kah_NO3_monthly_sum",
          "kah_no3_count_monthly": "count_kah_NO3_monthly",
          "kah_na_avg_monthly": "kah_Na_monthly_ave",
          "kah_na_mod_monthly": "kah_Na_monthly_mod",
          "kah_na_med_monthly": "kah_Na_monthly_med",
          "kah_na_max_monthly": "kah_Na_monthly_max",
          "kah_na_min_monthly": "kah_Na_monthly_min",
          "kah_na_sum_monthly": "kah_Na_monthly_sum",
          "kah_na_count_monthly": "count_kah_Na_monthly",
          "kah_nh4_avg_monthly": "kah_NH4_monthly_ave",
          "kah_nh4_mod_monthly": "kah_NH4_monthly_mod",
          "kah_nh4_med_monthly": "kah_NH4_monthly_med",
          "kah_nh4_max_monthly": "kah_NH4_monthly_max",
          "kah_nh4_min_monthly": "kah_NH4_monthly_min",
          "kah_nh4_sum_monthly": "kah_NH4_monthly_sum",
          "kah_nh4_count_monthly": "count_kah_NH4_monthly",
          "kah_k_avg_monthly": "kah_K_monthly_ave",
          "kah_k_mod_monthly": "kah_K_monthly_mod",
          "kah_k_med_monthly": "kah_K_monthly_med",
          "kah_k_max_monthly": "kah_K_monthly_max",
          "kah_k_min_monthly": "kah_K_monthly_min",
          "kah_k_sum_monthly": "kah_K_monthly_sum",
          "kah_k_count_monthly": "count_kah_K_monthly",
          "kah_mg2_avg_monthly": "kah_Mg_monthly_ave",
          "kah_mg2_mod_monthly": "kah_Mg_monthly_mod",
          "kah_mg2_med_monthly": "kah_Mg_monthly_med",
          "kah_mg2_max_monthly": "kah_Mg_monthly_max",
          "kah_mg2_min_monthly": "kah_Mg_monthly_min",
          "kah_mg2_sum_monthly": "kah_Mg_monthly_sum",
          "kah_mg2_count_monthly": "count_kah_Mg_monthly",
          "kah_ca2_avg_monthly": "kah_Ca_monthly_ave",
          "kah_ca2_mod_monthly": "kah_Ca_monthly_mod",
          "kah_ca2_med_monthly": "kah_Ca_monthly_med",
          "kah_ca2_max_monthly": "kah_Ca_monthly_max",
          "kah_ca2_min_monthly": "kah_Ca_monthly_min",
          "kah_ca2_sum_monthly": "kah_Ca_monthly_sum",
          "kah_ca2_count_monthly": "count_kah_Ca_monthly",
          "kah_acidity_avg_monthly": "kah_Acidity_monthly_ave",
          "kah_acidity_mod_monthly": "kah_Acidity_monthly_mod",
          "kah_acidity_med_monthly": "kah_Acidity_monthly_med",
          "kah_acidity_max_monthly": "kah_Acidity_monthly_max",
          "kah_acidity_min_monthly": "kah_Acidity_monthly_min",
          "kah_acidity_sum_monthly": "kah_Acidity_monthly_sum",
          "kah_acidity_count_monthly": "count_kah_acidity_monthly",
          "kah_alkalinity_avg_monthly": "kah_Alkalinity_monthly_ave",
          "kah_alkalinity_mod_monthly": "kah_Alkalinity_monthly_mod",
          "kah_alkalinity_med_monthly": "kah_Alkalinity_monthly_med",
          "kah_alkalinity_max_monthly": "kah_Alkalinity_monthly_max",
          "kah_alkalinity_min_monthly": "kah_Alkalinity_monthly_min",
          "kah_alkalinity_sum_monthly": "kah_Alkalinity_monthly_sum",
          "kah_alkalinity_count_monthly": "count_kah_Alkalinity_monthly",
          "kah_curah_hujan_sampling_avg_monthly": "kah_Ch_sampling_monthly_ave",
          "kah_curah_hujan_sampling_mod_monthly": "kah_Ch_sampling_monthly_mod",
          "kah_curah_hujan_sampling_med_monthly": "kah_Ch_sampling_monthly_med",
          "kah_curah_hujan_sampling_max_monthly": "kah_Ch_sampling_monthly_max",
          "kah_curah_hujan_sampling_min_monthly": "kah_Ch_sampling_monthly_min",
          "kah_curah_hujan_sampling_sum_monthly": "kah_Ch_sampling_monthly_total",
          "kah_curah_hujan_sampling_count_monthly": "count_kah_Ch_sampling_monthly",
          "kah_massa_sampling_avg_monthly": "kah_massa_sampling_monthly_ave",
          "kah_massa_sampling_mod_monthly": "kah_massa_sampling_monthly_mod",
          "kah_massa_sampling_med_monthly": "kah_massa_sampling_monthly_med",
          "kah_massa_sampling_max_monthly": "kah_massa_sampling_monthly_max",
          "kah_massa_sampling_min_monthly": "kah_massa_sampling_monthly_min",
          "kah_massa_sampling_sum_monthly": "kah_massa_sampling_monthly_sum",
          "kah_massa_sampling_count_monthly": "count_massa_sampling_monthly",
          "kah_kesadahan_total_avg_monthly": "kah_kesadahan_total_monthly_ave"
        },
      }
    ];
    return listAttrMonthly;
  }
};
