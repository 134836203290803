import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
        {
            label: "Angin",
            attr: {
                "wind_indicator_iw":"Pengenal Data Angin (iw)",
                "wind_dir_deg_dd":"Arah Angin (derajat)",
                "wind_speed_ff":"Kecepatan Angin (knot)",
                "visibility_vv":"Jarak penglihatan mendatar (km)",
                },  
        },
        {
            label: "Cuaca",
            attr: {
                "weather_indicator_ix":"Pengenal Data Cuaca (ix)",
                "present_weather_ww":"Cuaca Saat Pengamatan (ww)",
                "past_weather_w1":"Cuaca yang lalu (W1)",
                "past_weather_w2":"Cuaca yang lalu (W2)",
                },  
        },
        {
            label: "Tekanan",
            attr: {
                "pressure_temp_c":"Derajat Panas (℃)",
                "pressure_reading_mb":"Tekanan dibaca (mb)",
                "pressure_qfe_mb_derived":"Tekanan QFE",
                "pressure_qff_mb_derived":"Tekanan QFF",
                },  
        },
        {
            label: "Suhu",
            attr: {
                "temp_drybulb_c_tttttt":"Suhu Bola Kering (℃)",
                "temp_wetbulb_c":"Suhu Bola basah (℃)",
                "temp_max_c_txtxtx":"Suhu Maksimum (℃)",
                "temp_min_c_tntntn":"Suhu Minimum (℃)",
                },  
        },
        {
            label: "Awan Rendah",
            attr: {
                "cloud_low_type_cl":"CL",
                "cloud_low_cover_oktas":"NCL",
                "cloud_low_type_1":"Jenis 1",
                "cloud_low_type_2":"Jenis 2",
                "cloud_low_type_3":"Jenis 3",
                "cloud_low_cover_1":"Jumlah 1",
                "cloud_low_cover_2":"Jumlah 2",
                "cloud_low_cover_3":"Jumlah 3",
                "cloud_low_base_1":"Tinggi Dasar (m) 1",
                "cloud_low_base_2":"Tinggi Dasar (m) 2",
                "cloud_low_base_3":"Tinggi Dasar (m) 3",
                "cloud_low_peak_1":"Tinggi Puncak (m) 1",
                "cloud_low_peak_2":"Tinggi Puncak (m) 2",
                "cloud_low_dir_1":"Arah 1",
                "cloud_low_dir_2":"Arah 2",
                "cloud_low_dir_3":"Arah 3",
                "cloud_elevation_angle_ec_1":"Sudut Elevasi 1",
                "cloud_elevation_angle_ec_2":"Sudut Elevasi 2",
                },  
        },
        {
            label: "Awan Menengah",
            attr: {
                "cloud_med_type_cm":"CM",
                "cloud_med_cover_oktas":"NCM",
                "cloud_med_type_1":"Jenis 1",
                "cloud_med_type_2":"Jenis 2",
                "cloud_med_cover_1":"Jumlah 1",
                "cloud_med_cover_2":"Jumlah 2",
                "cloud_med_base_1":"Tinggi Dasar (m) 1",
                "cloud_med_base_2":"Tinggi Dasar (m) 2",
                "cloud_med_dir_1":"Arah 1",
                "cloud_med_dir_2":"Arah 2",
                },  
        },
        {
            label: "Awan Tinggi",
            attr: {
                "cloud_high_type_ch":"CH",
                "cloud_high_cover_oktas":"NCH",
                "cloud_high_type_1":"Jenis 1",
                "cloud_high_type_2":"Jenis 2",
                "cloud_high_cover_1":"Jumlah 1",
                "cloud_high_cover_2":"Jumlah 2",
                "cloud_high_base_1":"Tinggi Dasar (m) 1",
                "cloud_high_base_2":"Tinggi Dasar (m) 2",
                "cloud_high_dir_1":"Arah 1",
                "cloud_high_dir_2":"Arah 2",
                },  
        },
        {
            label: "Sandi & Lain-Lain",
            attr: {
                "cloud_cover_oktas_m":"Bagian Langit Tertutup Awan (oktas)",
                "rainfall_last_mm":"Hujan ditakar (mm)",
                "evaporation_24hours_mm_eee":"Penguapan (mm)",
                "evaporation_eq_indicator_ie":"Pengenal Data Penguapan(IE)",
                "solar_rad_24h_jcm2_f24":"Radiasi Matahari (Joule/cm2)",
                "sunshine_h_sss":"Lama Penyinaran Matahari (jam)",
                "encoded_synop":"Sandi",
                },  
        },
        ]
        return listAttr;
    }
    
}







