import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "Lama Penyinaran",
        attr: {
          ss_6_7: "Lamanya penyinaran antara Jam 6-7",
          ss_7_8: "Lamanya penyinaran antara Jam 7-8",
          ss_8_9: "Lamanya penyinaran antara Jam 8-9",
          ss_9_10: "Lamanya penyinaran antara Jam 9-10",
          ss_10_11: "Lamanya penyinaran antara Jam 10-11",
          ss_11_12: "Lamanya penyinaran antara Jam 11-12",
          ss_12_13: "Lamanya penyinaran antara Jam 12-13",
          ss_13_14: "Lamanya penyinaran antara Jam 13-14",
          ss_14_15: "Lamanya penyinaran antara Jam 14-15",
          ss_15_16: "Lamanya penyinaran antara Jam 15-16",
          ss_16_17: "Lamanya penyinaran antara Jam 16-17",
          ss_17_18: "Lamanya penyinaran antara Jam 17-18",
          ss_total_8: "Jumlah lamanya penyinaran matahari 8 sd 16",
          ss_total_12: "Jumlah lamanya penyinaran matahari 6 sd 18",
          ss_PC_8 : "Jumlah lamanya penyinaran matahari 8 sd 16 (dalam persen)",
          ss_PC_12 : "Jumlah lamanya penyinaran matahari  6 sd 18 (dalam persen)",
        },
      },
    ];

    return listAttr;
  },

  getAttrMonthly() {
    const listAttr = [
      {
        group: "Lama Penyinaran",
        form: "Lama Penyinaran",
        label: "Lama Penyinaran",
        attr: {
          "ss_6_7_avg_monthly": "Lamanya penyinaran antara Jam 6-7 AVG MONTHLY",
          // "ss_6_7_mod_monthly": "Lamanya penyinaran antara Jam 6-7 MOD MONTHLY",
          // "ss_6_7_med_monthly": "Lamanya penyinaran antara Jam 6-7 MED MONTHLY",
          "ss_6_7_max_monthly": "Lamanya penyinaran antara Jam 6-7 MAX MONTHLY",
          "ss_6_7_min_monthly": "Lamanya penyinaran antara Jam 6-7 MIN MONTHLY",
          "ss_6_7_sum_monthly": "Lamanya penyinaran antara Jam 6-7 SUM MONTHLY",
          "ss_6_7_count_monthly": "Lamanya penyinaran antara Jam 6-7 COUNT MONTHLY",
          "ss_7_8_avg_monthly": "Lamanya penyinaran antara Jam 7-8 AVG MONTHLY",
          // "ss_7_8_mod_monthly": "Lamanya penyinaran antara Jam 7-8 MOD MONTHLY",
          // "ss_7_8_med_monthly": "Lamanya penyinaran antara Jam 7-8 MED MONTHLY",
          "ss_7_8_max_monthly": "Lamanya penyinaran antara Jam 7-8 MAX MONTHLY",
          "ss_7_8_min_monthly": "Lamanya penyinaran antara Jam 7-8 MIN MONTHLY",
          "ss_7_8_sum_monthly": "Lamanya penyinaran antara Jam 7-8 SUM MONTHLY",
          "ss_7_8_count_monthly": "Lamanya penyinaran antara Jam 7-8 COUNT MONTHLY",
          "ss_8_9_avg_monthly": "Lamanya penyinaran antara Jam 8-9 AVG MONTHLY",
          // "ss_8_9_mod_monthly": "Lamanya penyinaran antara Jam 8-9 MOD MONTHLY",
          // "ss_8_9_med_monthly": "Lamanya penyinaran antara Jam 8-9 MED MONTHLY",
          "ss_8_9_max_monthly": "Lamanya penyinaran antara Jam 8-9 MAX MONTHLY",
          "ss_8_9_min_monthly": "Lamanya penyinaran antara Jam 8-9 MIN MONTHLY",
          "ss_8_9_sum_monthly": "Lamanya penyinaran antara Jam 8-9 SUM MONTHLY",
          "ss_8_9_count_monthly": "Lamanya penyinaran antara Jam 8-9 COUNT MONTHLY",
          "ss_9_10_avg_monthly": "Lamanya penyinaran antara Jam 9-10 AVG MONTHLY",
          // "ss_9_10_mod_monthly": "Lamanya penyinaran antara Jam 9-10 MOD MONTHLY",
          // "ss_9_10_med_monthly": "Lamanya penyinaran antara Jam 9-10 MED MONTHLY",
          "ss_9_10_max_monthly": "Lamanya penyinaran antara Jam 9-10 MAX MONTHLY",
          "ss_9_10_min_monthly": "Lamanya penyinaran antara Jam 9-10 MIN MONTHLY",
          "ss_9_10_sum_monthly": "Lamanya penyinaran antara Jam 9-10 SUM MONTHLY",
          "ss_9_10_count_monthly": "Lamanya penyinaran antara Jam 9-10 COUNT MONTHLY",
          "ss_10_11_avg_monthly": "Lamanya penyinaran antara Jam 10-11 AVG MONTHLY",
          // "ss_10_11_mod_monthly": "Lamanya penyinaran antara Jam 10-11 MOD MONTHLY",
          // "ss_10_11_med_monthly": "Lamanya penyinaran antara Jam 10-11 MED MONTHLY",
          "ss_10_11_max_monthly": "Lamanya penyinaran antara Jam 10-11 MAX MONTHLY",
          "ss_10_11_min_monthly": "Lamanya penyinaran antara Jam 10-11 MIN MONTHLY",
          "ss_10_11_sum_monthly": "Lamanya penyinaran antara Jam 10-11 SUM MONTHLY",
          "ss_10_11_count_monthly": "Lamanya penyinaran antara Jam 10-11 COUNT MONTHLY",
          "ss_11_12_avg_monthly": "Lamanya penyinaran antara Jam 11-12 AVG MONTHLY",
          // "ss_11_12_mod_monthly": "Lamanya penyinaran antara Jam 11-12 MOD MONTHLY",
          // "ss_11_12_med_monthly": "Lamanya penyinaran antara Jam 11-12 MED MONTHLY",
          "ss_11_12_max_monthly": "Lamanya penyinaran antara Jam 11-12 MAX MONTHLY",
          "ss_11_12_min_monthly": "Lamanya penyinaran antara Jam 11-12 MIN MONTHLY",
          "ss_11_12_sum_monthly": "Lamanya penyinaran antara Jam 11-12 SUM MONTHLY",
          "ss_11_12_count_monthly": "Lamanya penyinaran antara Jam 11-12 COUNT MONTHLY",
          "ss_12_13_avg_monthly": "Lamanya penyinaran antara Jam 12-13 AVG MONTHLY",
          // "ss_12_13_mod_monthly": "Lamanya penyinaran antara Jam 12-13 MOD MONTHLY",
          // "ss_12_13_med_monthly": "Lamanya penyinaran antara Jam 12-13 MED MONTHLY",
          "ss_12_13_max_monthly": "Lamanya penyinaran antara Jam 12-13 MAX MONTHLY",
          "ss_12_13_min_monthly": "Lamanya penyinaran antara Jam 12-13 MIN MONTHLY",
          "ss_12_13_sum_monthly": "Lamanya penyinaran antara Jam 12-13 SUM MONTHLY",
          "ss_12_13_count_monthly": "Lamanya penyinaran antara Jam 12-13 COUNT MONTHLY",
          "ss_13_14_avg_monthly": "Lamanya penyinaran antara Jam 13-14 AVG MONTHLY",
          // "ss_13_14_mod_monthly": "Lamanya penyinaran antara Jam 13-14 MOD MONTHLY",
          // "ss_13_14_med_monthly": "Lamanya penyinaran antara Jam 13-14 MED MONTHLY",
          "ss_13_14_max_monthly": "Lamanya penyinaran antara Jam 13-14 MAX MONTHLY",
          "ss_13_14_min_monthly": "Lamanya penyinaran antara Jam 13-14 MIN MONTHLY",
          "ss_13_14_sum_monthly": "Lamanya penyinaran antara Jam 13-14 SUM MONTHLY",
          "ss_13_14_count_monthly": "Lamanya penyinaran antara Jam 13-14 COUNT MONTHLY",
          "ss_14_15_avg_monthly": "Lamanya penyinaran antara Jam 14-15 AVG MONTHLY",
          // "ss_14_15_mod_monthly": "Lamanya penyinaran antara Jam 14-15 MOD MONTHLY",
          // "ss_14_15_med_monthly": "Lamanya penyinaran antara Jam 14-15 MED MONTHLY",
          "ss_14_15_max_monthly": "Lamanya penyinaran antara Jam 14-15 MAX MONTHLY",
          "ss_14_15_min_monthly": "Lamanya penyinaran antara Jam 14-15 MIN MONTHLY",
          "ss_14_15_sum_monthly": "Lamanya penyinaran antara Jam 14-15 SUM MONTHLY",
          "ss_14_15_count_monthly": "Lamanya penyinaran antara Jam 14-15 COUNT MONTHLY",
          "ss_15_16_avg_monthly": "Lamanya penyinaran antara Jam 15-16 AVG MONTHLY",
          // "ss_15_16_mod_monthly": "Lamanya penyinaran antara Jam 15-16 MOD MONTHLY",
          // "ss_15_16_med_monthly": "Lamanya penyinaran antara Jam 15-16 MED MONTHLY",
          "ss_15_16_max_monthly": "Lamanya penyinaran antara Jam 15-16 MAX MONTHLY",
          "ss_15_16_min_monthly": "Lamanya penyinaran antara Jam 15-16 MIN MONTHLY",
          "ss_15_16_sum_monthly": "Lamanya penyinaran antara Jam 15-16 SUM MONTHLY",
          "ss_15_16_count_monthly": "Lamanya penyinaran antara Jam 15-16 COUNT MONTHLY",
          "ss_16_17_avg_monthly": "Lamanya penyinaran antara Jam 16-17 AVG MONTHLY",
          // "ss_16_17_mod_monthly": "Lamanya penyinaran antara Jam 16-17 MOD MONTHLY",
          // "ss_16_17_med_monthly": "Lamanya penyinaran antara Jam 16-17 MED MONTHLY",
          "ss_16_17_max_monthly": "Lamanya penyinaran antara Jam 16-17 MAX MONTHLY",
          "ss_16_17_min_monthly": "Lamanya penyinaran antara Jam 16-17 MIN MONTHLY",
          "ss_16_17_sum_monthly": "Lamanya penyinaran antara Jam 16-17 SUM MONTHLY",
          "ss_16_17_count_monthly": "Lamanya penyinaran antara Jam 16-17 COUNT MONTHLY",
          "ss_17_18_avg_monthly": "Lamanya penyinaran antara Jam 17-18 AVG MONTHLY",
          // "ss_17_18_mod_monthly": "Lamanya penyinaran antara Jam 17-18 MOD MONTHLY",
          // "ss_17_18_med_monthly": "Lamanya penyinaran antara Jam 17-18 MED MONTHLY",
          "ss_17_18_max_monthly": "Lamanya penyinaran antara Jam 17-18 MAX MONTHLY",
          "ss_17_18_min_monthly": "Lamanya penyinaran antara Jam 17-18 MIN MONTHLY",
          "ss_17_18_sum_monthly": "Lamanya penyinaran antara Jam 17-18 SUM MONTHLY",
          "ss_17_18_count_monthly": "Lamanya penyinaran antara Jam 17-18 COUNT MONTHLY",
          "ss_total_8_avg_monthly": "Jumlah lamanya penyinaran matahari 8 sd 16 AVG MONTHLY",
          // "ss_total_8_mod_monthly": "Jumlah lamanya penyinaran matahari 8 sd 16 MOD MONTHLY",
          // "ss_total_8_med_monthly": "Jumlah lamanya penyinaran matahari 8 sd 16 MED MONTHLY",
          "ss_total_8_max_monthly": "Jumlah lamanya penyinaran matahari 8 sd 16 MAX MONTHLY",
          "ss_total_8_min_monthly": "Jumlah lamanya penyinaran matahari 8 sd 16 MIN MONTHLY",
          "ss_total_8_sum_monthly": "Jumlah lamanya penyinaran matahari 8 sd 16 SUM MONTHLY",
          "ss_total_8_count_monthly": "Jumlah lamanya penyinaran matahari 8 sd 16 COUNT MONTHLY",
          "ss_total_12_avg_monthly": "Jumlah lamanya penyinaran matahari 6 sd 18 AVG MONTHLY",
          // "ss_total_12_mod_monthly": "Jumlah lamanya penyinaran matahari 6 sd 18 MOD MONTHLY",
          // "ss_total_12_med_monthly": "Jumlah lamanya penyinaran matahari 6 sd 18 MED MONTHLY",
          "ss_total_12_max_monthly": "Jumlah lamanya penyinaran matahari 6 sd 18 MAX MONTHLY",
          "ss_total_12_min_monthly": "Jumlah lamanya penyinaran matahari 6 sd 18 MIN MONTHLY",
          "ss_total_12_sum_monthly": "Jumlah lamanya penyinaran matahari 6 sd 18 SUM MONTHLY",
          "ss_total_12_count_monthly": "Jumlah lamanya penyinaran matahari 6 sd 18 COUNT MONTHLY",
        },
      },
    ];

    return listAttr;
  },

  getAttrMonthlyCsv() {
    const listAttr = [
      {
        label: "Lama Penyinaran",
        attr: {
          "ss_6_7_avg_monthly": "ss_6_7_monthly_avg_jam",
          "ss_6_7_mod_monthly": "ss_6_7_monthly_mod",
          "ss_6_7_med_monthly": "ss_6_7_monthly_med",
          "ss_6_7_max_monthly": "ss_6_7_monthly_max_jam",
          "ss_6_7_min_monthly": "ss_6_7_monthly_min_jam",
          "ss_6_7_sum_monthly": "ss_6_7_monthly_total_jam",
          "ss_6_7_count_monthly": "c_ss_6_7_monthly",
          "ss_7_8_avg_monthly": "ss_7_8_monthly_avg_jam",
          "ss_7_8_mod_monthly": "ss_7_8_monthly_mod",
          "ss_7_8_med_monthly": "ss_7_8_monthly_med",
          "ss_7_8_max_monthly": "ss_7_8_monthly_max_jam",
          "ss_7_8_min_monthly": "ss_7_8_monthly_min_jam",
          "ss_7_8_sum_monthly": "ss_7_8_monthly_total_jam",
          "ss_7_8_count_monthly": "c_ss_7_8_monthly",
          "ss_8_9_avg_monthly": "ss_8_9_monthly_avg_jam",
          "ss_8_9_mod_monthly": "ss_8_9_monthly_mod",
          "ss_8_9_med_monthly": "ss_8_9_monthly_med",
          "ss_8_9_max_monthly": "ss_8_9_monthly_max_jam",
          "ss_8_9_min_monthly": "ss_8_9_monthly_min_jam",
          "ss_8_9_sum_monthly": "ss_8_9_monthly_total_jam",
          "ss_8_9_count_monthly": "c_ss_8_9_monthly",
          "ss_9_10_avg_monthly": "ss_9_10_monthly_avg_jam",
          "ss_9_10_mod_monthly": "ss_9_10_monthly_mod",
          "ss_9_10_med_monthly": "ss_9_10_monthly_med",
          "ss_9_10_max_monthly": "ss_9_10_monthly_max_jam",
          "ss_9_10_min_monthly": "ss_9_10_monthly_min_jam",
          "ss_9_10_sum_monthly": "ss_9_10_monthly_total_jam",
          "ss_9_10_count_monthly": "c_ss_9_10_monthly",
          "ss_10_11_avg_monthly": "ss_10_11_monthly_avg_jam",
          "ss_10_11_mod_monthly": "ss_10_11_monthly_mod",
          "ss_10_11_med_monthly": "ss_10_11_monthly_med",
          "ss_10_11_max_monthly": "ss_10_11_monthly_max_jam",
          "ss_10_11_min_monthly": "ss_10_11_monthly_min_jam",
          "ss_10_11_sum_monthly": "ss_10_11_monthly_total_jam",
          "ss_10_11_count_monthly": "c_ss_10_11_monthly",
          "ss_11_12_avg_monthly": "ss_11_12_monthly_avg_jam",
          "ss_11_12_mod_monthly": "ss_11_12_monthly_mod",
          "ss_11_12_med_monthly": "ss_11_12_monthly_med",
          "ss_11_12_max_monthly": "ss_11_12_monthly_max_jam",
          "ss_11_12_min_monthly": "ss_11_12_monthly_min_jam",
          "ss_11_12_sum_monthly": "ss_11_12_monthly_total_jam",
          "ss_11_12_count_monthly": "c_ss_11_12_monthly",
          "ss_12_13_avg_monthly": "ss_12_13_monthly_avg_jam",
          "ss_12_13_mod_monthly": "ss_12_13_monthly_mod",
          "ss_12_13_med_monthly": "ss_12_13_monthly_med",
          "ss_12_13_max_monthly": "ss_12_13_monthly_max_jam",
          "ss_12_13_min_monthly": "ss_12_13_monthly_min_jam",
          "ss_12_13_sum_monthly": "ss_12_13_monthly_total_jam",
          "ss_12_13_count_monthly": "c_ss_12_13_monthly",
          "ss_13_14_avg_monthly": "ss_13_14_monthly_avg_jam",
          "ss_13_14_mod_monthly": "ss_13_14_monthly_mod",
          "ss_13_14_med_monthly": "ss_13_14_monthly_med",
          "ss_13_14_max_monthly": "ss_13_14_monthly_max_jam",
          "ss_13_14_min_monthly": "ss_13_14_monthly_min_jam",
          "ss_13_14_sum_monthly": "ss_13_14_monthly_total_jam",
          "ss_13_14_count_monthly": "c_ss_13_14_monthly",
          "ss_14_15_avg_monthly": "ss_14_15_monthly_avg_jam",
          "ss_14_15_mod_monthly": "ss_14_15_monthly_mod",
          "ss_14_15_med_monthly": "ss_14_15_monthly_med",
          "ss_14_15_max_monthly": "ss_14_15_monthly_max_jam",
          "ss_14_15_min_monthly": "ss_14_15_monthly_min_jam",
          "ss_14_15_sum_monthly": "ss_14_15_monthly_total_jam",
          "ss_14_15_count_monthly": "c_ss_14_15_monthly",
          "ss_15_16_avg_monthly": "ss_15_16_monthly_avg_jam",
          "ss_15_16_mod_monthly": "ss_15_16_monthly_mod",
          "ss_15_16_med_monthly": "ss_15_16_monthly_med",
          "ss_15_16_max_monthly": "ss_15_16_monthly_max_jam",
          "ss_15_16_min_monthly": "ss_15_16_monthly_min_jam",
          "ss_15_16_sum_monthly": "ss_15_16_monthly_total_jam",
          "ss_15_16_count_monthly": "c_ss_15_16_monthly",
          "ss_16_17_avg_monthly": "ss_16_17_monthly_avg_jam",
          "ss_16_17_mod_monthly": "ss_16_17_monthly_mod",
          "ss_16_17_med_monthly": "ss_16_17_monthly_med",
          "ss_16_17_max_monthly": "ss_16_17_monthly_max_jam",
          "ss_16_17_min_monthly": "ss_16_17_monthly_min_jam",
          "ss_16_17_sum_monthly": "ss_16_17_monthly_total_jam",
          "ss_16_17_count_monthly": "c_ss_16_17_monthly",
          "ss_17_18_avg_monthly": "ss_17_18_monthly_avg_jam",
          "ss_17_18_mod_monthly": "ss_17_18_monthly_mod",
          "ss_17_18_med_monthly": "ss_17_18_monthly_med",
          "ss_17_18_max_monthly": "ss_17_18_monthly_max_jam",
          "ss_17_18_min_monthly": "ss_17_18_monthly_min_jam",
          "ss_17_18_sum_monthly": "ss_17_18_monthly_total_jam",
          "ss_17_18_count_monthly": "c_ss_17_18_monthly",
          "ss_total_8_avg_monthly": "ss_total_8_monthly_avg_jam",
          "ss_total_8_mod_monthly": "ss_total_8_monthly_mod",
          "ss_total_8_med_monthly": "ss_total_8_monthly_med",
          "ss_total_8_max_monthly": "ss_total_8_monthly_max_jam",
          "ss_total_8_min_monthly": "ss_total_8_monthly_min_jam",
          "ss_total_8_sum_monthly": "ss_total_8_monthly_total_jam",
          "ss_total_8_count_monthly": "c_ss_total_8_monthly",
          "ss_total_12_avg_monthly": "ss_total_12_monthly_avg_jam",
          "ss_total_12_mod_monthly": "ss_total_12_monthly_mod",
          "ss_total_12_med_monthly": "ss_total_12_monthly_med",
          "ss_total_12_max_monthly": "ss_total_12_monthly_max_jam",
          "ss_total_12_min_monthly": "ss_total_12_monthly_min_jam",
          "ss_total_12_sum_monthly": "ss_total_12_monthly_total_jam",
          "ss_total_12_count_monthly": "c_ss_total_12_monthly",
        },
      },
    ];

    return listAttr;
  },

  getAttrMonthlyDecimal() {
    const listAttr = [
      {
        label: "Lama Penyinaran",
        attr: {
          "ss_6_7_avg_monthly": 1,
          "ss_6_7_mod_monthly": 1,
          "ss_6_7_med_monthly": 1,
          "ss_6_7_max_monthly": 1,
          "ss_6_7_min_monthly": 1,
          "ss_6_7_sum_monthly": 1,
          "ss_6_7_count_monthly": 1,
          "ss_7_8_avg_monthly": 1,
          "ss_7_8_mod_monthly": 1,
          "ss_7_8_med_monthly": 1,
          "ss_7_8_max_monthly": 1,
          "ss_7_8_min_monthly": 1,
          "ss_7_8_sum_monthly": 1,
          "ss_7_8_count_monthly": 1,
          "ss_8_9_avg_monthly": 1,
          "ss_8_9_mod_monthly": 1,
          "ss_8_9_med_monthly": 1,
          "ss_8_9_max_monthly": 1,
          "ss_8_9_min_monthly": 1,
          "ss_8_9_sum_monthly": 1,
          "ss_8_9_count_monthly": 1,
          "ss_9_10_avg_monthly": 1,
          "ss_9_10_mod_monthly": 1,
          "ss_9_10_med_monthly": 1,
          "ss_9_10_max_monthly": 1,
          "ss_9_10_min_monthly": 1,
          "ss_9_10_sum_monthly": 1,
          "ss_9_10_count_monthly": 1,
          "ss_10_11_avg_monthly": 1,
          "ss_10_11_mod_monthly": 1,
          "ss_10_11_med_monthly": 1,
          "ss_10_11_max_monthly": 1,
          "ss_10_11_min_monthly": 1,
          "ss_10_11_sum_monthly": 1,
          "ss_10_11_count_monthly": 1,
          "ss_11_12_avg_monthly": 1,
          "ss_11_12_mod_monthly": 1,
          "ss_11_12_med_monthly": 1,
          "ss_11_12_max_monthly": 1,
          "ss_11_12_min_monthly": 1,
          "ss_11_12_sum_monthly": 1,
          "ss_11_12_count_monthly": 1,
          "ss_12_13_avg_monthly": 1,
          "ss_12_13_mod_monthly": 1,
          "ss_12_13_med_monthly": 1,
          "ss_12_13_max_monthly": 1,
          "ss_12_13_min_monthly": 11,
          "ss_12_13_sum_monthly": 1,
          "ss_12_13_count_monthly": 1,
          "ss_13_14_avg_monthly": 1,
          "ss_13_14_mod_monthly": 1,
          "ss_13_14_med_monthly": 1,
          "ss_13_14_max_monthly": 1,
          "ss_13_14_min_monthly": 1,
          "ss_13_14_sum_monthly": 1,
          "ss_13_14_count_monthly": 1,
          "ss_14_15_avg_monthly": 1,
          "ss_14_15_mod_monthly": 1,
          "ss_14_15_med_monthly": 1,
          "ss_14_15_max_monthly": 1,
          "ss_14_15_min_monthly": 1,
          "ss_14_15_sum_monthly": 1,
          "ss_14_15_count_monthly": 1,
          "ss_15_16_avg_monthly": 1,
          "ss_15_16_mod_monthly": 1,
          "ss_15_16_med_monthly": 1,
          "ss_15_16_max_monthly": 1,
          "ss_15_16_min_monthly": 1,
          "ss_15_16_sum_monthly": 1,
          "ss_15_16_count_monthly": 1,
          "ss_16_17_avg_monthly": 1,
          "ss_16_17_mod_monthly": 1,
          "ss_16_17_med_monthly": 1,
          "ss_16_17_max_monthly": 1,
          "ss_16_17_min_monthly": 1,
          "ss_16_17_sum_monthly": 1,
          "ss_16_17_count_monthly": 1,
          "ss_17_18_avg_monthly": 1,
          "ss_17_18_mod_monthly": 1,
          "ss_17_18_med_monthly": 1,
          "ss_17_18_max_monthly": 1,
          "ss_17_18_min_monthly": 1,
          "ss_17_18_sum_monthly": 1,
          "ss_17_18_count_monthly": 1,
          "ss_total_8_avg_monthly": 1,
          "ss_total_8_mod_monthly": 1,
          "ss_total_8_med_monthly": 1,
          "ss_total_8_max_monthly": 1,
          "ss_total_8_min_monthly": 1,
          "ss_total_8_sum_monthly": 1,
          "ss_total_8_count_monthly": 1,
          "ss_total_12_avg_monthly": 1,
          "ss_total_12_mod_monthly": 1,
          "ss_total_12_med_monthly": 1,
          "ss_total_12_max_monthly": 1,
          "ss_total_12_min_monthly": 1,
          "ss_total_12_sum_monthly": 1,
          "ss_total_12_count_monthly": 1,
        },
      },
    ];

    return listAttr;
  },

  getAttrDaily() {
        const listAttr = [
            {
                label: "Lama Penyinaran",
                attr: {
                  ss_6_7: "Lamanya penyinaran antara Jam 6-7 DAILY",
                  ss_7_8: "Lamanya penyinaran antara Jam 7-8 DAILY",
                  ss_8_9: "Lamanya penyinaran antara Jam 8-9 DAILY",
                  ss_9_10: "Lamanya penyinaran antara Jam 9-10 DAILY",
                  ss_10_11: "Lamanya penyinaran antara Jam 10-11 DAILY",
                  ss_11_12: "Lamanya penyinaran antara Jam 11-12 DAILY",
                  ss_12_13: "Lamanya penyinaran antara Jam 12-13 DAILY",
                  ss_13_14: "Lamanya penyinaran antara Jam 13-14 DAILY",
                  ss_14_15: "Lamanya penyinaran antara Jam 14-15 DAILY",
                  ss_15_16: "Lamanya penyinaran antara Jam 15-16 DAILY",
                  ss_16_17: "Lamanya penyinaran antara Jam 16-17 DAILY",
                  ss_17_18: "Lamanya penyinaran antara Jam 17-18 DAILY",
                  ss_total_8: "Jumlah lamanya penyinaran matahari 8 sd 16",
                  ss_total_12: "Jumlah lamanya penyinaran matahari 6 sd 18"
                }
            }
        ];
        return listAttr;
    },

    getAttrDailyCsv() {
        const listAttr = [
            {
              label: "Lama Penyinaran Harian Csv",
              attr: {
                ss_6_7: "c_ss_6_7_daily",
                ss_7_8: "c_ss_7_8_daily",
                ss_8_9: "c_ss_8_9_daily",
                ss_9_10: "c_ss_9_10_daily",
                ss_10_11: "c_ss_10_11_daily",
                ss_11_12: "c_ss_11_12_daily",
                ss_12_13: "c_ss_12_13_daily",
                ss_13_14: "c_ss_13_14_daily",
                ss_14_15: "c_ss_14_15_daily",
                ss_15_16: "c_ss_15_16_daily",
                ss_16_17: "c_ss_16_17_daily",
                ss_17_18: "c_ss_17_18_daily",
                ss_total_8: "ss_total_8_daily_jam",
                ss_total_12: "ss_total_12_daily_jam"
              },
            }
        ];
        return listAttr;
    },

    getAttrDailyDecimal() {
        const listAttr = [
            {
              label: "Lama Penyinaran Harian Decimal",
              attr: {
                ss_6_7: 1,
                ss_7_8: 1,
                ss_8_9: 1,
                ss_9_10: 1,
                ss_10_11: 1,
                ss_11_12: 1,
                ss_12_13: 1,
                ss_13_14: 1,
                ss_14_15: 1,
                ss_15_16: 1,
                ss_16_17: 1,
                ss_17_18: 1,
                ss_total_8: 1,
                ss_total_12: 1
              },
            },
        ];
        return listAttr;
    },

};
