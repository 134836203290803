import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
    getAttr() {
        const listAttr = [
            {
                label: "Air Siraman",
                attr: {
                    "s_gundul" : "air siraman tanah gundul",
                    "s_komoditi" : "air siraman tanah komoditi",
                    "s_berumput" : "air siraman tanah rumput",
                    },
            },
            {
                label: "Hujan",
                attr: {
                    "rr_17" : "curah hujan jam 1700 ",
                    },
            },
            {
                label: "Air Perkolasi",
                attr: {
                    "pk_gundul" : "air perkolasi gundul",
                    "pk_komoditi" : "air perkolasi komoditi",
                    "pk_berumput" : "air perkolasi rumput",
                    },
            },
            {
                label: "Penguapan",
                attr: {
                    "eva_gundul" : "Evapotranspirasi gundul",
                    "eva_komoditi" : "Evapotranspirasi komoditi",
                    "eva_berumput" : "Evapotranspirasi rumput",
                    },
            },
        ]
        return listAttr;
    },

    getAttrMonthly() {
        const listAttr = [
            {
                group: "Air",
                form: "Lysimeter",
                label: "Air Siraman",
                attr: {
                    "s_gundul_avg_monthly" : "Air Siraman Tanah Gundul AVG MONTHLY",
                    // "s_gundul_mod_monthly" : "Air Siraman Tanah Gundul MOD MONTHLY",
                    // "s_gundul_med_monthly" : "Air Siraman Tanah Gundul MED MONTHLY",
                    "s_gundul_max_monthly" : "Air Siraman Tanah Gundul MAX MONTHLY",
                    "s_gundul_min_monthly" : "Air Siraman Tanah Gundul MIN MONTHLY",
                    "s_gundul_sum_monthly" : "Air Siraman Tanah Gundul SUM MONTHLY",
                    "s_gundul_count_monthly" : "Air Siraman Tanah Gundul COUNT MONTHLY",
                    "s_komoditi_avg_monthly" : "Air Siraman Tanah Komoditi AVG MONTHLY",
                    // "s_komoditi_mod_monthly" : "Air Siraman Tanah Komoditi MOD MONTHLY",
                    // "s_komoditi_med_monthly" : "Air Siraman Tanah Komoditi MED MONTHLY",
                    "s_komoditi_max_monthly" : "Air Siraman Tanah Komoditi MAX MONTHLY",
                    "s_komoditi_min_monthly" : "Air Siraman Tanah Komoditi MIN MONTHLY",
                    "s_komoditi_sum_monthly" : "Air Siraman Tanah Komoditi SUM MONTHLY",
                    "s_komoditi_count_monthly" : "Air Siraman Tanah Komoditi COUNT MONTHLY",
                    "s_berumput_avg_monthly" : "Air Siraman Tanah Rumput AVG MONTHLY",
                    // "s_berumput_mod_monthly" : "Air Siraman Tanah Rumput MOD MONTHLY",
                    // "s_berumput_med_monthly" : "Air Siraman Tanah Rumput MED  MONTHLY",
                    "s_berumput_max_monthly" : "Air Siraman Tanah Rumput MAX MONTHLY",
                    "s_berumput_min_monthly" : "Air Siraman Tanah Rumput MIN MONTHLY",
                    "s_berumput_sum_monthly" : "Air Siraman Tanah Rumput SUM MONTHLY",
                    "s_berumput_count_monthly" : "Air Siraman Tanah Rumput COUNT MONTHLY",
                },
            },
            {
                group: "Air",
                form: "Lysimeter",
                label: "Air Perkolasi",
                attr: {
                    "pk_gundul_avg_monthly" : "Air Perkolasi Gundul AVG MONTHLY",
                    // "pk_gundul_mod_monthly" : "Air Perkolasi Gundul MOD MONTHLY",
                    // "pk_gundul_med_monthly" : "Air Perkolasi Gundul MED MONTHLY",
                    "pk_gundul_max_monthly" : "Air Perkolasi Gundul MAX MONTHLY",
                    "pk_gundul_min_monthly" : "Air Perkolasi Gundul MIN MONTHLY",
                    "pk_gundul_sum_monthly" : "Air Perkolasi Gundul SUM MONTHLY",
                    "pk_gundul_count_monthly" : "Air Perkolasi Gundul COUNT MONTHLY",
                    "pk_komoditi_avg_monthly" : "Air Perkolasi Komoditi AVG MONTHLY",
                    // "pk_komoditi_mod_monthly" : "Air Perkolasi Komoditi MOD MONTHLY",
                    // "pk_komoditi_med_monthly" : "Air Perkolasi Komoditi MED MONTHLY",
                    "pk_komoditi_max_monthly" : "Air Perkolasi Komoditi MAX MONTHLY",
                    "pk_komoditi_min_monthly" : "Air Perkolasi Komoditi MIN MONTHLY",
                    "pk_komoditi_sum_monthly" : "Air Perkolasi Komoditi SUM MONTHLY",
                    "pk_komoditi_count_monthly" : "Air Perkolasi Komoditi COUNT MONTHLY",
                    "pk_berumput_avg_monthly" : "Air Perkolasi Rumput AVG MONTHLY",
                    // "pk_berumput_mod_monthly" : "Air Perkolasi Rumput MOD MONTHLY",
                    // "pk_berumput_med_monthly" : "Air Perkolasi Rumput MED MONTHLY",
                    "pk_berumput_max_monthly" : "Air Perkolasi Rumput MAX MONTHLY",
                    "pk_berumput_min_monthly" : "Air Perkolasi Rumput MIN MONTHLY",
                    "pk_berumput_sum_monthly" : "Air Perkolasi Rumput SUM MONTHLY",
                    "pk_berumput_count_monthly" : "Air Perkolasi Rumput COUNT MONTHLY",
                },
            },
            {
                group: "Penguapan",
                form: "Lysimeter",
                label: "Penguapan",
                attr: {
                    "eva_gundul_avg_monthly" : "Evapotranspirasi Gundul AVG MONTHLY",
                    // "eva_gundul_mod_monthly" : "Evapotranspirasi Gundul MOD MONTHLY",
                    // "eva_gundul_med_monthly" : "Evapotranspirasi Gundul MED MONTHLY",
                    "eva_gundul_max_monthly" : "Evapotranspirasi Gundul MAX MONTHLY",
                    "eva_gundul_min_monthly" : "Evapotranspirasi Gundul MIN MONTHLY",
                    "eva_gundul_sum_monthly" : "Evapotranspirasi Gundul SUM MONTHLY",
                    "eva_gundul_count_monthly" : "Evapotranspirasi Gundul COUNT MONTHLY",
                    "eva_komoditi_avg_monthly" : "Evapotranspirasi Komoditi AVG MONTHLY",
                    // "eva_komoditi_mod_monthly" : "Evapotranspirasi Komoditi MOD MONTHLY",
                    // "eva_komoditi_med_monthly" : "Evapotranspirasi Komoditi MED MONTHLY",
                    "eva_komoditi_max_monthly" : "Evapotranspirasi Komoditi MAX MONTHLY",
                    "eva_komoditi_min_monthly" : "Evapotranspirasi Komoditi MIN MONTHLY",
                    "eva_komoditi_sum_monthly" : "Evapotranspirasi Komoditi SUM MONTHLY",
                    "eva_komoditi_count_monthly" : "Evapotranspirasi Komoditi COUNT MONTHLY",
                    "eva_berumput_avg_monthly" : "Evapotranspirasi Rumput AVG MONTHLY",
                    // "eva_berumput_mod_monthly" : "Evapotranspirasi Rumput MOD MONTHLY",
                    // "eva_berumput_med_monthly" : "Evapotranspirasi Rumput MED MONTHLY",
                    "eva_berumput_max_monthly" : "Evapotranspirasi Rumput MAX MONTHLY",
                    "eva_berumput_min_monthly" : "Evapotranspirasi Rumput MIN MONTHLY",
                    "eva_berumput_sum_monthly" : "Evapotranspirasi Rumput SUM MONTHLY",
                    "eva_berumput_count_monthly" : "Evapotranspirasi Rumput COUNT MONTHLY",
                },
            },
        ]
        return listAttr;
    },

    getAttrMonthlyCsv() {
        const listAttr = [
            {
                label: "Air Siraman",
                attr: {
                    "s_gundul_avg_monthly" : "siram_gundul_monthly_avg_mm",
                    "s_gundul_mod_monthly" : "siram_gundul_monthly_mod_mm",
                    "s_gundul_med_monthly" : "siram_gundul_monthly_med_mm",
                    "s_gundul_max_monthly" : "siram_gundul_monthly_max_mm",
                    "s_gundul_min_monthly" : "siram_gundul_monthly_min_mm",
                    "s_gundul_sum_monthly" : "siram_gundul_monthly_total_mm",
                    "s_gundul_count_monthly" : "c_siram_gundul_monthly_mm",
                    "s_komoditi_avg_monthly" : "siram_komoditi_monthly_avg_mm",
                    "s_komoditi_mod_monthly" : "siram_komoditi_monthly_mod_mm",
                    "s_komoditi_med_monthly" : "siram_komoditi_monthly_med_mm",
                    "s_komoditi_max_monthly" : "siram_komoditi_monthly_max_mm",
                    "s_komoditi_min_monthly" : "siram_komoditi_monthly_min_mm",
                    "s_komoditi_sum_monthly" : "siram_komoditi_monthly_total_mm",
                    "s_komoditi_count_monthly" : "c_siram_komoditi_monthly_mm",
                    "s_berumput_avg_monthly" : "siram_berumput_monthly_avg_mm",
                    "s_berumput_mod_monthly" : "siram_berumput_monthly_mod_mm",
                    "s_berumput_med_monthly" : "siram_berumput_monthly_med_mm",
                    "s_berumput_max_monthly" : "siram_berumput_monthly_max_mm",
                    "s_berumput_min_monthly" : "siram_berumput_monthly_min_mm",
                    "s_berumput_sum_monthly" : "siram_berumput_monthly_total_mm",
                    "s_berumput_count_monthly" : "c_siram_berumput_monthly_mm",

                    "pk_gundul_avg_monthly" : "perkolasi_gundul_monthly_avg_mm",
                    "pk_gundul_mod_monthly" : "perkolasi_gundul_monthly_mod_mm",
                    "pk_gundul_med_monthly" : "perkolasi_gundul_monthly_med_mm",
                    "pk_gundul_max_monthly" : "perkolasi_gundul_monthly_max_mm",
                    "pk_gundul_min_monthly" : "perkolasi_gundul_monthly_min_mm",
                    "pk_gundul_sum_monthly" : "perkolasi_gundul_monthly_total_mm",
                    "pk_gundul_count_monthly" : "c_perkolasi_gundul_monthly_mm",
                    "pk_komoditi_avg_monthly" : "perkolasi_komoditi_monthly_avg_mm",
                    "pk_komoditi_mod_monthly" : "perkolasi_komoditi_monthly_mod_mm",
                    "pk_komoditi_med_monthly" : "perkolasi_komoditi_monthly_med_mm",
                    "pk_komoditi_max_monthly" : "perkolasi_komoditi_monthly_max_mm",
                    "pk_komoditi_min_monthly" : "perkolasi_komoditi_monthly_min_mm",
                    "pk_komoditi_sum_monthly" : "perkolasi_komoditi_monthly_total_mm",
                    "pk_komoditi_count_monthly" : "c_perkolasi_komoditi_monthly_mm",
                    "pk_berumput_avg_monthly" : "perkolasi_berumput_monthly_avg_mm",
                    "pk_berumput_mod_monthly" : "perkolasi_berumput_monthly_mod_mm",
                    "pk_berumput_med_monthly" : "perkolasi_berumput_monthly_med_mm",
                    "pk_berumput_max_monthly" : "perkolasi_berumput_monthly_max_mm",
                    "pk_berumput_min_monthly" : "perkolasi_berumput_monthly_min_mm",
                    "pk_berumput_sum_monthly" : "perkolasi_berumput_monthly_total_mm",
                    "pk_berumput_count_monthly" : "c_perkolasi_berumput_monthly_mm",

                    "eva_gundul_avg_monthly" : "evapotranspirasi_gundul_monthly_avg_mm",
                    "eva_gundul_mod_monthly" : "evapotranspirasi_gundul_monthly_mod_mm",
                    "eva_gundul_med_monthly" : "evapotranspirasi_gundul_monthly_med_mm",
                    "eva_gundul_max_monthly" : "evapotranspirasi_gundul_monthly_max_mm",
                    "eva_gundul_min_monthly" : "evapotranspirasi_gundul_monthly_min_mm",
                    "eva_gundul_sum_monthly" : "evapotranspirasi_gundul_monthly_total_mm",
                    "eva_gundul_count_monthly" : "c_evapotranspirasi_gundul_monthly_mm",
                    "eva_komoditi_avg_monthly" : "evapotranspirasi_komoditi_monthly_avg_mm",
                    "eva_komoditi_mod_monthly" : "evapotranspirasi_komoditi_monthly_mod_mm",
                    "eva_komoditi_med_monthly" : "evapotranspirasi_komoditi_monthly_med_mm",
                    "eva_komoditi_max_monthly" : "evapotranspirasi_komoditi_monthly_max_mm",
                    "eva_komoditi_min_monthly" : "evapotranspirasi_komoditi_monthly_min_mm",
                    "eva_komoditi_sum_monthly" : "evapotranspirasi_komoditi_monthly_total_mm",
                    "eva_komoditi_count_monthly" : "c_evapotranspirasi_komoditi_monthly_mm",
                    "eva_berumput_avg_monthly" : "evapotranspirasi_berumput_monthly_avg_mm",
                    "eva_berumput_mod_monthly" : "evapotranspirasi_berumput_monthly_mod_mm",
                    "eva_berumput_med_monthly" : "evapotranspirasi_berumput_monthly_med_mm",
                    "eva_berumput_max_monthly" : "evapotranspirasi_berumput_monthly_max_mm",
                    "eva_berumput_min_monthly" : "evapotranspirasi_berumput_monthly_min_mm",
                    "eva_berumput_sum_monthly" : "evapotranspirasi_berumput_monthly_total_mm",
                    "eva_berumput_count_monthly" : "c_evapotranspirasi_berumput_monthly_mm",
                },
            }
        ]
        return listAttr;
    },

    getAttrMonthlyDecimal() {
        const listAttr = [
            {
                label: "Air Siraman",
                attr: {
                    "s_gundul_avg_monthly" : 1,
                    "s_gundul_mod_monthly" : 1,
                    "s_gundul_med_monthly" : 1,
                    "s_gundul_max_monthly" : 1,
                    "s_gundul_min_monthly" : 1,
                    "s_gundul_sum_monthly" : 1,
                    "s_gundul_count_monthly" : 1,
                    "s_komoditi_avg_monthly" : 1,
                    "s_komoditi_mod_monthly" : 1,
                    "s_komoditi_med_monthly" : 1,
                    "s_komoditi_max_monthly" : 1,
                    "s_komoditi_min_monthly" : 1,
                    "s_komoditi_sum_monthly" : 1,
                    "s_komoditi_count_monthly" : 1,
                    "s_berumput_avg_monthly" : 1,
                    "s_berumput_mod_monthly" : 1,
                    "s_berumput_med_monthly" : 1,
                    "s_berumput_max_monthly" : 1,
                    "s_berumput_min_monthly" : 1,
                    "s_berumput_sum_monthly" : 1,
                    "s_berumput_count_monthly" : 1,

                    "pk_gundul_avg_monthly" : 1,
                    "pk_gundul_mod_monthly" : 1,
                    "pk_gundul_med_monthly" : 1,
                    "pk_gundul_max_monthly" : 1,
                    "pk_gundul_min_monthly" : 1,
                    "pk_gundul_sum_monthly" : 1,
                    "pk_gundul_count_monthly" : 1,
                    "pk_komoditi_avg_monthly" : 1,
                    "pk_komoditi_mod_monthly" : 1,
                    "pk_komoditi_med_monthly" : 1,
                    "pk_komoditi_max_monthly" : 1,
                    "pk_komoditi_min_monthly" : 1,
                    "pk_komoditi_sum_monthly" : 1,
                    "pk_komoditi_count_monthly" : 1,
                    "pk_berumput_avg_monthly" : 1,
                    "pk_berumput_mod_monthly" : 1,
                    "pk_berumput_med_monthly" : 1,
                    "pk_berumput_max_monthly" : 1,
                    "pk_berumput_min_monthly" : 1,
                    "pk_berumput_sum_monthly" : 1,
                    "pk_berumput_count_monthly" : 1,

                    "eva_gundul_avg_monthly" : 1,
                    "eva_gundul_mod_monthly" : 1,
                    "eva_gundul_med_monthly" : 1,
                    "eva_gundul_max_monthly" : 1,
                    "eva_gundul_min_monthly" : 1,
                    "eva_gundul_sum_monthly" : 1,
                    "eva_gundul_count_monthly" : 1,
                    "eva_komoditi_avg_monthly" : 1,
                    "eva_komoditi_mod_monthly" : 1,
                    "eva_komoditi_med_monthly" : 1,
                    "eva_komoditi_max_monthly" : 1,
                    "eva_komoditi_min_monthly" : 1,
                    "eva_komoditi_sum_monthly" : 1,
                    "eva_komoditi_count_monthly" : 1,
                    "eva_berumput_avg_monthly" : 1,
                    "eva_berumput_mod_monthly" : 1,
                    "eva_berumput_med_monthly" : 1,
                    "eva_berumput_max_monthly" : 1,
                    "eva_berumput_min_monthly" : 1,
                    "eva_berumput_sum_monthly" : 1,
                    "eva_berumput_count_monthly" : 1,
                },
            }
        ]
        return listAttr;
    },

    getAttrDaily() {
        const listAttr = [
            {
                label: "Air Siraman",
                attr: {
                    // "m_1700ws[rr_gundul]" : "curah hujan di jam 17.00 gundul",
                    // "m_1700ws[rr_komoditi]" : "curah hujan di jam 17.00 komoditi",
                    // "m_1700ws[rr_berumput]" : "curah hujan di jam 17.00 berumput",
                    "m_1700ws[eva_gundul]" : "Evapotranspirasi gundul DAILY",
                    "m_1700ws[eva_komoditi]" : "Evapotranspirasi komoditi DAILY",
                    "m_1700ws[eva_berumput]" : "Evapotranspirasi rumput DAILY",
                    "rr_17_total" : "Curah Hujan di jam 17.00 TOTAL"
                }
            }
        ];
        return listAttr;
    },

    getAttrDailyCsv() {
        const listAttr = [
            {
                label: "Air Siraman",
                attr: {
                    "m_1700ws[rr_gundul]" : "rr_gundul_daily",
                    "m_1700ws[rr_komoditi]" : "rr_komoditi_daily",
                    "m_1700ws[rr_berumput]" : "rr_berumput_daily",
                    "eva_gundul" : "evapotranspirasi_gundul_daily_mm",
                    "eva_komoditi" : "evapotranspirasi_komoditi_daily_mm",
                    "eva_berumput" : "evapotranspirasi_berumput_daily_mm",
                    "rr_17_total": "rr17_daily_mm"
                },
            }
        ];
        return listAttr;
    },

    getAttrDailyDecimal() {
        const listAttr = [
            {
                label: "Air Siraman",
                attr: {
                    "m_1700ws[rr_gundul]" : 1,
                    "m_1700ws[rr_komoditi]" : 1,
                    "m_1700ws[rr_berumput]" : 1,
                    "m_1700ws[eva_gundul]" : 1,
                    "m_1700ws[eva_komoditi]" : 1,
                    "m_1700ws[eva_berumput]" : 1,
                    "rr_17_total": 1
                },
            },
        ];
        return listAttr;
    },
    
}
