import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
        {
            label: "Lain - Lain",
            attr: {
                m_cor : "COR",
                m_nil : "NIL",
                m_auto : "AUTO",
                m_cavok : "Report CAVOK",
                m_air_temperature_tt : "TEMPERATUR ℃",
                m_dew_point_temp_tdtd : "TITIK EMBUN ℃",
                m_qnh_pressure_phphph : "TEKANAN UDARA (QNH)",
                m_sea_surface_temperature_tsts : "TEMP PERMUKAAN LAUT ℃",
                m_sea_state_s : "KEADAAN LAUT",
                m_remark : "REMARK",
                encoded_metar_speci : "SANDI",
                },  
        },

        {
            label: "Angin",
            attr: {
                m_wind_dir_ddd : "Arah Angin (derajat)",
                m_vrb : "VRB",
                m_wind_speed_ff : "Kecepatan Angin (knot)",
                m_wind_gust_fmfm : "Gust (Knot)",
                m_dxdxdx : "Arah angin min",
                m_dndndn : "Arah angin max",
                },  
        },
        {
            label: "Cuaca",
            attr: {
                m_present_weather_ww_1 : "Saat Pengamatan Group 1",
                m_present_weather_ww_2 : "Saat Pengamatan Group 2",
                m_present_weather_ww_3 : "Saat Pengamatan Group 3",
                m_recent_weather_ww_1 : "Yang Lalu Group 1",
                m_recent_weather_ww_2 : "Yang Lalu Group 2",
                m_recent_weather_ww_3 : "Yang Lalu Group 3",
                },  
        },

        {
            label: "Visibility",
            attr: {
                m_prevailing_visibility_vvvv : "Prevailling (m)",
                m_visibiility_min_vnvnvnvn : "Minimum",
                m_visibility_min_direction_dv : "Min Vis Direction",
                m_ndv : "NDV",
                },  
        },
        {
            label: "RVR",
            attr: {
                "m_rvr[designator_drdr]" : "DESIGNATOR",
                "m_rvr[vrvrvrvr]" : "VR VR VR VR",
                "m_rvr[variation_vrvrvrvr_min]" : "VR VIS MIN",
                "m_rvr[variation_vrvrvrvr_max]" : "VR VIS MAX",
                "m_rvr[instrument_limit]" : "INS LIMIT",
                "m_rvr[tendency_i]" : "TENDENCY",
                },  
        },
        {
            label: "Awan",
            attr: {
                "m_cloud_clear" : "CLEAR",
                "m_cloud[amount_nsnsns]" : "JUMLAH",
                "m_cloud[height_hshshs]" : "TINGGI (FEET)",
                "m_cloud[type]" : "TYPE",
                "m_vertical_visibility_vvhshshs" : "VERTICAL VIS",
                },  
        },
        {
            label: "Wind Shear Runaway",
            attr: {
                "m_wind_shear_all_runway" : "WS ALL RUNWAY",
                "m_wind_shear[runway_ind_drdr]" : "WIND SHEAR DESIGNATOR",
                "m_runway[state_ind_drdr]" : "DESIGNATOR",
                "m_runway[depos_state_er]" : "STATE",
                "m_runway[depos_cr]" : "CONTAMINATION",
                "m_runway[depos_depth_erer]" : "DEPTH",
                "m_runway[depos_braking_brbr]" : "BRAKING COEFFICIENT",
                },  
        },
        {
            label: "Trend",
            attr: {
                "m_trend" : "Trend",
                "m_trend_tt_1" : "Indicator 1",
                "m_trend_tt_2" : "Indicator 2",
                "m_trend_gggg_1" : "Time 1",
                "m_trend_gggg_2" : "Time 1",
                "m_trend_wind_dir_dd" : "Arah Angin (derajat)",
                "m_trend_wind_speed_ff" : "Kecepatan Angin (knot)",
                "m_trend_wind_gust_fmfm" : "Gust (Knot)",
                "m_trend_cavok" : "Report CAVOK",
                "m_trend_vvvv" : "Prevailling (m)",
                "m_trend_nsw" : "No Significant Weather (NSW)",
                "m_trend_ww" : "Forecast Weather",
                "m_trend_nsc" : "No Significant Cloud (NSC)",
                "m_trend_cloud[nsnsns]" : "JUMLAH",
                "m_trend_cloud[height_hshshs]" : "TINGGI (FEET)",
                "m_trend_cloud[type]" : "TYPE",
                "m_trend_vv_vvhshshs" : "VERTICAL VIS",
                },  
        },
        
        ]
        return listAttr;
    }
    
}















