import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper"
export default {
  getRawDataKU(type, date_gte, date_lte, station_id, attr,reg_selected,prov_selected,kab_selected){
    var url = Helper.getBaseUrl()+"@export_data?station_id="+station_id+"&data_timestamp__gte="+date_gte+"&data_timestamp__lte="+date_lte+"&_size=1000000&type_name="+type+
    "&_metadata="+attr 
    return axios.get(url, Helper.getConfig());
  },
  getRawData(type, date_gte, date_lte, station_id, attr,reg_selected,prov_selected,kab_selected){
    // var url = Helper.getBaseUrl()+"/@aggregation_multiquery?data_timestamp__gte="+date_gte+"&data_timestamp__lte="+date_lte+
    //     "&_size=1000000&type_name="+type+"&station_id="+station_id+"&_metadata="+attr
    // console.log(type, date_gte, date_lte, station_id, attr, reg_selected,prov_selected,kab_selected)
    var url = "";
    
    // if(prov_selected == null && station_id == null){
    //   url = Helper.getBaseUrl()+"@export_data?region="+reg_selected+
    //         "&data_timestamp__gte="+date_gte+"&data_timestamp__lte="+date_lte+"&_size=1000000&type_name="+type+"&_metadata="+attr
    // }else if(kab_selected == null && station_id == null ){
    //   url = Helper.getBaseUrl()+"@export_data?region="+reg_selected+"&province="+prov_selected+
    //         "&data_timestamp__gte="+date_gte+"&data_timestamp__lte="+date_lte+"&_size=1000000&type_name="+type+"&_metadata="+attr
    // }else if(station_id == null && station_id == null){
    //   url = Helper.getBaseUrl()+"@export_data?region="+reg_selected+"&province="+prov_selected+"&kabupaten="+kab_selected+
    //         "&data_timestamp__gte="+date_gte+"&data_timestamp__lte="+date_lte+"&_size=1000000&type_name="+type+"&_metadata="+attr  
    // }else{
    //   url = Helper.getBaseUrl()+"@export_data?station_id="+station_id+"&data_timestamp__gte="+date_gte+"&data_timestamp__lte="+date_lte+"&_size=1000000&type_name="+type+
    //   "&_sort_asc=station_id&station_id__gte=1&station_id__lte=10000&_metadata="+attr   
    // }
    url = Helper.getBaseUrl()+"@export_data?station_id="+station_id+"&data_timestamp__gte="+date_gte+"&data_timestamp__lte="+date_lte+"&_size=1000000&type_name="+type+
    "&_sort_asc=station_id&station_id__gte=1&station_id__lte=10000&_metadata="+attr 
    return axios.get(url, Helper.getConfig());
  },
  getRawDataFdih(type, date_gte, date_lte, station_id, attr, reg_selected,prov_selected,kab_selected){
    // var url = Helper.getBaseUrl()+"/@aggregation_multiquery?fdih_type="+type+"&data_timestamp__gte="+date_gte+"&data_timestamp__lte="+date_lte+
    //     "&_size=1000000&type_name=Fdih&station_id="+station_id+"&_metadata="+attr


    var url = "";
    // if(prov_selected == null && station_id == null){
    //   url = Helper.getBaseUrl()+"@export_data?type_name=Fdih&region="+reg_selected+
    //         "&data_timestamp__gte="+date_gte+"&data_timestamp__lte="+date_lte+"&_size=1000000&fdih_type="+type+"&_metadata="+attr
    // }else if(kab_selected == null && station_id == null){
    //   url = Helper.getBaseUrl()+"@export_data?type_name=Fdih&region="+reg_selected+"&province="+prov_selected+
    //         "&data_timestamp__gte="+date_gte+"&data_timestamp__lte="+date_lte+"&_size=1000000&fdih_type="+type+"&_metadata="+attr
    // }else if(station_id == null && station_id == null){
    //   url = Helper.getBaseUrl()+"@export_data?type_name=Fdih&region="+reg_selected+"&province="+prov_selected+"&kabupaten="+kab_selected+
    //         "&data_timestamp__gte="+date_gte+"&data_timestamp__lte="+date_lte+"&_size=1000000&fdih_type="+type+"&_metadata="+attr   
    // }else{
    //   url = Helper.getBaseUrl()+"@export_data?type_name=Fdih&station_id="+station_id+"&data_timestamp__gte="+date_gte+"&data_timestamp__lte="+date_lte+"&_size=1000000&fdih_type="+type+
    //   "&_sort_asc=station_id&station_id__gte=1&station_id__lte=10000&_metadata="+attr   
      
    // }

    url = Helper.getBaseUrl()+"@export_data?type_name=Fdih&station_id="+station_id+"&data_timestamp__gte="+date_gte+"&data_timestamp__lte="+date_lte+"&_size=1000000&fdih_type="+type+
      "&_sort_asc=station_id&station_id__gte=1&station_id__lte=10000&_metadata="+attr  
    return axios.get(url, Helper.getConfig());
  },
  getRawDataFormHujanHarian(type, date_gte, date_lte, station_id, attr, reg_selected,prov_selected,kab_selected){
    var url = Helper.getBaseUrl()+"@export_data?station_id="+station_id+"&rr_timestamp__gte="+date_gte+"&rr_timestamp__lte="+date_lte+"&_size=1000000&type_name=FormHujanHarian&_metadata="+attr   
    return axios.get(url, Helper.getConfig());
  },
  getRawDataFormHujan(type, date_gte, date_lte, station_id, attr, reg_selected,prov_selected,kab_selected){
    // var url = Helper.getBaseUrl()+"/@aggregation_multiquery?received_timestamp__gte="+date_gte+"&received_timestamp__lte="+date_lte+
    //     "&_size=1000000&type_name="+type+"&station_id="+station_id+"&_metadata="+attr
    var url = "";
    // if(prov_selected == null && station_id == null){
    //   url = Helper.getBaseUrl()+"@export_data?region="+reg_selected+
    //         "&received_timestamp__gte="+date_gte+"&received_timestamp__lte="+date_lte+"&_size=1000000&type_name="+type+"&_metadata="+attr
    // }else if(kab_selected == null && station_id == null){
    //   url = Helper.getBaseUrl()+"@export_data?region="+reg_selected+"&province="+prov_selected+
    //         "&received_timestamp__gte="+date_gte+"&received_timestamp__lte="+date_lte+"&_size=1000000&type_name="+type+"&_metadata="+attr
    // }else if(station_id == null && station_id == null){
    //   url = Helper.getBaseUrl()+"@export_data?region="+reg_selected+"&province="+prov_selected+"&kabupaten="+kab_selected+
    //         "&received_timestamp__gte="+date_gte+"&received_timestamp__lte="+date_lte+"&_size=1000000&type_name="+type+"&_metadata="+attr   
    
    // }else{
    //   url = Helper.getBaseUrl()+"@export_data?station_id="+station_id+"&received_timestamp__gte="+date_gte+"&received_timestamp__lte="+date_lte+"&_size=1000000&type_name="+type+
    //   "&_sort_asc=station_id&station_id__gte=1&station_id__lte=12000&_metadata="+attr   
    // }
    url = Helper.getBaseUrl()+"@export_data?station_id="+station_id+"&received_timestamp__gte="+date_gte+"&received_timestamp__lte="+date_lte+"&_size=1000000&type_name="+type+
      "&_sort_asc=station_id&station_id__gte=1&station_id__lte=12000&_metadata="+attr   
    return axios.get(url, Helper.getConfig());
  },
  getRawDataSinoptik(type, date_gte, date_lte, station_id, attr, reg_selected,prov_selected,kab_selected){
    // var url = Helper.getBaseUrl()+"/@aggregation_multiquery?data_timestamp__gte="+date_gte+"&data_timestamp__lte="+date_lte+
    //     "&_size=1000000&type_name="+type+"&station_id="+station_id+"&_metadata="+attr

    var url = "";
    // if(prov_selected == null && station_id == null){
    //   url = Helper.getBaseUrl()+"@export_data?region="+reg_selected+
    //         "&data_timestamp__gte="+date_gte+"&data_timestamp__lte="+date_lte+"&_size=1000000&type_name="+type+"&_metadata="+attr
    // }else if(kab_selected == null && station_id == null){
    //   url = Helper.getBaseUrl()+"@export_data?region="+reg_selected+"&province="+prov_selected+
    //         "&data_timestamp__gte="+date_gte+"&data_timestamp__lte="+date_lte+"&_size=1000000&type_name="+type+"&_metadata="+attr
    // }else if(station_id == null && station_id == null){
    //   url = Helper.getBaseUrl()+"@export_data?region="+reg_selected+"&province="+prov_selected+"&kabupaten="+kab_selected+
    //         "&data_timestamp__gte="+date_gte+"&data_timestamp__lte="+date_lte+"&_size=1000000&type_name="+type+"&_metadata="+attr   
    // }else{
    //   // url = Helper.getBaseUrl()+"@export_data?station_id="+station_id+"&data_timestamp__gte="+date_gte+"&data_timestamp__lte="+date_lte+"&_size=1000000&type_name="+type+"&_metadata="+attr   
    //   url = Helper.getBaseUrl()+"@export_data?station_id="+station_id+"&data_timestamp__gte="+date_gte+"&data_timestamp__lte="+date_lte+"&_size=1000000&type_name="+type+
    //   "&_sort_asc=station_id&station_id__gte=1&station_id__lte=10000&_metadata="+attr   
    
    // }
    url = Helper.getBaseUrl()+"@export_data?station_id="+station_id+"&data_timestamp__gte="+date_gte+"&data_timestamp__lte="+date_lte+"&_size=1000000&type_name="+type+
      "&_sort_asc=station_id&station_id__gte=1&station_id__lte=10000&_metadata="+attr   
    return axios.get(url, Helper.getConfig());
  },
};
