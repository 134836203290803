<template>
  <div>
    <b-card>
      <b-row>
        <b-col sm="12">
          <b-row>
            <!-- <b-col sm="2 ">
              <label>*Kategori</label>
              <v-select v-model="t_mkg" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="t_mkg_opt" />
            </b-col> -->
            <b-col sm="2 ">
              <label>Form</label>
              <v-select v-model="t_form" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="t_form_opt"  />
              <!-- <b-form-select v-model="t_kabupaten" :options="kabupaten_opt" size="sm" /> -->
            </b-col>
            <b-col sm="2 ">
              <label>*Temporal Data</label>
              <v-select v-model="t_temporal_data" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="t_temp_data_opt"  >
                <!-- <template #option="{ value, text, disabled }" class="{disabled: true}">
                  <span class="{ disabled: true }"> {{ text }}</span>
                </template> -->
              </v-select>
            </b-col>
            <b-col sm="4">
              <label>*Group Parameter</label>
              <v-select v-model="group" class="select-size-md" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" multiple label="text" :close-on-select="false" :options="group_opt" />
            </b-col>
            <!-- <b-col sm="2">
              <label>*Temporal Data</label>
              <v-select v-model="temporal" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="temporal_opt" class="select-size-sm" />
            </b-col> -->
            <b-col sm="2">
              <label>*Balai</label>
              <v-select v-model="regions" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="regions_opt"  />
              <!-- <b-form-select v-model="regions" :options="regions_opt" size="sm" /> -->
            </b-col>
            <b-col sm="2 ">
              <label>Provinsi</label>
              <v-select v-model="propinsi" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="propinsi_opt"  />
            </b-col>
          </b-row>
          
          <b-row class="mt-1">
            
            <b-col sm="2 ">
              <label>Kabupaten</label>
              <v-select v-model="kabupaten" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="kabupaten_opt"  />

              <!-- <b-form-select v-model="kabupaten" :options="kabupaten" size="sm" /> -->
            </b-col>
            <b-col sm="4 ">
              <label>Stasiun</label>
              <!-- <b-form-select v-model="stasiun" :options="stasiun_opt" size="sm" /> -->
              <v-select v-model="stasiun" class="select-size-md" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" multiple label="text" :close-on-select="false" :options="stasiun_opt" />
            </b-col>
            <!-- <b-col sm="2 ">
              <label>*Bulan Awal</label>
              <flat-pickr  v-model="start_date" style="height: 39px" class="form-control" :config="dpconfig" />
            </b-col> -->
            <b-col sm="6">
              <b-row>
                
                <b-col v-if="!(t_temporal_data && t_temporal_data.value == 'harian')" cols="5">
                  <b-form-group>
                    <label>*Bulan Awal</label>
                    <flat-pickr id="start_date" v-model="start_date" style="height: 39px" class="form-control" :config="{dateFormat: 'm/Y'}" />
                  </b-form-group>
                </b-col>
                <b-col v-if="!(t_temporal_data && t_temporal_data.value == 'harian')" cols="5">
                  <b-form-group style="display:none"> <!-- ini untuk pengalihan bug pada flat-pickr -- jancok-->
                    <label>*Bulan Akhir</label>
                    <flat-pickr id="end_date_bak" v-model="end_date_bak" style="height: 39px" class="form-control" :config="{dateFormat: 'm/Y'}" > </flat-pickr>
                  </b-form-group>
                  <b-form-group>
                    <label>*Bulan Akhir</label>
                    <flat-pickr id="end_date" v-model="end_date" style="height: 39px" class="form-control" :config="dpconfig" > </flat-pickr>
                  </b-form-group>
                </b-col>
                <b-col v-if="t_temporal_data && t_temporal_data.value == 'harian'" cols="10">
                  <b-form-group>
                    <label>*Tanggal Awal - Akhir</label>
                    <flat-pickr id="range_date" v-model="range_date" style="height: 39px" class="form-control" :config="{mode: 'range', dateFormat: 'd-m-Y'}" > </flat-pickr>
                  </b-form-group>
                </b-col>
                <b-col cols="2" style="margin-top:3px">
                  <!-- <b-button class=" mt-2" variant="gradient-primary" v-on:click="previewGroup"  >
                    <feather-icon icon="EyeIcon" class=" align-middle" v-b-tooltip.hover.v-dark title="Preview"
                    />
                  </b-button> -->
                  <b-button  variant="relief-primary" v-on:click="previewGroup" class="mt-2">
                    <feather-icon icon="EyeIcon" class=" align-middle" v-b-tooltip.hover.v-dark title="Preview"/>
                  </b-button>
                </b-col>
                
              </b-row>
              
            </b-col>
            <!-- <b-col sm="1" class=" mt-2 w-100">
              <b-button variant="gradient-primary" v-on:click="previewGroup" style="margin-top:4px">
                <feather-icon
                  icon="EyeIcon"
                  class=""
                  v-b-tooltip.hover.v-dark
                  title="Preview"
                />
              </b-button>
            </b-col> -->
          </b-row>
          <!-- == TABLE== -->
          <b-card no-body class="border mt-1">
            <!-- <b-card-header class="p-1 bg-light-primary">
              <b-card-title class="font-medium-1">
                <feather-icon icon="FileIcon" size="16" />
                <span class="align-middle ml-50">Report View</span>
              </b-card-title>
            </b-card-header> -->

            <b-row class="p-1">
              <b-col :cols="col_card" v-for="obj in cardCheckbox" :key="obj.label">
                <b-card style="border: 2px solid #333333" id="showcard">
                  <span class="align-middle ml-10 text-dark font-weight-bolder mb-1">{{ obj.label }}</span>
                  <b-row class="mt-1">
                    <b-col>
                      <b-form-checkbox :value="obj" v-model="checkall"> Checked </b-form-checkbox>
                    </b-col>
                    <b-col>
                      <b-form-checkbox :value="obj" v-model="uncheckall"> Unchecked </b-form-checkbox>
                    </b-col>
                  </b-row>

                  <b-form-group label=" " class="font-weight-bolder text-dark">
                    <b-form-checkbox v-for="item in obj.attr" :key="item.value" v-model="selectedCheck" :value="item.value" name="flavour-3a">
                      {{ item.text }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-card>
              </b-col>

              <b-col cols="12" class="float-right">
                <b-button v-if="sumbitShow" variant="relief-primary" class="float-right" v-on:click="showTable"> Submit </b-button>
              </b-col>
             
              
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-card>

    <b-card v-if="cardTable">
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Sort Date</label>
            <b-form-select id="sortDate" v-model="sortDate" size="sm" :options="sortDateOpt" class="w-50" />
          </b-form-group>
        </b-col>

        <!-- <b-col md="2" class="my-1">
          <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
            <b-input-group size="sm">
              <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col> -->

        
        <b-col md="3" class="my-1" v-if="ready_export">
          <download-excel class="btn btn-sm btn-success ml-1" :data="itemsCsv" type="csv" :name="csvfilename" :escapeCsv="escapeCsv" :header="form_name_exp_xls" :title="form_name_exp_xls"> Download CSV </download-excel>
        </b-col>

        <b-col cols="12">
          <b-table
            class="table b-table my-table table-striped table-hover table-sm table-responsive text-nowrap text-center"
            striped
            hover
            responsive
            :bordered="true"
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm" class="my-0" />
        </b-col>
      </b-row>
    </b-card>
    <div id="divExport" class="divExport">
      <table>
        <tr>
          <td v-for="item in items" :key="item"></td>
        </tr>
      </table>
    </div>
    
    <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
  </div>
</template>

<script>
//import LiquorTree from "liquor-tree";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import 'flatpickr/dist/flatpickr.css';
import confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate';
 import 'flatpickr/dist/plugins/confirmDate/confirmDate.css';
import {
  BOverlay,
  BTable,
  BAvatar,
  BBadge,
  BPagination,
  BInputGroupAppend,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormInput,
  BInputGroup,
  BFormSelect,
  BButton,
  BButtonGroup,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormGroup,
  BFormTextarea,
  BPopover,
  BModal,
  VBModal,
  BForm,
  AlertPlugin,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import metadata from "@/api/metadata";
import VueHtml2pdf from "vue-html2pdf";

import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import helper from "@/api/helper";

import outputexport from "@/api/outputdataderived";
import lamapenyinaran from "@/attr/derivedata/pias/lamapenyinaran";
import aktinograph from "@/attr/derivedata/pias/aktinograph";
import barograph from "@/attr/derivedata/pias/barograph";
import fenologi from "@/attr/derivedata/pias/fenologi";
import intentitashujan from "@/attr/derivedata/pias/intentitashujan";
import kelembabantanah from "@/attr/derivedata/pias/kelembabantanah";
import perawanan from "@/attr/derivedata/pias/perawanan";
import thermohigrograph from "@/attr/derivedata/pias/thermohigrograph";
import formhujan from "@/attr/derivedata/pias/formhujan";

import fklim from "@/attr/derivedata/fdih/fklim";
import agm1a from "@/attr/derivedata/fdih/agm1a";
import agm1b from "@/attr/derivedata/fdih/agm1b";
import gunbellani from "@/attr/derivedata/fdih/gunbellani";
import iklimmikro from "@/attr/derivedata/fdih/iklimmikro";
import lysimeter from "@/attr/derivedata/fdih/lysimeter";
import pichepenguapan from "@/attr/derivedata/fdih/pichepenguapan";
import psycrometerassman from "@/attr/derivedata/fdih/psycrometerassman";
import suhutanah from "@/attr/derivedata/fdih/suhutanah";
import oppenguapan from "@/attr/derivedata/fdih/oppenguapan";
import allklimat from "@/attr/derivedata/fdih/allklimat";

import kah from "@/attr/derivedata/ku/kah";
import spm from "@/attr/derivedata/ku/spm";
import so2no2 from "@/attr/derivedata/ku/so2no2";
import aerosol from "@/attr/derivedata/ku/aerosol";


import me45 from "@/attr/meteo/me45";
import me48 from "@/attr/meteo/me48";
import sinoptik from "@/attr/derivedata/meteo/sinop";

import metar from "@/attr/derivedata/meteo/metar";
import speci from "@/attr/derivedata/meteo/speci";
import metarspecy from "@/attr/derivedata/meteo/metarspecy";

import pibal from "@/attr/derivedata/meteo/pibal";
import JsPDFAutotable from "jspdf-autotable";
import jsPDF from "jspdf";
import moment from "moment";

// const dpconfig = {
      
//         plugins: [
//             new monthSelectPlugin({
//               shorthand: true,
//               dateFormat: "m/Y",
//             })
//           ]
//       }

// const timedpconfig = {
//         plugins: [
//             new rangePlugin({
//               shorthand: true,
//               allowInput: true,
//               dateFormat: "m/d/Y",
//             })
//           ]
//       }
export default {
  components: {
    AlertPlugin,
    BOverlay,
    VueHtml2pdf,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    vSelect,
    jsPDF,
    JsPDFAutotable,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormSelect,
    BButton,
    BButtonGroup,
    flatPickr,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormDatepicker,
    BTable,
    BAvatar,
    BBadge,
    BPagination,
    BInputGroupAppend,
    BPopover,
    BFormTextarea,
    BModal,
    BForm,
    VBTooltip
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {

      dpconfig : {
        // plugins: [
        //     new monthSelectPlugin({
        //       shorthand: true,
        //       dateFormat: "m/Y",
        //     })
        //   ],
        dateFormat: 'm/Y'
      },

      timedpconfig : {
        plugins: [],
        mode: 'range',
        dateFormat: 'd-m-Y'
      },
      list_allias_parameters: null,
      range_date: "",
      escapeCsv: false,
      form_name_exp_pdf: "",
      form_name_exp_xls: "",
      start_end_date : "",
      start_date : "",
      end_date : "",
      end_date_bak : "",
      perPage: 20,
      pageOptions: [5, 10, 20, 50],
      sortDate: "Asc",
      sortDateOpt: ["Asc", "Desc"],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      fields: [],
      items: [],
      itemsCsv: [],

      t_form: "",
      t_form_opt: [
        // { value: "Agm1a", text: "Agm1a" },
        // { value: "Agm1b", text: "Agm1b" },
        { value: "Aktinograph", text: "Aktinograph" },
        { value: "Barograph", text: "Barograph" },
        { value: "Fklim", text: "Fklim 71" },
        { value: "FormHujan", text: "Form Hujan" },
        { value: "FormPerawanan", text: "Form Perawanan" },
        { value: "Gunbellani", text: "Gunbellani" },
        { value: "IklimMikro", text: "Iklim Mikro" },
        { value: "HujanHellman", text: "Intensitas Hujan(Hellman)" },
        { value: "KelembabanTanah", text: "Kelembaban Tanah" },
        { value: "LamaPenyinaran", text: "Lama Penyinaran" },
        { value: "Lysimeter", text: "Lysimeter" },
        { value: "OpPenguapan", text: "OP Penguapan" },
        { value: "PichePenguapan", text: "Piche Penguapan" },
        { value: "PsycrometerAssman", text: "Psychrometer Assman" },
        { value: "SuhuTanah", text: "Suhu Tanah" },
        { value: "Thermohigograph", text: "Thermohigrograph" }, 
      ],
      t_mkg: "",
      t_mkg_opt: [
        { value: "meteo", text: "Meteorologi" },
        { value: "klimat", text: "Klimatologi" },
        { value: "geofis", text: "Geofisika" }
      ],
      t_temporal_data:"",
      t_temp_data_opt: [
        { value: "jam", text: "Jam" },
        { value: "harian", text: "Harian" },
        { value: "dasarian", text: "Dasarian" },
        { value: "bulanan", text: "Bulanan" },
      ],

      t_from_m: [
        { value: "MetarSpeci", text: "MetarSpeci" },
        { value: "Pibal", text: "Pibal" },
        { value: "sinoptik", text: "Sinop" },
      ],
      t_from_g: [
        { value: "Magnet", text: "Magnet" },
      ],
      t_from_k: [
        // { value: "Agm1a", text: "Agm1a" },
        // { value: "Agm1b", text: "Agm1b" },
        { value: "Aerosol", text: "Aerosol" },
        { value: "Aktinograph", text: "Aktinograph" },
        { value: "Barograph", text: "Barograph" },
        // { value: "Fenologi", text: "Fenologi" },
        { value: "Fklim", text: "Fklim 71" },
        { value: "FormHujan", text: "Form Hujan" },
        { value: "FormPerawanan", text: "Form Perawanan" },
        { value: "Gunbellani", text: "Gunbellani" },
        { value: "IklimMikro", text: "Iklim Mikro" },
        { value: "HujanHellman", text: "Intensitas Hujan(Hellman)" },
        { value: "Kah", text: "Kimia Air Hujan" },
        { value: "KelembabanTanah", text: "Kelembaban Tanah" },
        { value: "LamaPenyinaran", text: "Lama Penyinaran" },
        { value: "Lysimeter", text: "Lysimeter" },
        { value: "OpPenguapan", text: "OP Penguapan" },
        { value: "PichePenguapan", text: "Piche Penguapan" },
        { value: "PsycrometerAssman", text: "Psychrometer Assman" },
        { value: "So2no2", text: "SO2&NO2" },
        { value: "SuhuTanah", text: "Suhu Tanah" },
        { value: "Spm", text: "Suspended Particulate Matter" },
        { value: "Thermohigograph", text: "Thermohigrograph" },
      ],
      group:null,
      group_opt:[],
      cardTable: true,
      checkall: [],
      uncheckall: [],
      cardCheckbox: [],
      arr_group: [],
      selectedCheck: [],
      sumbitShow: false,
      type_object: '',
      temporal:null,
      temporal_opt:[
        { value: "jam", text: "Jam" },
        { value: "harian", text: "Harian" },
        { value: "dasarian", text: "Dasarian" },
        { value: "bulanan", text: "Bulanan" },
      ],
      regions: "",
      regions_opt: [],
      propinsi: "",
      propinsi_opt: [],
      kabupaten: "",
      kabupaten_opt: [],
      stasiun: "",
      stasiun_opt: [],

      is_bmkg_entry:null,
      is_balai_selected:null,
      is_prov_selected:null,
      is_kab_selected:null,
      g_all: {},
      g_all_daily: {},
      g_all_monthly: {},
      join_g_all: [],
      rangeDate: null,
      dir: "ltr",
      showLoading:false,
      ready_export:false,


    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
   this.listBalai(); 
   this.callGroupForm();
   this.totalRows = this.items.length;
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    two_digits_decimal(val) {
      // console.log("value decimal : ",val);
      if (val == null || val == "") {
        return "";
      } else {
        return parseFloat(val).toFixed(2);
      }
    },

    async showTable(){
      // this.showLoading=true;
      // setTimeout((arr)=>{
      //   this.showLoading=false;
      //   this.$swal({
      //       title: "ERROR",
      //       text: "Api Belum Terintegrasi ",
      //       icon: "error",
      //       customClass: {
      //         confirmButton: "btn btn-danger",
      //       },
      //       buttonsStyling: false,
      //     });
      // }, 2000);
      this.sortDate = "Asc";
      // console.log("stasiun : ",this.stasiun_opt)
      // return
      var stasiun_opt_temp = this.stasiun_opt;
      var stasiun_tmp = [];
      stasiun_opt_temp.forEach((o) => {
        if (o.value !== "") stasiun_tmp.push(o.value);
      });
      if (this.regions == null || this.propinsi == null || this.kabupaten == null) {
        this.$swal({
          title: "ERROR",
          text: "Silahkan Lengkapi Filter",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        return;
      }
      this.pibaldataform = false;
      this.stasiun_arr = [];
      this.STORE_EXPORT_PIAS = [];
      // console.log("array : ",this.selectedCheck)
      // console.log("region : ",this.regions.value);
      // console.log("propinsi : ",this.propinsi);
      // console.log("kabupaten : ",this.kabupaten);
      let reg_selected = this.regions.value;
      let prov_selected = this.propinsi.value;
      if (prov_selected == "") {
        prov_selected = null;
      }
      let kab_selected = this.kabupaten.value;
      if (kab_selected == "") {
        kab_selected = null;
      }
      // if (this.rangeDate == null || reg_selected == "") {
      if (this.start_date == null || this.end_date == null || reg_selected == "") {
        this.$swal({
          title: "ERROR",
          text: "Silahkan Lengkapi Filter",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        return;
      }
      if (this.selectedCheck == "" || this.selectedCheck == null) {
        this.$swal({
          title: "ERROR",
          text: "Silahkan Pilih(ceklis) Parameter yang diinginkan",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        return;
      }

      // console.log('start_date', this.start_date, this.end_date)
      // var s_date = this.start_date.split('/')
      // var e_date = this.end_date.split('/')
      // // let s_tgl = new Date(s_date[1], s_date[0], 0)
      // let e_tgl = new Date(e_date[1], e_date[0], 0)
      // var date_gte = s_date[1]+"-"+s_date[0]+"-01T00:00"
      // var date_lte = e_date[1]+"-"+e_date[0]+"-"+e_tgl.getDate()+"T23:59"
      // console.log('date_gte', date_gte, date_lte, e_tgl )

      // console.log('start_date 1.', this.start_date +',2.'+ this.end_date +',3.'+ this.range_date)
      var s_date = null
      var e_date = null 
      let e_tgl = null
      var date_gte = null
      var date_lte = null
      var twoDate = false;

      if (this.t_temporal_data && this.t_temporal_data.value == "harian") {
        let arr_rangedate = this.range_date.split("to");
        // console.log("arr_rangedate", arr_rangedate)
        twoDate = arr_rangedate.length > 1 ? true : false

        s_date = arr_rangedate[0].trim().split('-')
        e_date = arr_rangedate.length > 1 ? arr_rangedate[1].trim().split('-') : arr_rangedate[0].trim().split('-')

        e_tgl = new Date(e_date[2], e_date[1], 0)
        date_gte = s_date[2]+"-"+s_date[1]+"-"+s_date[0]+"T00:00"
        date_lte = e_date[2]+"-"+e_date[1]+"-"+e_date[0]+"T23:59"
      }
      else {
        s_date = this.start_date.split('/')
        e_date = this.end_date.split('/')

        e_tgl = new Date(e_date[1], e_date[0], 0)
        date_gte = s_date[1]+"-"+s_date[0]+"-01T00:00"
        date_lte = e_date[1]+"-"+e_date[0]+"-"+e_tgl.getDate()+"T23:59"
      }
      // let s_tgl = new Date(s_date[1], s_date[0], 0)
      // e_tgl = new Date(e_date[1], e_date[0], 0)
      // date_gte = s_date[1]+"-"+s_date[0]+"-01T00:00"
      // date_lte = e_date[1]+"-"+e_date[0]+"-"+e_tgl.getDate()+"T23:59"
      // console.log('date_gte', date_gte, date_lte, e_tgl )

      // var rangeDate_arr = this.rangeDate.split("to");
      // // console.log("date", rangeDate_arr[1]);
      // var date_gte = "";
      // var date_lte = "";
      // var twoDate = false;
      // if (rangeDate_arr[1] == undefined) {
      //   // this.$swal({
      //   //   title: "ERROR",
      //   //   text: "Silahkan Isi Range Date dengan benar",
      //   //   icon: "error",
      //   //   customClass: {
      //   //     confirmButton: "btn btn-danger",
      //   //   },
      //   //   buttonsStyling: false,
      //   // });
      //   // return;

      //   date_gte = rangeDate_arr[0].trim() + "T00:00";
      //   date_lte = rangeDate_arr[0].trim() + "T23:59";
      // } else {
      //   twoDate = true;
      //   date_gte = rangeDate_arr[0].trim() + "T00:00";
      //   date_lte = rangeDate_arr[1].trim();
      // }
      this.showLoading = true;
      // this.csvfilename = this.type_object + " " + this.rangeDate + ".csv";
      // let rdate = s_date[1]+"-"+s_date[0]+"-01 to "+e_date[1]+"-"+e_date[0]+"-"+e_tgl.getDate()
      // this.csvfilename = this.type_object + " " + rdate + ".csv";
      console.log('this.type_object1', this.type_object)
      let rdate = s_date[0]+""+s_date[1]+"-"+e_date[0]+""+e_date[1]
      let str_temporal = (this.t_temporal_data && this.t_temporal_data.value == "harian") ? "_harian_" : "_bulanan_"
      this.csvfilename = "derived_"+(this.type_object ? (this.type_object).toLowerCase() : this.type_object) + str_temporal + rdate + ".csv";
      this.rawpibalname = "Raw Pibal" + this.rangeDate + ".csv";
      this.datapibalname = "Raw Pibal" + this.rangeDate + ".csv";
      console.log('this.type_object2', this.type_object)
      for (var i in this.stasiun) {
        if (this.stasiun[i].value !== "") {
          this.stasiun_arr.push(this.stasiun[i].value);
        }
      }
      var _statiun = this.stasiun_arr.toString();
      // console.log(_statiun);
      if (_statiun == "") {
        var temp_s = stasiun_tmp.toString();
        if (temp_s == "") {
          _statiun = null;
        } else {
          _statiun = temp_s;
        }
      }

      var _metadata_arr = [];
      _metadata_arr = this.selectedCheck;
      //  console.log("met arr : ",_metadata_arr);
        // console.log("selectedCheck : ",this.selectedCheck);
      var _metadata = "";
      _metadata = "station_id,station_name,data_timestamp,alias_station_id,source_data," + _metadata_arr.toString();
      // _metadata = "station_id,station_name,data_timestamp,current_latitude,current_longitude," + _metadata_arr.toString();

      var _headTable = _metadata.split(",");
      // console.log(_metadata);
      // console.log(_headTable);
      // this.fields = _headTable
      this.ready_export = true;
      this.items = [];
      var currentFrom = this.type_object;
      // if (this.type_object == "me45" || this.type_object == "me48" || this.type_object == "sinoptik") {
      //   currentFrom = "Sinoptik";
      // } else if (this.type_object == "metar" || this.type_object == "speci" || this.type_object == "MetarSpeci") {
      //   currentFrom = "MetarSpeci";
      // }
      // console.log('t_temporal_data', this.t_temporal_data)
      if (this.t_temporal_data && this.t_temporal_data.value == "harian") {
        // var _metadata = ""
        if ( currentFrom == "Fklim" || currentFrom == "OpPenguapan") {
          _metadata = "station_id,station_name,data_timestamp,alias_station_id,source_data," + _metadata_arr.toString();
        }
        else if ( currentFrom == "Lysimeter" ) {
          // console.log('_metadata_arr', _metadata_arr)
          _metadata = "station_id,station_name,data_timestamp,alias_station_id,source_data,m_1700ws[rr_gundul],m_1700ws[rr_komoditi],m_1700ws[rr_berumput]," + _metadata_arr.toString();
        }
        else if ( currentFrom == "PichePenguapan" ) {
          _metadata = "station_id,station_name,data_timestamp,alias_station_id,source_data,m_0730ws[eva_piche_0730],m_1330ws[eva_piche_1330],m_1730ws[eva_piche_1730]," + _metadata_arr.toString();
        }

        if (currentFrom == "FormHujan") {
          var metahujanharian = "station_id,alias_station_id,station_name,current_latitude,current_longitude,rr_timestamp," + _metadata_arr.toString() + ",qc_flag,qc_histories";
          var arr_metahujanharian = metahujanharian.split(",");
          try {
            const { data } = await outputexport.getRawDataFormHujanHarian(currentFrom, date_gte, date_lte, _statiun, metahujanharian, reg_selected, prov_selected, kab_selected);
            var tempData = [];
            var nonya = 1;
            for (var i in data) {
              var row = data[i];
              var obj = {};
              if (arr_metahujanharian.indexOf("rr_phk_mm") == -1 && arr_metahujanharian.indexOf("rr_das_mm") != -1) {

                var TGL = row.rr_timestamp.substr(8, 2)
                if (TGL == "01" || TGL == "11" || TGL == "21") {

                } else {
                  continue;
                }
              }
              obj["station_id"] = row.alias_station_id;
              obj["station_name"] = row.station_name;
              obj["latitude"] = row.current_latitude;
              obj["longitude"] = row.current_longitude;
              obj["data_timestamp"] = row.rr_timestamp.substr(0, 10);
              if (arr_metahujanharian.indexOf("rr_phk_mm") != -1) {
                obj["rr_phk_daily_mm"] = row.rr_phk_mm
              }
              if (arr_metahujanharian.indexOf("rr_das_mm") != -1) {
                obj["rr_das_mm"] = row.rr_das_mm
              }
              if (arr_metahujanharian.indexOf("observer_id") != -1) {
                obj["observer_id"] = row.observer_id
              }
              if (arr_metahujanharian.indexOf("observer_name") != -1) {
                obj["observer_name"] = row.observer_name
              }
              // var data_qc = row.qc_flag;
              // obj["qc_flag"] = data_qc;
              // obj["qc_parameter"] = "";
              // if (data_qc == 1) {
              //   var qc_histories = row.qc_histories;
              //   // console.log("qc his : ",qc_histories);
              //   if (qc_histories !== null) {
              //     let JsonQc = JSON.parse(qc_histories);
              //     let Qc_His = JsonQc.after;
              //     var Log_QC_His = "";
              //     for (var i_qc in Qc_His) {
              //       Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
              //     }
              //     let len = Log_QC_His.length;
              //     let log = Log_QC_His.substring(0, len - 1);
              //     obj["qc_parameter"] = log;
              //   }

              //   // console.log("MASUK_QC: ",tgl[field]);
              // }
              // console.log("obj : ",obj)
              tempData.push(obj)
              // console.log("Keluar Idx: ",tgl[field]);
            }
            console.log("tempData : ", tempData)

            this.items = tempData;
            this.STORE_EXPORT_PIAS = tempData;
            this.itemsCsv = tempData;
            this.totalRows = this.items.length;
            this.showLoading = false;
            // console.log("this.items :",this.items);
            // console.log(" this.totalRows : ", this.totalRows);
            
          } catch (e) {
            // console.log(e);
            this.showLoading = false;
          }
        }
        else if (
          currentFrom == "Fklim" ||
          currentFrom == "Agm1a" ||
          currentFrom == "Agm1b" ||
          currentFrom == "Gunbellani" ||
          currentFrom == "IklimMikro" ||
          currentFrom == "Lysimeter" ||
          currentFrom == "PsycrometerAssman" ||
          currentFrom == "SuhuTanah" ||
          currentFrom == "OpPenguapan" || 
          currentFrom == "PichePenguapan"
        ) {
          try {
            
            const { data } = await outputexport.getRawDataFdih(currentFrom, date_gte, date_lte, _statiun, _metadata, reg_selected, prov_selected, kab_selected);
            var tempData = [];
            let params = this.callGroupFormCsv(this.type_object)[0]['attr']

            for (var obj in data) {
              var itemObj = {};

              // itemObj["station_id"] = data[obj].station_id;
              itemObj["station_id"] = data[obj].alias_station_id;
              itemObj["station_name"] = data[obj].station_name;

              itemObj["current_latitude"] = data[obj].current_latitude;
              itemObj["current_longitude"] = data[obj].current_longitude;
              itemObj["data_timestamp"] = data[obj].data_timestamp.substr(0, 10);
              // itemObj["id"] = data[obj].id;
              // console.log("index of  rh_avg_4 = ",_metadata_arr.indexOf("rh_avg_4"))
              //iklim mikro
              if (_metadata_arr.indexOf("observer_id") != "-1") {
                itemObj["observer_id"] = data[obj].observer_id;
              }
              if (_metadata_arr.indexOf("observer_name") != "-1") {
                itemObj["observer_name"] = data[obj].observer_name;
              }
              if (_metadata_arr.indexOf("rh_avg_4") != "-1") {
                itemObj["rh_avg_4"] = this.two_digits_decimal(data[obj].rh_avg_4);
              }
              if (_metadata_arr.indexOf("rh_avg_7") != "-1") {
                itemObj["rh_avg_7"] = this.two_digits_decimal(data[obj].rh_avg_7);
              }
              if (_metadata_arr.indexOf("rh_avg_10") != "-1") {
                itemObj["rh_avg_10"] = this.two_digits_decimal(data[obj].rh_avg_10);
              }
              if (_metadata_arr.indexOf("tbk_avg_4") != "-1") {
                itemObj["tbk_avg_4"] = this.two_digits_decimal(data[obj].tbk_avg_4);
              }
              if (_metadata_arr.indexOf("tbk_avg_7") != "-1") {
                itemObj["tbk_avg_7"] = this.two_digits_decimal(data[obj].tbk_avg_7);
              }
              if (_metadata_arr.indexOf("tbk_avg_10") != "-1") {
                itemObj["tbk_avg_10"] = this.two_digits_decimal(data[obj].tbk_avg_10);
              }

              if (_metadata_arr.indexOf("ws_avg_4_0713") != "-1") {
                itemObj["ws_avg_4_0713"] = this.two_digits_decimal(data[obj].ws_avg_4_0713);
              }
              if (_metadata_arr.indexOf("ws_avg_4_1318") != "-1") {
                itemObj["ws_avg_4_1318"] = this.two_digits_decimal(data[obj].ws_avg_4_1318);
              }
              if (_metadata_arr.indexOf("ws_avg_4_1807") != "-1") {
                itemObj["ws_avg_4_1807"] = this.two_digits_decimal(data[obj].ws_avg_4_1807);
              }

              if (_metadata_arr.indexOf("ws_avg_7_0713") != "-1") {
                itemObj["ws_avg_7_0713"] = this.two_digits_decimal(data[obj].ws_avg_7_0713);
              }
              if (_metadata_arr.indexOf("ws_avg_7_1318") != "-1") {
                itemObj["ws_avg_7_1318"] = this.two_digits_decimal(data[obj].ws_avg_7_1318);
              }
              if (_metadata_arr.indexOf("ws_avg_7_1807") != "-1") {
                itemObj["ws_avg_7_1807"] = this.two_digits_decimal(data[obj].ws_avg_7_1807);
              }

              if (_metadata_arr.indexOf("ws_avg_10_0713") != "-1") {
                itemObj["ws_avg_10_0713"] = this.two_digits_decimal(data[obj].ws_avg_10_0713);
              }
              if (_metadata_arr.indexOf("ws_avg_10_1318") != "-1") {
                itemObj["ws_avg_10_1318"] = this.two_digits_decimal(data[obj].ws_avg_10_1318);
              }
              if (_metadata_arr.indexOf("ws_avg_10_1807") != "-1") {
                itemObj["ws_avg_10_1807"] = this.two_digits_decimal(data[obj].ws_avg_10_1807);
              }

              // Fklim71
              if (_metadata_arr.indexOf("tbk_avg") != "-1") {
                itemObj["tbk_avg"] = this.two_digits_decimal(data[obj].tbk_avg);
              }
              if (_metadata_arr.indexOf("rh_avg") != "-1") {
                itemObj["rh_avg"] = this.two_digits_decimal(data[obj].rh_avg);
              }
              if (_metadata_arr.indexOf("ff_avg_km_jm") != "-1") {
                itemObj["ff_avg_km_jm"] = this.two_digits_decimal(data[obj].ff_avg_km_jm);
              }
              if (_metadata_arr.indexOf("tbk_avg_10") != "-1") {
                itemObj["tbk_avg_10"] = this.two_digits_decimal(data[obj].tbk_avg_10);
              }
              if (_metadata_arr.indexOf("wd_cardinal") != "-1") {
                itemObj["wd_cardinal"] = data[obj].wd_cardinal;
              }
              if (_metadata_arr.indexOf("ff_max") != "-1") {
                itemObj["ff_max"] = data[obj].ff_max;
              }
              if (_metadata_arr.indexOf("wd_ff_max") != "-1") {
                itemObj["wd_ff_max"] = data[obj].wd_ff_max;
              }

              //AGM1b
              if (_metadata_arr.indexOf("ws_avg_1b_0c5_1") != "-1") {
                itemObj["ws_avg_1b_0c5_1"] = this.two_digits_decimal(data[obj].ws_avg_1b_0c5_1);
                // itemObj["ws_avg_1b_0c5_0730"] = this.two_digits_decimal(data[obj].ws_avg_1b_0c5_1);
              }
              if (_metadata_arr.indexOf("ws_avg_1b_0c5_2") != "-1") {
                itemObj["ws_avg_1b_0c5_2"] = this.two_digits_decimal(data[obj].ws_avg_1b_0c5_2);
                // itemObj["ws_avg_1b_0c5_1330"] = this.two_digits_decimal(data[obj].ws_avg_1b_0c5_2);
              }
              if (_metadata_arr.indexOf("ws_avg_1b_0c5_3") != "-1") {
                itemObj["ws_avg_1b_0c5_3"] = this.two_digits_decimal(data[obj].ws_avg_1b_0c5_3);
                // itemObj["ws_avg_1b_0c5_1730"] = this.two_digits_decimal(data[obj].ws_avg_1b_0c5_3);
              }

              if (_metadata_arr.indexOf("sum_ch_1b") != "-1") {
                itemObj["sum_ch_1b"] = this.two_digits_decimal(data[obj].sum_ch_1b);
              }
              if (_metadata_arr.indexOf("sum_eva_1b") != "-1") {
                itemObj["sum_eva_1b"] = this.two_digits_decimal(data[obj].sum_eva_1b);
              }
              if (_metadata_arr.indexOf("ket_1b") != "-1") {
                itemObj["ket_1b"] = data[obj].ket_1b;
              }

              // Lysimeter
              if (_metadata_arr.indexOf("rr_17_total") != "-1") {
                console.log('obj', obj, data)
                let rr_17 = this.checkIntValue(data[obj].m_1700ws.rr_gundul) + this.checkIntValue(data[obj].m_1700ws.rr_komoditi) + this.checkIntValue(data[obj].m_1700ws.rr_berumput)
                itemObj["rr_17_total"] = this.two_digits_decimal(rr_17);
              }

              // Piche Penguapan
              if (_metadata_arr.indexOf("eva_piche_total") != "-1") {
                console.log('obj', obj, data)
                let rr_17 = this.checkIntValue(data[obj].m_0730ws.eva_piche_0730) + this.checkIntValue(data[obj].m_1330ws.eva_piche_1330) + this.checkIntValue(data[obj].m_1730ws.eva_piche_1730)
                itemObj["eva_piche_total"] = this.two_digits_decimal(rr_17);
              }


              var items_0700ws = data[obj].m_0700ws;
              if (items_0700ws != undefined || items_0700ws != null) {
                for (var i_meta in _metadata_arr) {
                  var tmp_field = _metadata_arr[i_meta].split("[");
                  if (tmp_field.length > 1) {
                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                    // console.log("fieldnya : ",fieldnya)
                    var ws = tmp_field[0];
                    if (ws == "m_0700ws") {
                      var f_data = items_0700ws[fieldnya];
                      itemObj[fieldnya] = "";
                      if (f_data != undefined) {
                        itemObj[fieldnya] = f_data == "null" ? "" : f_data;
                      }
                    }
                  }
                }
              } else if (items_0700ws == null) {
                for (var i_meta in _metadata_arr) {
                  var tmp_field = _metadata_arr[i_meta].split("[");
                  if (tmp_field.length > 1) {
                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                    var ws = tmp_field[0];
                    if (ws == "m_0700ws") {
                      itemObj[fieldnya] = "";
                    }
                  }
                }
              }
              var items_0730ws = data[obj].m_0730ws;
              if (items_0730ws != undefined || items_0730ws != null) {
                for (var i_meta in _metadata_arr) {
                  var tmp_field = _metadata_arr[i_meta].split("[");
                  if (tmp_field.length > 1) {
                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                    var ws = tmp_field[0];
                    if (ws == "m_0730ws") {
                      var f_data = items_0730ws[fieldnya];
                      itemObj[fieldnya] = "";
                      if (f_data != undefined) {
                        itemObj[fieldnya] = f_data == "null" ? "" : f_data;
                      }
                    }
                  }
                }
              } else if (items_0730ws == null) {
                for (var i_meta in _metadata_arr) {
                  var tmp_field = _metadata_arr[i_meta].split("[");
                  if (tmp_field.length > 1) {
                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                    var ws = tmp_field[0];
                    if (ws == "m_0730ws") {
                      itemObj[fieldnya] = "";
                    }
                  }
                }
              }
              var items_1300ws = data[obj].m_1300ws;
              if (items_1300ws != undefined || items_1300ws != null) {
                for (var i_meta in _metadata_arr) {
                  var tmp_field = _metadata_arr[i_meta].split("[");
                  if (tmp_field.length > 1) {
                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                    var ws = tmp_field[0];
                    if (ws == "m_1300ws") {
                      var f_data = items_1300ws[fieldnya];
                      itemObj[fieldnya] = "";
                      if (f_data != undefined) {
                        itemObj[fieldnya] = f_data == "null" ? "" : f_data;
                      }
                    }
                  }
                }
              } else if (items_1300ws == null) {
                for (var i_meta in _metadata_arr) {
                  var tmp_field = _metadata_arr[i_meta].split("[");
                  if (tmp_field.length > 1) {
                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                    var ws = tmp_field[0];
                    if (ws == "m_1300ws") {
                      itemObj[fieldnya] = "";
                    }
                  }
                }
              }
              var items_1330ws = data[obj].m_1330ws;
              if (items_1330ws != undefined || items_1330ws != null) {
                for (var i_meta in _metadata_arr) {
                  var tmp_field = _metadata_arr[i_meta].split("[");
                  if (tmp_field.length > 1) {
                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                    var ws = tmp_field[0];
                    if (ws == "m_1330ws") {
                      var f_data = items_1330ws[fieldnya];
                      itemObj[fieldnya] = "";
                      if (f_data != undefined) {
                        itemObj[fieldnya] = f_data == "null" ? "" : f_data;
                      }
                    }
                  }
                }
              } else if (items_1330ws == null) {
                for (var i_meta in _metadata_arr) {
                  var tmp_field = _metadata_arr[i_meta].split("[");
                  if (tmp_field.length > 1) {
                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                    var ws = tmp_field[0];
                    if (ws == "m_1330ws") {
                      itemObj[fieldnya] = "";
                    }
                  }
                }
              }
              var items_1400ws = data[obj].m_1400ws;
              if (items_1400ws != undefined || items_1400ws != null) {
                for (var i_meta in _metadata_arr) {
                  var tmp_field = _metadata_arr[i_meta].split("[");
                  if (tmp_field.length > 1) {
                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                    var ws = tmp_field[0];
                    if (ws == "m_1400ws") {
                      var f_data = items_1400ws[fieldnya];
                      itemObj[fieldnya] = "";
                      if (f_data != undefined) {
                        itemObj[fieldnya] = f_data == "null" ? "" : f_data;
                      }
                    }
                  }
                }
              } else if (items_1400ws == null) {
                for (var i_meta in _metadata_arr) {
                  var tmp_field = _metadata_arr[i_meta].split("[");
                  if (tmp_field.length > 1) {
                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                    var ws = tmp_field[0];
                    if (ws == "m_1400ws") {
                      itemObj[fieldnya] = "";
                    }
                  }
                }
              }
              var items_1700ws = data[obj].m_1700ws;
              if (items_1700ws != undefined || items_1700ws != null) {
                for (var i_meta in _metadata_arr) {
                  var tmp_field = _metadata_arr[i_meta].split("[");
                  if (tmp_field.length > 1) {
                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                    var ws = tmp_field[0];
                    if (ws == "m_1700ws") {
                      var f_data = items_1700ws[fieldnya];
                      itemObj[fieldnya] = "";
                      if (f_data != undefined) {
                        itemObj[fieldnya] = f_data == "null" ? "" : f_data;
                      }
                    }
                  }
                }
              } else if (items_1700ws == null) {
                for (var i_meta in _metadata_arr) {
                  var tmp_field = _metadata_arr[i_meta].split("[");
                  if (tmp_field.length > 1) {
                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                    var ws = tmp_field[0];
                    if (ws == "m_1700ws") {
                      itemObj[fieldnya] = "";
                    }
                  }
                }
              }
              var items_1730ws = data[obj].m_1730ws;
              if (items_1730ws != undefined || items_1730ws != null) {
                for (var i_meta in _metadata_arr) {
                  var tmp_field = _metadata_arr[i_meta].split("[");
                  if (tmp_field.length > 1) {
                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                    var ws = tmp_field[0];
                    if (ws == "m_1730ws") {
                      var f_data = items_1730ws[fieldnya];
                      itemObj[fieldnya] = "";
                      if (f_data != undefined) {
                        itemObj[fieldnya] = f_data == "null" ? "" : f_data;
                      }
                    }
                  }
                }
              } else if (items_1730ws == null) {
                for (var i_meta in _metadata_arr) {
                  var tmp_field = _metadata_arr[i_meta].split("[");
                  if (tmp_field.length > 1) {
                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                    var ws = tmp_field[0];
                    if (ws == "m_1730ws") {
                      itemObj[fieldnya] = "";
                    }
                  }
                }
              }
              var items_1800ws = data[obj].m_1800ws;
              if (items_1800ws != undefined || items_1800ws != null) {
                for (var i_meta in _metadata_arr) {
                  var tmp_field = _metadata_arr[i_meta].split("[");
                  if (tmp_field.length > 1) {
                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                    var ws = tmp_field[0];
                    if (ws == "m_1800ws") {
                      var f_data = items_1800ws[fieldnya];
                      itemObj[fieldnya] = "";
                      if (f_data != undefined) {
                        itemObj[fieldnya] = f_data == "null" ? "" : f_data;
                      }
                    }
                  }
                }
              } else if (items_1800ws == null) {
                for (var i_meta in _metadata_arr) {
                  var tmp_field = _metadata_arr[i_meta].split("[");
                  if (tmp_field.length > 1) {
                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                    var ws = tmp_field[0];
                    if (ws == "m_1800ws") {
                      itemObj[fieldnya] = "";
                    }
                  }
                }
              }

              if (currentFrom == "OpPenguapan") {
                // _metadata = _metadata + ",qc_flag_op_penguapan,qc_histories_op_penguapan";
                var flag = data[obj].qc_flag_op_penguapan;
                var histories = data[obj].qc_histories_op_penguapan;
                itemObj["qc_flag"] = flag;
                itemObj["qc_parameter"] = "";
                if (flag == 1 || flag == 2) {
                  let JsonQc = JSON.parse(histories);
                  let Qc_His = JsonQc.after;
                  var Log_QC_His = "";
                  for (var i_qc in Qc_His) {
                    Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                  }
                  let len = Log_QC_His.length;
                  let log = Log_QC_His;
                  if (len > 0) {
                    log = Log_QC_His.substring(0, len - 1);
                  }

                  itemObj["qc_parameter"] = log;
                  // itemObj["qc_parameter"] = histories
                }
              }
              if (currentFrom == "PichePenguapan") {
                // _metadata = _metadata + ",qc_flag_piche_penguapan,qc_histories_piche_penguapan";
                var flag = data[obj].qc_flag_piche_penguapan;
                var histories = data[obj].qc_histories_piche_penguapan;
                itemObj["qc_flag"] = flag;
                if (flag == 1 || flag == 2) {
                  let JsonQc = JSON.parse(histories);
                  let Qc_His = JsonQc.after;
                  var Log_QC_His = "";
                  for (var i_qc in Qc_His) {
                    Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                  }
                  let len = Log_QC_His.length;
                  let log = Log_QC_His;
                  if (len > 0) {
                    log = Log_QC_His.substring(0, len - 1);
                  }

                  itemObj["qc_parameter"] = log;
                  // itemObj["qc_parameter"] = histories
                }
              }
              if (currentFrom == "Fklim") {
                var flag = data[obj].qc_flag_fklim;
                var histories = data[obj].qc_histories_fklim;
                itemObj["qc_flag"] = flag;
                itemObj["qc_parameter"] = "";
                if (flag == 1 || flag == 2) {
                  let JsonQc = JSON.parse(histories);
                  let Qc_His = JsonQc.after;
                  var Log_QC_His = "";
                  for (var i_qc in Qc_His) {
                    Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                  }
                  let len = Log_QC_His.length;
                  let log = Log_QC_His;
                  if (len > 0) {
                    log = Log_QC_His.substring(0, len - 1);
                  }

                  itemObj["qc_parameter"] = log;
                  // itemObj["qc_parameter"] = histories
                }
              }
              if (currentFrom == "Agm1a") {
                var flag = data[obj].qc_flag_agm1a;
                var histories = data[obj].qc_histories_agm1a;
                itemObj["qc_flag"] = flag;
                itemObj["qc_parameter"] = "";
                if (flag == 1 || flag == 2) {
                  let JsonQc = JSON.parse(histories);
                  let Qc_His = JsonQc.after;
                  var Log_QC_His = "";
                  for (var i_qc in Qc_His) {
                    Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                  }
                  let len = Log_QC_His.length;
                  let log = Log_QC_His;
                  if (len > 0) {
                    log = Log_QC_His.substring(0, len - 1);
                  }

                  itemObj["qc_parameter"] = log;
                  // itemObj["qc_parameter"] = histories
                }
                console.log("Qc terpanggill");
              }
              if (currentFrom == "Agm1b") {
                var flag = data[obj].qc_flag_agm1b;
                var histories = data[obj].qc_histories_agm1b;
                itemObj["qc_flag"] = flag;
                itemObj["qc_parameter"] = "";
                if (flag == 1 || flag == 2) {
                  let JsonQc = JSON.parse(histories);
                  let Qc_His = JsonQc.after;
                  var Log_QC_His = "";
                  for (var i_qc in Qc_His) {
                    Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                  }
                  let len = Log_QC_His.length;
                  let log = Log_QC_His;
                  if (len > 0) {
                    log = Log_QC_His.substring(0, len - 1);
                  }

                  itemObj["qc_parameter"] = log;
                  // itemObj["qc_parameter"] = histories
                }
              }
              if (currentFrom == "SuhuTanah") {
                var flag = data[obj].qc_flag_suhu_tanah;
                var histories = data[obj].qc_histories_suhu_tanah;
                itemObj["qc_flag"] = flag;
                itemObj["qc_parameter"] = "";
                if (flag == 1 || flag == 2) {
                  let JsonQc = JSON.parse(histories);
                  let Qc_His = JsonQc.after;
                  var Log_QC_His = "";
                  for (var i_qc in Qc_His) {
                    Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                  }
                  let len = Log_QC_His.length;
                  let log = Log_QC_His;
                  if (len > 0) {
                    log = Log_QC_His.substring(0, len - 1);
                  }

                  itemObj["qc_parameter"] = log;
                  // itemObj["qc_parameter"] = histories
                }
              }
              if (currentFrom == "PsycrometerAssman") {
                var flag = data[obj].qc_flag_psychrometer_assman;
                var histories = data[obj].qc_histories_psychrometer_assman;
                itemObj["qc_flag"] = flag;
                itemObj["qc_parameter"] = "";
                if (flag == 1 || flag == 2) {
                  let JsonQc = JSON.parse(histories);
                  let Qc_His = JsonQc.after;
                  var Log_QC_His = "";
                  for (var i_qc in Qc_His) {
                    Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                  }
                  let len = Log_QC_His.length;
                  let log = Log_QC_His;
                  if (len > 0) {
                    log = Log_QC_His.substring(0, len - 1);
                  }

                  itemObj["qc_parameter"] = log;
                  // itemObj["qc_parameter"] = histories
                }
              }
              if (currentFrom == "Gunbellani") {
                var flag = data[obj].qc_flag_gunbellani;
                var histories = data[obj].qc_histories_gunbellani;
                itemObj["qc_flag"] = flag;
                itemObj["qc_parameter"] = "";
                if (flag == 1 || flag == 2) {
                  let JsonQc = JSON.parse(histories);
                  let Qc_His = JsonQc.after;  
                  var Log_QC_His = "";
                  for (var i_qc in Qc_His) {
                    Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                  }
                  let len = Log_QC_His.length;
                  let log = Log_QC_His;
                  if (len > 0) {
                    log = Log_QC_His.substring(0, len - 1);
                  }

                  itemObj["qc_parameter"] = log;
                  // itemObj["qc_parameter"] = histories
                }
              }
              if (currentFrom == "IklimMikro") {
                var flag = data[obj].qc_flag_iklim_mikro;
                var histories = data[obj].qc_histories_iklim_mikro;
                itemObj["qc_flag"] = flag;
                itemObj["qc_parameter"] = "";
                if (flag == 1 || flag == 2) {
                  let JsonQc = JSON.parse(histories);
                  let Qc_His = JsonQc.after;
                  var Log_QC_His = "";
                  for (var i_qc in Qc_His) {
                    Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                  }
                  let len = Log_QC_His.length;
                  let log = Log_QC_His;
                  if (len > 0) {
                    log = Log_QC_His.substring(0, len - 1);
                  }

                  itemObj["qc_parameter"] = log;
                  // itemObj["qc_parameter"] = histories
                }
              }
              // console.log("itemObj : ",itemObj)
              tempData.push(itemObj);
            }

            tempData.sort(function (a, b) {
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return new Date(a.data_timestamp) - new Date(b.data_timestamp);
            });

          var x_result = [];
          // itemObj["data_timestamp"] = data[obj].data_timestamp.substr(0,10);
          //   itemObj["station_name"] = data[obj].station_name;
          //   itemObj["current_latitude"] = data[obj].current_latitude;
          //   itemObj["current_longitude"] = data[obj].current_longitude;
          // console.log("temp data : ",tempData, _metadata_arr,itemObj)
          for (var y in tempData) {
            var x_data = [
              {
                attr: "station_id",
                index: 0,
                value: tempData[y].station_id,
              },
              {
                attr: "station_name",
                index: 1,
                value: tempData[y].station_name,
              },
              {
                attr: "current_latitude",
                index: 2,
                value: tempData[y].current_latitude,
              },
              {
                attr: "current_longitude",
                index: 3,
                value: tempData[y].current_longitude,
              },
              {
                attr: "data_timestamp",
                index: 4,
                value: tempData[y].data_timestamp,
              },
            ];
            var x_index = 5;
            for (var x in _metadata_arr) {
              if (_metadata_arr[x] == "observer_id" || _metadata_arr[x] == "observer_name") {
                continue;
              }
              var tmp_field = _metadata_arr[x].split("[");

              var x_field = _metadata_arr[x];
              var x_local = {};
              if (tmp_field.length > 1) {
                var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                x_field = fieldnya;
              }
              var attr = x_field;
              // if (attr == "ws_avg_1b_0c5_1") {
              //   attr = "ws_avg_1b_0c5_0730";
              // }
              // if (attr == "ws_avg_1b_0c5_2") {
              //   attr = "ws_avg_1b_0c5_1330";
              // }
              // if (attr == "ws_avg_1b_0c5_3") {
              //   attr = "ws_avg_1b_0c5_1730";
              // }
              // console.log("param asli : ",x_field)
              // if (this.list_allias_parameters[x_field] != undefined) {
              //   var allias = this.list_allias_parameters[x_field].alias_parameter;
              //   // attr = allias.replace(".", "");
              //   attr = allias;
              //   // console.log("parameter = "+x_field+" , allias = "+allias);
              // }
              x_local = {
                attr: attr,
                index: x_index,
                value: tempData[y][x_field],
              };
              // console.log("tempData[y] : ",tempData[y])
              // console.log("x_field : ",x_field)
              // console.log("row : ",x_local)
              x_data.push(x_local);
              // x_data[x_field] = tempData[y][x_field];
              x_index++;
            }
            // if (tempData[y].observer_id != undefined) {
            //   var obs_id = {
            //     attr: "observer_id",
            //     index: x_index,
            //     value: tempData[y].observer_id,
            //   }
            //   x_data.push(obs_id)
            //   x_index++;
            // }
            // if (tempData[y].observer_name != undefined) {
            //   var obs_name = {
            //     attr: "observer_name",
            //     index: x_index,
            //     value: tempData[y].observer_name,
            //   }
            //   x_data.push(obs_name)
            //   x_index++;
            // }

            // if (
            //   currentFrom == "Fklim" ||
            //   currentFrom == "PichePenguapan" ||
            //   currentFrom == "OpPenguapan" ||
            //   currentFrom == "Agm1a" ||
            //   currentFrom == "Agm1b" ||
            //   currentFrom == "SuhuTanah" ||
            //   currentFrom == "PsycrometerAssman" ||
            //   currentFrom == "Gunbellani" ||
            //   currentFrom == "IklimMikro"
            // ) {
            //   var qc_flag = {
            //     attr: "qc_flag",
            //     index: x_index + 1,
            //     value: tempData[y].qc_flag,
            //   };
            //   x_data.push(qc_flag);
            //   var qc_histories = {
            //     attr: "qc_parameter",
            //     index: x_index + 1,
            //     value: tempData[y].qc_parameter,
            //   };
            //   x_data.push(qc_histories);
            // }
            x_result.push(x_data);
          }
          // console.log("x_result : ",x_result)
          var m_result = [];
          for (var m in x_result) {
            var m_row = x_result[m];
            var m_obj = {};
            for (var f in m_row) {

              var f_data = m_row[f];
              // console.log("data F :",f);
              // console.log("data F :",f_data, params);
              // m_obj[f_data.attr] = f_data.value;
              if (params && params.hasOwnProperty(f_data.attr)) {
                m_obj[params[f_data.attr]] = f_data.value;
              }
              else {
                m_obj[f_data.attr] = f_data.value;
              }
              
            }
            m_result.push(m_obj);
          }
          // console.log("result : ", m_result)
          this.items = m_result;
          // this.items = tempData;
          this.itemsCsv = m_result;
          this.STORE_EXPORT_PIAS = m_result;
          this.totalRows = this.items.length;
          this.showLoading = false;
          }
          catch (e) {
            console.log(e);
            this.showLoading = false;
          }
        }
        else if (currentFrom == "FormPerawanan") {
          try {
            var date_lte_awan = date_lte;
            if (twoDate) {
              date_lte_awan = date_lte + "T23:59";
            }
            const { data } = await outputexport.getRawData(currentFrom, date_gte, date_lte_awan, _statiun, _metadata, reg_selected, prov_selected, kab_selected);
            console.log("PIAS");
            let params = this.callGroupFormCsv(this.type_object)[0]['attr']
            var dataTemp = [];
            var dataTempCsv = []
            for (var items in data) {
              var dataRow = {};
              var dataRowCsv = {}
              // console.log("items : ",items);
              //  console.log("data[items] : ",data[items]);
              for (var item in data[items]) {
                // console.log("item : ", item);
                // console.log("data[items].item : ", data[items][item]);
                if (item == "alias_station_id") {
                  continue;
                } else if (item == "source_data") {
                  continue;
                } else if (item == "station_id") {
                  dataRow["station_id"] = data[items]["alias_station_id"];
                } else if (item == "data_timestamp") {
                  var data_timestamp = data[items][item];
                  dataRow["data_timestamp"] = data_timestamp.substr(0, 10);
                  // } else if (item == "obsawan_total_24") {
                  //   dataRow["total_pengamatan_24"] = data[items][item];
                } else {
                  // console.log("parameter : ", item);
                  // if (this.list_allias_parameters[item] != undefined) {
                  //   var allias = this.list_allias_parameters[item].alias_parameter;
                  //   // console.log("parameter : "+item+" allias : "+allias);
                  //   dataRow[allias] = data[items][item];
                  // } else {
                    // dataRow[item] = data[items][item];
                    if (params && params.hasOwnProperty(item)) {
                      dataRow[params[item]] = data[items][item];
                      dataRowCsv[params[item]] = data[items][item];
                    }
                    else {
                      dataRow[item] = data[items][item];
                      dataRowCsv[item] = data[items][item];
                    }
                  // }
                  // dataRow[item] = data[items][item];
                }
              }
              dataTemp.push(dataRow);
              dataTempCsv.push(dataRowCsv)
            }
            // console.log("dataTemp : ", dataTemp);
            this.STORE_EXPORT_PIAS = dataTemp;
            this.items = dataTemp;
            this.itemsCsv = dataTempCsv;
            this.totalRows = this.items.length;
            this.showLoading = false;
          } catch (e) {
            console.log(e);
            this.showLoading = false;
          }
        }
        else if ( currentFrom == "LamaPenyinaran" || currentFrom == "Thermohigograph" || currentFrom == "Barograph" || currentFrom == "HujanHellman" || 
                  currentFrom == "Aktinograph" ) {
          try {
            // if (currentFrom == "Thermohigograph" || currentFrom == "Barograph" || currentFrom == "HujanHellman") {
            //   _metadata = _metadata + ",qc_flag,qc_histories";
            // }
            if ( currentFrom == "HujanHellman" ) {
              _metadata = "station_id,station_name,data_timestamp,alias_station_id,source_data,hellman_01_02,hellman_02_03,hellman_03_04,hellman_04_05,hellman_05_06,hellman_06_07,hellman_07_08,hellman_08_09,hellman_09_10,hellman_10_11,hellman_11_12,hellman_12_13,hellman_13_14,hellman_14_15,hellman_15_16,hellman_16_17,hellman_17_18,hellman_18_19,hellman_19_20,hellman_20_21,hellman_21_22,hellman_22_23,hellman_23_24,hellman_24_01";
            }
            const { data } = await outputexport.getRawData(currentFrom, date_gte, date_lte, _statiun, _metadata, reg_selected, prov_selected, kab_selected);
            console.log("PIAS");
            let params = this.callGroupFormCsv(this.type_object)[0]['attr']
            var dataTemp = [];
            var dataTempCsv = []
            for (var items in data) {
              var dataRow = {};
              var dataRowCsv = {}
              // console.log("items : ",items);
              //  console.log("data[items] : ",data[items]);
              for (var item in data[items]) {
                console.log("item : ", item);
                // console.log("data[items].item : ", data[items][item]);
                if (item == "alias_station_id") {
                  continue;
                } else if (item == "source_data") {
                  continue;
                } else if (item == "station_id") {
                  dataRow["station_id"] = data[items]["alias_station_id"];
                  dataRowCsv["station_id"] = data[items]["alias_station_id"];
                } else if (item == "data_timestamp") {
                  var data_timestamp = data[items][item];
                  dataRow["data_timestamp"] = data_timestamp.substr(0, 10);
                  dataRowCsv["data_timestamp"] = data_timestamp.substr(0, 10);
                } else if (item == "qc_histories") {
                  if (data[items].qc_flag == 1 || data[items].qc_flag == 2) {
                    let JsonQc = JSON.parse(data[items][item]);
                    let Qc_His = JsonQc.after;
                    var Log_QC_His = "";
                    for (var i_qc in Qc_His) {
                      Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                    }
                    let len = Log_QC_His.length;
                    let log = Log_QC_His;
                    if (len > 0) {
                      log = Log_QC_His.substring(0, len - 1);
                    }

                    dataRow["qc_parameter"] = log;
                  } else {
                    dataRow["qc_parameter"] = "";
                  }
                } else {

                  // if (this.list_allias_parameters[item] != undefined) {
                  //   var allias = this.list_allias_parameters[item].alias_parameter;
                  //   // console.log("parameter : "+item+" allias : "+allias);
                  //   dataRow[allias] = data[items][item];
                  // } else {
                    if ( currentFrom == "HujanHellman" ) {
                      if(item == "hellman_24_01") {
                        let hellman_total_24 = this.checkIntValue(data[items].hellman_01_02) + this.checkIntValue(data[items].hellman_02_03) + this.checkIntValue(data[items].hellman_03_04) + 
                                                this.checkIntValue(data[items].hellman_04_05) + this.checkIntValue(data[items].hellman_05_06) + this.checkIntValue(data[items].hellman_06_07) +
                                                this.checkIntValue(data[items].hellman_07_08) + this.checkIntValue(data[items].hellman_08_09) + this.checkIntValue(data[items].hellman_09_10) + 
                                                this.checkIntValue(data[items].hellman_10_11) + this.checkIntValue(data[items].hellman_11_12) + this.checkIntValue(data[items].hellman_12_13) +
                                                this.checkIntValue(data[items].hellman_13_14) + this.checkIntValue(data[items].hellman_14_15) + this.checkIntValue(data[items].hellman_15_16) + 
                                                this.checkIntValue(data[items].hellman_16_17) + this.checkIntValue(data[items].hellman_17_18) + this.checkIntValue(data[items].hellman_18_19) +
                                                this.checkIntValue(data[items].hellman_19_20) + this.checkIntValue(data[items].hellman_20_21) + this.checkIntValue(data[items].hellman_21_22) + 
                                                this.checkIntValue(data[items].hellman_22_23) + this.checkIntValue(data[items].hellman_23_24) + this.checkIntValue(data[items].hellman_24_01) 
                        dataRow['hellman_daily_total_mm'] = hellman_total_24//data[items][item];
                        // if (params && params.hasOwnProperty(item)) {
                        //   dataRowCsv[params[item]] = hellman_total_24//data[items][item];
                        // }
                        // else {
                          dataRowCsv['hellman_daily_total_mm'] = hellman_total_24//data[items][item];
                        // }
                      }                      
                    }
                    else {
                      // dataRow[item] = data[items][item];
                      if (params && params.hasOwnProperty(item)) {
                        dataRow[params[item]] = data[items][item];
                        dataRowCsv[params[item]] = data[items][item];
                      }
                      else {
                        dataRow[item] = data[items][item];
                        dataRowCsv[item] = data[items][item];
                      }
                    }
                  // }
                  // dataRow[field] = data[items][item];
                }
              }
              dataTemp.push(dataRow);
              dataTempCsv.push(dataRowCsv)
            }
            // console.log("dataTemp : ", dataTemp);
            this.STORE_EXPORT_PIAS = dataTemp;
            this.items = dataTemp;
            this.itemsCsv = dataTempCsv;
            this.totalRows = this.items.length;
            this.showLoading = false;
          } catch (e) {
            console.log(e);
            this.showLoading = false;
          }
        }

      }
      else { 
        if (currentFrom == "Barograph" || currentFrom == "Thermohigograph" || currentFrom == "Aktinograph" || currentFrom == "FormPerawanan" || 
            currentFrom == "HujanHellman" || currentFrom == "LamaPenyinaran" || currentFrom == "KelembabanTanah" || currentFrom == "FormHujan" ||
            currentFrom == "Fklim" || currentFrom == "IklimMikro" || currentFrom == "Lysimeter" || currentFrom == "Gunbellani" ||
            currentFrom == "OpPenguapan" || currentFrom == "Agm1a" || currentFrom == "Agm1b" || currentFrom == "SuhuTanah" || currentFrom == "PsycrometerAssman") {
          var _metadata_KU = "";
          _metadata_KU = "station_id,station_name,data_timestamp," + _metadata_arr.toString();
          var FORM_KU = "DSM_Klimatologi";

          try {
            // console.log('getRawDataKU', FORM_KU, date_gte, date_lte, _statiun, _metadata_KU, reg_selected, prov_selected, kab_selected)
            const { data } = await outputexport.getRawDataKU(FORM_KU, date_gte, date_lte, _statiun, _metadata_KU, reg_selected, prov_selected, kab_selected);
            var temp_data_ku = [],
                temp_data_csv = [];
            let params = this.callGroupFormCsv(this.type_object)[0]['attr']
            // let params2 = this.callGroupFormCsv(this.type_object)[1]['attr']
            // let params3 = [...params1, ...params2];
            // console.log('params3', params3, this.callGroupFormCsv(this.type_object))
            for (var i in data) {
              var row = data[i];
              var row_ku = {};
              var row_csv = {}
              var tgl = row.data_timestamp.split('-')
              row_ku["station_id"] = row.station_id//row.alias_station_id;
              row_csv["station_id"] = row.station_id
              row_ku["station_name"] = row.station_name;
              row_csv["station_name"] = row.station_name; 
              row_ku["latitude"] = row.current_latitude;
              row_csv["latitude"] = row.current_latitude; 
              row_ku["longitude"] = row.current_longitude;
              row_csv["longitude"] = row.current_longitude;
              // row_ku["periode"] = row.periode_form;
              // row_ku["tahun"] = row.tahun_form.substr(0, 4);
              row_ku["bulan"] = (tgl.length > 2) ? (tgl[0]+"-"+tgl[1]) : row.data_timestamp;
              row_csv["bulan"] = (tgl.length > 2) ? (tgl[0]+"-"+tgl[1]) : row.data_timestamp;
              // row_ku["tgl_angkat"] = row.tgl_angkat.replace("T", " ");;
              for (var k in row) {
                // console.log("k : ",k)
                if (k == "alias_station_id" || k == "station_id" || k == "station_name" || k == "data_timestamp" || k == "qc_flag" ||
                  k == "tgl_angkat" || k == "source_data" || k == "tahun_form" || k == "periode_form" || k == "qc_histories" ||
                  k == "current_latitude" || k == "current_longitude") {
                  // console.log("continue : ",k)
                  continue;
                } else {
                  // row_ku[k] = row[k];
                  let value = this.derivedValue(currentFrom, k, row[k]);
                  // row_ku[k] = value
                  row_ku[params[k]] = value
                  // ONLY FOR CSV LABKU
                  row_csv[params[k]] = value//row[k]
                }

              }
              temp_data_ku.push(row_ku);
              temp_data_csv.push(row_csv);
            }

            // console.log('temp_data_csv', temp_data_csv)
            this.STORE_EXPORT_PIAS = temp_data_ku;
            this.items = temp_data_ku;
            this.itemsCsv = temp_data_csv;
            this.totalRows = this.items.length;
            this.showLoading = false;
          } catch (e) {
            console.log('e', e)
            this.showLoading = false;
          }
        }
        else if (currentFrom == "Kah" || currentFrom == "Aerosol" || currentFrom == "Spm" || currentFrom == "So2no2") {
          var _metadata_KU = "";
          // _metadata_KU = "station_id,station_name,tgl_pasang,tgl_angkat,alias_station_id,source_data,tahun_form,periode_form," + _metadata_arr.toString() + ",qc_flag,qc_histories";
          _metadata_KU = "station_id,station_name,data_timestamp," + _metadata_arr.toString();
          var FORM_KU = currentFrom;
          if (currentFrom == "Aerosol") {
            FORM_KU = "DSM_Aerosol";
          } else if (currentFrom == "Kah") {
            FORM_KU = "DSM_KAH";
          } else if (currentFrom == "So2no2") {
            FORM_KU = "DSM_SO2NO2";
          } else if (currentFrom == "Spm") {
            FORM_KU = "DSM_SPM";
          }
          
          try {
            console.log('getRawDataKU', FORM_KU, date_gte, date_lte, _statiun, _metadata_KU, reg_selected, prov_selected, kab_selected)
            const { data } = await outputexport.getRawDataKU(FORM_KU, date_gte, date_lte, _statiun, _metadata_KU, reg_selected, prov_selected, kab_selected);
            var temp_data_ku = [],
                temp_data_csv = [];
            let params = this.callGroupFormCsv(this.type_object)[0]['attr']
            console.log('params', params, this.callGroupFormCsv(this.type_object))
            for (var i in data) {
              var row = data[i];
              var row_ku = {};
              var row_csv = {}
              var tgl = row.data_timestamp.split('-')
              row_ku["station_id"] = row.station_id//row.alias_station_id;
              row_csv["station_id"] = row.station_id
              row_ku["station_name"] = row.station_name;
              row_csv["station_name"] = row.station_name; 
              row_ku["latitude"] = row.current_latitude;
              row_csv["latitude"] = row.current_latitude; 
              row_ku["longitude"] = row.current_longitude;
              row_csv["longitude"] = row.current_longitude;
              // row_ku["periode"] = row.periode_form;
              // row_ku["tahun"] = row.tahun_form.substr(0, 4);
              row_ku["bulan"] = (tgl.length > 2) ? (tgl[0]+"-"+tgl[1]) : row.data_timestamp;
              row_csv["bulan"] = (tgl.length > 2) ? (tgl[0]+"-"+tgl[1]) : row.data_timestamp;
              // row_ku["tgl_angkat"] = row.tgl_angkat.replace("T", " ");;
              for (var k in row) {
                // console.log("k : ",k)
                if (k == "alias_station_id" || k == "station_id" || k == "station_name" || k == "data_timestamp" || k == "qc_flag" ||
                  k == "tgl_angkat" || k == "source_data" || k == "tahun_form" || k == "periode_form" || k == "qc_histories" ||
                  k == "current_latitude" || k == "current_longitude") {
                  // console.log("continue : ",k)
                  continue;
                } else {
                  // row_ku[k] = row[k];
                  let value = this.derivedValue(currentFrom, k, row[k]);
                  row_ku[k] = value
                  // ONLY FOR CSV LABKU
                  row_csv[params[k]] = value//row[k]
                }

              }

              // row_ku["qc_flag"] = row.qc_flag;
              // if (row_ku["qc_flag"] == 1 || row_ku["qc_flag"] == 2) {
              //   let JsonQc = JSON.parse(row.qc_flag);
              //   let Qc_His = JsonQc.after;
              //   var Log_QC_His = "";
              //   for (var i_qc in Qc_His) {
              //     Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
              //   }
              //   let len = Log_QC_His.length;
              //   let log = Log_QC_His;
              //   if (len > 0) {
              //     log = Log_QC_His.substring(0, len - 1);
              //   }

              //   row_ku["qc_parameter"] = log;
              // } else {
              //   row_ku["qc_parameter"] = "";
              // }
              temp_data_ku.push(row_ku);
              temp_data_csv.push(row_csv);
            }

            // console.log('temp_data_csv', temp_data_csv)
            this.STORE_EXPORT_PIAS = temp_data_ku;
            this.items = temp_data_ku;
            this.itemsCsv = temp_data_csv;
            this.totalRows = this.items.length;
            this.showLoading = false;
          } catch (e) {
            console.log('e', e)
            this.showLoading = false;
          }

        }
        else {
          var _metadata_KU = "";
          _metadata_KU = "station_id,station_name,data_timestamp," + _metadata_arr.toString();
          var FORM_KU = "DSM_Klimatologi";

          try {
            // console.log('getRawDataKU', FORM_KU, date_gte, date_lte, _statiun, _metadata_KU, reg_selected, prov_selected, kab_selected)
            const { data } = await outputexport.getRawDataKU(FORM_KU, date_gte, date_lte, _statiun, _metadata_KU, reg_selected, prov_selected, kab_selected);
            var temp_data_ku = [],
                temp_data_csv = [];
            let params = {}
            if (this.type_object != 'all_klimat') {
              params = this.callGroupFormCsv(this.type_object)[0]['attr']
            }
            else {
              params = this.callGroupFormCsv(this.type_object)
              console.log('params3', params)
            }

            for (var i in data) {
              var row = data[i];
              var row_ku = {};
              var row_csv = {}
              var tgl = row.data_timestamp.split('-')
              row_ku["station_id"] = row.station_id//row.alias_station_id;
              row_csv["station_id"] = row.station_id
              row_ku["station_name"] = row.station_name;
              row_csv["station_name"] = row.station_name; 
              row_ku["latitude"] = row.current_latitude;
              row_csv["latitude"] = row.current_latitude; 
              row_ku["longitude"] = row.current_longitude;
              row_csv["longitude"] = row.current_longitude;
              // row_ku["periode"] = row.periode_form;
              // row_ku["tahun"] = row.tahun_form.substr(0, 4);
              row_ku["bulan"] = (tgl.length > 2) ? (tgl[0]+"-"+tgl[1]) : row.data_timestamp;
              row_csv["bulan"] = (tgl.length > 2) ? (tgl[0]+"-"+tgl[1]) : row.data_timestamp;
              // row_ku["tgl_angkat"] = row.tgl_angkat.replace("T", " ");;
              for (var k in row) {
                console.log("k : ",k)
                if (k == "alias_station_id" || k == "station_id" || k == "station_name" || k == "data_timestamp" || k == "qc_flag" ||
                  k == "tgl_angkat" || k == "source_data" || k == "tahun_form" || k == "periode_form" || k == "qc_histories" ||
                  k == "current_latitude" || k == "current_longitude") {
                  // console.log("continue : ",k)
                  continue;
                } else {
                  // row_ku[k] = row[k];
                  let value = this.derivedValue(currentFrom, k, row[k]);
                  // row_ku[k] = value
                  row_ku[params[k]] = value
                  // ONLY FOR CSV LABKU
                  row_csv[params[k]] = value//row[k]
                }

              }
              temp_data_ku.push(row_ku);
              temp_data_csv.push(row_csv);
            }

            // console.log('temp_data_csv', temp_data_csv)
            this.STORE_EXPORT_PIAS = temp_data_ku;
            this.items = temp_data_ku;
            this.itemsCsv = temp_data_csv;
            this.totalRows = this.items.length;
            this.showLoading = false;
          } catch (e) {
            console.log('e', e)
            this.showLoading = false;
          }
        }
      }

    },

    checkIntValue(val) {
      let newVal = Number.isInteger(parseInt(val)) ? parseInt(val) : 0
      // console.log('checkIntValue', newVal)
      return newVal
    },
    
    derivedValue(form, param, value) {
      let attr = null
      if (form == "Aktinograph") {
        // attr = aktinograph.getAttrMonthlyDecimal()[0].attr
        if (this.t_temporal_data) {
          if (this.t_temporal_data.value == "harian") {
            attr = aktinograph.getAttrDailyDecimal()[0].attr
          }
          else if (this.t_temporal_data.value == "bulanan") {
            attr = aktinograph.getAttrMonthlyDecimal()[0].attr
          }
        }
      }
      else if (form == "Barograph") {
        // attr = barograph.getAttrMonthlyDecimal()[0].attr
        if (this.t_temporal_data) {
          if (this.t_temporal_data.value == "harian") {
            attr = barograph.getAttrDailyDecimal()[0].attr
          }
          else if (this.t_temporal_data.value == "bulanan") {
            attr = barograph.getAttrMonthlyDecimal()[0].attr
          }
        }
      }
      else if (form == "KelembabanTanah") {
        attr = kelembabantanah.getAttrMonthlyDecimal()[0].attr
      }
      // else if (form == "HujanHellman") {
      //   // attr = lamapenyinaran.getAttrMonthlyDecimal()[0].attr
      //   if (this.t_temporal_data) {
      //     if (this.t_temporal_data.value == "harian") {
      //       attr = intentitashujan.getAttrDailyDecimal()[0].attr
      //     }
      //     else if (this.t_temporal_data.value == "bulanan") {
      //       attr = intentitashujan.getAttrMonthlyDecimal()[0].attr
      //     }
      //   }
      // }
      else if (form == "Thermohigograph") {
        // attr = thermohigrograph.getAttrMonthlyDecimal()[0].attr
        if (this.t_temporal_data) {
          if (this.t_temporal_data.value == "harian") {
            attr = thermohigrograph.getAttrDailyDecimal()[0].attr
          }
          else if (this.t_temporal_data.value == "bulanan") {
            attr = thermohigrograph.getAttrMonthlyDecimal()[0].attr
          }
        }
      }
      else if (form == "LamaPenyinaran") {
        // attr = lamapenyinaran.getAttrMonthlyDecimal()[0].attr
        if (this.t_temporal_data) {
          if (this.t_temporal_data.value == "harian") {
            attr = lamapenyinaran.getAttrDailyDecimal()[0].attr
          }
          else if (this.t_temporal_data.value == "bulanan") {
            attr = lamapenyinaran.getAttrMonthlyDecimal()[0].attr
          }
        }
      }
      else if (form == "Agm1a") {
        attr = agm1a.getAttrMonthlyDecimal()[0].attr
      }
      else if (form == "Agm1b") {
        attr = agm1b.getAttrMonthlyDecimal()[0].attr
      }
      else if (form == "PsycrometerAssman") {
        attr = psycrometerassman.getAttrMonthlyDecimal()[0].attr
      }
      else if (form == "SuhuTanah") {
        attr = suhutanah.getAttrMonthlyDecimal()[0].attr
      }
      else if (form == "Fklim") {
        if (this.t_temporal_data) {
          if (this.t_temporal_data.value == "harian") {
            attr = fklim.getAttrDailyDecimal()[0].attr
          }
          else if (this.t_temporal_data.value == "bulanan") {
            attr = fklim.getAttrMonthlyDecimal()[0].attr
          }
        }
        // attr = fklim.getAttrMonthlyDecimal()[0].attr
      }
      else if (form == "IklimMikro") {
        attr = iklimmikro.getAttrMonthlyDecimal()[0].attr
      }
      else if (form == "OpPenguapan") {
        // attr = oppenguapan.getAttrMonthlyDecimal()[0].attr
        if (this.t_temporal_data) {
          if (this.t_temporal_data.value == "harian") {
            attr = oppenguapan.getAttrDailyDecimal()[0].attr
          }
          else if (this.t_temporal_data.value == "bulanan") {
            attr = oppenguapan.getAttrMonthlyDecimal()[0].attr
          }
        }
      }
      else if (form == "Lysimeter") {
        // attr = lysimeter.getAttrMonthlyDecimal()[0].attr
        if (this.t_temporal_data) {
          if (this.t_temporal_data.value == "harian") {
            attr = lysimeter.getAttrDailyDecimal()[0].attr
          }
          else if (this.t_temporal_data.value == "bulanan") {
            attr = lysimeter.getAttrMonthlyDecimal()[0].attr
          }
        }
      }
      else if (form == "PichePenguapan") {
        // attr = lysimeter.getAttrMonthlyDecimal()[0].attr
        if (this.t_temporal_data) {
          if (this.t_temporal_data.value == "harian") {
            attr = pichepenguapan.getAttrDailyDecimal()[0].attr
          }
          else if (this.t_temporal_data.value == "bulanan") {
            attr = pichepenguapan.getAttrDailyDecimal()[0].attr
          }
        }
      }
      else if (form == "Gunbellani") {
        attr = gunbellani.getAttrMonthlyDecimal()[0].attr
      }
      // console.log('attr', attr, form, param, value)
      let newValue = value
      let decimal = (attr && attr.hasOwnProperty(param)) ? attr[param] : -1
      
      if (value && decimal != -1) {
        newValue = value.toFixed(decimal)
      }

      return newValue
    },

    previewGroup() {
      this.cardCheckbox = [];
      this.selectedCheck = [];
      this.checkall = [];
      // this.col_card = this.temp_cols;
      // console.log("Selected ", this.selectedCheck);
      // console.log("leng grup : ", this.group.length);
      let leng_cols_current = this.group.length;
      if (leng_cols_current < 3) {
        this.col_card = 6;
      } else {
        this.col_card = 4;
      }

      if (this.t_form) {
        for (var obj in this.group) {
          var attrib = [];
          var groupData = parseInt(this.group[obj].value);
          console.log("objenya : ", groupData, this.group[obj].value);
          for (var atrb in this.arr_group[groupData].attr) {
            var row = {
              text: this.arr_group[groupData].attr[atrb],
              value: atrb,
            };
            // console.log(row)
            attrib.push(row);
          }
          // console.log("attrib : ",attrib)
          var data = {
            label: this.arr_group[groupData].label,
            attr: attrib,
          };
          this.cardCheckbox.push(data);
          this.sumbitShow = true;
          // console.log("data : ",data)
        }
      }
      else {
        console.log('previewGroup - this.type_object', this.type_object)
        if (this.type_object == 'all_klimat' && this.t_temporal_data.value == "bulanan") {
          let group_parameter = []

          let expandGroup = []

          for (var idx in this.group) {
            for (var obj in this.join_g_all) {
              console.log('this.join_g_all', this.join_g_all[obj], this.group[idx])
              if (this.join_g_all[obj].label == this.group[idx].text) {
                var rowData = {
                  value: obj,
                  text: this.join_g_all[obj].label,
                };
                expandGroup.push(rowData);
              }
            }
          }
          console.log('expandGroup - data', expandGroup)

          for (var obj in expandGroup) {
            var attrib = [];
            // console.log('group[obj]', this.group[obj], this.group[obj].value)

            var groupData = parseInt(expandGroup[obj].value);
            // console.log("objenya : ", groupData);
            for (var atrb in this.arr_group[groupData].attr) {
              var row = {
                text: this.arr_group[groupData].attr[atrb],
                value: atrb,
              };
              // console.log(row)
              attrib.push(row);
            }
            // console.log("attrib : ",attrib)
            var data = {
              label: this.arr_group[groupData].label,
              attr: attrib,
            };
            this.cardCheckbox.push(data);
            this.sumbitShow = true;
            // console.log("data : ",data)
          }
        }
      }
    },

    selectGroup(form, temp_data) {
      // if (this.t_)
    },

    callGroupForm(){
      this.g_all = {
        LamaPenyinaran: lamapenyinaran.getAttrMonthly(),
        Aktinograph: aktinograph.getAttrMonthly(),
        Thermohigograph: thermohigrograph.getAttrMonthly(),
        HujanHellman: intentitashujan.getAttrMonthly(),
        Barograph: barograph.getAttrMonthly(),
        KelembabanTanah: kelembabantanah.getAttrMonthly(),
        FormPerawanan: perawanan.getAttrMonthly(),
        Fenologi: fenologi.getAttr(),
        FormHujan: formhujan.getAttrMonthly(),
        Fklim: fklim.getAttrMonthly(),
        Agm1a: agm1a.getAttrMonthly(),
        Agm1b: agm1b.getAttrMonthly(),
        Gunbellani: gunbellani.getAttrMonthly(),
        IklimMikro: iklimmikro.getAttrMonthly(),
        Lysimeter: lysimeter.getAttrMonthly(),
        PichePenguapan: pichepenguapan.getAttrMonthly(),
        PsycrometerAssman: psycrometerassman.getAttrMonthly(),
        SuhuTanah: suhutanah.getAttrMonthly(),
        OpPenguapan: oppenguapan.getAttrMonthly(),
        // All: allklimat.getAttrMonthly(),
      };

      this.g_all_daily = {
        LamaPenyinaran: lamapenyinaran.getAttrDaily(),
        Aktinograph: aktinograph.getAttrDaily(),
        Thermohigograph: thermohigrograph.getAttrDaily(),
        HujanHellman: intentitashujan.getAttrDaily(),
        Barograph: barograph.getAttrDaily(),
        KelembabanTanah: kelembabantanah.getAttrMonthly(),
        FormPerawanan: perawanan.getAttrDaily(),
        Fenologi: fenologi.getAttr(),
        FormHujan: formhujan.getAttrDaily(),
        Fklim: fklim.getAttrDaily(),
        Agm1a: agm1a.getAttrMonthly(),
        Agm1b: agm1b.getAttrMonthly(),
        Gunbellani: gunbellani.getAttrMonthly(),
        IklimMikro: iklimmikro.getAttrMonthly(),
        Lysimeter: lysimeter.getAttrDaily(),
        PichePenguapan: pichepenguapan.getAttrDaily(),
        PsycrometerAssman: psycrometerassman.getAttrMonthly(),
        SuhuTanah: suhutanah.getAttrMonthly(),
        OpPenguapan: oppenguapan.getAttrDaily(),
        // All: allklimat.getAttrMonthly(),
      };
      this.g_all_monthly = {

      }
    }, 
    
    callGroupFormCsv(type){
      console.log('callGroupFormCsv', type)
      let group_all = {}
      if (this.t_temporal_data && this.t_temporal_data.value == "harian") {
        group_all = {
          LamaPenyinaran: lamapenyinaran.getAttrDailyCsv(),
          Aktinograph: aktinograph.getAttrDailyCsv(),
          Thermohigograph: thermohigrograph.getAttrDailyCsv(),
          HujanHellman: intentitashujan.getAttrDailyCsv(),
          Barograph: barograph.getAttrDailyCsv(),
          KelembabanTanah: kelembabantanah.getAttrMonthlyCsv(),
          FormPerawanan: perawanan.getAttrDailyCsv(),
          Fenologi: fenologi.getAttr(),
          FormHujan: formhujan.getAttrDailyCsv(),
          Fklim: fklim.getAttrDailyCsv(),
          Agm1a: agm1a.getAttrMonthlyCsv(),
          Agm1b: agm1b.getAttrMonthlyCsv(),
          Gunbellani: gunbellani.getAttrMonthlyCsv(),
          IklimMikro: iklimmikro.getAttrMonthlyCsv(),
          Lysimeter: lysimeter.getAttrDailyCsv(),
          PichePenguapan: pichepenguapan.getAttrDailyCsv(),
          PsycrometerAssman: psycrometerassman.getAttrMonthlyCsv(),
          SuhuTanah: suhutanah.getAttrMonthlyCsv(),
          OpPenguapan: oppenguapan.getAttrDailyCsv(),
        };
      }
      else {
        group_all = {
          LamaPenyinaran: lamapenyinaran.getAttrMonthlyCsv(),
          Aktinograph: aktinograph.getAttrMonthlyCsv(),
          Thermohigograph: thermohigrograph.getAttrMonthlyCsv(),
          HujanHellman: intentitashujan.getAttrMonthlyCsv(),
          Barograph: barograph.getAttrMonthlyCsv(),
          KelembabanTanah: kelembabantanah.getAttrMonthlyCsv(),
          FormPerawanan: perawanan.getAttrMonthlyCsv(),
          Fenologi: fenologi.getAttr(),
          FormHujan: formhujan.getAttrMonthlyCsv(),
          Fklim: fklim.getAttrMonthlyCsv(),
          Agm1a: agm1a.getAttrMonthlyCsv(),
          Agm1b: agm1b.getAttrMonthlyCsv(),
          Gunbellani: gunbellani.getAttrMonthlyCsv(),
          IklimMikro: iklimmikro.getAttrMonthlyCsv(),
          Lysimeter: lysimeter.getAttrMonthlyCsv(),
          PichePenguapan: pichepenguapan.getAttr(),
          PsycrometerAssman: psycrometerassman.getAttrMonthlyCsv(),
          SuhuTanah: suhutanah.getAttrMonthlyCsv(),
          OpPenguapan: oppenguapan.getAttrMonthlyCsv(),
        };
      }

      if (type == 'all_klimat') {
        var tempArr = {
          ...group_all.LamaPenyinaran[0]['attr'],
          ...group_all.Aktinograph[0]['attr'],
          ...group_all.Thermohigograph[0]['attr'],
          ...group_all.HujanHellman[0]['attr'],
          ...group_all.Barograph[0]['attr'],
          ...group_all.KelembabanTanah[0]['attr'],
          ...group_all.FormPerawanan[0]['attr'],
          ...group_all.Fenologi[0]['attr'],
          ...group_all.FormHujan[0]['attr'],
          ...group_all.Fklim[0]['attr'],
          ...group_all.Agm1a[0]['attr'],
          ...group_all.Agm1b[0]['attr'],
          ...group_all.Gunbellani[0]['attr'],
          ...group_all.IklimMikro[0]['attr'],
          ...group_all.Lysimeter[0]['attr'],
          ...group_all.PichePenguapan[0]['attr'],
          ...group_all.PsycrometerAssman[0]['attr'],
          ...group_all.SuhuTanah[0]['attr'],
          ...group_all.OpPenguapan[0]['attr']
        }
        // var attrs = {
        //   label: "all attribute",
        //   attr: tempArr
        // }
        return tempArr
      }
      else {
        return group_all[type]
      }
    }, 

    async listBalai() {
      try {
        const { data } = await metadata.getRegionList();
        for (var i = 0; i < data.length; i++) {
          const dataRegion = {
            value: data[i].region_id,
            text: data[i].region_description,
          };
          this.regions_opt.push(dataRegion);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async listPropinsi(region_id) {
      this.propinsi_opt = [{ value: "", text: "Semua Propinsi" }];
      try {
        const { data } = await metadata.getPropinsiListByRegion(region_id);
        // console.log(data);
        for (var i = 0; i < data.length; i++) {
          const rowsPropinsi = {
            value: data[i].propinsi_id,
            text: data[i].propinsi_name,
          };
          this.propinsi_opt.push(rowsPropinsi);

          // this.items.push(dataPropinsi);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async listAllPropinsi() {
      this.propinsi_opt = [{ value: "", text: "Semua Propinsi" }];
      try {
        const { data } = await metadata.getPropinsiList();
        // console.log(data);
        for (var i = 0; i < data.length; i++) {
          const rowsPropinsi = {
            value: data[i].propinsi_id,
            text: data[i].propinsi_name,
          };
          this.propinsi_opt.push(rowsPropinsi);

        }
      } catch (e) {
        console.log(e);
      }
    },
    async listKabupaten(propinsi_id) {
      if (propinsi_id != "") {
        this.stasiun_opt = [];
        this.kabupaten_opt = [{ value: "", text: "Semua Kota/Kabupaten" }];
        try {
          const { data } = await metadata.getKabupatenListByPropinsi(propinsi_id);
          // console.log(data);
          for (var i = 0; i < data.length; i++) {
            const rowsKabupaten = {
              value: data[i].kabupaten_id,
              text: data[i].kabupaten_name,
            };
            this.kabupaten_opt.push(rowsKabupaten);
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    async listStasiunReg(reg_id) {
      this.showLoading = true;
      this.stasiun_opt = [];
      this.stasiun_opt = [{ value: "", text: " - Semua Stasiun" }];
      try {
        // const { data } = await metadata.getStasiunByRegion(reg_id);
        const { data } = await metadata.getExStasiunByBalai(reg_id, this.is_bmkg_entry);
        // console.log(data);
        for (var i = 0; i < data.length; i++) {
          const rowsStasiun = {
            value: data[i].station_id,
            text: data[i].station_name,
          };
          this.stasiun_opt.push(rowsStasiun);
        }
        var sort_data = this.stasiun_opt;
        // console.log("sort : ", sort_data)
        sort_data.sort((a, b) => {
          let fa = a.text;
          let fb = b.text;

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        this.stasiun_opt = sort_data;
        this.showLoading = false;
      } catch (e) {
        console.log(e);
        this.showLoading = false;
      }
    },
    async listStasiunProv(prov_id) {
      // console.log("prov_id : ",prov_id)
      if (prov_id == "") {
        console.log("prov_id kosong");
      } else {
        this.showLoading = true;
        this.stasiun_opt = [{ value: "", text: " - Semua Stasiun" }];
        try {
          const { data } = await metadata.getExStasiunByProv(prov_id, this.is_bmkg_entry);
          // const { data } = await metadata.getStasiunByPropinsi(prov_id);
          // console.log(data);
          for (var i = 0; i < data.length; i++) {
            const rowsStasiun = {
              value: data[i].station_id,
              text: data[i].station_name,
            };
            this.stasiun_opt.push(rowsStasiun);
          }
          var sort_data = this.stasiun_opt;
          sort_data.sort((a, b) => {
            let fa = a.text;
            let fb = b.text;

            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          this.stasiun_opt = sort_data;
          this.showLoading = false;
        } catch (e) {
          console.log(e);
          this.showLoading = false;
        }
      }
    },
    async listStasiun(kab_id) {
      if (kab_id == "") {
        console.log("kab kosong");
      } else {
        this.showLoading = true;
        this.stasiun_opt = [];
        this.stasiun_opt = [{ value: "", text: " - Semua Stasiun" }];
        try {
          const { data } = await metadata.getExStasiunByKab(kab_id, this.is_bmkg_entry);
          // const { data } = await metadata.getStasiunByKabupaten(kab_id);
          // console.log(data);
          for (var i = 0; i < data.length; i++) {
            const rowsStasiun = {
              value: data[i].station_id,
              text: data[i].station_name,
            };
            this.stasiun_opt.push(rowsStasiun);
          }
          var sort_data = this.stasiun_opt;
          sort_data.sort((a, b) => {
            let fa = a.text;
            let fb = b.text;

            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          this.stasiun_opt = sort_data;
          this.showLoading = false;
        } catch (e) {
          console.log(e);
          this.showLoading = false;
        }
      }
    },
    checkStarEndDate(bln){
      // console.log(this.start_date)
      // console.log(this.end_date)
      var ds = moment(this.start_date, "MM/YYYY")
      var de = moment(this.end_date, "MM/YYYY");
      var a = ds.isBefore(de) // false
      var b = ds.isAfter(de)
      // console.log("a = "+a+" b = "+b)
      if(!a && b){
        if(bln == "start"){
          this.start_date = "";
        }else if(bln == "end"){
          this.end_date = ""
        }
        // alert("Bulan Akhir harus lebih besar/sama dari bulan "+ this.start_date)
        this.$swal({
            title: "ERROR",
            text: "Bulan Akhri harus sama/lebih besar dari bulan awal ",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
      }
    },

    // updateLabelGroup(arrData, groupName) {
    //   var newArr = []
    //   if (arrData) {
    //     for (var obj in arrData) {
    //       console.
    //       var rowData = {
    //         value: obj,
    //         text: this.g_all[val.value][obj].label,
    //       };
    //       data.push(rowData);
    //     }
    //   }

    //   return
    // }

  },
  watch: {
    start_date(val){
      this.checkStarEndDate("start")
    },
    end_date(val){
      this.checkStarEndDate("end")
    },
    t_mkg(val) {
      // console.log("val :", val);
      if (val.value == "meteo") {
        this.t_form_opt = this.t_from_m;
      } else if (val.value == "klimat") {
        this.t_form_opt = this.t_from_k;
      } else if (val.value == "geofis") {
        this.t_form_opt = this.t_from_g;
      } else {
        this.t_form_opt = [];
      }
      this.t_form = "";
      this.group = "";
    },

    t_form(val) {
      this.selectedCheck = [];
      this.checkall = [];
      this.cardCheckbox = [];
      this.t_temporal_data = ""
      if (!val) {
        this.t_temporal_data = ""
        this.t_temp_data_opt = [
          { value: "bulanan", text: "Bulanan", disabled: false }
        ]
        this.group = ""
        this.group_opt = []
        return;
      }

      // console.log("form select : ",val)
      // console.log("form op  : ",this.g_all[val.value])
      // console.log("selected cek cek : ",this.selectedCheck)
      if (val && (val.value == "Aerosol" || val.value == "Spm" || val.value == "Kah" || val.value == "So2no2") ) {
        this.t_temp_data_opt = [
          { value: "jam", text: "Jam", disabled: true },
          { value: "harian", text: "Harian", disabled: true },
          { value: "dasarian", text: "Dasarian", disabled: true },
          { value: "bulanan", text: "Bulanan", disabled: false },
        ]
      }
      else if (val && (val.value == "Fklim" || val.value == "OpPenguapan" || val.value == "Lysimeter" || val.value == "LamaPenyinaran" || val.value == "FormHujan" ||
            val.value == "Barograph" || val.value == "Thermohigograph" || val.value == "Aktinograph" || val.value == "FormPerawanan" || val.value == "HujanHellman") ) 
      {
        
        this.t_temp_data_opt = [
          // { value: "jam", text: "Jam", disabled: true },
          { value: "harian", text: "Harian", disabled: false },
          // { value: "dasarian", text: "Dasarian", disabled: true },
          { value: "bulanan", text: "Bulanan", disabled: false },
        ]                  
      }
      else if ( val.value == "PichePenguapan" ) {
        this.t_temp_data_opt = [
          // { value: "jam", text: "Jam", disabled: true },
          { value: "harian", text: "Harian", disabled: false },
          // { value: "dasarian", text: "Dasarian", disabled: true },
          // { value: "bulanan", text: "Bulanan", disabled: false },
        ]  
      }
      else if (val && (val.value == "PsycrometerAssman" || val.value == "SuhuTanah" ||
                val.value == "IklimMikro" || val.value == "Gunbellani" || 
                val.value == "HujanHellman" || val.value == "KelembabanTanah" || val.value == "KelembabanTanah" || 
                val.value == "FormHujan" ) ) 
      {
        this.t_temp_data_opt = [
          // { value: "jam", text: "Jam", disabled: false },
          // { value: "harian", text: "Harian", disabled: false },
          // { value: "dasarian", text: "Dasarian", disabled: false },
          { value: "bulanan", text: "Bulanan", disabled: false },
        ]
      }
      else {
        this.t_temp_data_opt = [
          { value: "jam", text: "Jam", disabled: false },
          { value: "harian", text: "Harian", disabled: false },
          { value: "dasarian", text: "Dasarian", disabled: false },
          { value: "bulanan", text: "Bulanan", disabled: false },
        ]
      }

      if (val != null) {
        this.is_bmkg_entry = val.value;
        if (this.is_kab_selected != "") {
          this.listStasiun(this.is_kab_selected);
        } else if (this.is_prov_selected != "") {
          this.listStasiunProv(this.is_prov_selected);
        } else if (this.is_balai_selected != "") {
          this.listStasiunReg(this.is_balai_selected);
        }

        var data = [];
        if (this.t_temporal_data && this.t_temporal_data.value == "harian") {
          for (var obj in this.g_all_daily[val.value]) {
            var rowData = {
              value: obj,
              text: this.g_all_daily[val.value][obj].label,
            };
            data.push(rowData);
          }
        }
        else { 
          for (var obj in this.g_all[val.value]) {
            var rowData = {
              value: obj,
              text: this.g_all[val.value][obj].label,
            };
            data.push(rowData);
          }
        }
        var temp_cols_card = 3;
        var leng_cols = (this.t_temporal_data && this.t_temporal_data.value == "harian") ? this.g_all_daily[val.value].length : this.g_all[val.value].length;
        if (leng_cols < 2) {
          temp_cols_card = 12;
        } else if (temp_cols_card < 3) {
          temp_cols_card = 6;
        } else {
          temp_cols_card = 4;
        }
        this.temp_cols = temp_cols_card;

        this.arr_group = (this.t_temporal_data && this.t_temporal_data.value == "harian") ? this.g_all_daily[val.value] : this.g_all[val.value];
        this.group_opt = data;
        this.type_object = val.value;
        this.group = null
        console.log('t_form - this.type_object', this.type_object)
      }
      
      this.group = "";
    },

    t_temporal_data(val) {
      console.log('t_temporal_data t', val, '-'+this.t_form)
      if (val) {
        if (this.t_form) { 
          var data = [];
          if (this.t_temporal_data && this.t_temporal_data.value == "harian") {
            for (var obj in this.g_all_daily[this.t_form.value]) {
              var rowData = {
                value: obj,
                text: this.g_all_daily[this.t_form.value][obj].label,
              };
              data.push(rowData);
            }
          }
          else { 
            for (var obj in this.g_all[this.t_form.value]) {
              var rowData = {
                value: obj,
                text: this.g_all[this.t_form.value][obj].label,
              };
              data.push(rowData);
            }
          }
          var temp_cols_card = 3;
          var leng_cols = (this.t_temporal_data && this.t_temporal_data.value == "harian") ? this.g_all_daily[this.t_form.value].length : this.g_all[this.t_form.value].length;
          if (leng_cols < 2) {
            temp_cols_card = 12;
          } else if (temp_cols_card < 3) {
            temp_cols_card = 6;
          } else {
            temp_cols_card = 4;
          }
          this.temp_cols = temp_cols_card;

          this.arr_group = (this.t_temporal_data && this.t_temporal_data.value == "harian") ? this.g_all_daily[this.t_form.value] : this.g_all[this.t_form.value];
          this.group_opt = data;
          this.type_object = this.t_form.value;
          this.group = null
          // console.log('t_temporal_data - group_opt', this.group_opt)
        }
        else {
          if (val.value == "bulanan") {
            //  Barograph Fklim FormHujan FormPerawanan Gunbellani IklimMikro HujanHellman KelembabanTanah LamaPenyinaran Lysimeter OpPenguapan PichePenguapan PsycrometerAssman PsycrometerAssman SuhuTanah Thermohigograph
            var data = [];
            var g_monthly = [] 
            var aktinograph = this.g_all["Aktinograph"]
            var barograph = this.g_all["Barograph"]
            var fklim = this.g_all["Fklim"]
            var formHujan = this.g_all["FormHujan"]
            var formPerawanan = this.g_all["FormPerawanan"]
            var gunbellani = this.g_all["Gunbellani"]
            var iklimMikro = this.g_all["IklimMikro"]
            var hujanHellman = this.g_all["HujanHellman"]
            var kelembabanTanah = this.g_all["KelembabanTanah"]
            var lamaPenyinaran = this.g_all["LamaPenyinaran"]
            var lysimeter = this.g_all["Lysimeter"]
            var opPenguapan = this.g_all["OpPenguapan"]
            var pichePenguapan = this.g_all["PichePenguapan"]
            var psycrometerAssman = this.g_all["PsycrometerAssman"]
            var suhuTanah = this.g_all["SuhuTanah"]
            var thermohigograph = this.g_all["Thermohigograph"]
            
            this.join_g_all.push(...aktinograph)
            this.join_g_all.push(...barograph)
            this.join_g_all.push(...fklim)
            this.join_g_all.push(...formHujan)
            this.join_g_all.push(...formPerawanan)
            this.join_g_all.push(...gunbellani)
            this.join_g_all.push(...iklimMikro)
            this.join_g_all.push(...hujanHellman)
            this.join_g_all.push(...kelembabanTanah)
            this.join_g_all.push(...lamaPenyinaran)
            this.join_g_all.push(...lysimeter)
            this.join_g_all.push(...opPenguapan)
            this.join_g_all.push(...pichePenguapan)
            this.join_g_all.push(...psycrometerAssman)
            this.join_g_all.push(...suhuTanah)
            this.join_g_all.push(...thermohigograph)
            // for (var obj in join_g_all) {
            //   var rowData = {
            //     value: obj,
            //     text: join_g_all[obj].form + ' - ' +join_g_all[obj].label,
            //   };
            //   data.push(rowData);
            // }
            for (var obj in this.join_g_all) {
              var rowData = {
                value: this.join_g_all[obj].form,
                text: this.join_g_all[obj].group,
              };
              data.push(rowData);
            }
            // console.log('datat', data)
            for (var groupidx in data) {
              if (g_monthly.length == 0) {
                g_monthly.push(data[groupidx]);
              }
              else {
                let gExist = false
                for (var gidx in g_monthly) {
                  if (g_monthly[gidx].text == data[groupidx].text) {
                    gExist = true
                    break
                  }
                }
                if (!gExist) {
                  g_monthly.push(data[groupidx]);
                }
              }
            }

            console.log('khusus bulanan', g_monthly )
            var temp_cols_card = 3;
            var leng_cols = this.join_g_all.length;
            if (leng_cols < 2) {
              temp_cols_card = 12;
            } else if (temp_cols_card < 3) {
              temp_cols_card = 6;
            } else {
              temp_cols_card = 4;
            }
            this.temp_cols = temp_cols_card;

            this.arr_group = this.join_g_all;
            this.group_opt = g_monthly// data;
            this.type_object = 'all_klimat';
            this.group = null
          }
        }
      }
    },

    regions(val) {
      if (val != null || val == "") {
        if (this.t_form) {
          if (this.is_bmkg_entry == "" || this.is_bmkg_entry == undefined) {
            this.$swal({
              title: "ERROR",
              text: "Silahkan Isi Form ",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
            this.regions = null;
            return;
          }
          this.is_balai_selected = val.value;
        }
        if (!this.t_form) {
          this.listAllPropinsi()
        }
        else {
          this.listPropinsi(val.value);
        }
        // this.listPropinsi(val.value);
        this.listStasiunReg(val.value);
        this.propinsi = { value: "", text: "Semua Propinsi" };
      } else {
        this.propinsi_opt = [];
        this.propinsi = "";
        this.kabupaten_opt = [];
        this.kabupaten = "";
        this.stasiun_opt = [];
        this.stasiun = "";
      }
    },
    propinsi(val) {
      if (val != null || val == "") {
        this.is_prov_selected = val.value;
        this.listKabupaten(val.value);
        this.listStasiunProv(val.value);
        this.kabupaten = { value: "", text: "Semua Kota/Kabupaten" };
      } else {
        this.kabupaten_opt = [];
        this.kabupaten = "";
        this.stasiun_opt = [];
        this.stasiun = "";
      }
    },
    kabupaten(val) {
      console.log("kab val : ", val);
      if (val != null || val == "") {
        this.is_kab_selected = val.value;
        this.listStasiun(val.value);
        this.stasiun = "";
      } else {
        this.stasiun_opt = [];
        this.stasiun = "";
      }
    },

    checkall() {
      // console.log("cek : ",this.selectedCheck)
      // Helper.DELETE_ARRAY(this.selectedCheck,)
      this.selectedCheck = [];

      for (var obj in this.checkall) {
        var cek = this.checkall[obj];
        var is_data = this.uncheckall.indexOf(cek);
        if (is_data != "-1") {
          this.uncheckall.splice(is_data, 1);
        }
        var row = this.checkall[obj].attr;
        for (var i in row) {
          if (this.selectedCheck.indexOf(row[i].value) == "-1") {
            // console.log("daaaa : ",row[i])
            this.selectedCheck.push(row[i].value);
          }
        }
      }
    },
    uncheckall() {
      // console.log("uncek : ",this.uncheckall)
      for (var obj in this.uncheckall) {
        var uncek = this.uncheckall[obj];
        var is_data = this.checkall.indexOf(uncek);
        if (is_data != "-1") {
          this.checkall.splice(is_data, 1);
        }
        // console.log("uncek : ",this.checkall.indexOf(uncek))
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style scoped>
.pad1 {
  padding-right: 0px;
}

.pad2 {
  padding-right: 0px;
  padding-left: 0px;
}
</style>

<style>
    .disabled {
      pointer-events:none;
      color: #bfcbd9;
      cursor: not-allowed;
      background-image: none;
      background-color: #eef1f6;
      border-color: #d1dbe5;   
    }
  </style>
