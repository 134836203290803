import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "Suhu Udara",
        attr: {
          t_thermo_01: "Suhu thermohigrograph jam 01:00",
          t_thermo_02: "Suhu thermohigrograph jam 02:00",
          t_thermo_03: "Suhu thermohigrograph jam 03:00",
          t_thermo_04: "Suhu thermohigrograph jam 04:00",
          t_thermo_05: "Suhu thermohigrograph jam 05:00",
          t_thermo_06: "Suhu thermohigrograph jam 06:00",
          t_thermo_07: "Suhu thermohigrograph jam 07:00",
          t_thermo_08: "Suhu thermohigrograph jam 08:00",
          t_thermo_09: "Suhu thermohigrograph jam 09:00",
          t_thermo_10: "Suhu thermohigrograph jam 10:00",
          t_thermo_11: "Suhu thermohigrograph jam 11:00",
          t_thermo_12: "Suhu thermohigrograph jam 12:00",
          t_thermo_13: "Suhu thermohigrograph jam 13:00",
          t_thermo_14: "Suhu thermohigrograph jam 14:00",
          t_thermo_15: "Suhu thermohigrograph jam 15:00",
          t_thermo_16: "Suhu thermohigrograph jam 16:00",
          t_thermo_17: "Suhu thermohigrograph jam 17:00",
          t_thermo_18: "Suhu thermohigrograph jam 18:00",
          t_thermo_19: "Suhu thermohigrograph jam 19:00",
          t_thermo_20: "Suhu thermohigrograph jam 20:00",
          t_thermo_21: "Suhu thermohigrograph jam 21:00",
          t_thermo_22: "Suhu thermohigrograph jam 22:00",
          t_thermo_23: "Suhu thermohigrograph jam 23:00",
          t_thermo_24: "Suhu thermohigrograph jam 24:00",
          tx_thermo: "Suhu max dari jam 01:00 sampai 24:00",
          tn_thermo: "Suhu min dari jam 01:00  sampai 24:00",
          t_avg_thermo: "suhu rata2 dari jam 01:00  sampai 24:00",
        },
      },

      {
        label: "Kelembaban Udara",
        attr: {
          rh_higro_01: "Kelembaban thermohigrograph jam 01:00",
          rh_higro_02: "Kelembaban thermohigrograph jam 02:00",
          rh_higro_03: "Kelembaban thermohigrograph jam 03:00",
          rh_higro_04: "Kelembaban thermohigrograph jam 04:00",
          rh_higro_05: "Kelembaban thermohigrograph jam 05:00",
          rh_higro_06: "Kelembaban thermohigrograph jam 06:00",
          rh_higro_07: "Kelembaban thermohigrograph jam 07:00",
          rh_higro_08: "Kelembaban thermohigrograph jam 08:00",
          rh_higro_09: "Kelembaban thermohigrograph jam 09:00",
          rh_higro_10: "Kelembaban thermohigrograph jam 10:00",
          rh_higro_11: "Kelembaban thermohigrograph jam 11:00",
          rh_higro_12: "Kelembaban thermohigrograph jam 12:00",
          rh_higro_13: "Kelembaban thermohigrograph jam 13:00",
          rh_higro_14: "Kelembaban thermohigrograph jam 14:00",
          rh_higro_15: "Kelembaban thermohigrograph jam 15:00",
          rh_higro_16: "Kelembaban thermohigrograph jam 16:00",
          rh_higro_17: "Kelembaban thermohigrograph jam 17:00",
          rh_higro_18: "Kelembaban thermohigrograph jam 18:00",
          rh_higro_19: "Kelembaban thermohigrograph jam 19:00",
          rh_higro_20: "Kelembaban thermohigrograph jam 20:00",
          rh_higro_21: "Kelembaban thermohigrograph jam 21:00",
          rh_higro_22: "Kelembaban thermohigrograph jam 22:00",
          rh_higro_23: "Kelembaban thermohigrograph jam 23:00",
          rh_higro_24: "Kelembaban thermohigrograph jam 24:00",
          rh_max_higro: "Kelembaban max dari jam 01:00 sampai 24:00",
          rh_min_higro: "Kelembaban min dari jam 01:00  sampai 24:00",
          rh_avg_higro: "Kelembaban rata2 dari jam 01:00  sampai 24:00",
        },
      },
    //   {
    //     label: "Observer",
    //     attr: {
    //         "observer_id" : "ID observer",
    //         "observer_name" : "Nama observer "
    //         },
    // },
    ];

    return listAttr;
  },

  getAttrMonthly() {
    const listAttrMonthly = [
      {
        group: "Temperatur",
        form: "Thermohigrograph",
        label: "Suhu Udara",
        attr: {
          "t_thermo_01_avg_monthly": "Suhu thermohigrograph jam 01:00 AVG MONTHLY",
          // "t_thermo_01_mod_monthly": "Suhu thermohigrograph jam 01:00 MOD MONTHLY",
          // "t_thermo_01_med_monthly": "Suhu thermohigrograph jam 01:00 MED MONTHLY",
          "t_thermo_01_max_monthly": "Suhu thermohigrograph jam 01:00 MAX MONTHLY",
          "t_thermo_01_min_monthly": "Suhu thermohigrograph jam 01:00 MIN MONTHLY",
          // "t_thermo_01_sum_monthly": "Suhu thermohigrograph jam 01:00 SUM MONTHLY",
          "t_thermo_01_count_monthly": "Suhu thermohigrograph jam 01:00 COUNT MONTHLY",
          "t_thermo_02_avg_monthly": "Suhu thermohigrograph jam 02:00 AVG MONTHLY",
          // "t_thermo_02_mod_monthly": "Suhu thermohigrograph jam 02:00 MOD MONTHLY",
          // "t_thermo_02_med_monthly": "Suhu thermohigrograph jam 02:00 MED MONTHLY",
          "t_thermo_02_max_monthly": "Suhu thermohigrograph jam 02:00 MAX MONTHLY",
          "t_thermo_02_min_monthly": "Suhu thermohigrograph jam 02:00 MIN MONTHLY",
          // "t_thermo_02_sum_monthly": "Suhu thermohigrograph jam 02:00 SUM MONTHLY",
          "t_thermo_02_count_monthly": "Suhu thermohigrograph jam 02:00 COUNT MONTHLY",
          "t_thermo_03_avg_monthly": "Suhu thermohigrograph jam 03:00 AVG MONTHLY",
          // "t_thermo_03_mod_monthly": "Suhu thermohigrograph jam 03:00 MOD MONTHLY",
          // "t_thermo_03_med_monthly": "Suhu thermohigrograph jam 03:00 MED MONTHLY",
          "t_thermo_03_max_monthly": "Suhu thermohigrograph jam 03:00 MAX MONTHLY",
          "t_thermo_03_min_monthly": "Suhu thermohigrograph jam 03:00 MIN MONTHLY",
          // "t_thermo_03_sum_monthly": "Suhu thermohigrograph jam 03:00 SUM MONTHLY",
          "t_thermo_03_count_monthly": "Suhu thermohigrograph jam 03:00 COUNT MONTHLY",
          "t_thermo_04_avg_monthly": "Suhu thermohigrograph jam 04:00 AVG MONTHLY",
          // "t_thermo_04_mod_monthly": "Suhu thermohigrograph jam 04:00 MOD MONTHLY",
          // "t_thermo_04_med_monthly": "Suhu thermohigrograph jam 04:00 MED MONTHLY",
          "t_thermo_04_max_monthly": "Suhu thermohigrograph jam 04:00 MAX MONTHLY",
          "t_thermo_04_min_monthly": "Suhu thermohigrograph jam 04:00 MIN MONTHLY",
          // "t_thermo_04_sum_monthly": "Suhu thermohigrograph jam 04:00 SUM MONTHLY",
          "t_thermo_04_count_monthly": "Suhu thermohigrograph jam 04:00 COUNT MONTHLY",
          "t_thermo_05_avg_monthly": "Suhu thermohigrograph jam 05:00 AVG MONTHLY",
          // "t_thermo_05_mod_monthly": "Suhu thermohigrograph jam 05:00 MOD MONTHLY",
          // "t_thermo_05_med_monthly": "Suhu thermohigrograph jam 05:00 MED MONTHLY",
          "t_thermo_05_max_monthly": "Suhu thermohigrograph jam 05:00 MAX MONTHLY",
          "t_thermo_05_min_monthly": "Suhu thermohigrograph jam 05:00 MIN MONTHLY",
          "t_thermo_05_sum_monthly": "Suhu thermohigrograph jam 05:00 SUM MONTHLY",
          "t_thermo_05_count_monthly": "Suhu thermohigrograph jam 05:00 COUNT MONTHLY",
          "t_thermo_06_avg_monthly": "Suhu thermohigrograph jam 06:00 AVG MONTHLY",
          // "t_thermo_06_mod_monthly": "Suhu thermohigrograph jam 06:00 MOD MONTHLY",
          // "t_thermo_06_med_monthly": "Suhu thermohigrograph jam 06:00 MED MONTHLY",
          "t_thermo_06_max_monthly": "Suhu thermohigrograph jam 06:00 MAX MONTHLY",
          "t_thermo_06_min_monthly": "Suhu thermohigrograph jam 06:00 MIN MONTHLY",
          // "t_thermo_06_sum_monthly": "Suhu thermohigrograph jam 06:00 SUM MONTHLY",
          "t_thermo_06_count_monthly": "Suhu thermohigrograph jam 06:00 COUNT MONTHLY",
          "t_thermo_07_avg_monthly": "Suhu thermohigrograph jam 07:00 AVG MONTHLY",
          // "t_thermo_07_mod_monthly": "Suhu thermohigrograph jam 07:00 MOD MONTHLY",
          // "t_thermo_07_med_monthly": "Suhu thermohigrograph jam 07:00 MED MONTHLY",
          "t_thermo_07_max_monthly": "Suhu thermohigrograph jam 07:00 MAX MONTHLY",
          "t_thermo_07_min_monthly": "Suhu thermohigrograph jam 07:00 MIN MONTHLY",
          // "t_thermo_07_sum_monthly": "Suhu thermohigrograph jam 07:00 SUM MONTHLY",
          "t_thermo_07_count_monthly": "Suhu thermohigrograph jam 07:00 COUNT MONTHLY",
          "t_thermo_08_avg_monthly": "Suhu thermohigrograph jam 08:00 AVG MONTHLY",
          // "t_thermo_08_mod_monthly": "Suhu thermohigrograph jam 08:00 MOD MONTHLY",
          // "t_thermo_08_med_monthly": "Suhu thermohigrograph jam 08:00 MED MONTHLY",
          "t_thermo_08_max_monthly": "Suhu thermohigrograph jam 08:00 MAX MONTHLY",
          "t_thermo_08_min_monthly": "Suhu thermohigrograph jam 08:00 MIN MONTHLY",
          // "t_thermo_08_sum_monthly": "Suhu thermohigrograph jam 08:00 SUM MONTHLY",
          "t_thermo_08_count_monthly": "Suhu thermohigrograph jam 08:00 COUNT MONTHLY",
          "t_thermo_09_avg_monthly": "Suhu thermohigrograph jam 09:00 AVG MONTHLY",
          // "t_thermo_09_mod_monthly": "Suhu thermohigrograph jam 09:00 MOD MONTHLY",
          // "t_thermo_09_med_monthly": "Suhu thermohigrograph jam 09:00 MED MONTHLY",
          "t_thermo_09_max_monthly": "Suhu thermohigrograph jam 09:00 MAX MONTHLY",
          "t_thermo_09_min_monthly": "Suhu thermohigrograph jam 09:00 MIN MONTHLY",
          // "t_thermo_09_sum_monthly": "Suhu thermohigrograph jam 09:00 SUM MONTHLY",
          "t_thermo_09_count_monthly": "Suhu thermohigrograph jam 09:00 COUNT MONTHLY",
          "t_thermo_10_avg_monthly": "Suhu thermohigrograph jam 10:00 AVG MONTHLY",
          // "t_thermo_10_mod_monthly": "Suhu thermohigrograph jam 10:00 MOD MONTHLY",
          // "t_thermo_10_med_monthly": "Suhu thermohigrograph jam 10:00 MED MONTHLY",
          "t_thermo_10_max_monthly": "Suhu thermohigrograph jam 10:00 MAX MONTHLY",
          "t_thermo_10_min_monthly": "Suhu thermohigrograph jam 10:00 MIN MONTHLY",
          // "t_thermo_10_sum_monthly": "Suhu thermohigrograph jam 10:00 SUM MONTHLY",
          "t_thermo_10_count_monthly": "Suhu thermohigrograph jam 10:00 COUNT MONTHLY",
          "t_thermo_11_avg_monthly": "Suhu thermohigrograph jam 11:00 AVG MONTHLY",
          // "t_thermo_11_mod_monthly": "Suhu thermohigrograph jam 11:00 MOD MONTHLY",
          // "t_thermo_11_med_monthly": "Suhu thermohigrograph jam 11:00 MED MONTHLY",
          "t_thermo_11_max_monthly": "Suhu thermohigrograph jam 11:00 MAX MONTHLY",
          "t_thermo_11_min_monthly": "Suhu thermohigrograph jam 11:00 MIN MONTHLY",
          // "t_thermo_11_sum_monthly": "Suhu thermohigrograph jam 11:00 SUM MONTHLY",
          "t_thermo_11_count_monthly": "Suhu thermohigrograph jam 11:00 COUNT MONTHLY",
          "t_thermo_12_avg_monthly": "Suhu thermohigrograph jam 12:00 AVG MONTHLY",
          // "t_thermo_12_mod_monthly": "Suhu thermohigrograph jam 12:00 MOD MONTHLY",
          // "t_thermo_12_med_monthly": "Suhu thermohigrograph jam 12:00 MED MONTHLY",
          "t_thermo_12_max_monthly": "Suhu thermohigrograph jam 12:00 MAX MONTHLY",
          "t_thermo_12_min_monthly": "Suhu thermohigrograph jam 12:00 MIN MONTHLY",
          // "t_thermo_12_sum_monthly": "Suhu thermohigrograph jam 12:00 SUM MONTHLY",
          "t_thermo_12_count_monthly": "Suhu thermohigrograph jam 12:00 COUNT MONTHLY",
          "t_thermo_13_avg_monthly": "Suhu thermohigrograph jam 13:00 AVG MONTHLY",
          // "t_thermo_13_mod_monthly": "Suhu thermohigrograph jam 13:00 MOD MONTHLY",
          // "t_thermo_13_med_monthly": "Suhu thermohigrograph jam 13:00 MED MONTHLY",
          "t_thermo_13_max_monthly": "Suhu thermohigrograph jam 13:00 MAX MONTHLY",
          "t_thermo_13_min_monthly": "Suhu thermohigrograph jam 13:00 MIN MONTHLY",
          // "t_thermo_13_sum_monthly": "Suhu thermohigrograph jam 13:00 SUM MONTHLY",
          "t_thermo_13_count_monthly": "Suhu thermohigrograph jam 13:00 COUNT MONTHLY",
          "t_thermo_14_avg_monthly": "Suhu thermohigrograph jam 14:00 AVG MONTHLY",
          // "t_thermo_14_mod_monthly": "Suhu thermohigrograph jam 14:00 MOD MONTHLY",
          // "t_thermo_14_med_monthly": "Suhu thermohigrograph jam 14:00 MED MONTHLY",
          "t_thermo_14_max_monthly": "Suhu thermohigrograph jam 14:00 MAX MONTHLY",
          "t_thermo_14_min_monthly": "Suhu thermohigrograph jam 14:00 MIN MONTHLY",
          // "t_thermo_14_sum_monthly": "Suhu thermohigrograph jam 14:00 SUM MONTHLY",
          "t_thermo_14_count_monthly": "Suhu thermohigrograph jam 14:00 COUNT MONTHLY",
          "t_thermo_15_avg_monthly": "Suhu thermohigrograph jam 15:00 AVG MONTHLY",
          // "t_thermo_15_mod_monthly": "Suhu thermohigrograph jam 15:00 MOD MONTHLY",
          // "t_thermo_15_med_monthly": "Suhu thermohigrograph jam 15:00 MED MONTHLY",
          "t_thermo_15_max_monthly": "Suhu thermohigrograph jam 15:00 MAX MONTHLY",
          "t_thermo_15_min_monthly": "Suhu thermohigrograph jam 15:00 MIN MONTHLY",
          // "t_thermo_15_sum_monthly": "Suhu thermohigrograph jam 15:00 SUM MONTHLY",
          "t_thermo_15_count_monthly": "Suhu thermohigrograph jam 15:00 COUNT MONTHLY",
          "t_thermo_16_avg_monthly": "Suhu thermohigrograph jam 16:00 AVG MONTHLY",
          // "t_thermo_16_mod_monthly": "Suhu thermohigrograph jam 16:00 MOD MONTHLY",
          // "t_thermo_16_med_monthly": "Suhu thermohigrograph jam 16:00 MED MONTHLY",
          "t_thermo_16_max_monthly": "Suhu thermohigrograph jam 16:00 MAX MONTHLY",
          "t_thermo_16_min_monthly": "Suhu thermohigrograph jam 16:00 MIN MONTHLY",
          // "t_thermo_16_sum_monthly": "Suhu thermohigrograph jam 16:00 SUM MONTHLY",
          "t_thermo_16_count_monthly": "Suhu thermohigrograph jam 16:00 COUNT MONTHLY",
          "t_thermo_17_avg_monthly": "Suhu thermohigrograph jam 17:00 AVG MONTHLY",
          // "t_thermo_17_mod_monthly": "Suhu thermohigrograph jam 17:00 MOD MONTHLY",
          // "t_thermo_17_med_monthly": "Suhu thermohigrograph jam 17:00 MED MONTHLY",
          "t_thermo_17_max_monthly": "Suhu thermohigrograph jam 17:00 MAX MONTHLY",
          "t_thermo_17_min_monthly": "Suhu thermohigrograph jam 17:00 MIN MONTHLY",
          // "t_thermo_17_sum_monthly": "Suhu thermohigrograph jam 17:00 SUM MONTHLY",
          "t_thermo_17_count_monthly": "Suhu thermohigrograph jam 17:00 COUNT MONTHLY",
          "t_thermo_18_avg_monthly": "Suhu thermohigrograph jam 18:00 AVG MONTHLY",
          // "t_thermo_18_mod_monthly": "Suhu thermohigrograph jam 18:00 MOD MONTHLY",
          // "t_thermo_18_med_monthly": "Suhu thermohigrograph jam 18:00 MED MONTHLY",
          "t_thermo_18_max_monthly": "Suhu thermohigrograph jam 18:00 MAX MONTHLY",
          "t_thermo_18_min_monthly": "Suhu thermohigrograph jam 18:00 MIN MONTHLY",
          // "t_thermo_18_sum_monthly": "Suhu thermohigrograph jam 18:00 SUM MONTHLY",
          "t_thermo_18_count_monthly": "Suhu thermohigrograph jam 18:00 COUNT MONTHLY",
          "t_thermo_19_avg_monthly": "Suhu thermohigrograph jam 19:00 AVG MONTHLY",
          // "t_thermo_19_mod_monthly": "Suhu thermohigrograph jam 19:00 MOD MONTHLY",
          // "t_thermo_19_med_monthly": "Suhu thermohigrograph jam 19:00 MED MONTHLY",
          "t_thermo_19_max_monthly": "Suhu thermohigrograph jam 19:00 MAX MONTHLY",
          "t_thermo_19_min_monthly": "Suhu thermohigrograph jam 19:00 MIN MONTHLY",
          // "t_thermo_19_sum_monthly": "Suhu thermohigrograph jam 19:00 SUM MONTHLY",
          "t_thermo_19_count_monthly": "Suhu thermohigrograph jam 19:00 COUNT MONTHLY",
          "t_thermo_20_avg_monthly": "Suhu thermohigrograph jam 20:00 AVG MONTHLY",
          // "t_thermo_20_mod_monthly": "Suhu thermohigrograph jam 20:00 MOD MONTHLY",
          // "t_thermo_20_med_monthly": "Suhu thermohigrograph jam 20:00 MED MONTHLY",
          "t_thermo_20_max_monthly": "Suhu thermohigrograph jam 20:00 MAX MONTHLY",
          "t_thermo_20_min_monthly": "Suhu thermohigrograph jam 20:00 MIN MONTHLY",
          // "t_thermo_20_sum_monthly": "Suhu thermohigrograph jam 20:00 SUM MONTHLY",
          "t_thermo_20_count_monthly": "Suhu thermohigrograph jam 20:00 COUNT MONTHLY",
          "t_thermo_21_avg_monthly": "Suhu thermohigrograph jam 21:00 AVG MONTHLY",
          // "t_thermo_21_mod_monthly": "Suhu thermohigrograph jam 21:00 MOD MONTHLY",
          // "t_thermo_21_med_monthly": "Suhu thermohigrograph jam 21:00 MED MONTHLY",
          "t_thermo_21_max_monthly": "Suhu thermohigrograph jam 21:00 MAX MONTHLY",
          "t_thermo_21_min_monthly": "Suhu thermohigrograph jam 21:00 MIN MONTHLY",
          // "t_thermo_21_sum_monthly": "Suhu thermohigrograph jam 21:00 SUM MONTHLY",
          "t_thermo_21_count_monthly": "Suhu thermohigrograph jam 21:00 COUNT MONTHLY",
          "t_thermo_22_avg_monthly": "Suhu thermohigrograph jam 22:00 AVG MONTHLY",
          // "t_thermo_22_mod_monthly": "Suhu thermohigrograph jam 22:00 MOD MONTHLY",
          // "t_thermo_22_med_monthly": "Suhu thermohigrograph jam 22:00 MED MONTHLY",
          "t_thermo_22_max_monthly": "Suhu thermohigrograph jam 22:00 MAX MONTHLY",
          "t_thermo_22_min_monthly": "Suhu thermohigrograph jam 22:00 MIN MONTHLY",
          // "t_thermo_22_sum_monthly": "Suhu thermohigrograph jam 22:00 SUM MONTHLY",
          "t_thermo_22_count_monthly": "Suhu thermohigrograph jam 22:00 COUNT MONTHLY",
          "t_thermo_23_avg_monthly": "Suhu thermohigrograph jam 23:00 AVG MONTHLY",
          // "t_thermo_23_mod_monthly": "Suhu thermohigrograph jam 23:00 MOD MONTHLY",
          // "t_thermo_23_med_monthly": "Suhu thermohigrograph jam 23:00 MED MONTHLY",
          "t_thermo_23_max_monthly": "Suhu thermohigrograph jam 23:00 MAX MONTHLY",
          "t_thermo_23_min_monthly": "Suhu thermohigrograph jam 23:00 MIN MONTHLY",
          // "t_thermo_23_sum_monthly": "Suhu thermohigrograph jam 23:00 SUM MONTHLY",
          "t_thermo_23_count_monthly": "Suhu thermohigrograph jam 23:00 COUNT MONTHLY",
          "t_thermo_24_avg_monthly": "Suhu thermohigrograph jam 24:00 AVG MONTHLY",
          // "t_thermo_24_mod_monthly": "Suhu thermohigrograph jam 24:00 MOD MONTHLY",
          // "t_thermo_24_med_monthly": "Suhu thermohigrograph jam 24:00 MED MONTHLY",
          "t_thermo_24_max_monthly": "Suhu thermohigrograph jam 24:00 MAX MONTHLY",
          "t_thermo_24_min_monthly": "Suhu thermohigrograph jam 24:00 MIN MONTHLY",
          // "t_thermo_24_sum_monthly": "Suhu thermohigrograph jam 24:00 SUM MONTHLY",
          "t_thermo_24_count_monthly": "Suhu thermohigrograph jam 24:00 COUNT MONTHLY",
          "tx_thermo_avg_monthly": "Suhu max dari jam 01:00 sampai 24:00 AVG MONTHLY",
          // "tx_thermo_mod_monthly": "Suhu max dari jam 01:00 sampai 24:00 MOD MONTHLY",
          // "tx_thermo_med_monthly": "Suhu max dari jam 01:00 sampai 24:00 MED MONTHLY",
          "tx_thermo_max_monthly": "Suhu max dari jam 01:00 sampai 24:00 MAX MONTHLY",
          "tx_thermo_min_monthly": "Suhu max dari jam 01:00 sampai 24:00 MIN MONTHLY",
          // "tx_thermo_sum_monthly": "Suhu max dari jam 01:00 sampai 24:00 SUM MONTHLY",
          "tx_thermo_count_monthly": "Suhu max dari jam 01:00 sampai 24:00 COUNT MONTHLY",
          "tn_thermo_avg_monthly": "Suhu min dari jam 01:00 sampai 24:00 AVG MONTHLY",
          // "tn_thermo_mod_monthly": "Suhu min dari jam 01:00 sampai 24:00 MOD MONTHLY",
          // "tn_thermo_med_monthly": "Suhu min dari jam 01:00 sampai 24:00 MED MONTHLY",
          "tn_thermo_max_monthly": "Suhu min dari jam 01:00 sampai 24:00 MAX MONTHLY",
          "tn_thermo_min_monthly": "Suhu min dari jam 01:00 sampai 24:00 MIN MONTHLY",
          // "tn_thermo_sum_monthly": "Suhu min dari jam 01:00 sampai 24:00 SUM MONTHLY",
          "tn_thermo_count_monthly": "Suhu min dari jam 01:00 sampai 24:00 COUNT MONTHLY",
          "t_avg_thermo_avg_monthly": "Suhu rata2 dari jam 01:00  sampai 24:00 AVG MONTHLY",
          // "t_avg_thermo_mod_monthly": "Suhu rata2 dari jam 01:00  sampai 24:00 MOD MONTHLY",
          // "t_avg_thermo_med_monthly": "Suhu rata2 dari jam 01:00  sampai 24:00 MED MONTHLY",
          "t_avg_thermo_max_monthly": "Suhu rata2 dari jam 01:00  sampai 24:00 MAX MONTHLY",
          "t_avg_thermo_min_monthly": "Suhu rata2 dari jam 01:00  sampai 24:00 MIN MONTHLY",
          // "t_avg_thermo_sum_monthly": "Suhu rata2 dari jam 01:00  sampai 24:00 SUM MONTHLY",
          "t_avg_thermo_count_monthly": "Suhu rata2 dari jam 01:00  sampai 24:00 COUNT MONTHLY"
        },
      },
      {
        group: "Kelembaban",
        form: "Thermohigrograph",
        label: "Kelembaban Udara",
        attr: {
          "rh_higro_01_avg_monthly": "Kelembaban thermohigrograph jam 01:00 AVG MONTHLY",
          // "rh_higro_01_mod_monthly": "Kelembaban thermohigrograph jam 01:00 MOD MONTHLY",
          // "rh_higro_01_med_monthly": "Kelembaban thermohigrograph jam 01:00 MED MONTHLY",
          "rh_higro_01_max_monthly": "Kelembaban thermohigrograph jam 01:00 MAX MONTHLY",
          "rh_higro_01_min_monthly": "Kelembaban thermohigrograph jam 01:00 MIN MONTHLY",
          // "rh_higro_01_sum_monthly": "Kelembaban thermohigrograph jam 01:00 SUM MONTHLY",
          "rh_higro_01_count_monthly": "Kelembaban thermohigrograph jam 01:00 COUNT MONTHLY",
          "rh_higro_02_avg_monthly": "Kelembaban thermohigrograph jam 02:00 AVG MONTHLY",
          // "rh_higro_02_mod_monthly": "Kelembaban thermohigrograph jam 02:00 MOD MONTHLY",
          // "rh_higro_02_med_monthly": "Kelembaban thermohigrograph jam 02:00 MED MONTHLY",
          "rh_higro_02_max_monthly": "Kelembaban thermohigrograph jam 02:00 MAX MONTHLY",
          "rh_higro_02_min_monthly": "Kelembaban thermohigrograph jam 02:00 MIN MONTHLY",
          // "rh_higro_02_sum_monthly": "Kelembaban thermohigrograph jam 02:00 SUM MONTHLY",
          "rh_higro_02_count_monthly": "Kelembaban thermohigrograph jam 02:00 COUNT MONTHLY",
          "rh_higro_03_avg_monthly": "Kelembaban thermohigrograph jam 03:00 AVG MONTHLY",
          // "rh_higro_03_mod_monthly": "Kelembaban thermohigrograph jam 03:00 MOD MONTHLY",
          // "rh_higro_03_med_monthly": "Kelembaban thermohigrograph jam 03:00 MED MONTHLY",
          "rh_higro_03_max_monthly": "Kelembaban thermohigrograph jam 03:00 MAX MONTHLY",
          "rh_higro_03_min_monthly": "Kelembaban thermohigrograph jam 03:00 MIN MONTHLY",
          // "rh_higro_03_sum_monthly": "Kelembaban thermohigrograph jam 03:00 SUM MONTHLY",
          "rh_higro_03_count_monthly": "Kelembaban thermohigrograph jam 03:00 COUNT MONTHLY",
          "rh_higro_04_avg_monthly": "Kelembaban thermohigrograph jam 04:00 AVG MONTHLY",
          // "rh_higro_04_mod_monthly": "Kelembaban thermohigrograph jam 04:00 MOD MONTHLY",
          // "rh_higro_04_med_monthly": "Kelembaban thermohigrograph jam 04:00 MED MONTHLY",
          "rh_higro_04_max_monthly": "Kelembaban thermohigrograph jam 04:00 MAX MONTHLY",
          "rh_higro_04_min_monthly": "Kelembaban thermohigrograph jam 04:00 MIN MONTHLY",
          // "rh_higro_04_sum_monthly": "Kelembaban thermohigrograph jam 04:00 SUM MONTHLY",
          "rh_higro_04_count_monthly": "Kelembaban thermohigrograph jam 04:00 COUNT MONTHLY",
          "rh_higro_05_avg_monthly": "Kelembaban thermohigrograph jam 05:00 AVG MONTHLY",
          // "rh_higro_05_mod_monthly": "Kelembaban thermohigrograph jam 05:00 MOD MONTHLY",
          // "rh_higro_05_med_monthly": "Kelembaban thermohigrograph jam 05:00 MED MONTHLY",
          "rh_higro_05_max_monthly": "Kelembaban thermohigrograph jam 05:00 MAX MONTHLY",
          "rh_higro_05_min_monthly": "Kelembaban thermohigrograph jam 05:00 MIN MONTHLY",
          // "rh_higro_05_sum_monthly": "Kelembaban thermohigrograph jam 05:00 SUM MONTHLY",
          "rh_higro_05_count_monthly": "Kelembaban thermohigrograph jam 05:00 COUNT MONTHLY",
          "rh_higro_06_avg_monthly": "Kelembaban thermohigrograph jam 06:00 AVG MONTHLY",
          // "rh_higro_06_mod_monthly": "Kelembaban thermohigrograph jam 06:00 MOD MONTHLY",
          // "rh_higro_06_med_monthly": "Kelembaban thermohigrograph jam 06:00 MED MONTHLY",
          "rh_higro_06_max_monthly": "Kelembaban thermohigrograph jam 06:00 MAX MONTHLY",
          "rh_higro_06_min_monthly": "Kelembaban thermohigrograph jam 06:00 MIN MONTHLY",
          // "rh_higro_06_sum_monthly": "Kelembaban thermohigrograph jam 06:00 SUM MONTHLY",
          "rh_higro_06_count_monthly": "Kelembaban thermohigrograph jam 06:00 COUNT MONTHLY",
          "rh_higro_07_avg_monthly": "Kelembaban thermohigrograph jam 07:00 AVG MONTHLY",
          // "rh_higro_07_mod_monthly": "Kelembaban thermohigrograph jam 07:00 MOD MONTHLY",
          // "rh_higro_07_med_monthly": "Kelembaban thermohigrograph jam 07:00 MED MONTHLY",
          "rh_higro_07_max_monthly": "Kelembaban thermohigrograph jam 07:00 MAX MONTHLY",
          "rh_higro_07_min_monthly": "Kelembaban thermohigrograph jam 07:00 MIN MONTHLY",
          // "rh_higro_07_sum_monthly": "Kelembaban thermohigrograph jam 07:00 SUM MONTHLY",
          "rh_higro_07_count_monthly": "Kelembaban thermohigrograph jam 07:00 COUNT MONTHLY",
          "rh_higro_08_avg_monthly": "Kelembaban thermohigrograph jam 08:00 AVG MONTHLY",
          // "rh_higro_08_mod_monthly": "Kelembaban thermohigrograph jam 08:00 MOD MONTHLY",
          // "rh_higro_08_med_monthly": "Kelembaban thermohigrograph jam 08:00 MED MONTHLY",
          "rh_higro_08_max_monthly": "Kelembaban thermohigrograph jam 08:00 MAX MONTHLY",
          "rh_higro_08_min_monthly": "Kelembaban thermohigrograph jam 08:00 MIN MONTHLY",
          // "rh_higro_08_sum_monthly": "Kelembaban thermohigrograph jam 08:00 SUM MONTHLY",
          "rh_higro_08_count_monthly": "Kelembaban thermohigrograph jam 08:00 COUNT MONTHLY",
          "rh_higro_09_avg_monthly": "Kelembaban thermohigrograph jam 09:00 AVG MONTHLY",
          // "rh_higro_09_mod_monthly": "Kelembaban thermohigrograph jam 09:00 MOD MONTHLY",
          // "rh_higro_09_med_monthly": "Kelembaban thermohigrograph jam 09:00 MED MONTHLY",
          "rh_higro_09_max_monthly": "Kelembaban thermohigrograph jam 09:00 MAX MONTHLY",
          "rh_higro_09_min_monthly": "Kelembaban thermohigrograph jam 09:00 MIN MONTHLY",
          // "rh_higro_09_sum_monthly": "Kelembaban thermohigrograph jam 09:00 SUM MONTHLY",
          "rh_higro_09_count_monthly": "Kelembaban thermohigrograph jam 09:00 COUNT MONTHLY",
          "rh_higro_10_avg_monthly": "Kelembaban thermohigrograph jam 10:00 AVG MONTHLY",
          // "rh_higro_10_mod_monthly": "Kelembaban thermohigrograph jam 10:00 MOD MONTHLY",
          // "rh_higro_10_med_monthly": "Kelembaban thermohigrograph jam 10:00 MED MONTHLY",
          "rh_higro_10_max_monthly": "Kelembaban thermohigrograph jam 10:00 MAX MONTHLY",
          "rh_higro_10_min_monthly": "Kelembaban thermohigrograph jam 10:00 MIN MONTHLY",
          // "rh_higro_10_sum_monthly": "Kelembaban thermohigrograph jam 10:00 SUM MONTHLY",
          "rh_higro_10_count_monthly": "Kelembaban thermohigrograph jam 10:00 COUNT MONTHLY",
          "rh_higro_11_avg_monthly": "Kelembaban thermohigrograph jam 11:00 AVG MONTHLY",
          // "rh_higro_11_mod_monthly": "Kelembaban thermohigrograph jam 11:00 MOD MONTHLY",
          // "rh_higro_11_med_monthly": "Kelembaban thermohigrograph jam 11:00 MED MONTHLY",
          "rh_higro_11_max_monthly": "Kelembaban thermohigrograph jam 11:00 MAX MONTHLY",
          "rh_higro_11_min_monthly": "Kelembaban thermohigrograph jam 11:00 MIN MONTHLY",
          // "rh_higro_11_sum_monthly": "Kelembaban thermohigrograph jam 11:00 SUM MONTHLY",
          "rh_higro_11_count_monthly": "Kelembaban thermohigrograph jam 11:00 COUNT MONTHLY",
          "rh_higro_12_avg_monthly": "Kelembaban thermohigrograph jam 12:00 AVG MONTHLY",
          // "rh_higro_12_mod_monthly": "Kelembaban thermohigrograph jam 12:00 MOD MONTHLY",
          // "rh_higro_12_med_monthly": "Kelembaban thermohigrograph jam 12:00 MED MONTHLY",
          "rh_higro_12_max_monthly": "Kelembaban thermohigrograph jam 12:00 MAX MONTHLY",
          "rh_higro_12_min_monthly": "Kelembaban thermohigrograph jam 12:00 MIN MONTHLY",
          // "rh_higro_12_sum_monthly": "Kelembaban thermohigrograph jam 12:00 SUM MONTHLY",
          "rh_higro_12_count_monthly": "Kelembaban thermohigrograph jam 12:00 COUNT MONTHLY",
          "rh_higro_13_avg_monthly": "Kelembaban thermohigrograph jam 13:00 AVG MONTHLY",
          // "rh_higro_13_mod_monthly": "Kelembaban thermohigrograph jam 13:00 MOD MONTHLY",
          // "rh_higro_13_med_monthly": "Kelembaban thermohigrograph jam 13:00 MED MONTHLY",
          "rh_higro_13_max_monthly": "Kelembaban thermohigrograph jam 13:00 MAX MONTHLY",
          "rh_higro_13_min_monthly": "Kelembaban thermohigrograph jam 13:00 MIN MONTHLY",
          // "rh_higro_13_sum_monthly": "Kelembaban thermohigrograph jam 13:00 SUM MONTHLY",
          "rh_higro_13_count_monthly": "Kelembaban thermohigrograph jam 13:00 COUNT MONTHLY",
          "rh_higro_14_avg_monthly": "Kelembaban thermohigrograph jam 14:00 AVG MONTHLY",
          // "rh_higro_14_mod_monthly": "Kelembaban thermohigrograph jam 14:00 MOD MONTHLY",
          // "rh_higro_14_med_monthly": "Kelembaban thermohigrograph jam 14:00 MED MONTHLY",
          "rh_higro_14_max_monthly": "Kelembaban thermohigrograph jam 14:00 MAX MONTHLY",
          "rh_higro_14_min_monthly": "Kelembaban thermohigrograph jam 14:00 MIN MONTHLY",
          // "rh_higro_14_sum_monthly": "Kelembaban thermohigrograph jam 14:00 SUM MONTHLY",
          "rh_higro_14_count_monthly": "Kelembaban thermohigrograph jam 14:00 COUNT MONTHLY",
          "rh_higro_15_avg_monthly": "Kelembaban thermohigrograph jam 15:00 AVG MONTHLY",
          // "rh_higro_15_mod_monthly": "Kelembaban thermohigrograph jam 15:00 MOD MONTHLY",
          // "rh_higro_15_med_monthly": "Kelembaban thermohigrograph jam 15:00 MED MONTHLY",
          "rh_higro_15_max_monthly": "Kelembaban thermohigrograph jam 15:00 MAX MONTHLY",
          "rh_higro_15_min_monthly": "Kelembaban thermohigrograph jam 15:00 MIN MONTHLY",
          // "rh_higro_15_sum_monthly": "Kelembaban thermohigrograph jam 15:00 SUM MONTHLY",
          "rh_higro_15_count_monthly": "Kelembaban thermohigrograph jam 15:00 COUNT MONTHLY",
          "rh_higro_16_avg_monthly": "Kelembaban thermohigrograph jam 16:00 AVG MONTHLY",
          // "rh_higro_16_mod_monthly": "Kelembaban thermohigrograph jam 16:00 MOD MONTHLY",
          // "rh_higro_16_med_monthly": "Kelembaban thermohigrograph jam 16:00 MED MONTHLY",
          "rh_higro_16_max_monthly": "Kelembaban thermohigrograph jam 16:00 MAX MONTHLY",
          "rh_higro_16_min_monthly": "Kelembaban thermohigrograph jam 16:00 MIN MONTHLY",
          // "rh_higro_16_sum_monthly": "Kelembaban thermohigrograph jam 16:00 SUM MONTHLY",
          "rh_higro_16_count_monthly": "Kelembaban thermohigrograph jam 16:00 COUNT MONTHLY",
          "rh_higro_17_avg_monthly": "Kelembaban thermohigrograph jam 17:00 AVG MONTHLY",
          // "rh_higro_17_mod_monthly": "Kelembaban thermohigrograph jam 17:00 MOD MONTHLY",
          // "rh_higro_17_med_monthly": "Kelembaban thermohigrograph jam 17:00 MED MONTHLY",
          "rh_higro_17_max_monthly": "Kelembaban thermohigrograph jam 17:00 MAX MONTHLY",
          "rh_higro_17_min_monthly": "Kelembaban thermohigrograph jam 17:00 MIN MONTHLY",
          // "rh_higro_17_sum_monthly": "Kelembaban thermohigrograph jam 17:00 SUM MONTHLY",
          "rh_higro_17_count_monthly": "Kelembaban thermohigrograph jam 17:00 COUNT MONTHLY",
          "rh_higro_18_avg_monthly": "Kelembaban thermohigrograph jam 18:00 AVG MONTHLY",
          // "rh_higro_18_mod_monthly": "Kelembaban thermohigrograph jam 18:00 MOD MONTHLY",
          // "rh_higro_18_med_monthly": "Kelembaban thermohigrograph jam 18:00 MED MONTHLY",
          "rh_higro_18_max_monthly": "Kelembaban thermohigrograph jam 18:00 MAX MONTHLY",
          "rh_higro_18_min_monthly": "Kelembaban thermohigrograph jam 18:00 MIN MONTHLY",
          // "rh_higro_18_sum_monthly": "Kelembaban thermohigrograph jam 18:00 SUM MONTHLY",
          "rh_higro_18_count_monthly": "Kelembaban thermohigrograph jam 18:00 COUNT MONTHLY",
          "rh_higro_19_avg_monthly": "Kelembaban thermohigrograph jam 19:00 AVG MONTHLY",
          // "rh_higro_19_mod_monthly": "Kelembaban thermohigrograph jam 19:00 MOD MONTHLY",
          // "rh_higro_19_med_monthly": "Kelembaban thermohigrograph jam 19:00 MED MONTHLY",
          "rh_higro_19_max_monthly": "Kelembaban thermohigrograph jam 19:00 MAX MONTHLY",
          "rh_higro_19_min_monthly": "Kelembaban thermohigrograph jam 19:00 MIN MONTHLY",
          // "rh_higro_19_sum_monthly": "Kelembaban thermohigrograph jam 19:00 SUM MONTHLY",
          "rh_higro_19_count_monthly": "Kelembaban thermohigrograph jam 19:00 COUNT MONTHLY",
          "rh_higro_20_avg_monthly": "Kelembaban thermohigrograph jam 20:00 AVG MONTHLY",
          // "rh_higro_20_mod_monthly": "Kelembaban thermohigrograph jam 20:00 MOD MONTHLY",
          // "rh_higro_20_med_monthly": "Kelembaban thermohigrograph jam 20:00 MED MONTHLY",
          "rh_higro_20_max_monthly": "Kelembaban thermohigrograph jam 20:00 MAX MONTHLY",
          "rh_higro_20_min_monthly": "Kelembaban thermohigrograph jam 20:00 MIN MONTHLY",
          // "rh_higro_20_sum_monthly": "Kelembaban thermohigrograph jam 20:00 SUM MONTHLY",
          "rh_higro_20_count_monthly": "Kelembaban thermohigrograph jam 20:00 COUNT MONTHLY",
          "rh_higro_21_avg_monthly": "Kelembaban thermohigrograph jam 21:00 AVG MONTHLY",
          // "rh_higro_21_mod_monthly": "Kelembaban thermohigrograph jam 2:00 MOD MONTHLY",
          // "rh_higro_21_med_monthly": "Kelembaban thermohigrograph jam 2:00 MED MONTHLY",
          "rh_higro_21_max_monthly": "Kelembaban thermohigrograph jam 21:00 MAX MONTHLY",
          "rh_higro_21_min_monthly": "Kelembaban thermohigrograph jam 21:00 MIN MONTHLY",
          // "rh_higro_21_sum_monthly": "Kelembaban thermohigrograph jam 2:00 SUM MONTHLY",
          "rh_higro_21_count_monthly": "Kelembaban thermohigrograph jam 21:00 COUNT MONTHLY",
          "rh_higro_22_avg_monthly": "Kelembaban thermohigrograph jam 22:00 AVG MONTHLY",
          // "rh_higro_22_mod_monthly": "Kelembaban thermohigrograph jam 2:00 MOD MONTHLY",
          // "rh_higro_22_med_monthly": "Kelembaban thermohigrograph jam 2:00 MED MONTHLY",
          "rh_higro_22_max_monthly": "Kelembaban thermohigrograph jam 22:00 MAX MONTHLY",
          "rh_higro_22_min_monthly": "Kelembaban thermohigrograph jam 22:00 MIN MONTHLY",
          // "rh_higro_22_sum_monthly": "Kelembaban thermohigrograph jam 2:00 SUM MONTHLY",
          "rh_higro_22_count_monthly": "Kelembaban thermohigrograph jam 22:00 COUNT MONTHLY",
          "rh_higro_23_avg_monthly": "Kelembaban thermohigrograph jam 22:00 AVG MONTHLY",
          // "rh_higro_23_mod_monthly": "Kelembaban thermohigrograph jam 2:00 MOD MONTHLY",
          // "rh_higro_23_med_monthly": "Kelembaban thermohigrograph jam 2:00 MED MONTHLY",
          "rh_higro_23_max_monthly": "Kelembaban thermohigrograph jam 23:00 MAX MONTHLY",
          "rh_higro_23_min_monthly": "Kelembaban thermohigrograph jam 23:00 MIN MONTHLY",
          // "rh_higro_23_sum_monthly": "Kelembaban thermohigrograph jam 2:00 SUM MONTHLY",
          "rh_higro_23_count_monthly": "Kelembaban thermohigrograph jam 23:00 COUNT MONTHLY",
          "rh_higro_24_avg_monthly": "Kelembaban thermohigrograph jam 24:00 AVG MONTHLY",
          // "rh_higro_24_mod_monthly": "Kelembaban thermohigrograph jam 2:00 MOD MONTHLY",
          // "rh_higro_24_med_monthly": "Kelembaban thermohigrograph jam 2:00 MED MONTHLY",
          "rh_higro_24_max_monthly": "Kelembaban thermohigrograph jam 24:00 MAX MONTHLY",
          "rh_higro_24_min_monthly": "Kelembaban thermohigrograph jam 24:00 MIN MONTHLY",
          // "rh_higro_24_sum_monthly": "Kelembaban thermohigrograph jam 2:00 SUM MONTHLY",
          "rh_higro_24_count_monthly": "Kelembaban thermohigrograph jam 24:00 COUNT MONTHLY",
          "rh_max_higro_avg_monthly": "Kelembaban max dari jam 01:00 sampai 24:00 AVG MONTHLY",
          // "rh_max_higro_mod_monthly": "Kelembaban max dari jam 01:00 sampai 24:00 MOD MONTHLY",
          // "rh_max_higro_med_monthly": "Kelembaban max dari jam 01:00 sampai 24:00 MED MONTHLY",
          "rh_max_higro_max_monthly": "Kelembaban max dari jam 01:00 sampai 24:00 MAX MONTHLY",
          "rh_max_higro_min_monthly": "Kelembaban max dari jam 01:00 sampai 24:00 MIN MONTHLY",
          // "rh_max_higro_sum_monthly": "Kelembaban max dari jam 01:00 sampai 24:00 SUM MONTHLY",
          "rh_max_higro_count_monthly": "Kelembaban max dari jam 01:00 sampai 24:00 COUNT MONTHLY",
          "rh_min_higro_avg_monthly": "Kelembaban min dari jam 01:00 sampai 24:00 AVG MONTHLY",
          // "rh_min_higro_mod_monthly": "Kelembaban min dari jam 01:00 sampai 24:00 MOD MONTHLY",
          // "rh_min_higro_med_monthly": "Kelembaban min dari jam 01:00 sampai 24:00 MED MONTHLY",
          "rh_min_higro_max_monthly": "Kelembaban min dari jam 01:00 sampai 24:00 MAX MONTHLY",
          "rh_min_higro_min_monthly": "Kelembaban min dari jam 01:00 sampai 24:00 MIN MONTHLY",
          // "rh_min_higro_sum_monthly": "Kelembaban min dari jam 01:00 sampai 24:00 SUM MONTHLY",
          "rh_min_higro_count_monthly": "Kelembaban min dari jam 01:00 sampai 24:00 COUNT MONTHLY",
          "rh_avg_higro_avg_monthly": "Kelembaban rata2 dari jam 01:00 sampai 24:00 AVG MONTHLY",
          // "rh_avg_higro_mod_monthly": "Kelembaban rata2 dari jam 01:00 sampai 24:00 MOD MONTHLY",
          // "rh_avg_higro_med_monthly": "Kelembaban rata2 dari jam 01:00 sampai 24:00 MED MONTHLY",
          "rh_avg_higro_max_monthly": "Kelembaban rata2 dari jam 01:00 sampai 24:00 MAX MONTHLY",
          "rh_avg_higro_min_monthly": "Kelembaban rata2 dari jam 01:00 sampai 24:00 MIN MONTHLY",
          // "rh_avg_higro_sum_monthly": "Kelembaban rata2 dari jam 01:00 sampai 24:00 SUM MONTHLY",
          "rh_avg_higro_count_monthly": "Kelembaban rata2 dari jam 01:00 sampai 24:00 COUNT MONTHLY"
        },
      },
    ];

    return listAttrMonthly;
  },

  getAttrMonthlyCsv() {
    const listAttrMonthly = [
      {
        label: "Suhu Udara",
        attr: {
          "t_thermo_01_avg_monthly": "t_thermo_01_monthly_avg_C",
          "t_thermo_01_mod_monthly": "t_thermo_01_monthly_mod_C",
          "t_thermo_01_med_monthly": "t_thermo_01_monthly_med_C",
          "t_thermo_01_max_monthly": "t_thermo_01_monthly_max_C",
          "t_thermo_01_min_monthly": "t_thermo_01_monthly_min_C",
          "t_thermo_01_sum_monthly": "t_thermo_01_monthly_sum_C",
          "t_thermo_01_count_monthly": "c_t_thermo_01_monthly_C",
          "t_thermo_02_avg_monthly": "t_thermo_02_monthly_avg_C",
          "t_thermo_02_mod_monthly": "t_thermo_02_monthly_mod_C",
          "t_thermo_02_med_monthly": "t_thermo_02_monthly_med_C",
          "t_thermo_02_max_monthly": "t_thermo_02_monthly_max_C",
          "t_thermo_02_min_monthly": "t_thermo_02_monthly_min_C",
          "t_thermo_02_sum_monthly": "t_thermo_02_monthly_sum_C",
          "t_thermo_02_count_monthly": "c_t_thermo_02_monthly_C",
          "t_thermo_03_avg_monthly": "t_thermo_03_monthly_avg_C",
          "t_thermo_03_mod_monthly": "t_thermo_03_monthly_mod_C",
          "t_thermo_03_med_monthly": "t_thermo_03_monthly_med_C",
          "t_thermo_03_max_monthly": "t_thermo_03_monthly_max_C",
          "t_thermo_03_min_monthly": "t_thermo_03_monthly_min_C",
          "t_thermo_03_sum_monthly": "t_thermo_03_monthly_sum_C",
          "t_thermo_03_count_monthly": "c_t_thermo_03_monthly_C",
          "t_thermo_04_avg_monthly": "t_thermo_04_monthly_avg_C",
          "t_thermo_04_mod_monthly": "t_thermo_04_monthly_mod_C",
          "t_thermo_04_med_monthly": "t_thermo_04_monthly_med_C",
          "t_thermo_04_max_monthly": "t_thermo_04_monthly_max_C",
          "t_thermo_04_min_monthly": "t_thermo_04_monthly_min_C",
          "t_thermo_04_sum_monthly": "t_thermo_04_monthly_sum_C",
          "t_thermo_04_count_monthly": "c_t_thermo_04_monthly_C",
          "t_thermo_05_avg_monthly": "t_thermo_05_monthly_avg_C",
          "t_thermo_05_mod_monthly": "t_thermo_05_monthly_mod_C",
          "t_thermo_05_med_monthly": "t_thermo_05_monthly_med_C",
          "t_thermo_05_max_monthly": "t_thermo_05_monthly_max_C",
          "t_thermo_05_min_monthly": "t_thermo_05_monthly_min_C",
          "t_thermo_05_sum_monthly": "t_thermo_05_monthly_sum_C",
          "t_thermo_05_count_monthly": "c_t_thermo_05_monthly_C",
          "t_thermo_06_avg_monthly": "t_thermo_06_monthly_avg_C",
          "t_thermo_06_mod_monthly": "t_thermo_06_monthly_mod_C",
          "t_thermo_06_med_monthly": "t_thermo_06_monthly_med_C",
          "t_thermo_06_max_monthly": "t_thermo_06_monthly_max_C",
          "t_thermo_06_min_monthly": "t_thermo_06_monthly_min_C",
          "t_thermo_06_sum_monthly": "t_thermo_06_monthly_sum_C",
          "t_thermo_06_count_monthly": "c_t_thermo_06_monthly_C",
          "t_thermo_07_avg_monthly": "t_thermo_07_monthly_avg_C",
          "t_thermo_07_mod_monthly": "t_thermo_07_monthly_mod_C",
          "t_thermo_07_med_monthly": "t_thermo_07_monthly_med_C",
          "t_thermo_07_max_monthly": "t_thermo_07_monthly_max_C",
          "t_thermo_07_min_monthly": "t_thermo_07_monthly_min_C",
          "t_thermo_07_sum_monthly": "t_thermo_07_monthly_sum_C",
          "t_thermo_07_count_monthly": "c_t_thermo_07_monthly_C",
          "t_thermo_08_avg_monthly": "t_thermo_08_monthly_avg_C",
          "t_thermo_08_mod_monthly": "t_thermo_08_monthly_mod_C",
          "t_thermo_08_med_monthly": "t_thermo_08_monthly_med_C",
          "t_thermo_08_max_monthly": "t_thermo_08_monthly_max_C",
          "t_thermo_08_min_monthly": "t_thermo_08_monthly_min_C",
          "t_thermo_08_sum_monthly": "t_thermo_08_monthly_sum_C",
          "t_thermo_08_count_monthly": "c_t_thermo_08_monthly_C",
          "t_thermo_09_avg_monthly": "t_thermo_09_monthly_avg_C",
          "t_thermo_09_mod_monthly": "t_thermo_09_monthly_mod_C",
          "t_thermo_09_med_monthly": "t_thermo_09_monthly_med_C",
          "t_thermo_09_max_monthly": "t_thermo_09_monthly_max_C",
          "t_thermo_09_min_monthly": "t_thermo_09_monthly_min_C",
          "t_thermo_09_sum_monthly": "t_thermo_09_monthly_sum_C",
          "t_thermo_09_count_monthly": "c_t_thermo_09_monthly_C",
          "t_thermo_10_avg_monthly": "t_thermo_10_monthly_avg_C",
          "t_thermo_10_mod_monthly": "t_thermo_10_monthly_mod_C",
          "t_thermo_10_med_monthly": "t_thermo_10_monthly_med_C",
          "t_thermo_10_max_monthly": "t_thermo_10_monthly_max_C",
          "t_thermo_10_min_monthly": "t_thermo_10_monthly_mn_C",
          "t_thermo_10_sum_monthly": "t_thermo_10_monthly_sum_C",
          "t_thermo_10_count_monthly": "c_t_thermo_10_monthly_C",
          "t_thermo_11_avg_monthly": "t_thermo_11_monthly_avg_C",
          "t_thermo_11_mod_monthly": "t_thermo_11_monthly_mod_C",
          "t_thermo_11_med_monthly": "t_thermo_11_monthly_med_C",
          "t_thermo_11_max_monthly": "t_thermo_11_monthly_max_C",
          "t_thermo_11_min_monthly": "t_thermo_11_monthly_min_C",
          "t_thermo_11_sum_monthly": "t_thermo_11_monthly_sum_C",
          "t_thermo_11_count_monthly": "c_t_thermo_11_monthly_C",
          "t_thermo_12_avg_monthly": "t_thermo_12_monthly_avg_C",
          "t_thermo_12_mod_monthly": "t_thermo_12_monthly_mod_C",
          "t_thermo_12_med_monthly": "t_thermo_12_monthly_med_C",
          "t_thermo_12_max_monthly": "t_thermo_12_monthly_max_C",
          "t_thermo_12_min_monthly": "t_thermo_12_monthly_min_C",
          "t_thermo_12_sum_monthly": "t_thermo_12_monthly_sum_C",
          "t_thermo_12_count_monthly": "c_t_thermo_12_monthly_C",
          "t_thermo_13_avg_monthly": "t_thermo_13_monthly_avg_C",
          "t_thermo_13_mod_monthly": "t_thermo_13_monthly_mod_C",
          "t_thermo_13_med_monthly": "t_thermo_13_monthly_med_C",
          "t_thermo_13_max_monthly": "t_thermo_13_monthly_max_C",
          "t_thermo_13_min_monthly": "t_thermo_13_monthly_min_C",
          "t_thermo_13_sum_monthly": "t_thermo_13_monthly_sum_C",
          "t_thermo_13_count_monthly": "c_t_thermo_13_monthly_C",
          "t_thermo_14_avg_monthly": "t_thermo_14_monthly_avg_C",
          "t_thermo_14_mod_monthly": "t_thermo_14_monthly_mod_C",
          "t_thermo_14_med_monthly": "t_thermo_14_monthly_med_C",
          "t_thermo_14_max_monthly": "t_thermo_14_monthly_max_C",
          "t_thermo_14_min_monthly": "t_thermo_14_monthly_min_C",
          "t_thermo_14_sum_monthly": "t_thermo_14_monthly_sum_C",
          "t_thermo_14_count_monthly": "c_t_thermo_14_monthly_C",
          "t_thermo_15_avg_monthly": "t_thermo_15_monthly_avg_C",
          "t_thermo_15_mod_monthly": "t_thermo_15_monthly_mod_C",
          "t_thermo_15_med_monthly": "t_thermo_15_monthly_med_C",
          "t_thermo_15_max_monthly": "t_thermo_15_monthly_max_C",
          "t_thermo_15_min_monthly": "t_thermo_15_monthly_min_C",
          "t_thermo_15_sum_monthly": "t_thermo_15_monthly_sum_C",
          "t_thermo_15_count_monthly": "c_t_thermo_15_monthly_C",
          "t_thermo_16_avg_monthly": "t_thermo_16_monthly_avg_C",
          "t_thermo_16_mod_monthly": "t_thermo_16_monthly_mod_C",
          "t_thermo_16_med_monthly": "t_thermo_16_monthly_med_C",
          "t_thermo_16_max_monthly": "t_thermo_16_monthly_max_C",
          "t_thermo_16_min_monthly": "t_thermo_16_monthly_min_C",
          "t_thermo_16_sum_monthly": "t_thermo_16_monthly_sum_C",
          "t_thermo_16_count_monthly": "c_t_thermo_16_monthly_C",
          "t_thermo_17_avg_monthly": "t_thermo_17_monthly_avg_C",
          "t_thermo_17_mod_monthly": "t_thermo_17_monthly_mod_C",
          "t_thermo_17_med_monthly": "t_thermo_17_monthly_med_C",
          "t_thermo_17_max_monthly": "t_thermo_17_monthly_max_C",
          "t_thermo_17_min_monthly": "t_thermo_17_monthly_min_C",
          "t_thermo_17_sum_monthly": "t_thermo_17_monthly_sum_C",
          "t_thermo_17_count_monthly": "c_t_thermo_17_monthly_C",
          "t_thermo_18_avg_monthly": "t_thermo_18_monthly_avg_C",
          "t_thermo_18_mod_monthly": "t_thermo_18_monthly_mod_C",
          "t_thermo_18_med_monthly": "t_thermo_18_monthly_med_C",
          "t_thermo_18_max_monthly": "t_thermo_18_monthly_max_C",
          "t_thermo_18_min_monthly": "t_thermo_18_monthly_min_C",
          "t_thermo_18_sum_monthly": "t_thermo_18_monthly_sum_C",
          "t_thermo_18_count_monthly": "c_t_thermo_18_monthly_C",
          "t_thermo_19_avg_monthly": "t_thermo_19_monthly_avg_C",
          "t_thermo_19_mod_monthly": "t_thermo_19_monthly_mod_C",
          "t_thermo_19_med_monthly": "t_thermo_19_monthly_med_C",
          "t_thermo_19_max_monthly": "t_thermo_19_monthly_max_C",
          "t_thermo_19_min_monthly": "t_thermo_19_monthly_min_C",
          "t_thermo_19_sum_monthly": "t_thermo_19_monthly_sum_C",
          "t_thermo_19_count_monthly": "c_t_thermo_19_monthly_C",
          "t_thermo_20_avg_monthly": "t_thermo_20_monthly_avg_C",
          "t_thermo_20_mod_monthly": "t_thermo_20_monthly_mod_C",
          "t_thermo_20_med_monthly": "t_thermo_20_monthly_med_C",
          "t_thermo_20_max_monthly": "t_thermo_20_monthly_max_C",
          "t_thermo_20_min_monthly": "t_thermo_20_monthly_min_C",
          "t_thermo_20_sum_monthly": "t_thermo_20_monthly_sum_C",
          "t_thermo_20_count_monthly": "c_t_thermo_20_monthly_C",
          "t_thermo_21_avg_monthly": "t_thermo_21_monthly_avg_C",
          "t_thermo_21_mod_monthly": "t_thermo_21_monthly_mod_C",
          "t_thermo_21_med_monthly": "t_thermo_21_monthly_med_C",
          "t_thermo_21_max_monthly": "t_thermo_21_monthly_max_C",
          "t_thermo_21_min_monthly": "t_thermo_21_monthly_min_C",
          "t_thermo_21_sum_monthly": "t_thermo_21_monthly_sum_C",
          "t_thermo_21_count_monthly": "c_t_thermo_21_monthly_C",
          "t_thermo_22_avg_monthly": "t_thermo_22_monthly_avg_C",
          "t_thermo_22_mod_monthly": "t_thermo_22_monthly_mod_C",
          "t_thermo_22_med_monthly": "t_thermo_22_monthly_med_C",
          "t_thermo_22_max_monthly": "t_thermo_22_monthly_max_C",
          "t_thermo_22_min_monthly": "t_thermo_22_monthly_min_C",
          "t_thermo_22_sum_monthly": "t_thermo_22_monthly_sum_C",
          "t_thermo_22_count_monthly": "c_t_thermo_22_monthly_C",
          "t_thermo_23_avg_monthly": "t_thermo_23_monthly_avg_C",
          "t_thermo_23_mod_monthly": "t_thermo_23_monthly_mod_C",
          "t_thermo_23_med_monthly": "t_thermo_23_monthly_med_C",
          "t_thermo_23_max_monthly": "t_thermo_23_monthly_max_C",
          "t_thermo_23_min_monthly": "t_thermo_23_monthly_min_C",
          "t_thermo_23_sum_monthly": "t_thermo_23_monthly_sum_C",
          "t_thermo_23_count_monthly": "c_t_thermo_23_monthly_C",
          "t_thermo_24_avg_monthly": "t_thermo_24_monthly_avg_C",
          "t_thermo_24_mod_monthly": "t_thermo_24_monthly_mod_C",
          "t_thermo_24_med_monthly": "t_thermo_24_monthly_med_C",
          "t_thermo_24_max_monthly": "t_thermo_24_monthly_max_C",
          "t_thermo_24_min_monthly": "t_thermo_24_monthly_min_C",
          "t_thermo_24_sum_monthly": "t_thermo_24_monthly_sum_C",
          "t_thermo_24_count_monthly": "c_t_thermo_24_monthly_C",
          "tx_thermo_avg_monthly": "tx_thermo_monthly_avg_C",
          "tx_thermo_mod_monthly": "tx_thermo_monthly_mod_C",
          "tx_thermo_med_monthly": "tx_thermo_monthly_med_C",
          "tx_thermo_max_monthly": "tx_thermo_monthly_max_C",
          "tx_thermo_min_monthly": "tx_thermo_monthly_min_C",
          "tx_thermo_sum_monthly": "tx_thermo_monthly_sum_C",
          "tx_thermo_count_monthly": "c_t_max_thermo_monthly_C",
          "tn_thermo_avg_monthly": "tn_thermo_monthly_avg_C",
          "tn_thermo_mod_monthly": "tn_thermo_monthly_mod_C",
          "tn_thermo_med_monthly": "tn_thermo_monthly_med_C",
          "tn_thermo_max_monthly": "tn_thermo_monthly_max_C",
          "tn_thermo_min_monthly": "tn_thermo_monthly_min_C",
          "tn_thermo_sum_monthly": "tn_thermo_monthly_sum_C",
          "tn_thermo_count_monthly": "c_t_min_thermo_monthly_C",
          "t_avg_thermo_avg_monthly": "t_avg_thermo_monthly_avg_C",
          "t_avg_thermo_mod_monthly": "t_avg_thermo_monthly_mod_C",
          "t_avg_thermo_med_monthly": "t_avg_thermo_monthly_med_C",
          "t_avg_thermo_max_monthly": "t_avg_thermo_monthly_max_C",
          "t_avg_thermo_min_monthly": "t_avg_thermo_monthly_min_C",
          "t_avg_thermo_sum_monthly": "t_min_thermo_monthly_sum_C",
          "t_avg_thermo_count_monthly": "c_t_avg_thermo_monthly_C",
        
          "rh_higro_01_avg_monthly": "rh_higro_01_monthly_avg_PC",
          "rh_higro_01_mod_monthly": "rh_higro_01_monthly_mod_PC",
          "rh_higro_01_med_monthly": "rh_higro_01_monthly_med_PC",
          "rh_higro_01_max_monthly": "rh_higro_01_monthly_max_PC",
          "rh_higro_01_min_monthly": "rh_higro_01_monthly_min_PC",
          "rh_higro_01_sum_monthly": "rh_higro_01_monthly_sum_PC",
          "rh_higro_01_count_monthly": "c_rh_higro_01_monthly_PC",
          "rh_higro_02_avg_monthly": "rh_higro_02_monthly_avg_PC",
          "rh_higro_02_mod_monthly": "rh_higro_02_monthly_mod_PC",
          "rh_higro_02_med_monthly": "rh_higro_02_monthly_med_PC",
          "rh_higro_02_max_monthly": "rh_higro_02_monthly_max_PC",
          "rh_higro_02_min_monthly": "rh_higro_02_monthly_min_PC",
          "rh_higro_02_sum_monthly": "rh_higro_02_monthly_sum_PC",
          "rh_higro_02_count_monthly": "c_rh_higro_02_monthly_PC",
          "rh_higro_03_avg_monthly": "rh_higro_03_monthly_avg_PC",
          "rh_higro_03_mod_monthly": "rh_higro_03_monthly_mod_PC",
          "rh_higro_03_med_monthly": "rh_higro_03_monthly_med_PC",
          "rh_higro_03_max_monthly": "rh_higro_03_monthly_max_PC",
          "rh_higro_03_min_monthly": "rh_higro_03_monthly_min_PC",
          "rh_higro_03_sum_monthly": "rh_higro_03_monthly_sum_PC",
          "rh_higro_03_count_monthly": "c_rh_higro_03_monthly_PC",
          "rh_higro_04_avg_monthly": "rh_higro_04_monthly_avg_PC",
          "rh_higro_04_mod_monthly": "rh_higro_04_monthly_mod_PC",
          "rh_higro_04_med_monthly": "rh_higro_04_monthly_med_PC",
          "rh_higro_04_max_monthly": "rh_higro_04_monthly_max_PC",
          "rh_higro_04_min_monthly": "rh_higro_04_monthly_min_PC",
          "rh_higro_04_sum_monthly": "rh_higro_04_monthly_sum_PC",
          "rh_higro_04_count_monthly": "c_rh_higro_04_monthly_PC",
          "rh_higro_05_avg_monthly": "rh_higro_05_monthly_avg_PC",
          "rh_higro_05_mod_monthly": "rh_higro_05_monthly_mod_PC",
          "rh_higro_05_med_monthly": "rh_higro_05_monthly_med_PC",
          "rh_higro_05_max_monthly": "rh_higro_05_monthly_max_PC",
          "rh_higro_05_min_monthly": "rh_higro_05_monthly_min_PC",
          "rh_higro_05_sum_monthly": "rh_higro_05_monthly_sum_PC",
          "rh_higro_05_count_monthly": "c_rh_higro_05_monthly_PC",
          "rh_higro_06_avg_monthly": "rh_higro_06_monthly_avg_PC",
          "rh_higro_06_mod_monthly": "rh_higro_06_monthly_mod_PC",
          "rh_higro_06_med_monthly": "rh_higro_06_monthly_med_PC",
          "rh_higro_06_max_monthly": "rh_higro_06_monthly_max_PC",
          "rh_higro_06_min_monthly": "rh_higro_06_monthly_min_PC",
          "rh_higro_06_sum_monthly": "rh_higro_06_monthly_sum_PC",
          "rh_higro_06_count_monthly": "c_rh_higro_06_monthly_PC",
          "rh_higro_07_avg_monthly": "rh_higro_07_monthly_avg_PC",
          "rh_higro_07_mod_monthly": "rh_higro_07_monthly_mod_PC",
          "rh_higro_07_med_monthly": "rh_higro_07_monthly_med_PC",
          "rh_higro_07_max_monthly": "rh_higro_07_monthly_max_PC",
          "rh_higro_07_min_monthly": "rh_higro_07_monthly_min_PC",
          "rh_higro_07_sum_monthly": "rh_higro_07_monthly_sum_PC",
          "rh_higro_07_count_monthly": "c_rh_higro_07_monthly_PC",
          "rh_higro_08_avg_monthly": "rh_higro_08_monthly_avg_PC",
          "rh_higro_08_mod_monthly": "rh_higro_08_monthly_mod_PC",
          "rh_higro_08_med_monthly": "rh_higro_08_monthly_med_PC",
          "rh_higro_08_max_monthly": "rh_higro_08_monthly_max_PC",
          "rh_higro_08_min_monthly": "rh_higro_08_monthly_min_PC",
          "rh_higro_08_sum_monthly": "rh_higro_08_monthly_sum_PC",
          "rh_higro_08_count_monthly": "c_rh_higro_08_monthly_PC",
          "rh_higro_09_avg_monthly": "rh_higro_09_monthly_avg_PC",
          "rh_higro_09_mod_monthly": "rh_higro_09_monthly_mod_PC",
          "rh_higro_09_med_monthly": "rh_higro_09_monthly_med_PC",
          "rh_higro_09_max_monthly": "rh_higro_09_monthly_max_PC",
          "rh_higro_09_min_monthly": "rh_higro_09_monthly_min_PC",
          "rh_higro_09_sum_monthly": "rh_higro_09_monthly_sum_PC",
          "rh_higro_09_count_monthly": "c_rh_higro_09_monthly_PC",
          "rh_higro_10_avg_monthly": "rh_higro_10_monthly_avg_PC",
          "rh_higro_10_mod_monthly": "rh_higro_10_monthly_mod_PC",
          "rh_higro_10_med_monthly": "rh_higro_10_monthly_med_PC",
          "rh_higro_10_max_monthly": "rh_higro_10_monthly_max_PC",
          "rh_higro_10_min_monthly": "rh_higro_10_monthly_min_PC",
          "rh_higro_10_sum_monthly": "rh_higro_10_monthly_sum_PC",
          "rh_higro_10_count_monthly": "c_rh_higro_10_monthly_PC",
          "rh_higro_11_avg_monthly": "rh_higro_11_monthly_avg_PC",
          "rh_higro_11_mod_monthly": "rh_higro_11_monthly_mod_PC",
          "rh_higro_11_med_monthly": "rh_higro_11_monthly_med_PC",
          "rh_higro_11_max_monthly": "rh_higro_11_monthly_max_PC",
          "rh_higro_11_min_monthly": "rh_higro_11_monthly_min_PC",
          "rh_higro_11_sum_monthly": "rh_higro_11_monthly_sum_PC",
          "rh_higro_11_count_monthly": "c_rh_higro_11_monthly_PC",
          "rh_higro_12_avg_monthly": "rh_higro_12_monthly_avg_PC",
          "rh_higro_12_mod_monthly": "rh_higro_12_monthly_mod_PC",
          "rh_higro_12_med_monthly": "rh_higro_12_monthly_med_PC",
          "rh_higro_12_max_monthly": "rh_higro_12_monthly_max_PC",
          "rh_higro_12_min_monthly": "rh_higro_12_monthly_min_PC",
          "rh_higro_12_sum_monthly": "rh_higro_12_monthly_sum_PC",
          "rh_higro_12_count_monthly": "c_rh_higro_12_monthly_PC",
          "rh_higro_13_avg_monthly": "rh_higro_13_monthly_avg_PC",
          "rh_higro_13_mod_monthly": "rh_higro_13_monthly_mod_PC",
          "rh_higro_13_med_monthly": "rh_higro_13_monthly_med_PC",
          "rh_higro_13_max_monthly": "rh_higro_13_monthly_max_PC",
          "rh_higro_13_min_monthly": "rh_higro_13_monthly_min_PC",
          "rh_higro_13_sum_monthly": "rh_higro_13_monthly_sum_PC",
          "rh_higro_13_count_monthly": "c_rh_higro_13_monthly_PC",
          "rh_higro_14_avg_monthly": "rh_higro_14_monthly_avg_PC",
          "rh_higro_14_mod_monthly": "rh_higro_14_monthly_mod_PC",
          "rh_higro_14_med_monthly": "rh_higro_14_monthly_med_PC",
          "rh_higro_14_max_monthly": "rh_higro_14_monthly_max_PC",
          "rh_higro_14_min_monthly": "rh_higro_14_monthly_min_PC",
          "rh_higro_14_sum_monthly": "rh_higro_14_monthly_sum_PC",
          "rh_higro_14_count_monthly": "c_rh_higro_14_monthly_PC",
          "rh_higro_15_avg_monthly": "rh_higro_15_monthly_avg_PC",
          "rh_higro_15_mod_monthly": "rh_higro_15_monthly_mod_PC",
          "rh_higro_15_med_monthly": "rh_higro_15_monthly_med_PC",
          "rh_higro_15_max_monthly": "rh_higro_15_monthly_max_PC",
          "rh_higro_15_min_monthly": "rh_higro_15_monthly_min_PC",
          "rh_higro_15_sum_monthly": "rh_higro_15_monthly_sum_PC",
          "rh_higro_15_count_monthly": "c_rh_higro_15_monthly_PC",
          "rh_higro_16_avg_monthly": "rh_higro_16_monthly_avg_PC",
          "rh_higro_16_mod_monthly": "rh_higro_16_monthly_mod_PC",
          "rh_higro_16_med_monthly": "rh_higro_16_monthly_med_PC",
          "rh_higro_16_max_monthly": "rh_higro_16_monthly_max_PC",
          "rh_higro_16_min_monthly": "rh_higro_16_monthly_min_PC",
          "rh_higro_16_sum_monthly": "rh_higro_16_monthly_sum_PC",
          "rh_higro_16_count_monthly": "c_rh_higro_16_monthly_PC",
          "rh_higro_17_avg_monthly": "rh_higro_17_monthly_avg_PC",
          "rh_higro_17_mod_monthly": "rh_higro_17_monthly_mod_PC",
          "rh_higro_17_med_monthly": "rh_higro_17_monthly_med_PC",
          "rh_higro_17_max_monthly": "rh_higro_17_monthly_max_PC",
          "rh_higro_17_min_monthly": "rh_higro_17_monthly_min_PC",
          "rh_higro_17_sum_monthly": "rh_higro_17_monthly_sum_PC ",
          "rh_higro_17_count_monthly": "c_rh_higro_17_monthly_PC",
          "rh_higro_18_avg_monthly": "rh_higro_18_monthly_avg_PC",
          "rh_higro_18_mod_monthly": "rh_higro_18_monthly_mod_PC",
          "rh_higro_18_med_monthly": "rh_higro_18_monthly_med_PC",
          "rh_higro_18_max_monthly": "rh_higro_18_monthly_max_PC",
          "rh_higro_18_min_monthly": "rh_higro_18_monthly_min_PC",
          "rh_higro_18_sum_monthly": "rh_higro_18_monthly_sum_PC",
          "rh_higro_18_count_monthly": "c_rh_higro_08_monthly_PC",
          "rh_higro_19_avg_monthly": "rh_higro_19_monthly_avg_PC",
          "rh_higro_19_mod_monthly": "rh_higro_19_monthly_mod_PC",
          "rh_higro_19_med_monthly": "rh_higro_19_monthly_med_PC",
          "rh_higro_19_max_monthly": "rh_higro_19_monthly_max_PC",
          "rh_higro_19_min_monthly": "rh_higro_19_monthly_min_PC",
          "rh_higro_19_sum_monthly": "rh_higro_19_monthly_sum_PC",
          "rh_higro_19_count_monthly": "c_rh_higro_19_monthly_PC",
          "rh_higro_20_avg_monthly": "rh_higro_20_monthly_avg_PC",
          "rh_higro_20_mod_monthly": "rh_higro_20_monthly_mod_PC",
          "rh_higro_20_med_monthly": "rh_higro_20_monthly_med_PC",
          "rh_higro_20_max_monthly": "rh_higro_20_monthly_max_PC",
          "rh_higro_20_min_monthly": "rh_higro_20_monthly_min_PC",
          "rh_higro_20_sum_monthly": "rh_higro_20_monthly_sum_PC",
          "rh_higro_20_count_monthly": "c_rh_higro_20_monthly_PC",
          "rh_higro_21_avg_monthly": "rh_higro_21_monthly_avg_PC",
          "rh_higro_21_mod_monthly": "rh_higro_21_monthly_mod_PC",
          "rh_higro_21_med_monthly": "rh_higro_21_monthly_med_PC",
          "rh_higro_21_max_monthly": "rh_higro_21_monthly_max_PC",
          "rh_higro_21_min_monthly": "rh_higro_21_monthly_min_PC",
          "rh_higro_21_sum_monthly": "rh_higro_21_monthly_sum_PC",
          "rh_higro_21_count_monthly": "c_rh_higro_21_monthly_PC",
          "rh_higro_22_avg_monthly": "rh_higro_22_monthly_avg_PC",
          "rh_higro_22_mod_monthly": "rh_higro_22_monthly_mod_PC",
          "rh_higro_22_med_monthly": "rh_higro_22_monthly_med_PC",
          "rh_higro_22_max_monthly": "rh_higro_22_monthly_max_PC",
          "rh_higro_22_min_monthly": "rh_higro_22_monthly_min_PC",
          "rh_higro_22_sum_monthly": "rh_higro_22_monthly_sum_PC",
          "rh_higro_22_count_monthly": "c_rh_higro_01_monthly_PC",
          "rh_higro_23_avg_monthly": "rh_higro_23_monthly_avg_PC",
          "rh_higro_23_mod_monthly": "rh_higro_23_monthly_mod_PC",
          "rh_higro_23_med_monthly": "rh_higro_23_monthly_med_PC",
          "rh_higro_23_max_monthly": "rh_higro_23_monthly_max_PC",
          "rh_higro_23_min_monthly": "rh_higro_23_monthly_min_PC",
          "rh_higro_23_sum_monthly": "rh_higro_23_monthly_sum_PC",
          "rh_higro_23_count_monthly": "c_rh_higro_23_monthly_PC",
          "rh_higro_24_avg_monthly": "rh_higro_24_monthly_avg_PC",
          "rh_higro_24_mod_monthly": "rh_higro_24_monthly_mod_PC",
          "rh_higro_24_med_monthly": "rh_higro_24_monthly_med_PC",
          "rh_higro_24_max_monthly": "rh_higro_24_monthly_max_PC",
          "rh_higro_24_min_monthly": "rh_higro_24_monthly_min_PC",
          "rh_higro_24_sum_monthly": "rh_higro_24_monthly_sum_PC",
          "rh_higro_24_count_monthly": "c_rh_higro_24_monthly_PC",
          "rh_max_higro_avg_monthly": "rh_max_higro_monthly_avg_PC",
          "rh_max_higro_mod_monthly": "rh_max_higro_monthly_mod_PC",
          "rh_max_higro_med_monthly": "rh_max_higro_monthly_med_PC",
          "rh_max_higro_max_monthly": "rh_max_higro_monthly_max_PC",
          "rh_max_higro_min_monthly": "rh_max_higro_monthly_min_PC",
          "rh_max_higro_sum_monthly": "rh_max_higro_monthly_sum_PC",
          "rh_max_higro_count_monthly": "c_rh_max_higro_monthly_PC",
          "rh_min_higro_avg_monthly": "rh_min_higro_monthly_avg_PC",
          "rh_min_higro_mod_monthly": "rh_min_higro_monthly_mod_PC",
          "rh_min_higro_med_monthly": "rh_min_higro_monthly_med_PC",
          "rh_min_higro_max_monthly": "rh_min_higro_monthly_max_PC",
          "rh_min_higro_min_monthly": "rh_min_higro_monthly_min_PC",
          "rh_min_higro_sum_monthly": "rh_min_higro_monthly_sum_PC",
          "rh_min_higro_count_monthly": "c_rh_min_higro_monthly_PC",
          "rh_avg_higro_avg_monthly": "rh_avg_higro_monthly_avg_PC",
          "rh_avg_higro_mod_monthly": "rh_avg_higro_monthly_mod_PC",
          "rh_avg_higro_med_monthly": "rh_avg_higro_monthly_med_PC",
          "rh_avg_higro_max_monthly": "rh_avg_higro_monthly_max_PC",
          "rh_avg_higro_min_monthly": "rh_avg_higro_monthly_min_PC",
          "rh_avg_higro_sum_monthly": "rh_avg_higro_monthly_sum_PC",
          "rh_avg_higro_count_monthly": "c_rh_avg_higro_monthly_PC"
        },
      },
    ];

    return listAttrMonthly;
  },

  getAttrMonthlyDecimal() {
    const listAttrMonthly = [
      {
        label: "Suhu Udara",
        attr: {
          "t_thermo_01_avg_monthly": 1,
          "t_thermo_01_mod_monthly": 1,
          "t_thermo_01_med_monthly": 1,
          "t_thermo_01_max_monthly": 1,
          "t_thermo_01_min_monthly": 1,
          "t_thermo_01_sum_monthly": 1,
          "t_thermo_01_count_monthly": 1,
          "t_thermo_02_avg_monthly": 1,
          "t_thermo_02_mod_monthly": 1,
          "t_thermo_02_med_monthly": 1,
          "t_thermo_02_max_monthly": 1,
          "t_thermo_02_min_monthly": 1,
          "t_thermo_02_sum_monthly": 1,
          "t_thermo_02_count_monthly": 1,
          "t_thermo_03_avg_monthly": 1,
          "t_thermo_03_mod_monthly": 1,
          "t_thermo_03_med_monthly": 1,
          "t_thermo_03_max_monthly": 1,
          "t_thermo_03_min_monthly": 1,
          "t_thermo_03_sum_monthly": 1,
          "t_thermo_03_count_monthly": 1,
          "t_thermo_04_avg_monthly": 1,
          "t_thermo_04_mod_monthly": 1,
          "t_thermo_04_med_monthly": 1,
          "t_thermo_04_max_monthly": 1,
          "t_thermo_04_min_monthly": 1,
          "t_thermo_04_sum_monthly": 1,
          "t_thermo_04_count_monthly": 1,
          "t_thermo_05_avg_monthly": 1,
          "t_thermo_05_mod_monthly": 1,
          "t_thermo_05_med_monthly": 1,
          "t_thermo_05_max_monthly": 1,
          "t_thermo_05_min_monthly": 1,
          "t_thermo_05_sum_monthly": 1,
          "t_thermo_05_count_monthly": 1,
          "t_thermo_06_avg_monthly": 1,
          "t_thermo_06_mod_monthly": 1,
          "t_thermo_06_med_monthly": 1,
          "t_thermo_06_max_monthly": 1,
          "t_thermo_06_min_monthly": 1,
          "t_thermo_06_sum_monthly": 1,
          "t_thermo_06_count_monthly": 1,
          "t_thermo_07_avg_monthly": 1,
          "t_thermo_07_mod_monthly": 1,
          "t_thermo_07_med_monthly": 1,
          "t_thermo_07_max_monthly": 1,
          "t_thermo_07_min_monthly": 1,
          "t_thermo_07_sum_monthly": 1,
          "t_thermo_07_count_monthly": 1,
          "t_thermo_08_avg_monthly": 1,
          "t_thermo_08_mod_monthly": 1,
          "t_thermo_08_med_monthly": 1,
          "t_thermo_08_max_monthly": 1,
          "t_thermo_08_min_monthly": 1,
          "t_thermo_08_sum_monthly": 1,
          "t_thermo_08_count_monthly": 1,
          "t_thermo_09_avg_monthly": 1,
          "t_thermo_09_mod_monthly": 1,
          "t_thermo_09_med_monthly": 1,
          "t_thermo_09_max_monthly": 1,
          "t_thermo_09_min_monthly": 1,
          "t_thermo_09_sum_monthly": 1,
          "t_thermo_09_count_monthly": 1,
          "t_thermo_10_avg_monthly": 1,
          "t_thermo_10_mod_monthly": 1,
          "t_thermo_10_med_monthly": 1,
          "t_thermo_10_max_monthly": 1,
          "t_thermo_10_min_monthly": 1,
          "t_thermo_10_sum_monthly": 1,
          "t_thermo_10_count_monthly": 1,
          "t_thermo_11_avg_monthly": 1,
          "t_thermo_11_mod_monthly": 1,
          "t_thermo_11_med_monthly": 1,
          "t_thermo_11_max_monthly": 1,
          "t_thermo_11_min_monthly": 1,
          "t_thermo_11_sum_monthly": 1,
          "t_thermo_11_count_monthly": 1,
          "t_thermo_12_avg_monthly": 1,
          "t_thermo_12_mod_monthly": 1,
          "t_thermo_12_med_monthly": 1,
          "t_thermo_12_max_monthly": 1,
          "t_thermo_12_min_monthly": 1,
          "t_thermo_12_sum_monthly": 1,
          "t_thermo_12_count_monthly": 1,
          "t_thermo_13_avg_monthly": 1,
          "t_thermo_13_mod_monthly": 1,
          "t_thermo_13_med_monthly": 1,
          "t_thermo_13_max_monthly": 1,
          "t_thermo_13_min_monthly": 1,
          "t_thermo_13_sum_monthly": 1,
          "t_thermo_13_count_monthly": 1,
          "t_thermo_14_avg_monthly": 1,
          "t_thermo_14_mod_monthly": 1,
          "t_thermo_14_med_monthly": 1,
          "t_thermo_14_max_monthly": 1,
          "t_thermo_14_min_monthly": 1,
          "t_thermo_14_sum_monthly": 1,
          "t_thermo_14_count_monthly": 1,
          "t_thermo_15_avg_monthly": 1,
          "t_thermo_15_mod_monthly": 1,
          "t_thermo_15_med_monthly": 1,
          "t_thermo_15_max_monthly": 1,
          "t_thermo_15_min_monthly": 1,
          "t_thermo_15_sum_monthly": 1,
          "t_thermo_15_count_monthly": 1,
          "t_thermo_16_avg_monthly": 1,
          "t_thermo_16_mod_monthly": 1,
          "t_thermo_16_med_monthly": 1,
          "t_thermo_16_max_monthly": 1,
          "t_thermo_16_min_monthly": 1,
          "t_thermo_16_sum_monthly": 1,
          "t_thermo_16_count_monthly": 1,
          "t_thermo_17_avg_monthly": 1,
          "t_thermo_17_mod_monthly": 1,
          "t_thermo_17_med_monthly": 1,
          "t_thermo_17_max_monthly": 1,
          "t_thermo_17_min_monthly": 1,
          "t_thermo_17_sum_monthly": 1,
          "t_thermo_17_count_monthly": 1,
          "t_thermo_18_avg_monthly": 1,
          "t_thermo_18_mod_monthly": 1,
          "t_thermo_18_med_monthly": 1,
          "t_thermo_18_max_monthly": 1,
          "t_thermo_18_min_monthly": 1,
          "t_thermo_18_sum_monthly": 1,
          "t_thermo_18_count_monthly": 1,
          "t_thermo_19_avg_monthly": 1,
          "t_thermo_19_mod_monthly": 1,
          "t_thermo_19_med_monthly": 1,
          "t_thermo_19_max_monthly": 1,
          "t_thermo_19_min_monthly": 1,
          "t_thermo_19_sum_monthly": 1,
          "t_thermo_19_count_monthly": 1,
          "t_thermo_20_avg_monthly": 1,
          "t_thermo_20_mod_monthly": 1,
          "t_thermo_20_med_monthly": 1,
          "t_thermo_20_max_monthly": 1,
          "t_thermo_20_min_monthly": 1,
          "t_thermo_20_sum_monthly": 1,
          "t_thermo_20_count_monthly": 1,
          "t_thermo_21_avg_monthly": 1,
          "t_thermo_21_mod_monthly": 1,
          "t_thermo_21_med_monthly": 1,
          "t_thermo_21_max_monthly": 1,
          "t_thermo_21_min_monthly": 1,
          "t_thermo_21_sum_monthly": 1,
          "t_thermo_21_count_monthly": 1,
          "t_thermo_22_avg_monthly": 1,
          "t_thermo_22_mod_monthly": 1,
          "t_thermo_22_med_monthly": 1,
          "t_thermo_22_max_monthly": 1,
          "t_thermo_22_min_monthly": 1,
          "t_thermo_22_sum_monthly": 1,
          "t_thermo_22_count_monthly": 1,
          "t_thermo_23_avg_monthly": 1,
          "t_thermo_23_mod_monthly": 1,
          "t_thermo_23_med_monthly": 1,
          "t_thermo_23_max_monthly": 1,
          "t_thermo_23_min_monthly": 1,
          "t_thermo_23_sum_monthly": 1,
          "t_thermo_23_count_monthly": 1,
          "t_thermo_24_avg_monthly": 1,
          "t_thermo_24_mod_monthly": 1,
          "t_thermo_24_med_monthly": 1,
          "t_thermo_24_max_monthly": 1,
          "t_thermo_24_min_monthly": 1,
          "t_thermo_24_sum_monthly": 1,
          "t_thermo_24_count_monthly": 1,
          "tx_thermo_avg_monthly": 1,
          "tx_thermo_mod_monthly": 1,
          "tx_thermo_med_monthly": 1,
          "tx_thermo_max_monthly": 1,
          "tx_thermo_min_monthly": 1,
          "tx_thermo_sum_monthly": 1,
          "tx_thermo_count_monthly": 1,
          "tn_thermo_avg_monthly": 1,
          "tn_thermo_mod_monthly": 1,
          "tn_thermo_med_monthly": 1,
          "tn_thermo_max_monthly": 1,
          "tn_thermo_min_monthly": 1,
          "tn_thermo_sum_monthly": 1,
          "tn_thermo_count_monthly": 1,
          "t_avg_thermo_avg_monthly": 1,
          "t_avg_thermo_mod_monthly": 1,
          "t_avg_thermo_med_monthly": 1,
          "t_avg_thermo_max_monthly": 1,
          "t_avg_thermo_min_monthly": 1,
          "t_avg_thermo_sum_monthly": 1,
          "t_avg_thermo_count_monthly": 1,
        
          "rh_higro_01_avg_monthly": 1,
          "rh_higro_01_mod_monthly": 1,
          "rh_higro_01_med_monthly": 1,
          "rh_higro_01_max_monthly": 1,
          "rh_higro_01_min_monthly": 1,
          "rh_higro_01_sum_monthly": 1,
          "rh_higro_01_count_monthly": 1,
          "rh_higro_02_avg_monthly": 1,
          "rh_higro_02_mod_monthly": 1,
          "rh_higro_02_med_monthly": 1,
          "rh_higro_02_max_monthly": 1,
          "rh_higro_02_min_monthly": 1,
          "rh_higro_02_sum_monthly": 1,
          "rh_higro_02_count_monthly": 1,
          "rh_higro_03_avg_monthly": 1,
          "rh_higro_03_mod_monthly": 1,
          "rh_higro_03_med_monthly": 1,
          "rh_higro_03_max_monthly": 1,
          "rh_higro_03_min_monthly": 1,
          "rh_higro_03_sum_monthly": 1,
          "rh_higro_03_count_monthly": 1,
          "rh_higro_04_avg_monthly": 1,
          "rh_higro_04_mod_monthly": 1,
          "rh_higro_04_med_monthly": 1,
          "rh_higro_04_max_monthly": 1,
          "rh_higro_04_min_monthly": 1,
          "rh_higro_04_sum_monthly": 1,
          "rh_higro_04_count_monthly": 1,
          "rh_higro_05_avg_monthly": 1,
          "rh_higro_05_mod_monthly": 1,
          "rh_higro_05_med_monthly": 1,
          "rh_higro_05_max_monthly": 1,
          "rh_higro_05_min_monthly": 1,
          "rh_higro_05_sum_monthly": 1,
          "rh_higro_05_count_monthly": 1,
          "rh_higro_06_avg_monthly": 1,
          "rh_higro_06_mod_monthly": 1,
          "rh_higro_06_med_monthly": 1,
          "rh_higro_06_max_monthly": 1,
          "rh_higro_06_min_monthly": 1,
          "rh_higro_06_sum_monthly": 1,
          "rh_higro_06_count_monthly": 1,
          "rh_higro_07_avg_monthly": 1,
          "rh_higro_07_mod_monthly": 1,
          "rh_higro_07_med_monthly": 1,
          "rh_higro_07_max_monthly": 1,
          "rh_higro_07_min_monthly": 1,
          "rh_higro_07_sum_monthly": 1,
          "rh_higro_07_count_monthly": 1,
          "rh_higro_08_avg_monthly": 1,
          "rh_higro_08_mod_monthly": 1,
          "rh_higro_08_med_monthly": 1,
          "rh_higro_08_max_monthly": 1,
          "rh_higro_08_min_monthly": 1,
          "rh_higro_08_sum_monthly": 1,
          "rh_higro_08_count_monthly": 1,
          "rh_higro_09_avg_monthly": 1,
          "rh_higro_09_mod_monthly": 1,
          "rh_higro_09_med_monthly": 1,
          "rh_higro_09_max_monthly": 1,
          "rh_higro_09_min_monthly": 1,
          "rh_higro_09_sum_monthly": 1,
          "rh_higro_09_count_monthly": 1,
          "rh_higro_10_avg_monthly": 1,
          "rh_higro_10_mod_monthly": 1,
          "rh_higro_10_med_monthly": 1,
          "rh_higro_10_max_monthly": 1,
          "rh_higro_10_min_monthly": 1,
          "rh_higro_10_sum_monthly": 1,
          "rh_higro_10_count_monthly": 1,
          "rh_higro_11_avg_monthly": 1,
          "rh_higro_11_mod_monthly": 1,
          "rh_higro_11_med_monthly": 1,
          "rh_higro_11_max_monthly": 1,
          "rh_higro_11_min_monthly": 1,
          "rh_higro_11_sum_monthly": 1,
          "rh_higro_11_count_monthly": 1,
          "rh_higro_12_avg_monthly": 1,
          "rh_higro_12_mod_monthly": 1,
          "rh_higro_12_med_monthly": 1,
          "rh_higro_12_max_monthly": 1,
          "rh_higro_12_min_monthly": 1,
          "rh_higro_12_sum_monthly": 1,
          "rh_higro_12_count_monthly": 1,
          "rh_higro_13_avg_monthly": 1,
          "rh_higro_13_mod_monthly": 1,
          "rh_higro_13_med_monthly": 1,
          "rh_higro_13_max_monthly": 1,
          "rh_higro_13_min_monthly": 1,
          "rh_higro_13_sum_monthly": 1,
          "rh_higro_13_count_monthly": 1,
          "rh_higro_14_avg_monthly": 1,
          "rh_higro_14_mod_monthly": 1,
          "rh_higro_14_med_monthly": 1,
          "rh_higro_14_max_monthly": 1,
          "rh_higro_14_min_monthly": 1,
          "rh_higro_14_sum_monthly": 1,
          "rh_higro_14_count_monthly": 1,
          "rh_higro_15_avg_monthly": 1,
          "rh_higro_15_mod_monthly": 1,
          "rh_higro_15_med_monthly": 1,
          "rh_higro_15_max_monthly": 1,
          "rh_higro_15_min_monthly": 1,
          "rh_higro_15_sum_monthly": 1,
          "rh_higro_15_count_monthly": 1,
          "rh_higro_16_avg_monthly": 1,
          "rh_higro_16_mod_monthly": 1,
          "rh_higro_16_med_monthly": 1,
          "rh_higro_16_max_monthly": 1,
          "rh_higro_16_min_monthly": 1,
          "rh_higro_16_sum_monthly": 1,
          "rh_higro_16_count_monthly": 1,
          "rh_higro_17_avg_monthly": 1,
          "rh_higro_17_mod_monthly": 1,
          "rh_higro_17_med_monthly": 1,
          "rh_higro_17_max_monthly": 1,
          "rh_higro_17_min_monthly": 1,
          "rh_higro_17_sum_monthly": " ",
          "rh_higro_17_count_monthly": 1,
          "rh_higro_18_avg_monthly": 1,
          "rh_higro_18_mod_monthly": 1,
          "rh_higro_18_med_monthly": 1,
          "rh_higro_18_max_monthly": 1,
          "rh_higro_18_min_monthly": 1,
          "rh_higro_18_sum_monthly": 1,
          "rh_higro_18_count_monthly": 1,
          "rh_higro_19_avg_monthly": 1,
          "rh_higro_19_mod_monthly": 1,
          "rh_higro_19_med_monthly": 1,
          "rh_higro_19_max_monthly": 1,
          "rh_higro_19_min_monthly": 1,
          "rh_higro_19_sum_monthly": 1,
          "rh_higro_19_count_monthly": 1,
          "rh_higro_20_avg_monthly": 1,
          "rh_higro_20_mod_monthly": 1,
          "rh_higro_20_med_monthly": 1,
          "rh_higro_20_max_monthly": 1,
          "rh_higro_20_min_monthly": 1,
          "rh_higro_20_sum_monthly": 1,
          "rh_higro_20_count_monthly": 1,
          "rh_higro_21_avg_monthly": 1,
          "rh_higro_21_mod_monthly": 1,
          "rh_higro_21_med_monthly": 1,
          "rh_higro_21_max_monthly": 1,
          "rh_higro_21_min_monthly": 1,
          "rh_higro_21_sum_monthly": 1,
          "rh_higro_21_count_monthly": 1,
          "rh_higro_22_avg_monthly": 1,
          "rh_higro_22_mod_monthly": 1,
          "rh_higro_22_med_monthly": 1,
          "rh_higro_22_max_monthly": 1,
          "rh_higro_22_min_monthly": 1,
          "rh_higro_22_sum_monthly": 1,
          "rh_higro_22_count_monthly": 1,
          "rh_higro_23_avg_monthly": 1,
          "rh_higro_23_mod_monthly": 1,
          "rh_higro_23_med_monthly": 1,
          "rh_higro_23_max_monthly": 1,
          "rh_higro_23_min_monthly": 1,
          "rh_higro_23_sum_monthly": 1,
          "rh_higro_23_count_monthly": 1,
          "rh_higro_24_avg_monthly": 1,
          "rh_higro_24_mod_monthly": 1,
          "rh_higro_24_med_monthly": 1,
          "rh_higro_24_max_monthly": 1,
          "rh_higro_24_min_monthly": 1,
          "rh_higro_24_sum_monthly": 1,
          "rh_higro_24_count_monthly": 1,
          "rh_max_higro_avg_monthly": 1,
          "rh_max_higro_mod_monthly": 1,
          "rh_max_higro_med_monthly": 1,
          "rh_max_higro_max_monthly": 1,
          "rh_max_higro_min_monthly": 1,
          "rh_max_higro_sum_monthly": 1,
          "rh_max_higro_count_monthly": 1,
          "rh_min_higro_avg_monthly": 1,
          "rh_min_higro_mod_monthly": 1,
          "rh_min_higro_med_monthly": 1,
          "rh_min_higro_max_monthly": 1,
          "rh_min_higro_min_monthly": 1,
          "rh_min_higro_sum_monthly": 1,
          "rh_min_higro_count_monthly": 1,
          "rh_avg_higro_avg_monthly": 1,
          "rh_avg_higro_mod_monthly": 1,
          "rh_avg_higro_med_monthly": 1,
          "rh_avg_higro_max_monthly": 1,
          "rh_avg_higro_min_monthly": 1,
          "rh_avg_higro_sum_monthly": 1,
          "rh_avg_higro_count_monthly": 1
        },
      },
    ];

    return listAttrMonthly;
  },

  getAttrDaily() {
      const listAttr = [
        {
          label: "Suhu Udara",
          attr: {
            tx_thermo: "Suhu Maksimum dari jam 1 sampai 24 DAILY",
            tn_thermo: "Suhu Min dari jam 1 sampai 24 DAILY",
            t_avg_thermo: "Suhu Rata-rata dari jam 1 sampai 24 DAILY"
          }
        }
      ];
      return listAttr;
    },

    getAttrDailyCsv() {
      const listAttr = [
        {
          label: "Suhu Udara",
          attr: {
            tx_thermo: "rh_max_higro_daily_PC",
            tn_thermo: "rh_min_higro_daily_PC",
            t_avg_thermo: "rh_avg_higro_daily_PC"
          },
        }
      ];
      return listAttr;
    },

    getAttrDailyDecimal() {
      const listAttr = [
        {
          label: "Suhu Udara",
          attr: {
            tx_thermo: 1,
            tn_thermo: 1,
            t_avg_thermo: 1
          },
        },
      ];
      return listAttr;
  },
};
