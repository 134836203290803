import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "Kelembaban Tanah",
        attr: {
          soilmoisture_10: "Kelembaban Tanah kedalaman 10 cm dalam persen",
          soilmoisture_20: "Kelembaban Tanah kedalaman 20 cm dalam persen",
          soilmoisture_30: "Kelembaban Tanah kedalaman 30 cm dalam persen",
          soilmoisture_40: "Kelembaban Tanah kedalaman 40 cm dalam persen",
          soilmoisture_60: "Kelembaban Tanah kedalaman 60 cm dalam persen",
          soilmoisture_100: "Kelembaban Tanah kedalaman 100 cm dalam persen",
        },
      },
    //   {
    //     label: "Observer",
    //     attr: {
    //         "observer_id" : "ID observer",
    //         "observer_name" : "Nama observer "
    //         },
    // },
    ];

    return listAttr;
  },

  getAttrMonthly() {
    const listAttr = [
      {
        group: "Kelembaban",
        form: "Kelembaban Tanah",
        label: "Kelembaban Tanah",
        attr: {
          "soilmoisture_10_avg_monthly": "Kelembaban Tanah kedalaman 10 cm dalam persen AVG MONTHLY",
          // "soilmoisture_10_mod_monthly": "Kelembaban Tanah kedalaman 10 cm dalam persen MOD MONTHLY",
          // "soilmoisture_10_med_monthly": "Kelembaban Tanah kedalaman 10 cm dalam persen MED MONTHLY",
          "soilmoisture_10_max_monthly": "Kelembaban Tanah kedalaman 10 cm dalam persen MAX MONTHLY",
          "soilmoisture_10_min_monthly": "Kelembaban Tanah kedalaman 10 cm dalam persen MIN MONTHLY",
          // "soilmoisture_10_sum_monthly": "Kelembaban Tanah kedalaman 10 cm dalam persen SUM MONTHLY",
          "soilmoisture_10_count_monthly": "Kelembaban Tanah kedalaman 10 cm dalam persen COUNT MONTHLY",
          "soilmoisture_20_avg_monthly": "Kelembaban Tanah kedalaman 20 cm dalam persen AVG MONTHLY",
          // "soilmoisture_20_mod_monthly": "Kelembaban Tanah kedalaman 20 cm dalam persen MOD MONTHLY",
          // "soilmoisture_20_med_monthly": "Kelembaban Tanah kedalaman 20 cm dalam persen MED MONTHLY",
          "soilmoisture_20_max_monthly": "Kelembaban Tanah kedalaman 20 cm dalam persen MAX MONTHLY",
          "soilmoisture_20_min_monthly": "Kelembaban Tanah kedalaman 20 cm dalam persen MIN MONTHLY",
          // "soilmoisture_20_sum_monthly": "Kelembaban Tanah kedalaman 20 cm dalam persen SUM MONTHLY",
          "soilmoisture_20_count_monthly": "Kelembaban Tanah kedalaman 20 cm dalam persen COUNT MONTHLY",
          "soilmoisture_30_avg_monthly": "Kelembaban Tanah kedalaman 30 cm dalam persen AVG MONTHLY",
          // "soilmoisture_30_mod_monthly": "Kelembaban Tanah kedalaman 30 cm dalam persen MOD MONTHLY",
          // "soilmoisture_30_med_monthly": "Kelembaban Tanah kedalaman 30 cm dalam persen MED MONTHLY",
          "soilmoisture_30_max_monthly": "Kelembaban Tanah kedalaman 30 cm dalam persen MAX MONTHLY",
          "soilmoisture_30_min_monthly": "Kelembaban Tanah kedalaman 30 cm dalam persen MIN MONTHLY",
          // "soilmoisture_30_sum_monthly": "Kelembaban Tanah kedalaman 30 cm dalam persen SUM MONTHLY",
          "soilmoisture_30_count_monthly": "Kelembaban Tanah kedalaman 30 cm dalam persen COUNT MONTHLY",
          "soilmoisture_40_avg_monthly": "Kelembaban Tanah kedalaman 40 cm dalam persen AVG MONTHLY",
          // "soilmoisture_40_mod_monthly": "Kelembaban Tanah kedalaman 40 cm dalam persen MOD MONTHLY",
          // "soilmoisture_40_med_monthly": "Kelembaban Tanah kedalaman 40 cm dalam persen MED MONTHLY",
          "soilmoisture_40_max_monthly": "Kelembaban Tanah kedalaman 40 cm dalam persen MAX MONTHLY",
          "soilmoisture_40_min_monthly": "Kelembaban Tanah kedalaman 40 cm dalam persen MIN MONTHLY",
          // "soilmoisture_40_sum_monthly": "Kelembaban Tanah kedalaman 40 cm dalam persen SUM MONTHLY",
          "soilmoisture_40_count_monthly": "Kelembaban Tanah kedalaman 40 cm dalam persen COUNT MONTHLY",
          "soilmoisture_60_avg_monthly": "Kelembaban Tanah kedalaman 60 cm dalam persen AVG MONTHLY",
          // "soilmoisture_60_mod_monthly": "Kelembaban Tanah kedalaman 60 cm dalam persen MOD MONTHLY",
          // "soilmoisture_60_med_monthly": "Kelembaban Tanah kedalaman 60 cm dalam persen MED MONTHLY",
          "soilmoisture_60_max_monthly": "Kelembaban Tanah kedalaman 60 cm dalam persen MAX MONTHLY",
          "soilmoisture_60_min_monthly": "Kelembaban Tanah kedalaman 60 cm dalam persen MIN MONTHLY",
          // "soilmoisture_60_sum_monthly": "Kelembaban Tanah kedalaman 60 cm dalam persen SUM MONTHLY",
          "soilmoisture_60_count_monthly": "Kelembaban Tanah kedalaman 60 cm dalam persen COUNT MONTHLY",
          "soilmoisture_100_avg_monthly": "Kelembaban Tanah kedalaman 100 cm dalam persen AVG MONTHLY",
          // "soilmoisture_100_mod_monthly": "Kelembaban Tanah kedalaman 100 cm dalam persen MOD MONTHLY",
          // "soilmoisture_100_med_monthly": "Kelembaban Tanah kedalaman 100 cm dalam persen MED MONTHLY",
          "soilmoisture_100_max_monthly": "Kelembaban Tanah kedalaman 100 cm dalam persen MAX MONTHLY",
          "soilmoisture_100_min_monthly": "Kelembaban Tanah kedalaman 100 cm dalam persen MIN MONTHLY",
          // "soilmoisture_100_sum_monthly": "Kelembaban Tanah kedalaman 100 cm dalam persen SUM MONTHLY",
          "soilmoisture_100_count_monthly": "Kelembaban Tanah kedalaman 100 cm dalam persen COUNT MONTHLY" 
        },
      },
    ];

    return listAttr;
  },

  getAttrMonthlyCsv() {
    const listAttr = [
      {
        label: "Kelembaban Tanah",
        attr: {
          "soilmoisture_10_avg_monthly": "soilmoisture_10_monthly_avg_PC",
          "soilmoisture_10_mod_monthly": "soilmoisture_10_monthly_mod_PC",
          "soilmoisture_10_med_monthly": "soilmoisture_10_monthly_med_PC",
          "soilmoisture_10_max_monthly": "soilmoisture_10_monthly_max_PC",
          "soilmoisture_10_min_monthly": "soilmoisture_10_monthly_min_PC",
          "soilmoisture_10_sum_monthly": "soilmoisture_10_monthly_sum_PC",
          "soilmoisture_10_count_monthly": "count_soilmoisture_10_monthly",
          "soilmoisture_20_avg_monthly": "soilmoisture_20_monthly_avg_PC",
          "soilmoisture_20_mod_monthly": "soilmoisture_20_monthly_mod_PC",
          "soilmoisture_20_med_monthly": "soilmoisture_20_monthly_med_PC",
          "soilmoisture_20_max_monthly": "soilmoisture_20_monthly_max_PC",
          "soilmoisture_20_min_monthly": "soilmoisture_20_monthly_min_PC",
          "soilmoisture_20_sum_monthly": "soilmoisture_20_monthly_sum_PC",
          "soilmoisture_20_count_monthly": "count_soilmoisture_20_monthly",
          "soilmoisture_30_avg_monthly": "soilmoisture_30_monthly_avg_PC",
          "soilmoisture_30_mod_monthly": "soilmoisture_30_monthly_mod_PC",
          "soilmoisture_30_med_monthly": "soilmoisture_30_monthly_med_PC",
          "soilmoisture_30_max_monthly": "soilmoisture_30_monthly_max_PC",
          "soilmoisture_30_min_monthly": "soilmoisture_30_monthly_min_PC",
          "soilmoisture_30_sum_monthly": "soilmoisture_30_monthly_sum_PC",
          "soilmoisture_30_count_monthly": "count_soilmoisture_30_monthly",
          "soilmoisture_40_avg_monthly": "soilmoisture_40_monthly_avg_PC",
          "soilmoisture_40_mod_monthly": "soilmoisture_40_monthly_mod_PC",
          "soilmoisture_40_med_monthly": "soilmoisture_40_monthly_med_PC",
          "soilmoisture_40_max_monthly": "soilmoisture_40_monthly_max_PC",
          "soilmoisture_40_min_monthly": "soilmoisture_40_monthly_min_PC",
          "soilmoisture_40_sum_monthly": "soilmoisture_40_monthly_sum_PC",
          "soilmoisture_40_count_monthly": "count_soilmoisture_40_monthly",
          "soilmoisture_60_avg_monthly": "soilmoisture_40_monthly_avg_PC",
          "soilmoisture_60_mod_monthly": "soilmoisture_40_monthly_mod_PC",
          "soilmoisture_60_med_monthly": "soilmoisture_40_monthly_med_PC",
          "soilmoisture_60_max_monthly": "soilmoisture_40_monthly_max_PC",
          "soilmoisture_60_min_monthly": "soilmoisture_40_monthly_min_PC",
          "soilmoisture_60_sum_monthly": "soilmoisture_40_monthly_sum_PC",
          "soilmoisture_60_count_monthly": "count_soilmoisture_40_monthly",
          "soilmoisture_100_avg_monthly": "soilmoisture_40_monthly_avg_PC",
          "soilmoisture_100_mod_monthly": "soilmoisture_40_monthly_mod_PC",
          "soilmoisture_100_med_monthly": "soilmoisture_40_monthly_med_PC",
          "soilmoisture_100_max_monthly": "soilmoisture_40_monthly_max_PC",
          "soilmoisture_100_min_monthly": "soilmoisture_40_monthly_min_PC",
          "soilmoisture_100_sum_monthly": "soilmoisture_40_monthly_sum_PC",
          "soilmoisture_100_count_monthly": "count_soilmoisture_40_monthly",
        },
      },
    ];

    return listAttr;
  },

  getAttrMonthlyDecimal() {
    const listAttr = [
      {
        label: "Kelembaban Tanah",
        attr: {
          "soilmoisture_10_avg_monthly": 0,
          "soilmoisture_10_mod_monthly": 0,
          "soilmoisture_10_med_monthly": 0,
          "soilmoisture_10_max_monthly": 0,
          "soilmoisture_10_min_monthly": 0,
          "soilmoisture_10_sum_monthly": 0,
          "soilmoisture_10_count_monthly": 0,
          "soilmoisture_20_avg_monthly": 0,
          "soilmoisture_20_mod_monthly": 0,
          "soilmoisture_20_med_monthly": 0,
          "soilmoisture_20_max_monthly": 0,
          "soilmoisture_20_min_monthly": 0,
          "soilmoisture_20_sum_monthly": 0,
          "soilmoisture_20_count_monthly": 0,
          "soilmoisture_30_avg_monthly": 0,
          "soilmoisture_30_mod_monthly": 0,
          "soilmoisture_30_med_monthly": 0,
          "soilmoisture_30_max_monthly": 0,
          "soilmoisture_30_min_monthly": 0,
          "soilmoisture_30_sum_monthly": 0,
          "soilmoisture_30_count_monthly": 0,
          "soilmoisture_40_avg_monthly": 0,
          "soilmoisture_40_mod_monthly": 0,
          "soilmoisture_40_med_monthly": 0,
          "soilmoisture_40_max_monthly": 0,
          "soilmoisture_40_min_monthly": 0,
          "soilmoisture_40_sum_monthly": 0,
          "soilmoisture_40_count_monthly": 0,
          "soilmoisture_60_avg_monthly": 0,
          "soilmoisture_60_mod_monthly": 0,
          "soilmoisture_60_med_monthly": 0,
          "soilmoisture_60_max_monthly": 0,
          "soilmoisture_60_min_monthly": 0,
          "soilmoisture_60_sum_monthly": 0,
          "soilmoisture_60_count_monthly": 0,
          "soilmoisture_100_avg_monthly": 0,
          "soilmoisture_100_mod_monthly": 0,
          "soilmoisture_100_med_monthly": 0,
          "soilmoisture_100_max_monthly": 0,
          "soilmoisture_100_min_monthly": 0,
          "soilmoisture_100_sum_monthly": 0,
          "soilmoisture_100_count_monthly": 0,
        },
      },
    ];

    return listAttr;
  },
};
