import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "Temperatur",
        attr: {
          ttr_0cm_0730: "Suhu tanah rumput kedalaman 0 Cm di jam 07.30",
          ttr_2cm_0730: "Suhu tanah rumput kedalaman 2 Cm di jam 07.30",
          ttr_5cm_0730: "Suhu tanah rumput kedalaman 5 Cm di jam 07.30",
          ttr_10cm_0730: "Suhu tanah rumput kedalaman 10 Cm di jam 07.30",
          ttr_20cm_0730: "Suhu tanah rumput kedalaman 20 Cm di jam 07.30",
          ttr_50cm_0730: "Suhu tanah rumput kedalaman 50 Cm di jam 07.30",
          ttr_100cm_0730: "Suhu tanah rumput kedalaman 100 Cm di jam 07.30",
          ttg_0cm_0730: "Suhu tanah gundul kedalaman 0 Cm di jam 07.30",
          ttg_2cm_0730: "Suhu tanah gundul kedalaman 2 Cm di jam 07.30",
          ttg_5cm_0730: "Suhu tanah gundul kedalaman 5 Cm di jam 07.30",
          ttg_10cm_0730: "Suhu tanah gundul kedalaman 10 Cm di jam 07.30",
          ttg_20cm_0730: "Suhu tanah gundul kedalaman 20 Cm di jam 07.30",
          ttg_50cm_0730: "Suhu tanah gundul kedalaman 50 Cm di jam 07.30",
          ttg_100cm_0730: "Suhu tanah gundul kedalaman 100 Cm di jam 07.30",
          ttr_0cm_1330: "Suhu tanah rumput kedalaman 0 Cm di jam 13.30",
          ttr_2cm_1330: "Suhu tanah rumput kedalaman 2 Cm di jam 13.30",
          ttr_5cm_1330: "Suhu tanah rumput kedalaman 5 Cm di jam 13.30",
          ttr_10cm_1330: "Suhu tanah rumput kedalaman 10 Cm di jam 13.30",
          ttr_20cm_1330: "Suhu tanah rumput kedalaman 20 Cm di jam 13.30",
          ttr_50cm_1330: "Suhu tanah rumput kedalaman 50 Cm di jam 13.30",
          ttr_100cm_1330: "Suhu tanah rumput kedalaman 100 Cm di jam 13.30",
          ttg_0cm_1330: "Suhu tanah gundul kedalaman 0 Cm di jam 13.30",
          ttg_2cm_1330: "Suhu tanah gundul kedalaman 2 Cm di jam 13.30",
          ttg_5cm_1330: "Suhu tanah gundul kedalaman 5 Cm di jam 13.30",
          ttg_10cm_1330: "Suhu tanah gundul kedalaman 10 Cm di jam 13.30",
          ttg_20cm_1330: "Suhu tanah gundul kedalaman 20 Cm di jam 13.30",
          ttg_50cm_1330: "Suhu tanah gundul kedalaman 50 Cm di jam 13.30",
          ttg_100cm_1330: "Suhu tanah gundul kedalaman 100 Cm di jam 13.30",
          ttr_0cm_1730: "Suhu tanah rumput kedalaman 0 Cm di jam 17.30",
          ttr_2cm_1730: "Suhu tanah rumput kedalaman 2 Cm di jam 17.30",
          ttr_5cm_1730: "Suhu tanah rumput kedalaman 5 Cm di jam 17.30",
          ttr_10cm_1730: "Suhu tanah rumput kedalaman 10 Cm di jam 17.30",
          ttr_20cm_1730: "Suhu tanah rumput kedalaman 20 Cm di jam 17.30",
          ttr_50cm_1730: "Suhu tanah rumput kedalaman 50 Cm di jam 17.30",
          ttr_100cm_1730: "Suhu tanah rumput kedalaman 100 Cm di jam 17.30",
          ttg_0cm_1730: "Suhu tanah gundul kedalaman 0 Cm di jam 17.30",
          ttg_2cm_1730: "Suhu tanah gundul kedalaman 2 Cm di jam 17.30",
          ttg_5cm_1730: "Suhu tanah gundul kedalaman 5 Cm di jam 17.30",
          ttg_10cm_1730: "Suhu tanah gundul kedalaman 10 Cm di jam 17.30",
          ttg_20cm_1730: "Suhu tanah gundul kedalaman 20 Cm di jam 17.30",
          ttg_50cm_1730: "Suhu tanah gundul kedalaman 50 Cm di jam 17.30",
          ttg_100cm_1730: "Suhu tanah gundul kedalaman 100 Cm di jam 17.30",
        },
      },
    ];
    return listAttr;
  },

  getAttrMonthly() {
    const listAttr = [
      {
        group: "Temperatur",
        form: "Suhu Tanah",
        label: "Temperatur",
        attr: {
          ttr_0cm_0730_avg_monthly:
            "Suhu Tanah Rumput Kedalaman 0 Cm di jam 07.30 AVG MONTHLY",
          // "ttr_0cm_0730_mod_monthly" : "Suhu Tanah Rumput Kedalaman 0 Cm di jam 07.30 MOD MONTHLY",
          // "ttr_0cm_0730_med_monthly" : "Suhu Tanah Rumput Kedalaman 0 Cm di jam 07.30 MED MONTHLY",
          // "ttr_0cm_0730_max_monthly" : "Suhu Tanah Rumput Kedalaman 0 Cm di jam 07.30 MAX MONTHLY",
          // "ttr_0cm_0730_min_monthly" : "Suhu Tanah Rumput Kedalaman 0 Cm di jam 07.30 MIN MONTHLY",
          // "ttr_0cm_0730_sum_monthly" : "Suhu Tanah Rumput Kedalaman 0 Cm di jam 07.30 SUM MONTHLY",
          ttr_0cm_0730_count_monthly:
            "Suhu Tanah Rumput Kedalaman 0 Cm di jam 07.30 COUNT MONTHLY",
          ttr_2cm_0730_avg_monthly:
            "Suhu Tanah Rumput Kedalaman 2 Cm di jam 07.30 AVG MONTHLY",
          // "ttr_2cm_0730_mod_monthly" : "Suhu Tanah Rumput Kedalaman 2 Cm di jam 07.30 MOD MONTHLY",
          // "ttr_2cm_0730_med_monthly" : "Suhu Tanah Rumput Kedalaman 2 Cm di jam 07.30 MED MONTHLY",
          // "ttr_2cm_0730_max_monthly" : "Suhu Tanah Rumput Kedalaman 2 Cm di jam 07.30 MAX MONTHLY",
          // "ttr_2cm_0730_min_monthly" : "Suhu Tanah Rumput Kedalaman 2 Cm di jam 07.30 MIN MONTHLY",
          // "ttr_2cm_0730_sum_monthly" : "Suhu Tanah Rumput Kedalaman 2 Cm di jam 07.30 SUM MONTHLY",
          ttr_2cm_0730_count_monthly:
            "Suhu Tanah Rumput Kedalaman 2 Cm di jam 07.30 COUNT MONTHLY",
          ttr_5cm_0730_avg_monthly:
            "Suhu Tanah Rumput Kedalaman 5 Cm di jam 07.30 AVG MONTHLY",
          // "ttr_5cm_0730_mod_monthly" : "Suhu Tanah Rumput Kedalaman 5 Cm di jam 07.30 MOD MONTHLY",
          // "ttr_5cm_0730_med_monthly" : "Suhu Tanah Rumput Kedalaman 5 Cm di jam 07.30 MED MONTHLY",
          // "ttr_5cm_0730_max_monthly" : "Suhu Tanah Rumput Kedalaman 5 Cm di jam 07.30 MAX MONTHLY",
          // "ttr_5cm_0730_min_monthly" : "Suhu Tanah Rumput Kedalaman 5 Cm di jam 07.30 MIN MONTHLY",
          // "ttr_5cm_0730_sum_monthly" : "Suhu Tanah Rumput Kedalaman 5 Cm di jam 07.30 SUM MONTHLY",
          ttr_5cm_0730_count_monthly:
            "Suhu Tanah Rumput Kedalaman 5 Cm di jam 07.30 COUNT MONTHLY",
          ttr_10cm_0730_avg_monthly:
            "Suhu Tanah Rumput Kedalaman 10 Cm di jam 07.30 AVG MONTHLY",
          // "ttr_10cm_0730_mod_monthly" : "Suhu Tanah Rumput Kedalaman 10 Cm di jam 07.30 MOD MONTHLY",
          // "ttr_10cm_0730_med_monthly" : "Suhu Tanah Rumput Kedalaman 10 Cm di jam 07.30 MED MONTHLY",
          // "ttr_10cm_0730_max_monthly" : "Suhu Tanah Rumput Kedalaman 10 Cm di jam 07.30 MAX MONTHLY",
          // "ttr_10cm_0730_min_monthly" : "Suhu Tanah Rumput Kedalaman 10 Cm di jam 07.30 MIN MONTHLY",
          // "ttr_10cm_0730_sum_monthly" : "Suhu Tanah Rumput Kedalaman 10 Cm di jam 07.30 SUM MONTHLY",
          ttr_10cm_0730_count_monthly:
            "Suhu Tanah Rumput Kedalaman 10 Cm di jam 07.30 COUNT MONTHLY",
          ttr_20cm_0730_avg_monthly:
            "Suhu Tanah Rumput Kedalaman 20 Cm di jam 07.30 AVG MONTHLY",
          // "ttr_20cm_0730_mod_monthly" : "Suhu Tanah Rumput Kedalaman 20 Cm di jam 07.30 MOD MONTHLY",
          // "ttr_20cm_0730_med_monthly" : "Suhu Tanah Rumput Kedalaman 20 Cm di jam 07.30 MED MONTHLY",
          // "ttr_20cm_0730_max_monthly" : "Suhu Tanah Rumput Kedalaman 20 Cm di jam 07.30 MAX MONTHLY",
          // "ttr_20cm_0730_min_monthly" : "Suhu Tanah Rumput Kedalaman 20 Cm di jam 07.30 MIN MONTHLY",
          // "ttr_20cm_0730_sum_monthly" : "Suhu Tanah Rumput Kedalaman 20 Cm di jam 07.30 SUM MONTHLY",
          ttr_20cm_0730_count_monthly:
            "Suhu Tanah Rumput Kedalaman 20 Cm di jam 07.30 COUNT MONTHLY",
          ttr_50cm_0730_avg_monthly:
            "Suhu Tanah Rumput Kedalaman 50 Cm di jam 07.30 AVG MONTHLY",
          // "ttr_50cm_0730_mod_monthly" : "Suhu Tanah Rumput Kedalaman 50 Cm di jam 07.30 MOD MONTHLY",
          // "ttr_50cm_0730_med_monthly" : "Suhu Tanah Rumput Kedalaman 50 Cm di jam 07.30 MED MONTHLY",
          // "ttr_50cm_0730_max_monthly" : "Suhu Tanah Rumput Kedalaman 50 Cm di jam 07.30 MAX MONTHLY",
          // "ttr_50cm_0730_min_monthly" : "Suhu Tanah Rumput Kedalaman 50 Cm di jam 07.30 MIN MONTHLY",
          // "ttr_50cm_0730_sum_monthly" : "Suhu Tanah Rumput Kedalaman 50 Cm di jam 07.30 SUM MONTHLY",
          ttr_50cm_0730_count_monthly:
            "Suhu Tanah Rumput Kedalaman 50 Cm di jam 07.30 COUNT MONTHLY",
          ttr_100cm_0730_avg_monthly:
            "Suhu Tanah Rumput Kedalaman 100 Cm di jam 07.30 AVG MONTHLY",
          // "ttr_100cm_0730_mod_monthly" : "Suhu Tanah Rumput Kedalaman 100 Cm di jam 07.30 MOD MONTHLY",
          // "ttr_100cm_0730_med_monthly" : "Suhu Tanah Rumput Kedalaman 100 Cm di jam 07.30 MED MONTHLY",
          // "ttr_100cm_0730_max_monthly" : "Suhu Tanah Rumput Kedalaman 100 Cm di jam 07.30 MAX MONTHLY",
          // "ttr_100cm_0730_min_monthly" : "Suhu Tanah Rumput Kedalaman 100 Cm di jam 07.30 MIN MONTHLY",
          // "ttr_100cm_0730_sum_monthly" : "Suhu Tanah Rumput Kedalaman 100 Cm di jam 07.30 SUM MONTHLY",
          ttr_100cm_0730_count_monthly:
            "Suhu Tanah Rumput Kedalaman 100 Cm di jam 07.30 COUNT MONTHLY",

          ttg_0cm_0730_avg_monthly:
            "Suhu Tanah Gundul Kedalaman 0 Cm di jam 07.30 AVG MONTHLY",
          // "ttg_0cm_0730_mod_monthly" : "Suhu Tanah Gundul Kedalaman 0 Cm di jam 07.30 MOD MONTHLY",
          // "ttg_0cm_0730_med_monthly" : "Suhu Tanah Gundul Kedalaman 0 Cm di jam 07.30 MED MONTHLY",
          // "ttg_0cm_0730_max_monthly" : "Suhu Tanah Gundul Kedalaman 0 Cm di jam 07.30 MAX MONTHLY",
          // "ttg_0cm_0730_min_monthly" : "Suhu Tanah Gundul Kedalaman 0 Cm di jam 07.30 MIN MONTHLY",
          // "ttg_0cm_0730_sum_monthly" : "Suhu Tanah Gundul Kedalaman 0 Cm di jam 07.30 SUM MONTHLY",
          ttg_0cm_0730_count_monthly:
            "Suhu Tanah Gundul Kedalaman 0 Cm di jam 07.30 COUNT MONTHLY",
          ttg_2cm_0730_avg_monthly:
            "Suhu Tanah Gundul Kedalaman 2 Cm di jam 07.30 AVG MONTHLY",
          // "ttg_2cm_0730_mod_monthly" : "Suhu Tanah Gundul Kedalaman 2 Cm di jam 07.30 MOD MONTHLY",
          // "ttg_2cm_0730_med_monthly" : "Suhu Tanah Gundul Kedalaman 2 Cm di jam 07.30 MED MONTHLY",
          // "ttg_2cm_0730_max_monthly" : "Suhu Tanah Gundul Kedalaman 2 Cm di jam 07.30 MAX MONTHLY",
          // "ttg_2cm_0730_min_monthly" : "Suhu Tanah Gundul Kedalaman 2 Cm di jam 07.30 MIN MONTHLY",
          // "ttg_2cm_0730_sum_monthly" : "Suhu Tanah Gundul Kedalaman 2 Cm di jam 07.30 SUM MONTHLY",
          ttg_2cm_0730_count_monthly:
            "Suhu Tanah Gundul Kedalaman 2 Cm di jam 07.30 COUNT MONTHLY",
          ttg_5cm_0730_avg_monthly:
            "Suhu Tanah Gundul Kedalaman 5 Cm di jam 07.30 AVG MONTHLY",
          // "ttg_5cm_0730_mod_monthly" : "Suhu Tanah Gundul Kedalaman 5 Cm di jam 07.30 MOD MONTHLY",
          // "ttg_5cm_0730_med_monthly" : "Suhu Tanah Gundul Kedalaman 5 Cm di jam 07.30 MED MONTHLY",
          // "ttg_5cm_0730_max_monthly" : "Suhu Tanah Gundul Kedalaman 5 Cm di jam 07.30 MAX MONTHLY",
          // "ttg_5cm_0730_min_monthly" : "Suhu Tanah Gundul Kedalaman 5 Cm di jam 07.30 MIN MONTHLY",
          // "ttg_5cm_0730_sum_monthly" : "Suhu Tanah Gundul Kedalaman 5 Cm di jam 07.30 SUM MONTHLY",
          ttg_5cm_0730_count_monthly:
            "Suhu Tanah Gundul Kedalaman 5 Cm di jam 07.30 COUNT MONTHLY",
          ttg_10cm_0730_avg_monthly:
            "Suhu Tanah Gundul Kedalaman 10 Cm di jam 07.30 AVG MONTHLY",
          // "ttg_10cm_0730_mod_monthly" : "Suhu Tanah Gundul Kedalaman 10 Cm di jam 07.30 MOD MONTHLY",
          // "ttg_10cm_0730_med_monthly" : "Suhu Tanah Gundul Kedalaman 10 Cm di jam 07.30 MED MONTHLY",
          // "ttg_10cm_0730_max_monthly" : "Suhu Tanah Gundul Kedalaman 10 Cm di jam 07.30 MAX MONTHLY",
          // "ttg_10cm_0730_min_monthly" : "Suhu Tanah Gundul Kedalaman 10 Cm di jam 07.30 MIN MONTHLY",
          // "ttg_10cm_0730_sum_monthly" : "Suhu Tanah Gundul Kedalaman 10 Cm di jam 07.30 SUM MONTHLY",
          ttg_10cm_0730_count_monthly:
            "Suhu Tanah Gundul Kedalaman 10 Cm di jam 07.30 COUNT MONTHLY",
          ttg_20cm_0730_avg_monthly:
            "Suhu Tanah Gundul Kedalaman 20 Cm di jam 07.30 AVG MONTHLY",
          // "ttg_20cm_0730_mod_monthly" : "Suhu Tanah Gundul Kedalaman 20 Cm di jam 07.30 MOD MONTHLY",
          // "ttg_20cm_0730_med_monthly" : "Suhu Tanah Gundul Kedalaman 20 Cm di jam 07.30 MED MONTHLY",
          // "ttg_20cm_0730_max_monthly" : "Suhu Tanah Gundul Kedalaman 20 Cm di jam 07.30 MAX MONTHLY",
          // "ttg_20cm_0730_min_monthly" : "Suhu Tanah Gundul Kedalaman 20 Cm di jam 07.30 MIN MONTHLY",
          // "ttg_20cm_0730_sum_monthly" : "Suhu Tanah Gundul Kedalaman 20 Cm di jam 07.30 SUM MONTHLY",
          ttg_20cm_0730_count_monthly:
            "Suhu Tanah Gundul Kedalaman 20 Cm di jam 07.30 COUNT MONTHLY",
          ttg_50cm_0730_avg_monthly:
            "Suhu Tanah Gundul Kedalaman 50 Cm di jam 07.30 AVG MONTHLY",
          // "ttg_50cm_0730_mod_monthly" : "Suhu Tanah Gundul Kedalaman 50 Cm di jam 07.30 MOD MONTHLY",
          // "ttg_50cm_0730_med_monthly" : "Suhu Tanah Gundul Kedalaman 50 Cm di jam 07.30 MED MONTHLY",
          // "ttg_50cm_0730_max_monthly" : "Suhu Tanah Gundul Kedalaman 50 Cm di jam 07.30 MAX MONTHLY",
          // "ttg_50cm_0730_min_monthly" : "Suhu Tanah Gundul Kedalaman 50 Cm di jam 07.30 MIN MONTHLY",
          // "ttg_50cm_0730_sum_monthly" : "Suhu Tanah Gundul Kedalaman 50 Cm di jam 07.30 SUM MONTHLY",
          ttg_50cm_0730_count_monthly:
            "Suhu Tanah Gundul Kedalaman 50 Cm di jam 07.30 COUNT MONTHLY",
          ttg_100cm_0730_avg_monthly:
            "Suhu Tanah Gundul Kedalaman 100 Cm di jam 07.30 AVG MONTHLY",
          // "ttg_100cm_0730_mod_monthly" : "Suhu Tanah Gundul Kedalaman 100 Cm di jam 07.30 MOD MONTHLY",
          // "ttg_100cm_0730_med_monthly" : "Suhu Tanah Gundul Kedalaman 100 Cm di jam 07.30 MED MONTHLY",
          // "ttg_100cm_0730_max_monthly" : "Suhu Tanah Gundul Kedalaman 100 Cm di jam 07.30 MAX MONTHLY",
          // "ttg_100cm_0730_min_monthly" : "Suhu Tanah Gundul Kedalaman 100 Cm di jam 07.30 MIN MONTHLY",
          // "ttg_100cm_0730_sum_monthly" : "Suhu Tanah Gundul Kedalaman 100 Cm di jam 07.30 SUM MONTHLY",
          ttg_100cm_0730_count_monthly:
            "Suhu Tanah Gundul Kedalaman 100 Cm di jam 07.30 COUNT MONTHLY",

          ttr_0cm_1330_avg_monthly:
            "Suhu Tanah Rumput Kedalaman 0 Cm di jam 13.30 AVG MONTHLY",
          // "ttr_0cm_1330_mod_monthly" : "Suhu Tanah Rumput Kedalaman 0 Cm di jam 13.30 MOD MONTHLY",
          // "ttr_0cm_1330_med_monthly" : "Suhu Tanah Rumput Kedalaman 0 Cm di jam 13.30 MED MONTHLY",
          // "ttr_0cm_1330_max_monthly" : "Suhu Tanah Rumput Kedalaman 0 Cm di jam 13.30 MAX MONTHLY",
          // "ttr_0cm_1330_min_monthly" : "Suhu Tanah Rumput Kedalaman 0 Cm di jam 13.30 MIN MONTHLY",
          // "ttr_0cm_1330_sum_monthly" : "Suhu Tanah Rumput Kedalaman 0 Cm di jam 13.30 SUM MONTHLY",
          ttr_0cm_1330_count_monthly:
            "Suhu Tanah Rumput Kedalaman 0 Cm di jam 13.30 COUNT MONTHLY",
          ttr_2cm_1330_avg_monthly:
            "Suhu Tanah Rumput Kedalaman 2 Cm di jam 13.30 AVG MONTHLY",
          // "ttr_2cm_1330_mod_monthly" : "Suhu Tanah Rumput Kedalaman 2 Cm di jam 13.30 MOD MONTHLY",
          // "ttr_2cm_1330_med_monthly" : "Suhu Tanah Rumput Kedalaman 2 Cm di jam 13.30 MED MONTHLY",
          // "ttr_2cm_1330_max_monthly" : "Suhu Tanah Rumput Kedalaman 2 Cm di jam 13.30 MAX MONTHLY",
          // "ttr_2cm_1330_min_monthly" : "Suhu Tanah Rumput Kedalaman 2 Cm di jam 13.30 MIN MONTHLY",
          // "ttr_2cm_1330_sum_monthly" : "Suhu Tanah Rumput Kedalaman 2 Cm di jam 13.30 SUM MONTHLY",
          ttr_2cm_1330_count_monthly:
            "Suhu Tanah Rumput Kedalaman 2 Cm di jam 13.30 COUNT MONTHLY",
          ttr_5cm_1330_avg_monthly:
            "Suhu Tanah Rumput Kedalaman 5 Cm di jam 13.30 AVG MONTHLY",
          // "ttr_5cm_1330_mod_monthly" : "Suhu Tanah Rumput Kedalaman 5 Cm di jam 13.30 MOD MONTHLY",
          // "ttr_5cm_1330_med_monthly" : "Suhu Tanah Rumput Kedalaman 5 Cm di jam 13.30 MED MONTHLY",
          // "ttr_5cm_1330_max_monthly" : "Suhu Tanah Rumput Kedalaman 5 Cm di jam 13.30 MAX MONTHLY",
          // "ttr_5cm_1330_min_monthly" : "Suhu Tanah Rumput Kedalaman 5 Cm di jam 13.30 MIN MONTHLY",
          // "ttr_5cm_1330_sum_monthly" : "Suhu Tanah Rumput Kedalaman 5 Cm di jam 13.30 SUM MONTHLY",
          ttr_5cm_1330_count_monthly:
            "Suhu Tanah Rumput Kedalaman 5 Cm di jam 13.30 COUNT MONTHLY",
          ttr_10cm_1330_avg_monthly:
            "Suhu Tanah Rumput Kedalaman 10 Cm di jam 13.30 AVG MONTHLY",
          // "ttr_10cm_1330_mod_monthly" : "Suhu Tanah Rumput Kedalaman 10 Cm di jam 13.30 MOD MONTHLY",
          // "ttr_10cm_1330_med_monthly" : "Suhu Tanah Rumput Kedalaman 10 Cm di jam 13.30 MED MONTHLY",
          // "ttr_10cm_1330_max_monthly" : "Suhu Tanah Rumput Kedalaman 10 Cm di jam 13.30 MAX MONTHLY",
          // "ttr_10cm_1330_min_monthly" : "Suhu Tanah Rumput Kedalaman 10 Cm di jam 13.30 MIN MONTHLY",
          // "ttr_10cm_1330_sum_monthly" : "Suhu Tanah Rumput Kedalaman 10 Cm di jam 13.30 SUM MONTHLY",
          ttr_10cm_1330_count_monthly:
            "Suhu Tanah Rumput Kedalaman 10 Cm di jam 13.30 COUNT MONTHLY",
          ttr_20cm_1330_avg_monthly:
            "Suhu Tanah Rumput Kedalaman 20 Cm di jam 13.30 AVG MONTHLY",
          // "ttr_20cm_1330_mod_monthly" : "Suhu Tanah Rumput Kedalaman 20 Cm di jam 13.30 MOD MONTHLY",
          // "ttr_20cm_1330_med_monthly" : "Suhu Tanah Rumput Kedalaman 20 Cm di jam 13.30 MED MONTHLY",
          // "ttr_20cm_1330_max_monthly" : "Suhu Tanah Rumput Kedalaman 20 Cm di jam 13.30 MAX MONTHLY",
          // "ttr_20cm_1330_min_monthly" : "Suhu Tanah Rumput Kedalaman 20 Cm di jam 13.30 MIN MONTHLY",
          // "ttr_20cm_1330_sum_monthly" : "Suhu Tanah Rumput Kedalaman 20 Cm di jam 13.30 SUM MONTHLY",
          ttr_20cm_1330_count_monthly:
            "Suhu Tanah Rumput Kedalaman 20 Cm di jam 13.30 COUNT MONTHLY",
          ttr_50cm_1330_avg_monthly:
            "Suhu Tanah Rumput Kedalaman 50 Cm di jam 13.30 AVG MONTHLY",
          // "ttr_50cm_1330_mod_monthly" : "Suhu Tanah Rumput Kedalaman 50 Cm di jam 13.30 MOD MONTHLY",
          // "ttr_50cm_1330_med_monthly" : "Suhu Tanah Rumput Kedalaman 50 Cm di jam 13.30 MED MONTHLY",
          // "ttr_50cm_1330_max_monthly" : "Suhu Tanah Rumput Kedalaman 50 Cm di jam 13.30 MAX MONTHLY",
          // "ttr_50cm_1330_min_monthly" : "Suhu Tanah Rumput Kedalaman 50 Cm di jam 13.30 MIN MONTHLY",
          // "ttr_50cm_1330_sum_monthly" : "Suhu Tanah Rumput Kedalaman 50 Cm di jam 13.30 SUM MONTHLY",
          ttr_50cm_1330_count_monthly:
            "Suhu Tanah Rumput Kedalaman 50 Cm di jam 13.30 COUNT MONTHLY",
          ttr_100cm_1330_avg_monthly:
            "Suhu Tanah Rumput Kedalaman 100 Cm di jam 13.30 AVG MONTHLY",
          // "ttr_100cm_1330_mod_monthly" : "Suhu Tanah Rumput Kedalaman 100 Cm di jam 13.30 MOD MONTHLY",
          // "ttr_100cm_1330_med_monthly" : "Suhu Tanah Rumput Kedalaman 100 Cm di jam 13.30 MED MONTHLY",
          // "ttr_100cm_1330_max_monthly" : "Suhu Tanah Rumput Kedalaman 100 Cm di jam 13.30 MAX MONTHLY",
          // "ttr_100cm_1330_min_monthly" : "Suhu Tanah Rumput Kedalaman 100 Cm di jam 13.30 MIN MONTHLY",
          // "ttr_100cm_1330_sum_monthly" : "Suhu Tanah Rumput Kedalaman 100 Cm di jam 13.30 SUM MONTHLY",
          ttr_100cm_1330_count_monthly:
            "Suhu Tanah Rumput Kedalaman 100 Cm di jam 13.30 COUNT MONTHLY",

          ttg_0cm_1330_avg_monthly:
            "Suhu Tanah Gundul Kedalaman 0 Cm di jam 13.30 AVG MONTHLY",
          // "ttg_0cm_1330_mod_monthly" : "Suhu Tanah Gundul Kedalaman 0 Cm di jam 13.30 MOD MONTHLY",
          // "ttg_0cm_1330_med_monthly" : "Suhu Tanah Gundul Kedalaman 0 Cm di jam 13.30 MED MONTHLY",
          // "ttg_0cm_1330_max_monthly" : "Suhu Tanah Gundul Kedalaman 0 Cm di jam 13.30 MAX MONTHLY",
          // "ttg_0cm_1330_min_monthly" : "Suhu Tanah Gundul Kedalaman 0 Cm di jam 13.30 MIN MONTHLY",
          // "ttg_0cm_1330_sum_monthly" : "Suhu Tanah Gundul Kedalaman 0 Cm di jam 13.30 SUM MONTHLY",
          ttg_0cm_1330_count_monthly:
            "Suhu Tanah Gundul Kedalaman 0 Cm di jam 13.30 COUNT MONTHLY",
          ttg_2cm_1330_avg_monthly:
            "Suhu Tanah Gundul Kedalaman 2 Cm di jam 13.30 AVG MONTHLY",
          // "ttg_2cm_1330_mod_monthly" : "Suhu Tanah Gundul Kedalaman 2 Cm di jam 13.30 MOD MONTHLY",
          // "ttg_2cm_1330_med_monthly" : "Suhu Tanah Gundul Kedalaman 2 Cm di jam 13.30 MED MONTHLY",
          // "ttg_2cm_1330_max_monthly" : "Suhu Tanah Gundul Kedalaman 2 Cm di jam 13.30 MAX MONTHLY",
          // "ttg_2cm_1330_min_monthly" : "Suhu Tanah Gundul Kedalaman 2 Cm di jam 13.30 MIN MONTHLY",
          // "ttg_2cm_1330_sum_monthly" : "Suhu Tanah Gundul Kedalaman 2 Cm di jam 13.30 SUM MONTHLY",
          ttg_2cm_1330_count_monthly:
            "Suhu Tanah Gundul Kedalaman 2 Cm di jam 13.30 COUNT MONTHLY",
          ttg_5cm_1330_avg_monthly:
            "Suhu Tanah Gundul Kedalaman 5 Cm di jam 13.30 AVG MONTHLY",
          // "ttg_5cm_1330_mod_monthly" : "Suhu Tanah Gundul Kedalaman 5 Cm di jam 13.30 MOD MONTHLY",
          // "ttg_5cm_1330_med_monthly" : "Suhu Tanah Gundul Kedalaman 5 Cm di jam 13.30 MED MONTHLY",
          // "ttg_5cm_1330_max_monthly" : "Suhu Tanah Gundul Kedalaman 5 Cm di jam 13.30 MAX MONTHLY",
          // "ttg_5cm_1330_min_monthly" : "Suhu Tanah Gundul Kedalaman 5 Cm di jam 13.30 MIN MONTHLY",
          // "ttg_5cm_1330_sum_monthly" : "Suhu Tanah Gundul Kedalaman 5 Cm di jam 13.30 SUM MONTHLY",
          ttg_5cm_1330_count_monthly:
            "Suhu Tanah Gundul Kedalaman 5 Cm di jam 13.30 COUNT MONTHLY",
          ttg_10cm_1330_avg_monthly:
            "Suhu Tanah Gundul Kedalaman 10 Cm di jam 13.30 AVG MONTHLY",
          // "ttg_10cm_1330_mod_monthly" : "Suhu Tanah Gundul Kedalaman 10 Cm di jam 13.30 MOD MONTHLY",
          // "ttg_10cm_1330_med_monthly" : "Suhu Tanah Gundul Kedalaman 10 Cm di jam 13.30 MED MONTHLY",
          // "ttg_10cm_1330_max_monthly" : "Suhu Tanah Gundul Kedalaman 10 Cm di jam 13.30 MAX MONTHLY",
          // "ttg_10cm_1330_min_monthly" : "Suhu Tanah Gundul Kedalaman 10 Cm di jam 13.30 MIN MONTHLY",
          // "ttg_10cm_1330_sum_monthly" : "Suhu Tanah Gundul Kedalaman 10 Cm di jam 13.30 SUM MONTHLY",
          ttg_10cm_1330_count_monthly:
            "Suhu Tanah Gundul Kedalaman 10 Cm di jam 13.30 COUNT MONTHLY",
          ttg_20cm_1330_avg_monthly:
            "Suhu Tanah Gundul Kedalaman 20 Cm di jam 13.30 AVG MONTHLY",
          // "ttg_20cm_1330_mod_monthly" : "Suhu Tanah Gundul Kedalaman 20 Cm di jam 13.30 MOD MONTHLY",
          // "ttg_20cm_1330_med_monthly" : "Suhu Tanah Gundul Kedalaman 20 Cm di jam 13.30 MED MONTHLY",
          // "ttg_20cm_1330_max_monthly" : "Suhu Tanah Gundul Kedalaman 20 Cm di jam 13.30 MAX MONTHLY",
          // "ttg_20cm_1330_min_monthly" : "Suhu Tanah Gundul Kedalaman 20 Cm di jam 13.30 MIN MONTHLY",
          // "ttg_20cm_1330_sum_monthly" : "Suhu Tanah Gundul Kedalaman 20 Cm di jam 13.30 SUM MONTHLY",
          ttg_20cm_1330_count_monthly:
            "Suhu Tanah Gundul Kedalaman 20 Cm di jam 13.30 COUNT MONTHLY",
          ttg_50cm_1330_avg_monthly:
            "Suhu Tanah Gundul Kedalaman 50 Cm di jam 13.30 AVG MONTHLY",
          // "ttg_50cm_1330_mod_monthly" : "Suhu Tanah Gundul Kedalaman 50 Cm di jam 13.30 MOD MONTHLY",
          // "ttg_50cm_1330_med_monthly" : "Suhu Tanah Gundul Kedalaman 50 Cm di jam 13.30 MED MONTHLY",
          // "ttg_50cm_1330_max_monthly" : "Suhu Tanah Gundul Kedalaman 50 Cm di jam 13.30 MAX MONTHLY",
          // "ttg_50cm_1330_min_monthly" : "Suhu Tanah Gundul Kedalaman 50 Cm di jam 13.30 MIN MONTHLY",
          // "ttg_50cm_1330_sum_monthly" : "Suhu Tanah Gundul Kedalaman 50 Cm di jam 13.30 SUM MONTHLY",
          ttg_50cm_1330_count_monthly:
            "Suhu Tanah Gundul Kedalaman 50 Cm di jam 13.30 COUNT MONTHLY",
          ttg_100cm_1330_avg_monthly:
            "Suhu Tanah Gundul Kedalaman 100 Cm di jam 13.30 AVG MONTHLY",
          // "ttg_100cm_1330_mod_monthly" : "Suhu Tanah Gundul Kedalaman 100 Cm di jam 13.30 MOD MONTHLY",
          // "ttg_100cm_1330_med_monthly" : "Suhu Tanah Gundul Kedalaman 100 Cm di jam 13.30 MED MONTHLY",
          // "ttg_100cm_1330_max_monthly" : "Suhu Tanah Gundul Kedalaman 100 Cm di jam 13.30 MAX MONTHLY",
          // "ttg_100cm_1330_min_monthly" : "Suhu Tanah Gundul Kedalaman 100 Cm di jam 13.30 MIN MONTHLY",
          // "ttg_100cm_1330_sum_monthly" : "Suhu Tanah Gundul Kedalaman 100 Cm di jam 13.30 SUM MONTHLY",
          ttg_100cm_1330_count_monthly:
            "Suhu Tanah Gundul Kedalaman 100 Cm di jam 13.30 COUNT MONTHLY",

          ttr_0cm_1730_avg_monthly:
            "Suhu Tanah Rumput Kedalaman 0 Cm di jam 17.30 AVG MONTHLY",
          // "ttr_0cm_1730_mod_monthly" : "Suhu Tanah Rumput Kedalaman 0 Cm di jam 17.30 MOD MONTHLY",
          // "ttr_0cm_1730_med_monthly" : "Suhu Tanah Rumput Kedalaman 0 Cm di jam 17.30 MED MONTHLY",
          // "ttr_0cm_1730_max_monthly" : "Suhu Tanah Rumput Kedalaman 0 Cm di jam 17.30 MAX MONTHLY",
          // "ttr_0cm_1730_min_monthly" : "Suhu Tanah Rumput Kedalaman 0 Cm di jam 17.30 MIN MONTHLY",
          // "ttr_0cm_1730_sum_monthly" : "Suhu Tanah Rumput Kedalaman 0 Cm di jam 17.30 SUM MONTHLY",
          ttr_0cm_1730_count_monthly:
            "Suhu Tanah Rumput Kedalaman 0 Cm di jam 17.30 COUNT MONTHLY",
          ttr_2cm_1730_avg_monthly:
            "Suhu Tanah Rumput Kedalaman 2 Cm di jam 17.30 AVG MONTHLY",
          // "ttr_2cm_1730_mod_monthly" : "Suhu Tanah Rumput Kedalaman 2 Cm di jam 17.30 MOD MONTHLY",
          // "ttr_2cm_1730_med_monthly" : "Suhu Tanah Rumput Kedalaman 2 Cm di jam 17.30 MED MONTHLY",
          // "ttr_2cm_1730_max_monthly" : "Suhu Tanah Rumput Kedalaman 2 Cm di jam 17.30 MAX MONTHLY",
          // "ttr_2cm_1730_min_monthly" : "Suhu Tanah Rumput Kedalaman 2 Cm di jam 17.30 MIN MONTHLY",
          // "ttr_2cm_1730_sum_monthly" : "Suhu Tanah Rumput Kedalaman 2 Cm di jam 17.30 SUM MONTHLY",
          ttr_2cm_1730_count_monthly:
            "Suhu Tanah Rumput Kedalaman 2 Cm di jam 17.30 COUNT MONTHLY",
          ttr_5cm_1730_avg_monthly:
            "Suhu Tanah Rumput Kedalaman 5 Cm di jam 17.30 AVG MONTHLY",
          // "ttr_5cm_1730_mod_monthly" : "Suhu Tanah Rumput Kedalaman 5 Cm di jam 17.30 MOD MONTHLY",
          // "ttr_5cm_1730_med_monthly" : "Suhu Tanah Rumput Kedalaman 5 Cm di jam 17.30 MED MONTHLY",
          // "ttr_5cm_1730_max_monthly" : "Suhu Tanah Rumput Kedalaman 5 Cm di jam 17.30 MAX MONTHLY",
          // "ttr_5cm_1730_min_monthly" : "Suhu Tanah Rumput Kedalaman 5 Cm di jam 17.30 MIN MONTHLY",
          // "ttr_5cm_1730_sum_monthly" : "Suhu Tanah Rumput Kedalaman 5 Cm di jam 17.30 SUM MONTHLY",
          ttr_5cm_1730_count_monthly:
            "Suhu Tanah Rumput Kedalaman 5 Cm di jam 17.30 COUNT MONTHLY",
          ttr_10cm_1730_avg_monthly:
            "Suhu Tanah Rumput Kedalaman 10 Cm di jam 17.30 AVG MONTHLY",
          // "ttr_10cm_1730_mod_monthly" : "Suhu Tanah Rumput Kedalaman 10 Cm di jam 17.30 MOD MONTHLY",
          // "ttr_10cm_1730_med_monthly" : "Suhu Tanah Rumput Kedalaman 10 Cm di jam 17.30 MED MONTHLY",
          // "ttr_10cm_1730_max_monthly" : "Suhu Tanah Rumput Kedalaman 10 Cm di jam 17.30 MAX MONTHLY",
          // "ttr_10cm_1730_min_monthly" : "Suhu Tanah Rumput Kedalaman 10 Cm di jam 17.30 MIN MONTHLY",
          // "ttr_10cm_1730_sum_monthly" : "Suhu Tanah Rumput Kedalaman 10 Cm di jam 17.30 SUM MONTHLY",
          ttr_10cm_1730_count_monthly:
            "Suhu Tanah Rumput Kedalaman 10 Cm di jam 17.30 COUNT MONTHLY",
          ttr_20cm_1730_avg_monthly:
            "Suhu Tanah Rumput Kedalaman 20 Cm di jam 17.30 AVG MONTHLY",
          // "ttr_20cm_1730_mod_monthly" : "Suhu Tanah Rumput Kedalaman 20 Cm di jam 17.30 MOD MONTHLY",
          // "ttr_20cm_1730_med_monthly" : "Suhu Tanah Rumput Kedalaman 20 Cm di jam 17.30 MED MONTHLY",
          // "ttr_20cm_1730_max_monthly" : "Suhu Tanah Rumput Kedalaman 20 Cm di jam 17.30 MAX MONTHLY",
          // "ttr_20cm_1730_min_monthly" : "Suhu Tanah Rumput Kedalaman 20 Cm di jam 17.30 MIN MONTHLY",
          // "ttr_20cm_1730_sum_monthly" : "Suhu Tanah Rumput Kedalaman 20 Cm di jam 17.30 SUM MONTHLY",
          ttr_20cm_1730_count_monthly:
            "Suhu Tanah Rumput Kedalaman 20 Cm di jam 17.30 COUNT MONTHLY",
          ttr_50cm_1730_avg_monthly:
            "Suhu Tanah Rumput Kedalaman 50 Cm di jam 17.30 AVG MONTHLY",
          // "ttr_50cm_1730_mod_monthly" : "Suhu Tanah Rumput Kedalaman 50 Cm di jam 17.30 MOD MONTHLY",
          // "ttr_50cm_1730_med_monthly" : "Suhu Tanah Rumput Kedalaman 50 Cm di jam 17.30 MED MONTHLY",
          // "ttr_50cm_1730_max_monthly" : "Suhu Tanah Rumput Kedalaman 50 Cm di jam 17.30 MAX MONTHLY",
          // "ttr_50cm_1730_min_monthly" : "Suhu Tanah Rumput Kedalaman 50 Cm di jam 17.30 MIN MONTHLY",
          // "ttr_50cm_1730_sum_monthly" : "Suhu Tanah Rumput Kedalaman 50 Cm di jam 17.30 SUM MONTHLY",
          ttr_50cm_1730_count_monthly:
            "Suhu Tanah Rumput Kedalaman 50 Cm di jam 17.30 COUNT MONTHLY",
          ttr_100cm_1730_avg_monthly:
            "Suhu Tanah Rumput Kedalaman 100 Cm di jam 17.30 AVG MONTHLY",
          // "ttr_100cm_1730_mod_monthly" : "Suhu Tanah Rumput Kedalaman 100 Cm di jam 17.30 MOD MONTHLY",
          // "ttr_100cm_1730_med_monthly" : "Suhu Tanah Rumput Kedalaman 100 Cm di jam 17.30 MED MONTHLY",
          // "ttr_100cm_1730_max_monthly" : "Suhu Tanah Rumput Kedalaman 100 Cm di jam 17.30 MAX MONTHLY",
          // "ttr_100cm_1730_min_monthly" : "Suhu Tanah Rumput Kedalaman 100 Cm di jam 17.30 MIN MONTHLY",
          // "ttr_100cm_1730_sum_monthly" : "Suhu Tanah Rumput Kedalaman 100 Cm di jam 17.30 SUM MONTHLY",
          ttr_100cm_1730_count_monthly:
            "Suhu Tanah Rumput Kedalaman 100 Cm di jam 17.30 COUNT MONTHLY",

          ttg_0cm_1730_avg_monthly:
            "Suhu Tanah Gundul Kedalaman 0 Cm di jam 17.30 AVG MONTHLY",
          // "ttg_0cm_1730_mod_monthly" : "Suhu Tanah Gundul Kedalaman 0 Cm di jam 17.30 MOD MONTHLY",
          // "ttg_0cm_1730_med_monthly" : "Suhu Tanah Gundul Kedalaman 0 Cm di jam 17.30 MED MONTHLY",
          // "ttg_0cm_1730_max_monthly" : "Suhu Tanah Gundul Kedalaman 0 Cm di jam 17.30 MAX MONTHLY",
          // "ttg_0cm_1730_min_monthly" : "Suhu Tanah Gundul Kedalaman 0 Cm di jam 17.30 MIN MONTHLY",
          // "ttg_0cm_1730_sum_monthly" : "Suhu Tanah Gundul Kedalaman 0 Cm di jam 17.30 SUM MONTHLY",
          ttg_0cm_1730_count_monthly:
            "Suhu Tanah Gundul Kedalaman 0 Cm di jam 17.30 COUNT MONTHLY",
          ttg_2cm_1730_avg_monthly:
            "Suhu Tanah Gundul Kedalaman 2 Cm di jam 17.30 AVG MONTHLY",
          // "ttg_2cm_1730_mod_monthly" : "Suhu Tanah Gundul Kedalaman 2 Cm di jam 17.30 MOD MONTHLY",
          // "ttg_2cm_1730_med_monthly" : "Suhu Tanah Gundul Kedalaman 2 Cm di jam 17.30 MED MONTHLY",
          // "ttg_2cm_1730_max_monthly" : "Suhu Tanah Gundul Kedalaman 2 Cm di jam 17.30 MAX MONTHLY",
          // "ttg_2cm_1730_min_monthly" : "Suhu Tanah Gundul Kedalaman 2 Cm di jam 17.30 MIN MONTHLY",
          // "ttg_2cm_1730_sum_monthly" : "Suhu Tanah Gundul Kedalaman 2 Cm di jam 17.30 SUM MONTHLY",
          ttg_2cm_1730_count_monthly:
            "Suhu Tanah Gundul Kedalaman 2 Cm di jam 17.30 COUNT MONTHLY",
          ttg_5cm_1730_avg_monthly:
            "Suhu Tanah Gundul Kedalaman 5 Cm di jam 17.30 AVG MONTHLY",
          // "ttg_5cm_1730_mod_monthly" : "Suhu Tanah Gundul Kedalaman 5 Cm di jam 17.30 MOD MONTHLY",
          // "ttg_5cm_1730_med_monthly" : "Suhu Tanah Gundul Kedalaman 5 Cm di jam 17.30 MED MONTHLY",
          // "ttg_5cm_1730_max_monthly" : "Suhu Tanah Gundul Kedalaman 5 Cm di jam 17.30 MAX MONTHLY",
          // "ttg_5cm_1730_min_monthly" : "Suhu Tanah Gundul Kedalaman 5 Cm di jam 17.30 MIN MONTHLY",
          // "ttg_5cm_1730_sum_monthly" : "Suhu Tanah Gundul Kedalaman 5 Cm di jam 17.30 SUM MONTHLY",
          ttg_5cm_1730_count_monthly:
            "Suhu Tanah Gundul Kedalaman 5 Cm di jam 17.30 COUNT MONTHLY",
          ttg_10cm_1730_avg_monthly:
            "Suhu Tanah Gundul Kedalaman 10 Cm di jam 17.30 AVG MONTHLY",
          // "ttg_10cm_1730_mod_monthly" : "Suhu Tanah Gundul Kedalaman 10 Cm di jam 17.30 MOD MONTHLY",
          // "ttg_10cm_1730_med_monthly" : "Suhu Tanah Gundul Kedalaman 10 Cm di jam 17.30 MED MONTHLY",
          // "ttg_10cm_1730_max_monthly" : "Suhu Tanah Gundul Kedalaman 10 Cm di jam 17.30 MAX MONTHLY",
          // "ttg_10cm_1730_min_monthly" : "Suhu Tanah Gundul Kedalaman 10 Cm di jam 17.30 MIN MONTHLY",
          // "ttg_10cm_1730_sum_monthly" : "Suhu Tanah Gundul Kedalaman 10 Cm di jam 17.30 SUM MONTHLY",
          ttg_10cm_1730_count_monthly:
            "Suhu Tanah Gundul Kedalaman 10 Cm di jam 17.30 COUNT MONTHLY",
          ttg_20cm_1730_avg_monthly:
            "Suhu Tanah Gundul Kedalaman 20 Cm di jam 17.30 AVG MONTHLY",
          // "ttg_20cm_1730_mod_monthly" : "Suhu Tanah Gundul Kedalaman 20 Cm di jam 17.30 MOD MONTHLY",
          // "ttg_20cm_1730_med_monthly" : "Suhu Tanah Gundul Kedalaman 20 Cm di jam 17.30 MED MONTHLY",
          // "ttg_20cm_1730_max_monthly" : "Suhu Tanah Gundul Kedalaman 20 Cm di jam 17.30 MAX MONTHLY",
          // "ttg_20cm_1730_min_monthly" : "Suhu Tanah Gundul Kedalaman 20 Cm di jam 17.30 MIN MONTHLY",
          // "ttg_20cm_1730_sum_monthly" : "Suhu Tanah Gundul Kedalaman 20 Cm di jam 17.30 SUM MONTHLY",
          ttg_20cm_1730_count_monthly:
            "Suhu Tanah Gundul Kedalaman 20 Cm di jam 17.30 COUNT MONTHLY",
          ttg_50cm_1730_avg_monthly:
            "Suhu Tanah Gundul Kedalaman 50 Cm di jam 17.30 AVG MONTHLY",
          // "ttg_50cm_1730_mod_monthly" : "Suhu Tanah Gundul Kedalaman 50 Cm di jam 17.30 MOD MONTHLY",
          // "ttg_50cm_1730_med_monthly" : "Suhu Tanah Gundul Kedalaman 50 Cm di jam 17.30 MED MONTHLY",
          // "ttg_50cm_1730_max_monthly" : "Suhu Tanah Gundul Kedalaman 50 Cm di jam 17.30 MAX MONTHLY",
          // "ttg_50cm_1730_min_monthly" : "Suhu Tanah Gundul Kedalaman 50 Cm di jam 17.30 MIN MONTHLY",
          // "ttg_50cm_1730_sum_monthly" : "Suhu Tanah Gundul Kedalaman 50 Cm di jam 17.30 SUM MONTHLY",
          ttg_50cm_1730_count_monthly:
            "Suhu Tanah Gundul Kedalaman 50 Cm di jam 17.30 COUNT MONTHLY",
          ttg_100cm_1730_avg_monthly:
            "Suhu Tanah Gundul Kedalaman 100 Cm di jam 17.30 AVG MONTHLY",
          // "ttg_100cm_1730_mod_monthly" : "Suhu Tanah Gundul Kedalaman 100 Cm di jam 17.30 MOD MONTHLY",
          // "ttg_100cm_1730_med_monthly" : "Suhu Tanah Gundul Kedalaman 100 Cm di jam 17.30 MED MONTHLY",
          // "ttg_100cm_1730_max_monthly" : "Suhu Tanah Gundul Kedalaman 100 Cm di jam 17.30 MAX MONTHLY",
          // "ttg_100cm_1730_min_monthly" : "Suhu Tanah Gundul Kedalaman 100 Cm di jam 17.30 MIN MONTHLY",
          // "ttg_100cm_1730_sum_monthly" : "Suhu Tanah Gundul Kedalaman 100 Cm di jam 17.30 SUM MONTHLY",
          ttg_100cm_1730_count_monthly:
            "Suhu Tanah Gundul Kedalaman 100 Cm di jam 17.30 COUNT MONTHLY",
        },
      },
    ];
    return listAttr;
  },

  getAttrMonthlyCsv() {
    const listAttr = [
      {
        label: "Temperatur",
        attr: {
          ttr_0cm_0730_avg_monthly: "ttr_0cm_0730_monthly_avg_C",
          ttr_0cm_0730_mod_monthly: "ttr_0cm_0730_monthly_mod",
          ttr_0cm_0730_med_monthly: "ttr_0cm_0730_monthly_med",
          ttr_0cm_0730_max_monthly: "ttr_0cm_0730_monthly_max",
          ttr_0cm_0730_min_monthly: "ttr_0cm_0730_monthly_min",
          ttr_0cm_0730_sum_monthly: "ttr_0cm_0730_monthly_sum",
          ttr_0cm_0730_count_monthly: "count_ttr_0cm_0730_monthly",
          ttr_2cm_0730_avg_monthly: "ttr_2cm_0730_monthly_avg_C",
          ttr_2cm_0730_mod_monthly: "ttr_2cm_0730_monthly_mod",
          ttr_2cm_0730_med_monthly: "ttr_2cm_0730_monthly_med",
          ttr_2cm_0730_max_monthly: "ttr_2cm_0730_monthly_max",
          ttr_2cm_0730_min_monthly: "ttr_2cm_0730_monthly_min",
          ttr_2cm_0730_sum_monthly: "ttr_2cm_0730_monthly_sum",
          ttr_2cm_0730_count_monthly: "count_ttr_2cm_0730_monthly",
          ttr_5cm_0730_avg_monthly: "ttr_5cm_0730_monthly_avg_C",
          ttr_5cm_0730_mod_monthly: "ttr_5cm_0730_monthly_mod",
          ttr_5cm_0730_med_monthly: "ttr_5cm_0730_monthly_med",
          ttr_5cm_0730_max_monthly: "ttr_5cm_0730_monthly_max",
          ttr_5cm_0730_min_monthly: "ttr_5cm_0730_monthly_min",
          ttr_5cm_0730_sum_monthly: "ttr_5cm_0730_monthly_sum",
          ttr_5cm_0730_count_monthly: "count_ttr_5cm_0730_monthly",
          ttr_10cm_0730_avg_monthly: "ttr_10cm_0730_monthly_avg_C",
          ttr_10cm_0730_mod_monthly: "ttr_10cm_0730_monthly_mod",
          ttr_10cm_0730_med_monthly: "ttr_10cm_0730_monthly_med",
          ttr_10cm_0730_max_monthly: "ttr_10cm_0730_monthly_max",
          ttr_10cm_0730_min_monthly: "ttr_10cm_0730_monthly_min",
          ttr_10cm_0730_sum_monthly: "ttr_10cm_0730_monthly_sum",
          ttr_10cm_0730_count_monthly: "count_ttr_10cm_0730_monthly",
          ttr_20cm_0730_avg_monthly: "ttr_20cm_0730_monthly_avg_C",
          ttr_20cm_0730_mod_monthly: "ttr_20cm_0730_monthly_mod",
          ttr_20cm_0730_med_monthly: "ttr_20cm_0730_monthly_med",
          ttr_20cm_0730_max_monthly: "ttr_20cm_0730_monthly_max",
          ttr_20cm_0730_min_monthly: "ttr_20cm_0730_monthly_min",
          ttr_20cm_0730_sum_monthly: "ttr_20cm_0730_monthly_sum",
          ttr_20cm_0730_count_monthly: "count_ttr_20cm_0730_monthly",
          ttr_50cm_0730_avg_monthly: "ttr_50cm_0730_monthly_avg_C",
          ttr_50cm_0730_mod_monthly: "ttr_50cm_0730_monthly_mod",
          ttr_50cm_0730_med_monthly: "ttr_50cm_0730_monthly_med",
          ttr_50cm_0730_max_monthly: "ttr_50cm_0730_monthly_max",
          ttr_50cm_0730_min_monthly: "ttr_50cm_0730_monthly_min",
          ttr_50cm_0730_sum_monthly: "ttr_50cm_0730_monthly_sum",
          ttr_50cm_0730_count_monthly: "count_ttr_50cm_0730_monthly",
          ttr_100cm_0730_avg_monthly: "ttr_100cm_0730_monthly_avg_C",
          ttr_100cm_0730_mod_monthly: "ttr_100cm_0730_monthly_mod",
          ttr_100cm_0730_med_monthly: "ttr_100cm_0730_monthly_med",
          ttr_100cm_0730_max_monthly: "ttr_100cm_0730_monthly_max",
          ttr_100cm_0730_min_monthly: "ttr_100cm_0730_monthly_min",
          ttr_100cm_0730_sum_monthly: "ttr_100cm_0730_monthly_sum",
          ttr_100cm_0730_count_monthly: "count_ttr_100cm_0730_monthly",

          ttg_0cm_0730_avg_monthly: "ttg_0cm_0730_monthly_avg_C",
          ttg_0cm_0730_mod_monthly: "ttg_0cm_0730_monthly_mod",
          ttg_0cm_0730_med_monthly: "ttg_0cm_0730_monthly_med",
          ttg_0cm_0730_max_monthly: "ttg_0cm_0730_monthly_max",
          ttg_0cm_0730_min_monthly: "ttg_0cm_0730_monthly_min",
          ttg_0cm_0730_sum_monthly: "ttg_0cm_0730_monthly_sum",
          ttg_0cm_0730_count_monthly: "count_ttg_0cm_0730_monthly",
          ttg_2cm_0730_avg_monthly: "ttg_2cm_0730_monthly_avg_C",
          ttg_2cm_0730_mod_monthly: "ttg_2cm_0730_monthly_mod",
          ttg_2cm_0730_med_monthly: "ttg_2cm_0730_monthly_med",
          ttg_2cm_0730_max_monthly: "ttg_2cm_0730_monthly_max",
          ttg_2cm_0730_min_monthly: "ttg_2cm_0730_monthly_min",
          ttg_2cm_0730_sum_monthly: "ttg_2cm_0730_monthly_sum",
          ttg_2cm_0730_count_monthly: "count_ttg_2cm_0730_monthly",
          ttg_5cm_0730_avg_monthly: "ttg_5cm_0730_monthly_avg_C",
          ttg_5cm_0730_mod_monthly: "ttg_5cm_0730_monthly_mod",
          ttg_5cm_0730_med_monthly: "ttg_5cm_0730_monthly_med",
          ttg_5cm_0730_max_monthly: "ttg_5cm_0730_monthly_max",
          ttg_5cm_0730_min_monthly: "ttg_5cm_0730_monthly_min",
          ttg_5cm_0730_sum_monthly: "ttg_5cm_0730_monthly_sum",
          ttg_5cm_0730_count_monthly: "count_ttg_5cm_0730_monthly",
          ttg_10cm_0730_avg_monthly: "ttg_10cm_0730_monthly_avg_C",
          ttg_10cm_0730_mod_monthly: "ttg_10cm_0730_monthly_mod",
          ttg_10cm_0730_med_monthly: "ttg_10cm_0730_monthly_med",
          ttg_10cm_0730_max_monthly: "ttg_10cm_0730_monthly_max",
          ttg_10cm_0730_min_monthly: "ttg_10cm_0730_monthly_min",
          ttg_10cm_0730_sum_monthly: "ttg_10cm_0730_monthly_sum",
          ttg_10cm_0730_count_monthly: "count_ttg_10cm_0730_monthly",
          ttg_20cm_0730_avg_monthly: "ttg_20cm_0730_monthly_avg_C",
          ttg_20cm_0730_mod_monthly: "ttg_20cm_0730_monthly_mod",
          ttg_20cm_0730_med_monthly: "ttg_20cm_0730_monthly_med",
          ttg_20cm_0730_max_monthly: "ttg_20cm_0730_monthly_max",
          ttg_20cm_0730_min_monthly: "ttg_20cm_0730_monthly_min",
          ttg_20cm_0730_sum_monthly: "ttg_20cm_0730_monthly_sum",
          ttg_20cm_0730_count_monthly: "count_ttg_20cm_0730_monthly",
          ttg_50cm_0730_avg_monthly: "ttg_50cm_0730_monthly_avg_C",
          ttg_50cm_0730_mod_monthly: "ttg_50cm_0730_monthly_mod",
          ttg_50cm_0730_med_monthly: "ttg_50cm_0730_monthly_med",
          ttg_50cm_0730_max_monthly: "ttg_50cm_0730_monthly_max",
          ttg_50cm_0730_min_monthly: "ttg_50cm_0730_monthly_min",
          ttg_50cm_0730_sum_monthly: "ttg_50cm_0730_monthly_sum",
          ttg_50cm_0730_count_monthly: "count_ttg_50cm_0730_monthly",
          ttg_100cm_0730_avg_monthly: "ttg_100cm_0730_monthly_avg_C",
          ttg_100cm_0730_mod_monthly: "ttg_100cm_0730_monthly_mod",
          ttg_100cm_0730_med_monthly: "ttg_100cm_0730_monthly_med",
          ttg_100cm_0730_max_monthly: "ttg_100cm_0730_monthly_max",
          ttg_100cm_0730_min_monthly: "ttg_100cm_0730_monthly_min",
          ttg_100cm_0730_sum_monthly: "ttg_100cm_0730_monthly_sum",
          ttg_100cm_0730_count_monthly: "count_ttg_100cm_0730_monthly",

          ttr_0cm_1330_avg_monthly: "ttr_0cm_1330_monthly_avg_C",
          ttr_0cm_1330_mod_monthly: "ttr_0cm_1330_monthly_mod",
          ttr_0cm_1330_med_monthly: "ttr_0cm_1330_monthly_med",
          ttr_0cm_1330_max_monthly: "ttr_0cm_1330_monthly_max",
          ttr_0cm_1330_min_monthly: "ttr_0cm_1330_monthly_min",
          ttr_0cm_1330_sum_monthly: "ttr_0cm_1330_monthly_sum",
          ttr_0cm_1330_count_monthly: "count_ttr_0cm_1330_monthly",
          ttr_2cm_1330_avg_monthly: "ttr_2cm_1330_monthly_avg_C",
          ttr_2cm_1330_mod_monthly: "ttr_2cm_1330_monthly_mod",
          ttr_2cm_1330_med_monthly: "ttr_2cm_1330_monthly_med",
          ttr_2cm_1330_max_monthly: "ttr_2cm_1330_monthly_max",
          ttr_2cm_1330_min_monthly: "ttr_2cm_1330_monthly_min",
          ttr_2cm_1330_sum_monthly: "ttr_2cm_1330_monthly_sum",
          ttr_2cm_1330_count_monthly: "count_ttr_2cm_1330_monthly",
          ttr_5cm_1330_avg_monthly: "ttr_5cm_1330_monthly_avg_C",
          ttr_5cm_1330_mod_monthly: "ttr_5cm_1330_monthly_mod",
          ttr_5cm_1330_med_monthly: "ttr_5cm_1330_monthly_med",
          ttr_5cm_1330_max_monthly: "ttr_5cm_1330_monthly_max",
          ttr_5cm_1330_min_monthly: "ttr_5cm_1330_monthly_min",
          ttr_5cm_1330_sum_monthly: "ttr_5cm_1330_monthly_sum",
          ttr_5cm_1330_count_monthly: "count_ttr_5cm_1330_monthly",
          ttr_10cm_1330_avg_monthly: "ttr_10cm_1330_monthly_avg_C",
          ttr_10cm_1330_mod_monthly: "ttr_10cm_1330_monthly_mod",
          ttr_10cm_1330_med_monthly: "ttr_10cm_1330_monthly_med",
          ttr_10cm_1330_max_monthly: "ttr_10cm_1330_monthly_max",
          ttr_10cm_1330_min_monthly: "ttr_10cm_1330_monthly_min",
          ttr_10cm_1330_sum_monthly: "ttr_10cm_1330_monthly_sum",
          ttr_10cm_1330_count_monthly: "count_ttr_10cm_1330_monthly",
          ttr_20cm_1330_avg_monthly: "ttr_20cm_1330_monthly_avg_C",
          ttr_20cm_1330_mod_monthly: "ttr_20cm_1330_monthly_mod",
          ttr_20cm_1330_med_monthly: "ttr_20cm_1330_monthly_med",
          ttr_20cm_1330_max_monthly: "ttr_20cm_1330_monthly_max",
          ttr_20cm_1330_min_monthly: "ttr_20cm_1330_monthly_min",
          ttr_20cm_1330_sum_monthly: "ttr_20cm_1330_monthly_sum",
          ttr_20cm_1330_count_monthly: "count_ttr_20cm_1330_monthly",
          ttr_50cm_1330_avg_monthly: "ttr_50cm_1330_monthly_avg_C",
          ttr_50cm_1330_mod_monthly: "ttr_50cm_1330_monthly_mod",
          ttr_50cm_1330_med_monthly: "ttr_50cm_1330_monthly_med",
          ttr_50cm_1330_max_monthly: "ttr_50cm_1330_monthly_max",
          ttr_50cm_1330_min_monthly: "ttr_50cm_1330_monthly_min",
          ttr_50cm_1330_sum_monthly: "ttr_50cm_1330_monthly_sum",
          ttr_50cm_1330_count_monthly: "count_ttr_50cm_1330_monthly",
          ttr_100cm_1330_avg_monthly: "ttr_100cm_1330_monthly_avg_C",
          ttr_100cm_1330_mod_monthly: "ttr_100cm_1330_monthly_mod",
          ttr_100cm_1330_med_monthly: "ttr_100cm_1330_monthly_med",
          ttr_100cm_1330_max_monthly: "ttr_100cm_1330_monthly_max",
          ttr_100cm_1330_min_monthly: "ttr_100cm_1330_monthly_min",
          ttr_100cm_1330_sum_monthly: "ttr_100cm_1330_monthly_sum",
          ttr_100cm_1330_count_monthly: "count_ttr_100cm_1330_monthly",

          ttg_0cm_1330_avg_monthly: "ttg_0cm_1330_monthly_avg_C",
          ttg_0cm_1330_mod_monthly: "ttg_0cm_1330_monthly_mod",
          ttg_0cm_1330_med_monthly: "ttg_0cm_1330_monthly_med",
          ttg_0cm_1330_max_monthly: "ttg_0cm_1330_monthly_max",
          ttg_0cm_1330_min_monthly: "ttg_0cm_1330_monthly_min",
          ttg_0cm_1330_sum_monthly: "ttg_0cm_1330_monthly_sum",
          ttg_0cm_1330_count_monthly: "count_ttg_0cm_1330_monthly",
          ttg_2cm_1330_avg_monthly: "ttg_2cm_1330_monthly_avg_C",
          ttg_2cm_1330_mod_monthly: "ttg_2cm_1330_monthly_mod",
          ttg_2cm_1330_med_monthly: "ttg_2cm_1330_monthly_med",
          ttg_2cm_1330_max_monthly: "ttg_2cm_1330_monthly_max",
          ttg_2cm_1330_min_monthly: "ttg_2cm_1330_monthly_min",
          ttg_2cm_1330_sum_monthly: "ttg_2cm_1330_monthly_sum",
          ttg_2cm_1330_count_monthly: "count_ttg_2cm_1330_monthly",
          ttg_5cm_1330_avg_monthly: "ttg_5cm_1330_monthly_avg_C",
          ttg_5cm_1330_mod_monthly: "ttg_5cm_1330_monthly_mod",
          ttg_5cm_1330_med_monthly: "ttg_5cm_1330_monthly_med",
          ttg_5cm_1330_max_monthly: "ttg_5cm_1330_monthly_max",
          ttg_5cm_1330_min_monthly: "ttg_5cm_1330_monthly_min",
          ttg_5cm_1330_sum_monthly: "ttg_5cm_1330_monthly_sum",
          ttg_5cm_1330_count_monthly: "count_ttg_5cm_1330_monthly",
          ttg_10cm_1330_avg_monthly: "ttg_10cm_1330_monthly_avg_C",
          ttg_10cm_1330_mod_monthly: "ttg_10cm_1330_monthly_mod",
          ttg_10cm_1330_med_monthly: "ttg_10cm_1330_monthly_med",
          ttg_10cm_1330_max_monthly: "ttg_10cm_1330_monthly_max",
          ttg_10cm_1330_min_monthly: "ttg_10cm_1330_monthly_min",
          ttg_10cm_1330_sum_monthly: "ttg_10cm_1330_monthly_sum",
          ttg_10cm_1330_count_monthly: "count_ttg_10cm_1330_monthly",
          ttg_20cm_1330_avg_monthly: "ttg_20cm_1330_monthly_avg_C",
          ttg_20cm_1330_mod_monthly: "ttg_20cm_1330_monthly_mod",
          ttg_20cm_1330_med_monthly: "ttg_20cm_1330_monthly_med",
          ttg_20cm_1330_max_monthly: "ttg_20cm_1330_monthly_max",
          ttg_20cm_1330_min_monthly: "ttg_20cm_1330_monthly_min",
          ttg_20cm_1330_sum_monthly: "ttg_20cm_1330_monthly_sum",
          ttg_20cm_1330_count_monthly: "count_ttg_20cm_1330_monthly",
          ttg_50cm_1330_avg_monthly: "ttg_50cm_1330_monthly_avg_C",
          ttg_50cm_1330_mod_monthly: "ttg_50cm_1330_monthly_mod",
          ttg_50cm_1330_med_monthly: "ttg_50cm_1330_monthly_med",
          ttg_50cm_1330_max_monthly: "ttg_50cm_1330_monthly_max",
          ttg_50cm_1330_min_monthly: "ttg_50cm_1330_monthly_min",
          ttg_50cm_1330_sum_monthly: "ttg_50cm_1330_monthly_sum",
          ttg_50cm_1330_count_monthly: "count_ttg_50cm_1330_monthly",
          ttg_100cm_1330_avg_monthly: "ttg_100cm_1330_monthly_avg_C",
          ttg_100cm_1330_mod_monthly: "ttg_100cm_1330_monthly_mod",
          ttg_100cm_1330_med_monthly: "ttg_100cm_1330_monthly_med",
          ttg_100cm_1330_max_monthly: "ttg_100cm_1330_monthly_max",
          ttg_100cm_1330_min_monthly: "ttg_100cm_1330_monthly_min",
          ttg_100cm_1330_sum_monthly: "ttg_100cm_1330_monthly_sum",
          ttg_100cm_1330_count_monthly: "count_ttg_100cm_1330_monthly",

          ttr_0cm_1730_avg_monthly: "ttr_0cm_1730_monthly_avg_C",
          ttr_0cm_1730_mod_monthly: "ttr_0cm_1730_monthly_mod",
          ttr_0cm_1730_med_monthly: "ttr_0cm_1730_monthly_med",
          ttr_0cm_1730_max_monthly: "ttr_0cm_1730_monthly_max",
          ttr_0cm_1730_min_monthly: "ttr_0cm_1730_monthly_min",
          ttr_0cm_1730_sum_monthly: "ttr_0cm_1730_monthly_sum",
          ttr_0cm_1730_count_monthly: "count_ttr_0cm_1730_monthly",
          ttr_2cm_1730_avg_monthly: "ttr_2cm_1730_monthly_avg_C",
          ttr_2cm_1730_mod_monthly: "ttr_2cm_1730_monthly_mod",
          ttr_2cm_1730_med_monthly: "ttr_2cm_1730_monthly_med",
          ttr_2cm_1730_max_monthly: "ttr_2cm_1730_monthly_max",
          ttr_2cm_1730_min_monthly: "ttr_2cm_1730_monthly_min",
          ttr_2cm_1730_sum_monthly: "ttr_2cm_1730_monthly_sum",
          ttr_2cm_1730_count_monthly: "count_ttr_2cm_1730_monthly",
          ttr_5cm_1730_avg_monthly: "ttr_5cm_1730_monthly_avg_C",
          ttr_5cm_1730_mod_monthly: "ttr_5cm_1730_monthly_mod",
          ttr_5cm_1730_med_monthly: "ttr_5cm_1730_monthly_med",
          ttr_5cm_1730_max_monthly: "ttr_5cm_1730_monthly_max",
          ttr_5cm_1730_min_monthly: "ttr_5cm_1730_monthly_min",
          ttr_5cm_1730_sum_monthly: "ttr_5cm_1730_monthly_sum",
          ttr_5cm_1730_count_monthly: "count_ttr_5cm_1730_monthly",
          ttr_10cm_1730_avg_monthly: "ttr_10cm_1730_monthly_avg_C",
          ttr_10cm_1730_mod_monthly: "ttr_10cm_1730_monthly_mod",
          ttr_10cm_1730_med_monthly: "ttr_10cm_1730_monthly_med",
          ttr_10cm_1730_max_monthly: "ttr_10cm_1730_monthly_max",
          ttr_10cm_1730_min_monthly: "ttr_10cm_1730_monthly_min",
          ttr_10cm_1730_sum_monthly: "ttr_10cm_1730_monthly_sum",
          ttr_10cm_1730_count_monthly: "count_ttr_10cm_1730_monthly",
          ttr_20cm_1730_avg_monthly: "ttr_20cm_1730_monthly_avg_C",
          ttr_20cm_1730_mod_monthly: "ttr_20cm_1730_monthly_mod",
          ttr_20cm_1730_med_monthly: "ttr_20cm_1730_monthly_med",
          ttr_20cm_1730_max_monthly: "ttr_20cm_1730_monthly_max",
          ttr_20cm_1730_min_monthly: "ttr_20cm_1730_monthly_min",
          ttr_20cm_1730_sum_monthly: "ttr_20cm_1730_monthly_sum",
          ttr_20cm_1730_count_monthly: "count_ttr_20cm_1730_monthly",
          ttr_50cm_1730_avg_monthly: "ttr_50cm_1730_monthly_avg_C",
          ttr_50cm_1730_mod_monthly: "ttr_50cm_1730_monthly_mod",
          ttr_50cm_1730_med_monthly: "ttr_50cm_1730_monthly_med",
          ttr_50cm_1730_max_monthly: "ttr_50cm_1730_monthly_max",
          ttr_50cm_1730_min_monthly: "ttr_50cm_1730_monthly_min",
          ttr_50cm_1730_sum_monthly: "ttr_50cm_1730_monthly_sum",
          ttr_50cm_1730_count_monthly: "count_ttr_50cm_1730_monthly",
          ttr_100cm_1730_avg_monthly: "ttr_100cm_1730_monthly_avg_C",
          ttr_100cm_1730_mod_monthly: "ttr_100cm_1730_monthly_mod",
          ttr_100cm_1730_med_monthly: "ttr_100cm_1730_monthly_med",
          ttr_100cm_1730_max_monthly: "ttr_100cm_1730_monthly_max",
          ttr_100cm_1730_min_monthly: "ttr_100cm_1730_monthly_min",
          ttr_100cm_1730_sum_monthly: "ttr_100cm_1730_monthly_sum",
          ttr_100cm_1730_count_monthly: "count_ttr_100cm_1730_monthly",

          ttg_0cm_1730_avg_monthly: "ttg_0cm_1730_monthly_avg_C",
          ttg_0cm_1730_mod_monthly: "ttg_0cm_1730_monthly_mod",
          ttg_0cm_1730_med_monthly: "ttg_0cm_1730_monthly_med",
          ttg_0cm_1730_max_monthly: "ttg_0cm_1730_monthly_max",
          ttg_0cm_1730_min_monthly: "ttg_0cm_1730_monthly_min",
          ttg_0cm_1730_sum_monthly: "ttg_0cm_1730_monthly_sum",
          ttg_0cm_1730_count_monthly: "count_ttg_0cm_1730_monthly",
          ttg_2cm_1730_avg_monthly: "ttg_2cm_1730_monthly_avg_C",
          ttg_2cm_1730_mod_monthly: "ttg_2cm_1730_monthly_mod",
          ttg_2cm_1730_med_monthly: "ttg_2cm_1730_monthly_med",
          ttg_2cm_1730_max_monthly: "ttg_2cm_1730_monthly_max",
          ttg_2cm_1730_min_monthly: "ttg_2cm_1730_monthly_min",
          ttg_2cm_1730_sum_monthly: "ttg_2cm_1730_monthly_sum",
          ttg_2cm_1730_count_monthly: "count_ttg_2cm_1730_monthly",
          ttg_5cm_1730_avg_monthly: "ttg_5cm_1730_monthly_avg_C",
          ttg_5cm_1730_mod_monthly: "ttg_5cm_1730_monthly_mod",
          ttg_5cm_1730_med_monthly: "ttg_5cm_1730_monthly_med",
          ttg_5cm_1730_max_monthly: "ttg_5cm_1730_monthly_max",
          ttg_5cm_1730_min_monthly: "ttg_5cm_1730_monthly_min",
          ttg_5cm_1730_sum_monthly: "ttg_5cm_1730_monthly_sum",
          ttg_5cm_1730_count_monthly: "count_ttg_5cm_1730_monthly",
          ttg_10cm_1730_avg_monthly: "ttg_10cm_1730_monthly_avg_C",
          ttg_10cm_1730_mod_monthly: "ttg_10cm_1730_monthly_mod",
          ttg_10cm_1730_med_monthly: "ttg_10cm_1730_monthly_med",
          ttg_10cm_1730_max_monthly: "ttg_10cm_1730_monthly_max",
          ttg_10cm_1730_min_monthly: "ttg_10cm_1730_monthly_min",
          ttg_10cm_1730_sum_monthly: "ttg_10cm_1730_monthly_sum",
          ttg_10cm_1730_count_monthly: "count_ttg_10cm_1730_monthly",
          ttg_20cm_1730_avg_monthly: "ttg_20cm_1730_monthly_avg_C",
          ttg_20cm_1730_mod_monthly: "ttg_20cm_1730_monthly_mod",
          ttg_20cm_1730_med_monthly: "ttg_20cm_1730_monthly_med",
          ttg_20cm_1730_max_monthly: "ttg_20cm_1730_monthly_max",
          ttg_20cm_1730_min_monthly: "ttg_20cm_1730_monthly_min",
          ttg_20cm_1730_sum_monthly: "ttg_20cm_1730_monthly_sum",
          ttg_20cm_1730_count_monthly: "count_ttg_20cm_1730_monthly",
          ttg_50cm_1730_avg_monthly: "ttg_50cm_1730_monthly_avg_C",
          ttg_50cm_1730_mod_monthly: "ttg_50cm_1730_monthly_mod",
          ttg_50cm_1730_med_monthly: "ttg_50cm_1730_monthly_med",
          ttg_50cm_1730_max_monthly: "ttg_50cm_1730_monthly_max",
          ttg_50cm_1730_min_monthly: "ttg_50cm_1730_monthly_min",
          ttg_50cm_1730_sum_monthly: "ttg_50cm_1730_monthly_sum",
          ttg_50cm_1730_count_monthly: "count_ttg_50cm_1730_monthly",
          ttg_100cm_1730_avg_monthly: "ttg_100cm_1730_monthly_avg_C",
          ttg_100cm_1730_mod_monthly: "ttg_100cm_1730_monthly_mod",
          ttg_100cm_1730_med_monthly: "ttg_100cm_1730_monthly_med",
          ttg_100cm_1730_max_monthly: "ttg_100cm_1730_monthly_max",
          ttg_100cm_1730_min_monthly: "ttg_100cm_1730_monthly_min",
          ttg_100cm_1730_sum_monthly: "ttg_100cm_1730_monthly_sum",
          ttg_100cm_1730_count_monthly: "count_ttg_100cm_1730_monthly",
        },
      },
    ];
    return listAttr;
  },

  getAttrMonthlyDecimal() {
    const listAttr = [
      {
        label: "Temperatur",
        attr: {
          ttr_0cm_0730_avg_monthly: 1,
          ttr_0cm_0730_mod_monthly: 1,
          ttr_0cm_0730_med_monthly: 1,
          ttr_0cm_0730_max_monthly: 1,
          ttr_0cm_0730_min_monthly: 1,
          ttr_0cm_0730_sum_monthly: 1,
          ttr_0cm_0730_count_monthly: 1,
          ttr_2cm_0730_avg_monthly: 1,
          ttr_2cm_0730_mod_monthly: 1,
          ttr_2cm_0730_med_monthly: 1,
          ttr_2cm_0730_max_monthly: 1,
          ttr_2cm_0730_min_monthly: 1,
          ttr_2cm_0730_sum_monthly: 1,
          ttr_2cm_0730_count_monthly: 1,
          ttr_5cm_0730_avg_monthly: 1,
          ttr_5cm_0730_mod_monthly: 1,
          ttr_5cm_0730_med_monthly: 1,
          ttr_5cm_0730_max_monthly: 1,
          ttr_5cm_0730_min_monthly: 1,
          ttr_5cm_0730_sum_monthly: 1,
          ttr_5cm_0730_count_monthly: 1,
          ttr_10cm_0730_avg_monthly: 1,
          ttr_10cm_0730_mod_monthly: 1,
          ttr_10cm_0730_med_monthly: 1,
          ttr_10cm_0730_max_monthly: 1,
          ttr_10cm_0730_min_monthly: 1,
          ttr_10cm_0730_sum_monthly: 1,
          ttr_10cm_0730_count_monthly: 1,
          ttr_20cm_0730_avg_monthly: 1,
          ttr_20cm_0730_mod_monthly: 1,
          ttr_20cm_0730_med_monthly: 1,
          ttr_20cm_0730_max_monthly: 1,
          ttr_20cm_0730_min_monthly: 1,
          ttr_20cm_0730_sum_monthly: 1,
          ttr_20cm_0730_count_monthly: 1,
          ttr_50cm_0730_avg_monthly: 1,
          ttr_50cm_0730_mod_monthly: 1,
          ttr_50cm_0730_med_monthly: 1,
          ttr_50cm_0730_max_monthly: 1,
          ttr_50cm_0730_min_monthly: 1,
          ttr_50cm_0730_sum_monthly: 1,
          ttr_50cm_0730_count_monthly: 1,
          ttr_100cm_0730_avg_monthly: 1,
          ttr_100cm_0730_mod_monthly: 1,
          ttr_100cm_0730_med_monthly: 1,
          ttr_100cm_0730_max_monthly: 1,
          ttr_100cm_0730_min_monthly: 1,
          ttr_100cm_0730_sum_monthly: 1,
          ttr_100cm_0730_count_monthly: 1,

          ttg_0cm_0730_avg_monthly: 1,
          ttg_0cm_0730_mod_monthly: 1,
          ttg_0cm_0730_med_monthly: 1,
          ttg_0cm_0730_max_monthly: 1,
          ttg_0cm_0730_min_monthly: 1,
          ttg_0cm_0730_sum_monthly: 1,
          ttg_0cm_0730_count_monthly: 1,
          ttg_2cm_0730_avg_monthly: 1,
          ttg_2cm_0730_mod_monthly: 1,
          ttg_2cm_0730_med_monthly: 1,
          ttg_2cm_0730_max_monthly: 1,
          ttg_2cm_0730_min_monthly: 1,
          ttg_2cm_0730_sum_monthly: 1,
          ttg_2cm_0730_count_monthly: 1,
          ttg_5cm_0730_avg_monthly: 1,
          ttg_5cm_0730_mod_monthly: 1,
          ttg_5cm_0730_med_monthly: 1,
          ttg_5cm_0730_max_monthly: 1,
          ttg_5cm_0730_min_monthly: 1,
          ttg_5cm_0730_sum_monthly: 1,
          ttg_5cm_0730_count_monthly: 1,
          ttg_10cm_0730_avg_monthly: 1,
          ttg_10cm_0730_mod_monthly: 1,
          ttg_10cm_0730_med_monthly: 1,
          ttg_10cm_0730_max_monthly: 1,
          ttg_10cm_0730_min_monthly: 1,
          ttg_10cm_0730_sum_monthly: 1,
          ttg_10cm_0730_count_monthly: 1,
          ttg_20cm_0730_avg_monthly: 1,
          ttg_20cm_0730_mod_monthly: 1,
          ttg_20cm_0730_med_monthly: 1,
          ttg_20cm_0730_max_monthly: 1,
          ttg_20cm_0730_min_monthly: 1,
          ttg_20cm_0730_sum_monthly: 1,
          ttg_20cm_0730_count_monthly: 1,
          ttg_50cm_0730_avg_monthly: 1,
          ttg_50cm_0730_mod_monthly: 1,
          ttg_50cm_0730_med_monthly: 1,
          ttg_50cm_0730_max_monthly: 1,
          ttg_50cm_0730_min_monthly: 1,
          ttg_50cm_0730_sum_monthly: 1,
          ttg_50cm_0730_count_monthly: 1,
          ttg_100cm_0730_avg_monthly: 1,
          ttg_100cm_0730_mod_monthly: 1,
          ttg_100cm_0730_med_monthly: 1,
          ttg_100cm_0730_max_monthly: 1,
          ttg_100cm_0730_min_monthly: 1,
          ttg_100cm_0730_sum_monthly: 1,
          ttg_100cm_0730_count_monthly: 1,

          ttr_0cm_1330_avg_monthly: 1,
          ttr_0cm_1330_mod_monthly: 1,
          ttr_0cm_1330_med_monthly: 1,
          ttr_0cm_1330_max_monthly: 1,
          ttr_0cm_1330_min_monthly: 1,
          ttr_0cm_1330_sum_monthly: 1,
          ttr_0cm_1330_count_monthly: 1,
          ttr_2cm_1330_avg_monthly: 1,
          ttr_2cm_1330_mod_monthly: 1,
          ttr_2cm_1330_med_monthly: 1,
          ttr_2cm_1330_max_monthly: 1,
          ttr_2cm_1330_min_monthly: 1,
          ttr_2cm_1330_sum_monthly: 1,
          ttr_2cm_1330_count_monthly: 1,
          ttr_5cm_1330_avg_monthly: 1,
          ttr_5cm_1330_mod_monthly: 1,
          ttr_5cm_1330_med_monthly: 1,
          ttr_5cm_1330_max_monthly: 1,
          ttr_5cm_1330_min_monthly: 1,
          ttr_5cm_1330_sum_monthly: 1,
          ttr_5cm_1330_count_monthly: 1,
          ttr_10cm_1330_avg_monthly: 1,
          ttr_10cm_1330_mod_monthly: 1,
          ttr_10cm_1330_med_monthly: 1,
          ttr_10cm_1330_max_monthly: 1,
          ttr_10cm_1330_min_monthly: 1,
          ttr_10cm_1330_sum_monthly: 1,
          ttr_10cm_1330_count_monthly: 1,
          ttr_20cm_1330_avg_monthly: 1,
          ttr_20cm_1330_mod_monthly: 1,
          ttr_20cm_1330_med_monthly: 1,
          ttr_20cm_1330_max_monthly: 1,
          ttr_20cm_1330_min_monthly: 1,
          ttr_20cm_1330_sum_monthly: 1,
          ttr_20cm_1330_count_monthly: 1,
          ttr_50cm_1330_avg_monthly: 1,
          ttr_50cm_1330_mod_monthly: 1,
          ttr_50cm_1330_med_monthly: 1,
          ttr_50cm_1330_max_monthly: 1,
          ttr_50cm_1330_min_monthly: 1,
          ttr_50cm_1330_sum_monthly: 1,
          ttr_50cm_1330_count_monthly: 1,
          ttr_100cm_1330_avg_monthly: 1,
          ttr_100cm_1330_mod_monthly: 1,
          ttr_100cm_1330_med_monthly: 1,
          ttr_100cm_1330_max_monthly: 1,
          ttr_100cm_1330_min_monthly: 1,
          ttr_100cm_1330_sum_monthly: 1,
          ttr_100cm_1330_count_monthly: 1,

          ttg_0cm_1330_avg_monthly: 1,
          ttg_0cm_1330_mod_monthly: 1,
          ttg_0cm_1330_med_monthly: 1,
          ttg_0cm_1330_max_monthly: 1,
          ttg_0cm_1330_min_monthly: 1,
          ttg_0cm_1330_sum_monthly: 1,
          ttg_0cm_1330_count_monthly: 1,
          ttg_2cm_1330_avg_monthly: 1,
          ttg_2cm_1330_mod_monthly: 1,
          ttg_2cm_1330_med_monthly: 1,
          ttg_2cm_1330_max_monthly: 1,
          ttg_2cm_1330_min_monthly: 1,
          ttg_2cm_1330_sum_monthly: 1,
          ttg_2cm_1330_count_monthly: 1,
          ttg_5cm_1330_avg_monthly: 1,
          ttg_5cm_1330_mod_monthly: 1,
          ttg_5cm_1330_med_monthly: 1,
          ttg_5cm_1330_max_monthly: 1,
          ttg_5cm_1330_min_monthly: 1,
          ttg_5cm_1330_sum_monthly: 1,
          ttg_5cm_1330_count_monthly: 1,
          ttg_10cm_1330_avg_monthly: 1,
          ttg_10cm_1330_mod_monthly: 1,
          ttg_10cm_1330_med_monthly: 1,
          ttg_10cm_1330_max_monthly: 1,
          ttg_10cm_1330_min_monthly: 1,
          ttg_10cm_1330_sum_monthly: 1,
          ttg_10cm_1330_count_monthly: 1,
          ttg_20cm_1330_avg_monthly: 1,
          ttg_20cm_1330_mod_monthly: 1,
          ttg_20cm_1330_med_monthly: 1,
          ttg_20cm_1330_max_monthly: 1,
          ttg_20cm_1330_min_monthly: 1,
          ttg_20cm_1330_sum_monthly: 1,
          ttg_20cm_1330_count_monthly: 1,
          ttg_50cm_1330_avg_monthly: 1,
          ttg_50cm_1330_mod_monthly: 1,
          ttg_50cm_1330_med_monthly: 1,
          ttg_50cm_1330_max_monthly: 1,
          ttg_50cm_1330_min_monthly: 1,
          ttg_50cm_1330_sum_monthly: 1,
          ttg_50cm_1330_count_monthly: 1,
          ttg_100cm_1330_avg_monthly: 1,
          ttg_100cm_1330_mod_monthly: 1,
          ttg_100cm_1330_med_monthly: 1,
          ttg_100cm_1330_max_monthly: 1,
          ttg_100cm_1330_min_monthly: 1,
          ttg_100cm_1330_sum_monthly: 1,
          ttg_100cm_1330_count_monthly: 1,

          ttr_0cm_1730_avg_monthly: 1,
          ttr_0cm_1730_mod_monthly: 1,
          ttr_0cm_1730_med_monthly: 1,
          ttr_0cm_1730_max_monthly: 1,
          ttr_0cm_1730_min_monthly: 1,
          ttr_0cm_1730_sum_monthly: 1,
          ttr_0cm_1730_count_monthly: 1,
          ttr_2cm_1730_avg_monthly: 1,
          ttr_2cm_1730_mod_monthly: 1,
          ttr_2cm_1730_med_monthly: 1,
          ttr_2cm_1730_max_monthly: 1,
          ttr_2cm_1730_min_monthly: 1,
          ttr_2cm_1730_sum_monthly: 1,
          ttr_2cm_1730_count_monthly: 1,
          ttr_5cm_1730_avg_monthly: 1,
          ttr_5cm_1730_mod_monthly: 1,
          ttr_5cm_1730_med_monthly: 1,
          ttr_5cm_1730_max_monthly: 1,
          ttr_5cm_1730_min_monthly: 1,
          ttr_5cm_1730_sum_monthly: 1,
          ttr_5cm_1730_count_monthly: 1,
          ttr_10cm_1730_avg_monthly: 1,
          ttr_10cm_1730_mod_monthly: 1,
          ttr_10cm_1730_med_monthly: 1,
          ttr_10cm_1730_max_monthly: 1,
          ttr_10cm_1730_min_monthly: 1,
          ttr_10cm_1730_sum_monthly: 1,
          ttr_10cm_1730_count_monthly: 1,
          ttr_20cm_1730_avg_monthly: 1,
          ttr_20cm_1730_mod_monthly: 1,
          ttr_20cm_1730_med_monthly: 1,
          ttr_20cm_1730_max_monthly: 1,
          ttr_20cm_1730_min_monthly: 1,
          ttr_20cm_1730_sum_monthly: 1,
          ttr_20cm_1730_count_monthly: 1,
          ttr_50cm_1730_avg_monthly: 1,
          ttr_50cm_1730_mod_monthly: 1,
          ttr_50cm_1730_med_monthly: 1,
          ttr_50cm_1730_max_monthly: 1,
          ttr_50cm_1730_min_monthly: 1,
          ttr_50cm_1730_sum_monthly: 1,
          ttr_50cm_1730_count_monthly: 1,
          ttr_100cm_1730_avg_monthly: 1,
          ttr_100cm_1730_mod_monthly: 1,
          ttr_100cm_1730_med_monthly: 1,
          ttr_100cm_1730_max_monthly: 1,
          ttr_100cm_1730_min_monthly: 1,
          ttr_100cm_1730_sum_monthly: 1,
          ttr_100cm_1730_count_monthly: 1,

          ttg_0cm_1730_avg_monthly: 1,
          ttg_0cm_1730_mod_monthly: 1,
          ttg_0cm_1730_med_monthly: 1,
          ttg_0cm_1730_max_monthly: 1,
          ttg_0cm_1730_min_monthly: 1,
          ttg_0cm_1730_sum_monthly: 1,
          ttg_0cm_1730_count_monthly: 1,
          ttg_2cm_1730_avg_monthly: 1,
          ttg_2cm_1730_mod_monthly: 1,
          ttg_2cm_1730_med_monthly: 1,
          ttg_2cm_1730_max_monthly: 1,
          ttg_2cm_1730_min_monthly: 1,
          ttg_2cm_1730_sum_monthly: 1,
          ttg_2cm_1730_count_monthly: 1,
          ttg_5cm_1730_avg_monthly: 1,
          ttg_5cm_1730_mod_monthly: 1,
          ttg_5cm_1730_med_monthly: 1,
          ttg_5cm_1730_max_monthly: 1,
          ttg_5cm_1730_min_monthly: 1,
          ttg_5cm_1730_sum_monthly: 1,
          ttg_5cm_1730_count_monthly: 1,
          ttg_10cm_1730_avg_monthly: 1,
          ttg_10cm_1730_mod_monthly: 1,
          ttg_10cm_1730_med_monthly: 1,
          ttg_10cm_1730_max_monthly: 1,
          ttg_10cm_1730_min_monthly: 1,
          ttg_10cm_1730_sum_monthly: 1,
          ttg_10cm_1730_count_monthly: 1,
          ttg_20cm_1730_avg_monthly: 1,
          ttg_20cm_1730_mod_monthly: 1,
          ttg_20cm_1730_med_monthly: 1,
          ttg_20cm_1730_max_monthly: 1,
          ttg_20cm_1730_min_monthly: 1,
          ttg_20cm_1730_sum_monthly: 1,
          ttg_20cm_1730_count_monthly: 1,
          ttg_50cm_1730_avg_monthly: 1,
          ttg_50cm_1730_mod_monthly: 1,
          ttg_50cm_1730_med_monthly: 1,
          ttg_50cm_1730_max_monthly: 1,
          ttg_50cm_1730_min_monthly: 1,
          ttg_50cm_1730_sum_monthly: 1,
          ttg_50cm_1730_count_monthly: 1,
          ttg_100cm_1730_avg_monthly: 1,
          ttg_100cm_1730_mod_monthly: 1,
          ttg_100cm_1730_med_monthly: 1,
          ttg_100cm_1730_max_monthly: 1,
          ttg_100cm_1730_min_monthly: 1,
          ttg_100cm_1730_sum_monthly: 1,
          ttg_100cm_1730_count_monthly: 1,
        },
      },
    ];
    return listAttr;
  },
};
