import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
        {
            label: "Pibal",
            attr: {
                // "station_wmo_id":"WMO ID",
                "station_elevation" : "Elevasi Stasiun",
                "wind_dir_surface":"Arah Angin Permukaan",
                "wind_speed_surface":"Kecepatan Angin Permukaan",
                "sandi_pibal" : "Sandi Pibal",
                "pembacaan_azimuth_elevasi[azimuth]":"Azimut",
                "pembacaan_azimuth_elevasi[elevasi]":"Elevasi",
                "hasil_lapisan_wd_ws_dddff[lapisan]" : "Lapisan",
                "hasil_lapisan_wd_ws_dddff[wd]":"Arah",
                "hasil_lapisan_wd_ws_dddff[ws]":"Kecepatan",
                "hasil_lapisan_wd_ws_dddff[dddff]":"dddff",
                "penghentian_pengamatan" : "Penghentian Pengamatan", 
                  
                },  
        },
        {
            label: "Observer",
            attr: {
                "observer_id" : "ID observer",
                "observer_name" : "Nama observer "
                },
        },
        
        ]
        return listAttr;
    }
    
}







