import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
        {
            label: "OP Penguapan",
            attr: {
                "m_0700ws[op_diff_baca_0700]" : "Beda pembacaan tinggi air pada bejana selama 24 jam dibaca pada jam 07.00",
                "m_0700ws[t_air_max_0700]" : "Temperatur air Maksimum di jam 07.00",
                "m_0700ws[t_air_min_0700]" : "Temperatur air Minimum di jam 07.00",
                "m_0700ws[t_air_avg_0700]" : "Temperatur air Rata-rata di jam 07.00",
                "m_0700ws[rr_0700]" : "curah hujan selama 24 jam yang dibaca pada jam 07.00",
                "m_0700ws[op_eva_0700]" : "penguapan selama 24 jam yang dibaca pada jam 07.00",
                "m_0700ws[ws_avg_0c5_0700]" : "Kecepatan angin rata-rata 0,5m jam 07.00",
                // "m_0730ws[op_diff_baca_0730]" : "Beda pembacaan tinggi air pada bejana selama 14 jam dibaca pada jam 0730",
                // "m_0730ws[h_air_0730]" : "Cup counter dibaca 0.5 m 07.30 WS hari ini",
                // "m_0730ws[h_air_diff_0730]" : "Cup Counter dibaca 0.5 m 17.30 WS kemarin dibaca di jam 07.30",
                // "m_0730ws[h_air_setelah_kuras_0730]" : "Tinggi air setelah dikuras yang dibaca pada jam 07.30",
                // "m_0730ws[h_air_sebelum_kuras_0730]" : "Tinggi air sebelum dikuras yang dibaca pada jam 07.30",
                // "m_0730ws[h_air_diff_setelah_kuras_0730]" : "Beda Tinggi air setelah dikuras yang dibaca pada jam 07.30",
                // "m_0730ws[h_air_diff_sebelum_kuras_0730]" : "Beda Tinggi air sebelum dikuras yang dibaca pada jam 07.30",
                // "m_0730ws[t_air_max_0730]" : "Temperatur air Maksimum dibaca pada jam 07.30",
                // "m_0730ws[t_air_min_0730]" : "Temperatur air Minimum ddibaca pada jam 07.30",
                // "m_0730ws[t_air_avg_0730]" : "Temperatur Rata-rata air dibaca pada jam 07.30",
                // "m_0730ws[rr_0730]" : "Hasil penakaran curah hujan selama 14 jam dibaca pada jam 07.30",
                // "m_0730ws[op_eva_0730]" : "Nilai penguapan dibaca pada jam 07.30",
                // "m_1330ws[op_diff_baca_1330]" : "Beda pembacaan tinggi air pada bejana selama 6 jam dibaca pada jam 1330",
                // "m_1330ws[h_air_1330]" : "Cup counter dibaca 0.5 m 13.30 WS hari ini",
                // "m_1330ws[h_air_diff_1330]" : "Cup counter dibaca 0.5 m 07.30 WS hari ini dibaca pada jam 07.30",
                // "m_1330ws[h_air_setelah_kuras_1330]" : "Tinggi air setelah dikuras yang dibaca pada jam 13.30",
                // "m_1330ws[h_air_sebelum_kuras_1330]" : "Tinggi air sebelum dikuras yang dibaca pada jam 13.30",
                // "m_1330ws[h_air_diff_setelah_kuras_1330]" : "Beda Tinggi air setelah dikuras yang dibaca pada jam 13.30",
                // "m_1330ws[h_air_diff_sebelum_kuras_1330]" : "Beda Tinggi air sebelum dikuras yang dibaca pada jam 13.30",
                // "m_1330ws[t_air_max_1330]" : "Temperatur air Maksimum dibaca pada jam 13.30",
                // "m_1330ws[t_air_min_1330]" : "Temperatur air Minimum dibaca pada jam 13.30",
                // "m_1330ws[t_air_avg_1330]" : "Temperatur Rata-rata air dibaca pada jam 13.30",
                // "m_1330ws[rr_1330]" : "Hasil penakaran curah hujan selama 6 jam dibaca pada jam 13.30",
                // "m_1330ws[op_eva_1330]" : "Nilai penguapan dibaca pada jam 13.30",
                // "m_1730ws[op_diff_baca_1730]" : "Beda pembacaan tinggi air pada bejana selama 4 jam dibaca pada jam 1730",
                // "m_1730ws[h_air_1730]" : "Cup counter dibaca 0.5 m 17.30 WS hari ini",
                // "m_1730ws[h_air_diff_1730]" : "Cup Counter dibaca 0.5 m 13.30 WS hari ini dibaca pada jam 1730",
                // "m_1730ws[h_air_setelah_kuras_1730]" : "Tinggi air setelah dikuras yang dibaca pada jam 17.30",
                // "m_1730ws[h_air_sebelum_kuras_1730]" : "Tinggi air sebelum dikuras yang dibaca pada jam 17.30",
                // "m_1730ws[h_air_diff_setelah_kuras_1730]" : "Beda Tinggi air setelah dikuras yang dibaca pada jam 17.30",
                // "m_1730ws[h_air_diff_sebelum_kuras_1730]" : "Beda Tinggi air sebelum dikuras yang dibaca pada jam 17.30",
                // "m_1730ws[t_air_max_1730]" : "Temperatur air Maksimum dibaca pada jam 17.30",
                // "m_1730ws[t_air_min_1730]" : "Temperatur air Minimum dibaca pada jam 17.30",
                // "m_1730ws[t_air_avg_1730]" : "Temperatur Rata-rata air dibaca pada jam 17.30",
                // "m_1730ws[rr_1730]" : "Hasil penakaran curah hujan selama 4 jam dibaca pada jam 17.30",
                // "m_1730ws[op_eva_1730]" : "Nilai penguapan dibaca pada jam 17.30"                
                },
            },

        ]
        return listAttr;
    },

    getAttrMonthly() {
        const listAttr = [
            {
                group: "Penguapan",
                form: "OP Penguapan",
                label: "Penguapan",
                attr: {
                        "op_eva_0700_avg_monthly" : "penguapan selama 24 jam yang dibaca pada jam 07.00 AVG MONTHLY",
                        "op_eva_0700_mod_monthly" : "penguapan selama 24 jam yang dibaca pada jam 07.00 MOD MONTHLY",
                        "op_eva_0700_med_monthly" : "penguapan selama 24 jam yang dibaca pada jam 07.00 MED MONTHLY",
                        "op_eva_0700_max_monthly" : "penguapan selama 24 jam yang dibaca pada jam 07.00 MAX MONTHLY",
                        "op_eva_0700_min_monthly" : "penguapan selama 24 jam yang dibaca pada jam 07.00 MIN MONTHLY",
                        "op_eva_0700_sum_monthly" : "penguapan selama 24 jam yang dibaca pada jam 07.00 SUM MONTHLY",
                        "op_eva_0700_count_monthly" : "penguapan selama 24 jam yang dibaca pada jam 07.00 COUNT MONTHLY", 
                    },
                },
    
            ]
        return listAttr;
    },

    getAttrMonthlyCsv() {
        const listAttr = [
            {
                label: "OP Penguapan",
                attr: {
                        "op_eva_0700_avg_monthly" : "op_eva_0700_monthly_avg",
                        "op_eva_0700_mod_monthly" : "op_eva_0700_monthly_mod",
                        "op_eva_0700_med_monthly" : "op_eva_0700_monthly_med",
                        "op_eva_0700_max_monthly" : "op_eva_0700_monthly_max",
                        "op_eva_0700_min_monthly" : "op_eva_0700_monthly_min",
                        "op_eva_0700_sum_monthly" : "op_eva_0700_monthly_sum",
                        "op_eva_0700_count_monthly" : "count_op_eva_0700_monthly", 
                    },
                },
    
            ]
        return listAttr;
    },

    getAttrMonthlyDecimal() {
        const listAttr = [
            {
                label: "OP Penguapan",
                attr: {
                        "op_eva_0700_avg_monthly" : 1,
                        "op_eva_0700_mod_monthly" : 1,
                        "op_eva_0700_med_monthly" : 1,
                        "op_eva_0700_max_monthly" : 1,
                        "op_eva_0700_min_monthly" : 1,
                        "op_eva_0700_sum_monthly" : 1,
                        "op_eva_0700_count_monthly" : 1, 
                    },
                },
    
            ]
        return listAttr;
    },

    getAttrDaily() {
        const listAttr = [
            {
                label: "OP Penguapan",
                attr: {
                    "m_0700ws[op_eva_0700]" : "Penguapan jam 07.00 WS DAILY",
                    "m_0700ws[t_air_max_0700]" : "Suhu Air Maksimum jam 07.30 WS DAILY",
                    "m_0700ws[t_air_min_0700]" : "Suhu Air Minimum jam 07.30 WS DAILY",
                    "m_0700ws[t_air_avg_0700]" : "Suhu Air Rata-rata jam 07.30 WS DAILY"
                }
            }
        ];
        return listAttr;
    },

    getAttrDailyCsv() {
        const listAttr = [
            {
                label: "OP Penguapan Harian Csv",
                attr: {
                    "op_eva_0700" : "op_eva_daily_mm",
                    "t_air_max_0700" : "t_air_max_daily_C",
                    "t_air_min_0700" : "t_air_min_daily_C",
                    "t_air_avg_0700" : "t_air_avg_daily_C"
                },
            }
        ];
        return listAttr;
    },

    getAttrDailyDecimal() {
        const listAttr = [
            {
                label: "OP Penguapan Harian Decimal",
                attr: {
                    "op_eva_0700" : 1,
                    "t_air_max_0700" : 1,
                    "t_air_min_0700" : 1,
                    "t_air_avg_0700" : 1
                },
            },
        ];
        return listAttr;
    },
    
}
