import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
        {
            label: "AGM1A",
            attr: {
                "m_0700ws[tbk_1c2m_0700]" : "Suhu bola kering 1.2 m 07.00",
                "m_0700ws[tbb_1c2m_0700]" : "suhu bola basah ketinggian 1.2m 0700",
                "m_1400ws[tbk_1c2m_1400]" : "Suhu bola kering 1.2 m 14.00",
                "m_1400ws[tbb_1c2m_1400]" : "suhu bola basah ketinggian 1.2m 14.00",
                "m_1800ws[tbk_1c2m_1800]" : "Suhu bola kering 1.2 m 18.00",
                "m_1800ws[tbb_1c2m_1800]" : "suhu bola basah ketinggian 1.2m 18.00",
                "m_0700ws[tn_rumput_0700]" : "suhu minimum rumput 07.00",
                "m_1400ws[t_min_uji_1400]" : "suhu minimum (Tmin) reset jam 14 .00",
                "m_1800ws[t_max_1c2m]" : "suhu maksimum di ketinggian 1.2m ",
                "m_0700ws[rh_1c2m_0700]" : "rel hum ketinggian 1.2m jam 07.00",
                "m_1400ws[rh_1c2m_1400]" : "rel hum ketinggian 1.2m jam 14.00",
                "m_1800ws[rh_1c2m_1800]" : "rel hum ketinggian 1.2m jam 18.00",
                "m_0700ws[ws_avg_0c5_0700]" : "Kecepatan angin rata-rata,  tingginya 0.5 m di jam 07.00",
                "m_1400ws[ws_avg_7_1400]" : "Kecepatan angin rata-rata, tingginya 7m di jam 14.00",
                "m_1800ws[ws_avg_7_1800]" :"Kecepatan angin rata-rata,  tingginya 7 m jam 18.00",
                // "m_1800ws[ws_avg_0c5_1800]" : "Kecepatan angin rata-rata,  tingginya 0.5 m jam 18.00",
                "m_0700ws[wd_0700]" : "arah angin jam 07.00",
                "m_1400ws[wd_1400]" : "arah angin jam 14.00",
                "m_1800ws[wd_1800]" : "arah angin jam 18.00",
                "m_0700ws[ws_0700]" : "kecepatan angin jam 07.00",
                "m_1400ws[ws_1400]" : "kecepatan angin jam 14.00",
                "m_1800ws[ws_1800]" : "kecepatan angin jam 18.00",
                "m_0700ws[ss_12]" : "lama penyinaran jam 12",
                "m_0700ws[rr_0700]" : "Curah hujan pada jam 07.00",
                "m_1400ws[tbk_1c2m_1400]" : "Suhu bola kering 1.2 m pada jam 14.00",
                "m_1400ws[t_min_uji_1400]" : "suhu minimum (Tmin) reset jam 14 .00",
                "m_1800ws[tbk_1c2m_1800]" : "Suhu bola kering 1.2 m pada jam 18.00",
                "m_1800ws[t_reset_1c2m_1800]" : "suhu minimum (Tmin) reset jam 18WS",
                "m_0700ws[co_cu_0700]" : "keadaan cuaca jam 0700",
                "m_1400ws[co_cu_1400]" : "Keadaan cuaca jam 14.00",
                "m_0700ws[ku_hujan]" : "keadaan udara hujan",
                "m_0700ws[ku_pembekuan]" : "keadaan udara pembekuan",
                "m_0700ws[ku_embun]" : "keadaan udara embun",
                "m_0700ws[ku_kabut]" : "keadaan udara kabut",
                "m_0700ws[ku_rambun]" : "keadaan udara rambun",
                "m_0700ws[ku_tanpa_rambun]" : "keadaan udara tanpa rambun",
                "m_0700ws[ku_24]" : "keadaan udara dalam 24 jam",
                "m_0700ws[ku_kabut_debu]" : "keadaan udara kabut debu",
                "m_0700ws[ku_salju]" : "keadaan udara salju",
                "m_0700ws[ku_angin_ribut]" : "keadaan udara angin ribut",
                },
        },
        {
            label: "Observer",
            attr: {
                "observer_id" : "ID observer",
                "observer_name" : "Nama observer "
                },
        },
        ]
        return listAttr;
    },


    getAttrMonthly() {
        const listAttr = [
            {
                group: "Temperatur",
                form: "Agm1a",
                label: "Temperatur",
                attr: {
                    "t_max_1c2m_avg_monthly" : "Suhu Maksimum di ketinggian 1.2m AVG MONTHLY",
                    "t_max_1c2m_mod_monthly" : "Suhu Maksimum di ketinggian 1.2m MOD MONTHLY",
                    "t_max_1c2m_med_monthly" : "Suhu Maksimum di ketinggian 1.2m MED MONTHLY",
                    "t_max_1c2m_max_monthly" : "Suhu Maksimum di ketinggian 1.2m MAX MONTHLY",
                    "t_max_1c2m_min_monthly" : "Suhu Maksimum di ketinggian 1.2m MIN MONTHLY",
                    "t_max_1c2m_sum_monthly" : "Suhu Maksimum di ketinggian 1.2m SUM MONTHLY",
                    "t_max_1c2m_count_monthly" : "Suhu Maksimum di ketinggian 1.2m COUNT MONTHLY"

                },
            },
            {
                group: "Kelembaban",
                form: "Agm1a",
                label: "Kelembaban",
                attr: {
                    "rr_0700_avg_monthly" : "Curah Hujan pada jam 07.00 AVG MONTHLY",
                    "rr_0700_mod_monthly" : "Curah Hujan pada jam 07.00 MOD MONTHLY",
                    "rr_0700_med_monthly" : "Curah Hujan pada jam 07.00 MED MONTHLY",
                    "rr_0700_max_monthly" : "Curah Hujan pada jam 07.00 MAX MONTHLY",
                    "rr_0700_min_monthly" : "Curah Hujan pada jam 07.00 MIN MONTHLY",
                    "rr_0700_sum_monthly" : "Curah Hujan pada jam 07.00 SUM MONTHLY",
                    "rr_0700_count_monthly" : "Curah Hujan pada jam 07.00 COUNT MONTHLY"
                },
            }
        ]
        return listAttr;
    },

    getAttrMonthlyCsv() {
        const listAttr = [
            {
                label: "AGM1A",
                attr: {
                    "t_max_1c2m_avg_monthly" : "t_max_1c2m_monthly_avg",
                    "t_max_1c2m_mod_monthly" : "t_max_1c2m_monthly_mod",
                    "t_max_1c2m_med_monthly" : "t_max_1c2m_monthly_med",
                    "t_max_1c2m_max_monthly" : "t_max_1c2m_monthly_max",
                    "t_max_1c2m_min_monthly" : "t_max_1c2m_monthly_min",
                    "t_max_1c2m_sum_monthly" : "t_max_1c2m_monthly_sum",
                    "t_max_1c2m_count_monthly" : "count_t_max_1c2m_monthly",

                    "rr_0700_avg_monthly" : "rr_0700_monthly_avg",
                    "rr_0700_mod_monthly" : "rr_0700_monthly_mod",
                    "rr_0700_med_monthly" : "rr_0700_monthly_med",
                    "rr_0700_max_monthly" : "rr_0700_monthly_max",
                    "rr_0700_min_monthly" : "rr_0700_monthly_min",
                    "rr_0700_sum_monthly" : "rr_0700_monthly_sum",
                    "rr_0700_count_monthly" : "count_rr_0700_monthly",

                },
            }
        ]
        return listAttr;
    },

    getAttrMonthlyDecimal() {
        const listAttr = [
            {
                label: "AGM1A",
                attr: {
                    "t_max_1c2m_avg_monthly" : 1,
                    "t_max_1c2m_mod_monthly" : 1,
                    "t_max_1c2m_med_monthly" : 1,
                    "t_max_1c2m_max_monthly" : 1,
                    "t_max_1c2m_min_monthly" : 1,
                    "t_max_1c2m_sum_monthly" : 1,
                    "t_max_1c2m_count_monthly" : 1,

                    "rr_0700_avg_monthly" : 1,
                    "rr_0700_mod_monthly" : 1,
                    "rr_0700_med_monthly" : 1,
                    "rr_0700_max_monthly" : 1,
                    "rr_0700_min_monthly" : 1,
                    "rr_0700_sum_monthly" : 1,
                    "rr_0700_count_monthly" : 1,

                },
            }
        ]
        return listAttr;
    }
    
}
