import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "Hujan",
        attr: {
          rr_phk_mm: "RR PHK mm",
          rr_das_mm: "RR DAS mm",
        },
        
      }
    ];
    return listAttr;
  },

  getAttrMonthly() {
    const listAttr = [
      {
        group: "Hujan",
        form: "Form Hujan",
        label: "Hujan",
        attr: {
          "rr_phk_avg_monthly": "RR PHK mm AVG MONTHLY",
          "rr_phk_mod_monthly": "RR PHK mm MOD MONTHLY",
          "rr_phk_med_monthly": "RR PHK mm MED MONTHLY",
          "rr_phk_max_monthly": "RR PHK mm MAX MONTHLY",
          "rr_phk_min_monthly": "RR PHK mm MIN MONTHLY",
          "rr_phk_sum_monthly": "RR PHK mm SUM MONTHLY",
          "rr_phk_count_monthly": "RR PHK mm COUNT MONTHLY",
        },
        
      }
    ];
    return listAttr;
  },

  getAttrMonthlyCsv() {
    const listAttr = [
      {
        label: "Hujan",
        attr: {
          "rr_phk_avg_monthly": "rr_phk_monthly_avg_mm",
          "rr_phk_mod_monthly": "rr_phk_monthly_mod_mm",
          "rr_phk_med_monthly": "rr_phk_monthly_med_mm",
          "rr_phk_max_monthly": "rr_phk_monthly_max_mm",
          "rr_phk_min_monthly": "rr_phk_monthly_min_mm",
          "rr_phk_sum_monthly": "rr_phk_monthly_sum_mm",
          "rr_phk_count_monthly": "count_rr_phk_monthly_mm",
        },
        
      }
    ];
    return listAttr;
  },

  getAttrMonthlyDecimal() {
    const listAttr = [
      {
        label: "Hujan",
        attr: {
          "rr_phk_avg_monthly": 1,
          "rr_phk_mod_monthly": 1,
          "rr_phk_med_monthly": 1,
          "rr_phk_max_monthly": 1,
          "rr_phk_min_monthly": 1,
          "rr_phk_sum_monthly": 1,
          "rr_phk_count_monthly": 1,
        },
        
      }
    ];
    return listAttr;
  },

  getAttrDaily() {
    const listAttr = [
      {
        label: "Hujan",
        attr: {
          rr_phk_mm: "RR PHK DAILY MM"
        }
      }
    ];
    return listAttr;
  },

  getAttrDailyCsv() {
    const listAttr = [
      {
        label: "Hujan",
        attr: {
          rr_phk_mm: "rr_phk_daily_mm"
        },
      }
    ];
    return listAttr;
  },

  getAttrDailyDecimal() {
    const listAttr = [
      {
        label: "Hujan",
        attr: {
          rr_phk_mm: 1
        },
      },
    ];
    return listAttr;
  },
};
