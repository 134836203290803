import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "Aerosol",
        attr: {
          "aerosol_ph":"pH",
          "aerosol_conductivity":"Electro-Conductivity",
          "aerosol_cl":"Cl- (klorida)",
          "aerosol_so42":"SO42- (Sulfat)",
          "aerosol_no3":"NO3- (Nitrat)",
          "aerosol_na":"Na+ (Natrium)",
          "aerosol_nh4":"NH4+ (ammonium)",
          "aerosol_k":"K+ (Kalium)",
          "aerosol_mg2":"Mg2+ (Magnesium)",
          "aerosol_ca2":"Ca2+ (Kalsium)",
          "aerosol_konsentrasi":"Konsentrasi Aerosol/PM",
          // "aerosol_bobot_filter_kosong":"Bobot Filter Kosong",
          // "aerosol_bobot_filter_sampling":"Bobot Filter Sampling",
          // "aerosol_flow_rate_awal":"Flow Rate Awal",
          // "aerosol_flow_rate_akhir":"Flow Rate Akhir",
          // "aerosol_total_waktu_sampling":"Total Waktu Sampling (menit)",
          // "aerosol_tekanan_udara_awal":"Tekanan udara awal",
          // "aerosol_tekanan_udara_akhir":"Tekanan udara akhir",
          // "aerosol_temperatur_awal":"Temperature awal",
          // "aerosol_temperatur_akhir":"Temperature akhir"          
        },
      },
      
    //   {
    //     label: "Observer",
    //     attr: {
    //         "observer_id" : "ID observer",
    //         "observer_name" : "Nama observer "
    //         },
    // },
    ];

    return listAttr;
  },

  getAttrMonthly() {
    const listAttrMonthly = [
      {
        label: "Aerosol",
        attr: {
          "aerosol_ph_avg_monthly":"pH AVG MONTHLY",
          // "aerosol_ph_mod_monthly":"pH MOD MONTHLY",
          // "aerosol_ph_med_monthly":"pH MED MONTHLY",
          "aerosol_ph_max_monthly":"pH MAX MONTHLY",
          "aerosol_ph_min_monthly":"pH MIN MONTHLY",
          // "aerosol_ph_sum_monthly":"pH SUM MONTHLY",
          "aerosol_ph_count_monthly":"pH COUNT MONTHLY",
          "aerosol_conductivity_avg_monthly":"Electro-Conductivity AVG MONTHLY",
          // "aerosol_conductivity_mod_monthly":"Electro-Conductivity MOD MONTHLY",
          // "aerosol_conductivity_med_monthly":"Electro-Conductivity MED MONTHLY",
          "aerosol_conductivity_max_monthly":"Electro-Conductivity MAX MONTHLY",
          "aerosol_conductivity_min_monthly":"Electro-Conductivity MIN MONTHLY",
          // "aerosol_conductivity_sum_monthly":"Electro-Conductivity SUM MONTHLY",
          "aerosol_conductivity_count_monthly":"Electro-Conductivity COUNT MONTHLY",
          "aerosol_konsentrasi_avg_monthly":"Konsentrasi Aerosol/PM AVG MONTHLY",
          // "aerosol_konsentrasi_mod_monthly":"Konsentrasi Aerosol/PM MOD MONTHLY",
          // "aerosol_konsentrasi_med_monthly":"Konsentrasi Aerosol/PM MED MONTHLY",
          "aerosol_konsentrasi_max_monthly":"Konsentrasi Aerosol/PM MAX MONTHLY",
          "aerosol_konsentrasi_min_monthly":"Konsentrasi Aerosol/PM MIN MONTHLY",
          // "aerosol_konsentrasi_sum_monthly":"Konsentrasi Aerosol/PM SUM MONTHLY",
          "aerosol_konsentrasi_count_monthly":"Konsentrasi Aerosol/PM COUNT MONTHLY",
          // "aerosol_bobot_filter_kosong_avg_monthly":"Bobot Filter Kosong AVG MONTHLY",
          // "aerosol_bobot_filter_kosong_mod_monthly":"Bobot Filter Kosong MOD MONTHLY",
          // "aerosol_bobot_filter_kosong_med_monthly":"Bobot Filter Kosong MED MONTHLY",
          // "aerosol_bobot_filter_kosong_max_monthly":"Bobot Filter Kosong MAX MONTHLY",
          // "aerosol_bobot_filter_kosong_min_monthly":"Bobot Filter Kosong MIN MONTHLY",
          // "aerosol_bobot_filter_kosong_sum_monthly":"Bobot Filter Kosong SUM MONTHLY",
          // "aerosol_bobot_filter_kosong_count_monthly": "Bobot Filter Kosong COUNT MONTHLY",
          // "aerosol_bobot_filter_sampling_avg_monthly": "Bobot Filter Sampling AVG MONTHLY",
          // "aerosol_bobot_filter_sampling_mod_monthly": "Bobot Filter Sampling MOD MONTHLY",
          // "aerosol_bobot_filter_sampling_med_monthly": "Bobot Filter Sampling MED MONTHLY",
          // "aerosol_bobot_filter_sampling_max_monthly": "Bobot Filter Sampling MAX MONTHLY",
          // "aerosol_bobot_filter_sampling_min_monthly": "Bobot Filter Sampling MIN MONTHLY",
          // "aerosol_bobot_filter_sampling_sum_monthly": "Bobot Filter Sampling SUM MONTHLY",
          // "aerosol_bobot_filter_sampling_count_monthly": "Bobot Filter Sampling COUNT MONTHLY",
          // "aerosol_flow_rate_awal_avg_monthly": "Flow Rate Awal AVG MONTHLY",
          // "aerosol_flow_rate_awal_mod_monthly": "Flow Rate Awal MOD MONTHLY",
          // "aerosol_flow_rate_awal_med_monthly": "Flow Rate Awal MED MONTHLY",
          // "aerosol_flow_rate_awal_max_monthly": "Flow Rate Awal MAX MONTHLY",
          // "aerosol_flow_rate_awal_min_monthly": "Flow Rate Awal MIN MONTHLY",
          // "aerosol_flow_rate_awal_sum_monthly": "Flow Rate Awal SUM MONTHLY",
          // "aerosol_flow_rate_awal_count_monthly": "Flow Rate Awal COUNT MONTHLY",
          // "aerosol_flow_rate_akhir_avg_monthly": "Flow Rate Akhir AVG MONTHLY",
          // "aerosol_flow_rate_akhir_mod_monthly": "Flow Rate Akhir MOD MONTHLY",
          // "aerosol_flow_rate_akhir_med_monthly": "Flow Rate Akhir MED MONTHLY",
          // "aerosol_flow_rate_akhir_max_monthly": "Flow Rate Akhir MAX MONTHLY",
          // "aerosol_flow_rate_akhir_min_monthly": "Flow Rate Akhir MIN MONTHLY",
          // "aerosol_flow_rate_akhir_sum_monthly": "Flow Rate Akhir SUM MONTHLY",
          // "aerosol_flow_rate_akhir_count_monthly": "Flow Rate Akhir COUNT MONTHLY",
          // "aerosol_total_waktu_sampling_avg_monthly": "Total Waktu Sampling (menit) AVG MONTHLY",
          // "aerosol_total_waktu_sampling_mod_monthly": "Total Waktu Sampling (menit) MOD MONTHLY",
          // "aerosol_total_waktu_sampling_med_monthly": "Total Waktu Sampling (menit) MED MONTHLY",
          // "aerosol_total_waktu_sampling_max_monthly": "Total Waktu Sampling (menit) MAX MONTHLY",
          // "aerosol_total_waktu_sampling_min_monthly": "Total Waktu Sampling (menit) MIN MONTHLY",
          // "aerosol_total_waktu_sampling_sum_monthly": "Total Waktu Sampling (menit) SUM MONTHLY",
          // "aerosol_total_waktu_sampling_count_monthly": "Total Waktu Sampling (menit) COUNT MONTHLY",
          // "aerosol_tekanan_udara_awal_avg_monthly": "Tekanan Udara Awal AVG MONTHLY",
          // "aerosol_tekanan_udara_awal_mod_monthly": "Tekanan Udara Awal MOD MONTHLY",
          // "aerosol_tekanan_udara_awal_med_monthly": "Tekanan Udara Awal MED MONTHLY",
          // "aerosol_tekanan_udara_awal_max_monthly": "Tekanan Udara Awal MAX MONTHLY",
          // "aerosol_tekanan_udara_awal_min_monthly": "Tekanan Udara Awal MIN MONTHLY",
          // "aerosol_tekanan_udara_awal_sum_monthly": "Tekanan Udara Awal SUM MONTHLY",
          // "aerosol_tekanan_udara_awal_count_monthly": "Tekanan Udara Awal COUNT MONTHLY",
          // "aerosol_tekanan_udara_akhir_avg_monthly": "Tekanan Udara Akhir AVG MONTHLY",
          // "aerosol_tekanan_udara_akhir_mod_monthly": "Tekanan Udara Akhir MOD MONTHLY",
          // "aerosol_tekanan_udara_akhir_med_monthly": "Tekanan Udara Akhir MED MONTHLY",
          // "aerosol_tekanan_udara_akhir_max_monthly": "Tekanan Udara Akhir MAX MONTHLY",
          // "aerosol_tekanan_udara_akhir_min_monthly": "Tekanan Udara Akhir MIN MONTHLY",
          // "aerosol_tekanan_udara_akhir_sum_monthly": "Tekanan Udara Akhir SUM MONTHLY",
          // "aerosol_tekanan_udara_akhir_count_monthly": "Tekanan Udara Akhir COUNT MONTHLY",
          // "aerosol_temperatur_awal_avg_monthly": "Temperature Awal AVG MONTHLY",
          // "aerosol_temperatur_awal_mod_monthly": "Temperature Awal MOD MONTHLY",
          // "aerosol_temperatur_awal_med_monthly": "Temperature Awal MED MONTHLY",
          // "aerosol_temperatur_awal_max_monthly": "Temperature Awal MAX MONTHLY",
          // "aerosol_temperatur_awal_min_monthly": "Temperature Awal MIN MONTHLY",
          // "aerosol_temperatur_awal_sum_monthly": "Temperature Awal SUM MONTHLY",
          // "aerosol_temperatur_awal_count_monthly": "Temperature Awal COUNT MONTHLY",
          // "aerosol_temperatur_akhir_avg_monthly": "Temperature Akhir AVG MONTHLY",
          // "aerosol_temperatur_akhir_mod_monthly": "Temperature Akhir MOD MONTHLY",
          // "aerosol_temperatur_akhir_med_monthly": "Temperature Akhir MED MONTHLY",
          // "aerosol_temperatur_akhir_max_monthly": "Temperature Akhir MAX MONTHLY",
          // "aerosol_temperatur_akhir_min_monthly": "Temperature Akhir MIN MONTHLY",
          // "aerosol_temperatur_akhir_sum_monthly": "Temperature Akhir SUM MONTHLY",
          // "aerosol_temperatur_akhir_count_monthly": "Temperature Akhir COUNT MONTHLY",
          "aerosol_cl_avg_monthly": "Cl- (Klorida) AVG MONTHLY",
          // "aerosol_cl_mod_monthly": "Cl- (Klorida) MOD MONTHLY",
          // "aerosol_cl_med_monthly": "Cl- (Klorida) MED MONTHLY",
          "aerosol_cl_max_monthly": "Cl- (Klorida) MAX MONTHLY",
          "aerosol_cl_min_monthly": "Cl- (Klorida) MIN MONTHLY",
          // "aerosol_cl_sum_monthly": "Cl- (Klorida) SUM MONTHLY",
          "aerosol_cl_count_monthly": "Cl- (Klorida) COUNT MONTHLY",
          "aerosol_so42_avg_monthly": "SO42- (Sulfat) AVG MONTHLY",
          // "aerosol_so42_mod_monthly": "SO42- (Sulfat) MOD MONTHLY",
          // "aerosol_so42_med_monthly": "SO42- (Sulfat) MED MONTHLY",
          "aerosol_so42_max_monthly": "SO42- (Sulfat) MAX MONTHLY",
          "aerosol_so42_min_monthly": "SO42- (Sulfat) MIN MONTHLY",
          // "aerosol_so42_sum_monthly": "SO42- (Sulfat) SUM MONTHLY",
          "aerosol_so42_count_monthly": "SO42- (Sulfat) COUNT MONTHLY",
          "aerosol_no3_avg_monthly": "NO3- (Nitrat) AVG MONTHLY",
          // "aerosol_no3_mod_monthly": "NO3- (Nitrat) MOD MONTHLY",
          // "aerosol_no3_med_monthly": "NO3- (Nitrat) MED MONTHLY",
          "aerosol_no3_max_monthly": "NO3- (Nitrat) MAX MONTHLY",
          "aerosol_no3_min_monthly": "NO3- (Nitrat) MIN MONTHLY",
          // "aerosol_no3_sum_monthly": "NO3- (Nitrat) SUM MONTHLY",
          "aerosol_no3_count_monthly": "NO3- (Nitrat) COUNT MONTHLY",
          "aerosol_na_avg_monthly": "Na+ (Natrium) AVG MONTHLY",
          // "aerosol_na_mod_monthly": "Na+ (Natrium) MOD MONTHLY",
          // "aerosol_na_med_monthly": "Na+ (Natrium) MED MONTHLY",
          "aerosol_na_max_monthly": "Na+ (Natrium) MAX MONTHLY",
          "aerosol_na_min_monthly": "Na+ (Natrium) MIN MONTHLY",
          // "aerosol_na_sum_monthly": "Na+ (Natrium) SUM MONTHLY",
          "aerosol_na_count_monthly": "Na+ (Natrium) COUNT MONTHLY",
          "aerosol_nh4_avg_monthly": "NH4+ (Ammonium) AVG MONTHLY",
          // "aerosol_nh4_mod_monthly": "NH4+ (Ammonium) MOD MONTHLY",
          // "aerosol_nh4_med_monthly": "NH4+ (Ammonium) MED MONTHLY",
          "aerosol_nh4_max_monthly": "NH4+ (Ammonium) MAX MONTHLY",
          "aerosol_nh4_min_monthly": "NH4+ (Ammonium) MIN MONTHLY",
          // "aerosol_nh4_sum_monthly": "NH4+ (Ammonium) SUM MONTHLY",
          "aerosol_nh4_count_monthly": "NH4+ (Ammonium) COUNT MONTHLY",
          "aerosol_k_avg_monthly": "K+ (Kalium) AVG MONTHLY",
          // "aerosol_k_mod_monthly": "K+ (Kalium) MOD MONTHLY",
          // "aerosol_k_med_monthly": "K+ (Kalium) MED MONTHLY",
          "aerosol_k_max_monthly": "K+ (Kalium) MAX MONTHLY",
          "aerosol_k_min_monthly": "K+ (Kalium) MIN MONTHLY",
          // "aerosol_k_sum_monthly": "K+ (Kalium) SUM MONTHLY",
          "aerosol_k_count_monthly": "K+ (Kalium) COUNT MONTHLY",
          "aerosol_mg2_avg_monthly": "Mg2+ (Magnesium) AVG MONTHLY",
          // "aerosol_mg2_mod_monthly": "Mg2+ (Magnesium) MOD MONTHLY",
          // "aerosol_mg2_med_monthly": "Mg2+ (Magnesium) MED MONTHLY",
          "aerosol_mg2_max_monthly": "Mg2+ (Magnesium) MAX MONTHLY",
          "aerosol_mg2_min_monthly": "Mg2+ (Magnesium) MIN MONTHLY",
          // "aerosol_mg2_sum_monthly": "Mg2+ (Magnesium) SUM MONTHLY",
          "aerosol_mg2_count_monthly": "Mg2+ (Magnesium) COUNT MONTHLY",
          "aerosol_ca2_avg_monthly": "Ca2+ (Kalsium) AVG MONTHLY",
          // "aerosol_ca2_mod_monthly": "Ca2+ (Kalsium) MOD MONTHLY",
          // "aerosol_ca2_med_monthly": "Ca2+ (Kalsium) MED MONTHLY",
          "aerosol_ca2_max_monthly": "Ca2+ (Kalsium) MAX MONTHLY",
          "aerosol_ca2_min_monthly": "Ca2+ (Kalsium) MIN MONTHLY",
          // "aerosol_ca2_sum_monthly": "Ca2+ (Kalsium) SUM MONTHLY",
          "aerosol_ca2_count_monthly": "Ca2+ (Kalsium) COUNT MONTHLY"             
        },
      },
      
    ];

    return listAttrMonthly;
  },

  getAttrMonthlyDecimal() {
    const listAttrMonthly = [
      {
        label: "Aerosol",
        attr: {
          "aerosol_ph_avg_monthly":2,
          // "aerosol_ph_mod_monthly":"pH MOD MONTHLY",
          // "aerosol_ph_med_monthly":"pH MED MONTHLY",
          "aerosol_ph_max_monthly":2,
          "aerosol_ph_min_monthly":2,
          // "aerosol_ph_sum_monthly":"pH SUM MONTHLY",
          "aerosol_ph_count_monthly":2,
          "aerosol_conductivity_avg_monthly":1,
          // "aerosol_conductivity_mod_monthly":"Electro-Conductivity MOD MONTHLY",
          // "aerosol_conductivity_med_monthly":"Electro-Conductivity MED MONTHLY",
          "aerosol_conductivity_max_monthly":1,
          "aerosol_conductivity_min_monthly":1,
          // "aerosol_conductivity_sum_monthly":"Electro-Conductivity SUM MONTHLY",
          "aerosol_conductivity_count_monthly":1,
          "aerosol_konsentrasi_avg_monthly":0,
          // "aerosol_konsentrasi_mod_monthly":"Konsentrasi Aerosol/PM MOD MONTHLY",
          // "aerosol_konsentrasi_med_monthly":"Konsentrasi Aerosol/PM MED MONTHLY",
          "aerosol_konsentrasi_max_monthly":0,
          "aerosol_konsentrasi_min_monthly":0,
          // "aerosol_konsentrasi_sum_monthly":"Konsentrasi Aerosol/PM SUM MONTHLY",
          "aerosol_konsentrasi_count_monthly":0,
          // "aerosol_bobot_filter_kosong_avg_monthly":"Bobot Filter Kosong AVG MONTHLY",
          // "aerosol_bobot_filter_kosong_mod_monthly":"Bobot Filter Kosong MOD MONTHLY",
          // "aerosol_bobot_filter_kosong_med_monthly":"Bobot Filter Kosong MED MONTHLY",
          // "aerosol_bobot_filter_kosong_max_monthly":"Bobot Filter Kosong MAX MONTHLY",
          // "aerosol_bobot_filter_kosong_min_monthly":"Bobot Filter Kosong MIN MONTHLY",
          // "aerosol_bobot_filter_kosong_sum_monthly":"Bobot Filter Kosong SUM MONTHLY",
          // "aerosol_bobot_filter_kosong_count_monthly": "Bobot Filter Kosong COUNT MONTHLY",
          // "aerosol_bobot_filter_sampling_avg_monthly": "Bobot Filter Sampling AVG MONTHLY",
          // "aerosol_bobot_filter_sampling_mod_monthly": "Bobot Filter Sampling MOD MONTHLY",
          // "aerosol_bobot_filter_sampling_med_monthly": "Bobot Filter Sampling MED MONTHLY",
          // "aerosol_bobot_filter_sampling_max_monthly": "Bobot Filter Sampling MAX MONTHLY",
          // "aerosol_bobot_filter_sampling_min_monthly": "Bobot Filter Sampling MIN MONTHLY",
          // "aerosol_bobot_filter_sampling_sum_monthly": "Bobot Filter Sampling SUM MONTHLY",
          // "aerosol_bobot_filter_sampling_count_monthly": "Bobot Filter Sampling COUNT MONTHLY",
          // "aerosol_flow_rate_awal_avg_monthly": "Flow Rate Awal AVG MONTHLY",
          // "aerosol_flow_rate_awal_mod_monthly": "Flow Rate Awal MOD MONTHLY",
          // "aerosol_flow_rate_awal_med_monthly": "Flow Rate Awal MED MONTHLY",
          // "aerosol_flow_rate_awal_max_monthly": "Flow Rate Awal MAX MONTHLY",
          // "aerosol_flow_rate_awal_min_monthly": "Flow Rate Awal MIN MONTHLY",
          // "aerosol_flow_rate_awal_sum_monthly": "Flow Rate Awal SUM MONTHLY",
          // "aerosol_flow_rate_awal_count_monthly": "Flow Rate Awal COUNT MONTHLY",
          // "aerosol_flow_rate_akhir_avg_monthly": "Flow Rate Akhir AVG MONTHLY",
          // "aerosol_flow_rate_akhir_mod_monthly": "Flow Rate Akhir MOD MONTHLY",
          // "aerosol_flow_rate_akhir_med_monthly": "Flow Rate Akhir MED MONTHLY",
          // "aerosol_flow_rate_akhir_max_monthly": "Flow Rate Akhir MAX MONTHLY",
          // "aerosol_flow_rate_akhir_min_monthly": "Flow Rate Akhir MIN MONTHLY",
          // "aerosol_flow_rate_akhir_sum_monthly": "Flow Rate Akhir SUM MONTHLY",
          // "aerosol_flow_rate_akhir_count_monthly": "Flow Rate Akhir COUNT MONTHLY",
          // "aerosol_total_waktu_sampling_avg_monthly": "Total Waktu Sampling (menit) AVG MONTHLY",
          // "aerosol_total_waktu_sampling_mod_monthly": "Total Waktu Sampling (menit) MOD MONTHLY",
          // "aerosol_total_waktu_sampling_med_monthly": "Total Waktu Sampling (menit) MED MONTHLY",
          // "aerosol_total_waktu_sampling_max_monthly": "Total Waktu Sampling (menit) MAX MONTHLY",
          // "aerosol_total_waktu_sampling_min_monthly": "Total Waktu Sampling (menit) MIN MONTHLY",
          // "aerosol_total_waktu_sampling_sum_monthly": "Total Waktu Sampling (menit) SUM MONTHLY",
          // "aerosol_total_waktu_sampling_count_monthly": "Total Waktu Sampling (menit) COUNT MONTHLY",
          // "aerosol_tekanan_udara_awal_avg_monthly": "Tekanan Udara Awal AVG MONTHLY",
          // "aerosol_tekanan_udara_awal_mod_monthly": "Tekanan Udara Awal MOD MONTHLY",
          // "aerosol_tekanan_udara_awal_med_monthly": "Tekanan Udara Awal MED MONTHLY",
          // "aerosol_tekanan_udara_awal_max_monthly": "Tekanan Udara Awal MAX MONTHLY",
          // "aerosol_tekanan_udara_awal_min_monthly": "Tekanan Udara Awal MIN MONTHLY",
          // "aerosol_tekanan_udara_awal_sum_monthly": "Tekanan Udara Awal SUM MONTHLY",
          // "aerosol_tekanan_udara_awal_count_monthly": "Tekanan Udara Awal COUNT MONTHLY",
          // "aerosol_tekanan_udara_akhir_avg_monthly": "Tekanan Udara Akhir AVG MONTHLY",
          // "aerosol_tekanan_udara_akhir_mod_monthly": "Tekanan Udara Akhir MOD MONTHLY",
          // "aerosol_tekanan_udara_akhir_med_monthly": "Tekanan Udara Akhir MED MONTHLY",
          // "aerosol_tekanan_udara_akhir_max_monthly": "Tekanan Udara Akhir MAX MONTHLY",
          // "aerosol_tekanan_udara_akhir_min_monthly": "Tekanan Udara Akhir MIN MONTHLY",
          // "aerosol_tekanan_udara_akhir_sum_monthly": "Tekanan Udara Akhir SUM MONTHLY",
          // "aerosol_tekanan_udara_akhir_count_monthly": "Tekanan Udara Akhir COUNT MONTHLY",
          // "aerosol_temperatur_awal_avg_monthly": "Temperature Awal AVG MONTHLY",
          // "aerosol_temperatur_awal_mod_monthly": "Temperature Awal MOD MONTHLY",
          // "aerosol_temperatur_awal_med_monthly": "Temperature Awal MED MONTHLY",
          // "aerosol_temperatur_awal_max_monthly": "Temperature Awal MAX MONTHLY",
          // "aerosol_temperatur_awal_min_monthly": "Temperature Awal MIN MONTHLY",
          // "aerosol_temperatur_awal_sum_monthly": "Temperature Awal SUM MONTHLY",
          // "aerosol_temperatur_awal_count_monthly": "Temperature Awal COUNT MONTHLY",
          // "aerosol_temperatur_akhir_avg_monthly": "Temperature Akhir AVG MONTHLY",
          // "aerosol_temperatur_akhir_mod_monthly": "Temperature Akhir MOD MONTHLY",
          // "aerosol_temperatur_akhir_med_monthly": "Temperature Akhir MED MONTHLY",
          // "aerosol_temperatur_akhir_max_monthly": "Temperature Akhir MAX MONTHLY",
          // "aerosol_temperatur_akhir_min_monthly": "Temperature Akhir MIN MONTHLY",
          // "aerosol_temperatur_akhir_sum_monthly": "Temperature Akhir SUM MONTHLY",
          // "aerosol_temperatur_akhir_count_monthly": "Temperature Akhir COUNT MONTHLY",
          "aerosol_cl_avg_monthly": 3,
          // "aerosol_cl_mod_monthly": "Cl- (Klorida) MOD MONTHLY",
          // "aerosol_cl_med_monthly": "Cl- (Klorida) MED MONTHLY",
          "aerosol_cl_max_monthly": 3,
          "aerosol_cl_min_monthly": 3,
          // "aerosol_cl_sum_monthly": "Cl- (Klorida) SUM MONTHLY",
          "aerosol_cl_count_monthly": 3,
          "aerosol_so42_avg_monthly": 3,
          // "aerosol_so42_mod_monthly": "SO42- (Sulfat) MOD MONTHLY",
          // "aerosol_so42_med_monthly": "SO42- (Sulfat) MED MONTHLY",
          "aerosol_so42_max_monthly": 3,
          "aerosol_so42_min_monthly": 3,
          // "aerosol_so42_sum_monthly": "SO42- (Sulfat) SUM MONTHLY",
          "aerosol_so42_count_monthly": 3,
          "aerosol_no3_avg_monthly": 3,
          // "aerosol_no3_mod_monthly": "NO3- (Nitrat) MOD MONTHLY",
          // "aerosol_no3_med_monthly": "NO3- (Nitrat) MED MONTHLY",
          "aerosol_no3_max_monthly": 3,
          "aerosol_no3_min_monthly": 3,
          // "aerosol_no3_sum_monthly": "NO3- (Nitrat) SUM MONTHLY",
          "aerosol_no3_count_monthly": 3,
          "aerosol_na_avg_monthly": 3,
          // "aerosol_na_mod_monthly": "Na+ (Natrium) MOD MONTHLY",
          // "aerosol_na_med_monthly": "Na+ (Natrium) MED MONTHLY",
          "aerosol_na_max_monthly": 3,
          "aerosol_na_min_monthly": 3,
          // "aerosol_na_sum_monthly": "Na+ (Natrium) SUM MONTHLY",
          "aerosol_na_count_monthly": 3,
          "aerosol_nh4_avg_monthly": 3,
          // "aerosol_nh4_mod_monthly": "NH4+ (Ammonium) MOD MONTHLY",
          // "aerosol_nh4_med_monthly": "NH4+ (Ammonium) MED MONTHLY",
          "aerosol_nh4_max_monthly": 3,
          "aerosol_nh4_min_monthly": 3,
          // "aerosol_nh4_sum_monthly": "NH4+ (Ammonium) SUM MONTHLY",
          "aerosol_nh4_count_monthly": 3,
          "aerosol_k_avg_monthly": 3,
          // "aerosol_k_mod_monthly": "K+ (Kalium) MOD MONTHLY",
          // "aerosol_k_med_monthly": "K+ (Kalium) MED MONTHLY",
          "aerosol_k_max_monthly": 3,
          "aerosol_k_min_monthly": 3,
          // "aerosol_k_sum_monthly": "K+ (Kalium) SUM MONTHLY",
          "aerosol_k_count_monthly": 3,
          "aerosol_mg2_avg_monthly": 3,
          // "aerosol_mg2_mod_monthly": "Mg2+ (Magnesium) MOD MONTHLY",
          // "aerosol_mg2_med_monthly": "Mg2+ (Magnesium) MED MONTHLY",
          "aerosol_mg2_max_monthly": 3,
          "aerosol_mg2_min_monthly": 3,
          // "aerosol_mg2_sum_monthly": "Mg2+ (Magnesium) SUM MONTHLY",
          "aerosol_mg2_count_monthly": 3,
          "aerosol_ca2_avg_monthly": 3,
          // "aerosol_ca2_mod_monthly": "Ca2+ (Kalsium) MOD MONTHLY",
          // "aerosol_ca2_med_monthly": "Ca2+ (Kalsium) MED MONTHLY",
          "aerosol_ca2_max_monthly": 3,
          "aerosol_ca2_min_monthly": 3,
          // "aerosol_ca2_sum_monthly": "Ca2+ (Kalsium) SUM MONTHLY",
          "aerosol_ca2_count_monthly": 3             
        },
      },
      
    ];

    return listAttrMonthly;
  },

  getAttrMonthlyCsv() {
    const listAttrMonthly = [
      {
        label: "Aerosol",
        attr: {
          "aerosol_ph_avg_monthly":"aerosol_pH_monthly_ave",
          "aerosol_ph_mod_monthly":"aerosol_pH_monthly_mod",
          "aerosol_ph_med_monthly":"aerosol_pH_monthly_med",
          "aerosol_ph_max_monthly":"aerosol_pH_monthly_max",
          "aerosol_ph_min_monthly":"aerosol_pH_monthly_min",
          "aerosol_ph_sum_monthly":"aerosol_pH_monthly_sum",
          "aerosol_ph_count_monthly":"count_aerosol_pH_monthly",
          "aerosol_conductivity_avg_monthly":"aerosol_Electro_Conductivity_monthly_ave",
          "aerosol_conductivity_mod_monthly":"aerosol_Electro_Conductivity_monthly_mod",
          "aerosol_conductivity_med_ monthly":"aerosol_Electro_Conductivity_monthly_med",
          "aerosol_conductivity_max_monthly":"aerosol_Electro_Conductivity_monthly_max",
          "aerosol_conductivity_min_monthly":"aerosol_Electro_Conductivity_monthly_min",
          "aerosol_conductivity_sum_monthly":"aerosol_Electro_Conductivity_monthly_sum",
          "aerosol_conductivity_count_monthly":"count_aerosol_Electro_Conductivity_monthly",
          "aerosol_konsentrasi_avg_monthly":"konsentrasi_aerosol_monthly_ave",
          "aerosol_konsentrasi_mod_monthly":"konsentrasi_aerosol_monthly_mod",
          "aerosol_konsentrasi_med_monthly":"konsentrasi_aerosol_monthly_med",
          "aerosol_konsentrasi_max_monthly":"konsentrasi_aerosol_monthly_max",
          "aerosol_konsentrasi_min_monthly":"konsentrasi_aerosol_monthly_min",
          "aerosol_konsentrasi_sum_monthly":"konsentrasi_aerosol_monthly_sum",
          "aerosol_konsentrasi_count_monthly":"count_konsentrasi_aerosol_monthly",
          "aerosol_bobot_filter_kosong_avg_monthly":"aerosol_Bobot_Filter_Kosong_monthly_ave",
          "aerosol_bobot_filter_kosong_mod_monthly":"aerosol_Bobot_Filter_Kosong_monthly_mod",
          "aerosol_bobot_filter_kosong_med_monthly":"aerosol_Bobot_Filter_Kosong_monthly_med",
          "aerosol_bobot_filter_kosong_max_monthly":"aerosol_Bobot_Filter_Kosong_monthly_max",
          "aerosol_bobot_filter_kosong_min_monthly":"aerosol_Bobot_Filter_Kosong_monthly_min",
          "aerosol_bobot_filter_kosong_sum_monthly":"aerosol_Bobot_Filter_Kosong_monthly_sum",
          "aerosol_bobot_filter_kosong_count_monthly": "count_aerosol_Bobot_Filter_Kosong_monthly",
          "aerosol_bobot_filter_sampling_avg_monthly": "aerosol_Bobot_Filter_Sampling_monthly_ave",
          "aerosol_bobot_filter_sampling_mod_monthly": "aerosol_Bobot_Filter_Sampling_monthly_mod",
          "aerosol_bobot_filter_sampling_med_monthly": "aerosol_Bobot_Filter_Sampling_monthly_med",
          "aerosol_bobot_filter_sampling_max_monthly": "aerosol_Bobot_Filter_Sampling_monthly_max",
          "aerosol_bobot_filter_sampling_min_monthly": "aerosol_Bobot_Filter_Sampling_monthly_min",
          "aerosol_bobot_filter_sampling_sum_monthly": "aerosol_Bobot_Filter_Sampling_monthly_sum",
          "aerosol_bobot_filter_sampling_count_monthly": "count_aerosol_Bobot_Filter_Sampling_monthly",
          "aerosol_flow_rate_awal_avg_monthly": "aerosol_Flow_Rate_Awal_monthly_ave",
          "aerosol_flow_rate_awal_mod_monthly": "aerosol_Flow_Rate_Awal_monthly_mod",
          "aerosol_flow_rate_awal_med_monthly": "aerosol_Flow_Rate_Awal_monthly_med",
          "aerosol_flow_rate_awal_max_monthly": "aerosol_Flow_Rate_Awal_monthly_max",
          "aerosol_flow_rate_awal_min_monthly": "aerosol_Flow_Rate_Awal_monthly_min",
          "aerosol_flow_rate_awal_sum_monthly": "aerosol_Flow_Rate_Awal_monthly_sum",
          "aerosol_flow_rate_awal_count_monthly": "count_aerosol_Flow_Rate_Awal_monthly",
          "aerosol_flow_rate_akhir_avg_monthly": "aerosol_Flow_Rate_Akhir_monthly_ave",
          "aerosol_flow_rate_akhir_mod_monthly": "aerosol_Flow_Rate_Akhir_monthly_mod",
          "aerosol_flow_rate_akhir_med_monthly": "aerosol_Flow_Rate_Akhir_monthly_med",
          "aerosol_flow_rate_akhir_max_monthly": "aerosol_Flow_Rate_Akhir_monthly_max",
          "aerosol_flow_rate_akhir_min_monthly": "aerosol_Flow_Rate_Akhir_monthly_min",
          "aerosol_flow_rate_akhir_sum_monthly": "aerosol_Flow_Rate_Akhir_monthly_sum",
          "aerosol_flow_rate_akhir_count_monthly": "count_aerosol_Flow_Rate_Akhir_monthly",
          "aerosol_total_waktu_sampling_avg_monthly": "aerosol_Total_Waktu_Sampling_monthly_ave",
          "aerosol_total_waktu_sampling_mod_monthly": "aerosol_Total_Waktu_Sampling_monthly_mod",
          "aerosol_total_waktu_sampling_med_monthly": "aerosol_Total_Waktu_Sampling_monthly_med",
          "aerosol_total_waktu_sampling_max_monthly": "aerosol_Total_Waktu_Sampling_monthly_max",
          "aerosol_total_waktu_sampling_min_monthly": "aerosol_Total_Waktu_Sampling_monthly_min",
          "aerosol_total_waktu_sampling_sum_monthly": "aerosol_Total_Waktu_Sampling_monthly_sum",
          "aerosol_total_waktu_sampling_count_monthly": "count_aerosol_Total_Waktu_Sampling_monthly",
          "aerosol_tekanan_udara_awal_avg_monthly": "aerosol_Tekanan_Udara_Awal_monthly_ave",
          "aerosol_tekanan_udara_awal_mod_monthly": "aerosol_Tekanan_Udara_Awal_monthly_mod",
          "aerosol_tekanan_udara_awal_med_monthly": "aerosol_Tekanan_Udara_Awal_monthly_med",
          "aerosol_tekanan_udara_awal_max_monthly": "aerosol_Tekanan_Udara_Awal_monthly_max",
          "aerosol_tekanan_udara_awal_min_monthly": "aerosol_Tekanan_Udara_Awal_monthly_min",
          "aerosol_tekanan_udara_awal_sum_monthly": "aerosol_Tekanan_Udara_Awal_monthly_sum",
          "aerosol_tekanan_udara_awal_count_monthly": "count_aerosol_Tekanan_Udara_Awal_monthly",
          "aerosol_tekanan_udara_akhir_avg_monthly": "aerosol_Tekanan_Udara_Akhir_monthly_ave",
          "aerosol_tekanan_udara_akhir_mod_monthly": "aerosol_Tekanan_Udara_Akhir_monthly_mod",
          "aerosol_tekanan_udara_akhir_med_monthly": "aerosol_Tekanan_Udara_Akhir_monthly_med",
          "aerosol_tekanan_udara_akhir_max_monthly": "aerosol_Tekanan_Udara_Akhir_monthly_max",
          "aerosol_tekanan_udara_akhir_min_monthly": "aerosol_Tekanan_Udara_Akhir_monthly_min",
          "aerosol_tekanan_udara_akhir_sum_monthly": "aerosol_Tekanan_Udara_Akhir_monthly_sum",
          "aerosol_tekanan_udara_akhir_count_monthly": "count_aerosol_Tekanan_Udara_Akhir_monthly",
          "aerosol_temperatur_awal_avg_monthly": "aerosol_Temperatur_Awal_monthly_ave",
          "aerosol_temperatur_awal_mod_monthly": "aerosol_Temperatur_Awal_monthly_mod",
          "aerosol_temperatur_awal_med_monthly": "aerosol_Temperatur_Awal_monthly_med",
          "aerosol_temperatur_awal_max_monthly": "aerosol_Temperatur_Awal_monthly_max",
          "aerosol_temperatur_awal_min_monthly": "aerosol_Temperatur_Awal_monthly_min",
          "aerosol_temperatur_awal_sum_monthly": "aerosol_Temperatur_Awal_monthly_sum",
          "aerosol_temperatur_awal_count_monthly": "count_aerosol_Temperatur_Awal_monthly",
          "aerosol_temperatur_akhir_avg_monthly": "aerosol_Temperatur_Akhir_monthly_ave",
          "aerosol_temperatur_akhir_mod_monthly": "aerosol_Temperatur_Akhir_monthly_mod",
          "aerosol_temperatur_akhir_med_monthly": "aerosol_Temperatur_Akhir_monthly_med",
          "aerosol_temperatur_akhir_max_monthly": "aerosol_Temperatur_Akhir_monthly_max",
          "aerosol_temperatur_akhir_min_monthly": "aerosol_Temperatur_Akhir_monthly_min",
          "aerosol_temperatur_akhir_sum_monthly": "aerosol_Temperatur_Akhir_monthly_sum",
          "aerosol_temperatur_akhir_count_monthly": "count_aerosol_Temperatur_Akhir_monthly",
          "aerosol_cl_avg_monthly": "aerosol_Cl_monthly_ave",
          "aerosol_cl_mod_monthly": "aerosol_Cl_monthly_mod",
          "aerosol_cl_med_monthly": "aerosol_Cl_monthly_med",
          "aerosol_cl_max_monthly": "aerosol_Cl_monthly_max",
          "aerosol_cl_min_monthly": "aerosol_Cl_monthly_min",
          "aerosol_cl_sum_monthly": "aerosol_Cl_monthly_sum",
          "aerosol_cl_count_monthly": "count_aerosol_Cl_monthly",
          "aerosol_so42_avg_monthly": "aerosol_SO4_monthly_ave",
          "aerosol_so42_mod_monthly": "aerosol_SO4_monthly_mod",
          "aerosol_so42_med_monthly": "aerosol_SO4_monthly_med",
          "aerosol_so42_max_monthly": "aerosol_SO4_monthly_max",
          "aerosol_so42_min_monthly": "aerosol_SO4_monthly_min",
          "aerosol_so42_sum_monthly": "aerosol_SO4_monthly_sum",
          "aerosol_so42_count_monthly": "count_aerosol_SO4_monthly",
          "aerosol_no3_avg_monthly": "aerosol_NO3_monthly_ave",
          "aerosol_no3_mod_monthly": "aerosol_NO3_monthly_mod",
          "aerosol_no3_med_monthly": "aerosol_NO3_monthly_med",
          "aerosol_no3_max_monthly": "aerosol_NO3_monthly_max",
          "aerosol_no3_min_monthly": "aerosol_NO3_monthly_min",
          "aerosol_no3_sum_monthly": "aerosol_NO3_monthly_sum",
          "aerosol_no3_count_monthly": "count_aerosol_NO3_monthly",
          "aerosol_na_avg_monthly": "aerosol_Na_monthly_ave",
          "aerosol_na_mod_monthly": "aerosol_Na_monthly_mod",
          "aerosol_na_med_monthly": "aerosol_Na_monthly_med",
          "aerosol_na_max_monthly": "aerosol_Na_monthly_max",
          "aerosol_na_min_monthly": "aerosol_Na_monthly_min",
          "aerosol_na_sum_monthly": "aerosol_Na_monthly_sum",
          "aerosol_na_count_monthly": "count_aerosol_Na_monthly",
          "aerosol_nh4_avg_monthly": "aerosol_NH4_monthly_ave",
          "aerosol_nh4_mod_monthly": "aerosol_NH4_monthly_mod",
          "aerosol_nh4_med_monthly": "aerosol_NH4_monthly_med",
          "aerosol_nh4_max_monthly": "aerosol_NH4_monthly_max",
          "aerosol_nh4_min_monthly": "aerosol_NH4_monthly_min",
          "aerosol_nh4_sum_monthly": "aerosol_NH4_monthly_sum",
          "aerosol_nh4_count_monthly": "count_aerosol_NH4_monthly",
          "aerosol_k_avg_monthly": "aerosol_K_monthly_ave",
          "aerosol_k_mod_monthly": "aerosol_K_monthly_mod",
          "aerosol_k_med_monthly": "aerosol_K_monthly_med",
          "aerosol_k_max_monthly": "aerosol_K_monthly_max",
          "aerosol_k_min_monthly": "aerosol_K_monthly_min",
          "aerosol_k_sum_monthly": "aerosol_K_monthly_sum",
          "aerosol_k_count_monthly": "count_aerosol_K_monthly",
          "aerosol_mg2_avg_monthly": "aerosol_Mg_monthly_ave",
          "aerosol_mg2_mod_monthly": "aerosol_Mg_monthly_mod",
          "aerosol_mg2_med_monthly": "aerosol_Mg_monthly_med",
          "aerosol_mg2_max_monthly": "aerosol_Mg_monthly_max",
          "aerosol_mg2_min_monthly": "aerosol_Mg_monthly_min",
          "aerosol_mg2_sum_monthly": "aerosol_Mg_monthly_sum",
          "aerosol_mg2_count_monthly": "count_aerosol_Mg_monthly",
          "aerosol_ca2_avg_monthly": "aerosol_Ca_monthly_ave",
          "aerosol_ca2_mod_monthly": "aerosol_Ca_monthly_mod",
          "aerosol_ca2_med_monthly": "aerosol_Ca_monthly_med",
          "aerosol_ca2_max_monthly": "aerosol_Ca_monthly_max",
          "aerosol_ca2_min_monthly": "aerosol_Ca_monthly_min",
          "aerosol_ca2_sum_monthly": "aerosol_Ca_monthly_sum",
          "aerosol_ca2_count_monthly": "count_aerosol_Ca_monthly"             
        },
      },
      
    ];

    return listAttrMonthly;
  },
};
