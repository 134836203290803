import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
    getAttr() {
        const listAttr = [
            {
                label: "Radiasi",
                attr: {
                    "rad_gunbellani" : "Radiasi MTH (cal/cm2)",     
                },  
            },
        ]
        return listAttr;
    },

    getAttrMonthly() {
        const listAttr = [
            {
                group: "Radiasi",
                form: "Gunbellani",
                label: "Radiasi",
                attr: {
                    "rad_gunbellani_avg_monthly" : "Radiasi MTH (cal/cm2) AVG MONTHLY", 
                    // "rad_gunbellani_mod_monthly" : "Radiasi MTH (cal/cm2) MOD MONTHLY", 
                    // "rad_gunbellani_med_monthly" : "Radiasi MTH (cal/cm2) MED MONTHLY", 
                    "rad_gunbellani_max_monthly" : "Radiasi MTH (cal/cm2) MAX MONTHLY", 
                    "rad_gunbellani_min_monthly" : "Radiasi MTH (cal/cm2) MIN MONTHLY", 
                    // "rad_gunbellani_sum_monthly" : "Radiasi MTH (cal/cm2) SUM MONTHLY", 
                    "rad_gunbellani_count_monthly" : "Radiasi MTH (cal/cm2) COUNT MONTHLY",     
                },  
            },
        ]
        return listAttr;
    },

    getAttrMonthlyCsv() {
        const listAttr = [
            {
                label: "Radiasi",
                attr: {
                    "rad_gunbellani_avg_monthly" : "rad_gunbellani_monthly_avg_calcm-2", 
                    "rad_gunbellani_mod_monthly" : "rad_gunbellani_monthly_mod", 
                    "rad_gunbellani_med_monthly" : "rad_gunbellani_monthly_med", 
                    "rad_gunbellani_max_monthly" : "rad_gunbellani_monthly_max_calcm-2", 
                    "rad_gunbellani_min_monthly" : "rad_gunbellani_monthly_min_calcm-2", 
                    "rad_gunbellani_sum_monthly" : "rad_gunbellani_monthly_sum", 
                    "rad_gunbellani_count_monthly" : "c_rad_gunbellani_monthly",     
                },  
            },
        ]
        return listAttr;
    },

    getAttrMonthlyDecimal() {
        const listAttr = [
            {
                label: "Radiasi",
                attr: {
                    "rad_gunbellani_avg_monthly" : 2, 
                    "rad_gunbellani_mod_monthly" : 2, 
                    "rad_gunbellani_med_monthly" : 2, 
                    "rad_gunbellani_max_monthly" : 2, 
                    "rad_gunbellani_min_monthly" : 2, 
                    "rad_gunbellani_sum_monthly" : 2, 
                    "rad_gunbellani_count_monthly" : 2,     
                },  
            },
        ]
        return listAttr;
    },
    
}
