import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "Fklim 71",
        attr: {
          "tbk_avg" : "suhu bola kering rata-rata",
          "rh_avg" : "lembab udara rata-rata",
          "ff_avg_km_jam" : "Kecepatan rata-rata (km/jam)",
          "wd_cardinal" : "arah angin terbanyak",
          "ff_max" : "kecepatan angin maksimum",
          "wd_ff_max" : "arah saat kecepatan angin maksimum",
          "t_max_1c2m" : "suhu maksimum ketinggian 1.2m",
          "t_min_1300" : "suhu minimum jam 13.00",
          "rr_0700" : "curah hujan jam 07.00",
          "ss_8" : "lama penyinaran 8 jam",
          "pp_qfe_0000" : "tekanan udara",

        },
      },
    ];
    return listAttr;
  },

  getAttrMonthly() {
    const listAttr = [
      {
        group: "Temperatur",
        form: "Fklim",
        label: "Temperatur",
        attr: {
          "tbk_avg_avg_monthly" : "Suhu Bola Kering Rata-rata AVG MONTHLY",
          // "tbk_avg_mod_monthly" : "Suhu Bola Kering Rata-rata MOD MONTHLY",
          // "tbk_avg_med_monthly" : "Suhu Bola Kering Rata-rata MED MONTHLY",
          "tbk_avg_max_monthly" : "Suhu Bola Kering Rata-rata MAX MONTHLY",
          "tbk_avg_min_monthly" : "Suhu Bola Kering Rata-rata MIN MONTHLY",
          // "tbk_avg_sum_monthly" : "Suhu Bola Kering Rata-rata SUM MONTHLY",
          "tbk_avg_count_monthly" : "Suhu Bola Kering Rata-rata COUNT MONTHLY",
          // "rh_avg_avg_monthly" : "Lembab Udara Rata-rata AVG MONTHLY",
          // "rh_avg_mod_monthly" : "Lembab Udara Rata-rata MOD MONTHLY",
          // "rh_avg_med_monthly" : "Lembab Udara Rata-rata MED MONTHLY",
          // "rh_avg_max_monthly" : "Lembab Udara Rata-rata MAX MONTHLY",
          // "rh_avg_min_monthly" : "Lembab Udara Rata-rata MIN MONTHLY",
          // "rh_avg_sum_monthly" : "Lembab Udara Rata-rata SUM MONTHLY",
          // "rh_avg_count_monthly" : "Lembab Udara Rata-rata COUNT MONTHLY",
          "t_max_1c2m_avg_monthly" : "Suhu Maksimum Ketinggian 1.2m diamati jam 18.00 WS AVG MONTHLY",
          // "t_max_1c2m_mod_monthly" : "Suhu Maksimum Ketinggian 1.2m MOD MONTHLY",
          // "t_max_1c2m_med_monthly" : "Suhu Maksimum Ketinggian 1.2m MED MONTHLY",
          "t_max_1c2m_max_monthly" : "Suhu Maksimum Ketinggian 1.2m diamati jam 18.00 WS MAX MONTHLY",
          "t_max_1c2m_min_monthly" : "Suhu Maksimum Ketinggian 1.2m diamati jam 18.00 WS MIN MONTHLY",
          "t_max_1c2m_sum_monthly" : "Suhu Maksimum Ketinggian 1.2m diamati jam 18.00 WS SUM MONTHLY",
          "t_max_1c2m_count_monthly" : "Suhu Maksimum Ketinggian 1.2m diamati jam 18.00 WS COUNT MONTHLY",
        },
      },
      {
        group: "Angin",
        form: "Fklim",
        label: "Angin",
        attr: {
          "ff_avg_km_jam_avg_monthly" : "Kecepatan rata-rata (km/jam) AVG MONTHLY",
          // "ff_avg_km_jam_mod_monthly" : "Kecepatan rata-rata (km/jam) MOD MONTHLY",
          // "ff_avg_km_jam_med_monthly" : "Kecepatan rata-rata (km/jam) MED MONTHLY",
          // "ff_avg_km_jam_max_monthly" : "Kecepatan rata-rata (km/jam) MAX MONTHLY",
          // "ff_avg_km_jam_min_monthly" : "Kecepatan rata-rata (km/jam) MIN MONTHLY",
          // "ff_avg_km_jam_sum_monthly" : "Kecepatan rata-rata (km/jam) SUM MONTHLY",
          "ff_avg_km_jam_count_monthly" : "Kecepatan rata-rata (km/jam) COUNT MONTHLY",
          // "wd_cardinal" : "arah angin terbanyak", //belum ada di API
          // "ff_max_avg_monthly" : "Kecepatan Angin Maksimum AVG MONTHLY",
          // "ff_max_mod_monthly" : "Kecepatan Angin Maksimum MOD MONTHLY",
          // "ff_max_med_monthly" : "Kecepatan Angin Maksimum MED MONTHLY",
          "ff_max_max_monthly" : "Kecepatan Angin Maksimum MAX MONTHLY",
          // "ff_max_min_monthly" : "Kecepatan Angin Maksimum MIN MONTHLY",
          // "ff_max_sum_monthly" : "Kecepatan Angin Maksimum SUM MONTHLY",
          // "ff_max_count_monthly" : "Kecepatan Angin Maksimum COUNT MONTHLY"
        },
      },
      {
        group: "Kelembaban",
        form: "Fklim",
        label: "Kelembaban",
        attr: {
          "rh_avg_avg_monthly" : "Lembab Udara Rata-rata AVG MONTHLY",
          // "rh_avg_mod_monthly" : "Lembab Udara Rata-rata MOD MONTHLY",
          // "rh_avg_med_monthly" : "Lembab Udara Rata-rata MED MONTHLY",
          "rh_avg_max_monthly" : "Lembab Udara Rata-rata MAX MONTHLY",
          "rh_avg_min_monthly" : "Lembab Udara Rata-rata MIN MONTHLY",
          // "rh_avg_sum_monthly" : "Lembab Udara Rata-rata SUM MONTHLY",
          "rh_avg_count_monthly" : "Lembab Udara Rata-rata COUNT MONTHLY",
        },
      },
      {
        group: "Hujan",
        form: "Fklim",
        label: "Curah Hujan",
        attr: {
          // "rr_0700_avg_monthly" : "Curah Hujan Jam 07.00 AVG MONTHLY",
          // "rr_0700_mod_monthly" : "Curah Hujan Jam 07.00 MOD MONTHLY",
          // "rr_0700_med_monthly" : "Curah Hujan Jam 07.00 MED MONTHLY",
          "rr_0700_max_monthly" : "Curah Hujan Jam 07.00 MAX MONTHLY",
          // "rr_0700_min_monthly" : "Curah Hujan Jam 07.00 MIN MONTHLY",
          "rr_0700_sum_monthly" : "Curah Hujan Jam 07.00 SUM MONTHLY",
          "rr_0700_count_monthly" : "Curah Hujan Jam 07.00 COUNT MONTHLY"
        },
      },
      {
        group: "Radiasi",
        form: "Fklim",
        label: "Radiasi",
        attr: {
          "ss_8_avg_monthly" : "Lama Penyinaran 8 jam AVG MONTHLY",
          "ss_8_mod_monthly" : "Lama Penyinaran 8 jam MOD MONTHLY",
          "ss_8_med_monthly" : "Lama Penyinaran 8 jam MED MONTHLY",
          "ss_8_max_monthly" : "Lama Penyinaran 8 jam MAX MONTHLY",
          "ss_8_min_monthly" : "Lama Penyinaran 8 jam MIN MONTHLY",
          "ss_8_sum_monthly" : "Lama Penyinaran 8 jam SUM MONTHLY",
          "ss_8_count_monthly" : "Lama Penyinaran 8 jam COUNT MONTHLY"
        },
      },
      {
        group: "Tekanan",
        form: "Fklim",
        label: "Tekanan Udara",
        attr: {
          "pp_qfe_0000_avg_monthly" : "Tekanan Udara AVG MONTHLY",
          "pp_qfe_0000_mod_monthly" : "Tekanan Udara MOD MONTHLY",
          "pp_qfe_0000_med_monthly" : "Tekanan Udara MED MONTHLY",
          "pp_qfe_0000_max_monthly" : "Tekanan Udara MAX MONTHLY",
          "pp_qfe_0000_min_monthly" : "Tekanan Udara MIN MONTHLY",
          "pp_qfe_0000_sum_monthly" : "Tekanan Udara SUM MONTHLY",
          "pp_qfe_0000_count_monthly" : "Tekanan Udara COUNT MONTHLY",
        },
      },
    ];
    return listAttr;
  },

  getAttrMonthlyCsv() {
    const listAttr = [
      {
        label: "Fklim 71",
        attr: {
          "tbk_avg_avg_monthly" : "tbk_avg_monthly_fklim_avg_C",
          "tbk_avg_mod_monthly" : "tbk_avg_monthly_fklim_mod",
          "tbk_avg_med_monthly" : "tbk_avg_monthly_fklim_med",
          "tbk_avg_max_monthly" : "tbk_avg_monthly_fklim_max_C",
          "tbk_avg_min_monthly" : "tbk_avg_monthly_fklim_min_C",
          "tbk_avg_sum_monthly" : "tbk_avg_monthly_sum",
          "tbk_avg_count_monthly" : "c_ff_avg_km_jam_monthly_fklim",
          "rh_avg_avg_monthly" : "rh_avg_monthly_fklim_avg_PC",
          "rh_avg_mod_monthly" : "rh_avg_monthly_mod",
          "rh_avg_med_monthly" : "rh_avg_monthly_med",
          "rh_avg_max_monthly" : "rh_avg_monthly_max_PC",
          "rh_avg_min_monthly" : "rh_avg_monthly_min_PC",
          "rh_avg_sum_monthly" : "rh_avg_monthly_sum",
          "rh_avg_count_monthly" : "c_wd_cardinal_monthly_fklim",
          "ff_avg_km_jam_avg_monthly" : "ff_avg_km_jam_monthly_avg",
          "ff_avg_km_jam_mod_monthly" : "ff_avg_km_jam_monthly_mod",
          "ff_avg_km_jam_med_monthly" : "ff_avg_km_jam_monthly_med",
          "ff_avg_km_jam_max_monthly" : "ff_avg_km_jam_monthly_max",
          "ff_avg_km_jam_min_monthly" : "ff_avg_km_jam_monthly_min",
          "ff_avg_km_jam_sum_monthly" : "ff_avg_km_jam_monthly_sum",
          "ff_avg_km_jam_count_monthly" : "count_ff_avg_km_jam_monthly",
          // "wd_cardinal" : "arah angin terbanyak", //belum ada di API
          "ff_max_avg_monthly" : "ff_max_monthly_avg_knot",
          "ff_max_mod_monthly" : "ff_max_monthly_mod_knot",
          "ff_max_med_monthly" : "ff_max_monthly_med_knot",
          "ff_max_max_monthly" : "ff_max_monthly_max_knot",
          "ff_max_min_monthly" : "ff_max_monthly_min_knot",
          "ff_max_sum_monthly" : "ff_max_monthly_sum_knot",
          "ff_max_count_monthly" : "count_ff_max_monthly_knot",
          // "wd_ff_max" : "arah saat kecepatan angin maksimum", // belum ada di API
          "t_max_1c2m_avg_monthly" : "t_max_1c2m_monthly_avg",
          "t_max_1c2m_mod_monthly" : "t_max_1c2m_monthly_mod",
          "t_max_1c2m_med_monthly" : "t_max_1c2m_monthly_med",
          "t_max_1c2m_max_monthly" : "t_max_1c2m_monthly_max",
          "t_max_1c2m_min_monthly" : "t_max_1c2m_monthly_min",
          "t_max_1c2m_sum_monthly" : "t_max_1c2m_monthly_sum",
          "t_max_1c2m_count_monthly" : "count_t_max_1c2m_monthly",
          // "t_min_1300" : "suhu minimum jam 13.00", // belum ada di API
          "rr_0700_avg_monthly" : "rr_0700_monthly_avg_mb",
          "rr_0700_mod_monthly" : "rr_0700_monthly_mod_mb",
          "rr_0700_med_monthly" : "rr_0700_monthly_med_mb",
          "rr_0700_max_monthly" : "rr_0700_monthly_max_mb",
          "rr_0700_min_monthly" : "rr_0700_monthly_min_mb",
          "rr_0700_sum_monthly" : "rr_0700_monthly_sum_mb",
          "rr_0700_count_monthly" : "count_rr_0700_monthly_mb",
          "ss_8_avg_monthly" : "ss_8_monthly_avg",
          "ss_8_mod_monthly" : "ss_8_monthly_mod",
          "ss_8_med_monthly" : "ss_8_monthly_med",
          "ss_8_max_monthly" : "ss_8_monthly_max",
          "ss_8_min_monthly" : "ss_8_monthly_min",
          "ss_8_sum_monthly" : "ss_8_monthly_sum",
          "ss_8_count_monthly" : "count_ss_8_monthly",
          "pp_qfe_0000_avg_monthly" : "pp_qfe_0000_monthly_avg",
          "pp_qfe_0000_mod_monthly" : "pp_qfe_0000_monthly_mod",
          "pp_qfe_0000_med_monthly" : "pp_qfe_0000_monthly_med",
          "pp_qfe_0000_max_monthly" : "pp_qfe_0000_monthly_max",
          "pp_qfe_0000_min_monthly" : "pp_qfe_0000_monthly_min",
          "pp_qfe_0000_sum_monthly" : "pp_qfe_0000_monthly_sum",
          "pp_qfe_0000_count_monthly" : "count_pp_qfe_0000_monthly",
        },
      },
    ];
    return listAttr;
  },

  getAttrMonthlyDecimal() {
    const listAttr = [
      {
        label: "Fklim 71",
        attr: {
          "tbk_avg_avg_monthly" : 1,
          "tbk_avg_mod_monthly" : 1,
          "tbk_avg_med_monthly" : 1,
          "tbk_avg_max_monthly" : 1,
          "tbk_avg_min_monthly" : 1,
          "tbk_avg_sum_monthly" : 1,
          "tbk_avg_count_monthly" : 1,
          "rh_avg_avg_monthly" : 1,
          "rh_avg_mod_monthly" : 1,
          "rh_avg_med_monthly" : 1,
          "rh_avg_max_monthly" : 1,
          "rh_avg_min_monthly" : 1,
          "rh_avg_sum_monthly" : 1,
          "rh_avg_count_monthly" : 1,
          "ff_avg_km_jam_avg_monthly" : 1,
          "ff_avg_km_jam_mod_monthly" : 1,
          "ff_avg_km_jam_med_monthly" : 1,
          "ff_avg_km_jam_max_monthly" : 1,
          "ff_avg_km_jam_min_monthly" : 1,
          "ff_avg_km_jam_sum_monthly" : 1,
          "ff_avg_km_jam_count_monthly" : 1,
          // "wd_cardinal" : "arah angin terbanyak", //belum ada di API
          "ff_max_avg_monthly" : 1,
          "ff_max_mod_monthly" : 1,
          "ff_max_med_monthly" : 1,
          "ff_max_max_monthly" : 1,
          "ff_max_min_monthly" : 1,
          "ff_max_sum_monthly" : 1,
          "ff_max_count_monthly" : 1,
          // "wd_ff_max" : "arah saat kecepatan angin maksimum", // belum ada di API
          "t_max_1c2m_avg_monthly" : 1,
          "t_max_1c2m_mod_monthly" : 1,
          "t_max_1c2m_med_monthly" : 1,
          "t_max_1c2m_max_monthly" : 1,
          "t_max_1c2m_min_monthly" : 1,
          "t_max_1c2m_sum_monthly" : 1,
          "t_max_1c2m_count_monthly" : 1,
          // "t_min_1300" : "suhu minimum jam 13.00", // belum ada di API
          "rr_0700_avg_monthly" : 1,
          "rr_0700_mod_monthly" : 1,
          "rr_0700_med_monthly" : 1,
          "rr_0700_max_monthly" : 1,
          "rr_0700_min_monthly" : 1,
          "rr_0700_sum_monthly" : 1,
          "rr_0700_count_monthly" : 1,
          "ss_8_avg_monthly" : 1,
          "ss_8_mod_monthly" : 1,
          "ss_8_med_monthly" : 1,
          "ss_8_max_monthly" : 1,
          "ss_8_min_monthly" : 1,
          "ss_8_sum_monthly" : 1,
          "ss_8_count_monthly" : 1,
          "pp_qfe_0000_avg_monthly" : 1,
          "pp_qfe_0000_mod_monthly" : 1,
          "pp_qfe_0000_med_monthly" : 1,
          "pp_qfe_0000_max_monthly" : 1,
          "pp_qfe_0000_min_monthly" : 1,
          "pp_qfe_0000_sum_monthly" : 1,
          "pp_qfe_0000_count_monthly" : 1,
        },
      },
    ];
    return listAttr;
  },

  getAttrDaily() {
    const listAttr = [
      {
        label: "Temperatur",
        attr: {
          "tbk_avg" : "Suhu Bola Kering Rata-rata DAILY",
          "m_1800ws[t_max_1c2m]" : "Suhu Maksimum Ketinggian 1.2m  DAILY",
          "m_1300ws[t_min_1c2m]" : "Suhu Minimum Jam 13.00 DAILY"
        },
      },
      {
        label: "Angin",
        attr: {
          "ff_avg_km_jm" : "Kecepatan Rata-rata (km/jam) DAILY",
          "wd_cardinal" : "Arah angin terbanyak DAILY",
          "ff_max" : "Kecepatan angin maksimum DAILY",
          "wd_ff_max" : "Arah saat kecepatan angin maksimum DAILY",
        }
      },
      {
        label: "Kelembaban",
        attr: {
          "rh_avg" : "Lembar Udara Rata-rata DAILY",
        }
      },
      {
        label: "Curah Hujan",
        attr: {
          "m_0700ws[rr_0700]" : "Curah Hujan jam 07.00 DAILY",
        }
      },
      {
        label: "Radiasi",
        attr: {
          "m_0700ws[ss_8]" : "Lama Penyinaran 8 jam DAILY",
        }
      },
      {
        label: "Tekanan Udara",
        attr: {
          "m_1800ws[pp_qfe_0000]" : "Tekanan Udara DAILY"
        }
      }
    ];
    return listAttr;
  },

  getAttrDailyCsv() {
    const listAttr = [
      {
        label: "Fklim",
        attr: {
          "tbk_avg" : "tbk_avg_daily_fklim_C",
          "rh_avg" : "rh_avg_daily_fklim_PC",
          "ff_avg_km_jm" : "ff_avg_daily_km_jam",
          "wd_cardinal" : "wd_cardinal_daily_fklim",
          "ff_max" : "ff_max_daily_fklim_knot",
          "wd_ff_max" : "wd_ff_max_daily_fklim",
          "m_1800ws[t_max_1c2m]" : "t_max_daily_fklim_C",
          "m_1300ws[t_min_1c2m]" : "t_min_daily_fklim_C",
          "m_0700ws[rr_0700]" : "rr_daily_fklim_mm",
          "m_0700ws[ss_8]" : "ss_8_daily_fklim_jam",
          "m_1800ws[pp_qfe_0000]" : "pp_qfe_0000_daily_fklim_mb"
        },
      }
    ];
    return listAttr;
  },

  getAttrDailyDecimal() {
    const listAttr = [
      {
        label: "Fklim 71",
        attr: {
          "tbk_avg" : 1,
          "rh_avg" : 1,
          "ff_avg_km_jm" : 1,
          "wd_cardinal" : 1,
          "ff_max" : 1,
          "wd_ff_max" : 1,
          "m_1800ws[t_max_1c2m]" : 1,
          "m_1300ws[t_min_1c2m]" : 1,
          "m_0700ws[rr_0700]" : 1,
          "m_0700ws[ss_8]" : 1,
          "m_1800ws[pp_qfe_0000]" : 1
        },
      },
    ];
    return listAttr;
  },

};
