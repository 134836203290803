import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
    getAttr() {
        const listAttr = [
            {
                label: "Temperatur",
                attr: {
                    "tbk_4m_0700" : "suhu bola kering ketinggian 4m di jam 07.00",
                    "tbk_4m_1300" : "suhu bola kering ketinggian 4m di jam 13.00",
                    "tbk_4m_1800" : "suhu bola kering ketinggian 4m di jam 18.00",
                    "tbk_7m_0700" : "suhu bola kering ketinggian 7m di jam 07.00",
                    "tbk_7m_1300" : "suhu bola kering ketinggian 7m di jam 13.00",
                    "tbk_7m_1800" : "suhu bola kering ketinggian 7m di jam 18.00",
                    "tbk_10m_0700" : "suhu bola kering ketinggian 10m di jam 07.00",
                    "tbk_10m_1300" : "suhu bola kering ketinggian 10m di jam 13.00",
                    "tbk_10m_1800" : "suhu bola kering ketinggian 10m di jam 18.00",
                    "tbk_avg_4" : "suhu bola kering rata-rata 4 m",
                    "tbk_avg_7" : "suhu bola kering rata-rata 7 m",
                    "tbk_avg_10" : "suhu bola kering rata-rata 10 m",
                    "t_max_4m" : "suhu maksimum ketinggian 4m",
                    "t_max_7m" : "suhu maksimum ketinggian 7m",
                    "t_max_10m" : "suhu maksimum ketinggian 10m",
                    "t_min_4m" : "suhu minimum ketinggian 4m",
                    "t_min_7m" : "suhu minimum ketinggian 7m",
                    "t_min_10m" : "suhu minimum ketinggian 10m"
                },
            },
            {
                label: "Kelembaban",
                attr: {
                    "rh_avg_4" : "rel hum rata ketinggian 4m",
                    "rh_avg_7" : "rel hum rata ketinggian 7m",
                    "rh_avg_10" : "rel hum rata ketinggian 10m",
                    "rh_4m_0700" : "Rel Hum Ketinggian 4m di jam 07.00",
                    "rh_4m_1300" : "Rel Hum Ketinggian 4m di jam 13.00",
                    "rh_4m_1800" : "Rel Hum Ketinggian 4m di jam 18.00",
                    "rh_7m_0700" : "Rel Hum Ketinggian 7m di jam 07.00",
                    "rh_7m_1300" : "Rel Hum Ketinggian 7m di jam 13.00",
                    "rh_7m_1800" : "Rel Hum Ketinggian 7m di jam 18.00",
                    "rh_10m_0700" : "Rel Hum Ketinggian 10m di jam 07.00",
                    "rh_10m_1300" : "Rel Hum Ketinggian 10m di jam 13.00",
                    "rh_10m_1800" : "Rel Hum Ketinggian 10m di jam 18.00"                
                },
            },
            {
                label: "Angin",
                attr: {
                    "ws_avg_4_0713" : "Kec angin rata selisih antara 07 sd 13 ketinggian 4m",
                    "ws_avg_7_0713" : "Kec angin rata selisih antara 07 sd 13 ketinggian 7m",
                    "ws_avg_10_0713" : "Kec angin rata selisih antara 07 sd 13 ketinggian 10m",
                    "ws_avg_4_1318" : "Kec angin rata selisih antara 13 sd 18 ketinggian 4m",
                    "ws_avg_7_1318" : "Kec angin rata selisih antara 13 sd 18 ketinggian 7m",
                    "ws_avg_10_1318" : "Kec angin rata selisih antara 13 sd 18 ketinggian 10m",
                    "ws_avg_4_1807" : "Kec angin rata selisih antara 18 sd 07 ketinggian 4m",
                    "ws_avg_7_1807" : "Kec angin rata selisih antara 18 sd 07 ketinggian 7m",
                    "ws_avg_10_1807" : "Kec angin rata selisih antara 18 sd 07 ketinggian 10m"
                },
            },
        ]
        return listAttr;
    },

    getAttrMonthly() {
        const listAttr = [
            {
                group: "Temperatur",
                form: "Iklim Mikro",
                label: "Suhu",
                attr: {
                    "tbk_4m_0700_avg_monthly" : "Suhu Bola Kering Ketinggian 4m Di Jam 07.00 AVG MONTHLY",
                    // "tbk_4m_0700_mod_monthly" : "Suhu Bola Kering Ketinggian 4m Di Jam 07.00 MOD MONTHLY",
                    // "tbk_4m_0700_med_monthly" : "Suhu Bola Kering Ketinggian 4m Di Jam 07.00 MED MONTHLY",
                    "tbk_4m_0700_max_monthly" : "Suhu Bola Kering Ketinggian 4m Di Jam 07.00 MAX MONTHLY",
                    "tbk_4m_0700_min_monthly" : "Suhu Bola Kering Ketinggian 4m Di Jam 07.00 MIN MONTHLY",
                    "tbk_4m_0700_sum_monthly" : "Suhu Bola Kering Ketinggian 4m Di Jam 07.00 SUM MONTHLY",
                    "tbk_4m_0700_count_monthly" : "Suhu Bola Kering Ketinggian 4m Di Jam 07.00 COUNT MONTHLY",
                    "tbk_4m_1300_avg_monthly" : "Suhu Bola Kering Ketinggian 4m Di Jam 13.00 AVG MONTHLY",
                    // "tbk_4m_1300_mod_monthly" : "Suhu Bola Kering Ketinggian 4m Di Jam 13.00 MOD MONTHLY",
                    // "tbk_4m_1300_med_monthly" : "Suhu Bola Kering Ketinggian 4m Di Jam 13.00 MED MONTHLY",
                    "tbk_4m_1300_max_monthly" : "Suhu Bola Kering Ketinggian 4m Di Jam 13.00 MAX MONTHLY",
                    "tbk_4m_1300_min_monthly" : "Suhu Bola Kering Ketinggian 4m Di Jam 13.00 MIN MONTHLY",
                    "tbk_4m_1300_sum_monthly" : "Suhu Bola Kering Ketinggian 4m Di Jam 13.00 SUM MONTHLY",
                    "tbk_4m_1300_count_monthly" : "Suhu Bola Kering Ketinggian 4m Di Jam 13.00 COUNT MONTHLY",
                    "tbk_4m_1800_avg_monthly" : "Suhu Bola Kering Ketinggian 4m Di Jam 18.00 AVG MONTHLY",
                    // "tbk_4m_1800_mod_monthly" : "Suhu Bola Kering Ketinggian 4m Di Jam 18.00 MOD MONTHLY",
                    // "tbk_4m_1800_med_monthly" : "Suhu Bola Kering Ketinggian 4m Di Jam 18.00 MED MONTHLY",
                    "tbk_4m_1800_max_monthly" : "Suhu Bola Kering Ketinggian 4m Di Jam 18.00 MAX MONTHLY",
                    "tbk_4m_1800_min_monthly" : "Suhu Bola Kering Ketinggian 4m Di Jam 18.00 MIN MONTHLY",
                    "tbk_4m_1800_sum_monthly" : "Suhu Bola Kering Ketinggian 4m Di Jam 18.00 SUM MONTHLY",
                    "tbk_4m_1800_count_monthly" : "Suhu Bola Kering Ketinggian 4m Di Jam 18.00 COUNT MONTHLY",
                    "tbk_7m_0700_avg_monthly" : "Suhu Bola Kering Ketinggian 7m di jam 07.00 AVG MONTLY",
                    // "tbk_7m_0700_mod_monthly" : "Suhu Bola Kering Ketinggian 7m di jam 07.00 MOD MONTLY",
                    // "tbk_7m_0700_med_monthly" : "Suhu Bola Kering Ketinggian 7m di jam 07.00 MED MONTLY",
                    "tbk_7m_0700_max_monthly" : "Suhu Bola Kering Ketinggian 7m di jam 07.00 MAX MONTLY",
                    "tbk_7m_0700_min_monthly" : "Suhu Bola Kering Ketinggian 7m di jam 07.00 MIN MONTLY",
                    "tbk_7m_0700_sum_monthly" : "Suhu Bola Kering Ketinggian 7m di jam 07.00 SUM MONTLY",
                    "tbk_7m_0700_count_monthly" : "Suhu Bola Kering Ketinggian 7m di jam 07.00 COUNT MONTLY",
                    "tbk_7m_1300_avg_monthly" : "Suhu Bola Kering Ketinggian 7m di jam 13.00 AVG MONTHLY",
                    // "tbk_7m_1300_mod_monthly" : "Suhu Bola Kering Ketinggian 7m di jam 13.00 MOD MONTHLY",
                    // "tbk_7m_1300_med_monthly" : "Suhu Bola Kering Ketinggian 7m di jam 13.00 MED MONTHLY",
                    "tbk_7m_1300_max_monthly" : "Suhu Bola Kering Ketinggian 7m di jam 13.00 MAX MONTHLY",
                    "tbk_7m_1300_min_monthly" : "Suhu Bola Kering Ketinggian 7m di jam 13.00 MIN MONTHLY",
                    "tbk_7m_1300_sum_monthly" : "Suhu Bola Kering Ketinggian 7m di jam 13.00 SUM MONTHLY",
                    "tbk_7m_1300_count_monthly" : "Suhu Bola Kering Ketinggian 7m di jam 13.00 COUNT MONTHLY",
                    "tbk_7m_1800_avg_monthly" : "Suhu Bola Kering Ketinggian 7m di jam 18.00 AVG MONTHLY",
                    // "tbk_7m_1800_mod_monthly" : "Suhu Bola Kering Ketinggian 7m di jam 18.00 MOD MONTHLY",
                    // "tbk_7m_1800_med_monthly" : "Suhu Bola Kering Ketinggian 7m di jam 18.00 MED MONTHLY",
                    "tbk_7m_1800_max_monthly" : "Suhu Bola Kering Ketinggian 7m di jam 18.00 MAX MONTHLY",
                    "tbk_7m_1800_min_monthly" : "Suhu Bola Kering Ketinggian 7m di jam 18.00 MIN MONTHLY",
                    "tbk_7m_1800_sum_monthly" : "Suhu Bola Kering Ketinggian 7m di jam 18.00 SUM MONTHLY",
                    "tbk_7m_1800_count_monthly" : "Suhu Bola Kering Ketinggian 7m di jam 18.00 COUNT MONTHLY",
                    "tbk_10m_0700_avg_monthly" : "Suhu Bola Kering Ketinggian 10m di jam 07.00 AVG MONTHLY",
                    // "tbk_10m_0700_mod_monthly" : "Suhu Bola Kering Ketinggian 10m di jam 07.00 MOD MONTHLY",
                    // "tbk_10m_0700_med_monthly" : "Suhu Bola Kering Ketinggian 10m di jam 07.00 MED MONTHLY",
                    "tbk_10m_0700_max_monthly" : "Suhu Bola Kering Ketinggian 10m di jam 07.00 MAX MONTHLY",
                    "tbk_10m_0700_min_monthly" : "Suhu Bola Kering Ketinggian 10m di jam 07.00 MIN MONTHLY",
                    "tbk_10m_0700_sum_monthly" : "Suhu Bola Kering Ketinggian 10m di jam 07.00 SUM MONTHLY",
                    "tbk_10m_0700_count_monthly" : "Suhu Bola Kering Ketinggian 10m di jam 07.00 COUNT MONTHLY",
                    "tbk_10m_1300_avg_monthly" : "Suhu Bola Kering Ketinggian 10m di jam 13.00 AVG MONTHLY",
                    // "tbk_10m_1300_mod_monthly" : "Suhu Bola Kering Ketinggian 10m di jam 13.00 MOD MONTHLY",
                    // "tbk_10m_1300_med_monthly" : "Suhu Bola Kering Ketinggian 10m di jam 13.00 MED MONTHLY",
                    "tbk_10m_1300_max_monthly" : "Suhu Bola Kering Ketinggian 10m di jam 13.00 MAX MONTHLY",
                    "tbk_10m_1300_min_monthly" : "Suhu Bola Kering Ketinggian 10m di jam 13.00 MIN MONTHLY",
                    "tbk_10m_1300_sum_monthly" : "Suhu Bola Kering Ketinggian 10m di jam 13.00 SUM MONTHLY",
                    "tbk_10m_1300_count_monthly" : "Suhu Bola Kering Ketinggian 10m di jam 13.00 COUNT MONTHLY",
                    "tbk_10m_1800_avg_monthly" : "Suhu Bola Kering Ketinggian 10m di jam 18.00 AVG MONTHLY",
                    // "tbk_10m_1800_mod_monthly" : "Suhu Bola Kering Ketinggian 10m di jam 18.00 MOD MONTHLY",
                    // "tbk_10m_1800_med_monthly" : "Suhu Bola Kering Ketinggian 10m di jam 18.00 MED MONTHLY",
                    "tbk_10m_1800_max_monthly" : "Suhu Bola Kering Ketinggian 10m di jam 18.00 MAX MONTHLY",
                    "tbk_10m_1800_min_monthly" : "Suhu Bola Kering Ketinggian 10m di jam 18.00 MIN MONTHLY",
                    "tbk_10m_1800_sum_monthly" : "Suhu Bola Kering Ketinggian 10m di jam 18.00 SUM MONTHLY",
                    "tbk_10m_1800_count_monthly" : "Suhu Bola Kering Ketinggian 10m di jam 18.00 COUNT MONTHLY",
                    "tbk_avg_4_avg_monthly" : "Suhu Bola Kering Rata-rata 4 m AVG MONTHLY",
                    // "tbk_avg_4_mod_monthly" : "Suhu Bola Kering Rata-rata 4 m MOD MONTHLY",
                    // "tbk_avg_4_med_monthly" : "Suhu Bola Kering Rata-rata 4 m MED MONTHLY",
                    "tbk_avg_4_max_monthly" : "Suhu Bola Kering Rata-rata 4 m MAX MONTHLY",
                    "tbk_avg_4_min_monthly" : "Suhu Bola Kering Rata-rata 4 m MIN MONTHLY",
                    "tbk_avg_4_sum_monthly" : "Suhu Bola Kering Rata-rata 4 m SUM MONTHLY",
                    "tbk_avg_4_count_monthly" : "Suhu Bola Kering Rata-rata 4 m COUNT MONTHLY",
                    "tbk_avg_7_avg_monthly" : "Suhu Bola Kering Rata-rata 7 m AVG MONTHLY",
                    // "tbk_avg_7_mod_monthly" : "Suhu Bola Kering Rata-rata 7 m MOD MONTHLY",
                    // "tbk_avg_7_med_monthly" : "Suhu Bola Kering Rata-rata 7 m MED MONTHLY",
                    "tbk_avg_7_max_monthly" : "Suhu Bola Kering Rata-rata 7 m MAX MONTHLY",
                    "tbk_avg_7_min_monthly" : "Suhu Bola Kering Rata-rata 7 m MIN MONTHLY",
                    "tbk_avg_7_sum_monthly" : "Suhu Bola Kering Rata-rata 7 m SUM MONTHLY",
                    "tbk_avg_7_count_monthly" : "Suhu Bola Kering Rata-rata 7 m COUNT MONTHLY",
                    "tbk_avg_10_avg_monthly" : "Suhu Bola Kering Rata-rata 10 m AVG MONTHLY",
                    // "tbk_avg_10_mod_monthly" : "Suhu Bola Kering Rata-rata 10 m MOD MONTHLY",
                    // "tbk_avg_10_med_monthly" : "Suhu Bola Kering Rata-rata 10 m MED MONTHLY",
                    "tbk_avg_10_max_monthly" : "Suhu Bola Kering Rata-rata 10 m MAX MONTHLY",
                    "tbk_avg_10_min_monthly" : "Suhu Bola Kering Rata-rata 10 m MIN MONTHLY",
                    "tbk_avg_10_sum_monthly" : "Suhu Bola Kering Rata-rata 10 m SUM MONTHLY",
                    "tbk_avg_10_count_monthly" : "Suhu Bola Kering Rata-rata 10 m COUNT MONTHLY",
                    "t_max_4m_avg_monthly" : "Suhu Maksimum Ketinggian 4m AVG MONTHLY",
                    // "t_max_4m_mod_monthly" : "Suhu Maksimum Ketinggian 4m MOD MONTHLY",
                    // "t_max_4m_med_monthly" : "Suhu Maksimum Ketinggian 4m MED MONTHLY",
                    "t_max_4m_max_monthly" : "Suhu Maksimum Ketinggian 4m MAX MONTHLY",
                    "t_max_4m_min_monthly" : "Suhu Maksimum Ketinggian 4m MIN MONTHLY",
                    "t_max_4m_sum_monthly" : "Suhu Maksimum Ketinggian 4m SUM MONTHLY",
                    "t_max_4m_count_monthly" : "Suhu Maksimum Ketinggian 4m COUNT MONTHLY",
                    "t_max_7m_avg_monthly" : "Suhu Maksimum Ketinggian 7m AVG MONTHLY",
                    // "t_max_7m_mod_monthly" : "Suhu Maksimum Ketinggian 7m MOD MONTHLY",
                    // "t_max_7m_med_monthly" : "Suhu Maksimum Ketinggian 7m MED MONTHLY",
                    "t_max_7m_max_monthly" : "Suhu Maksimum Ketinggian 7m MAX MONTHLY",
                    "t_max_7m_min_monthly" : "Suhu Maksimum Ketinggian 7m MIN MONTHLY",
                    "t_max_7m_sum_monthly" : "Suhu Maksimum Ketinggian 7m SUM MONTHLY",
                    "t_max_7m_count_monthly" : "Suhu Maksimum Ketinggian 7m COUNT MONTHLY",
                    "t_max_10m_avg_monthly" : "Suhu Maksimum Ketinggian 10m AVG MONTHLY",
                    // "t_max_10m_mod_monthly" : "Suhu Maksimum Ketinggian 10m MOD MONTHLY",
                    // "t_max_10m_med_monthly" : "Suhu Maksimum Ketinggian 10m MED MONTHLY",
                    "t_max_10m_max_monthly" : "Suhu Maksimum Ketinggian 10m MAX MONTHLY",
                    "t_max_10m_min_monthly" : "Suhu Maksimum Ketinggian 10m MIN MONTHLY",
                    "t_max_10m_sum_monthly" : "Suhu Maksimum Ketinggian 10m SUM MONTHLY",
                    "t_max_10m_count_monthly" : "Suhu Maksimum Ketinggian 10m COUNT MONTHLY",
                    "t_min_4m_avg_monthly" : "Suhu Minimum Ketinggian 4m AVG MONTHLY",
                    // "t_min_4m_mod_monthly" : "Suhu Minimum Ketinggian 4m MOD MONTHLY",
                    // "t_min_4m_med_monthly" : "Suhu Minimum Ketinggian 4m MED MONTHLY",
                    "t_min_4m_max_monthly" : "Suhu Minimum Ketinggian 4m MAX MONTHLY",
                    "t_min_4m_min_monthly" : "Suhu Minimum Ketinggian 4m MIN MONTHLY",
                    "t_min_4m_sum_monthly" : "Suhu Minimum Ketinggian 4m SUM MONTHLY",
                    "t_min_4m_count_monthly" : "Suhu Minimum Ketinggian 4m COUNT MONTHLY",
                    "t_min_7m_avg_monthly" : "Suhu Minimum Ketinggian 7m AVG MONTHLY",
                    // "t_min_7m_mod_monthly" : "Suhu Minimum Ketinggian 7m MOD MONTHLY",
                    // "t_min_7m_med_monthly" : "Suhu Minimum Ketinggian 7m MED MONTHLY",
                    "t_min_7m_max_monthly" : "Suhu Minimum Ketinggian 7m MAX MONTHLY",
                    "t_min_7m_min_monthly" : "Suhu Minimum Ketinggian 7m MIN MONTHLY",
                    "t_min_7m_sum_monthly" : "Suhu Minimum Ketinggian 7m SUM MONTHLY",
                    "t_min_7m_count_monthly" : "Suhu Minimum Ketinggian 7m COUNT MONTHLY",
                    "t_min_10m_avg_monthly" : "Suhu Minimum Ketinggian 10m AVG MONTHLY",
                    // "t_min_10m_mod_monthly" : "Suhu Minimum Ketinggian 10m MOD MONTHLY",
                    // "t_min_10m_med_monthly" : "Suhu Minimum Ketinggian 10m MED MONTHLY",
                    "t_min_10m_max_monthly" : "Suhu Minimum Ketinggian 10m MAX MONTHLY",
                    "t_min_10m_min_monthly" : "Suhu Minimum Ketinggian 10m MIN MONTHLY",
                    "t_min_10m_sum_monthly" : "Suhu Minimum Ketinggian 10m SUM MONTHLY",
                    "t_min_10m_count_monthly" : "Suhu Minimum Ketinggian 10m COUNT MONTHLY"
                },
            },
            {
                group: "Kelembaban",
                form: "Iklim Mikro",
                label: "Kelembaban",
                attr: {
                    "rh_avg_4_avg_monthly" : "Rel Hum Rata Ketinggian 4m AVG MONTHLY",
                    // "rh_avg_4_mod_monthly" : "Rel Hum Rata Ketinggian 4m MOD MONTHLY",
                    // "rh_avg_4_med_monthly" : "Rel Hum Rata Ketinggian 4m MED MONTHLY",
                    "rh_avg_4_max_monthly" : "Rel Hum Rata Ketinggian 4m MAX MONTHLY",
                    "rh_avg_4_min_monthly" : "Rel Hum Rata Ketinggian 4m MIN MONTHLY",
                    "rh_avg_4_sum_monthly" : "Rel Hum Rata Ketinggian 4m SUM MONTHLY",
                    "rh_avg_4_count_monthly" : "Rel Hum Rata Ketinggian 4m COUNT MONTHLY",
                    "rh_avg_7_avg_monthly" : "Rel Hum Rata Ketinggian 7m AVG MONTHLY",
                    // "rh_avg_7_mod_monthly" : "Rel Hum Rata Ketinggian 7m MOD MONTHLY",
                    // "rh_avg_7_med_monthly" : "Rel Hum Rata Ketinggian 7m MED MONTHLY",
                    "rh_avg_7_max_monthly" : "Rel Hum Rata Ketinggian 7m MAX MONTHLY",
                    "rh_avg_7_min_monthly" : "Rel Hum Rata Ketinggian 7m MIN MONTHLY",
                    "rh_avg_7_sum_monthly" : "Rel Hum Rata Ketinggian 7m SUM MONTHLY",
                    "rh_avg_7_count_monthly" : "Rel Hum Rata Ketinggian 7m COUNT MONTHLY",
                    "rh_avg_10_avg_monthly" : "Rel Hum Rata Ketinggian 10m AVG MONTHLY",
                    // "rh_avg_10_mod_monthly" : "Rel Hum Rata Ketinggian 10m MOD MONTHLY",
                    // "rh_avg_10_med_monthly" : "Rel Hum Rata Ketinggian 10m MED MONTHLY",
                    "rh_avg_10_max_monthly" : "Rel Hum Rata Ketinggian 10m MAX MONTHLY",
                    "rh_avg_10_min_monthly" : "Rel Hum Rata Ketinggian 10m MIN MONTHLY",
                    "rh_avg_10_sum_monthly" : "Rel Hum Rata Ketinggian 10m SUM MONTHLY",
                    "rh_avg_10_count_monthly" : "Rel Hum Rata Ketinggian 10m COUNT MONTHLY",
                    "rh_4m_0700_avg_monthly" : "Rel Hum Ketinggian 4m di jam 07.00 AVG MONTHLY",
                    // "rh_4m_0700_mod_monthly" : "Rel Hum Ketinggian 4m di jam 07.00 MOD MONTHLY",
                    // "rh_4m_0700_med_monthly" : "Rel Hum Ketinggian 4m di jam 07.00 MED MONTHLY",
                    "rh_4m_0700_max_monthly" : "Rel Hum Ketinggian 4m di jam 07.00 MAX MONTHLY",
                    "rh_4m_0700_min_monthly" : "Rel Hum Ketinggian 4m di jam 07.00 MIN MONTHLY",
                    "rh_4m_0700_sum_monthly" : "Rel Hum Ketinggian 4m di jam 07.00 SUM MONTHLY",
                    "rh_4m_0700_count_monthly" : "Rel Hum Ketinggian 4m di jam 07.00 COUNT MONTHLY",
                    "rh_4m_1300_avg_monthly" : "Rel Hum Ketinggian 4m di jam 13.00 AVG MONTHLY",
                    // "rh_4m_1300_mod_monthly" : "Rel Hum Ketinggian 4m di jam 13.00 MOD MONTHLY",
                    // "rh_4m_1300_med_monthly" : "Rel Hum Ketinggian 4m di jam 13.00 MED MONTHLY",
                    "rh_4m_1300_max_monthly" : "Rel Hum Ketinggian 4m di jam 13.00 MAX MONTHLY",
                    "rh_4m_1300_min_monthly" : "Rel Hum Ketinggian 4m di jam 13.00 MIN MONTHLY",
                    "rh_4m_1300_sum_monthly" : "Rel Hum Ketinggian 4m di jam 13.00 SUM MONTHLY",
                    "rh_4m_1300_count_monthly" :"Rel Hum Ketinggian 4m di jam 13.00 COUNT MONTHLY",
                    "rh_4m_1800_avg_monthly" : "Rel Hum Ketinggian 4m di jam 18.00 AVG MONTHLY",
                    // "rh_4m_1800_mod_monthly" : "Rel Hum Ketinggian 4m di jam 18.00 MOD MONTHLY",
                    // "rh_4m_1800_med_monthly" : "Rel Hum Ketinggian 4m di jam 18.00 MED MONTHLY",
                    "rh_4m_1800_max_monthly" : "Rel hum ketinggian 4m di jam 18.00 MAX MONTHLY",
                    "rh_4m_1800_min_monthly" : "Rel Hum Ketinggian 4m di jam 18.00 MIN MONTHLY",
                    "rh_4m_1800_sum_monthly" : "Rel Hum Ketinggian 4m di jam 18.00 SUM MONTHLY",
                    "rh_4m_1800_count_monthly" : "Rel Hum Ketinggian 4m di jam 18.00 COUNT MONTHLY",
                    "rh_7m_0700_avg_monthly" : "Rel Hum Ketinggian 7m di jam 07.00 AVG MONTHLY",
                    // "rh_7m_0700_mod_monthly" : "Rel Hum Ketinggian 7m di jam 07.00 MOD MONTHLY",
                    // "rh_7m_0700_med_monthly" : "Rel Hum Ketinggian 7m di jam 07.00 MED MONTHLY",
                    "rh_7m_0700_max_monthly" : "Rel Hum Ketinggian 7m di jam 07.00 MAX MONTHLY",
                    "rh_7m_0700_min_monthly" : "Rel Hum Ketinggian 7m di jam 07.00 MIN MONTHLY",
                    "rh_7m_0700_sum_monthly" : "Rel Hum Ketinggian 7m di jam 07.00 SUM MONTHLY",
                    "rh_7m_0700_count_monthly" : "Rel Hum Ketinggian 7m di jam 07.00 COUNT MONTHLY",
                    "rh_7m_1300_avg_monthly" : "Rel Hum Ketinggian 7m di jam 13.00 AVG MONTHLY",
                    // "rh_7m_1300_mod_monthly" : "Rel Hum Ketinggian 7m di jam 13.00 MOD MONTHLY",
                    // "rh_7m_1300_med_monthly" : "Rel Hum Ketinggian 7m di jam 13.00 MED MONTHLY",
                    "rh_7m_1300_max_monthly" : "Rel Hum Ketinggian 7m di jam 13.00 MAX MONTHLY",
                    "rh_7m_1300_min_monthly" : "Rel Hum Ketinggian 7m di jam 13.00 MIN MONTHLY",
                    "rh_7m_1300_sum_monthly" : "Rel Hum Ketinggian 7m di jam 13.00 SUM MONTHLY",
                    "rh_7m_1300_count_monthly" : "Rel Hum Ketinggian 7m di jam 13.00 COUNT MONTHLY",
                    "rh_7m_1800_avg_monthly" : "Rel Hum Ketinggian 7m di jam 18.00 AVG MONTHLY",
                    // "rh_7m_1800_mod_monthly" : "Rel Hum Ketinggian 7m di jam 18.00 MOD MONTHLY",
                    // "rh_7m_1800_med_monthly" : "Rel Hum Ketinggian 7m di jam 18.00 MED MONTHLY",
                    "rh_7m_1800_max_monthly" : "Rel Hum Ketinggian 7m di jam 18.00 MAX MONTHLY",
                    "rh_7m_1800_min_monthly" : "Rel Hum Ketinggian 7m di jam 18.00 MIN MONTHLY",
                    "rh_7m_1800_sum_monthly" : "Rel Hum Ketinggian 7m di jam 18.00 SUM MONTHLY",
                    "rh_7m_1800_count_monthly" : "Rel Hum Ketinggian 7m di jam 18.00 COUNT MONTHLY",
                    "rh_10m_0700_avg_monthly" : "Rel Hum Ketinggian 10m di jam 07.00 AVG MONTHLY",
                    // "rh_10m_0700_mod_monthly" : "Rel Hum Ketinggian 10m di jam 07.00 MOD MONTHLY",
                    // "rh_10m_0700_med_monthly" : "Rel Hum Ketinggian 10m di jam 07.00 MED MONTHLY",
                    "rh_10m_0700_max_monthly" : "Rel Hum Ketinggian 10m di jam 07.00 MAX MONTHLY",
                    "rh_10m_0700_min_monthly" : "Rel Hum Ketinggian 10m di jam 07.00 MIN MONTHLY",
                    "rh_10m_0700_sum_monthly" : "Rel Hum Ketinggian 10m di jam 07.00 SUM MONTHLY",
                    "rh_10m_0700_count_monthly" : "Rel Hum Ketinggian 10m di jam 07.00 COUNT MONTHLY",
                    "rh_10m_1300_avg_monthly" : "Rel Hum Ketinggian 10m di jam 13.00 AVG MONTHLY",
                    // "rh_10m_1300_mod_monthly" : "Rel Hum Ketinggian 10m di jam 13.00 MOD MONTHLY",
                    // "rh_10m_1300_med_monthly" : "Rel Hum Ketinggian 10m di jam 13.00 MED MONTHLY",
                    "rh_10m_1300_max_monthly" : "Rel Hum Ketinggian 10m di jam 13.00 MAX MONTHLY",
                    "rh_10m_1300_min_monthly" : "Rel Hum Ketinggian 10m di jam 13.00 MIN MONTHLY",
                    "rh_10m_1300_sum_monthly" : "Rel Hum Ketinggian 10m di jam 13.00 SUM MONTHLY",
                    "rh_10m_1300_count_monthly" : "Rel Hum Ketinggian 10m di jam 13.00 COUNT MONTHLY",
                    "rh_10m_1800_avg_monthly" : "Rel Hum Ketinggian 10m di jam 18.00 AVG MONTHLY",
                    // "rh_10m_1800_mod_monthly" : "Rel Hum Ketinggian 10m di jam 18.00 MOD MONTHLY",
                    // "rh_10m_1800_med_monthly" : "Rel Hum Ketinggian 10m di jam 18.00 MED MONTHLY",
                    "rh_10m_1800_max_monthly" : "Rel Hum Ketinggian 10m di jam 18.00 MAX MONTHLY",
                    "rh_10m_1800_min_monthly" : "Rel Hum Ketinggian 10m di jam 18.00 MIN MONTHLY",
                    "rh_10m_1800_sum_monthly" : "Rel Hum Ketinggian 10m di jam 18.00 SUM MONTHLY",
                    "rh_10m_1800_count_monthly" : "Rel Hum Ketinggian 10m di jam 18.00 COUNT MONTHLY",               
                },
            },
            {
                group: "Angin",
                form: "Iklim Mikro",
                label: "Angin",
                attr: {
                    "ws_avg_4_0713_avg_monthly" : "Kec angin rata selisih antara 07 sd 13 ketinggian 4m AVG MONTHLY",
                    // "ws_avg_4_0713_mod_monthly" : "Kec angin rata selisih antara 07 sd 13 ketinggian 4m MOD MONTHLY",
                    // "ws_avg_4_0713_med_monthly" : "Kec angin rata selisih antara 07 sd 13 ketinggian 4m MED MONTHLY",
                    "ws_avg_4_0713_max_monthly" : "Kec angin rata selisih antara 07 sd 13 ketinggian 4m MAX MONTHLY",
                    "ws_avg_4_0713_min_monthly" : "Kec angin rata selisih antara 07 sd 13 ketinggian 4m MIN MONTHLY",
                    "ws_avg_4_0713_sum_monthly" : "Kec angin rata selisih antara 07 sd 13 ketinggian 4m SUM MONTHLY",
                    "ws_avg_4_0713_count_monthly" : "Kec angin rata selisih antara 07 sd 13 ketinggian 4m COUNT MONTHLY",
                    "ws_avg_7_0713_avg_monthly" : "Kec angin rata selisih antara 07 sd 13 ketinggian 7m AVG MONTHLY",
                    // "ws_avg_7_0713_mod_monthly" : "Kec angin rata selisih antara 07 sd 13 ketinggian 7m MOD MONTHLY",
                    // "ws_avg_7_0713_med_monthly" : "Kec angin rata selisih antara 07 sd 13 ketinggian 7m MED MONTHLY",
                    "ws_avg_7_0713_max_monthly" : "Kec angin rata selisih antara 07 sd 13 ketinggian 7m MAX MONTHLY",
                    "ws_avg_7_0713_min_monthly" : "Kec angin rata selisih antara 07 sd 13 ketinggian 7m MIN MONTHLY",
                    "ws_avg_7_0713_sum_monthly" : "Kec angin rata selisih antara 07 sd 13 ketinggian 7m SUM MONTHLY",
                    "ws_avg_7_0713_count_monthly" : "Kec angin rata selisih antara 07 sd 13 ketinggian 7m COUNT MONTHLY",
                    "ws_avg_10_0713_avg_monthly" : "Kec angin rata selisih antara 07 sd 13 ketinggian 10m AVG MONTHLY",
                    // "ws_avg_10_0713_mod_monthly" : "Kec angin rata selisih antara 07 sd 13 ketinggian 10m MOD MONTHLY",
                    // "ws_avg_10_0713_med_monthly" : "Kec angin rata selisih antara 07 sd 13 ketinggian 10m MED MONTHLY",
                    "ws_avg_10_0713_max_monthly" : "Kec angin rata selisih antara 07 sd 13 ketinggian 10m MAX MONTHLY",
                    "ws_avg_10_0713_min_monthly" : "Kec angin rata selisih antara 07 sd 13 ketinggian 10m MIN MONTHLY",
                    "ws_avg_10_0713_sum_monthly" : "Kec angin rata selisih antara 07 sd 13 ketinggian 10m SUM MONTHLY",
                    "ws_avg_10_0713_count_monthly" : "Kec angin rata selisih antara 07 sd 13 ketinggian 10m COUNT MONTHLY",
                    "ws_avg_4_1318_avg_monthly" : "Kec angin rata selisih antara 13 sd 18 ketinggian 4m AVG MONTHLY",
                    // "ws_avg_4_1318_mod_monthly" : "Kec angin rata selisih antara 13 sd 18 ketinggian 4m MOD MONTHLY",
                    // "ws_avg_4_1318_med_monthly" : "Kec angin rata selisih antara 13 sd 18 ketinggian 4m MED MONTHLY",
                    "ws_avg_4_1318_max_monthly" : "Kec angin rata selisih antara 13 sd 18 ketinggian 4m MAX MONTHLY",
                    "ws_avg_4_1318_min_monthly" : "Kec angin rata selisih antara 13 sd 18 ketinggian 4m MIN MONTHLY",
                    "ws_avg_4_1318_sum_monthly" : "Kec angin rata selisih antara 13 sd 18 ketinggian 4m SUM MONTHLY",
                    "ws_avg_4_1318_count_monthly" : "Kec angin rata selisih antara 13 sd 18 ketinggian 4m COUNT MONTHLY",
                    "ws_avg_7_1318_avg_monthly" : "Kec angin rata selisih antara 13 sd 18 ketinggian 7m AVG MONTHLY",
                    // "ws_avg_7_1318_mod_monthly" : "Kec angin rata selisih antara 13 sd 18 ketinggian 7m MOD MONTHLY",
                    // "ws_avg_7_1318_med_monthly" : "Kec angin rata selisih antara 13 sd 18 ketinggian 7m MED MONTHLY",
                    "ws_avg_7_1318_max_monthly" : "Kec angin rata selisih antara 13 sd 18 ketinggian 7m MAX MONTHLY",
                    "ws_avg_7_1318_min_monthly" : "Kec angin rata selisih antara 13 sd 18 ketinggian 7m MIN MONTHLY",
                    "ws_avg_7_1318_sum_monthly" : "Kec angin rata selisih antara 13 sd 18 ketinggian 7m SUM MONTHLY",
                    "ws_avg_7_1318_count_monthly" : "Kec angin rata selisih antara 13 sd 18 ketinggian 7m COUNT MONTHLY",
                    "ws_avg_10_1318_avg_monthly" : "Kec angin rata selisih antara 13 sd 18 ketinggian 10m AVG MONTHLY",
                    // "ws_avg_10_1318_mod_monthly" : "Kec angin rata selisih antara 13 sd 18 ketinggian 10m MOD MONTHLY",
                    // "ws_avg_10_1318_med_monthly" : "Kec angin rata selisih antara 13 sd 18 ketinggian 10m MED MONTHLY",
                    "ws_avg_10_1318_max_monthly" : "Kec angin rata selisih antara 13 sd 18 ketinggian 10m MAX MONTHLY",
                    "ws_avg_10_1318_min_monthly" : "Kec angin rata selisih antara 13 sd 18 ketinggian 10m MIN MONTHLY",
                    "ws_avg_10_1318_sum_monthly" : "Kec angin rata selisih antara 13 sd 18 ketinggian 10m SUM MONTHLY",
                    "ws_avg_10_1318_count_monthly" : "Kec angin rata selisih antara 13 sd 18 ketinggian 10m COUNT MONTHLY",
                    "ws_avg_4_1807_avg_monthly" : "Kec angin rata selisih antara 18 sd 07 ketinggian 4m AVG MONTHLY",
                    // "ws_avg_4_1807_mod_monthly" : "Kec angin rata selisih antara 18 sd 07 ketinggian 4m MOD MONTHLY",
                    // "ws_avg_4_1807_med_monthly" : "Kec angin rata selisih antara 18 sd 07 ketinggian 4m MED MONTHLY",
                    "ws_avg_4_1807_max_monthly" : "Kec angin rata selisih antara 18 sd 07 ketinggian 4m MAX MONTHLY",
                    "ws_avg_4_1807_min_monthly" : "Kec angin rata selisih antara 18 sd 07 ketinggian 4m MIN MONTHLY",
                    "ws_avg_4_1807_sum_monthly" : "Kec angin rata selisih antara 18 sd 07 ketinggian 4m SUM MONTHLY",
                    "ws_avg_4_1807_count_monthly" : "Kec angin rata selisih antara 18 sd 07 ketinggian 4m COUNT MONTHLY",
                    "ws_avg_7_1807_avg_monthly" : "Kec angin rata selisih antara 18 sd 07 ketinggian 7m AVG MONTHLY",
                    // "ws_avg_7_1807_mod_monthly" : "Kec angin rata selisih antara 18 sd 07 ketinggian 7m MOD MONTHLY",
                    // "ws_avg_7_1807_med_monthly" : "Kec angin rata selisih antara 18 sd 07 ketinggian 7m MED MONTHLY",
                    "ws_avg_7_1807_max_monthly" : "Kec angin rata selisih antara 18 sd 07 ketinggian 7m MAX MONTHLY",
                    "ws_avg_7_1807_min_monthly" : "Kec angin rata selisih antara 18 sd 07 ketinggian 7m MIN MONTHLY",
                    "ws_avg_7_1807_sum_monthly" : "Kec angin rata selisih antara 18 sd 07 ketinggian 7m SUM MONTHLY",
                    "ws_avg_7_1807_count_monthly" : "Kec angin rata selisih antara 18 sd 07 ketinggian 7m COUNT MONTHLY",
                    "ws_avg_10_1807_avg_monthly" : "Kec angin rata selisih antara 18 sd 07 ketinggian 10m AVG MONTHLY",
                    // "ws_avg_10_1807_mod_monthly" : "Kec angin rata selisih antara 18 sd 07 ketinggian 10m MOD MONTHLY",
                    // "ws_avg_10_1807_med_monthly" : "Kec angin rata selisih antara 18 sd 07 ketinggian 10m MED MONTHLY",
                    "ws_avg_10_1807_max_monthly" : "Kec angin rata selisih antara 18 sd 07 ketinggian 10m MAX MONTHLY",
                    "ws_avg_10_1807_min_monthly" : "Kec angin rata selisih antara 18 sd 07 ketinggian 10m MIN MONTHLY",
                    "ws_avg_10_1807_sum_monthly" : "Kec angin rata selisih antara 18 sd 07 ketinggian 10m SUM MONTHLY",
                    "ws_avg_10_1807_count_monthly" : "Kec angin rata selisih antara 18 sd 07 ketinggian 10m COUNT MONTHLY",
                },
            }
        ]
        return listAttr;
    },

    getAttrMonthlyCsv() {
        const listAttr = [
            {
                label: "Suhu",
                attr: {
                    "tbk_4m_0700_avg_monthly" : "tbk_4m_0700_monthly_avg_C",
                    "tbk_4m_0700_mod_monthly" : "tbk_4m_0700_monthly_mod_C",
                    "tbk_4m_0700_med_monthly" : "tbk_4m_0700_monthly_med_C",
                    "tbk_4m_0700_max_monthly" : "tbk_4m_0700_monthly_max_C",
                    "tbk_4m_0700_min_monthly" : "tbk_4m_0700_monthly_min_C",
                    "tbk_4m_0700_sum_monthly" : "tbk_4m_0700_monthly_total_C",
                    "tbk_4m_0700_count_monthly" : "c_tbk_4m_0700_monthly",
                    "tbk_4m_1300_avg_monthly" : "tbk_4m_1300_monthly_avg_C",
                    "tbk_4m_1300_mod_monthly" : "tbk_4m_1300_monthly_mod_C",
                    "tbk_4m_1300_med_monthly" : "tbk_4m_1300_monthly_med_C",
                    "tbk_4m_1300_max_monthly" : "tbk_4m_1300_monthly_max_C",
                    "tbk_4m_1300_min_monthly" : "tbk_4m_1300_monthly_min_C",
                    "tbk_4m_1300_sum_monthly" : "tbk_4m_1300_monthly_total_C",
                    "tbk_4m_1300_count_monthly" : "c_tbk_4m_1200_monthly",
                    "tbk_4m_1800_avg_monthly" : "tbk_4m_1800_monthly_avg_C",
                    "tbk_4m_1800_mod_monthly" : "tbk_4m_1800_monthly_mod_C",
                    "tbk_4m_1800_med_monthly" : "tbk_4m_1800_monthly_med_C",
                    "tbk_4m_1800_max_monthly" : "tbk_4m_1800_monthly_max_C",
                    "tbk_4m_1800_min_monthly" : "tbk_4m_1800_monthly_min_C",
                    "tbk_4m_1800_sum_monthly" : "tbk_4m_1800_monthly_total_C",
                    "tbk_4m_1800_count_monthly" : "c_tbk_4m_1800_monthly",
                    "tbk_7m_0700_avg_monthly" : "tbk_7m_0700_monthly_avg_C",
                    "tbk_7m_0700_mod_monthly" : "tbk_7m_0700_monthly_mod_C",
                    "tbk_7m_0700_med_monthly" : "tbk_7m_0700_monthly_med_C",
                    "tbk_7m_0700_max_monthly" : "tbk_7m_0700_monthly_max_C",
                    "tbk_7m_0700_min_monthly" : "tbk_7m_0700_monthly_min_C",
                    "tbk_7m_0700_sum_monthly" : "tbk_7m_0700_monthly_total_C",
                    "tbk_7m_0700_count_monthly" : "c_tbk_7m_0700_monthly",
                    "tbk_7m_1300_avg_monthly" : "tbk_7m_1300_monthly_avg_C",
                    "tbk_7m_1300_mod_monthly" : "tbk_7m_1300_monthly_mod_C",
                    "tbk_7m_1300_med_monthly" : "tbk_7m_1300_monthly_med_C",
                    "tbk_7m_1300_max_monthly" : "tbk_7m_1300_monthly_max_C",
                    "tbk_7m_1300_min_monthly" : "tbk_7m_1300_monthly_min_C",
                    "tbk_7m_1300_sum_monthly" : "tbk_7m_1300_monthly_total_C",
                    "tbk_7m_1300_count_monthly" : "c_tbk_7m_1300_monthly",
                    "tbk_7m_1800_avg_monthly" : "tbk_7m_1800_monthly_avg_C",
                    "tbk_7m_1800_mod_monthly" : "tbk_7m_1800_monthly_mod_C",
                    "tbk_7m_1800_med_monthly" : "tbk_7m_1800_monthly_med_C",
                    "tbk_7m_1800_max_monthly" : "tbk_7m_1800_monthly_max_C",
                    "tbk_7m_1800_min_monthly" : "tbk_7m_1800_monthly_min_C",
                    "tbk_7m_1800_sum_monthly" : "tbk_7m_1800_monthly_total_C",
                    "tbk_7m_1800_count_monthly" : "c_tbk_7m_1800_monthly",
                    "tbk_10m_0700_avg_monthly" : "tbk_10m_0700_monthly_avg_C",
                    "tbk_10m_0700_mod_monthly" : "tbk_10m_0700_monthly_mod_C",
                    "tbk_10m_0700_med_monthly" : "tbk_10m_0700_monthly_med_C",
                    "tbk_10m_0700_max_monthly" : "tbk_10m_0700_monthly_max_C",
                    "tbk_10m_0700_min_monthly" : "tbk_10m_0700_monthly_min_C",
                    "tbk_10m_0700_sum_monthly" : "tbk_10m_0700_monthly_total_C",
                    "tbk_10m_0700_count_monthly" : "c_tbk_10m_0700_monthly",
                    "tbk_10m_1300_avg_monthly" : "tbk_10m_1300_monthly_avg_C",
                    "tbk_10m_1300_mod_monthly" : "tbk_10m_1300_monthly_mod_C",
                    "tbk_10m_1300_med_monthly" : "tbk_10m_1300_monthly_med_C",
                    "tbk_10m_1300_max_monthly" : "tbk_10m_1300_monthly_max_C",
                    "tbk_10m_1300_min_monthly" : "tbk_10m_1300_monthly_min_C",
                    "tbk_10m_1300_sum_monthly" : "tbk_10m_1300_monthly_total_C",
                    "tbk_10m_1300_count_monthly" : "c_tbk_10m_1300_monthly",
                    "tbk_10m_1800_avg_monthly" : "tbk_10m_1800_monthly_avg_C",
                    "tbk_10m_1800_mod_monthly" : "tbk_10m_1800_monthly_mod_C",
                    "tbk_10m_1800_med_monthly" : "tbk_10m_1800_monthly_med_C",
                    "tbk_10m_1800_max_monthly" : "tbk_10m_1800_monthly_max_C",
                    "tbk_10m_1800_min_monthly" : "tbk_10m_1800_monthly_min_C",
                    "tbk_10m_1800_sum_monthly" : "tbk_10m_1800_monthly_total_C",
                    "tbk_10m_1800_count_monthly" : "c_tbk_10m_1800_monthly",
                    "tbk_avg_4_avg_monthly" : "tbk_avg_4_monthly_avg_C",
                    "tbk_avg_4_mod_monthly" : "tbk_avg_4_monthly_mod_C",
                    "tbk_avg_4_med_monthly" : "tbk_avg_4_monthly_med_C",
                    "tbk_avg_4_max_monthly" : "tbk_avg_4_monthly_max_C",
                    "tbk_avg_4_min_monthly" : "tbk_avg_4_monthly_min_C",
                    "tbk_avg_4_sum_monthly" : "tbk_avg_4_monthly_total_C",
                    "tbk_avg_4_count_monthly" : "c_tbk_avg_4m_monthly",
                    "tbk_avg_7_avg_monthly" : "tbk_avg_7_monthly_avg_C",
                    "tbk_avg_7_mod_monthly" : "tbk_avg_7_monthly_mod_C",
                    "tbk_avg_7_med_monthly" : "tbk_avg_7_monthly_med_C",
                    "tbk_avg_7_max_monthly" : "tbk_avg_7_monthly_max_C",
                    "tbk_avg_7_min_monthly" : "tbk_avg_7_monthly_min_C",
                    "tbk_avg_7_sum_monthly" : "tbk_avg_7_monthly_total_C",
                    "tbk_avg_7_count_monthly" : "c_tbk_avg_7m_monthly",
                    "tbk_avg_10_avg_monthly" : "tbk_avg_10_monthly_avg_C",
                    "tbk_avg_10_mod_monthly" : "tbk_avg_10_monthly_mod_C",
                    "tbk_avg_10_med_monthly" : "tbk_avg_10_monthly_med_C",
                    "tbk_avg_10_max_monthly" : "tbk_avg_10_monthly_max_C",
                    "tbk_avg_10_min_monthly" : "tbk_avg_10_monthly_min_C",
                    "tbk_avg_10_sum_monthly" : "tbk_avg_10_monthly_total_C",
                    "tbk_avg_10_count_monthly" : "c_tbk_avg_10m_monthly",
                    "t_max_4m_avg_monthly" : "t_max_4m_monthly_avg_C",
                    "t_max_4m_mod_monthly" : "t_max_4m_monthly_mod_C",
                    "t_max_4m_med_monthly" : "t_max_4m_monthly_min_C",
                    "t_max_4m_max_monthly" : "t_max_4m_monthly_max_C",
                    "t_max_4m_min_monthly" : "t_max_4m_monthly_min_C",
                    "t_max_4m_sum_monthly" : "t_max_4m_monthly_total_C",
                    "t_max_4m_count_monthly" : "c_t_max_4m_monthly",
                    "t_max_7m_avg_monthly" : "t_max_7m_monthly_avg_C",
                    "t_max_7m_mod_monthly" : "t_max_7m_monthly_mod_C",
                    "t_max_7m_med_monthly" : "t_max_7m_monthly_min_C",
                    "t_max_7m_max_monthly" : "t_max_7m_monthly_max_C",
                    "t_max_7m_min_monthly" : "t_max_7m_monthly_min_C",
                    "t_max_7m_sum_monthly" : "t_max_7m_monthly_total_C",
                    "t_max_7m_count_monthly" : "c_t_max_7m_monthly",
                    "t_max_10m_avg_monthly" : "t_max_10m_monthly_avg_C",
                    "t_max_10m_mod_monthly" : "t_max_10m_monthly_mod_C",
                    "t_max_10m_med_monthly" : "t_max_10m_monthly_min_C",
                    "t_max_10m_max_monthly" : "t_max_10m_monthly_max_C",
                    "t_max_10m_min_monthly" : "t_max_10m_monthly_min_C",
                    "t_max_10m_sum_monthly" : "t_max_10m_monthly_total_C",
                    "t_max_10m_count_monthly" : "c_t_max_10m_monthly",
                    "t_min_4m_avg_monthly" : "t_min_4m_monthly_avg_C",
                    "t_min_4m_mod_monthly" : "t_min_4m_monthly_mod_C",
                    "t_min_4m_med_monthly" : "t_min_4m_monthly_med_C",
                    "t_min_4m_max_monthly" : "t_min_4m_monthly_max_C",
                    "t_min_4m_min_monthly" : "t_min_4m_monthly_min_C",
                    "t_min_4m_sum_monthly" : "t_min_4m_monthly_total_C",
                    "t_min_4m_count_monthly" : "c_t_min_4m_monthly",
                    "t_min_7m_avg_monthly" : "t_min_7m_monthly_avg_C",
                    "t_min_7m_mod_monthly" : "t_min_7m_monthly_mod_C",
                    "t_min_7m_med_monthly" : "t_min_7m_monthly_med_C",
                    "t_min_7m_max_monthly" : "t_min_7m_monthly_max_C",
                    "t_min_7m_min_monthly" : "t_min_7m_monthly_min_C",
                    "t_min_7m_sum_monthly" : "t_min_7m_monthly_total_C",
                    "t_min_7m_count_monthly" : "c_t_min_7m_monthly",
                    "t_min_10m_avg_monthly" : "t_min_10m_monthly_avg_C",
                    "t_min_10m_mod_monthly" : "t_min_10m_monthly_mod_C",
                    "t_min_10m_med_monthly" : "t_min_10m_monthly_med_C",
                    "t_min_10m_max_monthly" : "t_min_10m_monthly_max_C",
                    "t_min_10m_min_monthly" : "t_min_10m_monthly_min_C",
                    "t_min_10m_sum_monthly" : "t_min_10m_monthly_total_C",
                    "t_min_10m_count_monthly" : "c_t_min_10m_monthly",
                
                    "rh_avg_4_avg_monthly" : "rh_avg_4m_monthly_avg_PC",
                    "rh_avg_4_mod_monthly" : "rh_avg_4m_monthly_max_PC",
                    "rh_avg_4_med_monthly" : "rh_avg_4m_monthly_med_PC",
                    "rh_avg_4_max_monthly" : "rh_avg_4m_monthly_max_PC",
                    "rh_avg_4_min_monthly" : "rh_avg_4m_monthly_min_PC",
                    "rh_avg_4_sum_monthly" : "rh_avg_4m_monthly_total_PC",
                    "rh_avg_4_count_monthly" : "c_rh_avg_4m_monthly",
                    "rh_avg_7_avg_monthly" : "rh_avg_7m_monthly_avg_PC",
                    "rh_avg_7_mod_monthly" : "rh_avg_7m_monthly_max_PC",
                    "rh_avg_7_med_monthly" : "rh_avg_7m_monthly_med_PC",
                    "rh_avg_7_max_monthly" : "rh_avg_7m_monthly_max_PC",
                    "rh_avg_7_min_monthly" : "rh_avg_7m_monthly_min_PC",
                    "rh_avg_7_sum_monthly" : "rh_avg_7m_monthly_total_PC",
                    "rh_avg_7_count_monthly" : "c_rh_avg_7m_monthly",
                    "rh_avg_10_avg_monthly" : "rh_avg_10m_monthly_avg_PC",
                    "rh_avg_10_mod_monthly" : "rh_avg_10m_monthly_max_PC",
                    "rh_avg_10_med_monthly" : "rh_avg_10m_monthly_med_PC",
                    "rh_avg_10_max_monthly" : "rh_avg_10m_monthly_max_PC",
                    "rh_avg_10_min_monthly" : "rh_avg_10m_monthly_min_PC",
                    "rh_avg_10_sum_monthly" : "rh_avg_10m_monthly_total_PC",
                    "rh_avg_10_count_monthly" : "c_rh_avg_10m_monthly",
                    "rh_4m_0700_avg_monthly" : "rh_4m_0700_monthly_avg_PC",
                    "rh_4m_0700_mod_monthly" : "rh_4m_0700_monthly_mod_PC",
                    "rh_4m_0700_med_monthly" : "rh_4m_0700_monthly_med_PC",
                    "rh_4m_0700_max_monthly" : "rh_4m_0700_monthly_max_PC",
                    "rh_4m_0700_min_monthly" : "rh_4m_0700_monthly_min_PC",
                    "rh_4m_0700_sum_monthly" : "rh_4m_0700_monthly_total_PC",
                    "rh_4m_0700_count_monthly" : "c_rh_4m_0700_monthly",
                    "rh_4m_1300_avg_monthly" : "rh_4m_1300_monthly_avg_PC",
                    "rh_4m_1300_mod_monthly" : "rh_4m_1300_monthly_mod_PC",
                    "rh_4m_1300_med_monthly" : "rh_4m_1300_monthly_med_PC",
                    "rh_4m_1300_max_monthly" : "rh_4m_1300_monthly_max_PC",
                    "rh_4m_1300_min_monthly" : "rh_4m_1300_monthly_min_PC",
                    "rh_4m_1300_sum_monthly" : "rh_4m_1300_monthly_total_PC",
                    "rh_4m_1300_count_monthly" : "c_rh_4m_1300_monthly",
                    "rh_4m_1800_avg_monthly" : "rh_4m_1800_monthly_avg_PC",
                    "rh_4m_1800_mod_monthly" : "rh_4m_1800_monthly_mod_PC",
                    "rh_4m_1800_med_monthly" : "rh_4m_1800_monthly_med_PC",
                    "rh_4m_1800_max_monthly" : "rh_4m_1800_monthly_max_PC",
                    "rh_4m_1800_min_monthly" : "rh_4m_1800_monthly_min_PC",
                    "rh_4m_1800_sum_monthly" : "rh_4m_1800_monthly_total_PC",
                    "rh_4m_1800_count_monthly" : "c_rh_4m_1800_monthly",
                    "rh_7m_0700_avg_monthly" : "rh_7m_0700_monthly_avg_PC",
                    "rh_7m_0700_mod_monthly" : "rh_7m_0700_monthly_mod_PC",
                    "rh_7m_0700_med_monthly" : "rh_7m_0700_monthly_med_PC",
                    "rh_7m_0700_max_monthly" : "rh_7m_0700_monthly_max_PC",
                    "rh_7m_0700_min_monthly" : "rh_7m_0700_monthly_min_PC",
                    "rh_7m_0700_sum_monthly" : "rh_7m_0700_monthly_total_PC",
                    "rh_7m_0700_count_monthly" : "c_rh_7m_0700_monthly",
                    "rh_7m_1300_avg_monthly" : "rh_7m_1300_monthly_avg_PC",
                    "rh_7m_1300_mod_monthly" : "rh_7m_1300_monthly_mod_PC",
                    "rh_7m_1300_med_monthly" : "rh_7m_1300_monthly_med_PC",
                    "rh_7m_1300_max_monthly" : "rh_7m_1300_monthly_max_PC",
                    "rh_7m_1300_min_monthly" : "rh_7m_1300_monthly_min_PC",
                    "rh_7m_1300_sum_monthly" : "rh_7m_1300_monthly_total_PC",
                    "rh_7m_1300_count_monthly" : "c_rh_7m_1300_monthly",
                    "rh_7m_1800_avg_monthly" : "rh_7m_1800_monthly_avg_PC",
                    "rh_7m_1800_mod_monthly" : "rh_7m_1800_monthly_mod_PC",
                    "rh_7m_1800_med_monthly" : "rh_7m_1800_monthly_med_PC",
                    "rh_7m_1800_max_monthly" : "rh_7m_1800_monthly_max_PC",
                    "rh_7m_1800_min_monthly" : "rh_7m_1800_monthly_min_PC",
                    "rh_7m_1800_sum_monthly" : "rh_7m_1800_monthly_total_PC",
                    "rh_7m_1800_count_monthly" : "c_rh_7m_1800_monthly",
                    "rh_10m_0700_avg_monthly" : "rh_10m_0700_monthly_avg_PC",
                    "rh_10m_0700_mod_monthly" : "rh_10m_0700_monthly_mod_PC",
                    "rh_10m_0700_med_monthly" : "rh_10m_0700_monthly_med_PC",
                    "rh_10m_0700_max_monthly" : "rh_10m_0700_monthly_max_PC",
                    "rh_10m_0700_min_monthly" : "rh_10m_0700_monthly_min_PC",
                    "rh_10m_0700_sum_monthly" : "rh_10m_0700_monthly_total_PC",
                    "rh_10m_0700_count_monthly" : "c_rh_10m_0700_monthly",
                    "rh_10m_1300_avg_monthly" : "rh_10m_1300_monthly_avg_PC",
                    "rh_10m_1300_mod_monthly" : "rh_10m_1300_monthly_mod_PC",
                    "rh_10m_1300_med_monthly" : "rh_10m_1300_monthly_med_PC",
                    "rh_10m_1300_max_monthly" : "rh_10m_1300_monthly_max_PC",
                    "rh_10m_1300_min_monthly" : "rh_10m_1300_monthly_min_PC",
                    "rh_10m_1300_sum_monthly" : "rh_10m_1300_monthly_total_PC",
                    "rh_10m_1300_count_monthly" : "c_rh_10m_1300_monthly",
                    "rh_10m_1800_avg_monthly" : "rh_10m_1800_monthly_avg_PC",
                    "rh_10m_1800_mod_monthly" : "rh_10m_1800_monthly_mod_PC",
                    "rh_10m_1800_med_monthly" : "rh_10m_1800_monthly_med_PC",
                    "rh_10m_1800_max_monthly" : "rh_10m_1800_monthly_max_PC",
                    "rh_10m_1800_min_monthly" : "rh_10m_1800_monthly_min_PC",
                    "rh_10m_1800_sum_monthly" : "rh_10m_1800_monthly_total_PC",
                    "rh_10m_1800_count_monthly" : "c_rh_10m_1800_monthly",               
                
                    "ws_avg_4_0713_avg_monthly" : "ws_avg_4_0713_monthly_avg",
                    "ws_avg_4_0713_mod_monthly" : "ws_avg_4_0713_monthly_mod",
                    "ws_avg_4_0713_med_monthly" : "ws_avg_4_0713_monthly_med",
                    "ws_avg_4_0713_max_monthly" : "ws_avg_4_0713_monthly_max",
                    "ws_avg_4_0713_min_monthly" : "ws_avg_4_0713_monthly_min",
                    "ws_avg_4_0713_sum_monthly" : "ws_avg_4_0713_monthly_total",
                    "ws_avg_4_0713_count_monthly" : "c_ws_avg_4_0713_monthly",
                    "ws_avg_7_0713_avg_monthly" : "ws_avg_7_0713_monthly_avg",
                    "ws_avg_7_0713_mod_monthly" : "ws_avg_7_0713_monthly_mod",
                    "ws_avg_7_0713_med_monthly" : "ws_avg_7_0713_monthly_med",
                    "ws_avg_7_0713_max_monthly" : "ws_avg_7_0713_monthly_max",
                    "ws_avg_7_0713_min_monthly" : "ws_avg_7_0713_monthly_min",
                    "ws_avg_7_0713_sum_monthly" : "ws_avg_7_0713_monthly_total",
                    "ws_avg_7_0713_count_monthly" : "c_ws_avg_7_0713_monthly",
                    "ws_avg_10_0713_avg_monthly" : "ws_avg_10_0713_monthly_avg",
                    "ws_avg_10_0713_mod_monthly" : "ws_avg_10_0713_monthly_mod",
                    "ws_avg_10_0713_med_monthly" : "ws_avg_10_0713_monthly_med",
                    "ws_avg_10_0713_max_monthly" : "ws_avg_10_0713_monthly_max",
                    "ws_avg_10_0713_min_monthly" : "ws_avg_10_0713_monthly_min",
                    "ws_avg_10_0713_sum_monthly" : "ws_avg_10_0713_monthly_total",
                    "ws_avg_10_0713_count_monthly" : "c_ws_avg_10_0713_monthly",
                    "ws_avg_4_1318_avg_monthly" : "ws_avg_4_1318_monthly_avg",
                    "ws_avg_4_1318_mod_monthly" : "ws_avg_4_1318_monthly_mod",
                    "ws_avg_4_1318_med_monthly" : "ws_avg_4_1318_monthly_med",
                    "ws_avg_4_1318_max_monthly" : "ws_avg_4_1318_monthly_max",
                    "ws_avg_4_1318_min_monthly" : "ws_avg_4_1318_monthly_min",
                    "ws_avg_4_1318_sum_monthly" : "ws_avg_4_1318_monthly_total",
                    "ws_avg_4_1318_count_monthly" : "c_ws_avg_4_1318_monthly",
                    "ws_avg_7_1318_avg_monthly" : "ws_avg_7_1318_monthly_avg",
                    "ws_avg_7_1318_mod_monthly" : "ws_avg_7_1318_monthly_mod",
                    "ws_avg_7_1318_med_monthly" : "ws_avg_7_1318_monthly_med",
                    "ws_avg_7_1318_max_monthly" : "ws_avg_7_1318_monthly_max",
                    "ws_avg_7_1318_min_monthly" : "ws_avg_7_1318_monthly_min",
                    "ws_avg_7_1318_sum_monthly" : "ws_avg_7_1318_monthly_total",
                    "ws_avg_7_1318_count_monthly" : "c_ws_avg_7_1318_monthly",
                    "ws_avg_10_1318_avg_monthly" : "ws_avg_10_1318_monthly_avg",
                    "ws_avg_10_1318_mod_monthly" : "ws_avg_10_1318_monthly_mod",
                    "ws_avg_10_1318_med_monthly" : "ws_avg_10_1318_monthly_med",
                    "ws_avg_10_1318_max_monthly" : "ws_avg_10_1318_monthly_max",
                    "ws_avg_10_1318_min_monthly" : "ws_avg_10_1318_monthly_min",
                    "ws_avg_10_1318_sum_monthly" : "ws_avg_10_1318_monthly_total",
                    "ws_avg_10_1318_count_monthly" : "c_ws_avg_10_1318_monthly",
                    "ws_avg_4_1807_avg_monthly" : "ws_avg_4_1807_monthly_avg",
                    "ws_avg_4_1807_mod_monthly" : "ws_avg_4_1807_monthly_mod",
                    "ws_avg_4_1807_med_monthly" : "ws_avg_4_1807_monthly_med",
                    "ws_avg_4_1807_max_monthly" : "ws_avg_4_1807_monthly_max",
                    "ws_avg_4_1807_min_monthly" : "ws_avg_4_1807_monthly_min",
                    "ws_avg_4_1807_sum_monthly" : "ws_avg_4_1807_monthly_total",
                    "ws_avg_4_1807_count_monthly" : "c_ws_avg_4_1807_monthly",
                    "ws_avg_7_1807_avg_monthly" : "ws_avg_7_1807_monthly_avg",
                    "ws_avg_7_1807_mod_monthly" : "ws_avg_7_1807_monthly_mod",
                    "ws_avg_7_1807_med_monthly" : "ws_avg_7_1807_monthly_med",
                    "ws_avg_7_1807_max_monthly" : "ws_avg_7_1807_monthly_max",
                    "ws_avg_7_1807_min_monthly" : "ws_avg_7_1807_monthly_min",
                    "ws_avg_7_1807_sum_monthly" : "ws_avg_7_1807_monthly_total",
                    "ws_avg_7_1807_count_monthly" : "c_ws_avg_7_1807_monthly",
                    "ws_avg_10_1807_avg_monthly" : "ws_avg_10_1807_monthly_avg",
                    "ws_avg_10_1807_mod_monthly" : "ws_avg_10_1807_monthly_mod",
                    "ws_avg_10_1807_med_monthly" : "ws_avg_10_1807_monthly_med",
                    "ws_avg_10_1807_max_monthly" : "ws_avg_10_1807_monthly_max",
                    "ws_avg_10_1807_min_monthly" : "ws_avg_10_1807_monthly_min",
                    "ws_avg_10_1807_sum_monthly" : "ws_avg_10_1807_monthly_total",
                    "ws_avg_10_1807_count_monthly" : "c_ws_avg_10_1807_monthly",
                },
            }
        ]
        return listAttr;
    },

    getAttrMonthlyDecimal() {
        const listAttr = [
            {
                label: "Suhu",
                attr: {
                    "tbk_4m_0700_avg_monthly" : 1,
                    "tbk_4m_0700_mod_monthly" : 1,
                    "tbk_4m_0700_med_monthly" : 1,
                    "tbk_4m_0700_max_monthly" : 1,
                    "tbk_4m_0700_min_monthly" : 1,
                    "tbk_4m_0700_sum_monthly" : 1,
                    "tbk_4m_0700_count_monthly" : 1,
                    "tbk_4m_1300_avg_monthly" : 1,
                    "tbk_4m_1300_mod_monthly" : 1,
                    "tbk_4m_1300_med_monthly" : 1,
                    "tbk_4m_1300_max_monthly" : 1,
                    "tbk_4m_1300_min_monthly" : 1,
                    "tbk_4m_1300_sum_monthly" : 1,
                    "tbk_4m_1300_count_monthly" : 1,
                    "tbk_4m_1800_avg_monthly" : 1,
                    "tbk_4m_1800_mod_monthly" : 1,
                    "tbk_4m_1800_med_monthly" : 1,
                    "tbk_4m_1800_max_monthly" : 1,
                    "tbk_4m_1800_min_monthly" : 1,
                    "tbk_4m_1800_sum_monthly" : 1,
                    "tbk_4m_1800_count_monthly" : 1,
                    "tbk_7m_0700_avg_monthly" : 1,
                    "tbk_7m_0700_mod_monthly" : 1,
                    "tbk_7m_0700_med_monthly" : 1,
                    "tbk_7m_0700_max_monthly" : 1,
                    "tbk_7m_0700_min_monthly" : 1,
                    "tbk_7m_0700_sum_monthly" : 1,
                    "tbk_7m_0700_count_monthly" : 1,
                    "tbk_7m_1300_avg_monthly" : 1,
                    "tbk_7m_1300_mod_monthly" : 1,
                    "tbk_7m_1300_med_monthly" : 1,
                    "tbk_7m_1300_max_monthly" : 1,
                    "tbk_7m_1300_min_monthly" : 1,
                    "tbk_7m_1300_sum_monthly" : 1,
                    "tbk_7m_1300_count_monthly" : 1,
                    "tbk_7m_1800_avg_monthly" : 1,
                    "tbk_7m_1800_mod_monthly" : 1,
                    "tbk_7m_1800_med_monthly" : 1,
                    "tbk_7m_1800_max_monthly" : 1,
                    "tbk_7m_1800_min_monthly" : 1,
                    "tbk_7m_1800_sum_monthly" : 1,
                    "tbk_7m_1800_count_monthly" : 1,
                    "tbk_10m_0700_avg_monthly" : 1,
                    "tbk_10m_0700_mod_monthly" : 1,
                    "tbk_10m_0700_med_monthly" : 1,
                    "tbk_10m_0700_max_monthly" : 1,
                    "tbk_10m_0700_min_monthly" : 1,
                    "tbk_10m_0700_sum_monthly" : 1,
                    "tbk_10m_0700_count_monthly" : 1,
                    "tbk_10m_1300_avg_monthly" : 1,
                    "tbk_10m_1300_mod_monthly" : 1,
                    "tbk_10m_1300_med_monthly" : 1,
                    "tbk_10m_1300_max_monthly" : 1,
                    "tbk_10m_1300_min_monthly" : 1,
                    "tbk_10m_1300_sum_monthly" : 1,
                    "tbk_10m_1300_count_monthly" : 1,
                    "tbk_10m_1800_avg_monthly" : 1,
                    "tbk_10m_1800_mod_monthly" : 1,
                    "tbk_10m_1800_med_monthly" : 1,
                    "tbk_10m_1800_max_monthly" : 1,
                    "tbk_10m_1800_min_monthly" : 1,
                    "tbk_10m_1800_sum_monthly" : 1,
                    "tbk_10m_1800_count_monthly" : 1,
                    "tbk_avg_4_avg_monthly" : 1,
                    "tbk_avg_4_mod_monthly" : 1,
                    "tbk_avg_4_med_monthly" : 1,
                    "tbk_avg_4_max_monthly" : 1,
                    "tbk_avg_4_min_monthly" : 1,
                    "tbk_avg_4_sum_monthly" : 1,
                    "tbk_avg_4_count_monthly" : 1,
                    "tbk_avg_7_avg_monthly" : 1,
                    "tbk_avg_7_mod_monthly" : 1,
                    "tbk_avg_7_med_monthly" : 1,
                    "tbk_avg_7_max_monthly" : 1,
                    "tbk_avg_7_min_monthly" : 1,
                    "tbk_avg_7_sum_monthly" : 1,
                    "tbk_avg_7_count_monthly" : 1,
                    "tbk_avg_10_avg_monthly" : 1,
                    "tbk_avg_10_mod_monthly" : 1,
                    "tbk_avg_10_med_monthly" : 1,
                    "tbk_avg_10_max_monthly" : 1,
                    "tbk_avg_10_min_monthly" : 1,
                    "tbk_avg_10_sum_monthly" : 1,
                    "tbk_avg_10_count_monthly" : 1,
                    "t_max_4m_avg_monthly" : 1,
                    "t_max_4m_mod_monthly" : 1,
                    "t_max_4m_med_monthly" : 1,
                    "t_max_4m_max_monthly" : 1,
                    "t_max_4m_min_monthly" : 1,
                    "t_max_4m_sum_monthly" : 1,
                    "t_max_4m_count_monthly" : 1,
                    "t_max_7m_avg_monthly" : 1,
                    "t_max_7m_mod_monthly" : 1,
                    "t_max_7m_med_monthly" : 1,
                    "t_max_7m_max_monthly" : 1,
                    "t_max_7m_min_monthly" : 1,
                    "t_max_7m_sum_monthly" : 1,
                    "t_max_7m_count_monthly" : 1,
                    "t_max_10m_avg_monthly" : 1,
                    "t_max_10m_mod_monthly" : 1,
                    "t_max_10m_med_monthly" : 1,
                    "t_max_10m_max_monthly" : 1,
                    "t_max_10m_min_monthly" : 1,
                    "t_max_10m_sum_monthly" : 1,
                    "t_max_10m_count_monthly" : 1,
                    "t_min_4m_avg_monthly" : 1,
                    "t_min_4m_mod_monthly" : 1,
                    "t_min_4m_med_monthly" : 1,
                    "t_min_4m_max_monthly" : 1,
                    "t_min_4m_min_monthly" : 1,
                    "t_min_4m_sum_monthly" : 1,
                    "t_min_4m_count_monthly" : 1,
                    "t_min_7m_avg_monthly" : 1,
                    "t_min_7m_mod_monthly" : 1,
                    "t_min_7m_med_monthly" : 1,
                    "t_min_7m_max_monthly" : 1,
                    "t_min_7m_min_monthly" : 1,
                    "t_min_7m_sum_monthly" : 1,
                    "t_min_7m_count_monthly" : 1,
                    "t_min_10m_avg_monthly" : 1,
                    "t_min_10m_mod_monthly" : 1,
                    "t_min_10m_med_monthly" : 1,
                    "t_min_10m_max_monthly" : 1,
                    "t_min_10m_min_monthly" : 1,
                    "t_min_10m_sum_monthly" : 1,
                    "t_min_10m_count_monthly" : 1, 
                
                    "rh_avg_4_avg_monthly" : 1,
                    "rh_avg_4_mod_monthly" : 1,
                    "rh_avg_4_med_monthly" : 1,
                    "rh_avg_4_max_monthly" : 1,
                    "rh_avg_4_min_monthly" : 1,
                    "rh_avg_4_sum_monthly" : 1,
                    "rh_avg_4_count_monthly" : 1,
                    "rh_avg_7_avg_monthly" : 1,
                    "rh_avg_7_mod_monthly" : 1,
                    "rh_avg_7_med_monthly" : 1,
                    "rh_avg_7_max_monthly" : 1,
                    "rh_avg_7_min_monthly" : 1,
                    "rh_avg_7_sum_monthly" : 1,
                    "rh_avg_7_count_monthly" : 1,
                    "rh_avg_10_avg_monthly" : 1,
                    "rh_avg_10_mod_monthly" : 1,
                    "rh_avg_10_med_monthly" : 1,
                    "rh_avg_10_max_monthly" : 1,
                    "rh_avg_10_min_monthly" : 1,
                    "rh_avg_10_sum_monthly" : 1,
                    "rh_avg_10_count_monthly" : 1,
                    "rh_4m_0700_avg_monthly" : 1,
                    "rh_4m_0700_mod_monthly" : 1,
                    "rh_4m_0700_med_monthly" : 1,
                    "rh_4m_0700_max_monthly" : 1,
                    "rh_4m_0700_min_monthly" : 1,
                    "rh_4m_0700_sum_monthly" : 1,
                    "rh_4m_0700_count_monthly" : 1,
                    "rh_4m_1300_avg_monthly" : 1,
                    "rh_4m_1300_mod_monthly" : 1,
                    "rh_4m_1300_med_monthly" : 1,
                    "rh_4m_1300_max_monthly" : 1,
                    "rh_4m_1300_min_monthly" : 1,
                    "rh_4m_1300_sum_monthly" : 1,
                    "rh_4m_1300_count_monthly" : 1,
                    "rh_4m_1800_avg_monthly" : 1,
                    "rh_4m_1800_mod_monthly" : 1,
                    "rh_4m_1800_med_monthly" : 1,
                    "rh_4m_1800_max_monthly" : 1,
                    "rh_4m_1800_min_monthly" : 1,
                    "rh_4m_1800_sum_monthly" : 1,
                    "rh_4m_1800_count_monthly" : 1,
                    "rh_7m_0700_avg_monthly" : 1,
                    "rh_7m_0700_mod_monthly" : 1,
                    "rh_7m_0700_med_monthly" : 1,
                    "rh_7m_0700_max_monthly" : 1,
                    "rh_7m_0700_min_monthly" : 1,
                    "rh_7m_0700_sum_monthly" : 1,
                    "rh_7m_0700_count_monthly" : 1,
                    "rh_7m_1300_avg_monthly" : 1,
                    "rh_7m_1300_mod_monthly" : 1,
                    "rh_7m_1300_med_monthly" : 1,
                    "rh_7m_1300_max_monthly" : 1,
                    "rh_7m_1300_min_monthly" : 1,
                    "rh_7m_1300_sum_monthly" : 1,
                    "rh_7m_1300_count_monthly" : 1,
                    "rh_7m_1800_avg_monthly" : 1,
                    "rh_7m_1800_mod_monthly" : 1,
                    "rh_7m_1800_med_monthly" : 1,
                    "rh_7m_1800_max_monthly" : 1,
                    "rh_7m_1800_min_monthly" : 1,
                    "rh_7m_1800_sum_monthly" : 1,
                    "rh_7m_1800_count_monthly" : 1,
                    "rh_10m_0700_avg_monthly" : 1,
                    "rh_10m_0700_mod_monthly" : 1,
                    "rh_10m_0700_med_monthly" : 1,
                    "rh_10m_0700_max_monthly" : 1,
                    "rh_10m_0700_min_monthly" : 1,
                    "rh_10m_0700_sum_monthly" : 1,
                    "rh_10m_0700_count_monthly" : 1,
                    "rh_10m_1300_avg_monthly" : 1,
                    "rh_10m_1300_mod_monthly" : 1,
                    "rh_10m_1300_med_monthly" : 1,
                    "rh_10m_1300_max_monthly" : 1,
                    "rh_10m_1300_min_monthly" : 1,
                    "rh_10m_1300_sum_monthly" : 1,
                    "rh_10m_1300_count_monthly" : 1,
                    "rh_10m_1800_avg_monthly" : 1,
                    "rh_10m_1800_mod_monthly" : 1,
                    "rh_10m_1800_med_monthly" : 1,
                    "rh_10m_1800_max_monthly" : 1,
                    "rh_10m_1800_min_monthly" : 1,
                    "rh_10m_1800_sum_monthly" : 1,
                    "rh_10m_1800_count_monthly" : 1,               
                
                    "ws_avg_4_0713_avg_monthly" : 1,
                    "ws_avg_4_0713_mod_monthly" : 1,
                    "ws_avg_4_0713_med_monthly" : 1,
                    "ws_avg_4_0713_max_monthly" : 1,
                    "ws_avg_4_0713_min_monthly" : 1,
                    "ws_avg_4_0713_sum_monthly" : 1,
                    "ws_avg_4_0713_count_monthly" : 1,
                    "ws_avg_7_0713_avg_monthly" : 1,
                    "ws_avg_7_0713_mod_monthly" : 1,
                    "ws_avg_7_0713_med_monthly" : 1,
                    "ws_avg_7_0713_max_monthly" : 1,
                    "ws_avg_7_0713_min_monthly" : 1,
                    "ws_avg_7_0713_sum_monthly" : 1,
                    "ws_avg_7_0713_count_monthly" : 1,
                    "ws_avg_10_0713_avg_monthly" : 1,
                    "ws_avg_10_0713_mod_monthly" : 1,
                    "ws_avg_10_0713_med_monthly" : 1,
                    "ws_avg_10_0713_max_monthly" : 1,
                    "ws_avg_10_0713_min_monthly" : 1,
                    "ws_avg_10_0713_sum_monthly" : 1,
                    "ws_avg_10_0713_count_monthly" : 1,
                    "ws_avg_4_1318_avg_monthly" : 1,
                    "ws_avg_4_1318_mod_monthly" : 1,
                    "ws_avg_4_1318_med_monthly" : 1,
                    "ws_avg_4_1318_max_monthly" : 1,
                    "ws_avg_4_1318_min_monthly" : 1,
                    "ws_avg_4_1318_sum_monthly" : 1,
                    "ws_avg_4_1318_count_monthly" : 1,
                    "ws_avg_7_1318_avg_monthly" : 1,
                    "ws_avg_7_1318_mod_monthly" : 1,
                    "ws_avg_7_1318_med_monthly" : 1,
                    "ws_avg_7_1318_max_monthly" : 1,
                    "ws_avg_7_1318_min_monthly" : 1,
                    "ws_avg_7_1318_sum_monthly" : 1,
                    "ws_avg_7_1318_count_monthly" : 1,
                    "ws_avg_10_1318_avg_monthly" : 1,
                    "ws_avg_10_1318_mod_monthly" : 1,
                    "ws_avg_10_1318_med_monthly" : 1,
                    "ws_avg_10_1318_max_monthly" : 1,
                    "ws_avg_10_1318_min_monthly" : 1,
                    "ws_avg_10_1318_sum_monthly" : 1,
                    "ws_avg_10_1318_count_monthly" : 1,
                    "ws_avg_4_1807_avg_monthly" : 1,
                    "ws_avg_4_1807_mod_monthly" : 1,
                    "ws_avg_4_1807_med_monthly" : 1,
                    "ws_avg_4_1807_max_monthly" : 1,
                    "ws_avg_4_1807_min_monthly" : 1,
                    "ws_avg_4_1807_sum_monthly" : 1,
                    "ws_avg_4_1807_count_monthly" : 1,
                    "ws_avg_7_1807_avg_monthly" : 1,
                    "ws_avg_7_1807_mod_monthly" : 1,
                    "ws_avg_7_1807_med_monthly" : 1,
                    "ws_avg_7_1807_max_monthly" : 1,
                    "ws_avg_7_1807_min_monthly" : 1,
                    "ws_avg_7_1807_sum_monthly" : 1,
                    "ws_avg_7_1807_count_monthly" : 1,
                    "ws_avg_10_1807_avg_monthly" : 1,
                    "ws_avg_10_1807_mod_monthly" : 1,
                    "ws_avg_10_1807_med_monthly" : 1,
                    "ws_avg_10_1807_max_monthly" : 1,
                    "ws_avg_10_1807_min_monthly" : 1,
                    "ws_avg_10_1807_sum_monthly" : 1,
                    "ws_avg_10_1807_count_monthly" : 1,
                },
            }
        ]
        return listAttr;
    }

    
    
}
