import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
    
      {
        label: "SO2NO2",
        attr: {
         "konsentrasi_so2":"konsentrasi SO2",
         "konsentrasi_no2":"konsentrasi NO2"
        },
      },
     
    //   {
    //     label: "Observer",
    //     attr: {
    //         "observer_id" : "ID observer",
    //         "observer_name" : "Nama observer "
    //         },
    // },
    ];

    return listAttr;
  },

  getAttrMonthly() {
    const listAttrMonthly = [
      {
        label: "SO2NO2",
        attr: {
          "konsentrasi_so2_avg_monthly": "Konsentrasi SO2 AVG MONTHLY",
          // "konsentrasi_so2_mod_monthly": "Konsentrasi SO2 MOD MONTHLY",
          // "konsentrasi_so2_med_monthly": "Konsentrasi SO2 MED MONTHLY",
          "konsentrasi_so2_max_monthly": "Konsentrasi SO2 MAX MONTHLY",
          "konsentrasi_so2_min_monthly": "Konsentrasi SO2 MIN MONTHLY",
          // "konsentrasi_so2_sum_monthly": "Konsentrasi SO2 SUM MONTHLY",
          "konsentrasi_so2_count_monthly": "Konsentrasi SO2 COUNT MONTHLY",
          "konsentrasi_no2_avg_monthly": "Konsentrasi NO2 AVG MONTHLY",
          // "konsentrasi_no2_mod_monthly": "Konsentrasi NO2 MOD MONTHLY",
          // "konsentrasi_no2_med_monthly": "Konsentrasi NO2 MED MONTHLY",
          "konsentrasi_no2_max_monthly": "Konsentrasi NO2 MAX MONTHLY",
          "konsentrasi_no2_min_monthly": "Konsentrasi NO2 MIN MONTHLY",
          // "konsentrasi_no2_sum_monthly": "Konsentrasi NO2 SUM MONTHLY",
          "konsentrasi_no2_count_monthly": "Konsentrasi NO2 COUNT MONTHLY"
        },
      }
    ];
    return listAttrMonthly;
  },

  getAttrMonthlyDecimal() {
    const listAttrMonthly = [
      {
        label: "SO2NO2",
        attr: {
          "konsentrasi_so2_avg_monthly": 2,
          // "konsentrasi_so2_mod_monthly": "Konsentrasi SO2 MOD MONTHLY",
          // "konsentrasi_so2_med_monthly": "Konsentrasi SO2 MED MONTHLY",
          "konsentrasi_so2_max_monthly": 2,
          "konsentrasi_so2_min_monthly": 2,
          // "konsentrasi_so2_sum_monthly": "Konsentrasi SO2 SUM MONTHLY",
          "konsentrasi_so2_count_monthly": 2,
          "konsentrasi_no2_avg_monthly": 2,
          // "konsentrasi_no2_mod_monthly": "Konsentrasi NO2 MOD MONTHLY",
          // "konsentrasi_no2_med_monthly": "Konsentrasi NO2 MED MONTHLY",
          "konsentrasi_no2_max_monthly": 2,
          "konsentrasi_no2_min_monthly": 2,
          // "konsentrasi_no2_sum_monthly": "Konsentrasi NO2 SUM MONTHLY",
          "konsentrasi_no2_count_monthly": 2
        },
      }
    ];
    return listAttrMonthly;
  },

  getAttrMonthlyCsv() {
    const listAttrMonthly = [
      {
        label: "SO2NO2",
        attr: {
          "konsentrasi_so2_avg_monthly": "konsentrasi_SO2_monthly_ave",
          "konsentrasi_so2_mod_monthly": "konsentrasi_SO2_monthly_mod",
          "konsentrasi_so2_med_monthly": "konsentrasi_SO2_monthly_med",
          "konsentrasi_so2_max_monthly": "konsentrasi_SO2_monthly_max",
          "konsentrasi_so2_min_monthly": "konsentrasi_SO2_monthly_min",
          "konsentrasi_so2_sum_monthly": "konsentrasi_SO2_monthly_sum",
          "konsentrasi_so2_count_monthly": "count_konsentrasi_SO2_monthly",
          "konsentrasi_no2_avg_monthly": "konsentrasi_NO2_monthly_ave",
          "konsentrasi_no2_mod_monthly": "konsentrasi_NO2_monthly_mod",
          "konsentrasi_no2_med_monthly": "konsentrasi_NO2_monthly_med",
          "konsentrasi_no2_max_monthly": "konsentrasi_NO2_monthly_max",
          "konsentrasi_no2_min_monthly": "konsentrasi_NO2_monthly_min",
          "konsentrasi_no2_sum_monthly": "konsentrasi_NO2_monthly_sum",
          "konsentrasi_no2_count_monthly": "count_konsentrasi_NO2_monthly"
        },
      }
    ];
    return listAttrMonthly;
  }

};
