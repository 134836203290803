import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      
      {
        label: "SPM",
        attr: {
          "spm_konsentrasi":"Konsentrasi SPM",
          // "spm_bobot_filter_kosong":"Bobot Filter Kosong",
          // "spm_bobot_filter_sampling":"Bobot Filter Sampling",
          // "spm_flow_rate_awal":"Flow Rate Awal",
          // "spm_flow_rate_akhir":"Flow Rate Akhir",
          // "spm_total_waktu_sampling":"Total Waktu Sampling (menit)",
          // "spm_tekanan_udara_awal":"Tekanan udara awal",
          // "spm_tekanan_udara_akhir":"Tekanan udara akhir",
          // "spm_temperatur_awal":"Temperature awal",
          // "spm_temperatur_akhir":"Temperature akhir"
        },
      },
      
    //   {
    //     label: "Observer",
    //     attr: {
    //         "observer_id" : "ID observer",
    //         "observer_name" : "Nama observer "
    //         },
    // },
    ];

    return listAttr;
  },

  getAttrMonthly() {
    const listAttrMonthly = [
      {
        label: "SPM",
        attr: {
          // "spm_bobot_filter_kosong_avg_monthly": "Bobot Filter Kosong AVG MONTHLY",
          // "spm_bobot_filter_kosong_mod_monthly": "Bobot Filter Kosong MOD MONTHLY",
          // "spm_bobot_filter_kosong_med_monthly": "Bobot Filter Kosong MED MONTHLY",
          // "spm_bobot_filter_kosong_max_monthly": "Bobot Filter Kosong MAX MONTHLY",
          // "spm_bobot_filter_kosong_min_monthly": "Bobot Filter Kosong MIN MONTHLY",
          // "spm_bobot_filter_kosong_sum_monthly": "Bobot Filter Kosong SUM MONTHLY",
          // "spm_bobot_filter_kosong_count_monthly": "Bobot Filter Kosong COUNT MONTHLY",
          // "spm_bobot_filter_sampling_avg_monthly": "Bobot Filter Sampling AVG MONTHLY",
          // "spm_bobot_filter_sampling_mod_monthly": "Bobot Filter Sampling MOD MONTHLY",
          // "spm_bobot_filter_sampling_med_monthly": "Bobot Filter Sampling MED MONTHLY",
          // "spm_bobot_filter_sampling_max_monthly": "Bobot Filter Sampling MAX MONTHLY",
          // "spm_bobot_filter_sampling_min_monthly": "Bobot Filter Sampling MIN MONTHLY",
          // "spm_bobot_filter_sampling_sum_monthly": "Bobot Filter Sampling SUM MONTHLY",
          // "spm_bobot_filter_sampling_count_monthly": "Bobot Filter Sampling COUNT MONTHLY",
          "spm_konsentrasi_avg_monthly": "Konsentrasi SPM AVG MONTHLY",
          // "spm_konsentrasi_mod_monthly": "Konsentrasi SPM MOD MONTHLY",
          // "spm_konsentrasi_med_monthly": "Konsentrasi SPM MED MONTHLY",
          "spm_konsentrasi_max_monthly": "Konsentrasi SPM MAX MONTHLY",
          "spm_konsentrasi_min_monthly": "Konsentrasi SPM MIN MONTHLY",
          // "spm_konsentrasi_sum_monthly": "Konsentrasi SPM SUM MONTHLY",
          "spm_konsentrasi_count_monthly": "Konsentrasi SPM COUNT MONTHLY",
          // "spm_flow_rate_awal_avg_monthly": "Flow Rate Awal AVG MONTHLY",
          // "spm_flow_rate_awal_mod_monthly": "Flow Rate Awal MOD MONTHLY",
          // "spm_flow_rate_awal_med_monthly": "Flow Rate Awal MED MONTHLY",
          // "spm_flow_rate_awal_max_monthly": "Flow Rate Awal MAX MONTHLY",
          // "spm_flow_rate_awal_min_monthly": "Flow Rate Awal MIN MONTHLY",
          // "spm_flow_rate_awal_sum_monthly": "Flow Rate Awal SUM MONTHLY",
          // "spm_flow_rate_awal_count_monthly": "Flow Rate Awal COUNT MONTHLY",
          // "spm_flow_rate_akhir_avg_monthly": "Flow Rate Akhir AVG MONTHLY",
          // "spm_flow_rate_akhir_mod_monthly": "Flow Rate Akhir MOD MONTHLY",
          // "spm_flow_rate_akhir_med_monthly": "Flow Rate Akhir MED MONTHLY",
          // "spm_flow_rate_akhir_max_monthly": "Flow Rate Akhir MAX MONTHLY",
          // "spm_flow_rate_akhir_min_monthly": "Flow Rate Akhir MIN MONTHLY",
          // "spm_flow_rate_akhir_sum_monthly": "Flow Rate Akhir SUM MONTHLY",
          // "spm_flow_rate_akhir_count_monthly": "Flow Rate Akhir COUNT MONTHLY",
          // "spm_total_waktu_sampling_avg_monthly": "Total Waktu Sampling AVG MONTHLY",
          // "spm_total_waktu_sampling_mod_monthly": "Total Waktu Sampling MOD MONTHLY",
          // "spm_total_waktu_sampling_med_monthly": "Total Waktu Sampling MED MONTHLY",
          // "spm_total_waktu_sampling_max_monthly": "Total Waktu Sampling MAX MONTHLY",
          // "spm_total_waktu_sampling_min_monthly": "Total Waktu Sampling MIN MONTHLY",
          // "spm_total_waktu_sampling_sum_monthly": "Total Waktu Sampling SUN MONTHLY",
          // "spm_total_waktu_sampling_count_monthly": "Total Waktu Sampling COUNT MONTHLY",
          // "spm_tekanan_udara_awal_avg_monthly": "Tekanan Udara Awal AVG MONTHLY",
          // "spm_tekanan_udara_awal_mod_monthly": "Tekanan Udara Awal MOD MONTHLY",
          // "spm_tekanan_udara_awal_med_monthly": "Tekanan Udara Awal MED MONTHLY",
          // "spm_tekanan_udara_awal_max_monthly": "Tekanan Udara Awal MAX MONTHLY",
          // "spm_tekanan_udara_awal_min_monthly": "Tekanan Udara Awal MIN MONTHLY",
          // "spm_tekanan_udara_awal_sum_monthly": "Tekanan Udara Awal SUM MONTHLY",
          // "spm_tekanan_udara_awal_count_monthly": "Tekanan Udara Awal COUNT MONTHLY",
          // "spm_tekanan_udara_akhir_avg_monthly": "Tekanan Udara Akhir AVG MONTHLY",
          // "spm_tekanan_udara_akhir_mod_monthly": "Tekanan Udara Akhir MOD MONTHLY",
          // "spm_tekanan_udara_akhir_med_monthly": "Tekanan Udara Akhir MED MONTHLY",
          // "spm_tekanan_udara_akhir_max_monthly": "Tekanan Udara Akhir MAX MONTHLY",
          // "spm_tekanan_udara_akhir_min_monthly": "Tekanan Udara Akhir MIN MONTHLY",
          // "spm_tekanan_udara_akhir_sum_monthly": "Tekanan Udara Akhir SUM MONTHLY",
          // "spm_tekanan_udara_akhir_count_monthly": "Tekanan Udara Akhir COUNT MONTHLY",
          // "spm_temperatur_awal_avg_monthly": "Temperatur Awal AVG MONTHLY",
          // "spm_temperatur_awal_mod_monthly": "Temperatur Awal MOD MONTHLY",
          // "spm_temperatur_awal_med_monthly": "Temperatur Awal MED MONTHLY",
          // "spm_temperatur_awal_max_monthly": "Temperatur Awal MAX MONTHLY",
          // "spm_temperatur_awal_min_monthly": "Temperatur Awal MIN MONTHLY",
          // "spm_temperatur_awal_sum_monthly": "Temperatur Awal SUM MONTHLY",
          // "spm_temperatur_awal_count_monthly": "Temperatur Awal COUNT MONTHLY",
          // "spm_temperatur_akhir_avg_monthly": "Temperatur Akhir AVG MONTHLY",
          // "spm_temperatur_akhir_mod_monthly": "Temperatur Akhir MOD MONTHLY",
          // "spm_temperatur_akhir_med_monthly": "Temperatur Akhir MED MONTHLY",
          // "spm_temperatur_akhir_max_monthly": "Temperatur Akhir MAX MONTHLY",
          // "spm_temperatur_akhir_min_monthly": "Temperatur Akhir MIN MONTHLY",
          // "spm_temperatur_akhir_sum_monthly": "Temperatur Akhir SUM MONTHLY",
          // "spm_temperatur_akhir_count_monthly": "Temperatur Akhir COUNT MONTHLY",
          // "spm_flow_rate_awal_m3_avg_monthly": "Flow Rate Awal M3 AVG MONTHLY",
          // "spm_flow_rate_awal_m3_mod_monthly": "Flow Rate Awal M3 MOD MONTHLY",
          // "spm_flow_rate_awal_m3_med_monthly": "Flow Rate Awal M3 MED MONTHLY",
          // "spm_flow_rate_awal_m3_max_monthly": "Flow Rate Awal M3 MAX MONTHLY",
          // "spm_flow_rate_awal_m3_min_monthly": "Flow Rate Awal M3 MIN MONTHLY",
          // "spm_flow_rate_awal_m3_sum_monthly": "Flow Rate Awal M3 SUM MONTHLY",
          // "spm_flow_rate_awal_m3_count_monthly": "Flow Rate Awal M3 COUNT MONTHLY",
          // "spm_flow_rate_awal_ft3_avg_monthly": "Flow Rate Awal FT3 AVG MONTHLY",
          // "spm_flow_rate_awal_ft3_mod_monthly": "Flow Rate Awal FT3 MOD MONTHLY",
          // "spm_flow_rate_awal_ft3_med_monthly": "Flow Rate Awal FT3 MED MONTHLY",
          // "spm_flow_rate_awal_ft3_max_monthly": "Flow Rate Awal FT3 MAX MONTHLY",
          // "spm_flow_rate_awal_ft3_min_monthly": "Flow Rate Awal FT3 MIN MONTHLY",
          // "spm_flow_rate_awal_ft3_sum_monthly": "Flow Rate Awal FT3 SUM MONTHLY",
          // "spm_flow_rate_awal_ft3_count_monthly": "Flow Rate Awal FT3 COUNT MONTHLY",
          // "spm_flow_rate_akhir_m3_avg_monthly": "Flow Rate Akhir M3 AVG MONTHLY",
          // "spm_flow_rate_akhir_m3_mod_monthly": "Flow Rate Akhir M3 MOD MONTHLY",
          // "spm_flow_rate_akhir_m3_med_monthly": "Flow Rate Akhir M3 MED MONTHLY",
          // "spm_flow_rate_akhir_m3_max_monthly": "Flow Rate Akhir M3 MAX MONTHLY",
          // "spm_flow_rate_akhir_m3_min_monthly": "Flow Rate Akhir M3 MIN MONTHLY",
          // "spm_flow_rate_akhir_m3_sum_monthly": "Flow Rate Akhir M3 SUM MONTHLY",
          // "spm_flow_rate_akhir_m3_count_monthly": "Flow Rate Akhir M3 COUNT MONTHLY",
          // "spm_flow_rate_akhir_ft3_avg_monthly": "Flow Rate Akhir FT3 AVG MONTHLY",
          // "spm_flow_rate_akhir_ft3_mod_monthly": "Flow Rate Akhir FT3 MOD MONTHLY",
          // "spm_flow_rate_akhir_ft3_med_monthly": "Flow Rate Akhir FT3 MED MONTHLY",
          // "spm_flow_rate_akhir_ft3_max_monthly": "Flow Rate Akhir FT3 MAX MONTHLY",
          // "spm_flow_rate_akhir_ft3_min_monthly": "Flow Rate Akhir FT3 MIN MONTHLY",
          // "spm_flow_rate_akhir_ft3_sum_monthly": "Flow Rate Akhir FT3 SUM MONTHLY",
          // "spm_flow_rate_akhir_ft3_count_monthly": "Flow Rate Akhir FT3 COUNT MONTHLY"
        },
      }
    ];
    return listAttrMonthly;
  },

  getAttrMonthlyDecimal() {
    const listAttrMonthly = [
      {
        label: "SPM",
        attr: {
          // "spm_bobot_filter_kosong_avg_monthly": "Bobot Filter Kosong AVG MONTHLY",
          // "spm_bobot_filter_kosong_mod_monthly": "Bobot Filter Kosong MOD MONTHLY",
          // "spm_bobot_filter_kosong_med_monthly": "Bobot Filter Kosong MED MONTHLY",
          // "spm_bobot_filter_kosong_max_monthly": "Bobot Filter Kosong MAX MONTHLY",
          // "spm_bobot_filter_kosong_min_monthly": "Bobot Filter Kosong MIN MONTHLY",
          // "spm_bobot_filter_kosong_sum_monthly": "Bobot Filter Kosong SUM MONTHLY",
          // "spm_bobot_filter_kosong_count_monthly": "Bobot Filter Kosong COUNT MONTHLY",
          // "spm_bobot_filter_sampling_avg_monthly": "Bobot Filter Sampling AVG MONTHLY",
          // "spm_bobot_filter_sampling_mod_monthly": "Bobot Filter Sampling MOD MONTHLY",
          // "spm_bobot_filter_sampling_med_monthly": "Bobot Filter Sampling MED MONTHLY",
          // "spm_bobot_filter_sampling_max_monthly": "Bobot Filter Sampling MAX MONTHLY",
          // "spm_bobot_filter_sampling_min_monthly": "Bobot Filter Sampling MIN MONTHLY",
          // "spm_bobot_filter_sampling_sum_monthly": "Bobot Filter Sampling SUM MONTHLY",
          // "spm_bobot_filter_sampling_count_monthly": "Bobot Filter Sampling COUNT MONTHLY",
          "spm_konsentrasi_avg_monthly": 0,
          // "spm_konsentrasi_mod_monthly": "Konsentrasi SPM MOD MONTHLY",
          // "spm_konsentrasi_med_monthly": "Konsentrasi SPM MED MONTHLY",
          "spm_konsentrasi_max_monthly": 0,
          "spm_konsentrasi_min_monthly": 0,
          // "spm_konsentrasi_sum_monthly": "Konsentrasi SPM SUM MONTHLY",
          "spm_konsentrasi_count_monthly": 0,
          // "spm_flow_rate_awal_avg_monthly": "Flow Rate Awal AVG MONTHLY",
          // "spm_flow_rate_awal_mod_monthly": "Flow Rate Awal MOD MONTHLY",
          // "spm_flow_rate_awal_med_monthly": "Flow Rate Awal MED MONTHLY",
          // "spm_flow_rate_awal_max_monthly": "Flow Rate Awal MAX MONTHLY",
          // "spm_flow_rate_awal_min_monthly": "Flow Rate Awal MIN MONTHLY",
          // "spm_flow_rate_awal_sum_monthly": "Flow Rate Awal SUM MONTHLY",
          // "spm_flow_rate_awal_count_monthly": "Flow Rate Awal COUNT MONTHLY",
          // "spm_flow_rate_akhir_avg_monthly": "Flow Rate Akhir AVG MONTHLY",
          // "spm_flow_rate_akhir_mod_monthly": "Flow Rate Akhir MOD MONTHLY",
          // "spm_flow_rate_akhir_med_monthly": "Flow Rate Akhir MED MONTHLY",
          // "spm_flow_rate_akhir_max_monthly": "Flow Rate Akhir MAX MONTHLY",
          // "spm_flow_rate_akhir_min_monthly": "Flow Rate Akhir MIN MONTHLY",
          // "spm_flow_rate_akhir_sum_monthly": "Flow Rate Akhir SUM MONTHLY",
          // "spm_flow_rate_akhir_count_monthly": "Flow Rate Akhir COUNT MONTHLY",
          // "spm_total_waktu_sampling_avg_monthly": "Total Waktu Sampling AVG MONTHLY",
          // "spm_total_waktu_sampling_mod_monthly": "Total Waktu Sampling MOD MONTHLY",
          // "spm_total_waktu_sampling_med_monthly": "Total Waktu Sampling MED MONTHLY",
          // "spm_total_waktu_sampling_max_monthly": "Total Waktu Sampling MAX MONTHLY",
          // "spm_total_waktu_sampling_min_monthly": "Total Waktu Sampling MIN MONTHLY",
          // "spm_total_waktu_sampling_sum_monthly": "Total Waktu Sampling SUN MONTHLY",
          // "spm_total_waktu_sampling_count_monthly": "Total Waktu Sampling COUNT MONTHLY",
          // "spm_tekanan_udara_awal_avg_monthly": "Tekanan Udara Awal AVG MONTHLY",
          // "spm_tekanan_udara_awal_mod_monthly": "Tekanan Udara Awal MOD MONTHLY",
          // "spm_tekanan_udara_awal_med_monthly": "Tekanan Udara Awal MED MONTHLY",
          // "spm_tekanan_udara_awal_max_monthly": "Tekanan Udara Awal MAX MONTHLY",
          // "spm_tekanan_udara_awal_min_monthly": "Tekanan Udara Awal MIN MONTHLY",
          // "spm_tekanan_udara_awal_sum_monthly": "Tekanan Udara Awal SUM MONTHLY",
          // "spm_tekanan_udara_awal_count_monthly": "Tekanan Udara Awal COUNT MONTHLY",
          // "spm_tekanan_udara_akhir_avg_monthly": "Tekanan Udara Akhir AVG MONTHLY",
          // "spm_tekanan_udara_akhir_mod_monthly": "Tekanan Udara Akhir MOD MONTHLY",
          // "spm_tekanan_udara_akhir_med_monthly": "Tekanan Udara Akhir MED MONTHLY",
          // "spm_tekanan_udara_akhir_max_monthly": "Tekanan Udara Akhir MAX MONTHLY",
          // "spm_tekanan_udara_akhir_min_monthly": "Tekanan Udara Akhir MIN MONTHLY",
          // "spm_tekanan_udara_akhir_sum_monthly": "Tekanan Udara Akhir SUM MONTHLY",
          // "spm_tekanan_udara_akhir_count_monthly": "Tekanan Udara Akhir COUNT MONTHLY",
          // "spm_temperatur_awal_avg_monthly": "Temperatur Awal AVG MONTHLY",
          // "spm_temperatur_awal_mod_monthly": "Temperatur Awal MOD MONTHLY",
          // "spm_temperatur_awal_med_monthly": "Temperatur Awal MED MONTHLY",
          // "spm_temperatur_awal_max_monthly": "Temperatur Awal MAX MONTHLY",
          // "spm_temperatur_awal_min_monthly": "Temperatur Awal MIN MONTHLY",
          // "spm_temperatur_awal_sum_monthly": "Temperatur Awal SUM MONTHLY",
          // "spm_temperatur_awal_count_monthly": "Temperatur Awal COUNT MONTHLY",
          // "spm_temperatur_akhir_avg_monthly": "Temperatur Akhir AVG MONTHLY",
          // "spm_temperatur_akhir_mod_monthly": "Temperatur Akhir MOD MONTHLY",
          // "spm_temperatur_akhir_med_monthly": "Temperatur Akhir MED MONTHLY",
          // "spm_temperatur_akhir_max_monthly": "Temperatur Akhir MAX MONTHLY",
          // "spm_temperatur_akhir_min_monthly": "Temperatur Akhir MIN MONTHLY",
          // "spm_temperatur_akhir_sum_monthly": "Temperatur Akhir SUM MONTHLY",
          // "spm_temperatur_akhir_count_monthly": "Temperatur Akhir COUNT MONTHLY",
          // "spm_flow_rate_awal_m3_avg_monthly": "Flow Rate Awal M3 AVG MONTHLY",
          // "spm_flow_rate_awal_m3_mod_monthly": "Flow Rate Awal M3 MOD MONTHLY",
          // "spm_flow_rate_awal_m3_med_monthly": "Flow Rate Awal M3 MED MONTHLY",
          // "spm_flow_rate_awal_m3_max_monthly": "Flow Rate Awal M3 MAX MONTHLY",
          // "spm_flow_rate_awal_m3_min_monthly": "Flow Rate Awal M3 MIN MONTHLY",
          // "spm_flow_rate_awal_m3_sum_monthly": "Flow Rate Awal M3 SUM MONTHLY",
          // "spm_flow_rate_awal_m3_count_monthly": "Flow Rate Awal M3 COUNT MONTHLY",
          // "spm_flow_rate_awal_ft3_avg_monthly": "Flow Rate Awal FT3 AVG MONTHLY",
          // "spm_flow_rate_awal_ft3_mod_monthly": "Flow Rate Awal FT3 MOD MONTHLY",
          // "spm_flow_rate_awal_ft3_med_monthly": "Flow Rate Awal FT3 MED MONTHLY",
          // "spm_flow_rate_awal_ft3_max_monthly": "Flow Rate Awal FT3 MAX MONTHLY",
          // "spm_flow_rate_awal_ft3_min_monthly": "Flow Rate Awal FT3 MIN MONTHLY",
          // "spm_flow_rate_awal_ft3_sum_monthly": "Flow Rate Awal FT3 SUM MONTHLY",
          // "spm_flow_rate_awal_ft3_count_monthly": "Flow Rate Awal FT3 COUNT MONTHLY",
          // "spm_flow_rate_akhir_m3_avg_monthly": "Flow Rate Akhir M3 AVG MONTHLY",
          // "spm_flow_rate_akhir_m3_mod_monthly": "Flow Rate Akhir M3 MOD MONTHLY",
          // "spm_flow_rate_akhir_m3_med_monthly": "Flow Rate Akhir M3 MED MONTHLY",
          // "spm_flow_rate_akhir_m3_max_monthly": "Flow Rate Akhir M3 MAX MONTHLY",
          // "spm_flow_rate_akhir_m3_min_monthly": "Flow Rate Akhir M3 MIN MONTHLY",
          // "spm_flow_rate_akhir_m3_sum_monthly": "Flow Rate Akhir M3 SUM MONTHLY",
          // "spm_flow_rate_akhir_m3_count_monthly": "Flow Rate Akhir M3 COUNT MONTHLY",
          // "spm_flow_rate_akhir_ft3_avg_monthly": "Flow Rate Akhir FT3 AVG MONTHLY",
          // "spm_flow_rate_akhir_ft3_mod_monthly": "Flow Rate Akhir FT3 MOD MONTHLY",
          // "spm_flow_rate_akhir_ft3_med_monthly": "Flow Rate Akhir FT3 MED MONTHLY",
          // "spm_flow_rate_akhir_ft3_max_monthly": "Flow Rate Akhir FT3 MAX MONTHLY",
          // "spm_flow_rate_akhir_ft3_min_monthly": "Flow Rate Akhir FT3 MIN MONTHLY",
          // "spm_flow_rate_akhir_ft3_sum_monthly": "Flow Rate Akhir FT3 SUM MONTHLY",
          // "spm_flow_rate_akhir_ft3_count_monthly": "Flow Rate Akhir FT3 COUNT MONTHLY"
        },
      }
    ];
    return listAttrMonthly;
  },


  getAttrMonthlyCsv() {
    const listAttrMonthly = [
      {
        label: "SPM",
        attr: {
          "spm_bobot_filter_kosong_avg_monthly": "spm_bobot_filter_kosong_monthly_ave",
          "spm_bobot_filter_kosong_mod_monthly": "spm_bobot_filter_kosong_monthly_mod",
          "spm_bobot_filter_kosong_med_monthly": "spm_bobot_filter_kosong_monthly_med",
          "spm_bobot_filter_kosong_max_monthly": "spm_bobot_filter_kosong_monthly_max",
          "spm_bobot_filter_kosong_min_monthly": "spm_bobot_filter_kosong_monthly_min",
          "spm_bobot_filter_kosong_sum_monthly": "spm_bobot_filter_kosong_monthly_sum",
          "spm_bobot_filter_kosong_count_monthly": "count_spm_bobot_filter_kosong_monthly",
          "spm_bobot_filter_sampling_avg_monthly": "spm_bobot_filter_sampling_monthly_ave",
          "spm_bobot_filter_sampling_mod_monthly": "spm_bobot_filter_sampling_monthly_mod",
          "spm_bobot_filter_sampling_med_monthly": "spm_bobot_filter_sampling_monthly_med",
          "spm_bobot_filter_sampling_max_monthly": "spm_bobot_filter_sampling_monthly_max",
          "spm_bobot_filter_sampling_min_monthly": "spm_bobot_filter_sampling_monthly_min",
          "spm_bobot_filter_sampling_sum_monthly": "spm_bobot_filter_sampling_monthly_sum",
          "spm_bobot_filter_sampling_count_monthly": "count_spm_bobot_filter_kosong_monthly",
          "spm_konsentrasi_avg_monthly": "konsentrasi_spm_monthly_ave",
          "spm_konsentrasi_mod_monthly": "konsentrasi_spm_monthly_mod",
          "spm_konsentrasi_med_monthly": "konsentrasi_spm_monthly_med",
          "spm_konsentrasi_max_monthly": "konsentrasi_spm_monthly_max",
          "spm_konsentrasi_min_monthly": "konsentrasi_spm_monthly_min",
          "spm_konsentrasi_sum_monthly": "konsentrasi_spm_monthly_ave",
          "spm_konsentrasi_count_monthly": "count_konsentrasi_spm_monthly",
          "spm_flow_rate_awal_avg_monthly": "spm_flow_rate_awal_monthly_ave",
          "spm_flow_rate_awal_mod_monthly": "spm_flow_rate_awal_monthly_mod",
          "spm_flow_rate_awal_med_monthly": "spm_flow_rate_awal_monthly_med",
          "spm_flow_rate_awal_max_monthly": "spm_flow_rate_awal_monthly_max",
          "spm_flow_rate_awal_min_monthly": "spm_flow_rate_awal_monthly_min",
          "spm_flow_rate_awal_sum_monthly": "spm_flow_rate_awal_monthly_sum",
          "spm_flow_rate_awal_count_monthly": "count_spm_flow_rate_awal_monthly",
          "spm_flow_rate_akhir_avg_monthly": "spm_flow_rate_akhir_monthly_ave",
          "spm_flow_rate_akhir_mod_monthly": "spm_flow_rate_akhir_monthly_mod",
          "spm_flow_rate_akhir_med_monthly": "spm_flow_rate_akhir_monthly_med",
          "spm_flow_rate_akhir_max_monthly": "spm_flow_rate_akhir_monthly_max",
          "spm_flow_rate_akhir_min_monthly": "spm_flow_rate_akhir_monthly_min",
          "spm_flow_rate_akhir_sum_monthly": "spm_flow_rate_akhir_monthly_sum",
          "spm_flow_rate_akhir_count_monthly": "count_spm_flow_rate_akhir_monthly",
          "spm_total_waktu_sampling_avg_monthly": "spm_total_waktu_sampling_monthly_ave",
          "spm_total_waktu_sampling_mod_monthly": "spm_total_waktu_sampling_monthly_mod",
          "spm_total_waktu_sampling_med_monthly": "spm_total_waktu_sampling_monthly_med",
          "spm_total_waktu_sampling_max_monthly": "spm_total_waktu_sampling_monthly_max",
          "spm_total_waktu_sampling_min_monthly": "spm_total_waktu_sampling_monthly_min",
          "spm_total_waktu_sampling_sum_monthly": "spm_total_waktu_sampling_monthly_sum",
          "spm_total_waktu_sampling_count_monthly": "count_spm_total_waktu_sampling_monthly",
          "spm_tekanan_udara_awal_avg_monthly": "spm_tekanan_udara_awal_monthly_ave",
          "spm_tekanan_udara_awal_mod_monthly": "spm_tekanan_udara_awal_monthly_mod",
          "spm_tekanan_udara_awal_med_monthly": "spm_tekanan_udara_awal_monthly_med",
          "spm_tekanan_udara_awal_max_monthly": "spm_tekanan_udara_awal_monthly_max",
          "spm_tekanan_udara_awal_min_monthly": "spm_tekanan_udara_awal_monthly_min",
          "spm_tekanan_udara_awal_sum_monthly": "spm_tekanan_udara_awal_monthly_sum",
          "spm_tekanan_udara_awal_count_monthly": "count_spm_tekanan_udara_awal_monthly",
          "spm_tekanan_udara_akhir_avg_monthly": "spm_tekanan_udara_akhir_monthly_ave",
          "spm_tekanan_udara_akhir_mod_monthly": "spm_tekanan_udara_akhir_monthly_mod",
          "spm_tekanan_udara_akhir_med_monthly": "spm_tekanan_udara_akhir_monthly_med",
          "spm_tekanan_udara_akhir_max_monthly": "spm_tekanan_udara_akhir_monthly_max",
          "spm_tekanan_udara_akhir_min_monthly": "spm_tekanan_udara_akhir_monthly_min",
          "spm_tekanan_udara_akhir_sum_monthly": "spm_tekanan_udara_akhir_monthly_sum",
          "spm_tekanan_udara_akhir_count_monthly": "count_spm_tekanan_udara_akhir_monthly",
          "spm_temperatur_awal_avg_monthly": "spm_temperatur_awal_monthly_ave",
          "spm_temperatur_awal_mod_monthly": "spm_temperatur_awal_monthly_mod",
          "spm_temperatur_awal_med_monthly": "spm_temperatur_awal_monthly_med",
          "spm_temperatur_awal_max_monthly": "spm_temperatur_awal_monthly_max",
          "spm_temperatur_awal_min_monthly": "spm_temperatur_awal_monthly_min",
          "spm_temperatur_awal_sum_monthly": "spm_temperatur_awal_monthly_sum",
          "spm_temperatur_awal_count_monthly": "count_spm_temperatur_awal_monthly",
          "spm_temperatur_akhir_avg_monthly": "spm_temperatur_akhir_monthly_ave",
          "spm_temperatur_akhir_mod_monthly": "spm_temperatur_akhir_monthly_mod",
          "spm_temperatur_akhir_med_monthly": "spm_temperatur_akhir_monthly_med",
          "spm_temperatur_akhir_max_monthly": "spm_temperatur_akhir_monthly_max",
          "spm_temperatur_akhir_min_monthly": "spm_temperatur_akhir_monthly_min",
          "spm_temperatur_akhir_sum_monthly": "spm_temperatur_akhir_monthly_sum",
          "spm_temperatur_akhir_count_monthly": "count_spm_temperatur_akhir_monthly",
          "spm_flow_rate_awal_m3_avg_monthly": "spm_flow_rate_awal_m3_monthly_ave",
          "spm_flow_rate_awal_m3_mod_monthly": "spm_flow_rate_awal_m3_monthly_mod",
          "spm_flow_rate_awal_m3_med_monthly": "spm_flow_rate_awal_m3_monthly_med",
          "spm_flow_rate_awal_m3_max_monthly": "spm_flow_rate_awal_m3_monthly_max",
          "spm_flow_rate_awal_m3_min_monthly": "spm_flow_rate_awal_m3_monthly_min",
          "spm_flow_rate_awal_m3_sum_monthly": "spm_flow_rate_awal_m3_monthly_sum",
          "spm_flow_rate_awal_m3_count_monthly": "count_spm_flow_rate_awal_m3_monthly",
          "spm_flow_rate_awal_ft3_avg_monthly": "spm_flow_rate_awal_ft3_monthly_ave",
          "spm_flow_rate_awal_ft3_mod_monthly": "spm_flow_rate_awal_ft3_monthly_mod",
          "spm_flow_rate_awal_ft3_med_monthly": "spm_flow_rate_awal_ft3_monthly_med",
          "spm_flow_rate_awal_ft3_max_monthly": "spm_flow_rate_awal_ft3_monthly_max",
          "spm_flow_rate_awal_ft3_min_monthly": "spm_flow_rate_awal_ft3_monthly_min",
          "spm_flow_rate_awal_ft3_sum_monthly": "spm_flow_rate_awal_ft3_monthly_sum",
          "spm_flow_rate_awal_ft3_count_monthly": "count_spm_flow_rate_awal_ft3_monthly",
          "spm_flow_rate_akhir_m3_avg_monthly": "spm_flow_rate_akhir_m3_monthly_ave",
          "spm_flow_rate_akhir_m3_mod_monthly": "spm_flow_rate_akhir_m3_monthly_mod",
          "spm_flow_rate_akhir_m3_med_monthly": "spm_flow_rate_akhir_m3_monthly_med",
          "spm_flow_rate_akhir_m3_max_monthly": "spm_flow_rate_akhir_m3_monthly_max",
          "spm_flow_rate_akhir_m3_min_monthly": "spm_flow_rate_akhir_m3_monthly_min",
          "spm_flow_rate_akhir_m3_sum_monthly": "spm_flow_rate_akhir_m3_monthly_sum",
          "spm_flow_rate_akhir_m3_count_monthly": "count_spm_flow_rate_akhir_m3_monthly",
          "spm_flow_rate_akhir_ft3_avg_monthly": "spm_flow_rate_akhir_ft3_monthly_ave",
          "spm_flow_rate_akhir_ft3_mod_monthly": "spm_flow_rate_akhir_ft3_monthly_mod",
          "spm_flow_rate_akhir_ft3_med_monthly": "spm_flow_rate_akhir_ft3_monthly_med",
          "spm_flow_rate_akhir_ft3_max_monthly": "spm_flow_rate_akhir_ft3_monthly_max",
          "spm_flow_rate_akhir_ft3_min_monthly": "spm_flow_rate_akhir_ft3_monthly_min",
          "spm_flow_rate_akhir_ft3_sum_monthly": "spm_flow_rate_akhir_ft3_monthly_sum",
          "spm_flow_rate_akhir_ft3_count_monthly": "count_spm_flow_rate_akhir_ft3_monthly"
        },
      }
    ];
    return listAttrMonthly;
  }
};
