import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "Suhu",
        attr: {
          tbk_5cm_0730: "Suhu bola kering 5 cm di jam 07.30",
          tbk_5cm_1330: "Suhu bola kering 5 cm di jam 13.30",
          tbk_5cm_1730: "Suhu bola kering 5 cm di jam 17.30",
          tbk_10cm_0730: "Suhu bola kering 10 cm di jam 07.30",
          tbk_10_1330: "Suhu bola kering 10 cm di jam 13.30",
          tbk_10cm_1730: "Suhu bola kering 10 cm di jam 17.30",
          tbk_20cm_0730: "Suhu bola kering 20 cm di jam 07.30",
          tbk_20cm_1330: "Suhu bola kering 20 cm di jam 13.30",
          tbk_20cm_1730: "Suhu bola kering 20 cm di jam 17.30",
          tbk_50cm_0730: "Suhu bola kering 50 cm di jam 07.30",
          tbk_50_1330: "Suhu bola kering 50 cm di jam 13.30",
          tbk_50cm_1730: "Suhu bola kering 50 cm di jam 17.30",
          tbk_100cm_0730: "Suhu bola kering 100 cm di jam 07.30",
          tbk_100cm_1330: "Suhu bola kering 100 cm di jam 13.30",
          tbk_100cm_1730: "Suhu bola kering 100 cm di jam 17.30",
          tbk_150cm_0730: "Suhu bola kering 150 cm di jam 07.30",
          tbk_150_1330: "Suhu bola kering 150 cm di jam 13.30",
          tbk_150cm_1730: "Suhu bola kering 150 cm di jam 17.30",
          tbk_200cm_0730: "Suhu bola kering 200 cm di jam 07.30",
          tbk_200cm_1330: "Suhu bola kering 200 cm di jam 13.30",
          tbk_200cm_1730: "Suhu bola kering 200 cm di jam 17.30",
          tbb_5cm_0730: "Suhu bola basah 5 cm di jam 07.30",
          tbb_5cm_1330: "Suhu bola basah 5 cm di jam 13.30",
          tbb_5cm_1730: "Suhu bola basah 5 cm di jam 17.30",
          tbb_10cm_0730: "Suhu bola basah 10 cm di jam 07.30",
          tbb_10_1330: "Suhu bola basah 10 cm di jam 13.30",
          tbb_10cm_1730: "Suhu bola basah 10 cm di jam 17.30",
          tbb_20cm_0730: "Suhu bola basah 20 cm di jam 07.30",
          tbb_20cm_1330: "Suhu bola basah 20 cm di jam 13.30",
          tbb_20cm_1730: "Suhu bola basah 20 cm di jam 17.30",
          tbb_50cm_0730: "Suhu bola basah 50 cm di jam 07.30",
          tbb_50_1330: "Suhu bola basah 50 cm di jam 13.30",
          tbb_50cm_1730: "Suhu bola basah 50 cm di jam 17.30",
          tbb_100cm_0730: "Suhu bola basah 100 cm di jam 07.30",
          tbb_100cm_1330: "Suhu bola basah 100 cm di jam 13.30",
          tbb_100cm_1730: "Suhu bola basah 100 cm di jam 17.30",
          tbb_150cm_0730: "Suhu bola basah 150 cm di jam 07.30",
          tbb_150_1330: "Suhu bola basah 150 cm di jam 13.30",
          tbb_150cm_1730: "Suhu bola basah 150 cm di jam 17.30",
          tbb_200cm_0730: "Suhu bola basah 200 cm di jam 07.30",
          tbb_200cm_1330: "Suhu bola basah 200 cm di jam 13.30",
          tbb_200cm_1730: "Suhu bola basah 200 cm di jam 17.30",
        },
      },
      {
        label: "Relative Humidity",
        attr: {
          rh_5cm_0730: "Kelembaban 5 cm di jam 07.30",
          rh_5cm_1330: "Kelembaban 5 cm di jam 13.30",
          rh_5cm_1730: "Kelembaban 5 cm di jam 17.30",
          rh_10cm_0730: "Kelembaban 10 cm di jam 07.30",
          rh_10_1330: "Kelembaban 10 cm di jam 13.30",
          rh_10cm_1730: "Kelembaban 10 cm di jam 17.30",
          rh_20cm_0730: "Kelembaban 20 cm di jam 07.30",
          rh_20cm_1330: "Kelembaban 20 cm di jam 13.30",
          rh_20cm_1730: "Kelembaban 20 cm di jam 17.30",
          rh_50cm_0730: "Kelembaban 50 cm di jam 07.30",
          rh_50_1330: "Kelembaban 50 cm di jam 13.30",
          rh_50cm_1730: "Kelembaban 50 cm di jam 17.30",
          rh_100cm_0730: "Kelembaban 100 cm di jam 07.30",
          rh_100cm_1330: "Kelembaban 100 cm di jam 13.30",
          rh_100cm_1730: "Kelembaban 100 cm di jam 17.30",
          rh_150cm_0730: "Kelembaban 150 cm di jam 07.30",
          rh_150_1330: "Kelembaban 150 cm di jam 13.30",
          rh_150cm_1730: "Kelembaban 150 cm di jam 17.30",
          rh_200cm_0730: "Kelembaban 200 cm di jam 07.30",
          rh_200cm_1330: "Kelembaban 200 cm di jam 13.30",
          rh_200cm_1730: "Kelembaban 200 cm di jam 17.30",
        },
      },
    ];
    return listAttr;
  },

  getAttrMonthly() {
    const listAttr = [
      {
        group: "Temperatur",
        form: "Psychrometer Assman",
        label: "Temperatur",
        attr: {
          tbk_5cm_0730_avg_monthly:
            "Suhu bola kering 5 cm di jam 07.30 AVG MONTHLY",
          // "tbk_5cm_0730_mod_monthly" : "Suhu bola kering 5 cm di jam 07.30 MOD MONTHLY",
          // "tbk_5cm_0730_med_monthly" : "Suhu bola kering 5 cm di jam 07.30 MED MONTHLY",
          tbk_5cm_0730_max_monthly:
            "Suhu bola kering 5 cm di jam 07.30 MAX MONTHLY",
          tbk_5cm_0730_min_monthly:
            "Suhu bola kering 5 cm di jam 07.30 MIN MONTHLY",
          // "tbk_5cm_0730_sum_monthly" : "Suhu bola kering 5 cm di jam 07.30 SUM MONTHLY",
          tbk_5cm_0730_count_monthly:
            "Suhu bola kering 5 cm di jam 07.30 COUNT MONTHLY",
          tbk_5cm_1330_avg_monthly:
            "Suhu bola kering 5 cm di jam 13.30 AVG MONTHLY",
          // "tbk_5cm_1330_mod_monthly" : "Suhu bola kering 5 cm di jam 13.30 MOD MONTHLY",
          // "tbk_5cm_1330_med_monthly" : "Suhu bola kering 5 cm di jam 13.30 MED MONTHLY",
          tbk_5cm_1330_max_monthly:
            "Suhu bola kering 5 cm di jam 13.30 MAX MONTHLY",
          tbk_5cm_1330_min_monthly:
            "Suhu bola kering 5 cm di jam 13.30 MIN MONTHLY",
          // "tbk_5cm_1330_sum_monthly" : "Suhu bola kering 5 cm di jam 13.30 SUM MONTHLY",
          tbk_5cm_1330_count_monthly:
            "Suhu bola kering 5 cm di jam 13.30 COUNT MONTHLY",
          tbk_5cm_1730_avg_monthly:
            "Suhu bola kering 5 cm di jam 17.30 AVG MONTHLY",
          // "tbk_5cm_1730_mod_monthly" : "Suhu bola kering 5 cm di jam 17.30 MOD MONTHLY",
          // "tbk_5cm_1730_med_monthly" : "Suhu bola kering 5 cm di jam 17.30 MED MONTHLY",
          tbk_5cm_1730_max_monthly:
            "Suhu bola kering 5 cm di jam 17.30 MAX MONTHLY",
          tbk_5cm_1730_min_monthly:
            "Suhu bola kering 5 cm di jam 17.30 MIN MONTHLY",
          // "tbk_5cm_1730_sum_monthly" : "Suhu bola kering 5 cm di jam 17.30 SUM MONTHLY",
          tbk_5cm_1730_count_monthly:
            "Suhu bola kering 5 cm di jam 17.30 COUNT MONTHLY",
          tbk_10cm_0730_avg_monthly:
            "Suhu bola kering 10 cm di jam 07.30 AVG MONTHLY",
          // "tbk_10cm_0730_mod_monthly" : "Suhu bola kering 10 cm di jam 07.30 MOD MONTHLY",
          // "tbk_10cm_0730_med_monthly" : "Suhu bola kering 10 cm di jam 07.30 MED MONTHLY",
          tbk_10cm_0730_max_monthly:
            "Suhu bola kering 10 cm di jam 07.30 MAX MONTHLY",
          tbk_10cm_0730_min_monthly:
            "Suhu bola kering 10 cm di jam 07.30 MIN MONTHLY",
          // "tbk_10cm_0730_sum_monthly" : "Suhu bola kering 10 cm di jam 07.30 SUM MONTHLY",
          tbk_10cm_0730_count_monthly:
            "Suhu bola kering 10 cm di jam 07.30 COUNT MONTHLY",
          tbk_10cm_1330_avg_monthly:
            "Suhu bola kering 10 cm di jam 13.30 AVG MONTHLY",
          // "tbk_10cm_1330_mod_monthly" : "Suhu bola kering 10 cm di jam 13.30 MOD MONTHLY",
          // "tbk_10cm_1330_med_monthly" : "Suhu bola kering 10 cm di jam 13.30 MED MONTHLY",
          tbk_10cm_1330_max_monthly:
            "Suhu bola kering 10 cm di jam 13.30 MAX MONTHLY",
          tbk_10cm_1330_min_monthly:
            "Suhu bola kering 10 cm di jam 13.30 MIN MONTHLY",
          // "tbk_10cm_1330_sum_monthly" : "Suhu bola kering 10 cm di jam 13.30 SUM MONTHLY",
          tbk_10cm_1330_count_monthly:
            "Suhu bola kering 10 cm di jam 13.30 COUNT MONTHLY",
          tbk_10cm_1730_avg_monthly:
            "Suhu bola kering 10 cm di jam 17.30 AVG MONTHLY",
          // "tbk_10cm_1730_mod_monthly" : "Suhu bola kering 10 cm di jam 17.30 MOD MONTHLY",
          // "tbk_10cm_1730_med_monthly" : "Suhu bola kering 10 cm di jam 17.30 MED MONTHLY",
          tbk_10cm_1730_max_monthly:
            "Suhu bola kering 10 cm di jam 17.30 MAX MONTHLY",
          tbk_10cm_1730_min_monthly:
            "Suhu bola kering 10 cm di jam 17.30 MIN MONTHLY",
          // "tbk_10cm_1730_sum_monthly" : "Suhu bola kering 10 cm di jam 17.30 SUM MONTHLY",
          tbk_10cm_1730_count_monthly:
            "Suhu bola kering 10 cm di jam 17.30 COUNT MONTHLY",
          tbk_20cm_0730_avg_monthly:
            "Suhu bola kering 20 cm di jam 07.30 AVG MONTHLY",
          // "tbk_20cm_0730_mod_monthly" : "Suhu bola kering 20 cm di jam 07.30 MOD MONTHLY",
          // "tbk_20cm_0730_med_monthly" : "Suhu bola kering 20 cm di jam 07.30 MED MONTHLY",
          tbk_20cm_0730_max_monthly:
            "Suhu bola kering 20 cm di jam 07.30 MAX MONTHLY",
          tbk_20cm_0730_min_monthly:
            "Suhu bola kering 20 cm di jam 07.30 MIN MONTHLY",
          // "tbk_20cm_0730_sum_monthly" : "Suhu bola kering 20 cm di jam 07.30 SUM MONTHLY",
          tbk_20cm_0730_count_monthly:
            "Suhu bola kering 20 cm di jam 07.30 COUNT MONTHLY",
          tbk_20cm_1330_avg_monthly:
            "Suhu bola kering 20 cm di jam 13.30 AVG MONTHLY",
          // "tbk_20cm_1330_mod_monthly" : "Suhu bola kering 20 cm di jam 13.30 MOD MONTHLY",
          // "tbk_20cm_1330_med_monthly" : "Suhu bola kering 20 cm di jam 13.30 MED MONTHLY",
          tbk_20cm_1330_max_monthly:
            "Suhu bola kering 20 cm di jam 13.30 MAX MONTHLY",
          tbk_20cm_1330_min_monthly:
            "Suhu bola kering 20 cm di jam 13.30 MIN MONTHLY",
          // "tbk_20cm_1330_sum_monthly" : "Suhu bola kering 20 cm di jam 13.30 SUM MONTHLY",
          tbk_20cm_1330_count_monthly:
            "Suhu bola kering 20 cm di jam 13.30 COUNT MONTHLY",
          tbk_20cm_1730_avg_monthly:
            "Suhu bola kering 20 cm di jam 17.30 AVG MONTHLY",
          // "tbk_20cm_1730_mod_monthly" : "Suhu bola kering 20 cm di jam 17.30 MOD MONTHLY",
          // "tbk_20cm_1730_med_monthly" : "Suhu bola kering 20 cm di jam 17.30 MED MONTHLY",
          tbk_20cm_1730_max_monthly:
            "Suhu bola kering 20 cm di jam 17.30 MAX MONTHLY",
          tbk_20cm_1730_min_monthly:
            "Suhu bola kering 20 cm di jam 17.30 MIN MONTHLY",
          // "tbk_20cm_1730_sum_monthly" : "Suhu bola kering 20 cm di jam 17.30 SUM MONTHLY",
          tbk_20cm_1730_count_monthly:
            "Suhu bola kering 20 cm di jam 17.30 COUNT MONTHLY",
          tbk_50cm_0730_avg_monthly:
            "Suhu bola kering 50 cm di jam 07.30 AVG MONTHLY",
          // "tbk_50cm_0730_mod_monthly" : "Suhu bola kering 50 cm di jam 07.30 MOD MONTHLY",
          // "tbk_50cm_0730_med_monthly" : "Suhu bola kering 50 cm di jam 07.30 MED MONTHLY",
          tbk_50cm_0730_max_monthly:
            "Suhu bola kering 50 cm di jam 07.30 MAX MONTHLY",
          tbk_50cm_0730_min_monthly:
            "Suhu bola kering 50 cm di jam 07.30 MIN MONTHLY",
          // "tbk_50cm_0730_sum_monthly" : "Suhu bola kering 50 cm di jam 07.30 SUM MONTHLY",
          tbk_50cm_0730_count_monthly:
            "Suhu bola kering 50 cm di jam 07.30 COUNT MONTHLY",
          tbk_50cm_1330_avg_monthly:
            "Suhu bola kering 50 cm di jam 13.30 AVG MONTHLY",
          // "tbk_50cm_1330_mod_monthly" : "Suhu bola kering 50 cm di jam 13.30 MOD MONTHLY",
          // "tbk_50cm_1330_med_monthly" : "Suhu bola kering 50 cm di jam 13.30 MED MONTHLY",
          tbk_50cm_1330_max_monthly:
            "Suhu bola kering 50 cm di jam 13.30 MAX MONTHLY",
          tbk_50cm_1330_min_monthly:
            "Suhu bola kering 50 cm di jam 13.30 MIN MONTHLY",
          // "tbk_50cm_1330_sum_monthly" : "Suhu bola kering 50 cm di jam 13.30 SUM MONTHLY",
          tbk_50cm_1330_count_monthly:
            "Suhu bola kering 50 cm di jam 13.30 COUNT MONTHLY",
          tbk_50cm_1730_avg_monthly:
            "Suhu bola kering 50 cm di jam 17.30 AVG MONTHLY",
          // "tbk_50cm_1730_mod_monthly" : "Suhu bola kering 50 cm di jam 17.30 MOD MONTHLY",
          // "tbk_50cm_1730_med_monthly" : "Suhu bola kering 50 cm di jam 17.30 MED MONTHLY",
          tbk_50cm_1730_max_monthly:
            "Suhu bola kering 50 cm di jam 17.30 MAX MONTHLY",
          tbk_50cm_1730_min_monthly:
            "Suhu bola kering 50 cm di jam 17.30 MIN MONTHLY",
          // "tbk_50cm_1730_sum_monthly" : "Suhu bola kering 50 cm di jam 17.30 SUM MONTHLY",
          tbk_50cm_1730_count_monthly:
            "Suhu bola kering 50 cm di jam 17.30 COUNT MONTHLY",
          tbk_100cm_0730_avg_monthly:
            "Suhu bola kering 100 cm di jam 07.30 AVG MONTHLY",
          // "tbk_100cm_0730_mod_monthly" : "Suhu bola kering 100 cm di jam 07.30 MOD MONTHLY",
          // "tbk_100cm_0730_med_monthly" : "Suhu bola kering 100 cm di jam 07.30 MED MONTHLY",
          tbk_100cm_0730_max_monthly:
            "Suhu bola kering 100 cm di jam 07.30 MAX MONTHLY",
          tbk_100cm_0730_min_monthly:
            "Suhu bola kering 100 cm di jam 07.30 MIN MONTHLY",
          // "tbk_100cm_0730_sum_monthly" : "Suhu bola kering 100 cm di jam 07.30 SUM MONTHLY",
          tbk_100cm_0730_count_monthly:
            "Suhu bola kering 100 cm di jam 07.30 COUNT MONTHLY",
          tbk_100cm_1330_avg_monthly:
            "Suhu bola kering 100 cm di jam 13.30 AVG MONTHLY",
          // "tbk_100cm_1330_mod_monthly" : "Suhu bola kering 100 cm di jam 13.30 MOD MONTHLY",
          // "tbk_100cm_1330_med_monthly" : "Suhu bola kering 100 cm di jam 13.30 MED MONTHLY",
          tbk_100cm_1330_max_monthly:
            "Suhu bola kering 100 cm di jam 13.30 MAX MONTHLY",
          tbk_100cm_1330_min_monthly:
            "Suhu bola kering 100 cm di jam 13.30 MIN MONTHLY",
          // "tbk_100cm_1330_sum_monthly" : "Suhu bola kering 100 cm di jam 13.30 SUM MONTHLY",
          tbk_100cm_1330_count_monthly:
            "Suhu bola kering 100 cm di jam 13.30 COUNT MONTHLY",
          tbk_100cm_1730_avg_monthly:
            "Suhu bola kering 100 cm di jam 17.30 AVG MONTHLY",
          // "tbk_100cm_1730_mod_monthly" : "Suhu bola kering 100 cm di jam 17.30 MOD MONTHLY",
          // "tbk_100cm_1730_med_monthly" : "Suhu bola kering 100 cm di jam 17.30 MED MONTHLY",
          tbk_100cm_1730_max_monthly:
            "Suhu bola kering 100 cm di jam 17.30 MAX MONTHLY",
          tbk_100cm_1730_min_monthly:
            "Suhu bola kering 100 cm di jam 17.30 MIN MONTHLY",
          // "tbk_100cm_1730_sum_monthly" : "Suhu bola kering 100 cm di jam 17.30 SUM MONTHLY",
          tbk_100cm_1730_count_monthly:
            "Suhu bola kering 100 cm di jam 17.30 COUNT MONTHLY",
          tbk_150cm_0730_avg_monthly:
            "Suhu bola kering 150 cm di jam 07.30 AVG MONTHLY",
          // "tbk_150cm_0730_mod_monthly" : "Suhu bola kering 150 cm di jam 07.30 MOD MONTHLY",
          // "tbk_150cm_0730_med_monthly" : "Suhu bola kering 150 cm di jam 07.30 MED MONTHLY",
          tbk_150cm_0730_max_monthly:
            "Suhu bola kering 150 cm di jam 07.30 MAX MONTHLY",
          tbk_150cm_0730_min_monthly:
            "Suhu bola kering 150 cm di jam 07.30 MIN MONTHLY",
          // "tbk_150cm_0730_sum_monthly" : "Suhu bola kering 150 cm di jam 07.30 SUM MONTHLY",
          tbk_150cm_0730_count_monthly:
            "Suhu bola kering 150 cm di jam 07.30 COUNT MONTHLY",
          tbk_150cm_1330_avg_monthly:
            "Suhu bola kering 150 cm di jam 13.30 AVG MONTHLY",
          // "tbk_150cm_1330_mod_monthly" : "Suhu bola kering 150 cm di jam 13.30 MOD MONTHLY",
          // "tbk_150cm_1330_med_monthly" : "Suhu bola kering 150 cm di jam 13.30 MED MONTHLY",
          tbk_150cm_1330_max_monthly:
            "Suhu bola kering 150 cm di jam 13.30 MAX MONTHLY",
          tbk_150cm_1330_min_monthly:
            "Suhu bola kering 150 cm di jam 13.30 MIN MONTHLY",
          // "tbk_150cm_1330_sum_monthly" : "Suhu bola kering 150 cm di jam 13.30 SUM MONTHLY",
          tbk_150cm_1330_count_monthly:
            "Suhu bola kering 150 cm di jam 13.30 COUNT MONTHLY",
          tbk_150cm_1730_avg_monthly:
            "Suhu bola kering 150 cm di jam 17.30 AVG MONTHLY",
          // "tbk_150cm_1730_mod_monthly" : "Suhu bola kering 150 cm di jam 17.30 MOD MONTHLY",
          // "tbk_150cm_1730_med_monthly" : "Suhu bola kering 150 cm di jam 17.30 MED MONTHLY",
          tbk_150cm_1730_max_monthly:
            "Suhu bola kering 150 cm di jam 17.30 MAX MONTHLY",
          tbk_150cm_1730_min_monthly:
            "Suhu bola kering 150 cm di jam 17.30 MIN MONTHLY",
          // "tbk_150cm_1730_sum_monthly" : "Suhu bola kering 150 cm di jam 17.30 SUM MONTHLY",
          tbk_150cm_1730_count_monthly:
            "Suhu bola kering 150 cm di jam 17.30 COUNT MONTHLY",
          tbk_200cm_0730_avg_monthly:
            "Suhu bola kering 200 cm di jam 07.30 AVG MONTHLY",
          // "tbk_200cm_0730_mod_monthly" : "Suhu bola kering 200 cm di jam 07.30 MOD MONTHLY",
          // "tbk_200cm_0730_med_monthly" : "Suhu bola kering 200 cm di jam 07.30 MED MONTHLY",
          tbk_200cm_0730_max_monthly:
            "Suhu bola kering 200 cm di jam 07.30 MAX MONTHLY",
          tbk_200cm_0730_min_monthly:
            "Suhu bola kering 200 cm di jam 07.30 MIN MONTHLY",
          // "tbk_200cm_0730_sum_monthly" : "Suhu bola kering 200 cm di jam 07.30 SUM MONTHLY",
          tbk_200cm_0730_count_monthly:
            "Suhu bola kering 200 cm di jam 07.30 COUNT MONTHLY",
          tbk_200cm_1330_avg_monthly:
            "Suhu bola kering 200 cm di jam 13.30 AVG MONTHLY",
          // "tbk_200cm_1330_mod_monthly" : "Suhu bola kering 200 cm di jam 13.30 MOD MONTHLY",
          // "tbk_200cm_1330_med_monthly" : "Suhu bola kering 200 cm di jam 13.30 MED MONTHLY",
          tbk_200cm_1330_max_monthly:
            "Suhu bola kering 200 cm di jam 13.30 MAX MONTHLY",
          tbk_200cm_1330_min_monthly:
            "Suhu bola kering 200 cm di jam 13.30 MIN MONTHLY",
          // "tbk_200cm_1330_sum_monthly" : "Suhu bola kering 200 cm di jam 13.30 SUM MONTHLY",
          tbk_200cm_1330_count_monthly:
            "Suhu bola kering 200 cm di jam 13.30 COUNT MONTHLY",
          tbk_200cm_1730_avg_monthly:
            "Suhu bola kering 200 cm di jam 17.30 AVG MONTHLY",
          // "tbk_200cm_1730_mod_monthly" : "Suhu bola kering 200 cm di jam 17.30 MOD MONTHLY",
          // "tbk_200cm_1730_med_monthly" : "Suhu bola kering 200 cm di jam 17.30 MED MONTHLY",
          tbk_200cm_1730_max_monthly:
            "Suhu bola kering 200 cm di jam 17.30 MAX MONTHLY",
          tbk_200cm_1730_min_monthly:
            "Suhu bola kering 200 cm di jam 17.30 MIN MONTHLY",
          // "tbk_200cm_1730_sum_monthly" : "Suhu bola kering 200 cm di jam 17.30 SUM MONTHLY",
          tbk_200cm_1730_count_monthly:
            "Suhu bola kering 200 cm di jam 17.30 COUNT MONTHLY",
          tbb_5cm_0730_avg_monthly:
            "Suhu bola basah 5 cm di jam 07.30 AVG MONTHLY",
          // "tbb_5cm_0730_mod_monthly" : "Suhu bola basah 5 cm di jam 07.30 MOD MONTHLY",
          // "tbb_5cm_0730_med_monthly" : "Suhu bola basah 5 cm di jam 07.30 MED MONTHLY",
          tbb_5cm_0730_max_monthly:
            "Suhu bola basah 5 cm di jam 07.30 MAX MONTHLY",
          tbb_5cm_0730_min_monthly:
            "Suhu bola basah 5 cm di jam 07.30 MIN MONTHLY",
          // "tbb_5cm_0730_sum_monthly" : "Suhu bola basah 5 cm di jam 07.30 SUM MONTHLY",
          tbb_5cm_0730_count_monthly:
            "Suhu bola basah 5 cm di jam 07.30 COUNT MONTHLY",
          tbb_5cm_1330_avg_monthly:
            "Suhu bola basah 5 cm di jam 13.30 AVG MONTHLY",
          // "tbb_5cm_1330_mod_monthly" : "Suhu bola basah 5 cm di jam 13.30 MOD MONTHLY",
          // "tbb_5cm_1330_med_monthly" : "Suhu bola basah 5 cm di jam 13.30 MED MONTHLY",
          tbb_5cm_1330_max_monthly:
            "Suhu bola basah 5 cm di jam 13.30 MAX MONTHLY",
          tbb_5cm_1330_min_monthly:
            "Suhu bola basah 5 cm di jam 13.30 MIN MONTHLY",
          // "tbb_5cm_1330_sum_monthly" : "Suhu bola basah 5 cm di jam 13.30 SUM MONTHLY",
          tbb_5cm_1330_count_monthly:
            "Suhu bola basah 5 cm di jam 13.30 COUNT MONTHLY",
          tbb_5cm_1730_avg_monthly:
            "Suhu bola basah 5 cm di jam 17.30 AVG MONTHLY",
          // "tbb_5cm_1730_mod_monthly" : "Suhu bola basah 5 cm di jam 17.30 MOD MONTHLY",
          // "tbb_5cm_1730_med_monthly" : "Suhu bola basah 5 cm di jam 17.30 MED MONTHLY",
          tbb_5cm_1730_max_monthly:
            "Suhu bola basah 5 cm di jam 17.30 MAX MONTHLY",
          tbb_5cm_1730_min_monthly:
            "Suhu bola basah 5 cm di jam 17.30 MIN MONTHLY",
          // "tbb_5cm_1730_sum_monthly" : "Suhu bola basah 5 cm di jam 17.30 SUM MONTHLY",
          tbb_5cm_1730_count_monthly:
            "Suhu bola basah 5 cm di jam 17.30 COUNT MONTHLY",
          tbb_10cm_0730_avg_monthly:
            "Suhu bola basah 10 cm di jam 07.30 AVG MONTHLY",
          // "tbb_10cm_0730_mod_monthly" : "Suhu bola basah 10 cm di jam 07.30 MOD MONTHLY",
          // "tbb_10cm_0730_med_monthly" : "Suhu bola basah 10 cm di jam 07.30 MED MONTHLY",
          tbb_10cm_0730_max_monthly:
            "Suhu bola basah 10 cm di jam 07.30 MAX MONTHLY",
          tbb_10cm_0730_min_monthly:
            "Suhu bola basah 10 cm di jam 07.30 MIN MONTHLY",
          // "tbb_10cm_0730_sum_monthly" : "Suhu bola basah 10 cm di jam 07.30 SUM MONTHLY",
          tbb_10cm_0730_count_monthly:
            "Suhu bola basah 10 cm di jam 07.30 COUNT MONTHLY",
          tbb_10cm_1330_avg_monthly:
            "Suhu bola basah 10 cm di jam 13.30 AVG MONTHLY",
          // "tbb_10cm_1330_mod_monthly" : "Suhu bola basah 10 cm di jam 13.30 MOD MONTHLY",
          // "tbb_10cm_1330_med_monthly" : "Suhu bola basah 10 cm di jam 13.30 MED MONTHLY",
          tbb_10cm_1330_max_monthly:
            "Suhu bola basah 10 cm di jam 13.30 MAX MONTHLY",
          tbb_10cm_1330_min_monthly:
            "Suhu bola basah 10 cm di jam 13.30 MIN MONTHLY",
          // "tbb_10cm_1330_sum_monthly" : "Suhu bola basah 10 cm di jam 13.30 SUM MONTHLY",
          tbb_10cm_1330_count_monthly:
            "Suhu bola basah 10 cm di jam 13.30 COUNT MONTHLY",
          tbb_10cm_1730_avg_monthly:
            "Suhu bola basah 10 cm di jam 17.30 AVG MONTHLY",
          // "tbb_10cm_1730_mod_monthly" : "Suhu bola basah 10 cm di jam 17.30 MOD MONTHLY",
          // "tbb_10cm_1730_med_monthly" : "Suhu bola basah 10 cm di jam 17.30 MED MONTHLY",
          tbb_10cm_1730_max_monthly:
            "Suhu bola basah 10 cm di jam 17.30 MAX MONTHLY",
          tbb_10cm_1730_min_monthly:
            "Suhu bola basah 10 cm di jam 17.30 MIN MONTHLY",
          // "tbb_10cm_1730_sum_monthly" : "Suhu bola basah 10 cm di jam 17.30 SUM MONTHLY",
          tbb_10cm_1730_count_monthly:
            "Suhu bola basah 10 cm di jam 17.30 COUNT MONTHLY",
          tbb_20cm_0730_avg_monthly:
            "Suhu bola basah 20 cm di jam 07.30 AVG MONTHLY",
          // "tbb_20cm_0730_mod_monthly" : "Suhu bola basah 20 cm di jam 07.30 MOD MONTHLY",
          // "tbb_20cm_0730_med_monthly" : "Suhu bola basah 20 cm di jam 07.30 MED MONTHLY",
          tbb_20cm_0730_max_monthly:
            "Suhu bola basah 20 cm di jam 07.30 MAX MONTHLY",
          tbb_20cm_0730_min_monthly:
            "Suhu bola basah 20 cm di jam 07.30 MIN MONTHLY",
          // "tbb_20cm_0730_sum_monthly" : "Suhu bola basah 20 cm di jam 07.30 SUM MONTHLY",
          tbb_20cm_0730_count_monthly:
            "Suhu bola basah 20 cm di jam 07.30 COUNT MONTHLY",
          tbb_20cm_1330_avg_monthly:
            "Suhu bola basah 20 cm di jam 13.30 AVG MONTHLY",
          // "tbb_20cm_1330_mod_monthly" : "Suhu bola basah 20 cm di jam 13.30 MOD MONTHLY",
          // "tbb_20cm_1330_med_monthly" : "Suhu bola basah 20 cm di jam 13.30 MED MONTHLY",
          tbb_20cm_1330_max_monthly:
            "Suhu bola basah 20 cm di jam 13.30 MAX MONTHLY",
          tbb_20cm_1330_min_monthly:
            "Suhu bola basah 20 cm di jam 13.30 MIN MONTHLY",
          // "tbb_20cm_1330_sum_monthly" : "Suhu bola basah 20 cm di jam 13.30 SUM MONTHLY",
          tbb_20cm_1330_count_monthly:
            "Suhu bola basah 20 cm di jam 13.30 COUNT MONTHLY",
          tbb_20cm_1730_avg_monthly:
            "Suhu bola basah 20 cm di jam 17.30 AVG MONTHLY",
          // "tbb_20cm_1730_mod_monthly" : "Suhu bola basah 20 cm di jam 17.30 MOD MONTHLY",
          // "tbb_20cm_1730_med_monthly" : "Suhu bola basah 20 cm di jam 17.30 MED MONTHLY",
          tbb_20cm_1730_max_monthly:
            "Suhu bola basah 20 cm di jam 17.30 MAX MONTHLY",
          tbb_20cm_1730_min_monthly:
            "Suhu bola basah 20 cm di jam 17.30 MIN MONTHLY",
          // "tbb_20cm_1730_sum_monthly" : "Suhu bola basah 20 cm di jam 17.30 SUM MONTHLY",
          tbb_20cm_1730_count_monthly:
            "Suhu bola basah 20 cm di jam 17.30 COUNT MONTHLY",
          tbb_50cm_0730_avg_monthly:
            "Suhu bola basah 50 cm di jam 07.30 AVG MONTHLY",
          // "tbb_50cm_0730_mod_monthly" : "Suhu bola basah 50 cm di jam 07.30 MOD MONTHLY",
          // "tbb_50cm_0730_med_monthly" : "Suhu bola basah 50 cm di jam 07.30 MED MONTHLY",
          tbb_50cm_0730_max_monthly:
            "Suhu bola basah 50 cm di jam 07.30 MAX MONTHLY",
          tbb_50cm_0730_min_monthly:
            "Suhu bola basah 50 cm di jam 07.30 MIN MONTHLY",
          // "tbb_50cm_0730_sum_monthly" : "Suhu bola basah 50 cm di jam 07.30 SUM MONTHLY",
          tbb_50cm_0730_count_monthly:
            "Suhu bola basah 50 cm di jam 07.30 COUNT MONTHLY",
          tbb_50cm_1330_avg_monthly:
            "Suhu bola basah 50 cm di jam 13.30 AVG MONTHLY",
          // "tbb_50cm_1330_mod_monthly" : "Suhu bola basah 50 cm di jam 13.30 MOD MONTHLY",
          // "tbb_50cm_1330_med_monthly" : "Suhu bola basah 50 cm di jam 13.30 MED MONTHLY",
          tbb_50cm_1330_max_monthly:
            "Suhu bola basah 50 cm di jam 13.30 MAX MONTHLY",
          tbb_50cm_1330_min_monthly:
            "Suhu bola basah 50 cm di jam 13.30 MIN MONTHLY",
          // "tbb_50cm_1330_sum_monthly" : "Suhu bola basah 50 cm di jam 13.30 SUM MONTHLY",
          tbb_50cm_1330_count_monthly:
            "Suhu bola basah 50 cm di jam 13.30 COUNT MONTHLY",
          tbb_50cm_1730_avg_monthly:
            "Suhu bola basah 50 cm di jam 17.30 AVG MONTHLY",
          // "tbb_50cm_1730_mod_monthly" : "Suhu bola basah 50 cm di jam 17.30 MOD MONTHLY",
          // "tbb_50cm_1730_med_monthly" : "Suhu bola basah 50 cm di jam 17.30 MED MONTHLY",
          tbb_50cm_1730_max_monthly:
            "Suhu bola basah 50 cm di jam 17.30 MAX MONTHLY",
          tbb_50cm_1730_min_monthly:
            "Suhu bola basah 50 cm di jam 17.30 MIN MONTHLY",
          // "tbb_50cm_1730_sum_monthly" : "Suhu bola basah 50 cm di jam 17.30 SUM MONTHLY",
          tbb_50cm_1730_count_monthly:
            "Suhu bola basah 50 cm di jam 17.30 COUNT MONTHLY",
          tbb_100cm_0730_avg_monthly:
            "Suhu bola basah 100 cm di jam 07.30 AVG MONTHLY",
          // "tbb_100cm_0730_mod_monthly" : "Suhu bola basah 100 cm di jam 07.30 MOD MONTHLY",
          // "tbb_100cm_0730_med_monthly" : "Suhu bola basah 100 cm di jam 07.30 MED MONTHLY",
          tbb_100cm_0730_max_monthly:
            "Suhu bola basah 100 cm di jam 07.30 MAX MONTHLY",
          tbb_100cm_0730_min_monthly:
            "Suhu bola basah 100 cm di jam 07.30 MIN MONTHLY",
          // "tbb_100cm_0730_sum_monthly" : "Suhu bola basah 100 cm di jam 07.30 SUM MONTHLY",
          tbb_100cm_0730_count_monthly:
            "Suhu bola basah 100 cm di jam 07.30 COUNT MONTHLY",
          tbb_100cm_1330_avg_monthly:
            "Suhu bola basah 100 cm di jam 13.30 AVG MONTHLY",
          // "tbb_100cm_1330_mod_monthly" : "Suhu bola basah 100 cm di jam 13.30 MOD MONTHLY",
          // "tbb_100cm_1330_med_monthly" : "Suhu bola basah 100 cm di jam 13.30 MED MONTHLY",
          tbb_100cm_1330_max_monthly:
            "Suhu bola basah 100 cm di jam 13.30 MAX MONTHLY",
          tbb_100cm_1330_min_monthly:
            "Suhu bola basah 100 cm di jam 13.30 MIN MONTHLY",
          // "tbb_100cm_1330_sum_monthly" : "Suhu bola basah 100 cm di jam 13.30 SUM MONTHLY",
          tbb_100cm_1330_count_monthly:
            "Suhu bola basah 100 cm di jam 13.30 COUNT MONTHLY",
          tbb_100cm_1730_avg_monthly:
            "Suhu bola basah 100 cm di jam 17.30 AVG MONTHLY",
          // "tbb_100cm_1730_mod_monthly" : "Suhu bola basah 100 cm di jam 17.30 MOD MONTHLY",
          // "tbb_100cm_1730_med_monthly" : "Suhu bola basah 100 cm di jam 17.30 MED MONTHLY",
          tbb_100cm_1730_max_monthly:
            "Suhu bola basah 100 cm di jam 17.30 MAX MONTHLY",
          tbb_100cm_1730_min_monthly:
            "Suhu bola basah 100 cm di jam 17.30 MIN MONTHLY",
          // "tbb_100cm_1730_sum_monthly" : "Suhu bola basah 100 cm di jam 17.30 SUM MONTHLY",
          tbb_100cm_1730_count_monthly:
            "Suhu bola basah 100 cm di jam 17.30 COUNT MONTHLY",
          tbb_150cm_0730_avg_monthly:
            "Suhu bola basah 150 cm di jam 07.30 AVG MONTHLY",
          // "tbb_150cm_0730_mod_monthly" : "Suhu bola basah 150 cm di jam 07.30 MOD MONTHLY",
          // "tbb_150cm_0730_med_monthly" : "Suhu bola basah 150 cm di jam 07.30 MED MONTHLY",
          tbb_150cm_0730_max_monthly:
            "Suhu bola basah 150 cm di jam 07.30 MAX MONTHLY",
          tbb_150cm_0730_min_monthly:
            "Suhu bola basah 150 cm di jam 07.30 MIN MONTHLY",
          // "tbb_150cm_0730_sum_monthly" : "Suhu bola basah 150 cm di jam 07.30 SUM MONTHLY",
          tbb_150cm_0730_count_monthly:
            "Suhu bola basah 150 cm di jam 07.30 COUNT MONTHLY",
          tbb_150cm_1330_avg_monthly:
            "Suhu bola basah 150 cm di jam 13.30 AVG MONTHLY",
          // "tbb_150cm_1330_mod_monthly" : "Suhu bola basah 150 cm di jam 13.30 MOD MONTHLY",
          // "tbb_150cm_1330_med_monthly" : "Suhu bola basah 150 cm di jam 13.30 MED MONTHLY",
          tbb_150cm_1330_max_monthly:
            "Suhu bola basah 150 cm di jam 13.30 MAX MONTHLY",
          tbb_150cm_1330_min_monthly:
            "Suhu bola basah 150 cm di jam 13.30 MIN MONTHLY",
          // "tbb_150cm_1330_sum_monthly" : "Suhu bola basah 150 cm di jam 13.30 SUM MONTHLY",
          tbb_150cm_1330_count_monthly:
            "Suhu bola basah 150 cm di jam 13.30 COUNT MONTHLY",
          tbb_150cm_1730_avg_monthly:
            "Suhu bola basah 150 cm di jam 17.30 AVG MONTHLY",
          // "tbb_150cm_1730_mod_monthly" : "Suhu bola basah 150 cm di jam 17.30 MOD MONTHLY",
          // "tbb_150cm_1730_med_monthly" : "Suhu bola basah 150 cm di jam 17.30 MED MONTHLY",
          tbb_150cm_1730_max_monthly:
            "Suhu bola basah 150 cm di jam 17.30 MAX MONTHLY",
          tbb_150cm_1730_min_monthly:
            "Suhu bola basah 150 cm di jam 17.30 MIN MONTHLY",
          // "tbb_150cm_1730_sum_monthly" : "Suhu bola basah 150 cm di jam 17.30 SUM MONTHLY",
          tbb_150cm_1730_count_monthly:
            "Suhu bola basah 150 cm di jam 17.30 COUNT MONTHLY",
          tbb_200cm_0730_avg_monthly:
            "Suhu bola basah 200 cm di jam 07.30 AVG MONTHLY",
          // "tbb_200cm_0730_mod_monthly" : "Suhu bola basah 200 cm di jam 07.30 MOD MONTHLY",
          // "tbb_200cm_0730_med_monthly" : "Suhu bola basah 200 cm di jam 07.30 MED MONTHLY",
          tbb_200cm_0730_max_monthly:
            "Suhu bola basah 200 cm di jam 07.30 MAX MONTHLY",
          tbb_200cm_0730_min_monthly:
            "Suhu bola basah 200 cm di jam 07.30 MIN MONTHLY",
          // "tbb_200cm_0730_sum_monthly" : "Suhu bola basah 200 cm di jam 07.30 SUM MONTHLY",
          tbb_200cm_0730_count_monthly:
            "Suhu bola basah 200 cm di jam 07.30 COUNT MONTHLY",
          tbb_200cm_1330_avg_monthly:
            "Suhu bola basah 200 cm di jam 13.30 AVG MONTHLY",
          // "tbb_200cm_1330_mod_monthly" : "Suhu bola basah 200 cm di jam 13.30 MOD MONTHLY",
          // "tbb_200cm_1330_med_monthly" : "Suhu bola basah 200 cm di jam 13.30 MED MONTHLY",
          tbb_200cm_1330_max_monthly:
            "Suhu bola basah 200 cm di jam 13.30 MAX MONTHLY",
          tbb_200cm_1330_min_monthly:
            "Suhu bola basah 200 cm di jam 13.30 MIN MONTHLY",
          // "tbb_200cm_1330_sum_monthly" : "Suhu bola basah 200 cm di jam 13.30 SUM MONTHLY",
          tbb_200cm_1330_count_monthly:
            "Suhu bola basah 200 cm di jam 13.30 COUNT MONTHLY",
          tbb_200cm_1730_avg_monthly:
            "Suhu bola basah 200 cm di jam 17.30 AVG MONTHLY",
          // "tbb_200cm_1730_mod_monthly" : "Suhu bola basah 200 cm di jam 17.30 MOD MONTHLY",
          // "tbb_200cm_1730_med_monthly" : "Suhu bola basah 200 cm di jam 17.30 MED MONTHLY",
          tbb_200cm_1730_max_monthly:
            "Suhu bola basah 200 cm di jam 17.30 MAX MONTHLY",
          tbb_200cm_1730_min_monthly:
            "Suhu bola basah 200 cm di jam 17.30 MIN MONTHLY",
          // "tbb_200cm_1730_sum_monthly" : "Suhu bola basah 200 cm di jam 17.30 SUM MONTHLY",
          tbb_200cm_1730_count_monthly:
            "Suhu bola basah 200 cm di jam 17.30 COUNT MONTHLY",
        },
      },
      {
        group: "Kelembaban",
        form: "Psychrometer Assman",
        label: "Kelembaban",
        attr: {
          rh_5cm_0730_avg_monthly: "Kelembaban 5 cm di jam 07.30 AVG MONTHLY",
          // "rh_5cm_0730_mod_monthly" : "Kelembaban 5 cm di jam 07.30 MOD MONTHLY",
          // "rh_5cm_0730_med_monthly" : "Kelembaban 5 cm di jam 07.30 MED MONTHLY",
          rh_5cm_0730_max_monthly: "Kelembaban 5 cm di jam 07.30 MAX MONTHLY",
          rh_5cm_0730_min_monthly: "Kelembaban 5 cm di jam 07.30 MIN MONTHLY",
          // "rh_5cm_0730_sum_monthly" : "Kelembaban 5 cm di jam 07.30 SUM MONTHLY",
          rh_5cm_0730_count_monthly:
            "Kelembaban 5 cm di jam 13.30 COUNT MONTHLY",
          rh_5cm_1330_avg_monthly: "Kelembaban 5 cm di jam 13.30 AVG MONTHLY",
          // "rh_5cm_1330_mod_monthly" : "Kelembaban 5 cm di jam 13.30 MOD MONTHLY",
          // "rh_5cm_1330_med_monthly" : "Kelembaban 5 cm di jam 13.30 MED MONTHLY",
          rh_5cm_1330_max_monthly: "Kelembaban 5 cm di jam 13.30 MAX MONTHLY",
          rh_5cm_1330_min_monthly: "Kelembaban 5 cm di jam 13.30 MIN MONTHLY",
          // "rh_5cm_1330_sum_monthly" : "Kelembaban 5 cm di jam 13.30 SUM MONTHLY",
          rh_5cm_1330_count_monthly:
            "Kelembaban 5 cm di jam 13.30 COUNT MONTHLY",
          rh_5cm_1730_avg_monthly: "Kelembaban 5 cm di jam 17.30 AVG MONTHLY",
          // "rh_5cm_1730_mod_monthly" : "Kelembaban 5 cm di jam 17.30 MOD MONTHLY",
          // "rh_5cm_1730_med_monthly" : "Kelembaban 5 cm di jam 17.30 MED MONTHLY",
          rh_5cm_1730_max_monthly: "Kelembaban 5 cm di jam 17.30 MAX MONTHLY",
          rh_5cm_1730_min_monthly: "Kelembaban 5 cm di jam 17.30 MIN MONTHLY",
          // "rh_5cm_1730_sum_monthly" : "Kelembaban 5 cm di jam 17.30 SUM MONTHLY",
          rh_5cm_1730_count_monthly:
            "Kelembaban 5 cm di jam 17.30 COUNT MONTHLY",
          rh_10cm_0730_avg_monthly: "Kelembaban 10 cm di jam 07.30 AVG MONTHLY",
          // "rh_10cm_0730_mod_monthly" : "Kelembaban 10 cm di jam 07.30 MOD MONTHLY",
          // "rh_10cm_0730_med_monthly" : "Kelembaban 10 cm di jam 07.30 MED MONTHLY",
          rh_10cm_0730_max_monthly: "Kelembaban 10 cm di jam 07.30 MAX MONTHLY",
          rh_10cm_0730_min_monthly: "Kelembaban 10 cm di jam 07.30 MIN MONTHLY",
          // "rh_10cm_0730_sum_monthly" : "Kelembaban 10 cm di jam 07.30 SUM MONTHLY",
          rh_10cm_0730_count_monthly:
            "Kelembaban 10 cm di jam 13.30 COUNT MONTHLY",
          rh_10cm_1330_avg_monthly: "Kelembaban 10 cm di jam 13.30 AVG MONTHLY",
          // "rh_10cm_1330_mod_monthly" : "Kelembaban 10 cm di jam 13.30 MOD MONTHLY",
          // "rh_10cm_1330_med_monthly" : "Kelembaban 10 cm di jam 13.30 MED MONTHLY",
          rh_10cm_1330_max_monthly: "Kelembaban 10 cm di jam 13.30 MAX MONTHLY",
          rh_10cm_1330_min_monthly: "Kelembaban 10 cm di jam 13.30 MIN MONTHLY",
          // "rh_10cm_1330_sum_monthly" : "Kelembaban 10 cm di jam 13.30 SUM MONTHLY",
          rh_10cm_1330_count_monthly:
            "Kelembaban 10 cm di jam 13.30 COUNT MONTHLY",
          rh_10cm_1730_avg_monthly: "Kelembaban 10 cm di jam 17.30 AVG MONTHLY",
          // "rh_10cm_1730_mod_monthly" : "Kelembaban 10 cm di jam 17.30 MOD MONTHLY",
          // "rh_10cm_1730_med_monthly" : "Kelembaban 10 cm di jam 17.30 MED MONTHLY",
          rh_10cm_1730_max_monthly: "Kelembaban 10 cm di jam 17.30 MAX MONTHLY",
          rh_10cm_1730_min_monthly: "Kelembaban 10 cm di jam 17.30 MIN MONTHLY",
          // "rh_10cm_1730_sum_monthly" : "Kelembaban 10 cm di jam 17.30 SUM MONTHLY",
          rh_10cm_1730_count_monthly:
            "Kelembaban 10 cm di jam 17.30 COUNT MONTHLY",
          rh_20cm_0730_avg_monthly: "Kelembaban 20 cm di jam 07.30 AVG MONTHLY",
          // "rh_20cm_0730_mod_monthly" : "Kelembaban 20 cm di jam 07.30 MOD MONTHLY",
          // "rh_20cm_0730_med_monthly" : "Kelembaban 20 cm di jam 07.30 MED MONTHLY",
          rh_20cm_0730_max_monthly: "Kelembaban 20 cm di jam 07.30 MAX MONTHLY",
          rh_20cm_0730_min_monthly: "Kelembaban 20 cm di jam 07.30 MIN MONTHLY",
          // "rh_20cm_0730_sum_monthly" : "Kelembaban 20 cm di jam 07.30 SUM MONTHLY",
          rh_20cm_0730_count_monthly:
            "Kelembaban 20 cm di jam 13.30 COUNT MONTHLY",
          rh_20cm_1330_avg_monthly: "Kelembaban 20 cm di jam 13.30 AVG MONTHLY",
          // "rh_20cm_1330_mod_monthly" : "Kelembaban 20 cm di jam 13.30 MOD MONTHLY",
          // "rh_20cm_1330_med_monthly" : "Kelembaban 20 cm di jam 13.30 MED MONTHLY",
          rh_20cm_1330_max_monthly: "Kelembaban 20 cm di jam 13.30 MAX MONTHLY",
          rh_20cm_1330_min_monthly: "Kelembaban 20 cm di jam 13.30 MIN MONTHLY",
          // "rh_20cm_1330_sum_monthly" : "Kelembaban 20 cm di jam 13.30 SUM MONTHLY",
          rh_20cm_1330_count_monthly:
            "Kelembaban 20 cm di jam 13.30 COUNT MONTHLY",
          rh_20cm_1730_avg_monthly: "Kelembaban 20 cm di jam 17.30 AVG MONTHLY",
          // "rh_20cm_1730_mod_monthly" : "Kelembaban 20 cm di jam 17.30 MOD MONTHLY",
          // "rh_20cm_1730_med_monthly" : "Kelembaban 20 cm di jam 17.30 MED MONTHLY",
          rh_20cm_1730_max_monthly: "Kelembaban 20 cm di jam 17.30 MAX MONTHLY",
          rh_20cm_1730_min_monthly: "Kelembaban 20 cm di jam 17.30 MIN MONTHLY",
          // "rh_20cm_1730_sum_monthly" : "Kelembaban 20 cm di jam 17.30 SUM MONTHLY",
          rh_20cm_1730_count_monthly:
            "Kelembaban 20 cm di jam 17.30 COUNT MONTHLY",
          rh_50cm_0730_avg_monthly: "Kelembaban 50 cm di jam 07.30 AVG MONTHLY",
          // "rh_50cm_0730_mod_monthly" : "Kelembaban 50 cm di jam 07.30 MOD MONTHLY",
          // "rh_50cm_0730_med_monthly" : "Kelembaban 50 cm di jam 07.30 MED MONTHLY",
          rh_50cm_0730_max_monthly: "Kelembaban 50 cm di jam 07.30 MAX MONTHLY",
          rh_50cm_0730_min_monthly: "Kelembaban 50 cm di jam 07.30 MIN MONTHLY",
          // "rh_50cm_0730_sum_monthly" : "Kelembaban 50 cm di jam 07.30 SUM MONTHLY",
          rh_50cm_0730_count_monthly:
            "Kelembaban 50 cm di jam 13.30 COUNT MONTHLY",
          rh_50cm_1330_avg_monthly: "Kelembaban 50 cm di jam 13.30 AVG MONTHLY",
          // "rh_50cm_1330_mod_monthly" : "Kelembaban 50 cm di jam 13.30 MOD MONTHLY",
          // "rh_50cm_1330_med_monthly" : "Kelembaban 50 cm di jam 13.30 MED MONTHLY",
          rh_50cm_1330_max_monthly: "Kelembaban 50 cm di jam 13.30 MAX MONTHLY",
          rh_50cm_1330_min_monthly: "Kelembaban 50 cm di jam 13.30 MIN MONTHLY",
          // "rh_50cm_1330_sum_monthly" : "Kelembaban 50 cm di jam 13.30 SUM MONTHLY",
          rh_50cm_1330_count_monthly:
            "Kelembaban 50 cm di jam 13.30 COUNT MONTHLY",
          rh_50cm_1730_avg_monthly: "Kelembaban 50 cm di jam 17.30 AVG MONTHLY",
          // "rh_50cm_1730_mod_monthly" : "Kelembaban 50 cm di jam 17.30 MOD MONTHLY",
          // "rh_50cm_1730_med_monthly" : "Kelembaban 50 cm di jam 17.30 MED MONTHLY",
          rh_50cm_1730_max_monthly: "Kelembaban 50 cm di jam 17.30 MAX MONTHLY",
          rh_50cm_1730_min_monthly: "Kelembaban 50 cm di jam 17.30 MIN MONTHLY",
          // "rh_50cm_1730_sum_monthly" : "Kelembaban 50 cm di jam 17.30 SUM MONTHLY",
          rh_50cm_1730_count_monthly:
            "Kelembaban 50 cm di jam 17.30 COUNT MONTHLY",
          rh_100cm_0730_avg_monthly:
            "Kelembaban 100 cm di jam 07.30 AVG MONTHLY",
          // "rh_100cm_0730_mod_monthly" : "Kelembaban 100 cm di jam 07.30 MOD MONTHLY",
          // "rh_100cm_0730_med_monthly" : "Kelembaban 100 cm di jam 07.30 MED MONTHLY",
          rh_100cm_0730_max_monthly:
            "Kelembaban 100 cm di jam 07.30 MAX MONTHLY",
          rh_100cm_0730_min_monthly:
            "Kelembaban 100 cm di jam 07.30 MIN MONTHLY",
          // "rh_100cm_0730_sum_monthly" : "Kelembaban 100 cm di jam 07.30 SUM MONTHLY",
          rh_100cm_0730_count_monthly:
            "Kelembaban 100 cm di jam 13.30 COUNT MONTHLY",
          rh_100cm_1330_avg_monthly:
            "Kelembaban 100 cm di jam 13.30 AVG MONTHLY",
          // "rh_100cm_1330_mod_monthly" : "Kelembaban 100 cm di jam 13.30 MOD MONTHLY",
          // "rh_100cm_1330_med_monthly" : "Kelembaban 100 cm di jam 13.30 MED MONTHLY",
          rh_100cm_1330_max_monthly:
            "Kelembaban 100 cm di jam 13.30 MAX MONTHLY",
          rh_100cm_1330_min_monthly:
            "Kelembaban 100 cm di jam 13.30 MIN MONTHLY",
          // "rh_100cm_1330_sum_monthly" : "Kelembaban 100 cm di jam 13.30 SUM MONTHLY",
          rh_100cm_1330_count_monthly:
            "Kelembaban 100 cm di jam 13.30 COUNT MONTHLY",
          rh_100cm_1730_avg_monthly:
            "Kelembaban 100 cm di jam 17.30 AVG MONTHLY",
          // "rh_100cm_1730_mod_monthly" : "Kelembaban 100 cm di jam 17.30 MOD MONTHLY",
          // "rh_100cm_1730_med_monthly" : "Kelembaban 100 cm di jam 17.30 MED MONTHLY",
          rh_100cm_1730_max_monthly:
            "Kelembaban 100 cm di jam 17.30 MAX MONTHLY",
          rh_100cm_1730_min_monthly:
            "Kelembaban 100 cm di jam 17.30 MIN MONTHLY",
          // "rh_100cm_1730_sum_monthly" : "Kelembaban 100 cm di jam 17.30 SUM MONTHLY",
          rh_100cm_1730_count_monthly:
            "Kelembaban 100 cm di jam 17.30 COUNT MONTHLY",
          rh_150cm_0730_avg_monthly:
            "Kelembaban 150 cm di jam 07.30 AVG MONTHLY",
          // "rh_150cm_0730_mod_monthly" : "Kelembaban 150 cm di jam 07.30 MOD MONTHLY",
          // "rh_150cm_0730_med_monthly" : "Kelembaban 150 cm di jam 07.30 MED MONTHLY",
          rh_150cm_0730_max_monthly:
            "Kelembaban 150 cm di jam 07.30 MAX MONTHLY",
          rh_150cm_0730_min_monthly:
            "Kelembaban 150 cm di jam 07.30 MIN MONTHLY",
          // "rh_150cm_0730_sum_monthly" : "Kelembaban 150 cm di jam 07.30 SUM MONTHLY",
          rh_150cm_0730_count_monthly:
            "Kelembaban 150 cm di jam 13.30 COUNT MONTHLY",
          rh_150cm_1330_avg_monthly:
            "Kelembaban 150 cm di jam 13.30 AVG MONTHLY",
          // "rh_150cm_1330_mod_monthly" : "Kelembaban 150 cm di jam 13.30 MOD MONTHLY",
          // "rh_150cm_1330_med_monthly" : "Kelembaban 150 cm di jam 13.30 MED MONTHLY",
          rh_150cm_1330_max_monthly:
            "Kelembaban 150 cm di jam 13.30 MAX MONTHLY",
          rh_150cm_1330_min_monthly:
            "Kelembaban 150 cm di jam 13.30 MIN MONTHLY",
          // "rh_150cm_1330_sum_monthly" : "Kelembaban 150 cm di jam 13.30 SUM MONTHLY",
          rh_150cm_1330_count_monthly:
            "Kelembaban 150 cm di jam 13.30 COUNT MONTHLY",
          rh_150cm_1730_avg_monthly:
            "Kelembaban 150 cm di jam 17.30 AVG MONTHLY",
          // "rh_150cm_1730_mod_monthly" : "Kelembaban 150 cm di jam 17.30 MOD MONTHLY",
          // "rh_150cm_1730_med_monthly" : "Kelembaban 150 cm di jam 17.30 MED MONTHLY",
          rh_150cm_1730_max_monthly:
            "Kelembaban 150 cm di jam 17.30 MAX MONTHLY",
          rh_150cm_1730_min_monthly:
            "Kelembaban 150 cm di jam 17.30 MIN MONTHLY",
          // "rh_150cm_1730_sum_monthly" : "Kelembaban 150 cm di jam 17.30 SUM MONTHLY",
          rh_150cm_1730_count_monthly:
            "Kelembaban 150 cm di jam 17.30 COUNT MONTHLY",
          rh_200cm_0730_avg_monthly:
            "Kelembaban 200 cm di jam 07.30 AVG MONTHLY",
          // "rh_200cm_0730_mod_monthly" : "Kelembaban 200 cm di jam 07.30 MOD MONTHLY",
          // "rh_200cm_0730_med_monthly" : "Kelembaban 200 cm di jam 07.30 MED MONTHLY",
          rh_200cm_0730_max_monthly:
            "Kelembaban 200 cm di jam 07.30 MAX MONTHLY",
          rh_200cm_0730_min_monthly:
            "Kelembaban 200 cm di jam 07.30 MIN MONTHLY",
          // "rh_200cm_0730_sum_monthly" : "Kelembaban 200 cm di jam 07.30 SUM MONTHLY",
          rh_200cm_0730_count_monthly:
            "Kelembaban 200 cm di jam 13.30 COUNT MONTHLY",
          rh_200cm_1330_avg_monthly:
            "Kelembaban 200 cm di jam 13.30 AVG MONTHLY",
          // "rh_200cm_1330_mod_monthly" : "Kelembaban 200 cm di jam 13.30 MOD MONTHLY",
          // "rh_200cm_1330_med_monthly" : "Kelembaban 200 cm di jam 13.30 MED MONTHLY",
          rh_200cm_1330_max_monthly:
            "Kelembaban 200 cm di jam 13.30 MAX MONTHLY",
          rh_200cm_1330_min_monthly:
            "Kelembaban 200 cm di jam 13.30 MIN MONTHLY",
          // "rh_200cm_1330_sum_monthly" : "Kelembaban 200 cm di jam 13.30 SUM MONTHLY",
          rh_200cm_1330_count_monthly:
            "Kelembaban 200 cm di jam 13.30 COUNT MONTHLY",
          rh_200cm_1730_avg_monthly:
            "Kelembaban 200 cm di jam 17.30 AVG MONTHLY",
          // "rh_200cm_1730_mod_monthly" : "Kelembaban 200 cm di jam 17.30 MOD MONTHLY",
          // "rh_200cm_1730_med_monthly" : "Kelembaban 200 cm di jam 17.30 MED MONTHLY",
          rh_200cm_1730_max_monthly:
            "Kelembaban 200 cm di jam 17.30 MAX MONTHLY",
          rh_200cm_1730_min_monthly:
            "Kelembaban 200 cm di jam 17.30 MIN MONTHLY",
          // "rh_200cm_1730_sum_monthly" : "Kelembaban 200 cm di jam 17.30 SUM MONTHLY",
          rh_200cm_1730_count_monthly:
            "Kelembaban 200 cm di jam 17.30 COUNT MONTHLY",
        },
      },
    ];
    return listAttr;
  },

  getAttrMonthlyCsv() {
    const listAttr = [
      {
        label: "Suhu",
        attr: {
          tbk_5cm_0730_avg_monthly: "tbk_5cm_0730_monthly_avg_C",
          tbk_5cm_0730_mod_monthly: "tbk_5cm_0730_monthly_mod_C",
          tbk_5cm_0730_med_monthly: "tbk_5cm_0730_monthly_med_C",
          tbk_5cm_0730_max_monthly: "tbk_5cm_0730_monthly_max_C",
          tbk_5cm_0730_min_monthly: "tbk_5cm_0730_monthly_min_C",
          tbk_5cm_0730_sum_monthly: "tbk_5cm_0730_monthly_sum_C",
          tbk_5cm_0730_count_monthly: "c_tbk_5cm_0730_monthly",
          tbk_5cm_1330_avg_monthly: "tbk_5cm_1330_monthly_avg_C",
          tbk_5cm_1330_mod_monthly: "tbk_5cm_1330_monthly_mod_C",
          tbk_5cm_1330_med_monthly: "tbk_5cm_1330_monthly_med_C",
          tbk_5cm_1330_max_monthly: "tbk_5cm_1330_monthly_max_C",
          tbk_5cm_1330_min_monthly: "tbk_5cm_1330_monthly_min_C",
          tbk_5cm_1330_sum_monthly: "tbk_5cm_1330_monthly_sum_C",
          tbk_5cm_1330_count_monthly: "c_tbk_5cm_1330_monthly",
          tbk_5cm_1730_avg_monthly: "tbk_5cm_1730_monthly_avg_C",
          tbk_5cm_1730_mod_monthly: "tbk_5cm_1730_monthly_mod_C",
          tbk_5cm_1730_med_monthly: "tbk_5cm_1730_monthly_med_C",
          tbk_5cm_1730_max_monthly: "tbk_5cm_1730_monthly_max_C",
          tbk_5cm_1730_min_monthly: "tbk_5cm_1730_monthly_min_C",
          tbk_5cm_1730_sum_monthly: "tbk_5cm_1730_monthly_sum_C",
          tbk_5cm_1730_count_monthly: "c_tbk_5cm_1730_monthly",
          tbk_10cm_0730_avg_monthly: "tbk_10cm_0730_monthly_avg_C",
          tbk_10cm_0730_mod_monthly: "tbk_10cm_0730_monthly_mod_C",
          tbk_10cm_0730_med_monthly: "tbk_10cm_0730_monthly_med_C",
          tbk_10cm_0730_max_monthly: "tbk_10cm_0730_monthly_max_C",
          tbk_10cm_0730_min_monthly: "tbk_10cm_0730_monthly_min_C",
          tbk_10cm_0730_sum_monthly: "tbk_10cm_0730_monthly_sum_C",
          tbk_10cm_0730_count_monthly: "c_tbk_10cm_0730_monthly",
          tbk_10cm_1330_avg_monthly: "tbk_10cm_1330_monthly_avg_C",
          tbk_10cm_1330_mod_monthly: "tbk_10cm_1330_monthly_mod_C",
          tbk_10cm_1330_med_monthly: "tbk_10cm_1330_monthly_med_C",
          tbk_10cm_1330_max_monthly: "tbk_10cm_1330_monthly_max_C",
          tbk_10cm_1330_min_monthly: "tbk_10cm_1330_monthly_min_C",
          tbk_10cm_1330_sum_monthly: "tbk_10cm_1330_monthly_sum_C",
          tbk_10cm_1330_count_monthly: "c_tbk_10cm_1330_monthly",
          tbk_10cm_1730_avg_monthly: "tbk_10cm_1730_monthly_avg_C",
          tbk_10cm_1730_mod_monthly: "tbk_10cm_1730_monthly_mod_C",
          tbk_10cm_1730_med_monthly: "tbk_10cm_1730_monthly_med_C",
          tbk_10cm_1730_max_monthly: "tbk_10cm_1730_monthly_max_C",
          tbk_10cm_1730_min_monthly: "tbk_10cm_1730_monthly_min_C",
          tbk_10cm_1730_sum_monthly: "tbk_10cm_1730_monthly_sum_C",
          tbk_10cm_1730_count_monthly: "c_tbk_10cm_1730_monthly",
          tbk_20cm_0730_avg_monthly: "tbk_20cm_0730_monthly_avg_C",
          tbk_20cm_0730_mod_monthly: "tbk_20cm_0730_monthly_mod_C",
          tbk_20cm_0730_med_monthly: "tbk_20cm_0730_monthly_med_C",
          tbk_20cm_0730_max_monthly: "tbk_20cm_0730_monthly_max_C",
          tbk_20cm_0730_min_monthly: "tbk_20cm_0730_monthly_min_C",
          tbk_20cm_0730_sum_monthly: "tbk_20cm_0730_monthly_sum_C",
          tbk_20cm_0730_count_monthly: "c_tbk_20cm_0730_monthly",
          tbk_20cm_1330_avg_monthly: "tbk_20cm_1330_monthly_avg_C",
          tbk_20cm_1330_mod_monthly: "tbk_20cm_1330_monthly_mod_C",
          tbk_20cm_1330_med_monthly: "tbk_20cm_1330_monthly_med_C",
          tbk_20cm_1330_max_monthly: "tbk_20cm_1330_monthly_max_C",
          tbk_20cm_1330_min_monthly: "tbk_20cm_1330_monthly_min_C",
          tbk_20cm_1330_sum_monthly: "tbk_20cm_1330_monthly_sum_C",
          tbk_20cm_1330_count_monthly: "c_tbk_20cm_1330_monthly",
          tbk_20cm_1730_avg_monthly: "tbk_20cm_1730_monthly_avg_C",
          tbk_20cm_1730_mod_monthly: "tbk_20cm_1730_monthly_mod_C",
          tbk_20cm_1730_med_monthly: "tbk_20cm_1730_monthly_med_C",
          tbk_20cm_1730_max_monthly: "tbk_20cm_1730_monthly_max_C",
          tbk_20cm_1730_min_monthly: "tbk_20cm_1730_monthly_min_C",
          tbk_20cm_1730_sum_monthly: "tbk_20cm_1730_monthly_sum_C",
          tbk_20cm_1730_count_monthly: "c_tbk_20cm_1730_monthly",
          tbk_50cm_0730_avg_monthly: "tbk_50cm_0730_monthly_avg_C",
          tbk_50cm_0730_mod_monthly: "tbk_50cm_0730_monthly_mod_C",
          tbk_50cm_0730_med_monthly: "tbk_50cm_0730_monthly_med_C",
          tbk_50cm_0730_max_monthly: "tbk_50cm_0730_monthly_max_C",
          tbk_50cm_0730_min_monthly: "tbk_50cm_0730_monthly_min_C",
          tbk_50cm_0730_sum_monthly: "tbk_50cm_0730_monthly_sum_C",
          tbk_50cm_0730_count_monthly: "c_tbk_50cm_0730_monthly",
          tbk_50cm_1330_avg_monthly: "tbk_50cm_1330_monthly_avg_C",
          tbk_50cm_1330_mod_monthly: "tbk_50cm_1330_monthly_mod_C",
          tbk_50cm_1330_med_monthly: "tbk_50cm_1330_monthly_med_C",
          tbk_50cm_1330_max_monthly: "tbk_50cm_1330_monthly_max_C",
          tbk_50cm_1330_min_monthly: "tbk_50cm_1330_monthly_min_C",
          tbk_50cm_1330_sum_monthly: "tbk_50cm_1330_monthly_sum_C",
          tbk_50cm_1330_count_monthly: "c_tbk_50cm_1330_monthly",
          tbk_50cm_1730_avg_monthly: "tbk_50cm_1730_monthly_avg_C",
          tbk_50cm_1730_mod_monthly: "tbk_50cm_1730_monthly_mod_C",
          tbk_50cm_1730_med_monthly: "tbk_50cm_1730_monthly_med_C",
          tbk_50cm_1730_max_monthly: "tbk_50cm_1730_monthly_max_C",
          tbk_50cm_1730_min_monthly: "tbk_50cm_1730_monthly_min_C",
          tbk_50cm_1730_sum_monthly: "tbk_50cm_1730_monthly_sum_C",
          tbk_50cm_1730_count_monthly: "c_tbk_50cm_1730_monthly",
          tbk_100cm_0730_avg_monthly: "tbk_100cm_0730_monthly_avg_C",
          tbk_100cm_0730_mod_monthly: "tbk_100cm_0730_monthly_mod_C",
          tbk_100cm_0730_med_monthly: "tbk_100cm_0730_monthly_med_C",
          tbk_100cm_0730_max_monthly: "tbk_100cm_0730_monthly_max_C",
          tbk_100cm_0730_min_monthly: "tbk_100cm_0730_monthly_min_C",
          tbk_100cm_0730_sum_monthly: "tbk_100cm_0730_monthly_sum_C",
          tbk_100cm_0730_count_monthly: "c_tbk_100cm_0730_monthly",
          tbk_100cm_1330_avg_monthly: "tbk_100cm_1330_monthly_avg_C",
          tbk_100cm_1330_mod_monthly: "tbk_100cm_1330_monthly_mod_C",
          tbk_100cm_1330_med_monthly: "tbk_100cm_1330_monthly_med_C",
          tbk_100cm_1330_max_monthly: "tbk_100cm_1330_monthly_max_C",
          tbk_100cm_1330_min_monthly: "tbk_100cm_1330_monthly_min_C",
          tbk_100cm_1330_sum_monthly: "tbk_100cm_1330_monthly_sum_C",
          tbk_100cm_1330_count_monthly: "c_tbk_100cm_1330_monthly",
          tbk_100cm_1730_avg_monthly: "tbk_100cm_1730_monthly_avg_C",
          tbk_100cm_1730_mod_monthly: "tbk_100cm_1730_monthly_mod_C",
          tbk_100cm_1730_med_monthly: "tbk_100cm_1730_monthly_med_C",
          tbk_100cm_1730_max_monthly: "tbk_100cm_1730_monthly_max_C",
          tbk_100cm_1730_min_monthly: "tbk_100cm_1730_monthly_min_C",
          tbk_100cm_1730_sum_monthly: "tbk_100cm_1730_monthly_sum_C",
          tbk_100cm_1730_count_monthly: "c_tbk_100cm_1730_monthly",
          tbk_150cm_0730_avg_monthly: "tbk_150cm_0730_monthly_avg_C",
          tbk_150cm_0730_mod_monthly: "tbk_150cm_0730_monthly_mod_C",
          tbk_150cm_0730_med_monthly: "tbk_150cm_0730_monthly_med_C",
          tbk_150cm_0730_max_monthly: "tbk_150cm_0730_monthly_max_C",
          tbk_150cm_0730_min_monthly: "tbk_150cm_0730_monthly_min_C",
          tbk_150cm_0730_sum_monthly: "tbk_150cm_0730_monthly_sum_C",
          tbk_150cm_0730_count_monthly: "c_tbk_150cm_0730_monthly",
          tbk_150cm_1330_avg_monthly: "tbk_150cm_1330_monthly_avg_C",
          tbk_150cm_1330_mod_monthly: "tbk_150cm_1330_monthly_mod_C",
          tbk_150cm_1330_med_monthly: "tbk_150cm_1330_monthly_med_C",
          tbk_150cm_1330_max_monthly: "tbk_150cm_1330_monthly_max_C",
          tbk_150cm_1330_min_monthly: "tbk_150cm_1330_monthly_min_C",
          tbk_150cm_1330_sum_monthly: "tbk_150cm_1330_monthly_sum_C",
          tbk_150cm_1330_count_monthly: "c_tbk_150cm_1330_monthly",
          tbk_150cm_1730_avg_monthly: "tbk_150cm_1730_monthly_avg_C",
          tbk_150cm_1730_mod_monthly: "tbk_150cm_1730_monthly_mod_C",
          tbk_150cm_1730_med_monthly: "tbk_150cm_1730_monthly_med_C",
          tbk_150cm_1730_max_monthly: "tbk_150cm_1730_monthly_max_C",
          tbk_150cm_1730_min_monthly: "tbk_150cm_1730_monthly_min_C",
          tbk_150cm_1730_sum_monthly: "tbk_150cm_1730_monthly_sum_C",
          tbk_150cm_1730_count_monthly: "c_tbk_150cm_1730_monthly",
          tbk_200cm_0730_avg_monthly: "tbk_200cm_0730_monthly_avg_C",
          tbk_200cm_0730_mod_monthly: "tbk_200cm_0730_monthly_mod_C",
          tbk_200cm_0730_med_monthly: "tbk_200cm_0730_monthly_med_C",
          tbk_200cm_0730_max_monthly: "tbk_200cm_0730_monthly_max_C",
          tbk_200cm_0730_min_monthly: "tbk_200cm_0730_monthly_min_C",
          tbk_200cm_0730_sum_monthly: "tbk_200cm_0730_monthly_sum_C",
          tbk_200cm_0730_count_monthly: "c_tbk_200cm_0730_monthly",
          tbk_200cm_1330_avg_monthly: "tbk_200cm_1330_monthly_avg_C",
          tbk_200cm_1330_mod_monthly: "tbk_200cm_1330_monthly_mod_C",
          tbk_200cm_1330_med_monthly: "tbk_200cm_1330_monthly_med_C",
          tbk_200cm_1330_max_monthly: "tbk_200cm_1330_monthly_max_C",
          tbk_200cm_1330_min_monthly: "tbk_200cm_1330_monthly_min_C",
          tbk_200cm_1330_sum_monthly: "tbk_200cm_1330_monthly_sum_C",
          tbk_200cm_1330_count_monthly: "c_tbk_200cm_1330_monthly",
          tbk_200cm_1730_avg_monthly: "tbk_200cm_1730_monthly_avg_C",
          tbk_200cm_1730_mod_monthly: "tbk_200cm_1730_monthly_mod_C",
          tbk_200cm_1730_med_monthly: "tbk_200cm_1730_monthly_med_C",
          tbk_200cm_1730_max_monthly: "tbk_200cm_1730_monthly_max_C",
          tbk_200cm_1730_min_monthly: "tbk_200cm_1730_monthly_min_C",
          tbk_200cm_1730_sum_monthly: "tbk_200cm_1730_monthly_sum_C",
          tbk_200cm_1730_count_monthly: "c_tbk_200cm_1730_monthly",

          tbb_5cm_0730_avg_monthly: "tbb_5cm_0730_monthly_avg_C",
          tbb_5cm_0730_mod_monthly: "tbb_5cm_0730_monthly_mod_C",
          tbb_5cm_0730_med_monthly: "tbb_5cm_0730_monthly_med_C",
          tbb_5cm_0730_max_monthly: "tbb_5cm_0730_monthly_max_C",
          tbb_5cm_0730_min_monthly: "tbb_5cm_0730_monthly_min_C",
          tbb_5cm_0730_sum_monthly: "tbb_5cm_0730_monthly_sum_C",
          tbb_5cm_0730_count_monthly: "c_tbb_5cm_0730_monthly",
          tbb_5cm_1330_avg_monthly: "tbb_5cm_1330_monthly_avg_C",
          tbb_5cm_1330_mod_monthly: "tbb_5cm_1330_monthly_mod_C",
          tbb_5cm_1330_med_monthly: "tbb_5cm_1330_monthly_med_C",
          tbb_5cm_1330_max_monthly: "tbb_5cm_1330_monthly_max_C",
          tbb_5cm_1330_min_monthly: "tbb_5cm_1330_monthly_min_C",
          tbb_5cm_1330_sum_monthly: "tbb_5cm_1330_monthly_sum_C",
          tbb_5cm_1330_count_monthly: "c_tbb_5cm_1330_monthly",
          tbb_5cm_1730_avg_monthly: "tbb_5cm_1730_monthly_avg_C",
          tbb_5cm_1730_mod_monthly: "tbb_5cm_1730_monthly_mod_C",
          tbb_5cm_1730_med_monthly: "tbb_5cm_1730_monthly_med_C",
          tbb_5cm_1730_max_monthly: "tbb_5cm_1730_monthly_max_C",
          tbb_5cm_1730_min_monthly: "tbb_5cm_1730_monthly_min_C",
          tbb_5cm_1730_sum_monthly: "tbb_5cm_1730_monthly_sum_C",
          tbb_5cm_1730_count_monthly: "c_tbb_5cm_1730_monthly",
          tbb_10cm_0730_avg_monthly: "tbb_10cm_0730_monthly_avg_C",
          tbb_10cm_0730_mod_monthly: "tbb_10cm_0730_monthly_mod_C",
          tbb_10cm_0730_med_monthly: "tbb_10cm_0730_monthly_med_C",
          tbb_10cm_0730_max_monthly: "tbb_10cm_0730_monthly_max_C",
          tbb_10cm_0730_min_monthly: "tbb_10cm_0730_monthly_min_C",
          tbb_10cm_0730_sum_monthly: "tbb_10cm_0730_monthly_sum_C",
          tbb_10cm_0730_count_monthly: "c_tbb_10cm_0730_monthly",
          tbb_10cm_1330_avg_monthly: "tbb_10cm_1330_monthly_avg_C",
          tbb_10cm_1330_mod_monthly: "tbb_10cm_1330_monthly_mod_C",
          tbb_10cm_1330_med_monthly: "tbb_10cm_1330_monthly_med_C",
          tbb_10cm_1330_max_monthly: "tbb_10cm_1330_monthly_max_C",
          tbb_10cm_1330_min_monthly: "tbb_10cm_1330_monthly_min_C",
          tbb_10cm_1330_sum_monthly: "tbb_10cm_1330_monthly_sum_C",
          tbb_10cm_1330_count_monthly: "c_tbb_10cm_1330_monthly",
          tbb_10cm_1730_avg_monthly: "tbb_10cm_1730_monthly_avg_C",
          tbb_10cm_1730_mod_monthly: "tbb_10cm_1730_monthly_mod_C",
          tbb_10cm_1730_med_monthly: "tbb_10cm_1730_monthly_med_C",
          tbb_10cm_1730_max_monthly: "tbb_10cm_1730_monthly_max_C",
          tbb_10cm_1730_min_monthly: "tbb_10cm_1730_monthly_min_C",
          tbb_10cm_1730_sum_monthly: "tbb_10cm_1730_monthly_sum_C",
          tbb_10cm_1730_count_monthly: "c_tbb_10cm_1730_monthly",
          tbb_20cm_0730_avg_monthly: "tbb_20cm_0730_monthly_avg_C",
          tbb_20cm_0730_mod_monthly: "tbb_20cm_0730_monthly_mod_C",
          tbb_20cm_0730_med_monthly: "tbb_20cm_0730_monthly_med_C",
          tbb_20cm_0730_max_monthly: "tbb_20cm_0730_monthly_max_C",
          tbb_20cm_0730_min_monthly: "tbb_20cm_0730_monthly_min_C",
          tbb_20cm_0730_sum_monthly: "tbb_20cm_0730_monthly_sum_C",
          tbb_20cm_0730_count_monthly: "c_tbb_20cm_0730_monthly",
          tbb_20cm_1330_avg_monthly: "tbb_20cm_1330_monthly_avg_C",
          tbb_20cm_1330_mod_monthly: "tbb_20cm_1330_monthly_mod_C",
          tbb_20cm_1330_med_monthly: "tbb_20cm_1330_monthly_med_C",
          tbb_20cm_1330_max_monthly: "tbb_20cm_1330_monthly_max_C",
          tbb_20cm_1330_min_monthly: "tbb_20cm_1330_monthly_min_C",
          tbb_20cm_1330_sum_monthly: "tbb_20cm_1330_monthly_sum_C",
          tbb_20cm_1330_count_monthly: "c_tbb_20cm_1330_monthly",
          tbb_20cm_1730_avg_monthly: "tbb_20cm_1730_monthly_avg_C",
          tbb_20cm_1730_mod_monthly: "tbb_20cm_1730_monthly_mod_C",
          tbb_20cm_1730_med_monthly: "tbb_20cm_1730_monthly_med_C",
          tbb_20cm_1730_max_monthly: "tbb_20cm_1730_monthly_max_C",
          tbb_20cm_1730_min_monthly: "tbb_20cm_1730_monthly_min_C",
          tbb_20cm_1730_sum_monthly: "tbb_20cm_1730_monthly_sum_C",
          tbb_20cm_1730_count_monthly: "c_tbb_20cm_1730_monthly",
          tbb_50cm_0730_avg_monthly: "tbb_50cm_0730_monthly_avg_C",
          tbb_50cm_0730_mod_monthly: "tbb_50cm_0730_monthly_mod_C",
          tbb_50cm_0730_med_monthly: "tbb_50cm_0730_monthly_med_C",
          tbb_50cm_0730_max_monthly: "tbb_50cm_0730_monthly_max_C",
          tbb_50cm_0730_min_monthly: "tbb_50cm_0730_monthly_min_C",
          tbb_50cm_0730_sum_monthly: "tbb_50cm_0730_monthly_sum_C",
          tbb_50cm_0730_count_monthly: "c_tbb_50cm_0730_monthly",
          tbb_50cm_1330_avg_monthly: "tbb_50cm_1330_monthly_avg_C",
          tbb_50cm_1330_mod_monthly: "tbb_50cm_1330_monthly_mod_C",
          tbb_50cm_1330_med_monthly: "tbb_50cm_1330_monthly_med_C",
          tbb_50cm_1330_max_monthly: "tbb_50cm_1330_monthly_max_C",
          tbb_50cm_1330_min_monthly: "tbb_50cm_1330_monthly_min_C",
          tbb_50cm_1330_sum_monthly: "tbb_50cm_1330_monthly_sum_C",
          tbb_50cm_1330_count_monthly: "c_tbb_50cm_1330_monthly",
          tbb_50cm_1730_avg_monthly: "tbb_50cm_1730_monthly_avg_C",
          tbb_50cm_1730_mod_monthly: "tbb_50cm_1730_monthly_mod_C",
          tbb_50cm_1730_med_monthly: "tbb_50cm_1730_monthly_med_C",
          tbb_50cm_1730_max_monthly: "tbb_50cm_1730_monthly_max_C",
          tbb_50cm_1730_min_monthly: "tbb_50cm_1730_monthly_min_C",
          tbb_50cm_1730_sum_monthly: "tbb_50cm_1730_monthly_sum_C",
          tbb_50cm_1730_count_monthly: "c_tbb_50cm_1730_monthly",
          tbb_100cm_0730_avg_monthly: "tbb_100cm_0730_monthly_avg_C",
          tbb_100cm_0730_mod_monthly: "tbb_100cm_0730_monthly_mod_C",
          tbb_100cm_0730_med_monthly: "tbb_100cm_0730_monthly_med_C",
          tbb_100cm_0730_max_monthly: "tbb_100cm_0730_monthly_max_C",
          tbb_100cm_0730_min_monthly: "tbb_100cm_0730_monthly_min_C",
          tbb_100cm_0730_sum_monthly: "tbb_100cm_0730_monthly_sum_C",
          tbb_100cm_0730_count_monthly: "c_tbb_100cm_0730_monthly",
          tbb_100cm_1330_avg_monthly: "tbb_100cm_1330_monthly_avg_C",
          tbb_100cm_1330_mod_monthly: "tbb_100cm_1330_monthly_mod_C",
          tbb_100cm_1330_med_monthly: "tbb_100cm_1330_monthly_med_C",
          tbb_100cm_1330_max_monthly: "tbb_100cm_1330_monthly_max_C",
          tbb_100cm_1330_min_monthly: "tbb_100cm_1330_monthly_min_C",
          tbb_100cm_1330_sum_monthly: "tbb_100cm_1330_monthly_sum_C",
          tbb_100cm_1330_count_monthly: "c_tbb_100cm_1330_monthly",
          tbb_100cm_1730_avg_monthly: "tbb_100cm_1730_monthly_avg_C",
          tbb_100cm_1730_mod_monthly: "tbb_100cm_1730_monthly_mod_C",
          tbb_100cm_1730_med_monthly: "tbb_100cm_1730_monthly_med_C",
          tbb_100cm_1730_max_monthly: "tbb_100cm_1730_monthly_max_C",
          tbb_100cm_1730_min_monthly: "tbb_100cm_1730_monthly_min_C",
          tbb_100cm_1730_sum_monthly: "tbb_100cm_1730_monthly_sum_C",
          tbb_100cm_1730_count_monthly: "c_tbb_100cm_1730_monthly",
          tbb_150cm_0730_avg_monthly: "tbb_150cm_0730_monthly_avg_C",
          tbb_150cm_0730_mod_monthly: "tbb_150cm_0730_monthly_mod_C",
          tbb_150cm_0730_med_monthly: "tbb_150cm_0730_monthly_med_C",
          tbb_150cm_0730_max_monthly: "tbb_150cm_0730_monthly_max_C",
          tbb_150cm_0730_min_monthly: "tbb_150cm_0730_monthly_min_C",
          tbb_150cm_0730_sum_monthly: "tbb_150cm_0730_monthly_sum_C",
          tbb_150cm_0730_count_monthly: "c_tbb_150cm_0730_monthly",
          tbb_150cm_1330_avg_monthly: "tbb_150cm_1330_monthly_avg_C",
          tbb_150cm_1330_mod_monthly: "tbb_150cm_1330_monthly_mod_C",
          tbb_150cm_1330_med_monthly: "tbb_150cm_1330_monthly_med_C",
          tbb_150cm_1330_max_monthly: "tbb_150cm_1330_monthly_max_C",
          tbb_150cm_1330_min_monthly: "tbb_150cm_1330_monthly_min_C",
          tbb_150cm_1330_sum_monthly: "tbb_150cm_1330_monthly_sum_C",
          tbb_150cm_1330_count_monthly: "c_tbb_150cm_1330_monthly",
          tbb_150cm_1730_avg_monthly: "tbb_150cm_1730_monthly_avg_C",
          tbb_150cm_1730_mod_monthly: "tbb_150cm_1730_monthly_mod_C",
          tbb_150cm_1730_med_monthly: "tbb_150cm_1730_monthly_med_C",
          tbb_150cm_1730_max_monthly: "tbb_150cm_1730_monthly_max_C",
          tbb_150cm_1730_min_monthly: "tbb_150cm_1730_monthly_min_C",
          tbb_150cm_1730_sum_monthly: "tbb_150cm_1730_monthly_sum_C",
          tbb_150cm_1730_count_monthly: "c_tbb_150cm_1730_monthly",
          tbb_200cm_0730_avg_monthly: "tbb_200cm_0730_monthly_avg_C",
          tbb_200cm_0730_mod_monthly: "tbb_200cm_0730_monthly_mod_C",
          tbb_200cm_0730_med_monthly: "tbb_200cm_0730_monthly_med_C",
          tbb_200cm_0730_max_monthly: "tbb_200cm_0730_monthly_max_C",
          tbb_200cm_0730_min_monthly: "tbb_200cm_0730_monthly_min_C",
          tbb_200cm_0730_sum_monthly: "tbb_200cm_0730_monthly_sum_C",
          tbb_200cm_0730_count_monthly: "c_tbb_200cm_0730_monthly",
          tbb_200cm_1330_avg_monthly: "tbb_200cm_1330_monthly_avg_C",
          tbb_200cm_1330_mod_monthly: "tbb_200cm_1330_monthly_mod_C",
          tbb_200cm_1330_med_monthly: "tbb_200cm_1330_monthly_med_C",
          tbb_200cm_1330_max_monthly: "tbb_200cm_1330_monthly_max_C",
          tbb_200cm_1330_min_monthly: "tbb_200cm_1330_monthly_min_C",
          tbb_200cm_1330_sum_monthly: "tbb_200cm_1330_monthly_sum_C",
          tbb_200cm_1330_count_monthly: "c_tbb_200cm_1330_monthly",
          tbb_200cm_1730_avg_monthly: "tbb_200cm_1730_monthly_avg_C",
          tbb_200cm_1730_mod_monthly: "tbb_200cm_1730_monthly_mod_C",
          tbb_200cm_1730_med_monthly: "tbb_200cm_1730_monthly_med_C",
          tbb_200cm_1730_max_monthly: "tbb_200cm_1730_monthly_max_C",
          tbb_200cm_1730_min_monthly: "tbb_200cm_1730_monthly_min_C",
          tbb_200cm_1730_sum_monthly: "tbb_200cm_1730_monthly_sum_C",
          tbb_200cm_1730_count_monthly: "c_tbb_200cm_1730_monthly",

          rh_5cm_0730_avg_monthly: "rh_5cm_0730_monthly_avg_PC",
          rh_5cm_0730_mod_monthly: "rh_5cm_0730_monthly_max_PC",
          rh_5cm_0730_med_monthly: "rh_5cm_0730_monthly_min_PC",
          rh_5cm_0730_max_monthly: "rh_5cm_0730_monthly_max_PC",
          rh_5cm_0730_min_monthly: "rh_5cm_0730_monthly_min_PC",
          rh_5cm_0730_sum_monthly: "rh_5cm_0730_monthly_sum_PC",
          rh_5cm_0730_count_monthly: "c_rh_5cm_0730_monthly",
          rh_5cm_1330_avg_monthly: "rh_5cm_1330_monthly_avg_PC",
          rh_5cm_1330_mod_monthly: "rh_5cm_1330_monthly_max_PC",
          rh_5cm_1330_med_monthly: "rh_5cm_1330_monthly_min_PC",
          rh_5cm_1330_max_monthly: "rh_5cm_1330_monthly_max_PC",
          rh_5cm_1330_min_monthly: "rh_5cm_1330_monthly_min_PC",
          rh_5cm_1330_sum_monthly: "rh_5cm_1330_monthly_sum_PC",
          rh_5cm_1330_count_monthly: "c_rh_5cm_1330_monthly",
          rh_5cm_1730_avg_monthly: "rh_5cm_1730_monthly_avg_PC",
          rh_5cm_1730_mod_monthly: "rh_5cm_1730_monthly_max_PC",
          rh_5cm_1730_med_monthly: "rh_5cm_1730_monthly_min_PC",
          rh_5cm_1730_max_monthly: "rh_5cm_1730_monthly_max_PC",
          rh_5cm_1730_min_monthly: "rh_5cm_1730_monthly_min_PC",
          rh_5cm_1730_sum_monthly: "rh_5cm_1730_monthly_sum_PC",
          rh_5cm_1730_count_monthly: "c_rh_5cm_1730_monthly",
          rh_10cm_0730_avg_monthly: "rh_10cm_0730_monthly_avg_PC",
          rh_10cm_0730_mod_monthly: "rh_10cm_0730_monthly_max_PC",
          rh_10cm_0730_med_monthly: "rh_10cm_0730_monthly_min_PC",
          rh_10cm_0730_max_monthly: "rh_10cm_0730_monthly_max_PC",
          rh_10cm_0730_min_monthly: "rh_10cm_0730_monthly_min_PC",
          rh_10cm_0730_sum_monthly: "rh_10cm_0730_monthly_sum_PC",
          rh_10cm_0730_count_monthly: "c_rh_10cm_0730_monthly",
          rh_10cm_1330_avg_monthly: "rh_10cm_1330_monthly_avg_PC",
          rh_10cm_1330_mod_monthly: "rh_10cm_1330_monthly_max_PC",
          rh_10cm_1330_med_monthly: "rh_10cm_1330_monthly_min_PC",
          rh_10cm_1330_max_monthly: "rh_10cm_1330_monthly_max_PC",
          rh_10cm_1330_min_monthly: "rh_10cm_1330_monthly_min_PC",
          rh_10cm_1330_sum_monthly: "rh_10cm_1330_monthly_sum_PC",
          rh_10cm_1330_count_monthly: "c_rh_10cm_1330_monthly",
          rh_10cm_1730_avg_monthly: "rh_10cm_1730_monthly_avg_PC",
          rh_10cm_1730_mod_monthly: "rh_10cm_1730_monthly_max_PC",
          rh_10cm_1730_med_monthly: "rh_10cm_1730_monthly_min_PC",
          rh_10cm_1730_max_monthly: "rh_10cm_1730_monthly_max_PC",
          rh_10cm_1730_min_monthly: "rh_10cm_1730_monthly_min_PC",
          rh_10cm_1730_sum_monthly: "rh_10cm_1730_monthly_sum_PC",
          rh_10cm_1730_count_monthly: "c_rh_10cm_1730_monthly",
          rh_20cm_0730_avg_monthly: "rh_20cm_0730_monthly_avg_PC",
          rh_20cm_0730_mod_monthly: "rh_20cm_0730_monthly_max_PC",
          rh_20cm_0730_med_monthly: "rh_20cm_0730_monthly_min_PC",
          rh_20cm_0730_max_monthly: "rh_20cm_0730_monthly_max_PC",
          rh_20cm_0730_min_monthly: "rh_20cm_0730_monthly_min_PC",
          rh_20cm_0730_sum_monthly: "rh_20cm_0730_monthly_sum_PC",
          rh_20cm_0730_count_monthly: "c_rh_20cm_0730_monthly",
          rh_20cm_1330_avg_monthly: "rh_20cm_1330_monthly_avg_PC",
          rh_20cm_1330_mod_monthly: "rh_20cm_1330_monthly_max_PC",
          rh_20cm_1330_med_monthly: "rh_20cm_1330_monthly_min_PC",
          rh_20cm_1330_max_monthly: "rh_20cm_1330_monthly_max_PC",
          rh_20cm_1330_min_monthly: "rh_20cm_1330_monthly_min_PC",
          rh_20cm_1330_sum_monthly: "rh_20cm_1330_monthly_sum_PC",
          rh_20cm_1330_count_monthly: "c_rh_20cm_1330_monthly",
          rh_20cm_1730_avg_monthly: "rh_20cm_1730_monthly_avg_PC",
          rh_20cm_1730_mod_monthly: "rh_20cm_1730_monthly_max_PC",
          rh_20cm_1730_med_monthly: "rh_20cm_1730_monthly_min_PC",
          rh_20cm_1730_max_monthly: "rh_20cm_1730_monthly_max_PC",
          rh_20cm_1730_min_monthly: "rh_20cm_1730_monthly_min_PC",
          rh_20cm_1730_sum_monthly: "rh_20cm_1730_monthly_sum_PC",
          rh_20cm_1730_count_monthly: "c_rh_20cm_1730_monthly",
          rh_50cm_0730_avg_monthly: "rh_50cm_0730_monthly_avg_PC",
          rh_50cm_0730_mod_monthly: "rh_50cm_0730_monthly_max_PC",
          rh_50cm_0730_med_monthly: "rh_50cm_0730_monthly_min_PC",
          rh_50cm_0730_max_monthly: "rh_50cm_0730_monthly_max_PC",
          rh_50cm_0730_min_monthly: "rh_50cm_0730_monthly_min_PC",
          rh_50cm_0730_sum_monthly: "rh_50cm_0730_monthly_sum_PC",
          rh_50cm_0730_count_monthly: "c_rh_50cm_0730_monthly",
          rh_50cm_1330_avg_monthly: "rh_50cm_1330_monthly_avg_PC",
          rh_50cm_1330_mod_monthly: "rh_50cm_1330_monthly_max_PC",
          rh_50cm_1330_med_monthly: "rh_50cm_1330_monthly_min_PC",
          rh_50cm_1330_max_monthly: "rh_50cm_1330_monthly_max_PC",
          rh_50cm_1330_min_monthly: "rh_50cm_1330_monthly_min_PC",
          rh_50cm_1330_sum_monthly: "rh_50cm_1330_monthly_sum_PC",
          rh_50cm_1330_count_monthly: "c_rh_50cm_1330_monthly",
          rh_50cm_1730_avg_monthly: "rh_50cm_1730_monthly_avg_PC",
          rh_50cm_1730_mod_monthly: "rh_50cm_1730_monthly_max_PC",
          rh_50cm_1730_med_monthly: "rh_50cm_1730_monthly_min_PC",
          rh_50cm_1730_max_monthly: "rh_50cm_1730_monthly_max_PC",
          rh_50cm_1730_min_monthly: "rh_50cm_1730_monthly_min_PC",
          rh_50cm_1730_sum_monthly: "rh_50cm_1730_monthly_sum_PC",
          rh_50cm_1730_count_monthly: "c_rh_50cm_1730_monthly",
          rh_100cm_0730_avg_monthly: "rh_100cm_0730_monthly_avg_PC",
          rh_100cm_0730_mod_monthly: "rh_100cm_0730_monthly_max_PC",
          rh_100cm_0730_med_monthly: "rh_100cm_0730_monthly_min_PC",
          rh_100cm_0730_max_monthly: "rh_100cm_0730_monthly_max_PC",
          rh_100cm_0730_min_monthly: "rh_100cm_0730_monthly_min_PC",
          rh_100cm_0730_sum_monthly: "rh_100cm_0730_monthly_sum_PC",
          rh_100cm_0730_count_monthly: "c_rh_100cm_0730_monthly",
          rh_100cm_1330_avg_monthly: "rh_100cm_1330_monthly_avg_PC",
          rh_100cm_1330_mod_monthly: "rh_100cm_1330_monthly_max_PC",
          rh_100cm_1330_med_monthly: "rh_100cm_1330_monthly_min_PC",
          rh_100cm_1330_max_monthly: "rh_100cm_1330_monthly_max_PC",
          rh_100cm_1330_min_monthly: "rh_100cm_1330_monthly_min_PC",
          rh_100cm_1330_sum_monthly: "rh_100cm_1330_monthly_sum_PC",
          rh_100cm_1330_count_monthly: "c_rh_100cm_1330_monthly",
          rh_100cm_1730_avg_monthly: "rh_100cm_1730_monthly_avg_PC",
          rh_100cm_1730_mod_monthly: "rh_100cm_1730_monthly_max_PC",
          rh_100cm_1730_med_monthly: "rh_100cm_1730_monthly_min_PC",
          rh_100cm_1730_max_monthly: "rh_100cm_1730_monthly_max_PC",
          rh_100cm_1730_min_monthly: "rh_100cm_1730_monthly_min_PC",
          rh_100cm_1730_sum_monthly: "rh_100cm_1730_monthly_sum_PC",
          rh_100cm_1730_count_monthly: "c_rh_100cm_1730_monthly",
          rh_150cm_0730_avg_monthly: "rh_150cm_0730_monthly_avg_PC",
          rh_150cm_0730_mod_monthly: "rh_150cm_0730_monthly_max_PC",
          rh_150cm_0730_med_monthly: "rh_150cm_0730_monthly_min_PC",
          rh_150cm_0730_max_monthly: "rh_150cm_0730_monthly_max_PC",
          rh_150cm_0730_min_monthly: "rh_150cm_0730_monthly_min_PC",
          rh_150cm_0730_sum_monthly: "rh_150cm_0730_monthly_sum_PC",
          rh_150cm_0730_count_monthly: "c_rh_150cm_0730_monthly",
          rh_150cm_1330_avg_monthly: "rh_150cm_1330_monthly_avg_PC",
          rh_150cm_1330_mod_monthly: "rh_150cm_1330_monthly_max_PC",
          rh_150cm_1330_med_monthly: "rh_150cm_1330_monthly_min_PC",
          rh_150cm_1330_max_monthly: "rh_150cm_1330_monthly_max_PC",
          rh_150cm_1330_min_monthly: "rh_150cm_1330_monthly_min_PC",
          rh_150cm_1330_sum_monthly: "rh_150cm_1330_monthly_sum_PC",
          rh_150cm_1330_count_monthly: "c_rh_150cm_1330_monthly",
          rh_150cm_1730_avg_monthly: "rh_150cm_1730_monthly_avg_PC",
          rh_150cm_1730_mod_monthly: "rh_150cm_1730_monthly_max_PC",
          rh_150cm_1730_med_monthly: "rh_150cm_1730_monthly_min_PC",
          rh_150cm_1730_max_monthly: "rh_150cm_1730_monthly_max_PC",
          rh_150cm_1730_min_monthly: "rh_150cm_1730_monthly_min_PC",
          rh_150cm_1730_sum_monthly: "rh_150cm_1730_monthly_sum_PC",
          rh_150cm_1730_count_monthly: "c_rh_150cm_1730_monthly",
          rh_200cm_0730_avg_monthly: "rh_200cm_0730_monthly_avg_PC",
          rh_200cm_0730_mod_monthly: "rh_200cm_0730_monthly_max_PC",
          rh_200cm_0730_med_monthly: "rh_200cm_0730_monthly_min_PC",
          rh_200cm_0730_max_monthly: "rh_200cm_0730_monthly_max_PC",
          rh_200cm_0730_min_monthly: "rh_200cm_0730_monthly_min_PC",
          rh_200cm_0730_sum_monthly: "rh_200cm_0730_monthly_sum_PC",
          rh_200cm_0730_count_monthly: "c_rh_200cm_0730_monthly",
          rh_200cm_1330_avg_monthly: "rh_200cm_1330_monthly_avg_PC",
          rh_200cm_1330_mod_monthly: "rh_200cm_1330_monthly_max_PC",
          rh_200cm_1330_med_monthly: "rh_200cm_1330_monthly_min_PC",
          rh_200cm_1330_max_monthly: "rh_200cm_1330_monthly_max_PC",
          rh_200cm_1330_min_monthly: "rh_200cm_1330_monthly_min_PC",
          rh_200cm_1330_sum_monthly: "rh_200cm_1330_monthly_sum_PC",
          rh_200cm_1330_count_monthly: "c_rh_200cm_1330_monthly",
          rh_200cm_1730_avg_monthly: "rh_200cm_1730_monthly_avg_PC",
          rh_200cm_1730_mod_monthly: "rh_200cm_1730_monthly_max_PC",
          rh_200cm_1730_med_monthly: "rh_200cm_1730_monthly_min_PC",
          rh_200cm_1730_max_monthly: "rh_200cm_1730_monthly_max_PC",
          rh_200cm_1730_min_monthly: "rh_200cm_1730_monthly_min_PC",
          rh_200cm_1730_sum_monthly: "rh_200cm_1730_monthly_sum_PC",
          rh_200cm_1730_count_monthly: "c_rh_200cm_1730_monthly",
        },
      },
    ];
    return listAttr;
  },

  getAttrMonthlyDecimal() {
    const listAttr = [
      {
        label: "Suhu",
        attr: {
          tbk_5cm_0730_avg_monthly: 1,
          tbk_5cm_0730_mod_monthly: 1,
          tbk_5cm_0730_med_monthly: 1,
          tbk_5cm_0730_max_monthly: 1,
          tbk_5cm_0730_min_monthly: 1,
          tbk_5cm_0730_sum_monthly: 1,
          tbk_5cm_0730_count_monthly: 1,
          tbk_5cm_1330_avg_monthly: 1,
          tbk_5cm_1330_mod_monthly: 1,
          tbk_5cm_1330_med_monthly: 1,
          tbk_5cm_1330_max_monthly: 1,
          tbk_5cm_1330_min_monthly: 1,
          tbk_5cm_1330_sum_monthly: 1,
          tbk_5cm_1330_count_monthly: 1,
          tbk_5cm_1730_avg_monthly: 1,
          tbk_5cm_1730_mod_monthly: 1,
          tbk_5cm_1730_med_monthly: 1,
          tbk_5cm_1730_max_monthly: 1,
          tbk_5cm_1730_min_monthly: 1,
          tbk_5cm_1730_sum_monthly: 1,
          tbk_5cm_1730_count_monthly: 1,
          tbk_10cm_0730_avg_monthly: 1,
          tbk_10cm_0730_mod_monthly: 1,
          tbk_10cm_0730_med_monthly: 1,
          tbk_10cm_0730_max_monthly: 1,
          tbk_10cm_0730_min_monthly: 1,
          tbk_10cm_0730_sum_monthly: 1,
          tbk_10cm_0730_count_monthly: 1,
          tbk_10cm_1330_avg_monthly: 1,
          tbk_10cm_1330_mod_monthly: 1,
          tbk_10cm_1330_med_monthly: 1,
          tbk_10cm_1330_max_monthly: 1,
          tbk_10cm_1330_min_monthly: 1,
          tbk_10cm_1330_sum_monthly: 1,
          tbk_10cm_1330_count_monthly: 1,
          tbk_10cm_1730_avg_monthly: 1,
          tbk_10cm_1730_mod_monthly: 1,
          tbk_10cm_1730_med_monthly: 1,
          tbk_10cm_1730_max_monthly: 1,
          tbk_10cm_1730_min_monthly: 1,
          tbk_10cm_1730_sum_monthly: 1,
          tbk_10cm_1730_count_monthly: 1,
          tbk_20cm_0730_avg_monthly: 1,
          tbk_20cm_0730_mod_monthly: 1,
          tbk_20cm_0730_med_monthly: 1,
          tbk_20cm_0730_max_monthly: 1,
          tbk_20cm_0730_min_monthly: 1,
          tbk_20cm_0730_sum_monthly: 1,
          tbk_20cm_0730_count_monthly: 1,
          tbk_20cm_1330_avg_monthly: 1,
          tbk_20cm_1330_mod_monthly: 1,
          tbk_20cm_1330_med_monthly: 1,
          tbk_20cm_1330_max_monthly: 1,
          tbk_20cm_1330_min_monthly: 1,
          tbk_20cm_1330_sum_monthly: 1,
          tbk_20cm_1330_count_monthly: 1,
          tbk_20cm_1730_avg_monthly: 1,
          tbk_20cm_1730_mod_monthly: 1,
          tbk_20cm_1730_med_monthly: 1,
          tbk_20cm_1730_max_monthly: 1,
          tbk_20cm_1730_min_monthly: 1,
          tbk_20cm_1730_sum_monthly: 1,
          tbk_20cm_1730_count_monthly: 1,
          tbk_50cm_0730_avg_monthly: 1,
          tbk_50cm_0730_mod_monthly: 1,
          tbk_50cm_0730_med_monthly: 1,
          tbk_50cm_0730_max_monthly: 1,
          tbk_50cm_0730_min_monthly: 1,
          tbk_50cm_0730_sum_monthly: 1,
          tbk_50cm_0730_count_monthly: 1,
          tbk_50cm_1330_avg_monthly: 1,
          tbk_50cm_1330_mod_monthly: 1,
          tbk_50cm_1330_med_monthly: 1,
          tbk_50cm_1330_max_monthly: 1,
          tbk_50cm_1330_min_monthly: 1,
          tbk_50cm_1330_sum_monthly: 1,
          tbk_50cm_1330_count_monthly: 1,
          tbk_50cm_1730_avg_monthly: 1,
          tbk_50cm_1730_mod_monthly: 1,
          tbk_50cm_1730_med_monthly: 1,
          tbk_50cm_1730_max_monthly: 1,
          tbk_50cm_1730_min_monthly: 1,
          tbk_50cm_1730_sum_monthly: 1,
          tbk_50cm_1730_count_monthly: 1,
          tbk_100cm_0730_avg_monthly: 1,
          tbk_100cm_0730_mod_monthly: 1,
          tbk_100cm_0730_med_monthly: 1,
          tbk_100cm_0730_max_monthly: 1,
          tbk_100cm_0730_min_monthly: 1,
          tbk_100cm_0730_sum_monthly: 1,
          tbk_100cm_0730_count_monthly: 1,
          tbk_100cm_1330_avg_monthly: 1,
          tbk_100cm_1330_mod_monthly: 1,
          tbk_100cm_1330_med_monthly: 1,
          tbk_100cm_1330_max_monthly: 1,
          tbk_100cm_1330_min_monthly: 1,
          tbk_100cm_1330_sum_monthly: 1,
          tbk_100cm_1330_count_monthly: 1,
          tbk_100cm_1730_avg_monthly: 1,
          tbk_100cm_1730_mod_monthly: 1,
          tbk_100cm_1730_med_monthly: 1,
          tbk_100cm_1730_max_monthly: 1,
          tbk_100cm_1730_min_monthly: 1,
          tbk_100cm_1730_sum_monthly: 1,
          tbk_100cm_1730_count_monthly: 1,
          tbk_150cm_0730_avg_monthly: 1,
          tbk_150cm_0730_mod_monthly: 1,
          tbk_150cm_0730_med_monthly: 1,
          tbk_150cm_0730_max_monthly: 1,
          tbk_150cm_0730_min_monthly: 1,
          tbk_150cm_0730_sum_monthly: 1,
          tbk_150cm_0730_count_monthly: 1,
          tbk_150cm_1330_avg_monthly: 1,
          tbk_150cm_1330_mod_monthly: 1,
          tbk_150cm_1330_med_monthly: 1,
          tbk_150cm_1330_max_monthly: 1,
          tbk_150cm_1330_min_monthly: 1,
          tbk_150cm_1330_sum_monthly: 1,
          tbk_150cm_1330_count_monthly: 1,
          tbk_150cm_1730_avg_monthly: 1,
          tbk_150cm_1730_mod_monthly: 1,
          tbk_150cm_1730_med_monthly: 1,
          tbk_150cm_1730_max_monthly: 1,
          tbk_150cm_1730_min_monthly: 1,
          tbk_150cm_1730_sum_monthly: 1,
          tbk_150cm_1730_count_monthly: 1,
          tbk_200cm_0730_avg_monthly: 1,
          tbk_200cm_0730_mod_monthly: 1,
          tbk_200cm_0730_med_monthly: 1,
          tbk_200cm_0730_max_monthly: 1,
          tbk_200cm_0730_min_monthly: 1,
          tbk_200cm_0730_sum_monthly: 1,
          tbk_200cm_0730_count_monthly: 1,
          tbk_200cm_1330_avg_monthly: 1,
          tbk_200cm_1330_mod_monthly: 1,
          tbk_200cm_1330_med_monthly: 1,
          tbk_200cm_1330_max_monthly: 1,
          tbk_200cm_1330_min_monthly: 1,
          tbk_200cm_1330_sum_monthly: 1,
          tbk_200cm_1330_count_monthly: 1,
          tbk_200cm_1730_avg_monthly: 1,
          tbk_200cm_1730_mod_monthly: 1,
          tbk_200cm_1730_med_monthly: 1,
          tbk_200cm_1730_max_monthly: 1,
          tbk_200cm_1730_min_monthly: 1,
          tbk_200cm_1730_sum_monthly: 1,
          tbk_200cm_1730_count_monthly: 1,

          tbb_5cm_0730_avg_monthly: 1,
          tbb_5cm_0730_mod_monthly: 1,
          tbb_5cm_0730_med_monthly: 1,
          tbb_5cm_0730_max_monthly: 1,
          tbb_5cm_0730_min_monthly: 1,
          tbb_5cm_0730_sum_monthly: 1,
          tbb_5cm_0730_count_monthly: 1,
          tbb_5cm_1330_avg_monthly: 1,
          tbb_5cm_1330_mod_monthly: 1,
          tbb_5cm_1330_med_monthly: 1,
          tbb_5cm_1330_max_monthly: 1,
          tbb_5cm_1330_min_monthly: 1,
          tbb_5cm_1330_sum_monthly: 1,
          tbb_5cm_1330_count_monthly: 1,
          tbb_5cm_1730_avg_monthly: 1,
          tbb_5cm_1730_mod_monthly: 1,
          tbb_5cm_1730_med_monthly: 1,
          tbb_5cm_1730_max_monthly: 1,
          tbb_5cm_1730_min_monthly: 1,
          tbb_5cm_1730_sum_monthly: 1,
          tbb_5cm_1730_count_monthly: 1,
          tbb_10cm_0730_avg_monthly: 1,
          tbb_10cm_0730_mod_monthly: 1,
          tbb_10cm_0730_med_monthly: 1,
          tbb_10cm_0730_max_monthly: 1,
          tbb_10cm_0730_min_monthly: 1,
          tbb_10cm_0730_sum_monthly: 1,
          tbb_10cm_0730_count_monthly: 1,
          tbb_10cm_1330_avg_monthly: 1,
          tbb_10cm_1330_mod_monthly: 1,
          tbb_10cm_1330_med_monthly: 1,
          tbb_10cm_1330_max_monthly: 1,
          tbb_10cm_1330_min_monthly: 1,
          tbb_10cm_1330_sum_monthly: 1,
          tbb_10cm_1330_count_monthly: 1,
          tbb_10cm_1730_avg_monthly: 1,
          tbb_10cm_1730_mod_monthly: 1,
          tbb_10cm_1730_med_monthly: 1,
          tbb_10cm_1730_max_monthly: 1,
          tbb_10cm_1730_min_monthly: 1,
          tbb_10cm_1730_sum_monthly: 1,
          tbb_10cm_1730_count_monthly: 1,
          tbb_20cm_0730_avg_monthly: 1,
          tbb_20cm_0730_mod_monthly: 1,
          tbb_20cm_0730_med_monthly: 1,
          tbb_20cm_0730_max_monthly: 1,
          tbb_20cm_0730_min_monthly: 1,
          tbb_20cm_0730_sum_monthly: 1,
          tbb_20cm_0730_count_monthly: 1,
          tbb_20cm_1330_avg_monthly: 1,
          tbb_20cm_1330_mod_monthly: 1,
          tbb_20cm_1330_med_monthly: 1,
          tbb_20cm_1330_max_monthly: 1,
          tbb_20cm_1330_min_monthly: 1,
          tbb_20cm_1330_sum_monthly: 1,
          tbb_20cm_1330_count_monthly: 1,
          tbb_20cm_1730_avg_monthly: 1,
          tbb_20cm_1730_mod_monthly: 1,
          tbb_20cm_1730_med_monthly: 1,
          tbb_20cm_1730_max_monthly: 1,
          tbb_20cm_1730_min_monthly: 1,
          tbb_20cm_1730_sum_monthly: 1,
          tbb_20cm_1730_count_monthly: 1,
          tbb_50cm_0730_avg_monthly: 1,
          tbb_50cm_0730_mod_monthly: 1,
          tbb_50cm_0730_med_monthly: 1,
          tbb_50cm_0730_max_monthly: 1,
          tbb_50cm_0730_min_monthly: 1,
          tbb_50cm_0730_sum_monthly: 1,
          tbb_50cm_0730_count_monthly: 1,
          tbb_50cm_1330_avg_monthly: 1,
          tbb_50cm_1330_mod_monthly: 1,
          tbb_50cm_1330_med_monthly: 1,
          tbb_50cm_1330_max_monthly: 1,
          tbb_50cm_1330_min_monthly: 1,
          tbb_50cm_1330_sum_monthly: 1,
          tbb_50cm_1330_count_monthly: 1,
          tbb_50cm_1730_avg_monthly: 1,
          tbb_50cm_1730_mod_monthly: 1,
          tbb_50cm_1730_med_monthly: 1,
          tbb_50cm_1730_max_monthly: 1,
          tbb_50cm_1730_min_monthly: 1,
          tbb_50cm_1730_sum_monthly: 1,
          tbb_50cm_1730_count_monthly: 1,
          tbb_100cm_0730_avg_monthly: 1,
          tbb_100cm_0730_mod_monthly: 1,
          tbb_100cm_0730_med_monthly: 1,
          tbb_100cm_0730_max_monthly: 1,
          tbb_100cm_0730_min_monthly: 1,
          tbb_100cm_0730_sum_monthly: 1,
          tbb_100cm_0730_count_monthly: 1,
          tbb_100cm_1330_avg_monthly: 1,
          tbb_100cm_1330_mod_monthly: 1,
          tbb_100cm_1330_med_monthly: 1,
          tbb_100cm_1330_max_monthly: 1,
          tbb_100cm_1330_min_monthly: 1,
          tbb_100cm_1330_sum_monthly: 1,
          tbb_100cm_1330_count_monthly: 1,
          tbb_100cm_1730_avg_monthly: 1,
          tbb_100cm_1730_mod_monthly: 1,
          tbb_100cm_1730_med_monthly: 1,
          tbb_100cm_1730_max_monthly: 1,
          tbb_100cm_1730_min_monthly: 1,
          tbb_100cm_1730_sum_monthly: 1,
          tbb_100cm_1730_count_monthly: 1,
          tbb_150cm_0730_avg_monthly: 1,
          tbb_150cm_0730_mod_monthly: 1,
          tbb_150cm_0730_med_monthly: 1,
          tbb_150cm_0730_max_monthly: 1,
          tbb_150cm_0730_min_monthly: 1,
          tbb_150cm_0730_sum_monthly: 1,
          tbb_150cm_0730_count_monthly: 1,
          tbb_150cm_1330_avg_monthly: 1,
          tbb_150cm_1330_mod_monthly: 1,
          tbb_150cm_1330_med_monthly: 1,
          tbb_150cm_1330_max_monthly: 1,
          tbb_150cm_1330_min_monthly: 1,
          tbb_150cm_1330_sum_monthly: 1,
          tbb_150cm_1330_count_monthly: 1,
          tbb_150cm_1730_avg_monthly: 1,
          tbb_150cm_1730_mod_monthly: 1,
          tbb_150cm_1730_med_monthly: 1,
          tbb_150cm_1730_max_monthly: 1,
          tbb_150cm_1730_min_monthly: 1,
          tbb_150cm_1730_sum_monthly: 1,
          tbb_150cm_1730_count_monthly: 1,
          tbb_200cm_0730_avg_monthly: 1,
          tbb_200cm_0730_mod_monthly: 1,
          tbb_200cm_0730_med_monthly: 1,
          tbb_200cm_0730_max_monthly: 1,
          tbb_200cm_0730_min_monthly: 1,
          tbb_200cm_0730_sum_monthly: 1,
          tbb_200cm_0730_count_monthly: 1,
          tbb_200cm_1330_avg_monthly: 1,
          tbb_200cm_1330_mod_monthly: 1,
          tbb_200cm_1330_med_monthly: 1,
          tbb_200cm_1330_max_monthly: 1,
          tbb_200cm_1330_min_monthly: 1,
          tbb_200cm_1330_sum_monthly: 1,
          tbb_200cm_1330_count_monthly: 1,
          tbb_200cm_1730_avg_monthly: 1,
          tbb_200cm_1730_mod_monthly: 1,
          tbb_200cm_1730_med_monthly: 1,
          tbb_200cm_1730_max_monthly: 1,
          tbb_200cm_1730_min_monthly: 1,
          tbb_200cm_1730_sum_monthly: 1,
          tbb_200cm_1730_count_monthly: 1,

          rh_5cm_0730_avg_monthly: 1,
          rh_5cm_0730_mod_monthly: 1,
          rh_5cm_0730_med_monthly: 1,
          rh_5cm_0730_max_monthly: 1,
          rh_5cm_0730_min_monthly: 1,
          rh_5cm_0730_sum_monthly: 1,
          rh_5cm_0730_count_monthly: 1,
          rh_5cm_1330_avg_monthly: 1,
          rh_5cm_1330_mod_monthly: 1,
          rh_5cm_1330_med_monthly: 1,
          rh_5cm_1330_max_monthly: 1,
          rh_5cm_1330_min_monthly: 1,
          rh_5cm_1330_sum_monthly: 1,
          rh_5cm_1330_count_monthly: 1,
          rh_5cm_1730_avg_monthly: 1,
          rh_5cm_1730_mod_monthly: 1,
          rh_5cm_1730_med_monthly: 1,
          rh_5cm_1730_max_monthly: 1,
          rh_5cm_1730_min_monthly: 1,
          rh_5cm_1730_sum_monthly: 1,
          rh_5cm_1730_count_monthly: 1,
          rh_10cm_0730_avg_monthly: 1,
          rh_10cm_0730_mod_monthly: 1,
          rh_10cm_0730_med_monthly: 1,
          rh_10cm_0730_max_monthly: 1,
          rh_10cm_0730_min_monthly: 1,
          rh_10cm_0730_sum_monthly: 1,
          rh_10cm_0730_count_monthly: 1,
          rh_10cm_1330_avg_monthly: 1,
          rh_10cm_1330_mod_monthly: 1,
          rh_10cm_1330_med_monthly: 1,
          rh_10cm_1330_max_monthly: 1,
          rh_10cm_1330_min_monthly: 1,
          rh_10cm_1330_sum_monthly: 1,
          rh_10cm_1330_count_monthly: 1,
          rh_10cm_1730_avg_monthly: 1,
          rh_10cm_1730_mod_monthly: 1,
          rh_10cm_1730_med_monthly: 1,
          rh_10cm_1730_max_monthly: 1,
          rh_10cm_1730_min_monthly: 1,
          rh_10cm_1730_sum_monthly: 1,
          rh_10cm_1730_count_monthly: 1,
          rh_20cm_0730_avg_monthly: 1,
          rh_20cm_0730_mod_monthly: 1,
          rh_20cm_0730_med_monthly: 1,
          rh_20cm_0730_max_monthly: 1,
          rh_20cm_0730_min_monthly: 1,
          rh_20cm_0730_sum_monthly: 1,
          rh_20cm_0730_count_monthly: 1,
          rh_20cm_1330_avg_monthly: 1,
          rh_20cm_1330_mod_monthly: 1,
          rh_20cm_1330_med_monthly: 1,
          rh_20cm_1330_max_monthly: 1,
          rh_20cm_1330_min_monthly: 1,
          rh_20cm_1330_sum_monthly: 1,
          rh_20cm_1330_count_monthly: 1,
          rh_20cm_1730_avg_monthly: 1,
          rh_20cm_1730_mod_monthly: 1,
          rh_20cm_1730_med_monthly: 1,
          rh_20cm_1730_max_monthly: 1,
          rh_20cm_1730_min_monthly: 1,
          rh_20cm_1730_sum_monthly: 1,
          rh_20cm_1730_count_monthly: 1,
          rh_50cm_0730_avg_monthly: 1,
          rh_50cm_0730_mod_monthly: 1,
          rh_50cm_0730_med_monthly: 1,
          rh_50cm_0730_max_monthly: 1,
          rh_50cm_0730_min_monthly: 1,
          rh_50cm_0730_sum_monthly: 1,
          rh_50cm_0730_count_monthly: 1,
          rh_50cm_1330_avg_monthly: 1,
          rh_50cm_1330_mod_monthly: 1,
          rh_50cm_1330_med_monthly: 1,
          rh_50cm_1330_max_monthly: 1,
          rh_50cm_1330_min_monthly: 1,
          rh_50cm_1330_sum_monthly: 1,
          rh_50cm_1330_count_monthly: 1,
          rh_50cm_1730_avg_monthly: 1,
          rh_50cm_1730_mod_monthly: 1,
          rh_50cm_1730_med_monthly: 1,
          rh_50cm_1730_max_monthly: 1,
          rh_50cm_1730_min_monthly: 1,
          rh_50cm_1730_sum_monthly: 1,
          rh_50cm_1730_count_monthly: 1,
          rh_100cm_0730_avg_monthly: 1,
          rh_100cm_0730_mod_monthly: 1,
          rh_100cm_0730_med_monthly: 1,
          rh_100cm_0730_max_monthly: 1,
          rh_100cm_0730_min_monthly: 1,
          rh_100cm_0730_sum_monthly: 1,
          rh_100cm_0730_count_monthly: 1,
          rh_100cm_1330_avg_monthly: 1,
          rh_100cm_1330_mod_monthly: 1,
          rh_100cm_1330_med_monthly: 1,
          rh_100cm_1330_max_monthly: 1,
          rh_100cm_1330_min_monthly: 1,
          rh_100cm_1330_sum_monthly: 1,
          rh_100cm_1330_count_monthly: 1,
          rh_100cm_1730_avg_monthly: 1,
          rh_100cm_1730_mod_monthly: 1,
          rh_100cm_1730_med_monthly: 1,
          rh_100cm_1730_max_monthly: 1,
          rh_100cm_1730_min_monthly: 1,
          rh_100cm_1730_sum_monthly: 1,
          rh_100cm_1730_count_monthly: 1,
          rh_150cm_0730_avg_monthly: 1,
          rh_150cm_0730_mod_monthly: 1,
          rh_150cm_0730_med_monthly: 1,
          rh_150cm_0730_max_monthly: 1,
          rh_150cm_0730_min_monthly: 1,
          rh_150cm_0730_sum_monthly: 1,
          rh_150cm_0730_count_monthly: 1,
          rh_150cm_1330_avg_monthly: 1,
          rh_150cm_1330_mod_monthly: 1,
          rh_150cm_1330_med_monthly: 1,
          rh_150cm_1330_max_monthly: 1,
          rh_150cm_1330_min_monthly: 1,
          rh_150cm_1330_sum_monthly: 1,
          rh_150cm_1330_count_monthly: 1,
          rh_150cm_1730_avg_monthly: 1,
          rh_150cm_1730_mod_monthly: 1,
          rh_150cm_1730_med_monthly: 1,
          rh_150cm_1730_max_monthly: 1,
          rh_150cm_1730_min_monthly: 1,
          rh_150cm_1730_sum_monthly: 1,
          rh_150cm_1730_count_monthly: 1,
          rh_200cm_0730_avg_monthly: 1,
          rh_200cm_0730_mod_monthly: 1,
          rh_200cm_0730_med_monthly: 1,
          rh_200cm_0730_max_monthly: 1,
          rh_200cm_0730_min_monthly: 1,
          rh_200cm_0730_sum_monthly: 1,
          rh_200cm_0730_count_monthly: 1,
          rh_200cm_1330_avg_monthly: 1,
          rh_200cm_1330_mod_monthly: 1,
          rh_200cm_1330_med_monthly: 1,
          rh_200cm_1330_max_monthly: 1,
          rh_200cm_1330_min_monthly: 1,
          rh_200cm_1330_sum_monthly: 1,
          rh_200cm_1330_count_monthly: 1,
          rh_200cm_1730_avg_monthly: 1,
          rh_200cm_1730_mod_monthly: 1,
          rh_200cm_1730_med_monthly: 1,
          rh_200cm_1730_max_monthly: 1,
          rh_200cm_1730_min_monthly: 1,
          rh_200cm_1730_sum_monthly: 1,
          rh_200cm_1730_count_monthly: 1,
        },
      },
    ];
    return listAttr;
  },
};
