import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
    getAttr() {
        const listAttr = [
            {
                label: "Piche Penguapan",
                attr: {
                    "h_piche_0730" : "tinggi piche di jam 07.30",
                    "h_piche_1330" : "tinggi piche di jam 13.30",
                    "h_piche_1730" : "tinggi piche di jam 17.30",
                    "eva_piche_0730" : "selisih penguapan piche di jam 07.30",
                    "eva_piche_1330" : "selisih penguapan piche di jam 13.30",
                    "eva_piche_1730" : "selisih penguapan piche di jam 17.30",

                    },
            }
        ]
            return listAttr;
    },

    getAttrMonthly() {
        const listAttr = [
            {
                group: "Piche",
                form: "Piche Penguapan",
                label: "Piche",
                attr: {
                    "h_piche_0730" : "tinggi piche di jam 07.30",
                    "h_piche_1330" : "tinggi piche di jam 13.30",
                    "h_piche_1730" : "tinggi piche di jam 17.30",
                    "eva_piche_0730" : "selisih penguapan piche di jam 07.30",
                    "eva_piche_1330" : "selisih penguapan piche di jam 13.30",
                    "eva_piche_1730" : "selisih penguapan piche di jam 17.30",

                    },
            }
        ]
            return listAttr;
    },


    getAttrDaily() {
        const listAttr = [
            {
                label: "Piche Penguapan",
                attr: {
                    "eva_piche_total" : "Selisih Penguapan Piche Total dari jam 07.30 sampai jam 17.30",
                }
            }
        ];
        return listAttr;
    },

    getAttrDailyCsv() {
        const listAttr = [
            {
                label: "Piche Penguapan Harian Csv",
                attr: {
                    "eva_piche_0730" : "eva_piche_0730",
                    "eva_piche_1330" : "eva_piche_1330",
                    "eva_piche_1730" : "eva_piche_1730",
                    "eva_piche_total" : "eva_piche_daily_total_mm",
                },
            }
        ];
        return listAttr;
    },

    getAttrDailyDecimal() {
        const listAttr = [
            {
                label: "Piche Penguapan Harian Decimal",
                attr: {
                    "eva_piche_total" : 1,
                },
            },
        ];
        return listAttr;
    },
    
}
